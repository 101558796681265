import { Component, Input, Output, EventEmitter } from '@angular/core';
import { BrokerService, LoggerFactory } from '@when-then/core';
import { Observable, BehaviorSubject, from } from 'rxjs';
import { map, take } from 'rxjs/operators';

export interface C4Param {
  _value?: number | string;
  _display?: string;

  name: string;
  type: ParamType;
  default?: string | number;
  high?: number;
  items?: any[];
  low?: number;
  maximum?: number;
  minimum?: number;
  multiselect?: boolean;
  readonly?: boolean;
  resolution?: number;
  value?: number;
  valueDisplay?: string;
  valueField?: string;
  valueSrc?: {
    method: 'GET',
    path: string
  };
  valueType?: string;
  values?: C4CombinedType[];
}

interface C4IdType {
  id: number | string;
  name: string;
}

export interface C4ValueType {
  display: string;
  value: string | number;
}

interface C4RegularizedType {
  _display: string;
  _value: string | number;
}

interface C4CombinedType {
  id?: number | string;
  name?: string;
  display?: string;
  value?: string | number;
}

type ParamType = 'LIST' | 'RANGE' | 'RANGED_INTEGER' | 'RANGED_FLOAT' |
  'DEVICE_SELECTOR' | 'HEXCOLOR' | 'INTEGER' | 'HIDDEN' | 'STRING' |
  'FLOAT' | 'BOOLEAN' | 'INT';

type InputType = 'checkbox' | 'color' | 'date' |
  'datetime' | 'email' | 'number' | 'list' |
  'month' | 'radio' | 'select' | 'range' |
  'tel' | 'text' | 'time' | 'url' | 'hidden';

@Component({
  selector: 'c4ParamInput',
  templateUrl: './param-input.component.html',
  styleUrls: ['./param-input.component.less']
})
export class ParamInput {

  private _logger = LoggerFactory.getLogger(ParamInput.name);

  @Input() param: C4Param;
  @Input() inputClass: string;
  @Input() typeOverride: InputType;
  @Input() placeholder: string;

  @Output() valueChange = new EventEmitter<C4ValueType>();

  _inputType: InputType;

  // Used if we're fetching a collection of values
  // from elsewhere...
  _values: Observable<C4RegularizedType[]>;

  constructor(private broker: BrokerService) {
  }

  ngOnInit() {

    this._inputType = this._getInputType(this.param);

    // Assemble an array of values from 'values', 'items',
    // or 'valueSrc', depending upon which we find. Wraps
    // all three types in an observable for template
    // consistency
    if (!!this.param.values) {
      let bh: BehaviorSubject<C4RegularizedType[]>;
      this._values = bh = new BehaviorSubject<C4RegularizedType[]>([]);
      bh.next(this.param.values.map(this._regularize));

    } else if (!!this.param.items) {
      let bh: BehaviorSubject<C4RegularizedType[]>;
      this._values = bh = new BehaviorSubject<C4RegularizedType[]>([]);
      bh.next(this.param.items.map(i => {

        // items are lists of numbers or strings, so just
        // map the value to both fields
        return {
          _display: (typeof i === 'string') ? i : i.toString(),
          _value: (typeof i === 'number') ? i : i.toString()
        }
      }))

    } else if (!!this.param.valueSrc && !!this.param.valueSrc.path) {
      this._values = from(
        this.broker.call({
          path: this.param.valueSrc.path,
          method: this.param.valueSrc.method || 'GET'
        })
      )
        .pipe(
          map(v => v.map(this._regularize))
        );
    }
  }

  /**
   * Parses a returned list of values, extracting the
   * relevant fields to _value and _display for template
   * consistency.
   */
  private _regularize = (v: any): C4RegularizedType => {

    let val =
      (v[this.param.valueField] != undefined) ? v[this.param.valueField] :
        (v.value != undefined) ? v.value :
          v.id;

    let pd = this._parseDisplay(this.param.valueDisplay, v);

    let dis =
      (pd != undefined) ? pd :
        (v.display != undefined) ? v.display :
          v.name;

    return Object.assign({}, v, {
      _value: val,
      _display: dis
    });
  }

  // Figure out which input type we should be using.
  // Defaults to 'text', with a warning that the
  // canonical type couldn't be found.
  private _getInputType = (param: C4Param): InputType => {

    if (this.typeOverride) {
      return this.typeOverride;
    }

    switch (param.type) {

      case 'RANGE':
      case 'RANGED_INTEGER':
      case 'RANGED_FLOAT':
        return 'range';

      case 'INT':
      case 'INTEGER':
      case 'FLOAT':
        return 'number';

      case 'HEXCOLOR':
        return 'color';

      case 'LIST':
      case 'DEVICE_SELECTOR':
        if (param.multiselect) return 'list';
        if (!param.multiselect) return 'select';

      case 'BOOLEAN':
        return 'checkbox';

      case 'STRING':
        return 'text';

      case 'HIDDEN':
        return 'hidden';

      default:
        console.warn('parameter-control.component.ts: Could not determine the correct input type based upon the provided parameter.');
        console.warn('parameter-control.component.ts: param: %O', param);
        console.warn('parameter-control.component.ts: Defaulting to text input.');

        return 'text';
    }
  }

  /**
   * Parses the tokens in a valueDisplay field, replacing them with
   * their corresponding values from the item
   */
  private _parseDisplay = (valueDisplay: string, value: Object): string => {
    let parsed = valueDisplay;
    let tokens = valueDisplay
      .match(/{(.*?)}/g)
      .map(t => t.substr(1, t.length - 2));

    tokens.forEach(t => parsed = parsed.replace('{' + t + '}', value[t]));

    return (!!tokens) ? parsed : undefined;
  }

  /**
   * Emits changes. Mostly to handle the cases where
   * we don't have a _values array...
   */
  public handleChange = (value: number | string) => {
    this._logger.debug('handling property change', value);
    let newValue: C4ValueType;
    if (!!this._values) {
      this._values
        .pipe(
          take(1)
        )
        .subscribe(vs => {
          let display = vs.find(v => v._value === value)._display;
          newValue = {
            value: value,
            display: display
          }
        })
    } else {
      newValue = {
        value: value,
        display: value.toString()
      }
    }

    this._logger.debug('setting new proeprty value', newValue);
    this.valueChange.next(newValue);
  }


}
