import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Store } from '@ngrx/store';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';
import { QuickstartProgrammingState } from '../../quickstarts.stores';

import { VoiceScenesService, VoiceScene } from '../voice-scenes.service';

const TURN = {
  ON: 'Turn On ',
  OFF: 'Turn Off '
}

@Component({
  selector: 'vs-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.less']
})
export class VSListComponent extends BaseRoutingComponent {

  public scenes: Observable<string[]>;
  public filteredScenes: Observable<string[]>;
  public initialized: boolean = false;
  public searchText: BehaviorSubject<string> = new BehaviorSubject<string>("");

  constructor(
    protected router: Router,
    protected store: Store<QuickstartProgrammingState>,
    private _sceneService: VoiceScenesService
  ) {
    super();

    this.scenes = this._sceneService.getScenesObservable()
      .pipe(
        map(scenes => scenes.reduce((acc, curr) => {
          (curr.off_prohibited) ? null : acc.push(TURN.OFF + curr.$t);
          (curr.on_prohibited) ? null : acc.push(TURN.ON + curr.$t);
          return acc;
        }, []))
      );

    this.filteredScenes = combineLatest(
      this.scenes,
      this.searchText
    )
      .pipe(
        tap(([scenes, search]) => this.initialized = true),
        map(([scenes, search]) => scenes
          .filter(scene => {
            return scene.toLowerCase().indexOf(search.toLowerCase()) > -1;
          })
        )
      )
  }

  public async addActions(scene: string) {
    const onOff = (scene.indexOf(TURN.ON) == 0) ? 'on' : 'off';
    const sceneName = scene.replace(TURN.ON, '').replace(TURN.OFF, '');

    const eventId = await this._sceneService.getEventId(sceneName, onOff);
    const deviceId = await this._sceneService.getDriverId();

    this.router.navigate(['when-then', 'device', deviceId, 'event', eventId, 'add'], {
      relativeTo: this.moduleRoot
    });
  }


}
