<div class="header flex-row nowrap space-between">
  <div class="grow blue title">When &gt;&gt; Then Automations</div>
  <span [routerLink]="['../add']" class="blue link">
    <span class="icon leading blue small add-circled"></span>
    <span> Add New Automation</span>
  </span>
</div>
<main>
  <cxWait [cxUntil]="(ready|async)" cxText="Loading automation...">
    <header class="filters">
      <span class="title" *ngIf="(currentCount | async) !== 0"><strong>{{currentCount | async}} Automations</strong></span>
      <span class="spacer"></span>
      <span class="filter">
        <label for="view">Show me</label>
        <select name="view" id="view" [ngModel]="(_filter | async)?.token" (change)="_updateFilter($event.target.value)">
          <option *ngFor="let filter of _filters" [value]="filter.token">{{ filter.label }}</option>
        </select>
      </span>
      <span class="filter">
        <label for="sortBy">Sort by</label>
        <select name="sortBy" id="sortBy" [ngModel]="(_by | async)?.token" (change)="_updateBy($event.target.value)">
          <option *ngFor="let item of _bys" [value]="item.token">{{ item.label }}</option>
        </select>
      </span>
      <div class="search">
        <span class="icon blue search small"></span>
        <input type="search" [value]="searchText | async" (input)="searchTextChanged($event)" placeholder="Search">
      </div>
    </header>

    <div [ngSwitch]="(_by | async)?.token">
      <div *ngSwitchCase="EVENTS_BY.DESCRIPTION">
        <prg-list-by-name [source]="filteredEvents | async | matchingSearchText:(this.searchText|async) | creator:((this._filter|async)?.token)"></prg-list-by-name>
      </div>
      <div *ngSwitchCase="EVENTS_BY.TODAY">
        <prg-today [source]="scheduleEvents | async | matchingSearchText:(this.searchText|async) | creator:((this._filter|async)?.token) | happeningToday"></prg-today>
      </div>
      <div *ngSwitchCase="EVENTS_BY.SCHEDULED">
        <prg-list-by-date [source]="scheduleEvents | async | matchingSearchText:(this.searchText|async) | creator:((this._filter|async)?.token)"></prg-list-by-date>
      </div>
      <div *ngSwitchCase="EVENTS_BY.ROOM">
        <prg-list-by-room [source]="filteredEvents | async | matchingSearchText:(this.searchText|async) | creator:((this._filter|async)?.token)"></prg-list-by-room>
      </div>
      <div *ngSwitchCase="EVENTS_BY.DEVICE">
        <prg-list-by-type [source]="filteredEvents | async | matchingSearchText:(this.searchText|async) | creator:((this._filter|async)?.token)"></prg-list-by-type>
      </div>
    </div>
  </cxWait>
</main>
