<header>
  <h6>The action to be added</h6>
</header>

<div class="row padded blue bordered rounded">
  <span class="icon blue" [ngClass]="pendingAction|async|icon"></span>
  <c4ParamLabel class="text" [command]="command|async" [params]="(pendingAction|async)?.params"></c4ParamLabel>
</div>
<!-- <prg-block-button leadingIcon="blue {{pendingAction | async | icon}}" [title]="(pendingAction|async)?.display"></prg-block-button> -->
<prg-nav-header title="Select when you would like this action to take place" (cancel)="goToEvent()"></prg-nav-header>
<div class="errors" *ngIf="errors.length > 0">
  <div class="error" *ngFor="let err of errors">{{err}}</div>
</div>
<div *ngIf="ready|async">
  <cxScrollable>
    <prg-block-button leadingIcon="blue plus" title="Always perform this action" (click)="addAction()"></prg-block-button>
    <div *ngFor="let ct of (conditionalTypes|async)">
      <div *ngFor="let wl of ct.whitelist">
        <prg-block-button leadingIcon="blue {{cnd.icon}}" [title]="cnd.label" *ngFor="let cnd of wl.conditionals"
          (click)="selectConditional(ct, cnd)" trailingIcon="right"></prg-block-button>
      </div>
    </div>
  </cxScrollable>
</div>
