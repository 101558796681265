import { Pipe, PipeTransform } from '@angular/core';

import { SharedProgrammingService } from './../services/shared-programming.service';
import { CodeItem } from '../interfaces/code-item.interface';

@Pipe({
  name: 'codeitemActionIcon'
})
export class CodeitemActionIconPipe implements PipeTransform {

  constructor(
    private shared: SharedProgrammingService
  ) { }

  transform(codeitem: CodeItem, args?: any): any {
    // console.log('codeitem icon pipe, args are', args);
    let editing: boolean = args;

    let icon = '';

    if (editing) {
      icon = (codeitem.protected) ? 'dealer-lock' : 'times';
    }

    return icon;
  }
}
