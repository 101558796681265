import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject, empty } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { UrlFactoryService } from '../services/url-factory.service';
import { MonitoredHttpService as Http } from '../services/monitored-http.service';

import { BrokerService } from '../services/broker.service';
import { LoggerFactory } from '../services/log.service';

export interface DirectorConnectionState {
  director?: 'unknown' | 'initializing' | 'parsing' | 'connected' | 'disabled';
  driverCacheProgress?: number;
  programmingCacheProgress?: number;
}

export const DIRECTOR_CONNECTION_INITIAL_STATE: DirectorConnectionState = {}

const STORE_NAME = 'DIRECTOR_CONNECTION';

export const DIRECTOR_CONNECTION_ACTIONS = {
  SET_DIRECTOR: `${STORE_NAME}:SET_DIRECTOR`,
  CLEAR: `${STORE_NAME}:CLEAR`
}

export const DIRECTOR_CONNECTION_STRINGS = Object.keys(DIRECTOR_CONNECTION_ACTIONS).map(key => DIRECTOR_CONNECTION_ACTIONS[key]);

export function directorConnectionReducer(state: DirectorConnectionState = DIRECTOR_CONNECTION_INITIAL_STATE, { type, payload }) {

  // if (DIRECTOR_CONNECTION_STRINGS.indexOf(type) > -1) {
  //   this._logger.info('type: %s, payload: %O', type, payload);
  // }

  switch (type) {
    case DIRECTOR_CONNECTION_ACTIONS.SET_DIRECTOR:
      return Object.assign({}, payload);

    case DIRECTOR_CONNECTION_ACTIONS.CLEAR:
      return Object.assign({}, DIRECTOR_CONNECTION_INITIAL_STATE);

    default:
      return state;
  }
}

@Injectable()
export class DirectorConnectionService {
  private _logger = LoggerFactory.getLogger(DirectorConnectionService.name);
  // How often to ping /status, in ms
  private _interval = 5000;

  constructor(
    private _store: Store<{ directorConnection: DirectorConnectionState }>,
    private _http: Http,
    private _broker: BrokerService,
    private _url: UrlFactoryService
  ) {

    this._broker.initialized.pipe(filter(i => !!i)).subscribe(i => {
      this._logger.debug('dc: broker initialized, start status sub');
      this._broker.getObservable({
        path: '/api/v1/status'
      }).subscribe(status => {
        // this._logger.debug('dc: status sub update', status);
        this._store.dispatch({
          type: DIRECTOR_CONNECTION_ACTIONS.SET_DIRECTOR,
          payload: status
        });
      }, err => this._unknownStatus());
    });

    // Observable.timer(0, this._interval).flatMap(t => {
    //   const jwt = JSON.parse(window.localStorage.getItem('authToken')).value;
    //   const headers = {};
    //   if (!!jwt) {
    //     headers['Authorization'] = jwt;
    //   }
    //   return <Observable<Response>>_http.request(_url.getBrokerURL('/api/v1/status'), {
    //     headers: headers
    //   })
    //     .timeout(this._interval)
    //     .catch((err, caught) => {
    //       return this._unknownStatus();
    //     })
    // }).filter(r => !!r)
    //   .map(r => r.json())
    //   .subscribe(status => this._store.dispatch({
    //     type: DIRECTOR_CONNECTION_ACTIONS.SET_DIRECTOR,
    //     payload: status
    //   }))
  }

  private _unknownStatus = (): Observable<{}> => {
    this._store.dispatch({
      type: DIRECTOR_CONNECTION_ACTIONS.SET_DIRECTOR,
      payload: {
        director: 'unknown'
      }
    });

    return empty();
  }

}
