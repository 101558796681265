import { Store } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedProgrammingService } from '../../common/services/shared-programming.service';
import { QuickstartProgrammingService } from '../services/quickstart-programming.service';

@Component({
  selector: 'prg-when-then',
  templateUrl: './when-then.component.html',
  styleUrls: ['./when-then.component.less']
})
export class WhenThenComponent implements OnInit {

  constructor(
    // NOTE although these appear to be unused, they need to be injected to allow
    // intialization to occur, don't delete them
    private shared: SharedProgrammingService,
    private quickstarts: QuickstartProgrammingService
  ) { }

  ngOnInit() {
  }

  isActive(name: string): boolean {
    return (window.location.href.indexOf(name) >= 0);
  }
}
