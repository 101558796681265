import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { StorageService, UrlFactoryService, LoggerFactory } from '@when-then/core';

import { AuthenticationStrategy } from './authentication-strategy';
import { AuthenticationState } from '../services/authentication-state';

@Injectable()
export class Local implements AuthenticationStrategy {
  private _logger = LoggerFactory.getLogger(Local.name);
  authentication: Observable<AuthenticationState>;

  constructor(
    // NOTE http service is used here directly because using broker would create a circular reference
    private http: HttpClient,
    private url: UrlFactoryService,
    private storage: StorageService
  ) { }

  authenticate(profile: string, password: string): Promise<any> {
    this._logger.debug('local.ts: authenticate()');
    return new Promise((resolve, reject) => {
      let path = undefined;
      let data = undefined;
      let requireOwner = false;
      if (profile === 'pro-installer' || profile === 'root') {
        path = '/api/v1/localjwt';
        data = {
          user: profile,
          password: password
        };
      } else {
        requireOwner = true;
        path = '/api/v1/jwt';
        data = {
          applicationkey: this.storage.get('appKey'),
          // env: this.storage.get('env') || 'Prod', // NOTE this should be assumed by broker now
          email: profile,
          pwd: password,
          dev: false
        };
      }

      this.http.post(this.url.getBrokerURL(path), data, {
        headers: this.getHeaders(this.getAuthToken()),
        responseType: 'json'
      }).subscribe(
        (data: {token: string, C4ErrorResponse: any}) => {
          this._logger.debug('local.ts: auth response is', data);

          if (data.token) {
            this.storage.set('authToken', data.token);
            if (requireOwner) {
              // in the consumer auth case we need to go a step further and confirm
              // the provided credential belongs to the primary account holder
              let token = this.storage.get('authToken');
              // this._logger.debug('local: stored auth token is', token);
              this.http.get(this.url.getBrokerURL('/api/v1/jwt/user'), {
                headers: this.getHeaders(token),
                responseType: 'json'
              }).subscribe((user: {isOwner: boolean}) => {
                if (!!user) {
                  this.storage.set('currentUser', user);
                  if (!user.isOwner) {
                    this.unauthenticate();
                    reject({ message: 'Access to this system is granted only to the primary account owner.  Please login with the primary account owner credentials.' });
                  } else {
                    resolve();
                  }
                }
              }, err => {
                this._logger.error('local.ts: unable to confirm account owner due to service error', err);
                reject(err);
              });
            } else {
              resolve();
            }
          } else {
            if (data.C4ErrorResponse) {
              this._logger.error('local: authenticate: error response returned', data);
              reject(data);
            } else {
              this._logger.error('local: unrecongized response in authenticate', data);
              reject(data);
            }
          }
        },
        error => {
          reject(error);
        });
    });
  }

  unauthenticate(): Promise<any> {
    this._logger.debug('local.ts: unauthenticate()');
    return new Promise((resolve, reject) => {
      this.storage.remove('accessToken');
      this.storage.remove('authToken');
      this.storage.remove('profile');
      this.storage.remove('currentUser');
      this.storage.remove('username');
      resolve();
    });
  }

  validateAuthentication(): Promise<boolean> {
    this._logger.debug('local.ts: validateAuthentication()');
    return new Promise((resolve, reject) => {
      this.http.get(this.url.getBrokerURL('/api/v1/logger/level'), {
        headers: this.getHeaders(this.getAuthToken())
      }).subscribe(
        response => resolve(true),
        error => {
          this.unauthenticate().then(() => {
            resolve(false);
          });
        });
    });
  }

  getAuthToken(): string {
    return this.storage.get('authToken');
  }

  isAuthenticated(): boolean {
    this._logger.warn('local.ts: isAuthenticated not supported with Local auth');
    return true;
  }

  isAuthorized(): boolean {
    let authToken: string = this.storage.get('authToken');
    return (authToken !== null && authToken.length > 0);
  }

  getCurrentAccount(accessToken: string): Promise<string> {
    this._logger.warn('local.ts: getCurrentAccount not supported with Local auth');
    return new Promise((resolve, reject) => {
      reject({ message: 'unsupported: getCurrentAccoutn unsupported with Local auth' })
    });
  }

  getHeaders(token?: string): HttpHeaders {
    let headers = new HttpHeaders();
    if (token) {
      headers.append('Authorization', `Bearer ${token}`);
    }
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json; charset=UTF-8');
    return headers;
  }

  checkLicense(profile: string): Promise<boolean> {
    this._logger.debug('local: checking license for profile', profile);
    if (profile === 'primary') {
      return new Promise<boolean>((resolve, reject) => {
        this.http.get(this.url.getBrokerURL('/api/v1/jwt/account?licenses=true'), {
          headers: this.getHeaders(this.getAuthToken()),
          responseType: 'json'
        }).subscribe(data => {
          this._logger.debug('local: license response', data);
          resolve(this._has4SightLicense(data));
        }, err => {
          this._logger.error('local: error checking 4sight license', err);
          reject(err);
        });
      });
    } else {
      // NOTE license check not required for root or pro-installer
      return Promise.resolve(true);
    }
  }

  private _has4SightLicense(account: any): boolean {
    let hasLicense = false;

    if (!!account && !!account.licenses && account.licenses.totalCount > 0) {
      let licenses = account.licenses.license || [];
      if (!Array.isArray(licenses)) {
        licenses = [licenses];
      }

      licenses.forEach(lic => {
        // NOTE expired 4sight licenses are not returned, so no need to check the timestamp
        if (lic.name === '4Sight Services') {
          hasLicense = true;
        }
      });
    }

    return hasLicense;
  }
}

