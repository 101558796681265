import { Pipe, PipeTransform } from '@angular/core';

import * as _ from 'lodash';

const SEPARATORS = [' ', '_'];
const RXP = new RegExp(SEPARATORS.join('|'), 'g');

@Pipe({
  name: 'readable',
  pure: true
})
export class ReadablePipe implements PipeTransform {

  transform(value: string): string {
    if (value) {
      let words = value.split(RXP);
      words = words.map(w => {
        return _.startCase(w.toLowerCase());
      });

      return words.join(' ');
    } else {
      return value;
    }
  }

}
