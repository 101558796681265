import { Routes } from '@angular/router';

import { ScheduleQuickstartComponent } from './schedule-quickstart.component';
import { ScheduleListComponent } from './schedule-list/schedule-list.component';
import { AddScheduleComponent } from './add-schedule/add-schedule.component';

export const QUICKSTART_TRIGGERS_SCHEDULE_ROUTES: Routes = [{
  path: 'schedule', component: ScheduleQuickstartComponent, children: [{
    path: '', redirectTo: 'list', pathMatch: 'full'
  }, {
    path: 'list', component: ScheduleListComponent
  }, {
    path: ':id', component: AddScheduleComponent
  }]
}];
