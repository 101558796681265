import {Pipe, PipeTransform} from '@angular/core';
import {Store} from '@ngrx/store';

@Pipe({
  name: 'roomItems'
})
export class RoomItemsFilterPipe implements PipeTransform {

  constructor(
    private store: Store<any>
  ) { }

  transform(items: any, room: any): Array<any> {
    if (room) {
      return items.filter(item => {
        return (item.roomId === room.id);
      });
    } else {
      return items;
    }
  }
}
