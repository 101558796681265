import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { QSSimpleComponent } from './quickstart-simple.component';
import { QSSimpleSelectDeviceComponent } from './select-device/select-device.component';
import { QSSimpleSelectEventComponent } from './select-event/select-event.component';
import { QSSimpleSelectActionComponent } from './select-action/select-action.component';
import { QSSimpleSelectParamsComponent } from './select-params/select-params.component';
import { QSSimpleSelectEventDeviceComponent } from './select-event-device/select-event-device.component';
import { QSSimpleSelectActionDeviceComponent } from './select-action-device/select-action-device.component';
import { CONDITIONAL_ROUTES } from '../quickstart-flow-control/flow-control.routes';

export const QUICKSTART_TRIGGERS_SIMPLE_ROUTES: Routes = [
  {
    path: '',
    component: QSSimpleComponent,
    children: [
      {
        path: ':type',
        component: QSSimpleSelectEventDeviceComponent
      },
      {
        path: 'device/:id',
        component: QSSimpleSelectEventComponent
      }
    ]
  },

];

export const QUICKSTART_ACTIONS_SIMPLE_ROUTES: Routes = [
  {
    path: '',
    component: QSSimpleComponent,
    children: [
      {
        path: ':type',
        component: QSSimpleSelectActionDeviceComponent
      },
      {
        path: 'device/:id',
        component: QSSimpleSelectActionComponent
      },
      {
        path: 'device/:id/conditionals', children: [...CONDITIONAL_ROUTES]
      },
      {
        path: 'device/:id/:command',
        component: QSSimpleSelectParamsComponent
      },
      {
        path: 'device/:id/:command/conditionals', children: [...CONDITIONAL_ROUTES]
      }
    ]
  }
];
