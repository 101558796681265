import { ExpectedConditions } from 'protractor';
import { EVENTS_FILTER } from './../../events/eventsUI.service';
import { Component, OnInit, Input, EventEmitter, ViewChild, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';

import { CommonProgrammingContext } from './../../../common/services/shared-programming.service';
import { SharedProgrammingService } from '../../../common/services/shared-programming.service';
import { CodeItem } from '../../../common/interfaces/code-item.interface';
import { Schedules } from '../../quickstart-schedule/+state/schedule.interfaces';
import { ProgrammingUtilsService as Utils } from '../../../common/services';
import { EVENTS_BY, EventsUIContext } from '../../events/eventsUI.service';

@Component({
  selector: 'prg-event-codeitems,[prg-event-codeitems]',
  templateUrl: './event-codeitems.component.html',
  styleUrls: ['./event-codeitems.component.less']
})
export class EventCodeitemsComponent implements OnInit {

  @Input() event: any;
  @Input() subtitle: string;
  @Input() expanded: boolean;

  @ViewChild('confirmDeleteModal') confirmDeleteModal;

  ready: Observable<boolean>;
  editing: Observable<boolean>;
  adding: Observable<boolean>;
  showSubtitle: Observable<boolean>;

  delete: EventEmitter<CodeItem>;

  constructor(
    private store: Store<{
      sharedProgramming: CommonProgrammingContext,
      scheduleProgramming: Schedules,
      eventsUI: EventsUIContext
    }>,
    private route: ActivatedRoute,
    private shared: SharedProgrammingService,
  ) {
    this.editing = this.store.select(s => s.sharedProgramming.editing);
    this.adding = this.store.select(s => s.sharedProgramming.adding);
    this.ready = this.store.select(s => s.sharedProgramming.ready);

    this.showSubtitle = combineLatest(
      this.store.select(s => s.eventsUI.by),
      this.route.url,
      (filter, url) => {
        return (
          url.some(el => el.path === 'event') ||
          filter === EVENTS_BY.TODAY ||
          filter === EVENTS_BY.SCHEDULED
        );
      })

    this.delete = new EventEmitter<CodeItem>();
  }

  toggleExpanded(event: MouseEvent) {
    event.stopPropagation();
    this.expanded = !this.expanded;
  }

  ngOnInit() {
    this.shared.clearError();
  }

  isEmpty(): boolean {
    return (!this.event || (!!this.event && (!this.event.codeItems || this.event.codeItems.length === 0)));
  }
}
