import {Pipe, PipeTransform} from '@angular/core';
import {Store} from '@ngrx/store';

@Pipe({
  name: 'locationItems'
})
export class LocationItemsFilterPipe implements PipeTransform {

  constructor(
    private store: Store<any>
  ) { }

  transform(items: any, location: any): Array<any> {
    if (location) {
      return items.filter(item => {
        if (location.floorId) {
          return (item.floorId === location.floorId);
        } else if (location.buildingId) {
          return (item.buildingId === location.buildingId);
        } else if (location.siteId) {
          return (item.siteId === location.siteId);
        }
      });
    } else {
      return items;
    }
  }
}
