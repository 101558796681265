import { CodeItemType, Item } from './../../../common/interfaces/item.interface';
import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';


import { ProgrammingUtilsService as Utils } from './../../../common/services/utils.service';
import { Device } from '../../../common/interfaces/item.interface';
import { SharedProgrammingService, CommonProgrammingContext } from '../../../common/services/shared-programming.service';
import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';

import { MediaProgrammingContext } from '../quickstart-media.service';
import { LoggerFactory, BrokerService } from '@when-then/core';
import { RemoteProgrammingContext } from '../../quickstart-system-remotes/quickstart-remotes.service';

@Component({
  templateUrl: './select-remote.component.html'
})
export class QSMediaSelectRemoteComponent extends BaseRoutingComponent {

  private _logger = LoggerFactory.getLogger(QSMediaSelectRemoteComponent.name);

  _remotes: Observable<Device[]>;
  _ready: Observable<boolean>;

  constructor(
    private route: ActivatedRoute,
    private broker: BrokerService,
    protected store: Store<{
      mediaProgramming: MediaProgrammingContext,
      sharedProgramming: CommonProgrammingContext,
      remoteProgramming: RemoteProgrammingContext
    }>,
    private _shared: SharedProgrammingService,
    public router: Router
  ) {
    super();
    this._remotes = this.store.select(s => s.remoteProgramming.remotes)
      .pipe(
        map(remotes => remotes.filter(r => !!r.capabilities && !!r.capabilities['can_be_found']))
      );
    this._ready = this._shared.isReady;
  }

  // TODO again, how does this even work?  private methods can't be called from the template
  private async _setAction(remote: Device) {

    try {
      const commands = await this.broker.call({
        path: `/api/v1/items/${remote.id}/commands`
      });
      const cmd = commands.find(c => c.command === 'BEEP:TIME');

      let commandData = {
        ...Utils.buildCommandData(CodeItemType.Command, cmd),
        ...{
          params: [{
            name: 'TIME',
            value: 60
          }]
        }
      };

      this._shared.setPendingAction(cmd, commandData);
      this.router.navigate(['conditionals'], { relativeTo: this.route });
    } catch (e) {
      this._logger.error(e);
    }
  }
}
