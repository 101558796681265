import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { UIModule } from '@when-then/ui';

import { ProgrammingCommonModule } from '../../common/common.module';
import { QuickstartCommonModule } from '../quickstart-common/quickstart-common.module';

import { QSThermostatService } from './quickstart-thermostats.service';

import { QSThermostatComponent } from './quickstart-thermostats.component';
import { QSThermostatSelectActionComponent } from './select-action/select-action.component';
import { QSThermostatSelectParamsComponent } from './select-params/select-params.component';
import { QSThermostatSelectThermostatComponent } from './select-thermostat/select-thermostat.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    UIModule,
    ProgrammingCommonModule,
    QuickstartCommonModule
  ],
  declarations: [
    QSThermostatComponent,
    QSThermostatSelectActionComponent,
    QSThermostatSelectParamsComponent,
    QSThermostatSelectThermostatComponent
  ],
  providers: [
    QSThermostatService
  ]
})
export class QuickstartThermostatModule { }
