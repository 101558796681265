import { Routes } from "@angular/router";
import { LEDColorsActionComponent } from "./led-colors-action/led-colors-action.component";
import { LEDColorsSelectParametersComponent } from './select-parameters/select-parameters.component';
import { LEDColorsActionTypeComponent } from './led-colors-action-type/led-colors-action-type.component';
import { LEDColorsSelectActionComponent } from './select-action/select-action.component';
import { CONDITIONAL_ROUTES } from "../quickstart-flow-control/flow-control.routes";

export const QUICKSTART_ACTIONS_LED_COLORS_ROUTES: Routes = [{
  path: 'led-colors', children: [{
    path: '', redirectTo: 'select', pathMatch: 'full'
  }, {
    path: 'select', component: LEDColorsActionTypeComponent, children: [{
      path: '', redirectTo: 'device', pathMatch: 'full'
    }, {
      path: 'device', component: LEDColorsActionComponent
    }]
  }, {
    path: 'device/:id', component: LEDColorsSelectActionComponent
  }, {
    path: 'device/:id/conditionals', children: [...CONDITIONAL_ROUTES]
  }, {
    path: 'device/:id/:command', component: LEDColorsSelectParametersComponent
  }, {
    path: 'device/:id/:command/conditionals', children: [...CONDITIONAL_ROUTES]
  }]
}];