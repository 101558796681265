<div *ngIf="(ready | async)">
  <prg-nav-header title="Which action on the {{device | async | deviceInRoom }} do you want to take place?" (cancel)="goToEvent()"></prg-nav-header>

  <div class="alert" *ngIf="(_keypadWithoutConfig | async)?.length > 0">
    <h4>No Buttons Defined</h4>
    <p>The selected keypad does not have any buttons configured. Please contact your Control4 dealer for assistance.
      You can
      also <a onclick="window.history.back()">try another device</a>.</p>
  </div>

  <cxScrollable *ngIf="(_keypadWithoutConfig | async)?.length === 0">
    <prg-block-button *ngFor="let command of _parameterCommands|async" [routerLink]="['../parameters', command.command, {id: command.id}]"
      leadingIcon="blue keypad" title="{{ command.label }}" trailingIcon="right"></prg-block-button>
  </cxScrollable>
</div>
