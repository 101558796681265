<prg-nav-header
  title='Where would you like the {{ (category | async)?.toLowerCase() | singularize }} "{{ (mediaItem | async)?.text }}" to play?'
  (cancel)="goToEvent()">
</prg-nav-header>
<cxWait
  [cxUntil]="(rooms | async) != undefined && (mediaItem | async)?.text"
  cxText="Finding rooms...">

  <div
    class="roomList"
    #roomList
    [style.maxHeight]="maxHeight">
    <prg-block-button
      *ngFor="let room of (rooms | async)"
      [title]="room.name"
      (click)="toggleRoom(room.id)"
      [buttonClass]="(selectedRooms | async)?.indexOf(room.id) > -1 ? 'dark' : ''"
      leadingIcon="room blue">
    </prg-block-button>
  </div>

  <footer
    class="flex row"
    #stickyFooter>
    <span class="preview">Play {{ (mediaItem | async)?.text }} in the {{ selectedRoomString | async }}</span>
    <button
      [disabled]="(selectedRooms | async)?.length == 0"
      (click)="setAction()"
      class="blue clickable">Add Automation</button>
  </footer>

</cxWait>
