<prg-nav-header title="Define a delay interval" (cancel)="goToEvent()"></prg-nav-header>
<form action="" (ngSubmit)="addDelay()">
  <div class="field-group">
    <label for="interval" class="leading">Pause for </label>
    <input type="number" [(ngModel)]="delay.interval" name="interval" id="interval" min="1" value="1" (change)="valueChanged($event)">
    <select name="units" id="units" class="inline-field" [(ngModel)]="delay.units">
      <option value="1000">seconds</option>
      <option value="60000">minutes</option>
    </select>
  </div>
  <div class="errors" *ngIf="errors.length > 0">
    <div *ngFor="let err of errors" class="error">{{err}}</div>
  </div>
  <div class="buttons">
    <button class="blue" type="submit">Add Delay</button>
  </div>
</form>
