import { Router } from '@angular/router';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';

import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';

@Component({
  selector: 'prg-nav-header,[prg-nav-header]',
  templateUrl: './nav-header.component.html',
  styleUrls: ['./nav-header.component.less']
})
export class NavHeaderComponent extends BaseRoutingComponent implements OnInit {

  @Input() title: string;

  @Output() cancel: EventEmitter<any>;

  constructor(
    protected router: Router,
    protected store: Store<any>
  ) {
    super();
    this.cancel = new EventEmitter<any>();
  }

  ngOnInit() {

  }
}
