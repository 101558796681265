import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ProgrammingUtilsService } from '../services/utils.service';
import { Logger, LoggerFactory } from '@when-then/core';
import { CommonProgrammingContext } from '../services/shared-programming.service';
import { AGENTS } from '../services/agent-meta.service';

@Injectable()
export class NonScheduleEventGuard implements CanActivate, CanActivateChild {

  private _logger: Logger;

  constructor(
    private utils: ProgrammingUtilsService,
    private store: Store<{
      sharedProgramming: CommonProgrammingContext
    }>,
    private router: Router
  ) {
    this._logger = LoggerFactory.getLogger(NonScheduleEventGuard.name);
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.store.select(s => s.sharedProgramming.trigger.event)
      .pipe(
        filter(e => !!e),
        map(e => e.deviceId !== AGENTS.SCHEDULER.ID)
      );
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.canActivate(next, state);
  }
}
