import { CommandType } from './command-type.interface';

export const DOOR_LOCK: CommandType = {
  name: 'Door Lock',
  description: 'Lock a Door',
  route: 'door-lock',
  category: 'Security & Access',
  order: 0,
  whitelist: [
    {
      proxy: 'lock',
      commands: [
        {
          id: 1,
          command: 'LOCK',
          icon: 'door-lock'
        }
      ]
    },
    {
      proxy: 'Lock_ZigBee_Yale',
      commands: [
        {
          command: 'LOCK',
          icon: 'door-lock'
        }
      ]
    },
    {
      proxy: 'Lock_Zigbee_Baldwin_SmartLock',
      commands: [
        {
          command: 'Lock',
          icon: 'door-lock'
        }
      ]
    },
    {
      proxy: 'DriverWorks_BlackDecker_Lock',
      commands: [
        {
          command: 'Lock',
          icon: 'door-lock'
        }
      ]
    },
    {
      proxy: 'DriverWorks_BlackDecker_Lock_SmartLock RF Zigbee_Black and Decker',
      commands: [
        {
          command: 'Lock',
          icon: 'door-lock'
        }
      ]
    },
    {
      proxy: 'Lock_Zigbee_Kwikset_SmartLock',
      commands: [
        {
          command: 'Lock',
          icon: 'door-lock'
        }
      ]
    }
  ]
}
