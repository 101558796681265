import { Pipe, PipeTransform } from '@angular/core';

import { ProgrammingUtilsService as Utils, DAYS_OF_THE_WEEK } from './../services/utils.service';

@Pipe({
  name: 'scheduleTitle'
})
export class ScheduleTitlePipe implements PipeTransform {

  constructor() { }

  transform(schedule: any, args?: any): any {
    let display = '';

    if (schedule && schedule.next_occurence) {
      let next = schedule.next_occurence;
      display += Utils.toDateDisplay(next.year, next.month, next.day);

      display += ' at '
      display += Utils.toTimeDisplay(next.year, next.month, next.day, ((next.hour * 60) + next.min));

      if (schedule.randomizeBy) {
        display += ' +/- ';
        display += schedule.randomizeBy;
        display += ' minutes';
      }
    }

    return display;
  }

  private composeDaysOfTheWeekDisplay(days: number): string {
    let display = '';

    for (var i = 0; i < 7; i++) {
      let val = Math.pow(2, i);
      if ((days & val) === val) {
        display += (DAYS_OF_THE_WEEK[i].toUpperCase().charAt(0));
      }
    }

    return display;
  }
}
