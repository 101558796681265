import { Observable } from "rxjs";
import { filter, distinctUntilChanged } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { RouterEvent, Router } from "@angular/router";

import { SubState } from "../interfaces/store";
import { StorageService } from "./storage.service";
import { Logger, LoggerFactory } from "./log.service";

declare var ga: Function;

@Injectable()
export class AnalyticsService {
  ready: Observable<boolean>;
  private _logger: Logger;

  constructor(
    private store: Store<{ commonConfig: SubState }>,
    private router: Router,
    private storage: StorageService
  ) {
    this._logger = LoggerFactory.getLogger(AnalyticsService.name);
    // NOTE wait for the runtime configuration to be read before trying to
    // load the GA script and initialize a tracker
    this.store.select(s => s.commonConfig.loaded)
      .pipe(
        filter(loaded => !!loaded),
        distinctUntilChanged()
      )
      .subscribe(loaded => {
        this.appendGaTrackingCode(this.storage.get('trackingId'));
      });
  }

  private get ga(): Function { return (<any>window).ga; }

  emitPageView(url: string) {
    if (this._useGA()) {
      this.ga('set', 'page', url);
      this.ga('send', 'pageview');
    }
  }

  emitEvent(category: string, action: string, label: string, value?: number) {
    if (this._useGA()) {
      this.ga('send', 'event', {
        eventCategory: category,
        eventLabel: label,
        eventAction: action,
        eventValue: value
      });
    }
  }

  emitTiming(category: string, variable: string, label: string, value: number) {
    if (this._useGA()) {
      this.ga('send', 'timing', {
        timingCategory: category,
        timingLabel: label,
        timingVar: variable,
        timingValue: value
      });
    }
  }

  private _useGA(): boolean {
    const trackingId = this.storage.get('trackingId');
    return (!!trackingId && typeof (<any>window).ga === 'function');
  }

  /**
   * This little hack is necessary to allow us to separate GA tracking between beta and
   * prod environments.  Separate tracking ids are defined for each environment, so the
   * applicable tracking id must bge read from configuration at runtime.
   * @param trackingId the google tracking id read from configuration
   */
  private appendGaTrackingCode(trackingId) {
    try {
      const script = document.createElement('script');
      script.innerHTML = `
      (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
      (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
      m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
      })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

      ga('require', 'eventTracker');
      ga('require', 'outboundLinkTracker');
      ga('require', 'urlChangeTracker');
      ga('require', 'cleanUrlTracker', {stripQuery: true});
      ga('require', 'eventTracker');
      ga('require', 'maxScrollTracker	');
      ga('require', 'mediaQueryTracker');
      ga('require', 'pageVisibilityTracker');

      ga('create', '` + trackingId + `', 'auto');`;

      document.head.appendChild(script);

      /**
       * script.onload is not reliable or supported by all browsers,
       * so another little hack here, set an interval that checks
       * every 200ms to see if a global `ga` variable has been defined,
       * once it exists, register for router events
       */
      const that = this;
      const scriptLoaded = setInterval(function() {
        that._logger.debug('checking for ga global');
        const ga = (<any>window).ga;
        if (!!ga && typeof ga === 'function') {
          clearInterval(scriptLoaded);
          that._logger.debug('GA script loaded');
          that.router.events.subscribe(event => {
            const ga = (<any>window).ga;
            if (event instanceof RouterEvent) {
              that.emitPageView(event.url);
            }
          });
        }
      }, 200);
    } catch (ex) {
      this._logger.error('Error appending google analytics');
      this._logger.error(ex);
    }
  }
}
