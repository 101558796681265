import { Injectable } from '@angular/core';

import { environment as ENV } from '../../environments/environment';

import { BaseService } from './base.service';
import { QueryString } from './broker.service';
import { ServiceLocatorService } from './service-locator.service';
import { StorageService } from './storage.service';

@Injectable()
export class UrlFactoryService extends BaseService {

  constructor(
    private storage: StorageService
  ) {
    super();
  }

  getBrokerURL(path?: string, queryString?: QueryString): string {
    return this.getEndpoint() + this.compose(path, queryString);
  }

  private compose(path?: string, queryString?: QueryString): string {
    let fullPath = '';

    if (path) {
      // a little sanity checking
      if (path.charAt(0) !== '/') {
        path = '/' + path;
      }
      fullPath += path;
    }

    if (queryString) {
      let qs = '';
      Object.keys(queryString).forEach(function(key) {
        if (queryString.hasOwnProperty(key)) {
          var val = queryString[key];
          if (val) {
            if (qs.length > 1) {
              qs += '&';
            }

            if (Array.isArray(val)) {
              val = val.join(',');
            } else if (typeof val === 'object') {
              val = JSON.stringify(val);
            }

            qs += (key + '=' + encodeURIComponent(val));
          }
        }
      });

      if (qs.length) {
        if (fullPath.indexOf('?') >= 0) {
          fullPath += ('&' + qs);
        } else {
          fullPath += ('?' + qs);
        }
      }
    }

    return fullPath;
  }

  public getEndpoint(): string {
    // console.debug('url-factory.service.ts: getEndpoint()');
    // let env = this.storage.get('env');
    // console.debug('url-factory.service.ts: env: %s', env);
    // console.log('url: current environment is ', ENV);
    if (ENV.production) {
      return '';
    } else {
      return 'https://' + this.storage.get('brokerAddr');
    }
  }

}
