import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { BaseService } from './base.service';
import { StorageService } from './storage.service';

import { environment as ENV } from '../../environments/environment';

@Injectable()
export class ServiceLocatorService extends BaseService {

  constructor(
    private http: HttpClient,
    private storage: StorageService
  ) {
    super();
  }

  loadConfig(configPath: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(configPath, {responseType: 'json'}).subscribe(
        (data: {currentHost: string}) => {
          this.storage.setAll(data);

          let currentHost = this.storage.get('currentHost');
          if (!currentHost || currentHost.length === 0) {
            let el = document.createElement('a');
            el.href = window.location.href;
            this.storage.set('currentHost', el.hostname);
            data.currentHost = el.hostname;
          }
          resolve();
        },
        err => {
          reject(err);
        });
    });
  }

  getEndpoints(): Promise<any> {
    return new Promise((resolve, reject) => {
      // NOTE static endpoints are loaded when the locator config is loaded
      this.clearDynamicEndpoints();

      let locator = this.storage.get('locator');

      let headers = new HttpHeaders();
      headers.append('Accept', 'application/json');

      let accessToken = this.storage.get('accessToken');
      if (accessToken) {
        headers.append('Authorization', 'Bearer ' + accessToken);
      }

      this.http.get(locator.url, { headers: headers }).subscribe(
        res => {
          this.parseDynamicEndpoints(res);
          resolve();
        },
        err => {
          console.error('locsvc: error getting endpoints');
          reject(err);
        });
    });
  }

  private parseDynamicEndpoints(res: any) {
    let endpoints: any = {};
    let data = res.json();
    data.c4ServerEndpoint.forEach((sep) => {
      endpoints[sep.serviceName] = sep;
    });

    this.storage.set('dynamicEndpoints', endpoints);
  }

  getBrokerEndpoint(): string {
    let env = this.storage.get('env');
    if (!ENV.production) {
      return ('https://' + this.storage.get('brokerHost'));
    } else {
      return this.getEndpoint('REFLECTOR');
    }
  }

  getAccountEndpoint(): string {
    return this.getEndpoint('ACCOUNT');
  }

  getAuthenticationEndpoint(): string {
    return this.getEndpoint('AUTHENTICATION');
  }

  getStorageEndpoint(): string {
    return this.getEndpoint('STORAGE');
  }

  getEndpoint(key: string): string {
    let endpoints = this.storage.get('dynamicEndpoints');
    if (endpoints) {
      if (key !== 'REFLECTOR') {
        return endpoints[key].endPointURL || 'UNKNOWN';
      } else {
        let locator = this.storage.get('locator');
        if (locator.broker) {
          return endpoints[key].endPointURL || 'UNKNOWN';
        } else {
          let staticEndpoints = this.storage.get('staticEndpoints');
          return staticEndpoints[key] || 'UNKNOWN';
        }
      }
    }

    return 'UNKNOWN';
  }

  clearAllEndpoints() {
    this.storage.remove('staticEndpoints');
    this.clearDynamicEndpoints();
  }

  private clearDynamicEndpoints() {
    this.storage.remove('dynamicEndpoints');
  }

}
