import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';

import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { take, map, filter, shareReplay } from 'rxjs/operators';

import { Event } from '../../../common/interfaces/event.interface';
import { SharedProgrammingService } from '../../../common/services/shared-programming.service';
import { QuickstartProgrammingState } from '../../quickstarts.stores';
import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';

import { VoiceScenesService, VoiceScene } from '../voice-scenes.service';

@Component({
  selector: 'atm-vs-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.less']
})
export class VSEditComponent extends BaseRoutingComponent {

  public newName = new FormControl();
  public oldName: Observable<string>;
  private _scenes: Observable<VoiceScene[]>;
  public sceneExists: Observable<boolean>;
  public verifying: Observable<boolean>;
  public working: boolean = false;

  constructor(
    protected router: Router,
    protected store: Store<QuickstartProgrammingState>,
    private _sceneService: VoiceScenesService,
    private _shared: SharedProgrammingService
  ) {
    super();

    this._scenes = this._sceneService.getScenesObservable();

    this.oldName = this.store.select(s => s.sharedProgramming)
      .pipe(
        filter(shared => !!shared.trigger && !!shared.trigger.event && !!shared.trigger.event.name),
        map(shared => shared.trigger.event.name
          .replace('Turn On ', '')
          .replace('Turn Off ', ''))
      )

    this.sceneExists = combineLatest(
      this.newName.valueChanges,
      this._scenes
    )
      .pipe(
        map(([newName, scenes]) => scenes
          .map(s => s.$t)
          .indexOf(newName.replace(',', '')) > -1 && !this.working),
        shareReplay(1)
      );

    this.verifying = this.store.select(s => s.sharedProgramming.codeItemVerifiers).pipe(
      map(verifiers => !!verifiers && verifiers.length > 0)
    )
  }

  public async save() {
    const oldName = await this.oldName.pipe(take(1)).toPromise();
    const newName = this.newName.value.replace(',', '');

    this._shared.addCodeItemVerifier(event => {
      return newName == event.name
        .replace('Turn On ', '')
        .replace('Turn Off ', '')
    });

    this.working = true;    
    // need a flag to show a spinner since this seems to cause a slow response from broker
    await this._sceneService.renameVoiceScene(oldName, newName);
    this.working = false;

    this.goToEvent();
  }
}
