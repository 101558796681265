<div class="working" *ngIf="!(ready | async)">
  <div class="spacer"></div>
  <prg-working></prg-working>
</div>
<div *ngIf="(ready | async)" class="buttons">
  <ng-container *ngIf="(event | async)?.deviceId === 100100">
    <button class=" red clickable" [ngClass]="{'disabled': !(ready|async)}" (click)="showConfirmDeleteSchedule(true)"
      [disabled]="!(ready|async)"><span class="icon small white x"></span> Delete Schedule</button>
    <button class="blue clickable" [ngClass]="{'disabled': !(ready|async)}" (click)="showActions(false)" [routerLink]="['schedule']"
      [disabled]="!(ready|async)"><span class="icon small white edit"></span>Edit Schedule</button>
  </ng-container>
  <ng-container *ngIf="(driver | async)?.proxy == 'voice-scene'">
    <button class=" red clickable" [ngClass]="{'disabled': !(ready|async)}" (click)="showConfirmDeleteVoiceScene(true)"
      [disabled]="!(ready|async)">Delete Voice Scene</button>
    <button class=" clickable" [ngClass]="{'disabled': !(ready|async)}" (click)="showActions(false)" [routerLink]="['voice-scene']"
      [disabled]="!(ready|async)">Rename Voice Scene</button>
  </ng-container>
  <span class="grow"></span>
  <ng-container>
    <button class="red clickable" [ngClass]="{'disabled': (testDisabled|async)}" (click)="showConfirmDeleteAllActions(true)"
      [disabled]="(testDisabled|async)"><span class="icon small x" [ngClass]="{'medium-grey': (testDisabled|async), 'white': !(testDisabled|async)}"></span>
       <span class="label">Delete All Actions</span></button>
    <button class="blue clickable" [ngClass]="{'disabled': !(ready|async)}" [routerLink]="['../add']" [disabled]="!(ready|async)"><span
        class="icon white small plus"></span> Add Actions</button>
    <button class="blue clickable" [ngClass]="{'disabled': (testDisabled|async)}" (click)="runTest()" [disabled]="(testDisabled|async)">
      <span class="icon small eye" [ngClass]="{'medium-grey': (testDisabled|async), 'white': !(testDisabled|async)}"></span>
      Test
    </button>
    <button class="green clickable" [ngClass]="{'disabled': !(ready|async)}" (click)="close()" [disabled]="!(ready|async)"><span
        class="icon white small done"></span> Done</button>
  </ng-container>
</div>

<prg-confirm-modal *ngIf="_showConfirmDeleteSchedule" title="Confirm Delete" confirmLabel="Delete Schedule"
  confirmClass="red" (confirm)="deleteSchedule()" (cancel)="showConfirmDeleteSchedule(false)">
  <div message>
    <p class="wrap message">Are you sure you want to delete this schedule and any actions associated with it?</p>
  </div>
</prg-confirm-modal>

<prg-confirm-modal *ngIf="_showConfirmDeleteVoiceScene" title="Confirm Delete" confirmLabel="Delete Voice Scene"
  confirmClass="red" (confirm)="deleteVoiceScene()" (cancel)="showConfirmDeleteVoiceScene(false)">
  <div message>
    <p class="message">Are you sure you want to delete this voice scene? This will delete the voice scene, as well as
      all programming associated with the Turn On and Turn Off events.</p>
  </div>
</prg-confirm-modal>

<prg-confirm-modal *ngIf="_showConfirmDeleteAllActions" title="Confirm Delete" confirmLabel="Delete All Actions"
  confirmClass="red" (confirm)="deleteAllActions()" (cancel)="showConfirmDeleteAllActions(false)">
  <div message>
    <p class="message">Are you sure you want to delete all actions associated with this trigger? </p>
    <p class="message">NOTE: Automation created by your Control4 dealer will not be deleted. Please contact your dealer
      to have this automation changed or removed.</p>
  </div>
</prg-confirm-modal>

<prg-alert-modal title="Cannot Delete" *ngIf="_showAccessDeniedModal" (confirm)="_showAccessDeniedModal = false">
  <div message>
    <p class="message">This schedule contains automation created by your Control4 dealer and cannot be deleted here.
      Please contact your dealer for assistance.</p>
  </div>
</prg-alert-modal>

<prg-alert-modal title="Testing Automation" *ngIf="_showAlertModal" (confirm)="_showAlertModal = false">
  <div message>
    <p class="message">{{_message}}</p>
  </div>
</prg-alert-modal>
