<prg-nav-header title="Select a Custom Button" (cancel)="goHome()"></prg-nav-header>
<p class="help-text">The SR-260 System Remote has three custom buttons that can be used to trigger automation. These
  buttons are labeled with one, two and three dots. When pressed these buttons can cause a given command to be sent to
  the room that the remote is currently controlling. </p>
<p class="help-text">Select the custom button you want to use to trigger an action when the system remote is
  controlling the <strong>{{(room | async) | deviceLocation}}</strong>.</p>

<prg-block-button buttonClass="dark" *ngFor="let evt of (events | async), index as i" (click)="buttonSelected(evt)"
  leadingIcon="blue remote" [title]="evt.name" trailingIcon="grey small right">
  <div supportingContent><img src="assets/images/cus-but-0{{i+1}}.svg" class="cus_but_0{{i+1}}}"></div>
</prg-block-button>