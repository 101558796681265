import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';

import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { CommonProgrammingContext } from './../../../common/services/shared-programming.service';
import { LightsService } from './../services/lights.service';
import { LightSceneDetail } from './../services/interfaces/light.interface';
import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';
import { LightsProgrammingContext } from '../services/lights.service';

@Component({
  selector: 'prg-light-scene-command',
  templateUrl: './light-scene-command.component.html',
  styleUrls: ['./light-scene-command.component.less']
})
export class LightSceneCommandComponent extends BaseRoutingComponent implements OnInit {

  private _adding = false;
  _scene: Observable<LightSceneDetail>;

  constructor(
    protected router: Router,
    protected store: Store<{
      lightsProgramming: LightsProgrammingContext,
      sharedProgramming: CommonProgrammingContext
    }>,
    private _lights: LightsService,
    private _route: ActivatedRoute
  ) {
    super();
    this._route.params.subscribe(params => {
      console.log('scene command route params', params);
      const id = parseInt(params.id, 10);
      if (!isNaN(id) && id >= 0) {
        this._lights.getScene(id);
      }
    });

    this._scene = this.store.select(s => s.lightsProgramming.action.scene);
  }

  ngOnInit() {
  }

  addCommand(command: string) {
    if (!this._adding) {
      this._adding = true;
      this._lights.setSceneCommand(command);
      /**
       * TODO parallel implementation #2
       * @see ../../quickstart-led-colors/select-action/select-action.component.ts
       */
      this.store.select(s => s.lightsProgramming.action.sceneCommand)
        .pipe(
          filter(c => !!c && c.command === command),
          take(1)
        )
        .subscribe(data => {
          this.router.navigate(['conditionals'], { relativeTo: this._route });
        });
    }
  }
}
