import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { BrokerService } from '@when-then/core';

const STORE_NAME: string = 'DEVICE_ICONS';
export const DEVICE_ICONS_UPDATE: string = STORE_NAME + '_UPDATE';

export function deviceIconsReducer(state: any, { type, payload }) {
  switch (type) {
    case DEVICE_ICONS_UPDATE:
      return payload;

    default:
      return state;
  }
};

@Injectable()
export class DeviceIconsService {
  deviceIcons: Observable<any>;

  constructor(
    private store: Store<any>,
    private broker: BrokerService
  ) {
    this.deviceIcons = this.store.select('deviceIcons');
  }

  getIcons(): Promise<any> {
    return this.broker.call({
      path: '/api/v1/items/icons'
    }).then(
      (data: any) => {
        var icons: any = {};
        data.forEach((icon: any) => {
          icons[icon.id] = icon;
        });
        this.store.dispatch({ type: DEVICE_ICONS_UPDATE, payload: icons });
      },
      err => {
        this.store.dispatch({ type: DEVICE_ICONS_UPDATE, payload: {} });
      });
  }
}
