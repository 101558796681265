import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { UIModule } from '@when-then/ui';
import { QuickstartCommonModule } from './../quickstart-common/quickstart-common.module';
import { ProgrammingCommonModule } from '../../common/common.module';

import { LEDColorsActionComponent } from './led-colors-action/led-colors-action.component';
import { LEDColorsSelectParametersComponent } from './select-parameters/select-parameters.component';
import { LEDColorsActionTypeComponent } from './led-colors-action-type/led-colors-action-type.component';
import { LEDColorsSelectActionComponent } from './select-action/select-action.component';
import { LEDColorsService } from './services/led-colors.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    UIModule,
    ProgrammingCommonModule,
    QuickstartCommonModule
  ],
  providers: [
    LEDColorsService
  ],
  declarations: [
    LEDColorsActionComponent,
    LEDColorsActionTypeComponent,
    LEDColorsSelectParametersComponent,
    LEDColorsSelectActionComponent,
    LEDColorsActionTypeComponent
  ]
})
export class QuickstartLEDColorsModule { }
