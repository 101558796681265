import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { Device, Item } from './../../../common/interfaces/item.interface';
import { ItemsService } from './../../../common/services/items.service';
import { LEDColorsService } from './../services/led-colors.service';
import { LEGACY_KEYPAD_PROXIES } from '../../quickstart-keypads/services/keypads.service';

import { SharedProgrammingService, CommonProgrammingContext } from '../../../common/services/shared-programming.service';
import { ProgrammingUtilsService as Utils } from './../../../common/services/utils.service';
import { OSCompatibilityService } from './../../../common/services/os-compatibility.service';
import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';
import { Room } from '../../quickstart-lights/services/interfaces/room.interface';

@Component({
  selector: 'atm-led-colors-action',
  templateUrl: './led-colors-action.component.html',
  styleUrls: ['./led-colors-action.component.less']
})
export class LEDColorsActionComponent extends BaseRoutingComponent implements OnInit {

  private _rooms: Observable<Room[]>;

  ledDevices: Observable<Item[]>;

  @Input() rowClass: string;
  @Input() title: string;

  @Output() selected: EventEmitter<Device>;

  supportsLegacyKeypads: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private ledColorsService: LEDColorsService,
    private osCompatibilityService: OSCompatibilityService,
    protected store: Store<any>,
    protected router: Router,
    private items: ItemsService
  ) {
    super();
    this._rooms = this.ledColorsService.actionLEDDevicesByRoom;
    this.ledDevices = this.ledColorsService.actionLEDDevices;
    this.rowClass = '';
    this.title = '';
    this.selected = new EventEmitter<Device>();
    this.supportsLegacyKeypads = this.osCompatibilityService.isFeatureSupported('action', 'legacy-keypad-led-colors');
  }

  ngOnInit() {
    this.ledDevices = this.items.itemsList
      .pipe(map(items => {
        return items.filter((item: any) => {
          return ((item.type === 7 && this.isSupportedProxy(item)));
        })
      })
      );
  }

  deviceSelected(device: Device) {
    console.log('led-color-action.deviceselected: ', device);
    this.ledColorsService.setActionLEDDevice(device);
    this.router.navigate(['../../device/' + device.id], { relativeTo: this.route });
  }

  // check for OS compatibility for legacy keypads
  private isSupportedProxy(item: any): boolean {
    return ((item.proxy === 'keypad_proxy') ||
      (this.supportsLegacyKeypads && ([...LEGACY_KEYPAD_PROXIES].indexOf(item.proxy) >= 0)) ||
      (['light_v2', 'light', 'fan'].indexOf(item.proxy) >= 0));
  }
}
