import { KeypadProgrammingContext } from './quickstarts/quickstart-keypads/services/keypads.service';
/**
 * Copyright 2017 Control4 Corporation. All rights reserved.
 * Control4 Confidential and Proprietary Information.
 */

import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { trigger, state, transition, style, animate } from '@angular/animations';

import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { StorageService, MonitoredHttpState, JWT_STATUS, BrokerService, LoggerFactory, LogLevel } from '@when-then/core';

import { environment } from '../environments/environment';

import { ProgrammingEventsState, EventsService } from './common/services/events.service';
import { ProgrammingUtilsService } from './common/services/utils.service';
import { SchedulesService } from './quickstarts/quickstart-schedule/schedule.service';

import { SharedProgrammingService, CommonProgrammingContext } from './common/services/shared-programming.service';
import { BaseRoutingComponent } from './common/base-routing/base-routing.component';
import { HttpClient } from '@angular/common/http';

enum KeyCodes {
  Shift = 16,
  Ctrl = 17,
  Alt = 18
}

@Component({
  selector: 'prg-programming',
  templateUrl: './programming.component.html',
  styleUrls: ['./programming.component.less'],
  animations: [
    trigger('toggleDevBar', [
      state('hidden', style({ bottom: '*' })),
      state('shown', style({ bottom: '0px' })),
      transition('shown <=> hidden', [
        animate(250), animate(250)
      ])
    ])
  ]
})
export class ProgrammingComponent extends BaseRoutingComponent implements OnInit {

  logging = {
    level: LoggerFactory.defaultLogLevel,
    interval: 5,
    units: 60000
  };

  _showLoggingDialog = false;

  private _logger = LoggerFactory.getLogger(ProgrammingComponent.name);
  events: Observable<Array<any>>;
  scheduleEvents: Observable<Array<any>>;
  initialized: Observable<boolean>;

  ready: Observable<boolean>;
  errors: Observable<Array<any>>;

  adding: Observable<boolean>;
  editing: Observable<boolean>;

  wtVersion: string;
  osVersion: string;

  _jwtStatus: Observable<JWT_STATUS>;
  JWT_STATUS = JWT_STATUS;

  LOGLEVEL = LogLevel;

  public devBar: 'shown' | 'hidden' = 'hidden';
  public env = environment;

  constructor(
    protected router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    protected store: Store<{
      sharedProgramming: CommonProgrammingContext,
      // NOTE loosely typed here because of a potential circular dependency
      scheduleProgramming: any,
      programmingEvents: ProgrammingEventsState,
      monitoredHttpState: MonitoredHttpState
    }>,
    private utils: ProgrammingUtilsService,
    protected shared: SharedProgrammingService,
    private schedule: SchedulesService,
    private storage: StorageService,
    // NOTE this is injected here to ensure that the list of events is populated for downstream consumers
    private eventsService: EventsService,
    private broker: BrokerService
  ) {
    super();
    this.events = this.store.select(s => s.programmingEvents.programmedEvents);
    this.scheduleEvents = this.store.select(s => s.scheduleProgramming.scheduleEvents);
    this.ready = this.store.select(s => s.sharedProgramming.ready);
    this.errors = this.store.select(s => s.sharedProgramming.errors);

    // NOTE intro screen is now required
    this.storage.remove('skipWelcome');

    this._jwtStatus = this.store.select(s => s.monitoredHttpState.jwtStatus);

    // store module root centrally in shared service so it can route if necessary
    this.shared.moduleRoot = this.route;

    this.wtVersion = 'loading...';
    this.http.get('build_version.txt', {responseType: 'text'}).subscribe((wtVersion: string) => {
      this.wtVersion = wtVersion;
      this.storage.set('wtVersion', this.wtVersion);
    }, err => {
      this.wtVersion = 'unknown';
    });

    this.osVersion = 'loading...';
    this.broker.call({
      path: '/api/v1/version'
    }).then(versions => {
      const director = versions.find(v => v.name === 'Director');
      if (!!director && !!director.version) {
        this.osVersion = director.version.substring(0, director.version.indexOf('-'));
      } else {
        this.osVersion = 'unknown';
      }
      // save OS Version for use in determining broker functionality
      this.shared.setOSVersion(this.osVersion);
    }, err => {
      this.osVersion = 'unknown';
    });

    this._logger.info('environment is', environment);
  }

  ngOnInit() {
  }

  public logState = () => {
    this.store.pipe(take(1)).subscribe(state => this._logger.info(state));
  }

  public showDev() {
    this.devBar = 'shown';
  }

  public hideDev() {
    this.devBar = 'hidden';
  }

  public versionClicked(event: MouseEvent) {
    if (!environment.production) {
      this._showLoggingDialog = true;
    }
  }

  elevateLogging() {
    this._showLoggingDialog = false;
    LoggerFactory.elevateLogging(this.logging.level, (this.logging.interval * this.logging.units));
    this._logger.debug('Logging level elevated.');
  }
}
