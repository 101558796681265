import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'eventDisplay'
})
export class EventDisplayPipe implements PipeTransform {
  transform(event: any): string {
    return (event.display || event.name || 'UNKNOWN');
  }
}
