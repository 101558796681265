import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BrokerService } from '@when-then/core';
import { ProgrammingUtilsService as Utils } from './utils.service';
import { Item } from '../interfaces/item.interface';

const REQUIRED_FIELDS = [
  'id', 'type', 'control', 'protocol', 'protocolControl', 'proxy', 'protocolFilename',
  'protocolName', 'protocolId', 'filename', 'capabilities', 'name', 'roomName', 'roomId',
  'floorName', 'floorId', 'floorOrder', 'buildingName', 'buildingId', 'buildingOrder',
  'siteName', 'siteId', 'siteOrder', 'categories', 'proxyMeta', 'typeName', 'compareStatus',
  'parentId', 'URIs', 'version', 'commands', 'events'
];

declare var _: any;

export interface ItemsState {
  itemsList: Item[];
  itemsMap: Map<number, Item>;
  ready: boolean
}

const INITIAL_STATE: ItemsState = {
  itemsList: [],
  itemsMap: new Map<number, Item>(),
  ready: false
};

const STORE_NAME = 'PROGRAMMING:ITEMS:';
export const SET_ITEMS = STORE_NAME + 'SET_ITEMS';

export function itemsReducer(state: ItemsState = INITIAL_STATE, { type, payload }): ItemsState {

  switch (type) {
    case SET_ITEMS:
      return Object.assign({}, state, { itemsList: payload.itemsList, itemsMap: payload.itemsMap, ready: true });
  }

  return state;
};

@Injectable()
export class ItemsService {

  ready: Observable<boolean>;
  itemsList: Observable<Item[]>;

  private _itemsMap: Observable<Map<number, Item>>;

  constructor(
    private store: Store<{programmingItems: ItemsState}>,
    private broker: BrokerService
  ) {
    this.broker.getObservable<Item[]>({
      path: '/api/v1/items',
      queryString: {
        fields: REQUIRED_FIELDS.join(',')
      }
    }).subscribe(items => {
      this.handleUpdates(items);
    });

    this.ready = this.store.select(s => s.programmingItems.ready);
    this._itemsMap = this.store.select(s => s.programmingItems.itemsMap);
    this.itemsList = this.store.select(s => s.programmingItems.itemsList);
  }

  getItem(id: number): any {
    return Utils.snapshot<Array<Item>>(this.itemsList).find(itm => itm.id === id);
  }

  getRoom(roomId: number): any {
    let items: Item[] = Utils.snapshot<Item[]>(this.itemsList);
    return items.filter(item => item.id === roomId && item.type === 8)[0];
  }

  // getAgent
  // getLocation
  // get...

  private handleUpdates(updates: Array<any>) {

    let map = Utils.snapshot<Map<number, Item>>(this._itemsMap);

    // NOTE items updates are incremental so we have to add or update the existing list
    // easiest to do this by maintaining a map by id
    if (!!updates && Array.isArray(updates)) {
      updates.forEach(item => {
        // console.log('items: handling update item', item);
        if (item.compareStatus) {
          // console.log('items: checking compare status', item.compareStatus);
          switch (item.compareStatus) {
            case 'removed':
              let deleted = map.delete(item.id);
              // console.log('items: deleted item', item, deleted, itemsMap);
              break;
            case 'changed':
            case 'added':
              map.set(item.id, item);
              // console.log('items: added incremental item', item, itemsMap);
              break;
            default: console.warn('unrecognized compare status on item update', item);
          }
        } else {
          // NOTE assume this is a first fetch, everything is new
          map.set(item.id, item);
          // console.log('items: initial update, added new item', item);
        }
      });
    }

    this.store.dispatch({ type: SET_ITEMS, payload: { itemsList: Array.from(map.values()), itemsMap: map } });
  }
}
