import { EventType } from './event-type.interface';

export const DOORSTATION_SECURITY: EventType = {
  name: 'Doorstation',
  description: 'When a user code is entered on a doorstation keypad',
  route: 'doorstation-security',
  category: 'Security & Access',
  icon: 'keypad',
  order: 3,
  whitelist: [
    {
      fields: { proxy: 'doorstation', protocolFilename: 'control4_doorstation_ds2.c4z' },
      events: [25, 26, 27, 28, 29, 30, 31, 31, 32, 33, 34, 35, 36]
    }
  ]
}
