import { UIModule } from '@when-then/ui';
import { RoleBasedAuthorizationProvider } from './strategies/rbac-provider';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { CookieModule } from 'ngx-cookie';
import { ActionReducerMap } from '@ngrx/store';

import { CoreModule } from '@when-then/core';
import { AssetsModule } from '@when-then/assets';

import { AuthenticationComponent } from './authentication.component';
import { AuthenticationService, authenticationReducer } from './services/authentication.service';
import { AuthenticationState } from './services/authentication-state';
import { AuthenticationGuard } from './services/authentication.guard';
import { Local } from './strategies/local';

import { AuthorizationService, authorizationReducer, AuthorizationState } from './services/authorization.service';
import { AuthorizationGuard } from './services/authorization.guard';
import { IdentityService, identityReducer, IdentityState } from './services/identity.service';

export interface AuthState {
  authentication: AuthenticationState,
  authorization: AuthorizationState,
  identity: IdentityState
}

export const AUTH_REDUCERS: ActionReducerMap<AuthState> = {
  authentication: authenticationReducer,
  authorization: authorizationReducer,
  identity: identityReducer
};

@NgModule({
  declarations: [
    AuthenticationComponent,
  ],
  imports: [
    AssetsModule,
    BrowserModule,
    FormsModule,
    CoreModule,
    RouterModule,
    UIModule,
    CookieModule.forRoot()
  ],
  exports: [
    AuthenticationComponent,
  ],
  providers: [
    AuthenticationService,
    AuthenticationGuard,
    AuthorizationService,
    AuthorizationGuard,
    IdentityService,
    RoleBasedAuthorizationProvider
  ]
})
export class AuthModule { }
