import {Pipe, PipeTransform} from '@angular/core';
import {Store} from '@ngrx/store';

@Pipe({
  name: 'nameFilter'
})
export class NameFilterPipe implements PipeTransform {

  constructor(
    private store: Store<any>
  ) { }

  transform(items: any, text: string): Array<any> {
    if (text && items && Array.isArray(items)) {
      return items.filter((item: any) => {
        return (item.display.trim().toLowerCase().indexOf(text.trim().toLowerCase()) >= 0);
      });
    } else {
      return items;
    }
  }
}
