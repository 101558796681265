import { Pipe, PipeTransform } from '@angular/core';
import { Location } from './../interfaces/item.interface';
import { Device } from '../interfaces/item.interface';

@Pipe({
  name: 'customButtonsMatchingFloor'
})
export class CustomButtonsMatchingFloorPipe implements PipeTransform {

  transform(devices: Device[], args?: any): any {
    if (args !== 'any') {
      let floor: Location = args;
      if (devices && floor) { // always return screens that apply to all rooms
        return devices.filter(dev => { return (dev.floorId === floor.floorId) || (dev.floorId === 0) });
      }
    }

    return devices;
  }
}

