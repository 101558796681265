import { ScheduleViewModel, IndexedValueLabelPair, Schedules, ScheduleEvent } from "./schedule.interfaces";

import * as moment from 'moment';

export const DEFAULT_SCHEDULE_VIEW_MODEL: ScheduleViewModel = {
    eventId: 0,
    name: '',
    startDateType: 'fixed',
    startDate: moment().format('YYYY-MM-DD'),
    startTimeType: 'fixed',
    startTime: moment().format('HH:mm'),
    sunriseOrSunset: 'sunrise',
    sunriseSunsetInterval: 15,
    sunriseSunsetOffsetType: 'at',
    repeatDays: 0,
    repeatFrequency: 1,
    repeatType: 'every',
    repeatRate: 1,
    endDate: moment().format('YYYY-MM-DD'),
    isRepeating: true,
    isRandomized: false,
    randomizeBy: 0,
    supportsInterval: false,
    startOffsetMinutes: 0,
    startWeekOffset: Math.floor(moment().date() / 7),
    startDayOfWeek: moment().weekday(),
    startMonth: moment().month(),
    startYear: moment().year(),
};


export const WEEK_OFFSETS: IndexedValueLabelPair[] = [{
    label: 'first', option: 'First', short: '1st'
}, {
    label: 'second', option: 'Second', short: '2nd'
}, {
    label: 'third', option: 'Third', short: '3rd'
}, {
    label: 'fourth', option: 'Fourth', short: '4th'
}, {
    label: 'last', option: 'Last', short: 'Last'
}];

export const WEEK_DAYS: IndexedValueLabelPair[] = [{
    label: 'Sunday', short: 'Sun', option: 'Sunday', value: 0
}, {
    label: 'Monday', short: 'Mon', option: 'Monday', value: 1
}, {
    label: 'Tuesday', short: 'Tue', option: 'Tuesday', value: 2
}, {
    label: 'Wednesday', short: 'Wed', option: 'Wednesday', value: 3
}, {
    label: 'Thursday', short: 'Thu', option: 'Thursday', value: 4
}, {
    label: 'Friday', short: 'Fri', option: 'Friday', value: 5
}, {
    label: 'Saturday', short: 'Sat', option: 'Saturday', value: 6
}];

export const MONTHS: IndexedValueLabelPair[] = [{
    label: 'January', option: 'January', short: 'Jan'
}, {
    label: 'February', option: 'February', short: 'Feb'
}, {
    label: 'March', option: 'March', short: 'Mar'
}, {
    label: 'April', option: 'April', short: 'Apr'
}, {
    label: 'May', option: 'May', short: 'May'
}, {
    label: 'June', option: 'June', short: 'Jun'
}, {
    label: 'July', option: 'July', short: 'Jul'
}, {
    label: 'August', option: 'August', short: 'Aug'
}, {
    label: 'September', option: 'September', short: 'Sept'
}, {
    label: 'October', option: 'October', short: 'Oct'
}, {
    label: 'November', option: 'November', short: 'Nov'
}, {
    label: 'December', option: 'December', short: 'Dec'
}];

export const REPEAT_FREQUENCIES = [
    'Day(s)',
    'Week(s)',
    'Month(s)',
    'Year(s)'
];

export const SUNRISE_SUNSET_OFFSETS: IndexedValueLabelPair[] = [{
    label: 'Before Sunrise', value: 'before:rise'
}, {
    label: 'At Sunrise', value: 'at:rise'
}, {
    label: 'After Sunrise', value: 'after:rise'
}, {
    label: 'Before Sunset', value: 'before:set'
}, {
    label: 'At Sunset', value: 'at:set'
}, {
    label: 'After Sunset', value: 'after:set'
}];

const now = moment();

export const DEFAULT_SCHEDULE_EVENT: ScheduleEvent = {
    buttonbindingid: 0,
    creatorId: 3,
    eventId: 0,
    deviceId: 100100,
    creatorState: '',
    hidden: false,
    display: '',
    subtitle: '',
    start: {
        offset: 0,
        offset_minutes: (now.hour() * 60) + now.minute(),
        start_date: {
            start_day: now.day(),
            start_month: now.month(),
            start_period: 0,
            start_weekday: 0,
            start_year: now.year()
        },
        randomize: 0,
    }
};


export const schedulesInitialState: Schedules = {
    // fill it initial state here
    ready: false,
    scheduleEvents: []
};

