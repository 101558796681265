import { CodeItemType } from './../../common/interfaces/item.interface';
import { SharedProgrammingService, CommonProgrammingContext, SET_HAS_CONDITIONAL, SET_CONDITIONAL_TYPE, SET_CONDITIONAL, SET_CONDITIONAL_DEVICE, CommandData } from "../../common/services/shared-programming.service";
import { LoggerFactory, BrokerService } from "@when-then/core";
import { Injectable } from "@angular/core";
import { Conditional } from "../../common/interfaces/conditional.interface";
import { AGENTS } from "../../common/services/agent-meta.service";
import { SchedulesService } from "../quickstart-schedule/schedule.service";
import { Event } from "../../common/interfaces/event.interface";
import { ProgrammingUtilsService as Utils, CW_CREATOR_ID, ItemsService, ItemsState } from "../../common/services";
import { Store } from "@ngrx/store";
import { ConditionalType } from "./conditional-types/conditional-type.interfaces";
import { Observable } from "rxjs";
import { Device } from "../../common/interfaces/item.interface";
import { Parameter } from "../../common/interfaces/parameter.interface";

export interface FlowControlViewModel {
    id: number;
    delay: {
        interval: number;
        units: string;
    }
}

@Injectable()
export class FlowControlService {
    private _logger = LoggerFactory.getLogger(FlowControlService.name);

    hasConditional: Observable<boolean>;
    pendingAction: Observable<CommandData>;
    conditionalType: Observable<ConditionalType>;
    innerActionDevice: Observable<Device>;
    conditional: Observable<Conditional>;

    constructor(
        private shared: SharedProgrammingService,
        private scheduler: SchedulesService,
        private store: Store<{
            sharedProgramming: CommonProgrammingContext,
            programmingItems: ItemsState
        }>,
        private items: ItemsService,
        private broker: BrokerService
    ) {
        this.hasConditional = this.store.select(s => s.sharedProgramming.action.hasConditional);
        this.pendingAction = this.store.select(s => s.sharedProgramming.action.pendingAction);
        this.conditionalType = this.store.select(s => s.sharedProgramming.action.condType);
        this.innerActionDevice = this.store.select(s => s.sharedProgramming.action.device);
        this.conditional = this.store.select(s => s.sharedProgramming.action.condCmd);
    }

    async setConditionalType(type: ConditionalType) {
        if (!!type) {
            this.store.dispatch({ type: SET_CONDITIONAL_TYPE, payload: type });
            if (!!type.deviceId) {
                const dev = this.items.getItem(type.deviceId);
                if (!!dev) {
                    this.store.dispatch({ type: SET_CONDITIONAL_DEVICE, payload: dev });
                }
            }
        }
    }

    async saveConditionalAction(cond: Conditional, parentCodeItemId?: number): Promise<any> {
        try {
            this._logger.debug('saving conditional', cond, parentCodeItemId);
            const event = Utils.snapshot<Event>(this.store.select(s => s.sharedProgramming.trigger.event));
            this._logger.debug('event is', event);

            const data = Utils.buildCommandData(CodeItemType.Conditional, cond);
            this._logger.debug('codeitem is', data);
            data.creatorState = Utils.buildCreatorState(event, data);

            let path = `/api/v1/items/${event.deviceId}/events/${event.eventId}/conditionals`;
            this._logger.debug('path is', path);

            const res = await this.broker.call({
                method: 'POST',
                path: path,
                data: data
            });

            this._logger.debug('conditional save response', res);
            if (!!res) {
                this.shared.addCodeItemVerifier(res);
                const action = Utils.snapshot<CommandData>(this.pendingAction);
                this._logger.debug('delegating to shared service to save inner action', action);
                return this.shared.saveAction(action, res.codeItem.codeItemId);

            } else {
                this._logger.error('invalid response from conditional save call');
                return Promise.reject({ message: 'An error occurred while saving the conditional for this action.' });
            }
        } catch (e) {
            this._logger.error('conditional save error', e);
            return Promise.reject(e);
        }
    }

    setHasConditional(state: boolean) {
        this.store.dispatch({ type: SET_HAS_CONDITIONAL, payload: state });
    }

    async selectConditonal(name: string) {
        this._logger.debug('selecting conditional for name', name);
        try {
            const ct = Utils.snapshot<ConditionalType>(this.conditionalType);
            this._logger.debug('current conditional type', ct);
            if (!!ct) {
                // NOTE special case
                if (!!ct.deviceId && ct.deviceId === AGENTS.SCHEDULER.ID) {
                    const list = await this.broker.call({
                        path: '/api/v1/agents/scheduler/conditionals'
                    });
                    this._logger.debug('scheduler conditionals', list);

                    const cnd = list.find(c => c.name === name);
                    this._logger.debug('found conditional', cnd);
                    if (!!cnd) {
                        this.store.dispatch({ type: SET_CONDITIONAL, payload: cnd });
                    }
                } else {
                    const dev = this.items.getItem(ct.deviceId);
                    // TODO but not yet
                }
            }

        } catch (e) {
            this._logger.error(JSON.stringify(e));
        }
    }

    _transformParams(params: Parameter[]): any[] {
        if (!!params) {
            params.forEach(p => {
                switch (p.type) {
                    case 'TIMEOFDAY':
                        const parts = p.value.toString().split(':').map(v => parseInt(v));
                        p.value = ((parts[0] * 60) + parts[1]);
                        break;
                }
            });

            return params.map(p => ({ name: p.name, value: p.value }))
        }
        return null;
    }
}
