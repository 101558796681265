<cxWait [cxUntil]="!!(supportedActions | async)" cxText="Loading...">
  <prg-nav-header title="Select one of the following actions to be added when this event takes place" (cancel)="goToEvent()"></prg-nav-header>
  <section>

    <div class="list grow">

      <prg-block-button *ngFor="let item of (supportedActions | async | sortBy:'order')" [routerLink]="['../' + item.route]"
        [leadingIcon]="item.icon" trailingIcon="right" [title]="item.label"></prg-block-button>

      <prg-block-button [routerLink]="['../rooms/ROOM_OFF']" title="Turn off all audio and video playing in a room"
        leadingIcon="icon room small" trailingIcon="right"></prg-block-button>
      <!-- <prg-block-button *ngIf="_movieSupported | async" [routerLink]="['../media/movie']" title="Play a movie from your collection"
        leadingIcon="icon watch small" trailingIcon="right"></prg-block-button> -->
      <prg-block-button *ngIf="_broadcastSupported | async" [routerLink]="['../media/broadcast_video']" title="Play a specific TV station"
        leadingIcon="icon watch small" trailingIcon="right"></prg-block-button>
      <prg-block-button [routerLink]="['../media/SELECT_VIDEO_DEVICE']" title="Select a specific video source in a room"
        leadingIcon="icon watch small" trailingIcon="right"></prg-block-button>
      <prg-block-button
        *ngIf="_stationSupported | async"
        [routerLink]="['../media/broadcast_audio']"
        title="Play a radio station"
        leadingIcon="icon song small"
        trailingIcon="right"></prg-block-button>
      <prg-block-button
        *ngIf="_stationSupported | async"
        title="Play something from a streaming service"
        [routerLink]="['../media/streaming']"
        leadingIcon="icon song small"
        trailingIcon="right"></prg-block-button>
      <prg-block-button *ngIf="_albumSupported | async" [routerLink]="['../media/album']" title="Play an album from your collection"
        leadingIcon="icon song small" trailingIcon="right"></prg-block-button>
      <prg-block-button *ngIf="_playlistSupported | async" [routerLink]="['../media/playlist']" title="Play one of your Playlists"
        leadingIcon="icon song small" trailingIcon="right"></prg-block-button>
      <prg-block-button [routerLink]="['../media/SELECT_AUDIO_DEVICE']" title="Select a specific audio source in a room"
        leadingIcon="icon music small" trailingIcon="right"></prg-block-button>
      <prg-block-button *ngIf="hasOSSupportForRemotes && (_findRemoteSupported | async)" [routerLink]="['../media/remotes']"
        title="Find a System Remote" leadingIcon="icon remote small" trailingIcon="right"></prg-block-button>
      <prg-block-button *ngIf="_thermostatSupported | async" [routerLink]="['../thermostats/select']" title="Change a Thermostat Setting"
        leadingIcon="icon thermostat small" trailingIcon="right"></prg-block-button>
      <prg-block-button [routerLink]="['../flow/delay']" title="Wait for a period of time" leadingIcon="icon intervals small"
        trailingIcon="right"></prg-block-button>

    </div>
  </section>
</cxWait>
