<!--
/**
 * Copyright 2017 Control4 Corporation. All rights reserved.
 * Control4 Confidential and Proprietary Information.
 */
-->
<div class="container">
  <div class="loading" *ngIf="!(guardsChecked | async)">
    <div class="fading text" id="text0">Preparing the experience.</div>
  </div>
  <router-outlet></router-outlet>
</div>
