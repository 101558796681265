import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'friendlyProxyName'
})
export class FriendlyProxyNamePipe implements PipeTransform {

  // TODO need to be able to reverse correlate these with the proxy name
  transform(item: any): string {
    switch (item.proxy) {
      case 'control4_thermostat':
      case 'control4_thermostat_proxy':
        return 'Thermostat';
      case 'control4_hc200':
      case 'control4_hc300v2':
      case 'control4_hc800':
        return 'Home Controller';
      case 'control4_iox':
        return 'I/O Extender';
      case 'relaycontact_garagedoor_c4':
      case 'contactsingle_doorcontactsensor_c4':
      case 'contactsingle_doorbell_c4':
      case 'contactsingle_glassbreakdetector_c4':
      case 'contactsingle_windowcontactsensor_c4':
      case 'contactsingle_smokedetector_c4':
      case 'contactsingle_heatdetector_c4':
      case 'contact_cardaccess_sps10_driverworks':
        return 'Contact Sensor';
      case 'eragy_meter_episensor_zem30':
        return 'Energy Sensor';
      case 'control4_sr250':
        return 'System Remote Control';
      case 'contactsingle_motionsensor_c4':
        return 'Motion Sensor';
      case 'relaysingle_relay_c4':
      case 'relaysingle_drapes_c4':
      case 'cardaccess_wirelessrelay':
      case 'relaysingle_gasfireplace_c4':
        return 'Relay Sensor';
      case 'Lock_Zigbee_Kwikset_SmartLock_SmartLock _DriverWorks__Kwikset':
      case 'relaysingle_doorlock_c4':
      case 'Lock_Zigbee_Baldwin_SmartLock':
      case 'DriverWorks_BlackDecker_Lock':
      case 'DriverWorks_BlackDecker_Lock_SmartLock RF Zigbee_Black and Decker':
      case 'Lock_Zigbee_Kwikset_SmartLock':
      case 'Lock_ZigBee_Yale':
      case 'lock':
        return 'Lock';
      case 'camera_ip_http_URL':
      case 'camera_ip_http_securityspy_Camera3':
      case 'camera_ip_http_securityspy_Camera0':
      case 'camera_ip_http_securityspy_Camera1':
      case 'camera_ip_http_securityspy_Camera2':
      case 'camera_ip_http_securityspy_Camera4':
      case 'camera_ip_http_securityspy_Camera5':
      case 'camera_ip_http_foscam_8918w':
        return 'Camera';
      case 'rf_directv':
        return 'Satellite TV';
      case 'rf_cable':
        return 'Cable TV';
      case 'control4_esata_mediastorage':
        return 'Media Storage';
      case 'rf_fm':
        return 'FM Radio';
      case 'rf_am':
        return 'AM Radio';
      case 'rf_xm':
      case 'sirius':
        return 'Satellite Radio';
      case 'rf_internet':
        return 'Internet Radio';
      case 'dvd':
        return 'DVD Player';
      case 'light_ip_control4_ldz-5s1-w':
      case 'light_ip_control4_ldz-102-w':
      case 'light_ip_control4_ldz-101-w':
        return 'IP-controlled Lighting';
      case 'blind_ir':
      case 'blind':
        return 'Blinds';
      case 'light':
      case 'light_v2':
        return 'Light';
      case 'outlet_wireless_dimmer':
        return 'Outlet Dimmer';
      case 'outlet_wireless':
      case 'outlet_light':
        return 'Outlet Light';
      case 'rf_uhf_vhf':
        return 'UHF/VHF Video';
      case 'avswitch_ip_control4':
      case 'avswitch':
        return 'Audio/Video Switch'
      case 'aswitch':
        return 'Audio Switch';
      case 'media_service':
        return 'Media Service';
      case 'vcr':
        return 'VCR';
      case 'tunerXM':
      case 'tuner':
        return 'Audio Tuner';
      case 'receiver':
        return 'Audio/Video Receiver'
      case 'tv':
      case 'plasma':
        return 'Television'
      case 'globalcache_gc100_06':
        return 'Global Cache';
      case 'control4_keypad_kpz-11-w': // support legacy keypads started in OS 2.10.3
      case 'control4_keypad_kpz-31-w':
      case 'control4_keypad_kpz-61-w':
      case 'keypad_proxy':
        return 'Keypad';
      default:
        return item.protocolName || item.proxy;
    }
  }
}
