import { Injectable } from '@angular/core';

import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { BrokerService } from '@when-then/core';

import { EventType } from './event-types/event-type.interface';
import { EventTypes } from './event-types/event-types';
import { Device } from '../../common/interfaces/item.interface';
import { DeviceEvent } from '../../common/interfaces/event.interface';
import { SharedProgrammingService } from '../../common/services/shared-programming.service';
import { ItemsService } from '../../common/services/items.service';

export interface SimpleProgrammingContext {
  devices?: Device[];
}

const INITIAL_STATE: SimpleProgrammingContext = {};

const STORE_NAME = ['PROGRAMMING', 'QUICKSTARTS', 'SIMPLE'].join(':');

export const SIMPLE_ACTIONS = {
  SET_DEVICES: `${STORE_NAME}:SET_DEVICES`,
  CLEAR: `${STORE_NAME}:CLEAR`
}

const SIMPLE_ACTION_STRINGS = Object.keys(SIMPLE_ACTIONS).map(key => SIMPLE_ACTIONS[key]);

export function simpleProgrammingReducers(state: SimpleProgrammingContext = INITIAL_STATE, { type, payload }): SimpleProgrammingContext {

  // if (SIMPLE_ACTION_STRINGS.indexOf(type) > -1) {
  //   console.debug('DEBUG >> %s called with %O', type, payload);
  // }

  switch (type) {

    case SIMPLE_ACTIONS.SET_DEVICES:
      return Object.assign({}, state, { devices: payload });

    case SIMPLE_ACTIONS.CLEAR:
      return INITIAL_STATE;

    default:
      return state;
  }
}

@Injectable()
export class QSSimpleService {

  public supportedProxies: string[] = EventTypes.reduce((acc, curr) => {
    return acc.concat(...curr.whitelist.map(w => w.fields['proxy']));
  }, []);

  private _subscription: Subscription;

  constructor(
    private _store: Store<{
      simpleProgramming: SimpleProgrammingContext
    }>,
    private _broker: BrokerService,
    private _items: ItemsService
  ) {
    this._subscription = this._items.itemsList
      .pipe(
        map(items => {
          return items.filter((item: Device) => {
            return [7, 8].indexOf(item.type) > -1 && this.supportedProxies.indexOf(item.proxy) > -1
          })
        })
      )
      .subscribe(devices => this._store.dispatch({
        type: SIMPLE_ACTIONS.SET_DEVICES,
        payload: devices
      }));
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
    this._store.dispatch({ type: SIMPLE_ACTIONS.CLEAR });
  }
}
