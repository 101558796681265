import { Event } from './event.interface';
import { Binding } from './binding.interface';

export enum CodeItemType {
  Command = 1,
  Conditional,
  Loop 
}

export interface Item {
  type: number;
  name: string;
  typeName: string;
  parentId: number;
  id: number;
  URIs: Array<{ [key: string]: string }>;
}

export interface Control {
  control?: string;
}

export interface Images {
  smallImage: string;
  largeImage: string;
}

export interface Location extends Item {
  siteId: number;
  siteName: string;
  siteOrder: number;
  buildingId?: number;
  buildingName?: string;
  buildingOrder?: number;
  floorId?: number;
  floorName?: string;
  floorOrder?: number;
}

export interface ProtocolDriver {
  protocolId: number;
  protocolName: string;
  protocolFilename: string;
  protocolControl?: string;
}

export interface ProxyDriver {
  proxy?: string;
  proxyOrder?: number;
  proxyMeta?: Array<{
    name?: string;
    smallImage?: string;
    smallSource?: string;
    largeImage?: string;
    largeSource?: string;
  }>;
}

export interface Driver extends Item, Location, Images, ProtocolDriver, ProxyDriver {
  roomId: number;
  roomName: string;
  roomOrder: number;
  filename: string;
  proxy: string;
  proxyOrder: number;
  roomHidden: boolean;
  tempHidden: boolean;
  capabilities: any;
  allControlBindingsHidden: number;
}

export interface Room extends Driver {

}

export interface Device extends Driver {
  typeName: string;
  manufacturer?: string;
  model?: string;
  modified?: string;
  created?: string;
  version?: number;
  deviceOrder?: number;
  control?: string;
  events?: Event[];
  conditionals?: any[];
  commands?: any;
  controller_disabled?: boolean;
  bindings?: Binding[];
}
