import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { UIModule } from '@when-then/ui';

import { ProgrammingCommonModule } from '../../common/common.module';
import { QuickstartCommonModule } from './../quickstart-common/quickstart-common.module';

import { SelectScreenComponent } from './select-screen/select-screen.component';
import { SelectCustomButtonComponent } from './select-custom-button/select-custom-button.component';
import { QuickstartCustomButtonsService } from './services/custom-buttons.service';
import { SelectScreenButtonComponent } from './select-screen-button/select-screen-button.component';
import { SelectCustomButtonInteractionComponent } from './select-custom-button-interaction/select-custom-button-interaction.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,    
    UIModule,
    ProgrammingCommonModule,
    QuickstartCommonModule,
  ],
  providers: [
    QuickstartCustomButtonsService
  ],
  declarations: [SelectScreenComponent, SelectCustomButtonComponent, SelectScreenButtonComponent, 
    SelectCustomButtonInteractionComponent]
})
export class QuickstartCustomButtonsModule { }
