import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SelectSystemComponent } from './select-system/select-system.component';
import { QuickstartCommonModule } from '../quickstart-common/quickstart-common.module';
import { ProgrammingCommonModule } from './../../common/common.module';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    QuickstartCommonModule,
    ProgrammingCommonModule,
    RouterModule,
  ],
  declarations: [SelectSystemComponent],
  exports: [SelectSystemComponent]
})
export class QuickstartSecuritySystemModule { }
