import { StorageService } from '@when-then/core';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, take, map } from 'rxjs/operators';

import { AuthenticationState } from './authentication-state';
import { AuthenticationService } from './authentication.service';

export const PROFILE_ROOT: string = 'root';
export const PROFILE_INSTALLER: string = 'pro-installer';
export const PROFILE_CUSTOMER: string = 'primary';
export const PROFILE_UNKNOWN: string = 'unknown';

export interface IdentityState {
  role: 'root' | 'pro-installer' | 'primary' | 'unknown';
  username: string;
  display: string;
}

const IDENTITY_UNKNOWN: IdentityState = {
  role: 'unknown',
  username: 'unknown',
  display: 'Unknown'
};

const IDENTITY_UNAUTHENTICATED: IdentityState = {
  role: 'unknown',
  username: 'unknown',
  display: ''
}

const STORE_NAME: string = 'IDENTITY:';
const SET_IDENTITY: string = STORE_NAME + 'SET_IDENTITY';

export function identityReducer(state: IdentityState = IDENTITY_UNKNOWN, { type, payload }) {
  switch (type) {
    case SET_IDENTITY: return Object.assign({}, payload);

    default: return state;
  }
}

@Injectable()
export class IdentityService {

  identity: Observable<IdentityState>;

  constructor(
    private storage: StorageService,
    private store: Store<{ authentication: AuthenticationState, identity: IdentityState }>,
    private authN: AuthenticationService
  ) {
    this.identity = this.store.select(s => s.identity);

    // this.store.select(s => s.authentication.initialized).distinctUntilChanged().subscribe(initialized => {
    //   console.log('ident: authN initialized', initialized);
    // });

    this.store.select(s => s.authentication)
      .pipe(
        filter(s => !!s),
        map(s => s.authenticated),
        filter(a => !!a)
      )
      .subscribe(authenticated => {
        console.log('ident: authN authenticated', authenticated);
        let role = this.storage.get('profile');
        if (role) {
          this.store.dispatch({
            type: SET_IDENTITY, payload: {
              role: role,
              username: this.storage.get('username'),
              display: this._getDisplayName(role)
            }
          });
        } else {
          this.store.dispatch({ type: SET_IDENTITY, payload: IDENTITY_UNKNOWN });
        }

      });
  }

  whoAmI(): IdentityState {
    let state: IdentityState = undefined;
    this.store.select(s => s.identity).pipe(take(1)).subscribe(ident => state = ident);
    return state;
  }

  private _getDisplayName(role: string) {
    switch (role) {
      case 'root': return 'System Admin';
      case 'pro-installer': return 'Pro-Installer';
      case 'primary': return 'Primary End User';
      default: return 'Unknown';
    }
  }
}
