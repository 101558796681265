import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIModule } from '@when-then/ui';

import { ProgrammingCommonModule } from '../../common/common.module';

import { FlowControlComponent } from './flow-control.component';
import { DelayComponent } from './delay/delay.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { QuickstartCommonModule } from '../quickstart-common/quickstart-common.module';
import { ConditionalComponent } from './conditional/conditional.component';
import { FlowControlService } from './flow-control.service';
import { ConditionalHeaderComponent } from './conditional-header/conditional-header.component';
import { ConditionalCommandsComponent } from './conditional-commands/conditional-commands.component';
import { ConditionalParamsComponent } from './conditional-params/conditional-params.component';
import { SelectConditionalComponent } from './select-conditional/select-conditional.component';
import { SelectComponent } from './dynamic/select.component';
import { InputComponent } from './dynamic/input.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    UIModule,
    ProgrammingCommonModule,
    QuickstartCommonModule
  ],
  declarations: [
    FlowControlComponent,
    DelayComponent,
    ConditionalComponent,
    ConditionalHeaderComponent,
    ConditionalCommandsComponent,
    ConditionalParamsComponent,
    SelectConditionalComponent,
    InputComponent,
    SelectComponent
  ],
  providers: [
    FlowControlService
  ],
  exports: [
    ConditionalHeaderComponent
  ]
})
export class QuickstartFlowControlModule { }
