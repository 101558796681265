<div class="header bordered">
  <span class="title grow">Add New Automation</span>
  <div class="search">
    <span class="icon blue search small"></span>
    <input type="search" [ngModel]="searchText|async" (input)="searchTextChanged($event)"  placeholder="" [disabled]="!(ready|async)" placeholder="Search">
  </div>
  <button class="trailing" (click)="goHome()" style="display:inline-block">
    Cancel
  </button>
</div>
<div class="header secondary">
  <h4 class="blue">Choose Your Automation Type</h4>
</div>
<div [cxWait]="(ready | async)" cxText="Finding available events...">
  <cxScrollable>
    <div *ngFor="let category of (selectEventService.installedCategories | async | sortBy:'order')">
      <div class="header section" *ngIf="(category.installedTypes | matchingSearchText:(searchText|async))?.length > 0">
        <span class="title">{{ category.name }}</span>
      </div>
      <div *ngFor="let type of (category.installedTypes | matchingSearchText:(searchText|async))">
        <prg-block-button *ngIf="!type.hidden" buttonClass="dark" [leadingIcon]="type.icon" [title]="type.description"
          trailingIcon="icon small right trailing silver" (click)="typeClicked(type)" [routerLink]="['../' + type.route]"></prg-block-button>
      </div>
    </div>
  </cxScrollable>
</div>
