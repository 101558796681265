// angular
import { Injectable } from '@angular/core';

// libs
import { Observable, Subscription } from 'rxjs';
import { filter, map, take, distinctUntilChanged } from 'rxjs/operators';
import { Store } from '@ngrx/store';

// C4 libs
import { BrokerService } from '@when-then/core';

// common
import { ProgrammingUtilsService as Utils } from './../../../common/services/utils.service';
import { ItemsService } from './../../../common/services/items.service';
import { ProgrammingUtilsService as Util } from '../../../common/services/utils.service';
import { SharedProgrammingService, CommandData, CommonProgrammingContext } from '../../../common/services/shared-programming.service';

import { Command } from '../../../common/interfaces/command.interface';
import { Parameter, ValueType } from "../../../common/interfaces/parameter.interface";

// module
import { Device } from '../../../common/interfaces/item.interface';
import { ItemsState } from '../../../common/services/items.service';
import { Room } from '../../quickstart-lights/services/interfaces/room.interface';

/**
 * List of commands supported by
 * the Timers quickstart
 *
 * @type {Array<string>}
 */
const COMMAND_WHITELIST = ['SET_AUTO_OFF:DISABLE', 'SET_AUTO_OFF', 'RESTART_AUTO_OFF'];

export interface TimersProgrammingContext {
  // define state here
  ready: boolean;
  command?: Command;
  action?: Action;
}

interface Action {
  timerDevice?: Device;
  commands?: Command[];
  command?: CommandData;
}

const INITIAL_STATE: TimersProgrammingContext = {
  ready: false,
  action: {},
}

const STORE_NAME = ['PROGRAMMING', 'QUICKSTARTS', 'TIMERS'].join(':');

const ACTIONS = {
  SET_ACTION: `${STORE_NAME}:SET_ACTION`,
  SET_COMMANDS: `${STORE_NAME}:SET_COMMANDS`,
  SET_COMMAND: `${STORE_NAME}:SET_COMMAND`,
  SET_COMMAND_VALUES: `${STORE_NAME}:SET_COMMAND_VALUES`,
  SET_TIMERS_READY: `${STORE_NAME}:SET_TIMERS_READY`
}

export function timersProgrammingReducer(state: TimersProgrammingContext = INITIAL_STATE, { type, payload }): TimersProgrammingContext {

  switch (type) {

    case ACTIONS.SET_TIMERS_READY:
      return Object.assign({}, state, { devices: payload });

    case ACTIONS.SET_ACTION:
      let a1 = Object.assign({}, state.action, { timerDevice: payload });
      return Object.assign({}, state, { action: a1 });

    case ACTIONS.SET_COMMANDS:
      let a2 = Object.assign({}, state.action, { commands: payload });
      return Object.assign({}, state, { action: a2 });

    case ACTIONS.SET_COMMAND:
      let a3 = Object.assign({}, state.action, { command: payload });
      return Object.assign({}, state, { action: a3 });

    case ACTIONS.SET_COMMAND_VALUES:
      let commands: Command[] = [...state.action.commands];
      let commandIx = commands.findIndex(c => {
        return c.command === payload.command.command &&
          c.id === payload.command.id
      });

      commands[commandIx].params = [...payload.params];

      return Object.assign({}, state, { action: Object.assign({}, state.action, { commands: commands }) });

    default:
      return state;
  }
}

@Injectable()
export class TimersService {

  private _timerDevices: Observable<Device[]>;
  public timerDevicesByRoom: Observable<Room[]>;

  public actionTimerDevices: Observable<Device[]>;
  public actionTimerDevicesByRoom: Observable<Room[]>;
  private _action: Observable<Action>;
  private _actionCommand: Observable<CommandData>;

  constructor(
    private _broker: BrokerService,
    private _store: Store<{
      timersProgramming: TimersProgrammingContext,
      sharedProgramming: CommonProgrammingContext,
      programmingItems: ItemsState
    }>,
    private _shared: SharedProgrammingService,
    private _items: ItemsService
  ) {
    this._timerDevices = <Observable<Device[]>>this._items.itemsList
      .pipe(
        map(items => {
          return items.filter((item: any) => {
            return ((item.type === 7) &&
              (['light_v2', 'keypad_proxy', 'fan'].indexOf(item.proxy) >= 0));
          })
        })
      );
    this.timerDevicesByRoom = <Observable<Room[]>>this._byRoom(this._timerDevices);

    this.actionTimerDevices = this._timerDevices
      .pipe(
        map(devices => devices.filter((device: Device) => {
          return device.commands && device.commands.command.some(c => COMMAND_WHITELIST.indexOf(c.name) > -1);
        }))
      );
    this.actionTimerDevicesByRoom = this._byRoom(this.actionTimerDevices);

    this._action = this._store.select(s => s.timersProgramming.action);
    this._actionCommand = this._action
      .pipe(
        filter(a => !!a),
        map((a: any) => a.command)
      );

    this._monitorAction();
  }

  setActionTimerDevice(d: Device | number): void {
    if (typeof d == 'number') {
      this._getDeviceById(d)
        .pipe(
          filter(d => !!d),
          take(1)
        )
        .subscribe(d => {
          this._store.dispatch({
            type: ACTIONS.SET_ACTION,
            payload: d
          })
        })
    } else {
      this._store.dispatch({
        type: ACTIONS.SET_ACTION,
        payload: d
      });
    }
  }


  setActionCommand(command: Command, params: Parameter[] = []) {
    params = params.filter(p => !!p);

    if (params.length > 0) {
      params.forEach((param) => {
        param.value = param._value;
      });
    }

    command.params = params;

    const data = Utils.buildCommandData(1, command);
    this._store.dispatch({ type: ACTIONS.SET_COMMAND, payload: data });

    // NOTE this is required to support conditional led color actions
    this._shared.setPendingAction(command, data);
  }

  updateParam = (evt: ValueType, param: Parameter, command: Command) => {
    let newParam: Parameter = Object.assign({}, param, {
      _value: evt.value,
      _display: evt.display
    });

    let params = [...command.params];

    let pix = params.findIndex(p => p.name === newParam.name && p.type === newParam.type);

    params[pix] = newParam;

    this.updateParams(params, command);
  }

  updateParams = (params: Parameter[], command: Command) => {
    this._store.dispatch({
      type: ACTIONS.SET_COMMAND_VALUES,
      payload: {
        params: params,
        command: command
      }
    })
  }

  /**
   * Monitors the action in the
   * store, and sets commands as
   * it changes
   */
  private _monitorAction = () => {
    this._action
      .pipe(
        filter(a => !!a),
        map((a: any) => {
          console.log('timerDevice: device changed', a);
          return (!!a.timerDevice && !!a.timerDevice.URIs && !!a.timerDevice.URIs.commands) ?
            a.timerDevice.URIs.commands :
            undefined;
        }),
        distinctUntilChanged<string>(Util.distinct)
      )
      .subscribe(
        uri => {
          console.log('timerDevice: device uri is', uri);
          if (uri === undefined) {
            this._store.dispatch({ type: ACTIONS.SET_COMMANDS, payload: undefined });
          } else {
            console.log('timerDevice: getting commands for device', uri);
            this._broker.call({
              path: uri
            }).then(
              (commands: Command[]) => {
                console.log('timerDevice: whitelisting device commands', commands);
                let whiteListed = commands.filter(c => COMMAND_WHITELIST.indexOf(c.command) > -1);
                this._store.dispatch({ type: ACTIONS.SET_COMMANDS, payload: whiteListed });
              },
              error => console.error('timers.service.ts: error: %O', error)
            );
          }
        },
        error => console.error('timers.service.ts: error: %O', error)
      );
  }

  private _getDeviceById = (id: number): Observable<Device> => {
    return this._timerDevices
      .pipe(
        map(devices => devices.find(d => d.id === id))
      );
  }

  private _byRoom = (devicesObservable: Observable<Device[]>): Observable<Room[]> => {
    return devicesObservable
      .pipe(
        map(devices => devices.reduce((rooms: Room[], device) => {
          let room = rooms.find(r => r.roomId === device.roomId);

          if (room === undefined) {
            room = {
              roomName: device.roomName,
              roomId: device.roomId,
              devices: []
            }

            rooms.push(room);
          }

          room.devices.push(device);

          return rooms;
        }, []))
      );
  }
}
