import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, combineLatest } from 'rxjs';
import { map, filter, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { BrokerService } from '@when-then/core';

import { Device, CodeItemType } from '../../../common/interfaces/item.interface';
import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';
import { SharedProgrammingService, CommonProgrammingContext } from '../../../common/services/shared-programming.service';

import { ThermostatProgrammingContext, QSThermostatService } from '../quickstart-thermostats.service';
import { Command } from '../../../common/interfaces/command.interface';
import { ProgrammingUtilsService as Utils } from '../../../common/services';


@Component({
  templateUrl: './select-action.component.html',
  styleUrls: [
    './select-action.component.less'
  ]
})
export class QSThermostatSelectActionComponent extends BaseRoutingComponent {

  _thermostat: Observable<Device>;
  _commands: Observable<Command[]>;
  commandsReady: Observable<boolean>;

  constructor(
    private _route: ActivatedRoute,
    protected store: Store<{
      thermostatProgramming: ThermostatProgrammingContext,
      sharedProgramming: CommonProgrammingContext
    }>,
    private _broker: BrokerService,
    public router: Router,
    private _shared: SharedProgrammingService,
    private _service: QSThermostatService
  ) {
    super();

    this._thermostat = combineLatest(
      this._route.params.pipe(map((params: { id: string }) => parseInt(params.id))),
      this.store.select(s => s.thermostatProgramming.thermostats).pipe(filter(t => !!t)),
      (id, thermostats) => thermostats.find(t => t.id == id)
    );

    this._commands = this._thermostat.pipe(switchMap(tstat => this._service.getCommands(tstat.id)));
    this.commandsReady = this.store.select(s => s.thermostatProgramming.commandsChecked);
  }

  selectCommand(command: Command) {
    if (!!command.params && command.params.length > 0) {
      this.router.navigate(['../' + command.deviceId, command.command], {
        relativeTo: this._route
      });
    } else {
      this._shared.setPendingAction(command, Utils.buildCommandData(CodeItemType.Command, command));
      this.router.navigate(['conditionals'], { relativeTo: this._route });
    }
  }

}
