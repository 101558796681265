<!-- <prg-notification-parameters></prg-notification-parameters> -->
<prg-nav-header title="{{ (_command|async)?.label }} Options" (cancel)="goToEvent()"></prg-nav-header>
<div class="push" [cxWait]="(_ready | async) && (_command | async) && (_params | async)">
    <p class="help-text"><strong>NOTE:</strong> To ensure that notifications are received by the intended parties,
        you
        need to configure the recipients for different message types on the <a href="https://customer.control4.com/setNotifications"
            target="_blank">Automation &gt; Notifications</a> page.</p>
    <form action="" (ngSubmit)="validateAndSave()" #pushForm="ngForm">
        <div class="field-group">
            <label for="" class="leading"></label>
            <span class="help-text"><span class="required">*</span> indicates a required field</span>
        </div>
        <div class="field-group">
            <label for="" class="leading"></label>
            <div class="errors">
                <div *ngFor="let err of errors" class="error">{{err}}</div>
            </div>
        </div>
        <div class="field-group">
            <label class="leading" for="severity"><span class="required">*</span> Severity</label>
            <select name="severity" id="severity" [(ngModel)]="pushNotif.severity" aria-placeholder="select a severity"
                required>
                <option [value]="0">select a severity</option>
                <option [value]="opt.id" *ngFor="let opt of _severities">{{opt.name}}</option>
            </select>
        </div>
        <div class="field-group">
            <label class="leading" for="category"><span class="required">*</span> Category</label>
            <select name="category" id="category" [(ngModel)]="pushNotif.category" aria-placeholder="select a category"
                required>
                <option [value]="0">select a category</option>
                <option [value]="opt.id" *ngFor="let opt of _categories">{{opt.name}}</option>
            </select>
        </div>
        <div class="field-group">
            <label class="leading" for="subject">Subject</label>
            <input type="text" placeholder="push notification subject" #subject="ngModel" name="subject" [(ngModel)]="pushNotif.subject">
            <div [hidden]="subject.valid || subject.pristine && (pushNotif.subject || pushNotif.message)" class="error">
                Either a subject or message is required.
            </div>
        </div>
        <div class="field-group">
            <label class="leading" for="message">Message</label>
            <textarea name="message" placeholder="push notiifcation message" #message="ngModel" [(ngModel)]="pushNotif.message"
                name="message" id="message" cols="40" rows="6"></textarea>
            <div [hidden]="message.valid || message.pristine && (pushNotif.subject || pushNotif.message)" class="error">
                Either a subject or message is required.
            </div>
        </div>
        <div class="field-group">
            <label class="leading"></label>
            <span class="inline-fields">
                <input type="checkbox" id="timestamp" #addTimestamp="ngModel" name="timestamp" [(ngModel)]="pushNotif.addTimestamp"
                    class="offset">
                <label for="timestamp" class="inline-fields"> Include a timestamp in the message body</label>
            </span>
        </div>
        <footer class="row flex" *ngIf="(_command | async) && (_params | async)">
            <span>Send a push notification with subject "{{pushNotif.subject}}"</span>
            <button class="blue clickable" type="submit">Add Push Notification</button>
        </footer>
    </form>
</div>