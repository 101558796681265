import { Pipe, PipeTransform } from '@angular/core';
import { ScheduleEvent } from '../../quickstarts/quickstart-schedule/+state/schedule.interfaces';

@Pipe({
  name: 'groupByTime'
})
export class GroupByTimePipe implements PipeTransform {

  transform(events: ScheduleEvent[], args?: any): any {
    let grouped = [];
    let lastEvent: ScheduleEvent = undefined;

    if (events) {
      events.forEach((evt: ScheduleEvent) => {
        let added = false;
        if (lastEvent) {
          // NOTE we've already determined that this event happens today so no need to check the date
          if (lastEvent.next_occurence.hour === evt.next_occurence.hour && lastEvent.next_occurence.min === evt.next_occurence.min) {
            let grp = grouped[grouped.length - 1];
            grp.events.push(evt);
            added = true;
          }
        }

        if (!added) {
          grouped.push({ time: evt.next_occurence.hour * 60 + evt.next_occurence.min, events: [evt] });
        }

        lastEvent = evt;
      });
    }

    return grouped;
  }

}
