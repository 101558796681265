import { Injectable } from '@angular/core';
import { C4Option } from './c4-input';
import { C4InputSelect } from './select/c4-input-select';
import { C4InputRadio } from './radio/c4-input-radio';
import { C4InputList } from './list/c4-input-list';
import { BrokerService } from '@when-then/core';

@Injectable()
export class C4OptionService {

  constructor(
    private broker: BrokerService
  ) { }

  getOptions(optionsUrl: string): Promise<C4Option[]> {
    return this.broker.call({
      path: optionsUrl
    });
  }

  hydrateOptions(input: C4InputSelect | C4InputList | C4InputRadio): void {
    if (input.options == undefined && input.optionsUrl != undefined) {
      this.getOptions(input.optionsUrl).then(
        (data: C4Option[]) => {
          input.options = data.map((option) => {
            return this.composeOption(option, input);
          });
        },
        error => {
          console.error('c4-option-service.ts: Error fetching options for ' + input.optionsUrl);
          console.error('c4-option-service.ts: error: %O', error);
        }
      );
    }

    if (input.optionKey || input.optionDisplay) {
      input.options = input.options.map(option => {
        return this.composeOption(option, input);
      });
    }
  }

  private composeOption = (option: C4Option, input: C4InputSelect | C4InputList | C4InputRadio): C4Option => {
    if (input.optionKey) {
      option[input.optionKey] = this.composeField(input.optionKey, option);
    }

    if (input.optionDisplay) {
      option[input.optionDisplay] = this.composeField(input.optionDisplay, option);
    }

    return option;
  }

  private composeField = (field: string, option: C4Option): string => {
    var tokens: string[] = field.split(/[{}]/);

    if (tokens.length === 1) return option[field];

    return tokens.slice(1, -1).map((token, i) => {
      if (i % 2 === 0) {
        return option[token];
      }

      return token;
    }).join("");
  }

  private dummyOptions = [
    {
      "id": 14,
      "varName": "POWER_STATE",
      "type": "Boolean",
      "hidden": 0,
      "value": 0,
      "variableId": 1010,
      "name": "Room",
      "roomName": "Room"
    },
    {
      "id": 14,
      "varName": "HAS_DISCRETE_VOLUME",
      "type": "Boolean",
      "hidden": 0,
      "value": 0,
      "variableId": 1016,
      "name": "Room",
      "roomName": "Room"
    },
    {
      "id": 14,
      "varName": "HAS_DISCRETE_MUTE",
      "type": "Boolean",
      "hidden": 0,
      "value": 0,
      "variableId": 1017,
      "name": "Room",
      "roomName": "Room"
    },
    {
      "id": 14,
      "varName": "IS_MUTED",
      "type": "Boolean",
      "hidden": 0,
      "value": 0,
      "variableId": 1018,
      "name": "Room",
      "roomName": "Room"
    },
    {
      "id": 14,
      "varName": "IN_NAVIGATION",
      "type": "Boolean",
      "hidden": 0,
      "value": 0,
      "variableId": 1019,
      "name": "Room",
      "roomName": "Room"
    },
    {
      "id": 14,
      "varName": "USE_DEFAULT_VOLUMES",
      "type": "Boolean",
      "hidden": 0,
      "value": false,
      "variableId": 1020,
      "name": "Room",
      "roomName": "Room"
    },
    {
      "id": 14,
      "varName": "VOLUME_IS_LINKED",
      "type": "Boolean",
      "hidden": 0,
      "value": 0,
      "variableId": 1023,
      "name": "Room",
      "roomName": "Room"
    },
    {
      "id": 14,
      "varName": "MUTE_IS_LINKED",
      "type": "Boolean",
      "hidden": 0,
      "value": 0,
      "variableId": 1025,
      "name": "Room",
      "roomName": "Room"
    },
    {
      "id": 14,
      "varName": "ROOMOFF_IS_LINKED",
      "type": "Boolean",
      "hidden": 0,
      "value": 0,
      "variableId": 1026,
      "name": "Room",
      "roomName": "Room"
    },
    {
      "id": 14,
      "varName": "SELECTIONS_LINKED",
      "type": "Boolean",
      "hidden": 0,
      "value": 0,
      "variableId": 1027,
      "name": "Room",
      "roomName": "Room"
    },
    {
      "id": 14,
      "varName": "ROOM_HIDDEN",
      "type": "Boolean",
      "hidden": 0,
      "value": 0,
      "variableId": 1029,
      "name": "Room",
      "roomName": "Room"
    },
    {
      "id": 14,
      "varName": "MEDIA_SCENE_ACTIVE",
      "type": "Boolean",
      "hidden": 0,
      "value": 0,
      "variableId": 1030,
      "name": "Room",
      "roomName": "Room"
    }
  ];

}
