import { Component, ViewChild, ElementRef, ChangeDetectorRef, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

import { Observable, BehaviorSubject, combineLatest, empty } from 'rxjs';
import { filter, map, flatMap, shareReplay, take, tap, concatMap, timeout, catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { BrokerService } from '@when-then/core';

import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';
import { ItemsState } from '../../../common/services/items.service';
import { Device, Room, CodeItemType } from '../../../common/interfaces/item.interface';
import { Command } from '../../../common/interfaces/command.interface';
import { SharedProgrammingService, CommandData, CommonProgrammingContext } from '../../../common/services/shared-programming.service';
import { ProgrammingUtilsService as Utils } from '../../../common/services/utils.service';

import { MediaProgrammingContext, QSMediaService } from '../quickstart-media.service';
import { StreamingServices } from './drivers/services';
import { MediaItem, Collection } from './drivers/interfaces/streaming.interface';
import { DataToUIShimService } from './datatoui-shim.service';

@Component({
  templateUrl: './select-streaming-rooms.component.html',
  styleUrls: ['./select-streaming-rooms.component.less']
})
export class QSMediaSelectStreamingRoomsComponent extends BaseRoutingComponent {

  @ViewChild('roomList') roomListElement: ElementRef;
  @ViewChild('stickyFooter') footerElement: ElementRef;

  top: number;
  boxCompensation: number;
  footerHeight: number;

  maxHeight: SafeStyle;

  uiPollHandle: number;
  uiPollInterval = 100;

  _listContainer: HTMLElement;

  public get listContainer(): HTMLElement {
    if (!this.roomListElement) { return; }

    this._listContainer = this._listContainer || this.roomListElement.nativeElement;

    return this._listContainer;
  }

  _footerContainer: HTMLElement;

  public get footerContainer(): HTMLElement {
    if (!this.footerElement) { return; }

    this._footerContainer = this._footerContainer || this.footerElement.nativeElement;

    return this._footerContainer;
  }

  public driver: Observable<Device>;
  public category: Observable<string>;
  public collectionCfg: Observable<Collection>;
  public collection: Observable<MediaItem[]>;
  private _commandMeta: Observable<Command>;

  public mediaItem: Observable<MediaItem>;
  public rooms: Observable<Room[]>;

  public selectedRooms: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);
  public selectedRoomString: Observable<string>;

  constructor(
    public router: Router,
    private _route: ActivatedRoute,
    protected store: Store<{
      mediaProgramming: MediaProgrammingContext,
      programmingItems: ItemsState,
      sharedProgramming: CommonProgrammingContext
    }>,
    private _broker: BrokerService,
    private _shared: SharedProgrammingService,
    private _sanitizer: DomSanitizer,
    private _zone: NgZone,
    private _dataToUI: DataToUIShimService
  ) {
    super();

    this.maxHeight = this._sanitizer.bypassSecurityTrustStyle('50vh');

    this._zone.runOutsideAngular(() => {
      this.uiPollHandle = window.setInterval(() => {
        this._checkHeight();
      }, this.uiPollInterval);
    })

    this.driver = combineLatest(
      this._route.params,
      this.store.select(s => s.programmingItems.itemsList)
    )
      .pipe(
        filter(([params, items]) => !!params && !!items && items.length > 0),
        map(([params, items]) => <Device>items.find(item => item.id == params['driverId']))
      );

    this.category = this._route.params
      .pipe(
        map(params => params['category'])
      );

    this.collectionCfg = combineLatest(
      this.driver,
      this.category
    )
      .pipe(
        filter(([driver, cat]) => !!driver && !!cat),
        map(([driver, category]) => StreamingServices.find(s => s.protocolFilename == driver.protocolFilename)[category])
      );

    this._commandMeta = combineLatest(
      this.collectionCfg,
      this.driver
    )
      .pipe(
        filter(([cfg, driver]) => !!cfg && !!driver),
        flatMap(([cfg, driver]) => this._broker.call({
          path: QSMediaService.interpolate(cfg.selectionEndpoint, driver)
        }).then(res => res.find(c => c.command == cfg.selectionCommand)))
      );

    this.collection = this._dataToUI.customCommandData(this.driver, this.category);

    this.mediaItem = combineLatest(
      this._route.params,
      this.collection
    )
      .pipe(
        filter(([params, collection]) => !!params && Array.isArray(collection)),
        map(([params, collection]) => collection.find(mi => mi.value == params['mediaItem']))

      );

    this.rooms = combineLatest(
      this.store.select(s => s.programmingItems.itemsList),
      this.store.select(s => s.mediaProgramming.listen_devices),
      this.driver
    )
      .pipe(
        filter(([items, listenDevices, proxyDriver]) => {
          return Array.isArray(items) && items.length > 0
            && Array.isArray(listenDevices) && listenDevices.length > 0
            && !!proxyDriver
        }),
        map(([items, listenDevices, proxyDriver]) => {

          return <Room[]>items.filter((room: Device) => {

            if (room.id != room.roomId) {
              return false;
            }

            const ldRoom = listenDevices.find(l => l.id == room.id);

            return Array.isArray(ldRoom.listen_devices)
              && ldRoom.listen_devices.findIndex(ld => ld.id == proxyDriver.id) >= 0;
          });
        })
      )

    this.selectedRoomString = combineLatest(
      this.rooms,
      this.selectedRooms
    )
      .pipe(
        filter(([rooms, selected]) => Array.isArray(rooms) && Array.isArray(selected)),
        map(([rooms, selected]) => {
          const roomNames = rooms
            .filter(room => selected.indexOf(room.id) > -1)
            .map(room => room.name);

          switch (roomNames.length) {
            case 0:
              return "...";

            case 1:
              return roomNames[0];

            case 2:
              return roomNames.join(" and ");

            default:
              roomNames[roomNames.length - 1] = "and " + roomNames[roomNames.length - 1];
              return roomNames.join(", ");
          }
        })
      );
  }

  ngOnDestroy() {
    this._zone.runOutsideAngular(() => {
      window.clearInterval(this.uiPollHandle);
    });
  }

  public toggleRoom = async (id: number) => {
    const curr = await this.selectedRooms
      .pipe(
        take(1)
      ).toPromise();

    curr.indexOf(id) == -1
      ? this.selectedRooms.next([id, ...curr])
      : this.selectedRooms.next(curr.filter(s => s != id));
  }

  public setAction = async () => {
    const command = await this._commandMeta
      .pipe(
        take(1)
      )
      .toPromise();

    const rooms = await this.selectedRooms
      .pipe(take(1))
      .toPromise();

    const roomString = await this.selectedRoomString
      .pipe(take(1))
      .toPromise();

    const mediaItem = await this.mediaItem
      .pipe(take(1))
      .toPromise();

    const data = {
      ...Utils.buildCommandData(CodeItemType.Command, command),
      ...{
        params: command.params.map(p => {
          const param = {
            name: p.name,
            value: undefined,
            display: undefined
          };

          if ((p.name == "Room") || (p.name == "Room(s)")) {
            param.value = rooms;
            param.display = roomString;
          }

          else if (p.name == "Shuffle") {
            param.value = "Off";
            param.display = "Off";
          }

          else {
            param.value = mediaItem.value;
            param.display = mediaItem.text;
          }

          return param;

        })
      }
    }

    this._shared.setPendingAction(command, data);
    this.router.navigate(['conditionals'], { relativeTo: this._route });
  }

  private _getAncestors(element: HTMLElement): HTMLElement[] {

    let tracking = element;
    let ancestors: HTMLElement[] = [tracking];

    while (!!tracking.parentElement) {
      tracking = tracking.parentElement;
      ancestors.push(tracking);
    }

    return ancestors;
  }

  private _checkHeight() {
    if (!!this.listContainer && this.listContainer.offsetTop !== this.top
      && !!this.footerContainer && this.footerContainer.offsetTop) {
      this.top = this.listContainer.offsetTop;
      this.boxCompensation = this._calculateCompensation();
      let footerStyle = window.getComputedStyle(this.footerContainer);
      this.footerHeight = parseFloat(footerStyle.height) + parseFloat(footerStyle.marginBottom) + parseFloat(footerStyle.marginTop);
      this.maxHeight = this._sanitizer.bypassSecurityTrustStyle('calc(100vh - ' + (this.top + this.boxCompensation + this.footerHeight) + 'px)')
    }
  }

  private _calculateCompensation(): number {
    let ancestors = this._getAncestors(this.listContainer);

    let compensation = ancestors.reduce((accumulator, element) => {
      let style = window.getComputedStyle(element);
      return accumulator + [
        parseFloat(style.marginBottom),
        parseFloat(style.paddingBottom),
        parseFloat(style.borderBottomWidth)
      ].reduce((acc, curr) => { return acc + curr; }, 0)
    }, 0);

    return compensation;
  }

}
