import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, combineLatest, from } from 'rxjs';
import { filter, switchMap, map, distinctUntilChanged } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { BrokerService, LoggerFactory } from '@when-then/core';
import { DeviceEvent } from '../../../common/interfaces/event.interface';
import { CommonProgrammingContext, SharedProgrammingService } from '../../../common/services/shared-programming.service';
import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';
import { Device } from '../../../common/interfaces/item.interface';
import { EventType, EventCriteria } from '../../quickstart-simple/event-types/event-type.interface';

@Component({
  selector: 'prg-doorstation-events',
  templateUrl: './doorstation-events.component.html',
  styleUrls: ['./doorstation-events.component.less']
})
export class DoorstationEventsComponent extends BaseRoutingComponent implements OnInit {
  events: Observable<Array<DeviceEvent>>;
  device: Observable<Device>;
  private _logger = LoggerFactory.getLogger(DoorstationEventsComponent.name);

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected store: Store<{
      sharedProgramming: CommonProgrammingContext
    }>,
    private shared: SharedProgrammingService,
    private broker: BrokerService
  ) {
    super();

    this.device = this.store.select(s => s.sharedProgramming.trigger.device);

    this.events = combineLatest(
      this.route.params.pipe(filter(p => !!p), distinctUntilChanged()),
      this.route.data.pipe(filter(d => !!d), distinctUntilChanged()),
      this.device.pipe(filter(d => !!d)),
      (params, data, device) => {
        // console.log('params and data', params, data);
        return { params, data, device };
      }
    )
      .pipe(
        switchMap(args => {
          // this._logger.debug('route data', args.data);
          // this._logger.debug('route params', args.params);
          return from(this.broker.call({
            path: '/api/v1/items/' + args.params.id + '/events',
            queryString: {
              allevents: true
            }
          }))
            .pipe(
              map(events => this._matchingEvents(args.device, events, args.data.eventType))
            )
        })
      );
  }

  private _matchingEvents(device: Device, allEvents: DeviceEvent[], etype: EventType): DeviceEvent[] {
    const wl: EventCriteria = this._matchingCriteria(etype, device);
    this._logger.debug('matching event criteria: ', wl);
    if (!!wl) {
      return allEvents.filter(evt => wl.events.indexOf(evt.eventId) >= 0);
    } else {
      return [];
    }
  }

  private _matchingCriteria(etype: EventType, device: Device): EventCriteria {
    return etype.whitelist.find(wl => Object.keys(wl.fields).every(key => device.hasOwnProperty(key) && device[key] == wl.fields[key]));
  }

  ngOnInit() { }

  selectEvent(event: DeviceEvent) {
    // NOTE the event component will handle subscribing to the event for us
    this.router.navigate(['when-then', 'device', event.deviceId, 'event', event.eventId, 'add'], { relativeTo: this.moduleRoot });
  }
}
