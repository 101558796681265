import { Pipe, PipeTransform } from '@angular/core';
import { ProgrammingUtilsService as Utils } from './../services/utils.service';

import * as moment from 'moment';

@Pipe({
  name: 'eventStartTimeLabel'
})
export class EventStartTimeLabelPipe implements PipeTransform {

  constructor() { }

  transform(event: any, args?: any): any {
    let display = '';

    if (event && event.start) {
      if (event.start.offset) {
        // sunrise or sunset
        if (event.start.offset_minutes) {
          if (event.start.offset_minutes < 0) {
            display += (Math.abs(event.start.offset_minutes) + ' before ');
          } else if (event.start.offset_minutes > 0) {
            display += (event.start.offset_minutes + ' after ');
          }
        } else {
          display += 'At ';
        }

        display += ((event.start.offset === 1) ? 'Sunrise ' : 'Sunset ');
      } else {
        display += 'At ';
        let now = new Date();
        let h = Math.floor((event.start.offset_minutes / 60));
        let m = (event.start.offset_minutes % 60);
        display += Utils.toTimeDisplay(now.getFullYear(), now.getMonth() + 1, now.getDate(), event.start.offset_minutes);
        display += ' ';
      }

      if (event.repeat && event.repeat.frequency) {
        // repeating `event`
        display += ', repeats ';
        display += this.repeatFrequency(event.repeat);
      }

      if (event.end_date) {

      }
    } else {
      display = 'Unknown start time';
    }

    return display;
  }


  private repeatFrequency(repeat: any): string {
    switch (repeat.frequency) {
      case 1: return (repeat.rate) ? 'every ' + repeat.rate + ' days ' : 'daily ';
      case 2: return (repeat.rate) ? 'every ' + repeat.rate + ' weeks ' : 'weekly ';
      case 3: return (repeat.rate) ? 'every ' + repeat.rate + ' months ' : 'monthly ';
      case 4: return (repeat.rate) ? 'every ' + repeat.rate + ' years ' : 'yearly ';
    }

    return ' ';
  }
}
