import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { SharedProgrammingService, CommandData, CommonProgrammingContext } from '../../../common/services/shared-programming.service';
import { Device } from '../../../common/interfaces/item.interface';
import { Command } from '../../../common/interfaces/command.interface';
import { FlowControlService } from '../flow-control.service';
import { LoggerFactory } from '@when-then/core';
import { Store } from '@ngrx/store';

@Component({
  templateUrl: './conditional.component.html',
  styleUrls: ['./conditional.component.less']
})
export class ConditionalComponent implements OnInit {

  private _logger = LoggerFactory.getLogger(ConditionalComponent.name);
  errors: string[];
  ready: Observable<boolean>;
  actionDevice: Observable<Device>;
  pendingAction: Observable<CommandData>;
  action: Observable<Command>;

  constructor(
    private shared: SharedProgrammingService,
    private flowControl: FlowControlService,
    private store: Store<{
      sharedProgramming: CommonProgrammingContext
    }>
  ) {
    this.errors = [];
    this.ready = this.shared.isReady;
    this.pendingAction = this.flowControl.pendingAction;
    this.action = this.store.select(s => s.sharedProgramming.action.command);
    this.actionDevice = this.flowControl.innerActionDevice;
    this._logger.debug('ctor');
  }

  ngOnInit() {
    this._logger.debug('init');
  }

}
