import { EventType } from './event-type.interface';

export const DOORSTATION_MOTION: EventType = {
  name: 'Doorstation',
  description: 'When a doorstation camera starts or stops detecting motion',
  route: 'doorstation-motion',
  category: 'Security & Access',
  icon: 'motion-trigger',
  order: 3,
  whitelist: [
    {
      fields: { proxy: 'doorstation', protocolFilename: 'control4_doorstation_ds2.c4z' },
      events: [23, 24]
    }
  ]
}
