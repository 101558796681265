import { map } from 'rxjs/operators';
import { Observable, combineLatest } from 'rxjs';
import { SharedProgrammingService } from './../services/shared-programming.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'prg-when-then-header',
  templateUrl: './when-then-header.component.html',
  styleUrls: ['./when-then-header.component.less']
})
export class WhenThenHeaderComponent implements OnInit {

  listMode: Observable<boolean>;

  constructor(
    private shared: SharedProgrammingService
  ) {
    this.listMode = combineLatest(
      this.shared.editing,
      this.shared.adding
    ).pipe(
      map(([editing, adding]) => !adding && !editing)
    );
  }

  ngOnInit() {
  }
}
