<div class="welcome">
  <div class="header">
    <h2 class="grow blue center">When &gt;&gt; Then</h2>
    <h1 class="grow blue light huge">PERSONALIZE YOUR CONTROL4 HOME</h1>
  </div>
  <div class="example">
    <div class="icon-label">
      <div class="icon xxl blue dawn"></div>
      <div class="caption"><strong>When</strong> the sun comes up...</div>
    </div>
    <div class="icon xl right grey"></div>
    <div class="icon-label">
      <div class="icon xxl blue lightbulb"></div>
      <div class="caption"><strong>Then</strong> turn off the outside lights.</div>
    </div>
  </div>

  <div class="prompt">
    <h3>My When &gt;&gt; Then</h3>
    <div class="buttons">
      <button [routerLink]="['../events']" class="huge blue wide">View My Automations</button>
    </div>
  </div>

  <div class="prompt">
    <h3>Learn More</h3>
    <div class="buttons flex row transparent nowrap">
      <button class="grey huge" [routerLink]="['../intro']">Give me a quick tutorial</button>
      <button class="whatsnew grey huge" [routerLink]="['../whats-new']">Learn about the newest features</button>
    </div>
  </div>

</div>
