import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { C4Param } from './param-input.component';

export interface C4Command {
  _display?: string;
  label: string;
  id?: number;
  display: string;
  command: string;
  params?: C4Param[];
  deviceId: number;
}

@Component({
  selector: 'c4ParamLabel',
  templateUrl: './param-label.component.html'
})
export class ParamLabel implements OnChanges {

  @Input() command: C4Command;
  @Input() params: C4Param[];

  _command: C4Command;

  // Matches all currently-known (by me) parameter token variants,
  // specifically PARAM0, PARAM:0 and "PARAM:0" styles
  // EL - Added PARAMVALUE:0 match for keypads for LED colors
  _rxp = /["]{0,1}PARAM(VALUE)*[:]??(\d+)["]{0,1}/gi;

  ngOnChanges(changes: SimpleChanges) {
    this._command = Object.assign({}, this.command);

    if (this._command != null && !!this._command.display) {
      this._command._display = this.parseLabel(this.command.display, this.params);
    }
  }

  /**
   * Replaces tokens like "PARAM:0" with the actual parameter
   * display values and returns the result
   */
  parseLabel = (display: string, params: C4Param[]): string => {

    // If match() can't find anything, it returns
    // undefined. Default to an empty array so
    // everything else works
    let match = display.match(this._rxp) || [];

    let tokens = match.map(t => {
      return {
        index: parseInt(t.match(/\d+/)[0]),
        token: t
      }
    });

    // We have some inconsistency in parameter indexing,
    // some are 0-indexed and others are 1-indexed. Since
    // arrays are 0-indexed, figure out the param with
    // the lowest index...
    let min = tokens.map(t => t.index).reduce((prev, curr) => {
      return (curr < prev) ? curr : prev;
    }, 100);

    // ...and decrement all parameters by that minimum
    tokens = tokens.map(t => {
      t.index = t.index - min;
      return t;
    });

    // Actually replace the tokens. This assumes the
    // param indexes match the order returned from
    // the endpoint, and that they are a contiguous
    // range of integers
    tokens.forEach(t => {
      let v = params[t.index]._display;
      if (v) {
        display = display.replace(t.token, ('"' + v + '"'));
      } else {
        display = display.replace(t.token, '""');
      }

    });

    return display;
  }

}
