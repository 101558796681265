import { EventType } from './event-type.interface';

export const ROOM_MEDIA_OFF: EventType = {
  name: 'Room Media Off',
  description: 'When the Turn Off Audio/Video command is received by a room',
  route: 'rooms/1',
  category: 'Room Media',
  icon: 'room',
  whitelist: [
    {
      fields: { proxy: 'roomdevice' },
      events: [1]
    }
  ]
}
