/**
 * Copyright 2017 Control4 Corporation. All rights reserved.
 * Control4 Confidential and Proprietary Information.
 */

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthenticationGuard, AuthorizationGuard } from '@when-then/auth';
import { DirectorConnectedGuard } from '@when-then/core';

import { RequiresBackupGuard } from './common/guards/requires-backup.guard';

import { QUICKSTART_PROGRAMMING_ROUTES } from './quickstarts/quickstarts.routes';

import { ProgrammingComponent } from './programming.component';

export const PROGRAMMING_ROUTES: Routes = [{
  path: 'programming',
  component: ProgrammingComponent,
  canActivate: [AuthenticationGuard, AuthorizationGuard, RequiresBackupGuard, DirectorConnectedGuard],
  canActivateChild: [AuthenticationGuard, AuthorizationGuard, RequiresBackupGuard, DirectorConnectedGuard],
  children: [
    ...QUICKSTART_PROGRAMMING_ROUTES
  ]
}];

@NgModule({
  imports: [
    RouterModule.forRoot(PROGRAMMING_ROUTES)
  ],
  exports: [RouterModule],
  providers: []
})
export class ProgrammingRoutingModule { }
