import { CommandData } from './../../../common/services/shared-programming.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { SharedProgrammingService, CommonProgrammingContext } from '../../../common/services/shared-programming.service';
import { ConditionalType, ConditionalRef, Criteria } from '../conditional-types/conditional-type.interfaces';
import { ItemsState, ProgrammingUtilsService as Utils } from '../../../common/services';
import { ConditionalTypes } from '../conditional-types/conditional-types';
import { Device, Item } from '../../../common/interfaces/item.interface';
import { FlowControlService } from '../flow-control.service';
import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';
import { LoggerFactory } from '@when-then/core';
import { Command } from '../../../common/interfaces/command.interface';

@Component({
  templateUrl: './select-conditional.component.html',
  styleUrls: ['./select-conditional.component.less']
})
export class SelectConditionalComponent extends BaseRoutingComponent implements OnInit {
  private _logger = LoggerFactory.getLogger(SelectConditionalComponent.name);
  errors: string[];
  ready: Observable<boolean>;
  conditionalTypes: Observable<ConditionalType[]>;
  actionDevice: Observable<Device>;
  command: Observable<Command>;
  pendingAction: Observable<CommandData>;

  constructor(
    protected router: Router,
    private route: ActivatedRoute,
    protected store: Store<{
      programmingItems: ItemsState,
      sharedProgramming: CommonProgrammingContext
    }>,
    private shared: SharedProgrammingService,
    private flowControl: FlowControlService
  ) {
    super();
    this.errors = [];

    this.actionDevice = this.store.select(s => s.sharedProgramming.action.device);
    this.command = this.store.select(s => s.sharedProgramming.action.command);
    this.pendingAction = this.store.select(s => s.sharedProgramming.action.pendingAction);

    this.conditionalTypes = this.store.select(s => s.programmingItems.itemsList).pipe(
      filter(list => !!list),
      map(items => items.filter(itm => [7, 8, 9].indexOf(itm.type) >= 0)),
      map(items => ConditionalTypes.filter(ct => this._isSupportedConditional(ct, items)))
    );

    this.ready = combineLatest(
      this.shared.isReady,
      this.conditionalTypes.pipe(map(ct => !!ct)),
      (a, b) => (a && b)
    );

    this.command.subscribe(a => this._logger.debug('command changed', a));
  }

  ngOnInit() {
  }

  addAction() {
    this.shared.ready(false);
    const action = Utils.snapshot<CommandData>(this.store.select(s => s.sharedProgramming.action.pendingAction));
    this.shared.saveAction(action).then(res => {
      this.shared.ready(true);
      this.goToEvent();
    }, err => {
      this._logger.error(err);
      this.errors.push(`An error occurred while adding this action.  Please try again, or try a different action.`);
      this.shared.ready(true);
    });
  }

  selectConditional(type: ConditionalType, cndRef: ConditionalRef) {
    this.shared.ready(false);
    this._logger.debug('selecting conditional', type, cndRef);
    this.shared.setConditionalRef(cndRef);
    this.errors = [];
    this.flowControl.setConditionalType(type);
    this.flowControl.selectConditonal(cndRef.name);

    this.flowControl.conditional
      .pipe(
        filter(c => !!c && c.name === cndRef.name),
        take(1)
      )
      .subscribe(c => {
        this._logger.debug('selected conditional changed', c);
        if (c.params && c.params.length > 0) {
          this.router.navigate(['..', cndRef.name, 'params'], { relativeTo: this.route });
          this.shared.ready(true);
        } else {
          this.shared.ready(false);
          this._logger.debug('saving condition with no parameters', c);
          this.flowControl.saveConditionalAction(c).then(res => {
            this.shared.ready(true);
            this.goToEvent();
          }, err => {
            this._logger.error('error adding conditional action with no params', err);
            this.errors.push('An error occurred while adding this conditional action.  Please try again.');
            this.shared.ready(true);
          });
        }
      });
  }

  private _isSupportedConditional(cond: ConditionalType, items: Item[]): boolean {
    return items.some(itm => this._itemSupportsConditional(cond, itm));
  }

  private _itemSupportsConditional(cond: ConditionalType, item: Item): boolean {
    return cond.whitelist.some(wl => this._itemMatchesWhitelist(wl, item));
  }

  private _itemMatchesWhitelist(wl: Criteria, item: Item): boolean {
    return this._itemMatchesControl(wl.control, <Device>item) ||
      this._itemMatchesProxy(wl.control, <Device>item);
  }

  private _itemMatchesControl(control: string, item: Device): boolean {
    return !!control && !!item.control && item.control === control;
  }

  private _itemMatchesProxy(proxy: string, item: Device): boolean {
    return !!proxy && !!item.proxy && item.proxy === proxy;
  }
}
