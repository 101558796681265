<div class="code-item" [ngClass]="{'disabled': disabled, 'protected': isProtected()}">
  <div class="display grow flex-row nowrap">
    <span class="icon {{codeItem | icon}} {{disabled ? 'light-grey': 'blue'}}"></span>
    <span class="text" [ngClass]="{'disabled': disabled}">{{codeItem.display}}</span>
  </div>
  <span class="toggle" *ngIf="!codeItem.protected && !anyParentDisabled() && !(listMode | async)"
    (click)="toggleClicked($event)" tooltip tooltip-text="Choose to enable or disable this action." tooltip-icon="information white">
    <prg-toggle-switch [value]="codeItem.enabled" (changed)="toggleCodeitem(codeItem, event)">
    </prg-toggle-switch>
  </span>
  <span class="icon dealer-lock grey" *ngIf="!!codeItem.protected" tooltip tooltip-text="This is automation created by your dealer. Contact your dealer if you wish to change or remove this automation."
    tooltip-icon="technician-circle red"></span>
  <span class="icon trash-bin" [ngClass]="deleteColor" (mouseover)="addDeleteHighlight()" (mouseout)="removeDeleteHighlight()"
    (click)="confirmDelete($event)" *ngIf="!codeItem.protected && !(listMode | async)"></span>
</div>
<prg-confirm-modal *ngIf="_showConfirmDelete" title="Confirm Delete" confirmLabel="Delete Action" confirmClass="red"
  (confirm)="deleteCodeItem()" (cancel)="showConfirmDelete(false)">
  <div message>
    <p class="wrap message">Are you sure you want to delete this action? <span *ngIf="!!_currentAction?.codeItems && _currentAction?.codeItems.length > 0">All
        child actions will also be deleted.</span></p>
    <p class="wrap message">{{_currentAction?.display}}</p>
  </div>
</prg-confirm-modal>
