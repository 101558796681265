// angular
import { Component, Input, ElementRef, HostListener } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

// app
import { SpinnerDirective } from './spinner.directive';

@Component({
  selector: 'cxWait, [cxWait]',
  templateUrl: './waitUntil.directive.html',
  styleUrls: [
    './waitUntil.directive.less'
  ]
})
export class WaitUntilDirective {

  wait = true;
  _value: string;

  _showSpinner: boolean = true;
  _showText: boolean = true;
  _style: SafeStyle;
  _displayBlock: boolean;
  _displayFull: boolean;

  top: number;
  nativeElement: HTMLElement;
  height: SafeStyle;

  constructor(
    private sanitizer: DomSanitizer,
    private element: ElementRef
  ) {
    this.nativeElement = this.element.nativeElement;
    this.height = sanitizer.bypassSecurityTrustStyle('initial');
  }

  @Input() set cxWait(input: string) {
    this._value = input;
  }

  @Input() set cxUntil(input: any) {
    this.wait = !input;
  }

  @Input() set cxShowText(input: string) {
    this._showText = input == 'true';
  }

  @Input() set cxShowSpinner(input: string) {
    this._showSpinner = input == 'true';
  }

  @Input() set cxDisplayBlock(input: string) {
    this._displayBlock = input == 'true';
  }

  @Input() set cxDisplayFull(input: string) {
    this._displayFull = input == 'true';
  }

  @Input() set cxStyle(input: string) {
    console.error('ERROR >> waitUntil.directive.ts:63: Due to Safari fussiness, cxStyle attribute is no longer supported.');
  }

  @Input() cxText: string = "Loading...";
  @Input() cxProgressText: string[];

  ngDoCheck() {
    this.setHeight();
  }

  @HostListener('window:resize')
  setHeight = () => {
    if (this._displayFull) {
      this.top = this.nativeElement.offsetTop;
      this.height = this.sanitizer.bypassSecurityTrustStyle('calc(100vh - 8rem - ' + this.top + 'px)');
    }
  }

}
