<!-- <prg-notification-parameters></prg-notification-parameters> -->
<prg-nav-header title="{{ command?.label }} Options" (cancel)="goToEvent()"></prg-nav-header>
<div class="email" [cxWait]="(_ready | async) && !!command && !!params">
    <div>
        <p class="help-text"><strong>NOTE:</strong> To ensure that notifications are received by the intended parties,
            you need to configure the recipients for different message types on the <a href="https://customer.control4.com/setNotifications"
                target="_blank">Automation &gt; Notifications</a> page.</p>
    </div>
    <form action="" (ngSubmit)="validateAndSave()" #emailForm="ngForm">
        <div class="field-group">
            <label for="" class="leading"></label>
            <span class="help-text"><span class="required">*</span> indicates a required field</span>
        </div>
        <div class="field-group">
            <label for="" class="leading"></label>
            <div class="errors">
                <div *ngFor="let err of errors" class="error">{{err}}</div>
            </div>
        </div>
        <div class="field-group">
            <label class="leading" for="to"><span class="required">*</span> To</label>
            <input type="email" autocomplete="email" placeholder="recipient's email address" #to="ngModel" name="to"
                [(ngModel)]="email.to" required>
            <div [hidden]="to.valid || to.pristine" class="error">
                A valid email address is required.
            </div>
        </div>
        <div class="field-group">
            <label class="leading" for="subject">Subject</label>
            <input type="text" placeholder="email subject" #subject="ngModel" name="subject" [(ngModel)]="email.subject">
            <div [hidden]="subject.valid || subject.pristine && (email.subject || email.message)" class="error">
                Either a subject or message is required.
            </div>
        </div>
        <div class="field-group">
            <label class="leading" for="message">Message</label>
            <textarea name="message" placeholder="email message" #message="ngModel" [(ngModel)]="email.message" name="message"
                id="message" cols="40" rows="6"></textarea>
            <div [hidden]="message.valid || message.pristine && (email.subject || email.message)" class="error">
                Either a subject or message is required.
            </div>
        </div>
        <div class="field-group">
            <label class="leading"></label>
            <span class="inline-fields">
                <input type="checkbox" id="timestamp" #timestamp="ngModel" name="timestamp" [(ngModel)]="email.timestamp"
                    class="offset">
                <label for="timestamp" class="inline-fields"> Include a timestamp in the message body</label>
            </span>
        </div>
        <footer class="row flex" *ngIf="!!command && !!params">
            <span>Send an email notification to "{{email.to}}" with subject "{{email.subject}}"</span>
            <button class="blue clickable" type="submit">Add Email Notification</button>
        </footer>
    </form>
</div>
