<ng-container>
  <header>
    <h6 class="grow">Rename Voice Scene</h6>
    <button
      class="blue clickable"
      [disabled]="!newName.value || (sceneExists | async) || (verifying | async) || working"
      (click)="save()">Save</button>
    <button
      class="default clickable"
      [disabled]="verifying | async"
      (click)="goToEvent()">Cancel</button>
  </header>

  <div *ngIf="!(verifying | async)" [cxWait]="!working" cxText="Saving Voice Scene name. Please wait...">
    <div class="alert alert-warning" *ngIf="sceneExists | async">
      <i class="icon information small"></i> It looks like you already have a Voice Scene called "{{ newName.value }}". If you wish to add actions to this scene, please proceed. If you wish to create a new scene instead, please choose another name.
    </div>
    <form class="scene">
      <div class="field-group">
        <label for="newName" class="leading">New Scene Name</label>
        <input
          required
          type="text"
          name="newName"
          id="newName"
          placeholder="Scene Name"
          [formControl]="newName">
      </div>
    </form>
  </div>
</ng-container>
