import { DeviceLocationPipe } from './../../../common/pipes/device-location.pipe';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';

import { QuickstartCustomButtonsService, CustomButtonScreen } from './../services/custom-buttons.service';
import { SharedProgrammingService } from '../../../common/services/shared-programming.service';
import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';
import { LocationsService, Location } from '@when-then/core';

@Component({
  selector: 'prg-select-screen',
  templateUrl: './select-screen.component.html',
  styleUrls: ['./select-screen.component.less']
})
export class SelectScreenComponent extends BaseRoutingComponent implements OnInit {

  allScreens: Observable<CustomButtonScreen[]>;
  allFloors: Observable<Location[]>;
  allRooms: Observable<Location[]>;
  ready: Observable<boolean>;

  _searchText: string;
  _currentRoom: Observable<Location>;
  _currentFloor: Observable<Location>;

  constructor(
    protected router: Router,
    protected store: Store<any>,
    private shared: SharedProgrammingService,
    private customButtonsService: QuickstartCustomButtonsService,
    private route: ActivatedRoute,
    private locations: LocationsService,
    private deviceLocation: DeviceLocationPipe
  ) {
    super();
    this.ready = this.customButtonsService.ready;
    this.allScreens = this.customButtonsService.allScreens;
    this._searchText = '';
  }

  ngOnInit() {
    this.shared.clearError();
    this.allRooms = this.locations.getRooms();
    this.allFloors = this.locations.getFloors();
  }

  screenSelected(screen: any) {
    this.customButtonsService.setScreen(screen);
    this.router.navigate(['../buttons'], { relativeTo: this.route });
  }

  getScreenLocation(screen: CustomButtonScreen) {
    switch (screen.id) {
      case 1: return 'Top Left';
      case 2: return 'Top Right';
      case 3: return 'Bottom Left';
      case 4: return 'Bottom Right';
      default: return '';
    }
  }

  setFloor(event: any) {
    this._currentFloor = this.locations.getLocationById(parseInt(event.target.value));
  }

  setRoom(event: any) {
    this._currentRoom = this.locations.getLocationById(parseInt(event.target.value));
  }

  goHome() {
    this.router.navigate(['../programming'], { relativeTo: this.moduleRoot });
  }

  getSubtitle(sc): string {
    if (sc.roomId === 0) {
      return `All Rooms, ${this.getScreenLocation(sc)} (${sc.buttons.button.length} Buttons)`;
    } else if (sc.roomId > 0) {
      return `${this.deviceLocation.transform(sc)}, ${this.getScreenLocation(sc)} (${sc.buttons.button.length} Buttons)`
    }
  }
}
