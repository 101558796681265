import { LightsProgrammingContext } from './../services/lights.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, distinctUntilChanged, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { SharedProgrammingService, CommonProgrammingContext } from './../../../common/services/shared-programming.service';
import { ProgrammingUtilsService as Util } from '../../../common/services/utils.service';
import { QuickstartProgrammingService } from '../../services/quickstart-programming.service';
import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';


import { LightsService } from '../services/lights.service';

import { Light } from '../services/interfaces/light.interface';
import { Command } from '../../../common/interfaces/command.interface';

@Component({
  selector: 'prg-select-action',
  templateUrl: './select-action.component.html',
  styleUrls: ['./select-action.component.less']
})
export class SelectActionComponent extends BaseRoutingComponent implements OnInit {

  private _adding = false;
  trigger: Observable<any>;
  light: Observable<Light>;
  ready: Observable<boolean>;

  private _commands: Observable<Command[]>;
  private _simpleCommands: Observable<Command[]>;
  private _parameterCommands: Observable<Command[]>;

  constructor(
    protected router: Router,
    private route: ActivatedRoute,
    private shared: SharedProgrammingService,
    protected store: Store<{
      lightsProgramming: LightsProgrammingContext,
      sharedProgramming: CommonProgrammingContext
    }>,
    private lights: LightsService,
    private utils: Util,
    private quickstarts: QuickstartProgrammingService
  ) {
    super();

    this.route.params.subscribe((params: { id: string }) => {
      this.lights.setActionLight(parseInt(params.id));
    });

    this.trigger = this.store.select(s => s.sharedProgramming.trigger.event);
    this.light = this.store.select(s => s.lightsProgramming.action.light);
    this.ready = this.shared.isReady;

    this._commands = this.store.select(s => s.lightsProgramming.action.commands)
      .pipe(
        filter(c => !!c),
        distinctUntilChanged(Util.distinct),
        map((c: any) => c.map(this._setParameterDefaults))
      );

    this._simpleCommands = this._commands.pipe(map(c => c.filter(c => !c.params || c.params.every(p => p.type === 'HIDDEN'))));
    this._parameterCommands = this._commands.pipe(map(c => c.filter(c => !!c.params && c.params.some(p => p.type !== 'HIDDEN'))));
  }

  ngOnInit() {
  }

  private _setParameterDefaults = (command: Command): Command => {
    if (!!command.params) {
      command.params = command.params.map(p => {
        p._value = p._value || p.value || p.default || p.low || p.minimum || 0;
        p._display = (typeof p._value == 'string') ? p._value : p._value.toString();
        return p;
      });
    }

    return command;
  }

  /**
   * This is called to set a light command that has no parameters.  Commands with parameters are
   * handled in different view.
   * @param command the command to be added.
   */
  setCommand(command: Command) {
    if (!this._adding) {
      this._adding = true;
      event.preventDefault();
      this.lights.setCommand(command);
      /**
       * NOTE parallel implementation #2
       * @see ../../quickstart-led-colors/select-action/select-action.component.ts
       */
      this.store.select(s => s.lightsProgramming.action.command)
        .pipe(
          filter(c => !!c && c.command === command.command),
          take(1)
        )
        .subscribe(data => {
          this.router.navigate(['conditionals'], { relativeTo: this.route });
        });
    }
  }
}
