import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'categoryImage'
})
export class CategoryImagePipe implements PipeTransform {
  transform(source: any): string {
    switch (source.value) {
      case 'keypad_proxy':      return '../../assets/images/c4-keypad.png';
      case 'lights':            return '../../assets/images/c4-switch.png';
      case 'uidevice':          return '../../assets/images/c4-touchscreen-t3.png';
      case 'fan':               return '../../assets/images/c4-fan-controller.png';
      default:                  return '../../assets/images/unknown-device.png';
    }
  }
}
