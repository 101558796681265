// angular
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// libs
import { Observable, Subscription } from 'rxjs';
import { map, filter, take, distinctUntilChanged } from 'rxjs/operators';
import { Store } from '@ngrx/store';

// C4 libs
import { BrokerService } from '@when-then/core';

// common
import { ProgrammingUtilsService as Utils } from './../../../common/services/utils.service';
import { ItemsService } from './../../../common/services/items.service';
import { ProgrammingUtilsService as Util } from '../../../common/services/utils.service';
import { SharedProgrammingService, CommandData, CommonProgrammingContext } from '../../../common/services/shared-programming.service';

// module
import { Item, Device, Location } from '../../../common/interfaces/item.interface';
import { ItemsState } from '../../../common/services/items.service';
import { Room } from '../../quickstart-lights/services/interfaces/room.interface';
import { LEGACY_KEYPAD_PROXIES } from '../../quickstart-keypads/services/keypads.service';
import { ValueType, Parameter } from '../../../common/interfaces/parameter.interface';
import { Command } from '../../../common/interfaces/command.interface';

/**
 * List of commands supported by
 * the LEDColors quickstart
 *
 * @type {Array<string>}
 */
const COMMAND_WHITELIST = ['SET_BACKLIGHT_COLOR', 'SET_ALL_LED', 'SET_BUTTON_COLOR', // <- light v2
  'KEYPAD_BUTTON_COLOR', 'KEYPAD_ALL_BUTTON_COLOR', 'KEYPAD_BUTTON_INFO',// <- keypads
  'SET_LED_COLOR', 'SET_LED_ON', 'SET_LED_OFF', 'SET_ALL_LED_COLOR', 'SET_ALL_LED_ON_COLOR', 'SET_ALL_LED_OFF_COLOR',// <- legacy keypads
  'SET_TOP_LED_ON', 'SET_TOP_LED_OFF', 'SET_BOTTOM_LED_ON', 'SET_BOTTOM_LED_OFF']; // <- light v1

// default color white
export const HEX_COLOR_DEFAULT = "#ffffff";

export interface LEDColorsProgrammingContext {
  // define state here
  ready: boolean;
  command?: Command;
  action?: Action;
}

interface Action {
  ledColorDevice?: Device;
  commands?: Command[];
  command?: CommandData;
}

const INITIAL_STATE: LEDColorsProgrammingContext = {
  ready: false,
  action: {},
}

const STORE_NAME = ['PROGRAMMING', 'QUICKSTARTS', 'LEDCOLORS'].join(':');

const ACTIONS = {
  SET_ACTION: `${STORE_NAME}:SET_ACTION`,
  SET_COMMANDS: `${STORE_NAME}:SET_COMMANDS`,
  SET_COMMAND: `${STORE_NAME}:SET_COMMAND`,
  SET_COMMAND_VALUES: `${STORE_NAME}:SET_COMMAND_VALUES`,
  SET_LEDCOLORS_READY: `${STORE_NAME}:SET_LEDCOLORS_READY`
}

const ACTION_STRINGS = Object.keys(ACTIONS).map(key => ACTIONS[key]);

export function ledColorsProgrammingReducer(state: LEDColorsProgrammingContext = INITIAL_STATE, { type, payload }): LEDColorsProgrammingContext {

  switch (type) {

    case ACTIONS.SET_LEDCOLORS_READY:
      return Object.assign({}, state, { devices: payload });

    case ACTIONS.SET_ACTION:
      let a1 = Object.assign({}, state.action, { ledColorDevice: payload });
      return Object.assign({}, state, { action: a1 });

    case ACTIONS.SET_COMMANDS:
      let a2 = Object.assign({}, state.action, { commands: payload });
      return Object.assign({}, state, { action: a2 });

    case ACTIONS.SET_COMMAND:
      let a3 = Object.assign({}, state.action, { command: payload });
      return Object.assign({}, state, { action: a3 });

    case ACTIONS.SET_COMMAND_VALUES:
      let commands: Command[] = [...state.action.commands];
      let commandIx = commands.findIndex(c => {
        return c.command === payload.command.command &&
          c.id === payload.command.id
      });

      commands[commandIx].params = [...payload.params];

      return Object.assign({}, state, { action: Object.assign({}, state.action, { commands: commands }) });

    default:
      return state;
  }
}

@Injectable()
export class LEDColorsService {

  private _ledDevices: Observable<Device[]>;
  public ledDevicesByRoom: Observable<Room[]>;

  public actionLEDDevices: Observable<Device[]>;
  public actionLEDDevicesByRoom: Observable<Room[]>;
  private _action: Observable<Action>;
  private _actionCommand: Observable<CommandData>;

  constructor(
    private _broker: BrokerService,
    private _store: Store<{
      ledColorsProgramming: LEDColorsProgrammingContext,
      sharedProgramming: CommonProgrammingContext,
      programmingItems: ItemsState
    }>,
    private _shared: SharedProgrammingService,
    private _items: ItemsService,
    private _router: Router,
    private _utils: Util
  ) {
    this._ledDevices = <Observable<Device[]>>this._items.itemsList
      .pipe(
        map(items => {
          return items.filter((item: any) => {
            return ((item.type === 7) &&
              ((item.proxy === 'keypad_proxy') ||
                (['light_v2', 'light', 'fan', ...LEGACY_KEYPAD_PROXIES].indexOf(item.proxy) >= 0)));
          })
        })
      );
    this.ledDevicesByRoom = <Observable<Room[]>>this._byRoom(this._ledDevices);

    this.actionLEDDevices = this._ledDevices
      .pipe(
        map(devices => devices.filter((device: Device) => {
          return device.commands && device.commands.command.some(c => COMMAND_WHITELIST.indexOf(c.name) > -1);
        }))
      );
    this.actionLEDDevicesByRoom = this._byRoom(this.actionLEDDevices);

    this._action = this._store.select(s => s.ledColorsProgramming.action);
    this._actionCommand = this._action
      .pipe(
        filter(a => !!a),
        map((a: any) => a.command)
      );

    this._monitorAction();
  }

  setActionLEDDevice(d: Device | number): void {
    if (typeof d == 'number') {
      this._getDeviceById(d)
        .pipe(
          filter(d => !!d),
          take(1)
        )
        .subscribe(d => {
          this._store.dispatch({
            type: ACTIONS.SET_ACTION,
            payload: d
          })
        })
    } else {
      this._store.dispatch({
        type: ACTIONS.SET_ACTION,
        payload: d
      });
    }
  }

  setActionCommand(command: Command, params: Parameter[] = []) {
    params = params.filter(p => !!p).map(p => {
      if (p.type === "HEXCOLOR") // strip out # char
        p._value = (<string>p._value).replace("#", "");
      return p;
    });

    if (params.length > 0) {
      params.forEach((param) => {
        param.value = param._value;
      });
    }

    command.params = params;

    const data = Utils.buildCommandData(1, command);
    this._store.dispatch({ type: ACTIONS.SET_COMMAND, payload: data });

    // NOTE this is required to support conditional led color actions
    this._shared.setPendingAction(command, data);
  }

  updateParam = (evt: ValueType, param: Parameter, command: Command) => {
    let newParam: Parameter = Object.assign({}, param, {
      _value: evt.value,
      _display: evt.display
    });

    let params = [...command.params];

    let pix = params.findIndex(p => p.name === newParam.name && p.type === newParam.type);

    params[pix] = newParam;

    this.updateParams(params, command);
  }

  updateParams = (params: Parameter[], command: Command) => {
    this._store.dispatch({
      type: ACTIONS.SET_COMMAND_VALUES,
      payload: {
        params: params,
        command: command
      }
    })
  }

  /**
 * Monitors the action in the
 * store, and sets commands as
 * it changes
 */
  private _monitorAction = () => {
    this._action
      .pipe(
        filter(a => !!a),
        map((a: any) => {
          //console.log('ledColorDevice: device changed', a);
          return (!!a.ledColorDevice && !!a.ledColorDevice.URIs && !!a.ledColorDevice.URIs.commands) ?
            a.ledColorDevice.URIs.commands :
            undefined;
        }),
        distinctUntilChanged<string>(Util.distinct)
      )
      .subscribe(
        uri => {
          //console.log('ledColorDevice: device uri is', uri);
          if (uri === undefined) {
            this._store.dispatch({ type: ACTIONS.SET_COMMANDS, payload: undefined });
          } else {
            //console.log('ledColorDevice: getting commands for device', uri);
            this._broker.call({
              path: uri
            }).then(
              (commands: Command[]) => {
                //console.log('ledColorDevice: whitelisting device commands', commands);
                let whiteListed = commands.filter(c => COMMAND_WHITELIST.indexOf(c.command) > -1);
                this._store.dispatch({ type: ACTIONS.SET_COMMANDS, payload: whiteListed });
              },
              error => console.error('led-colors.service.ts: error: %O', error)
            );
          }
        },
        error => console.error('led-colors.service.ts: error: %O', error)
      );
  }

  private _getDeviceById = (id: number): Observable<Device> => {
    return this._ledDevices.pipe(map(devices => devices.find(d => d.id === id)));
  }

  private _byRoom = (devicesObservable: Observable<Device[]>): Observable<Room[]> => {
    return devicesObservable
      .pipe(
        map(devices => devices.reduce((rooms: Room[], device) => {
          let room = rooms.find(r => r.roomId === device.roomId);

          if (room === undefined) {
            room = {
              roomName: device.roomName,
              roomId: device.roomId,
              devices: []
            }

            rooms.push(room);
          }

          room.devices.push(device);

          return rooms;
        }, []))
      );
  }
}
