<div class="event">
  <div class="header flex-row nowrap space-between">
    <div class="grow blue title">When &gt;&gt; Then Automations</div>
    <span (click)="addClicked()" class="blue link">
      <span class="icon leading blue small add-circled"></span>
      <span> Add New Automation</span>
    </span>
  </div>
  <main>
    <cxScrollable>
      <cxWait [cxUntil]="!!(event | async)" cxText="{{waitMessage | async}}  Please wait...">
        <prg-when-then-header></prg-when-then-header>
        <div prg-event-codeitems [expanded]="expanded" [event]="event | async" [subtitle]="(schedule | async | scheduleEventLabel)"></div>
        <router-outlet></router-outlet>
      </cxWait>
    </cxScrollable>
  </main>
</div>˝
