import { EventType } from './event-type.interface';

export const DOOR_LOCK: EventType = {
  name: 'Door Lock',
  description: 'When a door is locked, unlocked, or otherwise changed',
  route: 'door-lock',
  category: 'Security & Access',
  icon: 'door-lock',
  whitelist: [
    {
      fields: { proxy: 'relaysingle_doorlock_c4' },
      events: [1, 2]
    },
    {
      fields: { proxy: 'Lock_Zigbee_Baldwin_SmartLock' },
      events: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    },
    {
      fields: { proxy: 'DriverWorks_BlackDecker_Lock' },
      events: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    },
    {
      fields: { proxy: 'DriverWorks_BlackDecker_Lock_SmartLock RF Zigbee_Black and Decker' },
      events: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    },
    {
      fields: { proxy: 'Lock_Zigbee_Kwikset_SmartLock' },
      events: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    },
    {
      fields: { proxy: 'Lock_ZigBee_Yale' },
      events: [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22]
    },
    {
      fields: { proxy: 'lock' },
      events: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    }
  ]
}
