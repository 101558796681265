import { Observable } from 'rxjs';

import { StreamingService } from './interfaces/streaming.interface';
import { Playlist } from './interfaces/playlist.interface';

export const Tidal = {

  protocolFilename: 'tidal.c4z',

  Albums: {
    collectionEndpoint: '/api/v1/items/[protocolId]/custom_select_data?command=SelectAlbumName',
    selectionEndpoint: '/api/v1/items/[protocolId]/commands',
    selectionCommand: 'SelectAlbum'
  },

  Playlists: {
    collectionEndpoint: '/api/v1/items/[protocolId]/custom_select_data?command=SelectPlaylistName',
    selectionEndpoint: '/api/v1/items/[protocolId]/commands',
    selectionCommand: 'SelectPlaylist'
  }
}
