import { ScheduleViewModel } from './../quickstart-schedule/+state/schedule.interfaces';
import { EVENTS_UI_ACTIONS, EventsUIService } from './../events/eventsUI.service';
import { MatchingSearchTextPipe } from './../../common/pipes/matching-search-text.pipe';
import { HappeningTodayPipe } from './../../common/pipes/happening-today.pipe';
import { CreatorPipe } from './../../common/pipes/creator.pipe';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';

import { BaseRoutingComponent } from '../../common/base-routing/base-routing.component';
import { SharedProgrammingService, CommonProgrammingContext } from './../../common/services/shared-programming.service';
import { SchedulesService } from '../../quickstarts/quickstart-schedule/schedule.service';
import { EVENTS_FILTER } from '../events/eventsUI.service';
import { ScheduleEvent, Schedules } from '../quickstart-schedule/+state/schedule.interfaces';
import { switchMap, filter, map } from 'rxjs/operators';

@Component({
  selector: 'prg-today',
  templateUrl: './today.component.html',
  styleUrls: ['./today.component.less']
})
export class TodayComponent extends BaseRoutingComponent implements OnInit {
  currentGrouping: Observable<any>;
  searchText: Observable<string>;
  initialized: Observable<boolean>;
  scheduleEvents: Observable<ScheduleEvent[]>;

  expanded = false;

  @Input() source: ScheduleEvent[];

  @ViewChild('filterMenu') filterMenu;

  constructor(
    protected router: Router,
    protected shared: SharedProgrammingService,
    protected store: Store<{
      scheduleProgramming: Schedules,
      sharedProgramming: CommonProgrammingContext
    }>,
    private eventsUI: EventsUIService
  ) {
    super();

    this.initialized = this.store.select(s => s.sharedProgramming.initialized);
    this.searchText = this.store.select(s => s.sharedProgramming.searchText);
  }

  ngOnChanges() {
    if (!!this.source) {
      this.eventsUI.setEventCount(this.source.length);
    }
  }

  ngOnInit() {
    this.shared.setGrouping('today');
  }

  tileClicked(item: any) {
    console.log('tile clicked: ', item);
    if (item) {
      // NOTE selected event has to be set manually for schedule events
      // or maybe not
      // this.shared.setSelectedEvent(item);
      this.router.navigate(['when-then', 'device', item.deviceId, 'event', item.eventId, 'edit'], { relativeTo: this.moduleRoot });
    }
  }
}
