import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LoggerFactory } from '@when-then/core';

@Component({
  selector: 'prg-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.less']
})
export class ConfirmModalComponent implements OnInit {

  @Input() title: string;
  @Input() message: string;
  @Input() confirmLabel: string;
  @Input() confirmClass: string;
  @Input() disabled: boolean;

  @Output() confirm: EventEmitter<any>;
  @Output() cancel: EventEmitter<any>;

  private _logger = LoggerFactory.getLogger(ConfirmModalComponent.name);

  constructor() {
    this.confirm = new EventEmitter<any>();
    this.cancel = new EventEmitter<any>();
  }

  ngOnInit() {
  }

  modalClicked(event: MouseEvent) {
    this._logger.debug('modal clicked', event);
    event.stopPropagation();
  }

  cancelClicked(event: MouseEvent) {
    this._logger.debug('cancel clicked', event);
    event.stopPropagation();
    this.cancel.next();
  }

  confirmClicked(event: MouseEvent) {
    this._logger.debug('confirm clicked', event);
    event.stopPropagation();
    this.confirm.next();
  }
}
