import { ConditionalType, ConditionalRef } from './../conditional-types/conditional-type.interfaces';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { SharedProgrammingService, CommonProgrammingContext } from '../../../common/services/shared-programming.service';
import { FlowControlService } from '../flow-control.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Conditional } from '../../../common/interfaces/conditional.interface';

import * as moment from 'moment';
import { LoggerFactory } from '@when-then/core';
import { ProgrammingUtilsService as Utils } from '../../../common/services';
import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';
import { Store } from '@ngrx/store';
import { Parameter } from '../../../common/interfaces/parameter.interface';

@Component({
  templateUrl: './conditional-params.component.html',
  styleUrls: ['./conditional-params.component.less']
})
export class ConditionalParamsComponent extends BaseRoutingComponent implements OnInit {

  errors: string[];
  ready: Observable<boolean>;
  conditionalType: Observable<ConditionalType>;
  conditional: Observable<Conditional>;
  params: Parameter[];
  currentYear: number;
  currentDate: string;
  saving = false;

  /**
   * TODO: make this more dynamic to scale to other conditional types
   * @see https://medium.com/@DenysVuika/dynamic-content-in-angular-2-3c85023d9c36
   */

  private _logger = LoggerFactory.getLogger(ConditionalParamsComponent.name);

  constructor(
    protected router: Router,
    protected store: Store<{
      sharedProgramming: CommonProgrammingContext
    }>,
    private route: ActivatedRoute,
    private shared: SharedProgrammingService,
    private flowControl: FlowControlService
  ) {
    super();

    this.errors = [];
    this.ready = this.shared.isReady;
    this.conditionalType = this.flowControl.conditionalType;
    this.conditional = this.flowControl.conditional;

    this.currentYear = moment().year();
    this.currentDate = moment().format('YYYY-MM-DD');

    this.conditional.pipe(
      filter(c => !!c),
    ).subscribe(cond => {
      this._logger.debug('selected conditional', cond);
      this.params = cond.params;

      this.params.forEach(p => {
        // establish some reasonable default values
        switch (p.type) {
          case 'TIMEOFDAY': p.value = moment().format('HH:mm'); break;
          case 'LIST': p.value = p.values[0][p.valueField]; break;
          case 'RANGED_INTEGER': p.value = p.low; break;
        }

        // transform "{valueDisplay}" to "valueDisplay" for indexing value fields correctly
        if (!!p.valueDisplay && p.valueDisplay.charAt(0) === '{') {
          p.valueDisplay = p.valueDisplay.substring(1, p.valueDisplay.length - 1);
        }
      })

      this._logger.debug('conditional params', this.params);
    });
  }

  findParam(name: string): Parameter {
    if (!!name && !!this.params) {
      return this.params.find(p => p.name === name);
    }
    return null;
  }

  async addAction() {
    this._logger.debug('add action, saving', this.saving);
    if (!this.saving) {
      this.saving = true;
      this._logger.debug('saving action with params');
      this.shared.ready(false);
      try {
        const cond = Utils.snapshot<Conditional>(this.conditional);
        cond.params = this.params;

        const ref = Utils.snapshot<ConditionalRef>(this.store.select(s => s.sharedProgramming.action.conditionalRef));
        this._logger.debug('checking conditional ref for validator', ref);
        let errors = [];
        if (!!ref && !!ref.validate) {
          this._logger.debug('validating conditional', cond);
          errors.concat(ref.validate(cond));
          this.saving = false;
          this.shared.ready(true);
        }

        if (errors.length === 0) {
          await this.flowControl.saveConditionalAction(cond);
          this.goToEvent();
        } else {
          this.saving = false;
        }

      } catch (e) {
        this.errors.push('An error occurred while adding this conditional action. Please try again.');
        this._logger.error('error adding conditional action with params', e);
        this.saving = false;
        this.shared.ready(true);
      }
    }
  }

  ngOnInit() {
  }
}
