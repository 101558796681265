import { SPRINKLER_SYSTEM } from './sprinkler-system.type';
import { DOOR_LOCK } from './door-lock.type';
import { BLINDS } from './blinds.type';
import { FAN } from './fan.type';

export const CommandTypes = [
  DOOR_LOCK,
  BLINDS,
  FAN,
  SPRINKLER_SYSTEM,
];
