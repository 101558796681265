import { Action } from '@ngrx/store';
import { ScheduleEvent } from './schedule.interfaces';

const CONTAINER = 'AUTOMATION:WHENTHEN:SCHEDULES:';

export const SET_SCHEDULE_EVENTS = `${CONTAINER}SET_SCHEDULE_EVENTS`;
export const SET_SCHEDULES_READY = `${CONTAINER}SET_SCHEDULES_READY`;
export const SET_SELECTED_SCHEDULE = `${CONTAINER}SET_SELECTED_SCHEDULE`;
export const CLEAR_SCHEDULE_EVENT = `${CONTAINER}CLEAR_SCHEDULE_EVENT`;
export const SET_DEFAULT_SCHEDULE = `${CONTAINER}SET_DEFAULT_SCHEDULE`;
export const CLEAR_STATE = `${CONTAINER}CLEAR_STATE`;
export const SET_INITIALIZED = `${CONTAINER}SET_INITIALIZED`;
export const SET_WAIT_MESSAGE = `${CONTAINER}SET_WAIT_MESSAGE`;

export class SetScheduleEvents implements Action {
  constructor(public payload: Array<ScheduleEvent>) { }
  readonly type = SET_SCHEDULE_EVENTS;
}

export class SetSchedulesReady implements Action {
  constructor(public payload: boolean) { }
  readonly type = SET_SCHEDULES_READY;
}

export class SetSelectedSchedule implements Action {
  constructor(public payload: ScheduleEvent) { }
  readonly type = SET_SELECTED_SCHEDULE;
}

export class ClearScheduleEvent implements Action {
  readonly type = CLEAR_SCHEDULE_EVENT;
  readonly payload = undefined;
}

export class ClearState implements Action {
  readonly type = CLEAR_STATE;
  readonly payload = undefined;
}

export class SetInitialized implements Action {
    constructor(public payload: boolean) {}
    readonly type = CLEAR_STATE;
}

export class SetWaitMessage implements Action {
  constructor(public payload: string) {}
  readonly type = SET_WAIT_MESSAGE;
}

export type SchedulesAction = 
  SetScheduleEvents | 
  SetSchedulesReady | 
  SetSelectedSchedule |
  ClearScheduleEvent |
  ClearState |
  SetInitialized |
  SetWaitMessage;

