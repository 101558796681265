import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';

export enum EVENTS_BY {
  DESCRIPTION,
  TODAY,
  SCHEDULED,
  ROOM,
  DEVICE
}

export enum EVENTS_FILTER {
  ALL = 0,
  MINE = 1
}

export interface EventsUIContext {
  by: EVENTS_BY;
  filter: EVENTS_FILTER;
  currentCount: number;
}

const INITIAL_STATE: EventsUIContext = {
  by: EVENTS_BY.DESCRIPTION,
  filter: EVENTS_FILTER.ALL,
  currentCount: 0
}

const STORE_NAME = ['PROGRAMMING', 'QUICKSTARTS', 'EVENTS', 'UI'].join(':');

export const EVENTS_UI_ACTIONS = {
  SET_BY: `${STORE_NAME}:SET_BY`,
  SET_FILTER: `${STORE_NAME}:SET_FILTER`,
  SET_CURRENT_COUNT: `${STORE_NAME}:SET_CURRENT_COUNT`
}

const EVENTS_UI_ACTION_STRINGS = Object.keys(EVENTS_UI_ACTIONS).map(key => EVENTS_UI_ACTIONS[key]);

export function eventsUIReducer(state: EventsUIContext = INITIAL_STATE, { type, payload }): EventsUIContext {

  switch (type) {

    case EVENTS_UI_ACTIONS.SET_BY:
      return Object.assign({}, state, { by: parseInt(payload) });

    case EVENTS_UI_ACTIONS.SET_FILTER:
      console.log('setting creator', payload);
      return Object.assign({}, state, { filter: payload });

    case EVENTS_UI_ACTIONS.SET_CURRENT_COUNT:
      return Object.assign({}, state, { currentCount: payload });

    default:
      return state;
  }
}

@Injectable()
export class EventsUIService {

  constructor(
    private store: Store<{eventsUI: EventsUIContext}>
  ) { }

  setEventCount(count: number) {
    this.store.dispatch({type: EVENTS_UI_ACTIONS.SET_CURRENT_COUNT, payload: count});
  }
}
