import { EventType } from './event-type.interface';

export const ROOM_MEDIA_PLAY: EventType = {
  name: 'Room Media Play',
  description: 'When the Play Audio/Video command is received by a room',
  route: 'rooms/2054',
  category: 'Room Media',
  icon: 'play',
  whitelist: [
    {
      fields: { proxy: 'roomdevice' },
      events: [2054]
    }
  ]
}
