import { ActionReducerMap } from '@ngrx/store';

import { roomsProgrammingReducers, RoomsProgrammingContext } from './quickstart-rooms.service';

export interface QuickstartRoomsState {
  roomsProgramming: RoomsProgrammingContext
}

export const QUICKSTART_ROOMS_REDUCERS: ActionReducerMap<QuickstartRoomsState> = {
  roomsProgramming: roomsProgrammingReducers
}
