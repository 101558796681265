<div>
  <prg-nav-header title="Create Voice Scene" (cancel)="goHome()">
    <div right>
      <prg-working *ngIf="working"></prg-working>
    </div>
  </prg-nav-header>
  <div>
    <div class="alert alert-warning" *ngIf="!working && sceneExists | async">
      <i class="icon information small"></i> It looks like you already have a Voice Scene called "{{ sceneName.value
      }}". If you wish to add actions to this scene, please proceed. If you wish to create a new scene instead, please
      choose another name.
    </div>
    <form class="scene">
      <div class="field-group">
        <label for="onOff" class="leading">When I say: </label>
        <select required name="onOff" id="onOff" [formControl]="onOff">
          <option value="on">Turn On</option>
          <option value="off">Turn Off</option>
        </select>
        <input required type="text" name="sceneName" id="sceneName" placeholder="Scene Name" [formControl]="sceneName">
      </div>
    </form>
  </div>
  <footer class="flex row transparent">
    <span class="preview">When I say "{{ (onOff.value == 'on') ? 'Turn On ' : 'Turn Off ' }} {{ sceneName.value }}"</span>
    <button class="blue clickable" [disabled]="!sceneName.value || working" (click)="addActions()">Next: Add Actions</button>
  </footer>
</div>
