import { ActionReducerMap } from '@ngrx/store';

import { schedulesReducer } from "./+state/schedule.reducer";
import { Schedules } from './+state/schedule.interfaces';

export interface QuickstartScheduleState {
  scheduleProgramming: Schedules
}

export const QUICKSTART_SCHEDULE_REDUCERS: ActionReducerMap<QuickstartScheduleState> = {
  scheduleProgramming: schedulesReducer
};
