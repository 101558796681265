import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService } from './base.service';

import { BrokerService } from './broker.service';

@Injectable()
export class CategoryService extends BaseService {

  constructor(
    private broker: BrokerService
  ) {
    super();
  }

  getCategories(): Promise<Array<string>> {
    console.log('categories:getCategories()');
    var request: any = {
      path: '/api/v1/categories'
    };

    return this.broker.call(request);
  }

  getItems(category: string, filter?: Object, fields?: Array<string>): Promise<Array<any>> {
    console.log('categories:getItems()');
    var request: any = {
      path: '/api/v1/categories/' + category,
      queryString: { filter: filter, fields: fields }
    };

    return this.broker.call(request);
  }


  getVariables(category: string, variables?: Array<string>): Promise<Array<any>> {
    console.log('categories:getVariables()');
    return new Promise((resolve, reject) => {
      var request: any = {
        path: '/api/v1/categories/' + category + '/variables',
        queryString: { varNames: variables }
      };

      this.broker.call(request).then(
        (result) => {
          resolve(result);
        },
        (err) => {
          console.error('error getting category variables', err);
          reject(err);
        });
    });
  }


  watchVariables(category: string, variables?: Array<string>): Promise<Observable<any>> {
    console.log('categories:watchVariables()');
    var request: any = {
      path: '/api/v1/categories/' + category + '/variables',
      queryString: { varNames: variables }
    };

    return this.broker.subscribe(request);
  }


  getProperties(category: string, properties?: Array<string>): Promise<Array<any>> {
    console.log('categories:getItems()');
    return new Promise((resolve, reject) => {
      var request: any = {
        path: '/api/v1/categories/' + category + '/properties',
        queryString: { properties: properties } 
      };

      this.broker.call(request).then(
        (result) => {
          resolve(result);
        },
        (err) => {
          console.error('error getting category properties', err);
          reject(err);
        });
    });
  }


  watchProperties(category: string, properties?: Array<string>): Promise<Observable<any>> {
    console.log('categories:getItems()');
    var request: any = {
      path: '/api/v1/categories/' + category + '/properties',
      queryString: { properties: properties } 
    };

    return this.broker.subscribe(request);
  }

  // ------------- convenience functions for a single item within the category

  getItem(category: string, id: number, fields?: Array<string>): Promise<any> {
    console.log('categories:getItems()');
    return new Promise((resolve, reject) => {
      var request: any = {
        path: '/api/v1/categories/' + category + '/' + id,
        queryString: { fields: fields } 
      };

      this.broker.call(request).then(
        (result) => {
          resolve(result);
        },
        (err) => {
          console.error('error getting category item', err);
          reject(err);
        });
    });
  }

  getItemVariables(category: string, id: number, variables?: Array<string>): Promise<Array<any>> {
    console.log('categories:getItems()');
    return new Promise((resolve, reject) => {
      var request: any = {
        path: '/api/v1/categories/' + category + '/' + id + '/variables',
        queryString: { varNames: variables } 
      };

      this.broker.call(request).then(
        (result) => {
          resolve(result);
        },
        (err) => {
          console.error('error getting category item variables', err);
          reject(err);
        });
    });
  }

  watchItemVariables(category: string, id: number, callbackFn: Function, variables?: Array<string>): Promise<Observable<any>> {
    console.log('categories:getItems()');
    var request: any = {
      path: '/api/v1/categories/' + category + '/' + id + '/variables',
      queryString: { varNames: variables } 
    };

    return this.broker.subscribe(request);
  }

  getItemProperties(category: string, id: number, properties?: Array<string>): Promise<Array<any>> {
    console.log('categories:getItems()');
    return new Promise((resolve, reject) => {
      var request: any = {
        path: '/api/v1/categories/' + category + '/' + id + '/properties',
        queryString: { properties: properties }
      };

      this.broker.call(request).then(
        (result) => {
          resolve(result);
        },
        (err) => {
          console.error('error getting category item properties', err);
          reject(err);
        });
    });
  }

  watchItemProperties(category: string, id: number, properties?: Array<string>): Promise<Observable<any>> {
    console.log('categories:getItems()');
    var request: any = {
      path: '/api/v1/categories/' + category + '/' + id + '/properties',
      queryString: { properties: properties }
    };

    return this.broker.subscribe(request);
  }

  // TODO network, bindings, documentation, commands, actions, etc.


  // -------------------- general functions

  unwatch(watcher: string): void {
    console.log('categories:getItems()');
    this.broker.unsubscribe(watcher);
  }

}
