import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';

import { Event } from '../../../common/interfaces/event.interface';

@Component({
  selector: 'prg-codeitem-summary,[prg-codeitem-summary]',
  templateUrl: './codeitem-summary.component.html',
  styleUrls: ['./codeitem-summary.component.less']
})
export class CodeitemSummaryComponent {

  @Input() event: Event;

  editing: Observable<boolean>;

  constructor(
    private store: Store<any>,
  ) {
    this.editing = this.store.select(s => s.sharedProgramming.editing);
  }
}
