import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';

import { CommonProgrammingContext, SharedProgrammingService } from '../../common/services/shared-programming.service';
import { ProgrammingUtilsService as Utils, ProgrammingUtilsService } from '../../common/services/utils.service';
import { BaseRoutingComponent } from '../../common/base-routing/base-routing.component';
import { Device } from '../../common/interfaces/item.interface';

@Component({
  selector: 'prg-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.less']
})
export class AddComponent extends BaseRoutingComponent implements OnInit {

  constructor(
    protected router: Router,
    private _route: ActivatedRoute,
    protected store: Store<{ sharedProgramming: CommonProgrammingContext }>,
    protected shared: SharedProgrammingService,
    private _utils: ProgrammingUtilsService
  ) {
    super();
  }

  ngOnInit() {
    this.shared.resetAddScenario();
    this.shared.setAdding(true);
  }

  ngOnDestroy() {
    this.shared.setAdding(false);
  }
}
