import { CommandType } from './command-type.interface';

export const FAN: CommandType = {
  name: 'Fan',
  description: 'Turn on, off or toggle Fan',
  route: 'fan',
  category: 'Comfort',
  order: 3,
  whitelist: [
    {
      proxy: 'relaysingle_fan_c4',
      commands: [
        {
          id: 0,
          command: 'OPEN',
          icon: 'fan'
        },
        {
          id: 1,
          command: 'CLOSE',
          icon: 'fan'
        },
        {
          id: 2,
          command: 'TOGGLE',
          icon: 'fan'
        }
      ]
    },
    {
      proxy: 'fan',
      commands: [
        {
          id: 0,
          command: 'ON',
          icon: 'fan'
        },
        {
          id: 1,
          command: 'OFF',
          icon: 'fan'
        },
        {
          id: 2,
          command: 'TOGGLE',
          icon: 'fan'
        },
        {
          id: 5,
          command: 'SET_SPEED',
          icon: 'fan'
        }
      ]
    }
  ]
}
