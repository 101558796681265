import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, Directive } from '@angular/core';
import { ProgrammingUtilsService as Utils } from '../services/utils.service';
import { Event } from '../interfaces/event.interface';

@Directive()
export abstract class BaseRoutingComponent implements OnInit {

  protected abstract router: Router;
  protected abstract store: Store<any>

  constructor() { }

  ngOnInit() { }

  private mr: ActivatedRoute;

  get moduleRoot(): ActivatedRoute {
    this.mr = this.mr || this.buildModuleRoot();
    // console.log('returning module root', this.mr);
    return this.mr;
  }

  private buildModuleRoot = (): ActivatedRoute => {
    return (function getKids(active: ActivatedRoute): ActivatedRoute[] {
      // console.log('aggregating current and kids from', active);
      return [active].concat(...(active.children || [])
        .map(c => getKids(c)))
    })(this.router.routerState.root).find((active: ActivatedRoute) => {
      // console.log('checking route for module root', active);
      return (typeof active.component === 'function' && active.snapshot.url.length > 0) ?
        active.snapshot.url[0].path === "programming" : false;
    });
  }

  goHome() {
    this.router.navigate(['when-then/events'], { relativeTo: this.moduleRoot });
  }

  goToEvent() {
    let event = Utils.snapshot<Event>(this.store.select(s => s.sharedProgramming.trigger.event));
    if (!!event) {
      this.router.navigate(['when-then', 'device', event.deviceId, 'event', event.eventId, 'edit'], { relativeTo: this.moduleRoot });
    }
  }

  isForTrigger(route: ActivatedRoute): boolean {
    return window.location.href.indexOf('when-then/add') >= 0;
  }
}
