import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { UIModule } from '@when-then/ui';
import { QuickstartCommonModule } from './../quickstart-common/quickstart-common.module';
import { ProgrammingCommonModule } from '../../common/common.module';

import { TimersActionComponent } from './timers-action/timers-action.component';
import { TimersActionTypeComponent } from './timers-action-type/timers-action-type.component';
import { TimersSelectParametersComponent } from './select-parameters/select-parameters.component';
import { TimersSelectActionComponent } from './select-action/select-action.component';
import { TimersService } from './services/timers.service';

import { TimersParamInputComponent } from './timers-params/timers-param-input.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    UIModule,
    ProgrammingCommonModule,
    QuickstartCommonModule
  ],
  providers: [
    TimersService
  ],
  declarations: [
    TimersActionComponent,
    TimersActionTypeComponent,
    TimersSelectParametersComponent,
    TimersSelectActionComponent,
    TimersParamInputComponent
  ]
})
export class QuickstartTimersModule { }
