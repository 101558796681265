import { Observable } from 'rxjs';

import { StreamingService } from './interfaces/streaming.interface';
import { Playlist } from './interfaces/playlist.interface';
import { Album } from './interfaces/album.interface';

export const SiriusXM = {
  protocolFilename: 'SiriusXM.c4z',

  Stations: {
    collectionEndpoint: '/api/v1/items/[protocolId]/custom_select_data?command=SelectFavorite',
    selectionEndpoint: '/api/v1/items/[protocolId]/commands',
    selectionCommand: 'Select Favorite'
  }
}
