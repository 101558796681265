import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

/**
 * @deprecated Use semantic layout instead.  See c4-assets.
 */
@Component({
  selector: 'prg-block-button,[prg-block-button]',
  templateUrl: './block-button.component.html',
  styleUrls: ['./block-button.component.less']
})
export class BlockButtonComponent implements OnInit {

  @Input() leadingIcon: string;
  @Input() trailingIcon: string;

  @Input() title: string;
  @Input() titleClass: string;
  @Input() subtitle: string;
  @Input() subtitleClass: string;

  @Input() buttonClass: string;
  @Input() badge: string;

  constructor() {
  }

  ngOnInit() {
  }
}
