import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, combineLatest, empty, Subscription } from 'rxjs';
import { filter, map, shareReplay, timeout, catchError, take, concatMap, tap } from 'rxjs/operators';

import { BrokerService } from '@when-then/core';

import { Device } from '../../../common/interfaces/item.interface';

import { StreamingServices } from './drivers/services';
import { Collection, MediaItem } from './drivers/interfaces/streaming.interface';
import { QSMediaService } from '../quickstart-media.service';

@Injectable()
export class DataToUIShimService {

  constructor(
    private _broker: BrokerService,
    private _http: HttpClient
  ) { }

  public customCommandData(
    driver: Observable<Device>,
    category: Observable<string>
  ): Observable<MediaItem[]> {

    const collectionCfg = combineLatest(
      driver,
      category
    )
      .pipe(
        filter(([driver, cat]) => !!driver && !!cat),
        map(([driver, cat]) => {
          const svc = StreamingServices.find(s => s.protocolFilename == driver.protocolFilename);
          return svc[cat];
        })
      );

    // We need to be able to share the device ID
    // between a couple different requests that
    // need to be sequenced correctly, and since
    // it's cumbersome to pass synchronous data
    // through an Observable pipe along with the
    // response to a request, we're making it a
    // separate observable
    const requestVars = combineLatest(
      driver,
      collectionCfg
    )
      .pipe(
        map(([driver, cfg]) => QSMediaService.interpolate(cfg.collectionEndpoint, driver)),
        map(endpoint => {
          return {
            id: endpoint.split('/')[4],
            command: endpoint.split('command=')[1]
          }
        }),
        shareReplay(1)
      );

    // We need to be able to issue a command
    // AFTER the _datatoui subscription has been
    // established, but without losing the info
    // returned from the _datatoui subscription.
    // Make it an independent observable so the
    // UI and Broker can each do what they need
    // without awkward juggling
    const datatoui = requestVars
      .pipe(
        concatMap(vars => this._broker.getObservable({
          path: `/api/v1/items/${vars.id}/datatoui`
        })),
        shareReplay(1)
      );

    // Once the subscription has started, we
    // need to issue the custom_select_data command,
    // so we're subscribing to _datatoui and watching for
    // the "started" message before issuing the
    // command. Note that we don't actually care
    // about the response, and since it will time
    // out under unconfigured 2.10.x systems,
    // we're just setting an arbitrarily short
    // timeout and ignoring the response
    const sub = combineLatest(
      requestVars,
      datatoui
    )
      .pipe(
        filter(([vars, response]: [any, any]) => response.status == 'started'),
        take(1),
        concatMap(([vars, response]) => {
          const req = this._broker.buildRequest({
            path: `/api/v1/items/${vars.id}/commands`,
            method: 'POST',
            data: {
              command: 'GET_CUSTOM_SELECT_DATA',
              async: false,
              tParams: {
                Function: vars.command
              }
            }
          });

          return this._http.request(req);
        })
      )
      .subscribe(); // TODO: don't leave this hanging open

    // Here we're just watching for the
    // custom_select_data to come back for
    // the UI to consume
    return datatoui
      .pipe(
        filter((response: any) => !!response[0]
          && !!response[0].data
          && !!response[0].data.devicecommand
          && response[0].data.devicecommand.command == 'CUSTOM_SELECT_DATA'),
        map((response: any) => {
          const item = response[0].data.devicecommand.params.param.find(p => p.name == 'DATA').value.static.list.item || [];
          if (Array.isArray(item)) {
            return item;
          }

          return [item];
        }),
        take(1),
        shareReplay(1)
      );
  }
}
