import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';
import { QSNotificationsService, PushNotificationTemplate, NotificationProgrammingContext } from '../services/notifications.service';

@Component({
  selector: 'wt-select-notification',
  templateUrl: './select-notification.component.html',
  styleUrls: ['./select-notification.component.less']
})
export class QSSelectPushNotificationComponent extends BaseRoutingComponent {

  templates: Observable<PushNotificationTemplate[]>;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected store: Store<{ notificationsProgramming: NotificationProgrammingContext }>,
    private _notifications: QSNotificationsService
  ) {
    super();

    this.templates = this._notifications.pushTemplates;
    this._notifications.setAgentType('push');
    this._notifications.getPushTemplates();
  }

  setCommand(template: PushNotificationTemplate) {
    this._notifications.setSendNotificationCommand(template);
    this.router.navigate(['conditionals'], { relativeTo: this.route });
  }
}
