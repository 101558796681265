import { ProgrammingUtilsService as Utils } from './../services/utils.service';
import { LocationsService, Location, LoggerFactory } from '@when-then/core';
import { Pipe, PipeTransform } from '@angular/core';

import { ItemsService } from './../services/items.service';
import { AgentMetaService } from './../services/agent-meta.service';
import { DeviceCategoryService } from './../services/device-category.service';
import { Item, Device } from '../interfaces/item.interface';

@Pipe({
  name: 'icon'
})
export class IconPipe implements PipeTransform {

  private _logger = LoggerFactory.getLogger(IconPipe.name);

  constructor(
    private items: ItemsService,
    private categories: DeviceCategoryService,
    private agents: AgentMetaService,
    private locations: LocationsService
  ) { }

  transform(value: any, args?: any): any {
    let icon = 'unknown';

    if (!!value) {
      let id = value.deviceId || value.id;
      let item = this.items.getItem(id);
      // if this is a code item handle flow control icons as a special case
      // if (!!value.codeItemId) {
      // special case: the program flow agent requires knowledge of the command to infer an icon correctly
      if (id === 100000 && !!value.command) {
        // HACK this is exceeding weird, but sometimes we get the command as it's being created,
        // and sometimes after it's been created, the formats are slightly different.  Good thing
        // we're in JS or this would never work, probably worth splitting the icon pipe up
        switch (value.command.command || value.command) {
          case 'DELAY': icon = 'control-delay'; break;
          case 'BREAK': icon = 'control-break'; break;
          case 'RETURN': icon = 'control-stop'; break;
        }
      } else if (!!value.type && value.type > 1) {
        switch (value.type) {
          case 2: icon = 'control-conditional'; break;
          case 3: icon = 'control-loop'; break;
          case 4: icon = 'control-else'; break;
        }
      }
      // }

      if (icon === 'unknown') {
        if (!!id) {
          // console.log('icon: item for icon ', item);
          if (!!item) {
            // if this is an agent, resolve the icon using the agent meta service
            if (item.type === 9) {
              icon = this.agents.getIconForId(id);
            } else {
              icon = this.categories.getCategoryIconForItem(item);
            }
          } else {
            // might be a location
            let loc = Utils.snapshot<Location>(this.locations.getLocationById(id));
            if (!!loc) {
              if (loc.type === 8) {
                icon = 'room';
              }
            }
          }
        }
      }

      if (icon === 'unknown') {
        icon = this._getIconForProxy(value, item);
      }


      if (!value.enabled || !!value.protected || !!value.hasProtected) {
        icon += ' protected grey';
      }
    }

    // this._logger.debug('icon for', value, icon);
    return icon;
  }

  private _getIconForProxy(value: any, item: Device): string {
    switch (item.proxy) {
      case 'relaysingle_sprinklers_c4':
        return 'water';
    }

    return 'unknown';
  }
}
