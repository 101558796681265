import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { BrokerService } from '@when-then/core';

import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';
import { ItemsState } from '../../../common/services/items.service';
import { Device } from '../../../common/interfaces/item.interface';

import { MediaProgrammingContext } from '../quickstart-media.service';
import { StreamingServices } from './drivers/services';

@Component({
  templateUrl: './select-service.component.html'
})
export class QSMediaSelectStreamingServiceComponent extends BaseRoutingComponent {

  public services: Observable<Device[]>;

  constructor(
    public router: Router,
    protected store: Store<{
      mediaProgramming: MediaProgrammingContext,
      programmingItems: ItemsState
    }>
  ) {
    super();

    this.services = this.store.select(s => s.programmingItems)
      .pipe(
        filter(state => !!state.itemsList && state.itemsList.length > 0),
        map(state => <Device[]>state.itemsList
          .filter((item: Device) => {
            return !!item.protocolFilename
              && item.protocolId != item.id
              && StreamingServices.findIndex(svc => {
                return item.protocolFilename == svc.protocolFilename;
              }) > -1;
          })
          .sort((a, b) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
            if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
            return 0;
          })
        )
      )
  }
}
