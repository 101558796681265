import { DeviceEvent } from './../interfaces/event.interface';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'interactionIcon'
})
export class InteractionIconPipe implements PipeTransform {

  transform(event: DeviceEvent, args?: any): any {
    let icon = args || 'unknown';

    if (event && event.display) {
      let disp = event.display.toLowerCase();
      if (disp.indexOf('press') >= 0 || disp.indexOf('push') >= 0) {
        icon = 'touch-press';
      } else if (disp.indexOf('release') >= 0) {
        icon = 'touch-release';
      } else if (disp.indexOf('single') >= 0) {
        icon = 'touch-single-tap';
      } else if (disp.indexOf('double') >= 0) {
        icon = 'touch-double-tap';
      } else if (disp.indexOf('triple') >= 0) {
        icon = 'touch-triple-tap';
      }
    }

    return icon;
  }

}
