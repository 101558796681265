<prg-nav-header
  title="What would you like to stream from {{ (driver | async)?.name }}?"
  (cancel)="goToEvent()">
</prg-nav-header>
<cxScrollable>
  <cxWait
    [cxUntil]="(categories | async) != undefined"
    cxText="Finding streaming categories...">

    <prg-block-button
      *ngFor="let category of (categories | async)"
      [routerLink]="['./', category]"
      [title]="display[category]"
      leadingIcon="song blue"
      trailingIcon="right">
    </prg-block-button>

  </cxWait>
</cxScrollable>
