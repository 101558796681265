import { ProgrammingUtilsService as Utils } from './../../../common/services/utils.service';
import { CodeItemType } from './../../../common/interfaces/item.interface';
import { CommandData } from './../../../common/services/shared-programming.service';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, combineLatest } from 'rxjs';
import { filter, map, flatMap, share } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { BrokerService, LoggerFactory } from '@when-then/core';

import { Device } from '../../../common/interfaces/item.interface';
import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';
import { SharedProgrammingService, CommonProgrammingContext } from '../../../common/services/shared-programming.service';
import { CommandType, Criteria } from '../command-types/command-type.interface';
import { CommandTypes } from '../command-types/command-types';
import { SimpleProgrammingContext } from '../quickstart-simple.service';
import { FlowControlService } from '../../quickstart-flow-control/flow-control.service';
import { Command } from '../../../common/interfaces/command.interface';


@Component({
  templateUrl: './select-action.component.html',
  styleUrls: ['../select-device/select-device.component.less']
})
export class QSSimpleSelectActionComponent extends BaseRoutingComponent {

  private _adding = false;
  private _logger = LoggerFactory.getLogger(QSSimpleSelectActionComponent.name);

  _device: Observable<Device>;
  _commandType: Observable<CommandType>;
  _whitelist: Observable<Criteria>;
  _commands: Observable<Command[]>;

  constructor(
    public router: Router,
    private _route: ActivatedRoute,
    protected store: Store<{
      simpleProgramming: SimpleProgrammingContext,
      sharedProgramming: CommonProgrammingContext
    }>,
    private _broker: BrokerService,
    private _shared: SharedProgrammingService,
    private flowControl: FlowControlService
  ) {
    super();

    this._device = combineLatest(
      this._route.params.pipe(map((params: { id: string }) => parseInt(params.id))),
      this.store.select(s => s.simpleProgramming.devices).pipe(filter(d => !!d)),
      (id, devices) => devices.find(d => d.id == id)
    );

    this._commandType = this._device
      .pipe(
        filter(d => !!d),
        map(d => CommandTypes.find(ct =>
          (!!d.proxy && ct.whitelist.map(w => w.proxy).indexOf(d.proxy) > -1) ||
          (!!d.control && ct.whitelist.map(w => w.control).indexOf(d.control) > -1)
        ))
      );

    this._whitelist = combineLatest(
      this._device.pipe(filter(d => !!d)),
      this._commandType,
      (device, type) => type.whitelist.find(wl =>
        (!!device.proxy && wl.proxy === device.proxy) ||
        (!!device.control && wl.proxy === device.control)
      ));

    this._commands = combineLatest(
      this._device.pipe(filter(d => !!d)),
      this._whitelist
    )
      .pipe(
        flatMap(([device, whitelist]) => <Promise<Command[]>>this._broker.call({
          path: `/api/v1/items/${device.id}/commands`,
          queryString: {
            query: {
              command: {
                '$in': whitelist.commands.map(c => c.command)
              }
            }
          }
        })),
        share()
      );
  }

  selectAction = (command: Command): void => {
    if (!this._adding) {
      this._adding = true;
      this._logger.debug('action selected', command);
      if (!!command.params && command.params.length > 0) {
        this._shared.setSelectedAction(command);
        this.router.navigate(['../' + command.deviceId, command.command], {
          relativeTo: this._route
        })
      } else {
        const data: CommandData = Utils.buildCommandData(CodeItemType.Command, command);
        this._shared.setPendingAction(command, data);
        this.router.navigate(['conditionals'], { relativeTo: this._route });
      }
    }
  }

  getIcon = (command: Command): Observable<string> => {
    return this._whitelist.pipe(map(w => w.commands.find(c => c.command == command.command).icon));
  }
}
