import { ActionReducerMap } from '@ngrx/store';

import { sharedReducer, CommonProgrammingContext } from './services/shared-programming.service';
import { itemsReducer, ItemsState } from './services/items.service';
import { programmingEventsReducer, ProgrammingEventsState } from './services/events.service';
import { locationsReducer, LocationsContext } from '@when-then/core';

export interface CommonProgrammingState {
  programmingItems: ItemsState,
  sharedProgramming: CommonProgrammingContext,
  programmingEvents: ProgrammingEventsState,
  locations: LocationsContext
}

export const COMMON_PROGRAMMING_REDUCERS: ActionReducerMap<CommonProgrammingState> = {
  programmingItems: itemsReducer,
  sharedProgramming: sharedReducer,
  programmingEvents: programmingEventsReducer,
  locations: locationsReducer
};
