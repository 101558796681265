import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'prg-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.less']
})
export class AlertModalComponent implements OnInit {

  @Input() title: string;
  @Input() message: string;
  @Input() confirmLabel: string;

  @Output() confirm: EventEmitter<any>;
  @Output() cancel: EventEmitter<any>;

  constructor() {
    this.confirm = new EventEmitter<any>();
    this.cancel = new EventEmitter<any>();
    this.confirmLabel = 'OK';
  }

  ngOnInit() {
  }

}
