import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';

import { QuickstartKeypadsService } from './../services/keypads.service';
import { SharedProgrammingService } from '../../../common/services/shared-programming.service';
import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';
import { Device } from '../../../common/interfaces/item.interface';

@Component({
  selector: 'prg-select-keypad',
  templateUrl: './select-keypad.component.html',
  styleUrls: ['./select-keypad.component.less']
})
export class SelectKeypadComponent extends BaseRoutingComponent implements OnInit {

  // roomsWithKeyPads: Observable<Array<any>>;
  allKeypads: Observable<Device[]>;
  ready: Observable<boolean>;

  constructor(
    protected router: Router,
    protected store: Store<any>,
    private shared: SharedProgrammingService,
    private keypads: QuickstartKeypadsService,
    private route: ActivatedRoute
  ) {
    super();
    this.allKeypads = this.keypads.allKeypads;
    this.ready = this.shared.isReady;
  }

  ngOnInit() {
    this.shared.clearError();
    // this.shared.addBreadcrumb({ label: 'Keypads', route: Utils.getPathFromRoot(this.route) });
    // this.keypads.getKeypads();
  }

  keypadSelected(keypad: any) {
    this.keypads.setKeypad(keypad);
    this.router.navigate(['../buttons'], { relativeTo: this.route });
  }
}
