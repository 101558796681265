// TOOD this a dependency inversion
import { ConditionalRef } from './../../quickstarts/quickstart-flow-control/conditional-types/conditional-type.interfaces';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { map, take, tap, filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { BrokerService, Logger, LoggerFactory, LogLevel, AnalyticsService, LocationsService } from '@when-then/core';

import { ItemsState as ItemsContext, ItemsService } from './items.service';
import { ProgrammingUtilsService as Utils, TAG_QSP, CW_CREATOR_ID } from './utils.service';

import { Event, DeviceEvent } from '../interfaces/event.interface';
import { Device } from '../interfaces/item.interface';
import { CodeItem } from './../interfaces/code-item.interface';

import { SchedulesService } from '../../quickstarts/quickstart-schedule/schedule.service';
import { ScheduleEvent } from '../../quickstarts/quickstart-schedule/+state/schedule.interfaces';
import { AGENTS } from './agent-meta.service';
import { ConditionalType } from '../../quickstarts/quickstart-flow-control/conditional-types/conditional-type.interfaces';
import { Conditional } from '../interfaces/conditional.interface';
import { Command } from '../interfaces/command.interface';
import { Parameter } from '../interfaces/parameter.interface';
import { EventType } from '../../quickstarts/quickstart-simple/event-types/event-type.interface';
import { QSMediaService } from '../../quickstarts/quickstart-media/quickstart-media.service';

export type Verifier<T> = (target: T) => boolean;

export interface Trigger {
  devices?: Array<Device>;
  device?: Device;
  events?: Array<DeviceEvent>;
  event?: Event | ScheduleEvent;
  eventType?: EventType;
}
export interface Action {
  devices?: Array<Device>;
  device?: Device;
  commands?: Array<any>;
  command?: Command;
  pendingAction?: CommandData;

  // for conditional actions
  conditionalRef?: ConditionalRef;
  hasConditional?: boolean;
  condCmd?: Conditional;
  condType?: ConditionalType;
  condDev?: Device;
}


export interface CommonProgrammingContext {
  initialized: boolean;
  ready: boolean;
  error: boolean;
  editing: boolean;
  adding: boolean;
  errors?: Array<any>;
  searchText: string;
  saved: boolean;
  viewTitle: string;
  waitMessage?: string;
  osVersion?: string;

  floors?: Array<any>;
  rooms?: Array<any>;
  filteredDevices?: Array<any>;
  currentFloor?: any;
  currentRoom?: any;

  agents?: Array<any>;
  categories?: Array<string>;

  flatList?: Array<any>;
  groupedList?: Array<{
    label: string;
    events: Array<any>
  }>;
  roomList?: Array<any>;
  roomMap?: any;
  currentGrouping: string;
  hasProtected: boolean;

  trigger: Trigger;
  action: Action;

  codeItemVerifiers: Verifier<Event>[]
}

export interface CommandData {
  codeItemId: number;
  deviceId: number;
  command: string;
  beforeCodeItemId: number;
  parentCodeItemId: number;
  creator: number;
  type: number;
  params?: CommandDataParam[];
  conditional?: string;
  loop?: string;
  creatorState?: string;
}

interface CommandDataParam {
  name: string,
  value: string | number,
  _value?: string | number,
  display?: string,
  type?: string
}

export const INITAL_COMMON_STATE: CommonProgrammingContext = {
  initialized: false,
  ready: false,
  error: false,
  editing: false,
  adding: false,
  saved: false,
  currentGrouping: 'room',
  searchText: '',
  hasProtected: false,
  viewTitle: 'Back',
  trigger: {},
  action: {},
  codeItemVerifiers: []
};

const STORE_NAME: string = 'PROGRAMMING:';

const SET_INITIALIZED: string = STORE_NAME + 'SET_INITIALIZED';
export const SET_READY: string = STORE_NAME + 'SET_READY';
const SET_ERROR: string = STORE_NAME + 'SET_ERROR';
const CLEAR_ERROR: string = STORE_NAME + 'CLEAR_ERROR';
export const SET_WAIT_MESSAGE: string = STORE_NAME + 'SET_WAIT_MESSAGE';

const SET_CATEGORIES: string = STORE_NAME + 'SET_CATEGORIES';
export const SET_AGENTS: string = STORE_NAME + 'SET_AGENTS';
const SET_ROOMS_WITH_EVENTS: string = STORE_NAME + 'SET_ROOMS_WITH_EVENTS';
const SET_SEARCH_TEXT: string = STORE_NAME + 'SET_SEARCH_TEXT';
const SET_SHOWING: string = STORE_NAME + 'SET_SHOWING';
const SET_GROUPING: string = STORE_NAME + 'SET_GROUPING';
const SET_EVENT_DEVICE: string = STORE_NAME + 'SET_EVENT_DEVICE';
const SET_HAS_PROTECTED: string = STORE_NAME + 'SET_HAS_PROTECTED';
const SET_ADDING: string = STORE_NAME + 'SET_ADDING';

const SET_TRIGGER_DEVICES: string = STORE_NAME + 'SET_TRIGGER_DEVICES';
export const SET_TRIGGER_DEVICE: string = STORE_NAME + 'SET_TRIGGER_DEVICE';
const SET_TRIGGER_EVENTS: string = STORE_NAME + 'SET_TRIGGER_EVENTS';
export const SET_TRIGGER_EVENT: string = STORE_NAME + 'SET_TRIGGER_EVENT';
export const CLEAR_TRIGGER_EVENT: string = STORE_NAME + 'CLEAR_TRIGGER_EVENT';
const SET_EVENT_TYPE: string = STORE_NAME + 'SET_EVENT_TYPE';

const SET_ACTION_DEVICES: string = STORE_NAME + 'SET_ACTION_DEVICES';
export const SET_ACTION_DEVICE: string = STORE_NAME + 'SET_ACTION_DEVICE';
const SET_ACTION_COMMANDS: string = STORE_NAME + 'SET_ACTION_COMMANDS';
export const SET_ACTION_COMMAND: string = STORE_NAME + 'SET_ACTION_COMMAND';
export const SET_PENDING_ACTION: string = STORE_NAME + 'SET_PENDING_ACTION';
export const SET_COMMAND_PARAMS: string = STORE_NAME + 'SET_COMMAND_PARAMS';

const RESET_ADD_FLOW: string = STORE_NAME + 'RESET_ADD_FLOW';
const RESET_EVENT_FLOW: string = STORE_NAME + 'RESET_EVENT_FLOW';

const SET_FLOORS: string = STORE_NAME + 'SET_FLOORS';
const SET_ROOMS: string = STORE_NAME + 'SET_ROOMS';
const SET_LOCATION_DEVICES: string = STORE_NAME + 'SET_LOCATION_DEVICES';
const SET_FLOOR: string = STORE_NAME + 'SET_FLOOR';
const SET_ROOM: string = STORE_NAME + 'SET_ROOM';

const SET_OS_VERSION: string = STORE_NAME + 'SET_OS_VERSION';

const SET_VIEW_TITLE: string = STORE_NAME + 'SET_VIEW_TITLE';
const SET_EDITING: string = STORE_NAME + 'SET_EDITING';

export const ADD_CODEITEM_VERIFIER = STORE_NAME + 'ADD_CODEITEM_VERIFIER';
export const REMOVE_CODEITEM_VERIFIER = STORE_NAME + 'REMOVE_CODEITEM_VERIFIER';
export const CODEITEM_VERIFIERS_CHECKED = STORE_NAME + 'VERIFIERS_CHECKED';

export const SET_CONDITIONAL_REF = STORE_NAME + 'SET_CONDITIONAL_REF';
export const SET_HAS_CONDITIONAL = STORE_NAME + 'SET_HAS_CONDITIONAL';
export const SET_CONDITIONAL_TYPE = STORE_NAME + 'SET_CONDITIONAL_TYPE';
export const SET_CONDITIONAL = STORE_NAME + 'SET_CONDITIONAL';
export const SET_CONDITIONAL_DEVICE = STORE_NAME + 'SET_CONDITIONAL_DEVICE';

export function sharedReducer(state: CommonProgrammingContext = INITAL_COMMON_STATE, { type, payload }) {
  // if (type.indexOf(STORE_NAME) > -1) {
  //   this._logger.debug(type, payload);
  // }

  switch (type) {
    // general purpose
    case SET_INITIALIZED: return Object.assign({}, state, { initialized: payload });
    case SET_READY: return Object.assign({}, state, { ready: payload });
    case SET_CATEGORIES: return Object.assign({}, state, { categories: payload });
    case SET_SEARCH_TEXT: return Object.assign({}, state, { searchText: payload });
    case SET_ROOMS_WITH_EVENTS: return Object.assign({}, state, { groupedByRoom: payload });
    case SET_AGENTS: return Object.assign({}, state, { agents: payload });
    case SET_CATEGORIES: return Object.assign({}, state, { categories: payload });
    case SET_SHOWING: return Object.assign({}, state, { currentShowing: payload });
    case SET_GROUPING: return Object.assign({}, state, { currentGrouping: payload });
    case SET_EVENT_DEVICE: return Object.assign({}, state, { eventDevice: payload });
    case SET_HAS_PROTECTED: return Object.assign({}, state, { hasProtected: payload });
    case SET_ADDING: return Object.assign({}, state, { adding: payload });

    case SET_EDITING: return Object.assign({}, state, { editing: payload });
    case SET_VIEW_TITLE: return Object.assign({}, state, { viewTitle: payload });
    case SET_WAIT_MESSAGE: return Object.assign({}, state, { waitMessage: payload });

    case SET_ROOMS: return Object.assign({}, state, { rooms: payload });
    case SET_FLOORS: return Object.assign({}, state, { floors: payload });
    case SET_LOCATION_DEVICES: return Object.assign({}, state, { filteredDevices: payload });
    case SET_ROOM: return Object.assign({}, state, { currentRoom: payload });
    case SET_FLOOR: return Object.assign({}, state, { currentFloor: payload });
    case SET_OS_VERSION: return Object.assign({}, state, { osVersion: payload });

    case SET_TRIGGER_DEVICES: return Object.assign({}, state, { trigger: Object.assign({}, state.trigger, { devices: payload }) });
    case SET_TRIGGER_DEVICE: return Object.assign({}, state, { trigger: Object.assign({}, state.trigger, { device: payload }) });
    case SET_TRIGGER_EVENTS: return Object.assign({}, state, { trigger: Object.assign({}, state.trigger, { events: payload }) });
    case SET_TRIGGER_EVENT: return Object.assign({}, state, { trigger: Object.assign({}, state.trigger, { event: payload }) });
    case CLEAR_TRIGGER_EVENT: return Object.assign({}, state, { trigger: Object.assign({}, state.trigger, { event: undefined }) });
    case SET_EVENT_TYPE: return Object.assign({}, state, { trigger: Object.assign({}, state.trigger, { eventType: payload }) });

    case SET_ACTION_DEVICES: return Object.assign({}, state, { action: Object.assign({}, state.action, { devices: payload }) });
    case SET_ACTION_DEVICE: return Object.assign({}, state, { action: Object.assign({}, state.action, { device: payload }) });
    case SET_ACTION_COMMANDS: return Object.assign({}, state, { action: Object.assign({}, state.action, { commands: payload }) });
    case SET_ACTION_COMMAND: return Object.assign({}, state, { action: Object.assign({}, state.action, { command: payload }) });
    case SET_PENDING_ACTION: return Object.assign({}, state, { action: Object.assign({}, state.action, { pendingAction: payload }) });
    case SET_COMMAND_PARAMS: return Object.assign({}, state, {
      action: Object.assign({}, state.action, {
        command: Object.assign({}, state.action.command, { params: payload })
      })
    });

    case SET_ERROR: return Object.assign({}, state, { error: true, errors: payload });
    case CLEAR_ERROR: return Object.assign({}, state, { error: false, errors: undefined });

    case RESET_ADD_FLOW: return Object.assign({}, state, { action: {} });
    case RESET_EVENT_FLOW: return Object.assign({}, state, { trigger: {}, action: {} });

    case ADD_CODEITEM_VERIFIER:
      return Object.assign({}, state, { codeItemVerifiers: [...state.codeItemVerifiers, payload] });

    case REMOVE_CODEITEM_VERIFIER:
      let newArr = [...state.codeItemVerifiers];
      let index = state.codeItemVerifiers.findIndex(v => v == payload);
      newArr.splice(index, 1);
      return Object.assign({}, state, { codeItemVerifiers: newArr });

    case SET_CONDITIONAL_REF: return Object.assign({}, state, { action: Object.assign({}, state.action, { conditionalRef: payload }) });
    case SET_HAS_CONDITIONAL: return Object.assign({}, state, { action: Object.assign({}, state.action, { hasConditional: payload }) });
    case SET_CONDITIONAL_TYPE: return Object.assign({}, state, { action: Object.assign({}, state.action, { condType: payload }) });
    case SET_CONDITIONAL: return Object.assign({}, state, { action: Object.assign({}, state.action, { condCmd: payload }) });
    case SET_CONDITIONAL_DEVICE: return Object.assign({}, state, { action: Object.assign({}, state.action, { condDev: payload }) });

    default: return state;
  }
};

@Injectable()
export class SharedProgrammingService {
  searchText: Observable<string>;
  isReady: Observable<boolean>;
  currentGrouping: Observable<string>;
  initialized: Observable<boolean>;
  viewTitle: Observable<string>;
  editing: Observable<boolean>;
  adding: Observable<boolean>;

  private _logger: Logger;

  constructor(
    private broker: BrokerService,
    private router: Router,
    private store: Store<{
      sharedProgramming: CommonProgrammingContext,
      programmingItems: ItemsContext
    }>,
    private items: ItemsService,
    private schedules: SchedulesService,
    private locations: LocationsService,
    private analytics: AnalyticsService,
    // NOTE this is a hidden dependency, do not remove!
    private media: QSMediaService
  ) {
    this._logger = LoggerFactory.getLogger(SharedProgrammingService.name);
    // this._logger.setLevel(LogLevel.DEBUG);

    this.broker.connected.subscribe(connected => {
      if (connected) {
        this.ready(false);
        Promise.all([
          this.getCategories(),
          this._getAgents()
        ]).then(() => {
          this._logger.debug('shared services init complete');
          this.store.dispatch({ type: SET_INITIALIZED, payload: true });
        }, err => {
          this._logger.error('error initalizing shared programming service', err);
          this.error(err);
          this.ready(true);
        });
      }
    });

    this.searchText = this.store.select(s => s.sharedProgramming.searchText);
    this.isReady = this.store.select(s => s.sharedProgramming.ready);
    this.currentGrouping = this.store.select(s => s.sharedProgramming.currentGrouping);
    this.initialized = this.store.select(s => s.sharedProgramming.initialized);
    this.viewTitle = this.store.select(s => s.sharedProgramming.viewTitle);
    this.editing = this.store.select(s => s.sharedProgramming.editing);
    this.adding = this.store.select(s => s.sharedProgramming.adding);
  }

  setEditing(state: boolean) {
    this.store.dispatch({ type: SET_EDITING, payload: state });
  }

  setSearchText(text: string) {
    this.store.dispatch({ type: SET_SEARCH_TEXT, payload: text });
  }

  setGrouping(value: string) {
    this.store.dispatch({ type: SET_GROUPING, payload: value });
  }

  ready(state: boolean) {
    // this._logger.debug('setting ready: ', state);
    this.store.dispatch({ type: SET_READY, payload: state });
  }

  setAdding(state: boolean) {
    this.store.dispatch({ type: SET_ADDING, payload: state });
  }

  error(error: any) {
    this._logger.error('shared-programming: error:', error);
    this.store.dispatch({ type: SET_ERROR, payload: { error: true, errors: [error] } });
  }

  clearError() {
    this.store.dispatch({ type: CLEAR_ERROR });
  }

  // addCodeItemVerifier(verifier: Verifier<Event>): void {
  //     this.store.dispatch({ type: ADD_CODEITEM_VERIFIER, payload: verifier });
  // }

  removeCodeItemVerifier(verifier: Verifier<Event>): void {
    this.store.dispatch({ type: REMOVE_CODEITEM_VERIFIER, payload: verifier });
  }

  private getCategories(): Promise<any> {
    return this.broker.call({
      path: '/api/v1/categories'
    }).then(res => {
      this.store.dispatch({ type: SET_CATEGORIES, payload: res });
    });
  }

  setEventType(type: EventType) {
    this.store.dispatch({ type: SET_EVENT_TYPE, payload: type });
  }

  getEventDevice(event: any) {
    if (!!event && !!event.deviceId) {
      this._logger.debug('getting event device', event);
      this.broker.call({
        path: '/api/v1/items/' + event.deviceId
      }).then(res => {
        this._logger.debug('device for selected event is', res[0]);
        if (res.length > 0) {
          this.store.dispatch({ type: SET_EVENT_DEVICE, payload: res[0] });
        }
      }, err => {
        this._logger.error('error getting event device', err);
        this.error(err);
      });
    }
  }

  async clearSelectedEvent(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this._triggerSubscriber) {
        this._logger.debug('unsubscribing from trigger event');
        this._triggerSubscriber.unsubscribe();
        this._triggerSubscriber = undefined;
      }

      this.store.select(s => s.sharedProgramming.trigger.event)
        .pipe(
          filter(e => !e),
          take(1)
        )
        .subscribe(e => {
          this._logger.debug('trigger event has been cleared');
          resolve(e);
        }, err => {
          this._logger.error('error waiting for trigger event to be cleared', err);
          reject(err);
        });

      this.store.dispatch({ type: CLEAR_TRIGGER_EVENT });
    });

  }

  setTitle(title: string) {
    this.store.dispatch({ type: SET_VIEW_TITLE, payload: title });
  }

  setCurrentFloor(floorId: number) {
    let floor = this.locations.getLocationById(floorId);
    this._logger.debug('shared: setting floor', floor);
    if (floor) {
      this.store.dispatch({ type: SET_FLOOR, payload: floor });
    } else {
      this.store.dispatch({ type: SET_FLOOR, payload: undefined });
    }
  }

  setCurrentRoom(roomId: number) {
    let room = this.locations.getLocationById(roomId);
    this._logger.debug('shared: setting room', room);
    if (room) {
      this.store.dispatch({ type: SET_ROOM, payload: room });
    } else {
      this.store.dispatch({ type: SET_ROOM, payload: undefined });
    }
  }

  public setOSVersion(osVersion: string) {
    this.store.dispatch({ type: SET_OS_VERSION, payload: osVersion });
  }

  clearLocationFilter() {
    this.store.dispatch({ type: SET_FLOOR, payload: 'any' });
    this.store.dispatch({ type: SET_ROOM, payload: 'any' });
  }

  // getDevices(query?: any) {
  //   let req: BrokerRequest = {
  //     path: '/api/v1/items'
  //   }
  //   let reqQuery: any = { type: 7 };
  //   let floor = Utils.snapshot<Location>(this.store.select(s => s.sharedProgramming.currentFloor));
  //   if (floor) {
  //     reqQuery.floorId = floor.id;
  //   }
  //   let room = Utils.snapshot<Location>(this.store.select(s => s.sharedProgramming.currentRoom));
  //   if (room) {
  //     reqQuery.roomId = room.id;
  //   }
  //   if (query) {
  //     reqQuery = Object.assign({}, reqQuery, query);
  //   }
  //   req.queryString = {
  //     query: reqQuery
  //   };

  //   this.broker.call(req).then(res => {
  //     this.store.dispatch({ type: SET_LOCATION_DEVICES, payload: res });
  //   }, err => {
  //     this.error(err);
  //     this.store.dispatch({ type: SET_LOCATION_DEVICES, payload: [] });
  //   })
  // }

  async toggleCodeitemEnabled(codeitem: CodeItem, event?: Event | ScheduleEvent): Promise<any> {
    if (!event) {
      event = Utils.snapshot<Event | ScheduleEvent>(this.store.select(s => s.sharedProgramming.trigger.event));
    }

    if (!!event) {
      // TODO figure out why this doesn't invoke the wait state
      this._logger.debug('setting code item verifier for enabling');
      this.addCodeItemVerifier(event => {
        return (!!event && !!event.codeItems &&
          event.codeItems.find(ci => ci.codeItemId === codeitem.codeItemId).enabled !== codeitem.enabled);
      });

      return this.broker.call({
        path: '/api/v1/items/' + event.deviceId + '/events/' + event.eventId + '/' + codeitem.codeItemId,
        method: 'POST',
        data: { enabled: !codeitem.enabled }
      }).then(() => {
        this._logger.debug('code item enabled toggled');
      });
    } else {
      return Promise.reject({ message: 'no event in context' });
    }
  }

  // TODO encapsulate this in a service
  private _getAgents(): Promise<any> {
    try {
      this.clearError();
      this.ready(false);
      // NOTE need to use an items query here because /api/v1/agents only returns agents
      // that have routes defined in the api
      return this.broker.call({
        // path: '/api/v1/items',
        // queryString: {
        //   query: { type: 9 }
        // }
        path: '/api/v1/agents'
      }).then(
        agents => {
          this.store.dispatch({ type: SET_AGENTS, payload: agents });
        },
        err => {
          this.error(err);
        });
    } catch (error) {
      this.error(error);
    } finally {
      this.ready(true);
    }
  }

  ////-------------------------

  private _triggerSubscriber: Subscription;

  async fetchEvent(deviceId: number, eventId: number) {
    this.ready(false);
    await this.clearSelectedEvent();
    this._logger.debug('fetchevent', deviceId, eventId);

    // HACK! broker is not consistent in how event subscriptions may be obtained
    // On the one hand, /api/v1/items/100100/events/:eventId (for schedule events)
    // doesn't work for new schedules, so we have to resort to using the query string,
    // on the other hand the query string approach doesn't work when the room Command
    // events are being considered, such as for custom buttons on a system remote, so
    // we have to vary the approach based on the device id, until broker can be made
    // to be more consistent
    let path = '/api/v1/items/' + deviceId + '/events';
    let qs;

    // Voice Scenes have a dynamic id we need to check against,
    // unlike the static scheduler ID
    const vsDriver = await this.store.select(s => s.programmingItems.itemsList)
      .pipe(

        // We aren't guaranteed to have the itemslist
        // populated when we're evaluating this, so
        // wait until the list is populated
        filter(items => Array.isArray(items) && items.length > 0),

        map(items => items.find((item: Device) => !!item.proxy && item.proxy == 'voice-scene')),
        take(1)
      )
      .toPromise();

    if (
      deviceId === AGENTS.SCHEDULER.ID ||
      (!!vsDriver && vsDriver.id == deviceId)
    ) {
      qs = { query: { eventId: eventId }, allevents: true };
    } else {
      path += '/' + eventId;
    }

    // NOTE broker bug requires us to address events with a query in the query string, rather than in the path
    // for scheduler events anyway
    this._logger.debug(`creating new event subscription for ${path}, query string ${JSON.stringify(qs)}`);
    this._triggerSubscriber = this.broker.getObservable({
      path: path,
      queryString: qs
    }).subscribe(updates => {
      this._handleTriggerUpdates(updates);
      this.ready(true);
    }, err => this._logger.error('shared: error subscribing to trigger event definition', err));

    if (deviceId === AGENTS.SCHEDULER.ID && eventId > 0) {
      this.schedules.getSchedule(eventId);
    }
  }

  private _handleTriggerUpdates(updates: any) {
    this._logger.debug('handling selected event update', updates);
    // TODO what does it mean that we sometimes get here with an empty array?

    let event = updates;
    // this._logger.debug('shared: handling updated trigger event definition', updates);
    // TODO this may no longer be necessary
    if (Array.isArray(updates)) {
      event = updates[0];
    }

    if (!!event) {
      // HACK set protected state of the event and codeitems the same way we do when we process the entire list.
      event = Utils.resolveProtected([event])[0];
    }

    this._logger.debug('setting trigger event', event);
    this.store.dispatch({ type: SET_TRIGGER_EVENT, payload: event });
  }

  testEvent(): Promise<any> {
    this.clearError();
    let event = Utils.snapshot<Event>(this.store.select(s => s.sharedProgramming.trigger.event));
    return this.broker.call({
      method: 'PUT',
      path: '/api/v1/items/' + event.deviceId + '/events/' + event.eventId
    }).then(res => {
      this._logger.debug('shared: event invocation complete', res);
    }, err => {
      this._logger.error('shared: error invoking event', err);
      this.error(err);
    });
  }

  deleteAction(event: Event | ScheduleEvent, action: any) {
    this.ready(false);
    if (event && event.eventId && event.deviceId && action) {
      let p;
      if (event.deviceId === AGENTS.SCHEDULER.ID) {
        p = this.schedules.deleteAction(event, action)
      } else {
        p = this._deleteAction(event, action)
      }

      if (!!p) {
        p.then(() => {
          this.addCodeItemVerifier(event => (
            !event.codeItems || event.codeItems.length === 0 ||
            (!!event.codeItems && event.codeItems.length > 0 && event.codeItems.every(ci => ci.codeItemId !== action.codeItemId)))
          );
        }, err => {
          this._logger.error('shared: error deleting schedule action', err);
          this.error(err);
          this.ready(true);
        });
      }
    }
  }

  private _deleteAction(event: any, action: any): Promise<any> {
    this._logger.debug('shared: deleting action', action);
    return new Promise<any>((resolve, reject) => {
      this.broker.call({
        method: 'DELETE',
        path: '/api/v1/items/' + event.deviceId + '/events/' + event.eventId + '/' + action.codeItemId
      }).then(res => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  deleteAllActions(): Promise<any> {
    let event = Utils.snapshot<Event>(this.store.select(s => s.sharedProgramming.trigger.event));
    if (event && event.codeItems) {
      let p = [];
      event.codeItems.forEach(ci => {
        let data = JSON.parse(ci.creatorState || '{}');
        // TODO remove tags check once we get closer to release (2.10)
        if (ci.creator === CW_CREATOR_ID || (!!data.tags && data.tags.indexOf(TAG_QSP) >= 0)) {
          p.push(this.deleteAction(event, ci));
        }
      });
    } else {
      return new Promise<any>((reject) => reject({ code: 'INTERNAL', message: 'no event in context when deleting actions' }));
    }
  }

  private _navigateToCurrentEvent(state: string = 'edit') {
    let event = Utils.snapshot<Event>(this.store.select(s => s.sharedProgramming.trigger.event));
    if (event) {
      this.resetAddScenario();
      this.router.navigate(['when-then', 'device', event.deviceId, 'event', event.eventId, state], { relativeTo: this.moduleRoot });
    }
  }

  /**
   * Adds an action to the current event.
   * @param action the command, conditional, or loop being created
   * @param parentCodeItemId (optional) the parent of the code item being created (i.e., when adding a child code item)
   * @returns Promise<any> a promise that will resolve or be rejected when the add action attempt is completed
   */
  async saveAction(action: CommandData, parentCodeItemId?: number): Promise<any> {
    this._logger.debug('saving action', action, parentCodeItemId);
    this.ready(false);

    // HACK even though non-command actions are not yet supported, require action.type === 1 to continue
    action.type = action.type || 1;
    action.creator = CW_CREATOR_ID;
    let event = Utils.snapshot<Event>(this.store.select(s => s.sharedProgramming.trigger.event));
    this._logger.debug('event is', event);
    let prevCodeItemIds = (!!event && !!event.codeItems) ?
      event.codeItems.map(ci => ci.codeItemId) :
      [];
    action.creatorState = Utils.buildCreatorState(event, action);

    // for adding a code item as a child of a conditional or loop
    action.parentCodeItemId = parentCodeItemId || 0;

    // NOTE whichever step fails is responsible for setting the error context

    const eventDev = this.items.getItem(event.deviceId);
    const edevName = `${eventDev.proxy || eventDev.protocolName || eventDev.name}`;

    this._logger.debug('decorated action is ', action);
    const actionDev = this.items.getItem(action.deviceId);
    // this._logger.debug('action device is ', actionDev);
    // NOTE agents are labeled by name
    const label = `${actionDev.proxy || actionDev.protocolName || actionDev.name}:${action.command}`;
    this.analytics.emitEvent('Action', 'Command', label);

    const adevName = `${actionDev.proxy || actionDev.protocolName || actionDev.name}`;

    let actionParams = '';
    if (!!action.params) {
      action.params.forEach(param => {
        actionParams += `${param.name}=${param._value || param.value}`
      });

      if (actionParams.length > 0) {
        actionParams = ':' + actionParams;
      }
    }

    this.analytics.emitEvent('Event:Action', `[${adevName}:${action.command}]`, `[${edevName}:${event.name}]`);

    if (event.deviceId === AGENTS.SCHEDULER.ID) {
      this._logger.debug('saving action associated with a schedule');
      return this.schedules.saveAction(action).then(res => {
        this._logger.debug('save schedule action response', res);
        this.addCodeItemVerifier(res);
        this._navigateToCurrentEvent('edit');
      }, err => {
        this._logger.error('shared: error saving schedule event actions', err);
        this.ready(true);
      });
      // NOTE some event types number events zero-based (e.g., advanced lighting), so
      // thanks for THAT little inconsistency
    } else if (event.eventId >= 0 && event.deviceId) {
      this._logger.debug('saving non-scheduled action');
      return this._saveAction(event, action).then(res => {
        this._logger.debug('save action response', res);
        this.addCodeItemVerifier(res);
        this._navigateToCurrentEvent('edit')
      }, err => this._logger.error('shared: error saving action', err));
    } else {
      this.error({ type: 'INTERNAL', message: 'invalid context: no eventId or deviceId' });
      this.ready(true);
    }
  }

  addCodeItemVerifier(res: any) {
    if (!!res) {
      // ick! do we really have the latter case anymore?  codeItems?
      const savedCI = !!res.codeItem ? res.codeItem : !!res.codeItems ? res.codeItems[0] : null;
      this._logger.debug('saved code item is ', savedCI);
      if (!!savedCI) {
        this.store.dispatch({
          type: ADD_CODEITEM_VERIFIER,
          payload: event => (
            !!event.codeItems &&
            event.codeItems.length > 0 &&
            this._findCodeItem(savedCI.codeItemId, event.codeItems)
          )
        });
      }
    }
  }

  /**
   * recursively search through the hierarchy of code items looking for a code item
   * we expect to be present
   */
  private _findCodeItem(codeItemId: number, codeItems: CodeItem[]): boolean {
    if (!!codeItemId && !!codeItems) {
      return codeItems.some(ci => ci.codeItemId === codeItemId || this._findCodeItem(codeItemId, ci.codeItems));
    }

    return false;
  }

  private _saveAction(event: any, action: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.broker.call({
        method: 'POST',
        path: '/api/v1/items/' + event.deviceId + '/events/' + event.eventId + '/' + this.getActionType(action),
        data: action
      }).then(res => {
        resolve(res);
      }, err => {
        this.error(err);
        reject(err);
      });
    });
  }

  private getActionType(action: any): string {
    switch (action.type) {
      case 1: return 'commands';
      case 2: return 'conditionals';
      case 3: return 'loops';

      default: throw new Error('invalid action type on save: ' + JSON.stringify(action));
    }
  }

  setTriggerDevices(devices: Device[]) {
    this.store.dispatch({ type: SET_TRIGGER_DEVICES, payload: devices });
  }

  setActionDevices(devices: Device[]) {
    this.store.dispatch({ type: SET_ACTION_DEVICES, payload: devices });
  }

  // getTriggerDevices(query: any) {
  //   if (query) {
  //     this.broker.call({
  //       path: '/api/v1/items',
  //       queryString: {
  //         query: query
  //       }
  //     }).then(res => {
  //       this.store.dispatch({ type: SET_TRIGGER_DEVICES, payload: res });
  //     }, err => {
  //       this.error(err);
  //     });
  //   }
  // }

  // getActionDevices(query: any) {
  //   if (query) {
  //     this.broker.call({
  //       path: '/api/v1/items',
  //       queryString: {
  //         query: query
  //       }
  //     }).then(res => {
  //       this.store.dispatch({ type: SET_ACTION_DEVICES, payload: res });
  //     }, err => {
  //       this.error(err);
  //     });
  //   }
  // }

  setTriggerDevice(device: Device) {
    // NOTE clear the trigger events first so they don't stick around
    // once the new device is set and new event list retrieved
    // TODO could do this in the reducer I guess
    this.store.dispatch({ type: SET_TRIGGER_EVENTS, payload: [] });

    this.store.dispatch({ type: SET_TRIGGER_DEVICE, payload: device });
    this._getTriggerEvents(device);
  }

  private _getTriggerEvents(device: Device) {
    this.broker.call({
      path: '/api/v1/items/' + device.id + '/events',
      queryString: {
        allevents: true
      }
    }).then(res => {
      this.store.dispatch({ type: SET_TRIGGER_EVENTS, payload: res });
    }, err => {
      this.error(err);
    });
  }

  setSelectedAction(action: Command) {
    this.store.dispatch({ type: SET_ACTION_COMMAND, payload: action });
  }

  setActionDevice(device: Device) {
    this.store.dispatch({ type: SET_ACTION_DEVICE, payload: device });
    this._getActionCommands(device);
  }

  private _getActionCommands(device: Device) {
    this.broker.call({
      path: '/api/v1/items/' + device.id + '/commands'
    }).then(res => {
      this.store.dispatch({ type: SET_ACTION_COMMANDS, payload: res });
    }, err => {
      this.error(err);
    });
  }

  getSimpleItems(path: string): Promise<any> {
    return this.broker.call({ path: path });
  }

  setPendingAction(command: Command, data: CommandData) {
    this.ready(false);
    this.store.select(s => s.sharedProgramming.trigger)
      .pipe(
        filter(t => !!t),
        take(1)
      )
      .subscribe(trigger => {
        this._logger.debug('current trigger for pending action', trigger);
        if (!!trigger.event && trigger.event.deviceId === AGENTS.SCHEDULER.ID) {
          this.saveAction(data).then(res => {
            this.router.navigate([`when-then/device/${trigger.event.deviceId}/event/${trigger.event.eventId}`], { relativeTo: this.moduleRoot });
            this.ready(true);
          }, err => {
            this._logger.error(err);
            this.error(err);
            this.ready(true);
          });
        } else {
          const cmd = { ...command };
          this._interpolateCommandParams(cmd, data);
          this._logger.debug('setting pending action', cmd, data);
          this.store.dispatch({ type: SET_ACTION_COMMAND, payload: cmd });
          this.store.dispatch({ type: SET_PENDING_ACTION, payload: data });
        }
        this.ready(true);
      });

  }

  private _interpolateCommandParams(command: Command, data: CommandData) {
    if (!!data.params) {
      for (let i = 0; i < data.params.length; i++) {
        const p = data.params[i];
        const def = command.params.find(pd => pd.name === p.name);
        command.display = command.display.replace(`PARAM:${i}`, this._getParamDisplay(def, p));
      }
    }
  }

  private _getParamDisplay(def: Parameter, data: CommandDataParam): string {
    let display = !!data.display
      ? data.display
      : '';

    switch (def.type) {
      case 'INTEGER':
      case 'STRING':
      case 'RANGED_FLOAT':
      case 'RANGED_INTEGER':
      case 'FLOAT':
      case 'INT':
      case 'HEXCOLOR':
      case 'HIDDEN':
      case 'XML':
      case 'TIMEOFDAY':
      case 'RANGE':
        display = data.display || String(data.value || data._value);
        break;

      case 'DEVICE_SELECTOR':
        break;

      case 'BOOLEAN':
        break;

      case 'LIST':
        if (!!def.values) {
          const value = def.values.find(v => v[def.valueField] == data.value);
          this._logger.debug('list value is ', value);
          if (!!value) {
            //NOTE strip surrounding { and } from the name of the display field, which may not be on the ends of the string
            // e.g., "{name} LED", note that C4ParamLabel does not process this case correctly
            const displayFld = def.valueDisplay.substring(def.valueDisplay.indexOf('{') + 1, def.valueDisplay.indexOf('}'));
            this._logger.debug('processed display field name is', displayFld);
            if (!!displayFld) {
              display = def.valueDisplay.replace(`{${displayFld}}`, value[displayFld]);
            }
          }
        } else if (!!data.display) {
          display = data.display;
        }
        break;
    }

    return display;
  }

  setConditionalRef(ref: ConditionalRef) {
    this.store.dispatch({ type: SET_CONDITIONAL_REF, payload: ref });
  }

  setHasConditional(state: boolean) {
    this.store.dispatch({ type: SET_HAS_CONDITIONAL, payload: state });
  }

  setActionConditional(cnd: Conditional) {
    this.store.dispatch({ type: SET_CONDITIONAL, payload: cnd });
  }

  resetAddScenario() {
    this.store.dispatch({ type: RESET_ADD_FLOW });
  }

  resetEventScenario() {
    this.store.dispatch({ type: RESET_EVENT_FLOW });
  }

  set moduleRoot(route: ActivatedRoute) {
    this._moduleRoot = route;
  }

  get moduleRoot(): ActivatedRoute {
    return this._moduleRoot;
  }

  private _moduleRoot: ActivatedRoute;
}
