import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { QSThermostatComponent } from './quickstart-thermostats.component';
import { QSThermostatSelectActionComponent } from './select-action/select-action.component';
import { QSThermostatSelectParamsComponent } from './select-params/select-params.component';
import { QSThermostatSelectThermostatComponent } from './select-thermostat/select-thermostat.component';
import { CONDITIONAL_ROUTES } from '../quickstart-flow-control/flow-control.routes';

export const QUICKSTART_ACTIONS_THERMOSTAT_ROUTES: Routes = [
  {
    path: 'thermostats',
    component: QSThermostatComponent,
    children: [
      {
        path: 'select',
        component: QSThermostatSelectThermostatComponent
      },
      {
        path: ':id',
        component: QSThermostatSelectActionComponent
      },
      {
        path: ':id/:command',
        component: QSThermostatSelectParamsComponent
      },
      {
        path: ':id/:command/conditionals',
        children: [...CONDITIONAL_ROUTES]
      }
    ]
  }
];
