import { ItemsState, ItemsService } from './../../common/services/items.service';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { CommonProgrammingContext } from './../../common/services/shared-programming.service';
import { ProgrammingUtilsService as Utils } from '../../common/services/utils.service';
import { Device } from '../../common/interfaces/item.interface';
import { OSCompatibilityService } from '../../common/services';

declare var require: any;

// const SUPPORTED_ACTIONS: Supported[] = require('../assets/data/quickstart-actions.json');
// const SUPPORTED_TRIGGERS: Supported[] = require('../assets/data/quickstart-triggers.json');

export interface QuickstartsContext {
  ready: boolean;
  saved: boolean;
  adding: boolean;
  errors?: Array<any>;
  error: boolean;
  searchText: string;
  supportedTriggers: Array<Supported>;
  supportedActions: Array<Supported>;
  pageTitle?: string;
}

interface Supported {
  label: string;
  icon: string;
  route: string;
  requires: {
    [key: string]: {
      values: string[];
      match: string
    }
  };
  enabled: boolean
}

const INITIAL_STATE: QuickstartsContext = {
  ready: false,
  error: false,
  saved: false,
  adding: false,
  searchText: '',
  supportedTriggers: [],
  supportedActions: [],
};

const STORE_NAME = 'PROGRAMMING:QUICKSTARTS:';
const SET_QUICKSTART_TRIGGERS = STORE_NAME + 'SET_QUICKSTART_TYPES';
const SET_QUICKSTART_ACTIONS = STORE_NAME + 'SET_QUICKSTART_ACTIONS';
const SET_READY = STORE_NAME + 'SET_READY';
const SET_ERROR = STORE_NAME + 'SET_ERROR';
const SET_SAVED = STORE_NAME + 'SET_SAVED';
const SET_ADDING = STORE_NAME + 'SET_ADDING';
const CLEAR_ERROR = STORE_NAME + 'CLEAR_ERROR';
const CLEAR_ALL = STORE_NAME + 'CLEAR_ALL';

export function quickstartsReducer(state: QuickstartsContext = INITIAL_STATE, { type, payload }) {
  // console.log('quickstartReducer received: ', type, payload);
  switch (type) {
    case SET_QUICKSTART_TRIGGERS: return Object.assign({}, state, { supportedTriggers: payload });
    case SET_QUICKSTART_ACTIONS: return Object.assign({}, state, { supportedActions: payload });


    case SET_READY: return Object.assign({}, state, { ready: payload });
    case SET_ERROR: return Object.assign({}, state, { error: true, errors: payload });
    case SET_SAVED: return Object.assign({}, state, { saved: payload });
    case SET_ADDING: return Object.assign({}, state, { adding: payload });

    case CLEAR_ERROR: return Object.assign({}, state, { error: false, errors: undefined });
    case CLEAR_ALL: return Object.assign({}, INITIAL_STATE);

    default: return state;
  }
};

@Injectable()
export class QuickstartProgrammingService {

  constructor(
    private store: Store<{
      sharedProgramming: CommonProgrammingContext,
      programmingItems: ItemsState
    }>,
    private osCompatibilityService: OSCompatibilityService,
    // NOTE careful! this may represent a hiddend dependency, don't remove it unless you're sure
    private items: ItemsService
  ) {

    const supportableActions = require('../assets/data/quickstart-actions.json');

    combineLatest(
      this.store.select(s => s.sharedProgramming.categories),
      this.store.select(s => s.sharedProgramming.agents),
      this.store.select(s => s.programmingItems.itemsList),

      // This is only present to assure the list gets
      // re-evaluated if the version updates
      this.store.select(s => s.sharedProgramming.osVersion)
        .pipe(filter(v => !!v)),

    ).subscribe(([categories, agents, itemList, version]) => {
      const supported = [];

      supportableActions.forEach(type => {
        if (!type.requires) {
          supported.push(type);
        } else {
          if (this.matchesRequiredMinOSVersion(type.requires) &&
            (this.matchesRequiredCategories(type.requires, categories) ||
              this.matchesRequiredAgents(type.requires, agents) ||
              this.matchesRequiredControls(type.requires, <Array<Device>>itemList) ||
              this.matchesRequiredProxies(type.requires, <Array<Device>>itemList) ||
              this.matchesRequiredCapabilities(type.requires, <Array<Device>>itemList))) {
            supported.push(type);
          }
        }
      });

      this.store.dispatch({ type: SET_QUICKSTART_ACTIONS, payload: supported });
    })
  }

  // check min required OS against the current OS version
  public matchesRequiredMinOSVersion(required: any): boolean {
    let matches = true; // if no required version specified default to true
    if (required.OSMinVersion)
      matches = this.osCompatibilityService.isVersionOrGreater(required.OSMinVersion);

    return matches;
  }

  private matchesRequiredCategories(required: any, categories: Array<string>): boolean {
    let matches = false;

    if (required.categories && categories) {
      for (let i = 0; i < required.categories.values.length; i++) {
        let val = required.categories.values[i];
        if (required.categories.match === 'all' && categories.indexOf(val) === -1) {
          break;
        } else if (required.categories.match === 'any' && categories.indexOf(val) >= 0) {
          matches = true;
          break;
        }
      }
    }

    // console.log('category matches', required, matches);
    return matches;
  }

  private matchesRequiredAgents(required: any, agents: Array<any>): boolean {
    let matches = false;

    if (!!required && !!required.agents && !!required.agents.values && !!agents) {
      // console.log('checking for agent match', required, agents);
      let found = agents.filter(agent => {
        let name = agent.toLowerCase();
        if (required.agents.values) {
          return (required.agents.values.indexOf(name) >= 0);
        }
      });
      matches = (found.length > 0);
    }

    // console.log('agent matches', required, matches);
    return matches;
  }

  private matchesRequiredControls(required: any, items: Array<Device>): boolean {
    let matches = false;

    if (!!required && !!required.control && !!required.control.values && !!items) {
      // console.log('checking for agent match', required, agents);
      matches = items.some(itm => !!itm.control && required.control.values.indexOf(itm.control) >= 0);
    }

    // console.log('agent matches', required, matches);
    return matches;
  }

  private matchesRequiredProxies(required: any, items: Array<Device>): boolean {
    let matches = false;

    if (required.proxies && items) {
      let found = items.filter(itm => !!itm.proxy && required.proxies.values.indexOf(itm.proxy) >= 0);
      matches = (found.length > 0);
      // console.log('found proxy', found);
    }

    // console.log('proxy matches', required, matches);
    return matches;
  }

  private matchesRequiredCapabilities(required: any, items: Array<Device>): boolean {
    let matches = false;

    if (required.capabilities) {
      // matches = items.some(itm => !!itm.capabilities && required.capabilities.some(itm.capabilities.map()) )
    }

    return matches;
  }
}
