// const overrides = require('!json-loader!./overrides.json');

export const baseEnvironment: C4Environment = Object.assign(
  {
    appKey: "22ffc24d54c3b3db2c56c0c2b0574537e54d7a27"
  }
  // , overrides
);

export type C4Environment = {
  env: string;
  production: boolean;
  appKey: string;
  brokerAddr: string;
  trackingId?: string;
}
