import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DoorstationComponent } from './doorstation/doorstation.component';
import { QuickstartCommonModule } from '../quickstart-common/quickstart-common.module';
import { ProgrammingCommonModule } from '../../common/common.module';
import { UIModule } from '@when-then/ui';
import { DoorstationEventsComponent } from './doorstation-events/doorstation-events.component';

@NgModule({
  imports: [
    CommonModule,
    UIModule,
    ProgrammingCommonModule,
    QuickstartCommonModule
  ],
  declarations: [
    DoorstationComponent,
    DoorstationEventsComponent
  ],
  providers: []
})
export class QuickstartDoorstationsModule {}
