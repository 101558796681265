<prg-nav-header title="Select an Announcement" (cancel)="goToEvent()"></prg-nav-header>
<cxScrollable>
    <prg-block-button
      *ngFor="let announcement of announcements | async"
      (click)="setAnnouncement(announcement)"
      [title]="announcement.name"
      leadingIcon="announcement"
      trailingIcon="right">
    </prg-block-button>
</cxScrollable>
