// angular
import { Component, Input } from '@angular/core';

@Component({
  selector: 'cxSpinner',
  templateUrl: './spinner.directive.html',
  styleUrls: [
    './spinner.directive.less'
  ]
})
export class SpinnerDirective {

  @Input() cxDiameter: string;

  fps = 60;
  width = 90;  // how many degrees the interior arc should use
  speed = 1; // rotations per second
  outerLineWeight = 20;
  innerLineWeight = 10;

  increment = (360 * this.speed) * (1 / this.fps); // how many degrees to add per frame

  d: string; // arc description
  angle: number; // current starting point of interior arc

  offset = this.outerLineWeight / 2;

  intervalID: number;

  constructor() {
    this.angle = 90;
    this.d = this.arc(this.angle, this.subtractAngle(this.angle, this.width), this.offset);

    this.intervalID = window.setInterval(() => {
      this.angle = this.subtractAngle(this.angle, this.increment);
      this.d = this.arc(this.angle, this.subtractAngle(this.angle, this.width), this.offset);
    }, 60 / this.fps / 60 * 1000);

  }

  private subtractAngle = (a: number, b: number) => {
    return (360 + a - b) % 360;
  }

  private arc = (startAngle: number, endAngle: number, offset: number): string => {

    let [centerX, centerY, radius] = [50, 50, 50 - offset];

    let start = this.polarToCartesian(centerX, centerY, radius, startAngle);
    let end = this.polarToCartesian(centerX, centerY, radius, endAngle);

    let bigFlag = this.width <= 180 ? "0" : "1";

    let res = [
      "M", start.x, start.y,
      "A", radius, radius, 0, bigFlag, 1, end.x, end.y
    ].join(" ");

    return res;
  }

  private polarToCartesian = (centerX: number, centerY: number, radius: number, angle: number): { x: number, y: number } => {
    angle = -(angle % 360);
    let radians = angle * Math.PI / 180;

    return {
      x: centerX + (radius * Math.cos(radians)),
      y: centerY + (radius * Math.sin(radians))
    }
  }

  ngOnDestroy() {
    window.clearInterval(this.intervalID);
  }


}
