import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'codeitemsExpanderIcon'
})
export class CodeitemsExpanderIconPipe implements PipeTransform {

  transform(event: any, args?: any): any {

    if (!!event && !!event.codeItems) {
      if (args) { //expanded?
        return 'fa fa-fw fa-angle-up';
      } else {
        return 'fa fa-fw fa-angle-down';
      }
    }

    return 'fa fa-fw fa-angle-down';
  }

}
