import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';

@Pipe({
  name: 'deviceEvents'
})
export class DeviceEventsFilterPipe implements PipeTransform {

  constructor(
    private store: Store<any>
  ) { }

  transform(value: Array<any>, args?: any): Array<any> {
    let list = value.concat();

    console.log('devevt: transforming', value, args);
    if (value && args) {
      let device = args[0];
      // let protocolId = args[1];
      if (device.id) {
        list = value.filter((evt: any) => {
          return (evt.deviceId === device.id || evt.deviceId === device.protocolId) ;
        });
      }
    }

    console.log('filtered event list is', list);
    return list;
  }
}
