
import { baseEnvironment } from '@when-then/core';

const environment = {
    ...baseEnvironment,
    ... {
        production: true,
        brokerAddr: 'reflector.control4.com'
    }
};


export { environment };
