import { EventType } from './event-type.interface';

export const LIGHT_BUTTON: EventType = {
  name: 'Button',
  description: 'When a light switch button is tapped',
  route: 'buttons/lights',
  category: 'Buttons',
  icon: 'lightbulb',
  order: 2,
  whitelist: [
    {
      fields: { proxy: 'light_v2' },
      events: [5003, 5004, 5005, 5008, 5009, 5010, 5013, 5014, 5015]
    }
  ]
}
