import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hasParams'
})
export class HasParamsPipe implements PipeTransform {

  transform(codeitem: any, args?: any): boolean {
    return (
      codeitem &&
      codeitem.params &&
      codeitem.params.length > 0 &&
      this.hasPlacehoders(codeitem)
    );
  }

  hasPlacehoders(codeitem: any): boolean {
    return (codeitem.display.indexOf('PARAM:') >= 0);
  }

}
