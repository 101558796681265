import { Router, ActivatedRoute } from '@angular/router';
import { Observable, combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { LightsService, LightsProgrammingContext } from '../services/lights.service';
import { Light } from '../services/interfaces/light.interface';
import { Button } from '../services/interfaces/button.interface';
import { QuickstartProgrammingService, QuickstartsContext } from '../../services/quickstart-programming.service';

import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';
import { ProgrammingUtilsService as Util } from './../../../common/services/utils.service';
import { DeviceEvent } from '../../../common/interfaces/event.interface';
import { SharedProgrammingService } from '../../../common/services/shared-programming.service';

const IGNORABLE_BUTTON_ACTIONS: string[] = ['pushed', 'pressed', 'released'];

@Component({
  selector: 'prg-select-light-interaction',
  templateUrl: './select-light-interaction.component.html',
  styleUrls: ['./select-light-interaction.component.less']
})
export class SelectLightInteractionComponent extends BaseRoutingComponent implements OnInit {

  device: Observable<Light>;
  button: Observable<Button>;
  events: Observable<DeviceEvent[]>;

  constructor(
    protected router: Router,
    protected store: Store<{
      lightsProgramming: LightsProgrammingContext,
      quickstartProgramming: QuickstartsContext
    }>,
    private route: ActivatedRoute,
    private lightsService: LightsService,
    private utils: Util,
    private shared: SharedProgrammingService
  ) {
    super();
    this.device = this.store.select(s => s.lightsProgramming.trigger.light);
    this.button = this.store.select(s => s.lightsProgramming.trigger.button);
    this.events = combineLatest(
      this.button.pipe(filter(b => !!b)),
      this.store.select(s => s.lightsProgramming.trigger.events).pipe(filter(e => !!e)),
      (button, events) => {
        return events.filter(
          event => event.buttonName === button.buttonName &&
            IGNORABLE_BUTTON_ACTIONS.indexOf(event.buttonAction.toLowerCase()) === -1
        );
      }
    );
  }

  ngOnInit() {
    // this.shared.addBreadcrumb({ label: 'Interaction', route: Util.getPathFromRoot(this.route) });
  }

  interactionSelected(event: DeviceEvent) {
    // this.lightsService.setTriggerEvent(event);
    this.router.navigate([['when-then', 'device', event.deviceId, 'event', event.eventId, 'add'].join('/')], { relativeTo: this.moduleRoot });
  }

  goBack() {
    this.router.navigate(['../buttons'], { relativeTo: this.route });
  }

  goHome() {
    this.router.navigate(['../programming'], { relativeTo: this.moduleRoot });
  }
}
