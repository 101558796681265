import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';

@Injectable()
/**
 * The purpose of the AuthorizationGuard is to
 * grant / deny route permissions for specific
 * user roles.
 */
export class AuthorizationGuard implements CanActivate, CanActivateChild {

  constructor(

  ) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    // console.log('~~~ checking authentication guard');
    // TODO: Actual authorization checks...

    return true;
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {

    return this.canActivate(route, state);
  }
}
