import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, combineLatest } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { Device } from '../../../common/interfaces/item.interface';
import { SharedProgrammingService, SET_ACTION_DEVICE } from '../../../common/services/shared-programming.service';
import { SimpleProgrammingContext } from '../quickstart-simple.service';
import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';
import { CommandTypes } from '../command-types/command-types';
import { CommandType } from '../command-types/command-type.interface';

@Component({
  templateUrl: './select-action-device.component.html',
  styleUrls: ['./select-action-device.component.less']
})
export class QSSimpleSelectActionDeviceComponent extends BaseRoutingComponent {

  _searchText: string;
  ready: Observable<boolean>;
  _commandType: Observable<CommandType>;
  _devices: Observable<Device[]>;

  constructor(
    private _route: ActivatedRoute,
    protected store: Store<{
      simpleProgramming: SimpleProgrammingContext
    }>,
    protected router: Router,
    private _shared: SharedProgrammingService
  ) {
    super();

    this._commandType = this._route.params
      .pipe(
        map((params: { type: string }) => {
          return CommandTypes.find(et => et.route == params.type);
        })
      );

    this._devices = combineLatest(
      this._commandType
        .pipe(
          filter(et => !!et),
          map(et => et.whitelist.map(wl => wl.proxy))
        ),
      this.store.select(s => s.simpleProgramming.devices).pipe(filter(d => !!d)),
      (whitelist, devices) => {
        return devices.filter(device => {
          return whitelist.indexOf(device.proxy) > -1;
        });
      });

    this.ready = this._devices.pipe(map(ds => !!ds));
  }

  cancelClicked() {
    if (this.isForTrigger(this._route)) {
      this.goHome();
    } else {
      this.goToEvent();
    }
  }

  selectDevice(device: Device) {
    this.store.dispatch({ type: SET_ACTION_DEVICE, payload: device });
    this.router.navigate(['../device', device.id], {
      skipLocationChange: true,
      relativeTo: this._route
    })
  }
}
