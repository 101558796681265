import { RemoteProgrammingContext } from './../quickstart-system-remotes/quickstart-remotes.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { Observable, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { ItemsService } from './../../common/services/items.service';
import { Device } from '../../common/interfaces/item.interface';
import { SharedProgrammingService, CommonProgrammingContext } from './../../common/services/shared-programming.service';
import { ProgrammingUtilsService as Util } from './../../common/services/utils.service';
import { OSCompatibilityService } from '../../common/services/os-compatibility.service';
import { BaseRoutingComponent } from '../../common/base-routing/base-routing.component';

import { QuickstartsContext } from './../services/quickstart-programming.service';
import { MediaProgrammingContext, Room, QSMediaService } from '../quickstart-media/quickstart-media.service';
import { QSThermostatService, ThermostatProgrammingContext } from '../quickstart-thermostats/quickstart-thermostats.service';
import { Logger, LoggerFactory } from '@when-then/core';

const MEDIA_PROXIES = [
  'tuner',
  'tv',
  'media_service',
  'control4_network_mediastorage',
  'control4_usb_mediastorage'
];

@Component({
  selector: 'prg-select-action',
  templateUrl: './select-action.component.html',
  styleUrls: ['./select-action.component.less']
})
export class SelectActionComponent extends BaseRoutingComponent implements OnInit {

  private _logger: Logger;

  trigger: Observable<any>;
  supportedActions: Observable<Array<any>>;

  _audioSourceSupported: Observable<boolean>;
  _videoSourceSupported: Observable<boolean>;

  _mediaDevices: Observable<Device[]>;

  _stationSupported: Observable<boolean>;
  _albumSupported: Observable<boolean>;
  _playlistSupported: Observable<boolean>;
  _broadcastSupported: Observable<boolean>;
  _movieSupported: Observable<boolean>;
  _findRemoteSupported: Observable<boolean>;
  _mediaActionsSupported: Observable<boolean>;
  _thermostatSupported: Observable<boolean>;

  // checks OS compatibility
  hasOSSupportForRemotes: boolean = false;

  constructor(
    protected router: Router,
    protected store: Store<{
      sharedProgramming: CommonProgrammingContext,
      quickstartProgramming: QuickstartsContext,
      mediaProgramming: MediaProgrammingContext,
      remoteProgramming: RemoteProgrammingContext,
      thermostatProgramming: ThermostatProgrammingContext
    }>,
    private shared: SharedProgrammingService,
    private osCompatibilityService: OSCompatibilityService,
    private _items: ItemsService,

    // Leave these injections. While they don't appear
    // to be used, they are necessary to initialize
    // the service and store outside their respective
    // modules
    private mediaService: QSMediaService,
    private _thermService: QSThermostatService
  ) {
    super();

    this._logger = LoggerFactory.getLogger(SelectActionComponent.name);

    const rooms = this.store.select(s => s.mediaProgramming.rooms)
      .pipe(
        filter(r => !!r && r.length > 0)
      );

    this._audioSourceSupported = this.store.select(s => s.mediaProgramming.listen_devices)
      .pipe(
        filter(rooms => !!rooms),
        map(rooms => rooms.some(room => {
          return !!room.listen_devices
            && room.listen_devices.length > 0
            && room.listen_devices.some(d => d.visible);
        }))
      );

    this._videoSourceSupported = this.store.select(s => s.mediaProgramming.watch_devices)
      .pipe(
        filter(rooms => !!rooms),
        map(rooms => rooms.some(room => {
          return !!room.watch_devices
            && room.watch_devices.length > 0
            && room.watch_devices.some(d => d.visible);
        }))
      );

    this._mediaDevices = <Observable<Device[]>>this._items.itemsList
      .pipe(
        map(items => {
          return items.filter((item: Device) => {
            return MEDIA_PROXIES.indexOf(item.proxy) > -1;
          });
        })
      );

    this._mediaDevices.subscribe(devices => this._logger.debug('media devices: ', devices));

    // "broadcast audio" includes both OTA stations and streaming
    // sources for now.
    this._stationSupported = this._mediaDevices
      .pipe(
        map(ds => ds.some(d =>
          ['tuner', 'media_service'].indexOf(d.proxy) > -1
        ))
      );

    this._broadcastSupported = this._mediaDevices
      .pipe(
        map(ds => ds.some(d => d.proxy === 'tv'))
      );

    this._albumSupported = this._mediaDevices
      .pipe(
        map(ds => ds.some(d =>
          ['control4_network_mediastorage', 'control4_usb_mediastorage'].indexOf(d.proxy) > -1
        ))
      );

    this._playlistSupported = this._albumSupported;
    this._movieSupported = this._albumSupported;

    this._findRemoteSupported = this.store.select(s => s.remoteProgramming.remotes)
      .pipe(
        filter(r => !!r),
        map(rs => rs.filter(r => !!r.capabilities && !!r.capabilities['can_be_found'])),
        map(r => r.length > 0)
      );

    this._mediaActionsSupported = combineLatest(
      this._stationSupported,
      this._broadcastSupported,
      this._albumSupported,
      this._playlistSupported,
      this._movieSupported,
      this._findRemoteSupported,
      Util.someTrue
    );

    this._thermostatSupported = this.store.select(s => s.thermostatProgramming)
      .pipe(
        map(c => !!c.thermostats && c.thermostats.length > 0)
      );

    this.supportedActions = this.store.select(s => s.quickstartProgramming.supportedActions);
    this.trigger = this.store.select(s => s.sharedProgramming.trigger.event);
  }

  ngOnInit() {
    this.shared.clearError();
    this.hasOSSupportForRemotes = this.osCompatibilityService.isFeatureSupported('action', 'remote-beep');
    this._logger.debug('OS support for system remotes', this.hasOSSupportForRemotes);
    this._logger.debug('find remote supported', Util.snapshot<boolean>(this._findRemoteSupported));
  }

  cancelClicked() {
    this.goToEvent();
    // TODO should this be goHome()?
  }

  private _someRooms = (callback: (room: Room) => boolean): Observable<boolean> => {
    return this.store.select(s => s.mediaProgramming.rooms)
      .pipe(
        filter(r => !!r && r.length > 0),
        map(rooms => rooms.some(callback))
      )
  }
}
