import { EventType } from './event-type.interface';

export const DRIVEWAY: EventType = {
  name: 'Driveway',
  description: 'When a Driveway sensor indicates a car has come or gone',
  route: 'driveway',
  category: 'Sensors',
  icon: 'driveway',
  whitelist: [
    {
      fields: { proxy: 'contactsingle_drivewaysensor_c4' },
      events: [1, 2]
    }
  ]
}
