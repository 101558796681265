import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'recent'
})
export class RecentPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (Array.isArray(value)) {
      return value.sort(this.sortByCreateDate);
    }


    return value;
  }


  private sortByCreateDate(a: any, b: any): number {
    if (a.creatorState && b.creatorState) {
      let acs = JSON.parse(a.creatorState);
      let bcs = JSON.parse(b.creatorState);
      if (acs && acs.createDate && bcs && bcs.createDate) {
        let adt = Date.parse(acs.createDate);
        let bdt = Date.parse(bcs.createDate);

        if (adt < bdt) return -1;
        if (adt > bdt) return 1;
      }
    }

    return 0;
  }
}
