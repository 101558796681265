import { ActionReducerMap } from '@ngrx/store';

import { keypadProgrammingReducer, KeypadProgrammingContext } from './services/keypads.service';

export interface QuickstartKeypadsState {
  keypadProgramming: KeypadProgrammingContext
}

export const QUICKSTART_KEYPADS_REDUCERS: ActionReducerMap<QuickstartKeypadsState> = {
  keypadProgramming: keypadProgrammingReducer
};
