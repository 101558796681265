import { Pipe, PipeTransform } from '@angular/core';

import * as _ from 'lodash';

@Pipe({
  name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {
  transform(value: string): string {
    if (value) {
      return _.startCase(value.toLowerCase());
    } else {
      return value;
    }
  }
}
