<div [cxWait]="(ready | async)">
  <div *ngIf="!(searchText | async) && source?.length === 0" class="alert">
    <header>
      <span class="icon information grey small"></span>
      <h5 class="grow">No Scheduled Automation Found</h5>
    </header>
    <p>There is no scheduled automation to be displayed. You can try selecting a different filter, or <a [routerLink]="['../add/schedule/0']">create
        a new schedule</a>.
    </p>
  </div>

  <div class="alert flex" *ngIf="(searchText | async) && source?.length === 0">
    <header>
      <span class="icon information grey small"></span>
      <h5 class="grow">No Matching Automation </h5>
    </header>
    <p class="grow">No automation matches the search text you have entered. Try searching for room names, device names
      or specific actions,
      such as "opened" or "tapped".</p>
  </div>
  <prg-when-then-header *ngIf="(source)?.length"></prg-when-then-header>

  <cxScrollable>
    <div prg-event-codeitems *ngFor="let item of (source | sortBy:'display')"
      [expanded]="expanded" [event]="item" [subtitle]="item | scheduleEventLabel" (click)="tileClicked(item)">
    </div>
  </cxScrollable>
</div>
