import { CreatorPipe } from './../../common/pipes/creator.pipe';
import { MatchingSearchTextPipe } from './../../common/pipes/matching-search-text.pipe';
import { EVENTS_UI_ACTIONS, EventsUIService } from './../events/eventsUI.service';
import { EventsService } from './../../common/services/events.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { switchMap, filter, map, withLatestFrom } from 'rxjs/operators';
import { Component, OnInit, Input } from '@angular/core';

import { Event } from './../../common/interfaces/event.interface';
import { SharedProgrammingService } from '../../common/services/shared-programming.service';
import { VoiceScenesService } from '../quickstart-voice-scenes/voice-scenes.service';
import { EVENTS_FILTER } from '../events/eventsUI.service';
import { ItemsService } from '../../common/services';

@Component({
  selector: 'prg-list-by-room',
  templateUrl: './list-by-room.component.html'
})
export class ListByRoomComponent implements OnInit {
  searchText: Observable<string>;
  deviceEvents: Observable<Event[]>;
  ready: Observable<boolean>;

  @Input() source: Event[];

  filteredEvents: Observable<Event[]>;

  constructor(
    protected router: Router,
    private route: ActivatedRoute,
    private shared: SharedProgrammingService,
    private eventsUI: EventsUIService
  ) {
    this.searchText = this.shared.searchText;
    this.ready = this.shared.isReady;
  }

  ngOnInit() {
    this.shared.clearError();
    this.shared.setGrouping('room');
  }

  ngOnChanges() {
    if (!!this.source) {
      this.eventsUI.setEventCount(this.source.length);
    }
  }

  private tileClicked(item: any) {
    console.log('tile clicked: ', item);
    if (item) {
      // this.shared.setSelectedEvent(item).then(res => {
      this.router.navigate(['../device', item.deviceId, 'event', item.eventId, 'edit'], { relativeTo: this.route });
      // });
    }
  }
}
