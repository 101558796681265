import { Store } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';
import { StorageService } from '@when-then/core';
import { Router } from '@angular/router';
import { BaseRoutingComponent } from '../../common/base-routing/base-routing.component';

declare var window: any;
@Component({
  selector: 'prg-introduction',
  templateUrl: './introduction.component.html',
  styleUrls: ['./introduction.component.less']
})
export class IntroductionComponent extends BaseRoutingComponent implements OnInit {

  constructor(
    private storage: StorageService,
    protected router: Router,
    protected store: Store<any>
  ) {
    super();
  }

  ngOnInit() {
  }

  showVideo() {
    window.open('https://control4.wistia.com/medias/7r3yu2ag73', '_blank');
  }
}
