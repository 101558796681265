import { Action } from '@ngrx/store';
import { Item, Device } from './../../common/interfaces/item.interface';

const STORE_NAME = ['PROGRAMMING', 'QUICKSTARTS', 'REMOTES'].join(':');
export const REMOTES_ACTIONS = {
  SET_REMOTES: `${STORE_NAME}:SET_REMOTES`,
  SET_SELECTED_ROOM: `${STORE_NAME}:SET_SELECTED_ROOM`,
  SET_ROOM_EVENTS: `${STORE_NAME}:SET_ROOM_EVENTS`
}

export class SetSystemRemotes implements Action {
  readonly type = REMOTES_ACTIONS.SET_REMOTES;

  constructor(public payload: Device[]) { }
}
