import { Store } from '@ngrx/store';
import { BrokerService, Logger, LoggerFactory, LogLevel, Location, LocationsService } from '@when-then/core';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, from } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { LightsService } from './../services/lights.service';
import { SharedProgrammingService } from '../../../common/services/shared-programming.service';
import { LightSceneRef } from '../services/interfaces/light.interface';
import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';

@Component({
  selector: 'prg-light-scene-list',
  templateUrl: './light-scene-list.component.html',
  styleUrls: ['./light-scene-list.component.less']
})
export class LightSceneListComponent extends BaseRoutingComponent {
  private _logger: Logger;
  _scenes: Observable<LightSceneRef[]>;

  _selectedRoom: any;
  _rooms: Observable<Location[]>;
  _searchText: string;

  constructor(
    private route: ActivatedRoute,
    private shared: SharedProgrammingService,
    private lights: LightsService,
    private locations: LocationsService,
    private broker: BrokerService,
    protected store: Store<any>,
    protected router: Router
  ) {
    super();
    this._logger = LoggerFactory.getLogger(LightSceneListComponent.name);
    // this._logger.setLevel(LogLevel.DEBUG);
    this._rooms = this.locations.getRooms();
    this._selectedRoom = '0';

    this._allScenes = from(this.broker.call({
      path: '/api/v1/agents/advanced_lighting'
    }));
  }

  ngOnInit() {
    this.setScenes();
  }

  setScenes() {
    let roomId = parseInt(this._selectedRoom);
    this._logger.debug('lights: selected room is', roomId);
    if (roomId > 0) {
      this._scenes = from(this.broker.call({
        path: '/api/v1/locations/rooms/' + roomId + '/light_scenes'
      }))
        .pipe(
          map((scenes: any) => scenes.visible)
        );
    } else {
      this._scenes = this._allScenes;
    }
  }

  private _allScenes: Observable<LightSceneRef[]>;

  /**
   * NOTE because of the filtering above we can't rely solely on LightSceneRef
   * TODO need to declare a type for the response from the above request
   * @param scene LightSceneRef | any
   */
  routeToScene(scene: any) {
    this.route.data.pipe(take(1)).subscribe(data => {
      this._logger.debug('routing to lighting scene', scene, data.next);
      // HACK due to the filtering above
      // NOTE assume the room scene case
      let id = scene.id;
      if (scene.hasOwnProperty('scene_id')) {
        // if we got the unfilterd version use that instead
        id = scene.scene_id;
      }
      this.router.navigate(['..', id, data.next], { relativeTo: this.route });
    });
  }

  cancelClicked() {
    // HACKISH this means we're selecting the event, so cancel means "home"
    if (this.isForTrigger(this.route)) {
      this.goHome();
    } else {
      // otherwise we must be adding a light scene command, so cancel means "event"
      this.goToEvent();
    }
  }
}


interface RoomScene {
  id: number;
  name: string;
  light_scenes: LightSceneRef[];
}
