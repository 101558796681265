import { EventType } from './event-type.interface';

export const ROOM_VIDEO: EventType = {
  name: 'Room Video Changed',
  description: 'When the video selection playing in a room has changed',
  route: 'rooms/3',
  category: 'Room Media',
  icon: 'watch',
  whitelist: [
    {
      fields: { proxy: 'roomdevice' },
      events: [3]
    }
  ]
}
