import { EventType } from './event-type.interface';

export const CONTACT_SENSOR: EventType = {
  name: 'Contact Switch',
  description: 'When a Contact Switch is triggered',
  route: 'contact-sensor',
  category: 'Security & Access',
  icon: 'relay',
  whitelist: [
    {
      fields: { proxy: 'control4_contactsingle' },
      events: [1, 2]
    },
    {
      fields: { proxy: 'contactsingle_contactswitch_c4' },
      events: [1, 2]
    }
  ]
}
