<div style="margin: 12px 0;" [cxWait]="(initialized | async)">
  <div *ngIf="!(searchText | async) && (source | sortSchedules | groupByTime)?.length === 0" class="alert">
    <header>
      <span class="icon information small grey"></span>
      <h5 class="grow">No Automation Found</h5>
    </header>
    <p>There is currently no automation scheduled to happen today. Try <a [routerLink]="['../add/schedule/0']">creating
        some</a>.
      It's easy.
    </p>
  </div>
  <div class="alert flex" *ngIf="(searchText | async) && (source?.length === 0)">
    <h5><span class="icon information blue"></span> No Matching Automation </h5>
    <p class="grow">No automation matches the filter text you have entered. Please try a different filter.</p>
  </div>

  <div *ngIf="source?.length > 0" class="flex-row nowrap">
    <span class="offset"></span>
    <div class="grow">
      <prg-when-then-header></prg-when-then-header>
    </div>
  </div>

  <cxScrollable>
    <section class="groups">
      <div class="time-group row" *ngFor="let group of (source | sortSchedules | groupByTime)">
        <span class="time">{{ group.time | unixTimeFormat }}</span>
        <span class="events">
          <div class="event" prg-event-codeitems *ngFor="let item of group.events" [expanded]="expanded" [event]="item"
            [subtitle]="item | scheduleEventLabel" (click)="tileClicked(item)">
          </div>
        </span>
      </div>
    </section>
  </cxScrollable>
</div>
