// tslint:disable:no-bitwise

import { Pipe, PipeTransform } from '@angular/core';
import {
  ProgrammingUtilsService as Utils, WEEKS_OF_THE_MONTH, DAYS_OF_THE_WEEK,
  MONTHS_OF_THE_YEAR, SHORT_DAYS_OF_THE_WEEK
} from '../../../common/services';

import * as moment from 'moment';
import { ScheduleViewModel } from '../+state/schedule.interfaces';
import { Logger, LoggerFactory } from '@when-then/core';

const REPEAT_INTERVALS: Array<string> = [
  '', // for no repeat
  'days',
  'weeks',
  'months',
  'years'
];

const DOW_ABBREVS: Array<string> = [
  'Su',
  'M',
  'Tu',
  'W',
  'Th',
  'F',
  'Sa'
];

const DOW_WEEKEND = 65;
const DOW_WEEKDAYS = 62;
const DOW_ALL = 127;

enum Repeats {
  None = 0,
  Daily = 1,
  Weekly = 2,
  Monthly = 3,
  Yeary = 4
}

const MOMENT_DATE_DISPLAY_FORMAT = 'dddd, MMMM Do, YYYY';

@Pipe({
  name: 'scheduleVMLabel',
  pure: false
})
export class ScheduleVMLabelPipe implements PipeTransform {

  private _logger: Logger;

  constructor() {
    this._logger = LoggerFactory.getLogger(ScheduleVMLabelPipe.name);
  }

  transform(vm: ScheduleViewModel, args?: any): any {
    // this._logger.debug('transform');
    let label = '';

    if (!!vm) {
      // start date
      if (vm.startDateType === 'fixed') {
        // fixed start
        label += ' on ';
        label += moment(vm.startDate).format(MOMENT_DATE_DISPLAY_FORMAT);
      } else {
        // relative start
        label += 'on the ';
        label += WEEKS_OF_THE_MONTH[vm.startWeekOffset - 1];
        label += ' ';
        label += DAYS_OF_THE_WEEK[vm.startDayOfWeek - 1];
        label += ' of ';
        label += MONTHS_OF_THE_YEAR[vm.startMonth - 1];
        label += ', ';
        label += vm.startYear
        label += ', ';
      }
    }

    // start time
    label += this.startTimeLabel(vm);

    // repeat
    if (vm.repeatType === 'every') {
      label = 'Starting ' + label;

      label += (' and repeating every ' + this.getFrequency(vm) + ' ');

      // end date
      if (vm.hasEndDate) {
        label += ' until ';
        label += moment(vm.endDate).format(MOMENT_DATE_DISPLAY_FORMAT);
      }
    } else {
      label = 'Happening once ' + label;
    }

    // this._logger.debug('schedule label from VM is ', label);
    return label;
  }

  private startTimeLabel(vm: ScheduleViewModel, args?: any): any {
    // this._logger.debug('preparing time label with', vm);
    let label = '';
    if (vm) {
      if (vm.startTimeType === 'fixed') {
        // at a fixed time
        label += ' at ';
        let now = new Date();
        // this._logger.debug('time-label: current offset minutes are:', vm.startOffsetMinutes);

        let parts = vm.startTime.split(':');
        let minutes = (parseInt(parts[0], 10) * 60) + parseInt(parts[1], 10);
        label += Utils.toTimeDisplay(now.getFullYear(), now.getMonth() + 1, now.getDate(), minutes);
        label += ' ';
      } else {
        // relative to sunrise sunset
        if (vm.sunriseSunsetInterval === 0) {
          label += ' at ';
        } else {
          label += ' ' + vm.sunriseSunsetInterval + ' minutes ';
          label += `${vm.sunriseSunsetOffsetType} `;
        }

        label += `${vm.sunriseOrSunset} `;
      }

      if (vm.isRandomized) {
        label += ('+/- ' + vm.randomizeBy + ' minutes ');
      }
    }

    return label;
  }

  private getFrequency(vm: ScheduleViewModel): string {
    let freq = '';

    freq += (vm.repeatRate + ' ');

    switch (vm.repeatFrequency) {
      case 0: return ''; // NOTE shouldn't get here
      case 1: freq += 'day'; break;
      case 2: freq += 'week'; break;
      case 3: freq += 'month'; break;
      case 4: freq += 'year'; break;
    }

    if (vm.repeatRate > 1) {
      freq += 's';
    }

    if (vm.repeatFrequency === 2) {
      freq += ' on ';
      freq += this.composeDaysOfTheWeekDisplay(vm.repeatDays);
    }

    return freq;
  }

  private composeDaysOfTheWeekDisplay(days: number): string {
    let display = '';

    const list = [];

    for (let i = 0; i < 7; i++) {
      let val = Math.pow(2, i);
      if ((days & val) === val) {
        list.push(SHORT_DAYS_OF_THE_WEEK[i]);
      }
    }

    return list.join(', ');
  }

  private getStartTime(vm: ScheduleViewModel): string {
    let display = '';

    if (!!vm) {
      if (vm.startTimeType === 'sol') {
        // sunrise or sunset
        if (vm.startOffsetMinutes < 0) {
          display += (Math.abs(vm.startOffsetMinutes) + ' before ');
        } else if (vm.startOffsetMinutes > 0) {
          display += (vm.startOffsetMinutes + ' after ');
        }
      } else {
        display += 'At ';
      }

      display += `${vm.sunriseOrSunset} `;
    } else {
      display += 'At ';
      let now = new Date();
      let h = Math.floor((vm.startOffsetMinutes / 60));
      let m = (vm.startOffsetMinutes % 60);
      display += Utils.toTimeDisplay(now.getFullYear(), now.getMonth() + 1, now.getDate(), vm.startOffsetMinutes);
      display += ' ';
    }

    return display;
  }
}

