import {Pipe, PipeTransform} from '@angular/core';
import {Store} from '@ngrx/store';

@Pipe({
  name: 'categoryEvents'
})
export class CategoryEventFilterPipe implements PipeTransform {

  constructor(
    private store: Store<any>
  ) { }

  transform(events: any, category: any): Array<any> {
    // TODO filter the list of events to those found on devices in the selected cateogry
    return events;
  }
}
