import { Component } from '@angular/core';

import { QSThermostatService } from './quickstart-thermostats.service';

@Component({
  template: '<router-outlet></router-outlet>'
})
export class QSThermostatComponent {

}
