import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { BrokerService } from '@when-then/core';

import { AuthorizationProvider, AccessControlList } from './../strategies/authorization-provider';
import { AuthenticationState } from './authentication-state';
import { IdentityState, IdentityService } from './identity.service';
import { RoleBasedAuthorizationProvider } from './../strategies/rbac-provider';

export interface AuthorizationState {
  authorizedFor?: AccessControlList;
  initialized: boolean;
  error: boolean;
  errors: Array<any>;
}

export const AUTHORIZED_INITIAL_STATE: AuthorizationState = {
  initialized: false,
  error: false,
  errors: []
}

const STORE_NAME = 'AUTHORIZATION';

export const ACTIONS = {
  SET_INITIALIZED: `${STORE_NAME}:SET_INITIALIZED`,
  SET_ERRORS: `${STORE_NAME}:SET_ERRORS`,
  SET_AUTHORIZATION: `${STORE_NAME}:SET_AUTHORIZATION`,
  CLEAR: `${STORE_NAME}:CLEAR`
}

export function authorizationReducer(state: AuthorizationState, { type, payload }): AuthorizationState {

  switch (type) {
    case ACTIONS.SET_INITIALIZED:
      return Object.assign({}, state, { initialized: true });

    case ACTIONS.SET_ERRORS:
      let errors = [...payload, ...state.errors];
      return Object.assign({}, state, { error: true, errors: errors });

    case ACTIONS.SET_AUTHORIZATION:
      return Object.assign({}, state, { authorizedFor: payload });

    case ACTIONS.CLEAR:
      return Object.assign({}, AUTHORIZED_INITIAL_STATE);

    default:
      return state;
  }
}

@Injectable()
export class AuthorizationService {

  private _identity: Observable<IdentityState>;

  constructor(
    private store: Store<{ authentication: AuthenticationState, authorization: AuthorizationState, identity: IdentityState }>,
    private authorizationProvider: RoleBasedAuthorizationProvider
  ) {
    this._identity = this.store.select(s => s.identity);

    this._identity
      .pipe(
        distinctUntilChanged(),
        filter(ident => !!ident)
      )
      .subscribe(ident => {
        this.setup(ident);
      });
  }

  private setup = (ident: IdentityState) => {
    if (ident) {
      let authZ = this.authorizationProvider.provide(ident);
      this.store.dispatch({ type: ACTIONS.SET_AUTHORIZATION, payload: authZ });
    }
  }

  getAuthorizations(): AccessControlList {
    let authZ: AuthorizationState = undefined;
    this.store.select(s => s.authorization).pipe(take(1)).subscribe(authz => authZ = authz);
    if (authZ && authZ.authorizedFor) {
      return authZ.authorizedFor;
    }

    return {
      login: true,
      composer: false,
      quickstartProgramming: false,
      systemManager: false
    };
  }

  // private teardown = () => {
  //   this.store.dispatch({ type: ACTIONS.CLEAR });
  // }
}
