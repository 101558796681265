import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { map, filter, take } from 'rxjs/operators';

import { ItemsService } from '../../../common/services';
import { Device } from '../../../common/interfaces/item.interface';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';
import { SET_TRIGGER_DEVICE, CommonProgrammingContext } from '../../../common/services/shared-programming.service';
import { EventType } from '../../quickstart-simple/event-types/event-type.interface';

@Component({
  selector: 'prg-doorstation',
  templateUrl: './doorstation.component.html',
  styleUrls: ['./doorstation.component.less']
})
export class DoorstationComponent extends BaseRoutingComponent implements OnInit {

  doorstations: Observable<Array<Device>>;

  constructor(
    protected route: ActivatedRoute,
    protected store: Store<{
      sharedProgramming: CommonProgrammingContext
    }>,
    protected router: Router,
    private items: ItemsService
  ) {
    super();

    this.doorstations = combineLatest<Device[]>(
      this.items.itemsList.pipe(map(list => list.filter(itm => itm.type === 7))),
      this.store.select(s => s.sharedProgramming.trigger.eventType).pipe(filter(et => !!et)),
      (items, eventType) => {
        return items.filter(itm => this._deviceSupportsType(<Device>itm, eventType));
      }
    );
  }

  private _deviceSupportsType(itm: Device, type: EventType): boolean {
    return type.whitelist.some(wl => Object.keys(wl.fields).every(key => itm.hasOwnProperty(key) && itm[key] == wl.fields[key]));
  }

  ngOnInit() {
    this.doorstations
      .pipe(
        filter(ds => !!ds)
      )
      .subscribe(ds => {
        if (ds.length === 1) {
          this.selectDevice(ds[0]);
        }
      });
  }

  selectDevice(doorstation: Device) {
    this.store.dispatch({ type: SET_TRIGGER_DEVICE, payload: doorstation });

    this.route.data.pipe(take(1)).subscribe(data => {
      this.router.navigate([doorstation.id, 'events'], { relativeTo: this.route, skipLocationChange: true });
    });
  }
}
