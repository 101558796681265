import { EventType } from './event-type.interface';

export const LIGHTING_SCENES: EventType = {
  name: 'Lighting Scenes',
  description: 'When a lighting scene is activated or deactivated',
  route: 'lights/scenes',
  category: 'Lights',
  icon: 'lightscene',
  order: 3,
  whitelist: [
    {
      fields: { control: 'control4_agent_adv_lighting' },
      events: []
    }
  ]
}
