<prg-nav-header title="{{ _config[( _sourceName | async)]?.display }} in {{ ( _room | async )?.name }}" (cancel)="goToEvent()">
  <div right *ngFor="let filter of (_filters | async)">
    <select (change)="_setFilter(filter.id, $event.target.value)">
      <option [value]="filter.default">{{ filter.default }}</option>
      <option *ngFor="let op of filter.options" [value]="op">{{ op }}</option>
    </select>
  </div>
</prg-nav-header>
<cxScrollable>
  <cxWait [cxUntil]="(_channels | async) != undefined" [cxText]="_waitText | async">
    <prg-block-button *ngFor="let channel of (_filteredChannels | async)" (click)="_setAction(channel)" [leadingIcon]="_config[(_sourceName|async)]?.icon"
      title="{{ channel.name || channel.title }}" [subtitle]="getSubtitle(channel)"></prg-block-button>
    <div class="row alert" *ngIf="(_channels | async)?.length == 0">
      <header>No Media Found</header>
    </div>
  </cxWait>
</cxScrollable>
