import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { map, filter, take } from 'rxjs/operators';

// import { LightsProgrammingContext, LightsService } from '../services/lights.service';
import { LightsService, LightsProgrammingContext } from '../services/lights.service';
import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';
import { ProgrammingUtilsService as Utils } from '../../../common/services/utils.service';
import { CommonProgrammingContext } from '../../../common/services/shared-programming.service';

import { Parameter } from '../../../common/interfaces/parameter.interface';
import { Light } from '../services/interfaces/light.interface';
import { Command } from '../../../common/interfaces/command.interface';

@Component({
  selector: 'prg-select-parameters',
  templateUrl: './select-parameters.component.html',
  styleUrls: ['./select-parameters.component.less']
})
export class SelectParametersComponent extends BaseRoutingComponent {

  _command: Observable<Command>;
  _device: Observable<Light>;
  _params: Observable<Parameter[]>;
  _routeParams: Observable<{ command: string, id: string, lightId: string }>;

  _snapshot = Utils.snapshot;

  constructor(
    public router: Router,
    protected store: Store<{
      lightsProgramming: LightsProgrammingContext,
      sharedProgramming: CommonProgrammingContext
    }>,
    private _route: ActivatedRoute,
    private _lights: LightsService,
    private _utils: Utils
  ) {
    super();
    this._routeParams = <Observable<{ command: string, id: string, lightId: string }>>this._route.params;

    this._routeParams.pipe(map(p => parseInt(p.lightId))).subscribe(id => this._lights.setActionLight(id));

    this._device = this.store.select(s => s.lightsProgramming.action.light);
    this._command = combineLatest(
      this._routeParams,
      this.store.select(s => s.lightsProgramming.action).pipe(filter(l => !!l && !!l.commands)),
      (routeParams, action) => {
        let id = parseInt(routeParams.id) || undefined;
        return action.commands.find(c => c.command === routeParams.command && c.id === id);
      }
    );

    this._params = this._command.pipe(map(c => c.params));
  }

  setCommand() {
    let command = Utils.snapshot<Command>(this._command);
    let params = Utils.snapshot<Parameter[]>(this._params);
    this._lights.setCommand(command);
    this.store.select(s => s.lightsProgramming.action.command)
      .pipe(
        filter(c => !!c && c.command === command.command),
        take(1)
      )
      .subscribe(data => {
        this.router.navigate(['conditionals'], { relativeTo: this._route });
      });
  }

  identify = (param: Parameter) => param.name + param.type;
}
