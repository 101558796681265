<prg-nav-header
  title="Which {{ category | async | singularize }} would you like to stream from {{ (driver | async)?.name }}?"
  (cancel)="goToEvent()">
</prg-nav-header>

<cxScrollable>
  <cxWait
    [cxUntil]="(collection | async) != undefined"
    cxText="Finding {{ (driver | async)?.name }} {{ category | async }}">

    <ng-container *ngIf="(collection | async)?.length > 0">
      <prg-block-button
        *ngFor="let item of (collection | async)"
        [routerLink]="['./', item.value]"
        [title]="item.text"
        leadingIcon="song blue"
        trailingIcon="right">
      </prg-block-button>
    </ng-container>

    <ng-container *ngIf="(collection | async)?.length == 0">
      <div class="alert alert-warning">
        <header>
          <span class="icon song small grey"></span>
          <h5 class="grow"> No {{ category | async }} Found</h5>
        </header>
        <p class="grow">No {{ category | async }} appear to be configured. Please use an onscreen Navigator, or a Control4 touchscreen to save your favorite {{ (driver | async)?.name }} {{ category | async }}</p>
      </div>
    </ng-container>

  </cxWait>
</cxScrollable>
