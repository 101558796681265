import { Injectable } from '@angular/core';

import { Subscription, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { BrokerService, BrokerRequest, LoggerFactory } from '@when-then/core';

import { ItemsService } from './../../common/services/items.service';
import { Device } from '../../common/interfaces/item.interface';
import { Command } from '../../common/interfaces/command.interface';

export interface ThermostatProgrammingContext {
  listChecked: boolean;
  thermostats?: Device[];
  commandsChecked: boolean;
  commands?: Command[];
}

const COMMAND_WHITELIST = ["SET_PRESET"];
const PROXY_WHITELIST = ['thermostatV2'];

const INITIAL_STATE: ThermostatProgrammingContext = {
  listChecked: false,
  commandsChecked: false
};

const STORE_NAME = ['PROGRAMMING', 'QUICKSTARTS', 'THERMOSTATS'].join(':');

export const THERMOSTAT_ACTIONS = {
  SET_THERMOSTATS: `${STORE_NAME}:SET_THERMOSTATS`,
  SET_COMMANDS: `${STORE_NAME}:SET_COMMANDS`,
  CLEAR: `${STORE_NAME}:CLEAR`
}

const THERMOSTAT_ACTION_STRINGS = Object.keys(THERMOSTAT_ACTIONS).map(key => THERMOSTAT_ACTIONS[key]);

export function thermostatProgrammingReducers(state: ThermostatProgrammingContext = INITIAL_STATE, { type, payload }): ThermostatProgrammingContext {

  // if (THERMOSTAT_ACTION_STRINGS.indexOf(type) > -1) {
  //   console.debug('DEBUG >> %s called with %O', type, payload);
  // }

  switch (type) {
    case THERMOSTAT_ACTIONS.SET_THERMOSTATS:
      return Object.assign({}, state, { thermostats: payload, listChecked: true });

    case THERMOSTAT_ACTIONS.SET_COMMANDS:
      return Object.assign({}, state, { commands: payload, commandsChecked: true });

    case THERMOSTAT_ACTIONS.CLEAR:
      return Object.assign({}, INITIAL_STATE)

    default:
      return state;
  }
}

@Injectable()
export class QSThermostatService {

  private _subscriptions: Subscription[] = [];
  private _logger = LoggerFactory.getLogger(QSThermostatService.name);

  constructor(
    private _store: Store<{
      thermostatProgramming: ThermostatProgrammingContext
    }>,
    private _items: ItemsService,
    // NOTE this is probably a hidden dependency
    private _broker: BrokerService
  ) {
    this._subscriptions.push(

      this._items.itemsList
        .pipe(
          map(items => {
            return items.filter((item: Device) => {
              return PROXY_WHITELIST.indexOf(item.proxy) > -1
                && item.protocolFilename === "control4_thermostat_c4therm.c4z"
                && item.type === 7
            })
          })
        )
        .subscribe(thermostats => {
          this._store.dispatch({
            type: THERMOSTAT_ACTIONS.SET_THERMOSTATS,
            payload: thermostats
          })
        })
    );
  }

  getCommands(id: number): Observable<Command[]> {

    this._broker.call({
      path: `/api/v1/items/${id}/commands`,
      queryString: {
        query: {
          command: {
            '$in': COMMAND_WHITELIST
          }
        }
      }
    }).then(res => {
      this._store.dispatch({ type: THERMOSTAT_ACTIONS.SET_COMMANDS, payload: res });
    }, err => {
      this._logger.error(err);
    })

    return this._store.select(s => s.thermostatProgramming.commands);
  }

  ngOnDestroy() {
    this._subscriptions.forEach(sub => sub.unsubscribe());
    this._store.dispatch({
      type: THERMOSTAT_ACTIONS.CLEAR
    })
  }
}
