import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'matchingSearchText'
})
export class MatchingSearchTextPipe implements PipeTransform {

  transform(items: any, searchText?: string): any {
    if (!!searchText && !!items && Array.isArray(items)) {

      let terms = searchText.split(' ').map(t => t.toLowerCase());
      return items.filter(item => {
        return this.matchesDisplay(item, terms);
      });
    } else {
      return items;
    }
  }

  matchesDisplay(item: any, terms: string[]): boolean {
    let found = false;

    if (item) {
      // HACK to allow searching for event types as well
      let text = item.display || item.description || (item.name + item.roomName + item.floorName);
      if (text) {
        let _display = text.toLowerCase();
        found = terms.every(t => _display.indexOf(t) >= 0);
        if (!found && item.codeItems) {
          for (let i = 0; i < item.codeItems.length; i++) {
            let ci = item.codeItems[i];
            found = this.matchesDisplay(ci, terms);
            if (found) break;
          }
        }
      }
    }

    return found;
  }
}
