import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIModule } from '@when-then/ui';

import { ProgrammingCommonModule } from '../../common/common.module';
import { QuickstartSystemRemotesModule } from './../quickstart-system-remotes/quickstart-system-remotes.module';
import { QuickstartLightsModule } from './../quickstart-lights/quickstart-lights.module';
import { QuickstartKeypadsModule } from './../quickstart-keypads/quickstart-keypads.module';
import { QuickstartCustomButtonsModule } from './../quickstart-custom-buttons/quickstart-custom-buttons.module';

import { SelectTypeComponent } from './select-type/select-type.component';
import { ButtonsComponent } from './buttons.component';
import { QuickstartDoorstationsModule } from '../quickstart-doorstations/quickstart-doorstations.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    UIModule,
    ProgrammingCommonModule,
    QuickstartKeypadsModule,
    QuickstartLightsModule,
    QuickstartSystemRemotesModule,
    QuickstartDoorstationsModule,
    QuickstartCustomButtonsModule
  ],
  declarations: [
    SelectTypeComponent,
    ButtonsComponent
  ],
})
export class QuickstartButtonsModule { }
