import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortBy'
})
export class SortByPipe implements PipeTransform {

  transform(items: any[], args?: any): any {
    // console.log('pipe: sortby: transform', items, args);
    if (items && args) {
      let field = args;

      return items.sort((a: any, b: any) => {
        let af = a[field];
        let bf = b[field];
        if (af && bf) {
          // console.log('pipe: sortby: ', af, bf, af.toString().localeCompare(bf.toString()));
          return af.toString().localeCompare(bf.toString());
        } else {
          if (af) return 1;
          if (bf) return -1;
          return 0;
        }
      });
    } else {
      return items;
    }
  }

}
