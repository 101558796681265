import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { AuthenticationState } from './authentication-state';
import { AuthenticationService } from './authentication.service';

@Injectable()
/**
 * The purpose of the AuthenticationGuard is to enforce
 * the requirement to log in. This should NOT be concerned
 * with whether the user has access to a specific route.
 * That is the domain of the AuthorizationGuard.
 */
export class AuthenticationGuard implements CanActivate, CanActivateChild {

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private store: Store<{ authentication: AuthenticationState }>
  ) {
    // NOTE this causes a premature call to services before the correct endpoint is even known
    this.authenticationService.validateAuthentication();
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.checkAuth(state.url);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.canActivate(route, state);
  }

  private checkAuth(url: string): Observable<boolean> {
    // console.log('~~~ checking authentication guard');
    return this.store.select(s => s.authentication)
      .pipe(
        filter(s => !!s && !!s.initialized),
        map(s => {
          // HACK when W>>T is in the cloud NEVER allow routing to the authenticate route
          // if (!s.authenticated) {
          //   this.authenticationService.setRedirectUrl(url);
          //   this.router.navigate(['/authenticate']);
          // }

          if (!s.authenticated) {
            this.router.navigate(['/session-expired']);
          }

          return s.authenticated;
        })
      );
  }
}
