
import {Injectable} from '@angular/core';

import {CoreConfigService} from './core-config.service';

@Injectable()
export class ResourceService {

  /**
   * returns the fully qualifies path, relative to the root pf the 
   * returns the fully qualifies path, relative to the root of the web app, 
   * of the resource indicated by the given relative path.  This is 
   * required when loading resources from a module, since usually specified 
   * relative to the module's root foldfer which is below the web app 
   * root folder.
   * @param id: string, the module id of the component asking for the resource
   * @oparam path: string. the path to the requested resourceß.  NOTE: this path MSUT 
   * be relative to the current document.  For example, if intend to address an asset
   * that is in a directory two leves above this component that calls this method, you 
   * must provide a path that begins with '../..', such as '../../assets/images/...'.
   */
  getModulePath(id: string, path: string): string {
    console.log('resource.service: getModulePath: ', arguments);
    let base: string = '';

    var slash = id.lastIndexOf('/');
    base = id.substring(0, slash + 1);

    return base + path;
  }
}
