import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, combineLatest } from 'rxjs';
import { map, filter, tap, take, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { RouterHistoryState, RouterHistoryService } from '@when-then/core';

import { ProgrammingUtilsService as Utils } from './../../common/services/utils.service';
import { SharedProgrammingService } from '../../common/services/shared-programming.service';
import { Event } from '../../common/interfaces/event.interface';
import { Driver } from '../../common/interfaces/item.interface';
import { ProgrammingState } from '../../programming.stores';
import { BaseRoutingComponent } from '../../common/base-routing/base-routing.component';
import { SchedulesService } from '../../quickstarts/quickstart-schedule/schedule.service';
import { ScheduleEvent } from '../quickstart-schedule/+state/schedule.interfaces';
import { VoiceScenesService } from '../quickstart-voice-scenes/voice-scenes.service';

@Component({
  selector: 'prg-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.less']
})
export class EditComponent extends BaseRoutingComponent implements OnInit, OnDestroy {

  ready: Observable<boolean>;
  paths: Observable<Array<any>>;
  event: Observable<Event | ScheduleEvent>;
  driver: Observable<Driver>;
  testDisabled: Observable<boolean>;

  _message: string = '';
  _showAlertModal: boolean = false;
  _showConfirmDeleteAllActions = false;
  _showConfirmDeleteSchedule = false;
  _showAccessDeniedModal = false;
  _showConfirmDeleteVoiceScene = false;

  constructor(
    protected router: Router,
    protected shared: SharedProgrammingService,
    private schedules: SchedulesService,
    protected store: Store<ProgrammingState>,
    private _routerHistory: RouterHistoryService,
    private route: ActivatedRoute,
    private utils: Utils,
    private _voiceScenesService: VoiceScenesService
  ) {
    super();

    this.event = this.store.select(s => s.sharedProgramming.trigger.event);
    this.testDisabled = this.event.pipe(
      filter(event => !!event),
      map(event => !(!!event && !!event.codeItems && event.codeItems.length > 0))
    );

    this.driver = combineLatest(
      this.event,
      this.store.select(s => s.programmingItems.itemsList)
    ).pipe(
      filter(([event, items]) => !!event && !!items),
      map(([event, items]) => <Driver>items.find(item => item.id == event.deviceId))
    )

    this.ready = combineLatest(
      this.store.select(s => s.sharedProgramming.ready),
      this.store.select(s => s.scheduleProgramming.ready),
      this.store.select(s => s.sharedProgramming.codeItemVerifiers)
        .pipe(
          map(verifiers => verifiers.length === 0)
        ),
      (a, b, c) => (a && b && c)
    )
      .pipe(
        distinctUntilChanged()
      );
  }

  ngOnInit() {
    this.shared.clearError();
    // this.shared.setTitle('Back');
    this.shared.setEditing(true);
  }

  ngOnDestroy() {
  }

  showActions(state: boolean) {
  }

  showConfirmDeleteAllActions(state: boolean) {
    this._showConfirmDeleteAllActions = state
  }

  deleteAllActions() {
    this.showConfirmDeleteAllActions(false);
    this.shared.deleteAllActions();
  }

  showConfirmDeleteSchedule(state: boolean) {
    let event: Event = Utils.snapshot<Event>(this.event);
    if (!!event) {
      if (!event.hasProtected || (!!event.codeItems && event.codeItems.length === 0)) {
        this._showConfirmDeleteSchedule = state;
      } else {
        this._showAccessDeniedModal = true;
      }
    }
  }

  deleteSchedule() {
    this.showConfirmDeleteSchedule(false);
    let event = Utils.snapshot<Event>(this.event);
    this.schedules.deleteSchedule(event).then(res => {
      // NOTE can't do this in ngOnDestroy because we might be in the middle of an edit flow
      // when this component is destroy, so force exit from edit mode here
      // NOTE assume the user wants to go back to the schedule list
      this.goHome();
    }, err => {
      console.error('error deleteing schedule', err);
      this.shared.error(err);
    });
  }

  async showConfirmDeleteVoiceScene(state: boolean) {
    const event = await this.event.pipe(take(1)).toPromise();
    if (!!event) {
      if (!event.hasProtected || (!!event.codeItems && event.codeItems.length === 0)) {
        this._showConfirmDeleteVoiceScene = state;
      } else {
        this._showAccessDeniedModal = true;
      }
    }
  }

  async deleteVoiceScene() {
    // We're using a stubbed function
    // as a verifier since it won't be evaluated
    // when the scene is deleted
    const noop = () => false;
    this.shared.addCodeItemVerifier(noop);

    this.showConfirmDeleteVoiceScene(false);

    const event = await this.event.pipe(take(1)).toPromise();

    const sceneName = event.name
      .replace('Turn On ', '')
      .replace('Turn Off ', '');

    this.shared.ready(false);
    await this._voiceScenesService.deleteVoiceScene(sceneName);
    this.shared.ready(true);

    // We're manually removing this because there's
    // currently no way to verify that the
    // selected event has been entirely deleted
    this.shared.removeCodeItemVerifier(noop);

    this.goHome();
  }

  runTest() {
    this._message = 'The testing of this automation is in progress.';
    this._showAlertModal = true;
    this.shared.testEvent().then(res => {
      this._message = 'The testing of this automation is complete. Please verify the expected outcome.';
    }, err => {
      this._message = `An error occurred while testing this automation.
      Please double check the automation and try again.
      Contact your Control4 dealer if you continue to see this message.`;
    });;
  }

  close() {
    this.goHome();
  }
}
