import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';
import { NotificationProgrammingContext, QSNotificationsService } from '../services/notifications.service';
import { Announcement } from '../services/interfaces/announcement.interface';

@Component({
  selector: 'wt-select-announcement',
  templateUrl: './select-announcement.component.html',
  styleUrls: ['./select-announcement.component.less']
})
export class QSSelectAnnouncementComponent extends BaseRoutingComponent {

  public announcements: Observable<Announcement[]>;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected store: Store<{ notificationsProgramming: NotificationProgrammingContext }>,
    private _notifications: QSNotificationsService
  ) {
    super();

    this.announcements = this._notifications.announcements;
    this._notifications.setAgentType('announcement');
    this._notifications.getAnnouncements();
  }

  setAnnouncement(announcement: Announcement) {
    this._notifications.setSendAnnouncementCommand(announcement);
    this.router.navigate(['conditionals'], { relativeTo: this.route });
  }


}
