<prg-nav-header title="Select an action on the {{_thermostat | async | deviceInRoom}} to be performed when this automation is triggered."
  (cancel)="goToEvent()"></prg-nav-header>
<cxScrollable>
  <cxWait [cxUntil]="(commandsReady|async)">
    <ng-template [ngIf]="(_commands | async)?.length > 0">
      <prg-block-button *ngFor="let command of (_commands | async)" (click)="selectCommand(command)" leadingIcon="blue thermostat"
        title="{{ command.label || command.command }}" trailingIcon="right"></prg-block-button>
    </ng-template>

    <div class="alert" *ngIf="(commandsReady|async) && !(_commands | async)?.length">
      <header>
        <span class="icon information grey small"></span>
        <h5 class="grow">No Actions Found</h5>
      </header>
      <p>There are no actions available on this thermostat. Newly installed thermostats need to be added to the project
        and identified before certain actions become available. If this problem persists, please contact your dealer.</p>
    </div>
  </cxWait>
</cxScrollable>