import { Routes } from '@angular/router';

import { VSCreateComponent } from './create/create.component';
import { VSListComponent } from './list/list.component';

export const VOICE_SCENE_MANAGEMENT_ROUTES: Routes = [
];

export const VOICE_SCENE_AUTOMATION_ROUTES: Routes = [
  {
    path: 'voice-scene',
    children: [
      { path: 'list', component: VSListComponent },
      { path: 'new', component: VSCreateComponent }
    ]
  }
];
