import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment';

@Pipe({
  name: 'happeningAfter'
})
export class HappeningAfterPipe implements PipeTransform {

  transform(events: Array<any>, args?: any): any {
    if (events && args) {
      let days = parseInt(args);

      return events.filter(evt => {
        // console.log('checking event', evt);
        if (evt && evt.next_occurence) {
          let limit = moment().add(days, 'day');;

          let next = moment(new Date(
            evt.next_occurence.year,
            evt.next_occurence.month - 1,
            evt.next_occurence.day,
            evt.next_occurence.hour,
            evt.next_occurence.min, 0));

          // console.log('comparing scheduled programming with today', today, next);
          return (next.isAfter(limit, 'day'));
        }
      });
    }

    return events;
  }

}
