import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, distinctUntilChanged, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { SharedProgrammingService, CommonProgrammingContext } from './../../../common/services/shared-programming.service';
import { ProgrammingUtilsService as Util } from '../../../common/services/utils.service';
import { QuickstartProgrammingService } from '../../services/quickstart-programming.service';
import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';

import { LEDColorsService, LEDColorsProgrammingContext, HEX_COLOR_DEFAULT } from '../services/led-colors.service';

import { Device, CodeItemType } from '../../../common/interfaces/item.interface';
import { Command } from '../../../common/interfaces/command.interface';
import { Parameter } from '../../../common/interfaces/parameter.interface';

@Component({
  selector: 'prg-led-colors-select-action',
  templateUrl: './select-action.component.html',
  styleUrls: ['./select-action.component.less']
})
export class LEDColorsSelectActionComponent extends BaseRoutingComponent implements OnInit {
  device: Observable<Device>;
  ready: Observable<boolean>;

  private _commands: Observable<Command[]>;
  private _parameterCommands: Observable<Command[]>;
  private _keypadWithoutConfig: Observable<Command[]>;

  constructor(
    protected router: Router,
    private route: ActivatedRoute,
    private shared: SharedProgrammingService,
    protected store: Store<{
      ledColorsProgramming: LEDColorsProgrammingContext,
      sharedProgramming: CommonProgrammingContext
    }>,
    private ledColorsService: LEDColorsService,
    private utils: Util,
    private quickstarts: QuickstartProgrammingService
  ) {
    super();

    this.route.params.subscribe((params: { id: string }) => {
      this.ledColorsService.setActionLEDDevice(parseInt(params.id));
    });

    this.device = this.store.select(s => s.ledColorsProgramming.action.ledColorDevice);
    this.ready = this.shared.isReady;

    // ** HACK ALERT ** (truly icky) **
    // broker is sometimes producing two SET_BACKLIGHT_COLOR commands of the same type: one for the proxy, one for the protocol
    // for 2.10.x, allow only the SET_BACKLIGHT_COLOR for:
    // - light_v2: protocol id
    // - keypad_proxy, fan: proxy id
    this._commands = combineLatest(
      this.device.pipe(filter(d => !!d)),
      this.store.select(s => s.ledColorsProgramming.action.commands)
        .pipe(
          filter(c => !!c),
          distinctUntilChanged(Util.distinct),
          map((c: any) => c.map(this._setParameterDefaults))
        ),
      (device, commands) => commands.filter(c =>
        (c.command !== "SET_BACKLIGHT_COLOR") ||
        ((c.command === "SET_BACKLIGHT_COLOR") && (c.deviceId === device.protocolId) && (device.proxy === 'light_v2')) ||
        ((c.command === "SET_BACKLIGHT_COLOR") && (c.deviceId === device.id) && (['keypad_proxy', 'fan'].indexOf(device.proxy) > -1))
      ));

    // pull only commands with params including color
    this._parameterCommands = this._commands;

    // check for config keypad with no buttons configured
    this._keypadWithoutConfig = this._parameterCommands
      .pipe(
        map(c =>
          c.filter(c => (c.command === 'KEYPAD_BUTTON_COLOR') &&
            c.params.some(p => p.name === 'BUTTON_ID') &&
            c.params.some(p => !!p.values && p.values.length === 0)
          ))
      );
  }

  ngOnInit() {
  }

  private _setParameterDefaults = (command: Command): Command => {
    if (!!command.params) {
      command.params = command.params.map(p => {
        p._value = p._value || p.value || p.default || p.low || p.minimum || 0;
        p._display = (typeof p._value == 'string') ? p._value : p._value.toString();

        // set default color to white
        if (p && (p.type == "HEXCOLOR") && (p._value == 0)) {
          p._value = HEX_COLOR_DEFAULT;
          p._display = HEX_COLOR_DEFAULT;
        }
        return p;
      });
    }

    return command;
  }

  setCommand(command: Command, params?: Parameter[]) {
    // TODO determine if this is still necessary
    const allparams = (command.params || []).concat(params || []);
    this.ledColorsService.setActionCommand(command, allparams);

    command.params = allparams;
    const data = Util.buildCommandData(CodeItemType.Command, command);
    this.shared.setPendingAction(command, data);
    this.store.select(s => s.ledColorsProgramming.action.command)
      .pipe(
        filter(c => !!c && c.command === command.command),
        take(1)
      )
      .subscribe(data => {
        this.router.navigate(['conditionals'], { relativeTo: this.route });
      });
  }
}
