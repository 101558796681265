import { LoggerFactory } from './../../../../../../../libs/core/src/lib/services/log.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';


import { ProgrammingUtilsService as Utils } from './../../../common/services/utils.service';
import { LightsService } from './../services/lights.service';
import { Light } from '../services/interfaces/light.interface';
import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';
import { Store } from '@ngrx/store';

@Component({
  selector: 'prg-select-light',
  templateUrl: './select-light.component.html',
  styleUrls: ['./select-light.component.less']
})
export class SelectLightComponent extends BaseRoutingComponent {
  private _logger = LoggerFactory.getLogger(SelectLightComponent.name);
  _lights: Observable<Light[]>;

  @Input() rowClass: string;
  @Input() title: string;

  @Output() selected: EventEmitter<Light>;

  constructor(
    public route: ActivatedRoute,
    private lights: LightsService,
    protected store: Store<any>,
    protected router: Router
  ) {
    super();
    this._lights = this.lights.actionLights;
    this.rowClass = '';
    this.title = '';

    this.selected = new EventEmitter<Light>();
  }

  cancelClicked() {
    this._logger.debug('cancel clicked', this.route.snapshot.url.map(u => u.path).join('/'));
    if (this.isForTrigger(this.route)) {
      this.goHome();
    } else {
      this.goToEvent();
    }
  }
}
