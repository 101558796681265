import { EventType } from './event-type.interface';

export const REMOTE_BUTTON: EventType = {
  name: 'Button',
  description: 'When a system remote custom button is pressed in a room',
  route: 'buttons/remotes',
  category: 'Buttons',
  icon: 'remote',
  order: 2,
  whitelist: [
    {
      fields: { proxy: 'control4_sr260' },
      events: []
    }
  ]
}
