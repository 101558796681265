import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CONDITIONAL_ROUTES } from '../quickstart-flow-control/flow-control.routes';

import { QSMediaComponent } from './quickstart-media.component';
import { QSMediaSelectRoomComponent } from './select-room/select-room.component';
import { QSMediaSelectMediaComponent } from './select-media/select-media.component';
import { QSMediaSelectDeviceComponent } from './select-device/select-device.component';
import { QSMediaSelectRemoteComponent } from './select-remote/select-remote.component';
import { QSMediaSelectStreamingServiceComponent } from './streaming/select-service.component';
import { QSMediaSelectStreamingCategoryComponent } from './streaming/select-streaming-category.component';
import { QSMediaSelectStreamingMediaComponent } from './streaming/select-media.component';
import { QSMediaSelectStreamingRoomsComponent } from './streaming/select-streaming-rooms.component';

export const QUICKSTART_ACTIONS_MEDIA_ROUTES: Routes = [
  {
    path: 'media',
    component: QSMediaComponent,
    children: [
      {
        path: 'remotes',
        component: QSMediaSelectRemoteComponent
      },
      {
        path: 'remotes/conditionals',
        children: [...CONDITIONAL_ROUTES]
      },
      {
        path: 'streaming',
        component: QSMediaSelectStreamingServiceComponent
      },
      {
        path: 'streaming/:driverId',
        component: QSMediaSelectStreamingCategoryComponent
      },
      {
        path: 'streaming/:driverId/:category',
        component: QSMediaSelectStreamingMediaComponent
      },
      {
        path: 'streaming/:driverId/:category/:mediaItem',
        component: QSMediaSelectStreamingRoomsComponent
      },
      {
        path: 'streaming/:driverId/:category/:mediaItem/conditionals',
        children: [...CONDITIONAL_ROUTES]
      },
      {
        path: ':source',
        component: QSMediaSelectRoomComponent
      },
      {
        path: ':source/:roomId/media',
        component: QSMediaSelectMediaComponent
      },
      {
        path: ':source/:roomId/media/conditionals',
        children: [...CONDITIONAL_ROUTES]
      },
      {
        path: ':source/:roomId/device',
        component: QSMediaSelectDeviceComponent
      },
      {
        path: ':source/:roomId/device/conditionals',
        children: [...CONDITIONAL_ROUTES]
      }
    ]
  }
];
