import { Component, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { Device } from '../../../common/interfaces/item.interface';
import { SharedProgrammingService } from '../../../common/services/shared-programming.service';
import { ProgrammingUtilsService as Utils } from '../../../common/services/utils.service';
import { EventTypes } from '../event-types/event-types';
import { EventType } from '../event-types/event-type.interface';
import { SimpleProgrammingContext, SIMPLE_ACTIONS } from '../quickstart-simple.service';
import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';
import { Event } from '../../../common/interfaces/event.interface';
import { CommandType } from '../command-types/command-type.interface';

@Component({
  selector: 'qss-select-device',
  templateUrl: './select-device.component.html',
  styleUrls: ['./select-device.component.less']
})
export class QSSimpleSelectDeviceComponent extends BaseRoutingComponent implements AfterViewInit {

  _searchText: string;

  @Input() currentType: Observable<EventType | CommandType>;
  @Input() devices: Observable<Array<Device>>;
  @Input() ready: Observable<boolean>;
  @Input() rowClass: string;

  @Output() selected: EventEmitter<Device>;

  constructor(
    private _route: ActivatedRoute,
    protected store: Store<{
      simpleProgramming: SimpleProgrammingContext
    }>,
    protected router: Router,
    private _shared: SharedProgrammingService
  ) {
    super();
    this.selected = new EventEmitter<Device>();
  }

  cancelClicked() {
    if (this.isForTrigger(this._route)) {
      this.goHome();
    } else {
      this.goToEvent();
    }
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    console.log('afterviewinit: devices are', this.devices);
    if (!!this.devices) {
      this.devices
        .pipe(
          filter(devices => !!devices && devices.length == 1)
        )
        .subscribe(devices => {
          setTimeout(() => {
            this.selected.next(devices[0]);
          }, 100);
        });
    }
  }
}
