import { QuickstartProgrammingService } from './../../services/quickstart-programming.service';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { ProgrammingUtilsService } from '../../../common/services/utils.service';
import { CommonProgrammingContext } from '../../../common/services/shared-programming.service';
import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';

@Component({
  selector: 'prg-select-device-button',
  templateUrl: './select-device-button.component.html',
  styleUrls: ['./select-device-button.component.less']
})
export class SelectDeviceButtonComponent extends BaseRoutingComponent implements OnInit {

  @Input() device: any;
  @Input() buttons: Array<any>;
  @Input() iconClass: string;

  @Output() selected: EventEmitter<any>;

  constructor(
    protected router: Router,
    private utils: ProgrammingUtilsService,
    protected store: Store<{sharedProgramming: CommonProgrammingContext}>
  ) {
    super();
    this.selected = new EventEmitter<any>();
  }

  ngOnInit() {
    // must have trigger device in context
    if (!this.device) {
      console.warn('arrived at select device button with no device in context');
      // TODO do something smarter here
      this.router.navigate(['../programming'], {relativeTo: this.moduleRoot});
    }
  }
}
