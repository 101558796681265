import { Component, Input } from "@angular/core";
import { Parameter } from "../../../common/interfaces/parameter.interface";

@Component({
    selector: "prg-select",
    template: `<select [name]="param?.name" [(ngModel)]="param.value">
        <option *ngFor="let v of param?.values" [value]="v[param?.valueField]">{{v[param?.valueDisplay]}}</option>
    </select>`,
    styleUrls: ['./dynamic.less']
})
export class SelectComponent {
    @Input() type: string;
    @Input() param: Parameter;

    constructor() {
    }

    ngOnInit() {
        if (this.param.valueDisplay.charAt(0) === '{') {
            this.param.valueDisplay = this.param.valueDisplay.substring(1, this.param.valueDisplay.length - 1);
        }
    }
}
