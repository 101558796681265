import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, combineLatest, from } from 'rxjs';
import { filter, map, flatMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { BrokerService, AnalyticsService } from '@when-then/core';

import { Device } from '../../../common/interfaces/item.interface';
import { DeviceEvent } from '../../../common/interfaces/event.interface';
import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';
import { EventType } from '../event-types/event-type.interface';
import { EventTypes } from '../event-types/event-types';
import { BLINDS } from '../event-types/blinds.type';
import { SimpleProgrammingContext } from '../quickstart-simple.service';
import { ProgrammingUtilsService as Utils } from '../../../common/services';

@Component({
  templateUrl: './select-event.component.html',
  styleUrls: ['../select-device/select-device.component.less']
})
export class QSSimpleSelectEventComponent extends BaseRoutingComponent {

  _device: Observable<Device>;
  _events: Observable<DeviceEvent[]>;
  _eventType: Observable<EventType>;

  constructor(
    private _route: ActivatedRoute,
    protected store: Store<{
      simpleProgramming: SimpleProgrammingContext
    }>,
    public router: Router,
    private _broker: BrokerService
  ) {
    super();

    this._device = combineLatest(
      this._route.params.pipe(map((params: { id: string }) => parseInt(params.id))),
      this.store.select(s => s.simpleProgramming.devices).pipe(filter(d => !!d)),
      (id, devices) => devices.find(d => d.id == id)
    );

    this._eventType = this._device
      .pipe(
        filter(d => !!d),
        map(d => d.proxy),
        map(proxy => {
          return EventTypes.find(et => {
            return et.whitelist.map(w => w.fields['proxy']).indexOf(proxy) > -1;
          })
        })
      )

    this._events = this._device
      .pipe(
        filter(d => !!d),
        flatMap(device => {
          return from(this._broker.call({
            path: `/api/v1/items/${device.id}/events`,
            queryString: {
              allEvents: true
            }
          }))
            .pipe(
              map((events: DeviceEvent[]) => {
                return events.filter(event => {

                  let eventType = EventTypes.find(et => et.whitelist.map(w => w.fields['proxy']).indexOf(device.proxy) > -1);
                  let whitelist = eventType.whitelist.find(w => w.fields['proxy'] == device.proxy).events;

                  if (eventType.route == BLINDS.route) {
                    return whitelist.indexOf(event.eventId) > -1 && event.deviceId != device.protocolId;
                  }

                  return whitelist.indexOf(event.eventId) > -1;
                });
              })
            );
        })
      );
  }

  selectEvent(event: DeviceEvent) {
    this.router.navigate([['when-then', 'device', event.deviceId, 'event', event.eventId, 'add'].join('/')], { relativeTo: this.moduleRoot });
  }
}
