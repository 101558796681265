import { ConditionalType } from './conditional-type.interfaces';
import { AGENTS } from '../../../common/services/agent-meta.service';
import { Conditional } from '../../../common/interfaces/conditional.interface';

export const TIME_OF_DAY: ConditionalType = {
  name: 'Time of Day',
  description: 'At a certain time of day, or between certain hours',
  route: 'time-of-day',
  category: 'Scheduler',
  icon: 'intervals',
  deviceId: 100100,
  order: 0,
  whitelist: [
    {
      control: 'control4_agent_scheduler',
      conditionals: [
        {
          id: 1,
          name: 'IF_TIME:BETWEEN',
          label: "Between certain hours of the day",
          icon: 'intervals',
          view: 'BETWEEN:TIME',
          validate: (cond: Conditional): string[] => {
            const errors = [];
            const start = cond.params.find(p => p.name === 'OFFSET_FROM').value;
            const end = cond.params.find(p => p.name === 'OFFSET_TO').value;
            if (start === end) {
              errors.push('Start time must be different than end time.');
            }
            return errors;
          }
        },
        {
          id: 2,
          name: 'IF_TIME:SUNRISE',
          label: "Within a certain time relative to sunrise or sunset",
          icon: 'dawn',
          view: 'CUSTOM:SUNRISE',
          validate: (cond: Conditional): string[] => {
            const errors = [];
            if (cond.params.find(p => p.name === 'OFFSET_MINUTES').value === 0) {
              errors.push('Time interval relative to sunrise or sunset must be greater than zero.')
            }

            return errors;
          }
        },
        {
          id: 2,
          name: 'IF_TIME:DAY',
          label: "During the day",
          icon: 'dawn'
        },
        {
          id: 2,
          name: 'IF_TIME:NIGHT',
          label: "During the night",
          icon: 'dusk'
        }
      ]
    }
  ]
}
