<div class="parameter-input">

  <select
    id="timer-hours-input"
    name="timer-hours"
    class="{{ inputClass }}"
    [ngModel]="_valHours"
    (ngModelChange)="hoursChange($event)">
    <option *ngFor="let i of _hoursOptions"
      [ngValue]="i">{{ i }}</option>
  </select>
  <label for="timer-hours">Hours</label>

  <select
    id="timer-min-input"
    name="timer-min"
    class="{{ inputClass }}"
    [ngModel]="_valMinutes"
    (ngModelChange)="minutesChange($event)">
    <option *ngFor="let i of _minOptions"
      [ngValue]="i">{{ i }}</option>
  </select>
  <label for="timer-min">Minutes</label>

  <select
    id="timer-sec-input"
    name="timer-sec"
    class="{{ inputClass }}"
    [ngModel]="_valSeconds"
    (ngModelChange)="secondsChange($event)">
    <option *ngFor="let i of _secOptions"
      [ngValue]="i">{{ i }}</option>
  </select>
  <label for="timer-sec">Seconds</label>
  
</div>
