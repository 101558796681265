<div [ngClass]="buttonClass" class="clickable block-button">
  <span class="icon small leading" [ngClass]="leadingIcon" *ngIf="leadingIcon"></span>
  <div class="grow label">
    <div class="title" [ngClass]="titleClass">
      <span class="grow word-wrap text">{{title}}</span>
      <ng-content select="[supportingContent]"></ng-content>
    </div>
    <div class="subtitle" *ngIf="subtitle" [ngClass]="subtitleClass">{{subtitle}}</div>
  </div>
  <ng-content select="[badge]"></ng-content>
  <span class="icon small grey trailing" [ngClass]="trailingIcon" *ngIf="trailingIcon"></span>
</div>