<div class="select-device">
  <section class="filter">
    <label for="floorSel" class="inline-label">Floor</label>
    <select name="floorSel" id="floorSel" class="transparent" (change)="setFloor($event)">
      <option value="any">Any Floor</option>
      <option [value]="floor.id" *ngFor="let floor of (allFloors | async | sortBy:'name')">{{floor.name}}</option>
    </select>
    <label for="roomSel" class="inline-label">Room</label>
    <select name="roomSel" id="roomSel" class="transparent" (change)="setRoom($event)">
      <option value="any">Any Room</option>
      <option [value]="room.id" *ngFor="let room of (allRooms | async | matchingFloor:(_currentFloor | async) | sortBy:'name')">{{room.name}}</option>
    </select>
    <span class="spacer"></span>
    <div class="search">
      <span class="icon blue search small"></span>
      <input type="search" placeholder="Search" [(ngModel)]="_searchText">
    </div>
  </section>
  <div [cxWait]="(ready | async)">
    <div *ngIf="(ready | async) && (source | matchingFloor:(_currentFloor | async) | matchingRoom:(_currentRoom | async) | matchingNameOrLocation:_searchText)?.length === 0">
      <header>
        <span class="icon grey information small"></span>
        <h6 class="grow">No Devices Found</h6>
      </header>
      <p class="help-text">There are no devices in the selected location or that match the search text. Try a different
        location or search terms.</p>
    </div>
    <cxScrollable>
      <prg-block-button [buttonClass]="rowClass" *ngFor="let dev of (source | matchingFloor:(_currentFloor | async) | matchingRoom:(_currentRoom | async) | matchingNameOrLocation:_searchText | sortBy:'name')"
        (click)="selected.next(dev)" leadingIcon="blue {{dev | icon}}" title="{{dev | deviceName}}" subtitle="{{dev | deviceLocation}}"
        trailingIcon="right"></prg-block-button>
    </cxScrollable>
  </div>
</div>
