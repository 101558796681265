import {Pipe, PipeTransform} from '@angular/core';
import {Store} from '@ngrx/store';

@Pipe({
  name: 'hasProxy'
})
export class HasProxyPipe implements PipeTransform {

  constructor() { }

  transform(items: any, proxy: any): Array<any> {
    if (proxy) {
      return items.filter(item => {
        return (proxy.name === item.proxy || this.hasProxy(item, proxy))
      });
    } else {
      return items;
    }
  }

  private hasProxy(item: any, proxy: any): boolean {
    let found = false;

    if (item.proxyMeta) {
      item.proxyMeta.forEach(pm => {
        if (pm.name === proxy.name) {
          found = true;
        }
      });
    }

    return found;
  }
}
