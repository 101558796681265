import { Store } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { ItemsService } from './../../../common/services/items.service';
import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';
import { SharedProgrammingService } from './../../../common/services/shared-programming.service';
import { QuickstartRemotesService } from '../quickstart-remotes.service';
import { Room, Location, Item } from './../../../common/interfaces/item.interface';

@Component({
  selector: 'prg-select-room',
  templateUrl: './select-room.component.html',
  styleUrls: ['./select-room.component.less']
})
export class SelectRoomComponent extends BaseRoutingComponent implements OnInit {

  allRooms: Observable<Item[]>

  constructor(
    protected router: Router,
    protected store: Store<any>,
    private shared: SharedProgrammingService,
    private remotes: QuickstartRemotesService,
    private items: ItemsService,
    private route: ActivatedRoute
  ) {
    super();
    this.allRooms = this.items.itemsList
      .pipe(
        filter(i => !!i),
        map(items => items.filter(itm => itm.type === 8))
      );
  }

  ngOnInit() {
    this.shared.clearError();
  }

  roomSelected(room: Location) {
    this.remotes.setSelectedRoom(room);
    this.router.navigate(['../button'], { relativeTo: this.route });
  }
}
