import { Pipe, PipeTransform } from '@angular/core';
import { Location } from './../interfaces/item.interface';
import { Device } from '../interfaces/item.interface';

@Pipe({
  name: 'matchingFloor'
})
export class MatchingFloorPipe implements PipeTransform {

  transform(devices: Device[], args?: any): any {
    if (args !== 'any') {
      let floor: Location = args;
      // console.log('matching floor:', floor);
      if (devices && floor) {
        return devices.filter(dev => { return dev.floorId === floor.floorId });
      }
    }

    // console.log('matchingfloor: returning ', devices);
    return devices;
  }
}

