import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { map, filter, tap, take } from 'rxjs/operators';

import { LEDColorsService, LEDColorsProgrammingContext, HEX_COLOR_DEFAULT } from '../services/led-colors.service';
import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';
import { ProgrammingUtilsService as Utils } from '../../../common/services/utils.service';
import { CommonProgrammingContext } from '../../../common/services/shared-programming.service';

import { Parameter } from '../../../common/interfaces/parameter.interface';
import { Device } from '../../../common/interfaces/item.interface';
import { Command } from '../../../common/interfaces/command.interface';

@Component({
  selector: 'prg-led-colors-select-parameters',
  templateUrl: './select-parameters.component.html',
  styleUrls: ['./select-parameters.component.less']
})
export class LEDColorsSelectParametersComponent extends BaseRoutingComponent {

  _command: Observable<Command>;
  _device: Observable<Device>;
  _params: Observable<Parameter[]>;
  _displayParams: Observable<Parameter[]>;
  _routeParams: Observable<{ command: string, id: string, deviceId: string }>;

  _snapshot = Utils.snapshot;

  constructor(
    public router: Router,
    protected store: Store<{
      ledColorsProgramming: LEDColorsProgrammingContext,
      sharedProgramming: CommonProgrammingContext
    }>,
    private route: ActivatedRoute,
    public ledColorsService: LEDColorsService,
    private utils: Utils
  ) {
    super();
    this._routeParams = <Observable<{ command: string, id: string, deviceId: string }>>this.route.params;

    this._routeParams
      .pipe(
        map(p => parseInt(p.deviceId))
      )
      .subscribe(id => this.ledColorsService.setActionLEDDevice(id));

    this._device = this.store.select(s => s.ledColorsProgramming.action.ledColorDevice);
    this._command = combineLatest(
      this._routeParams,
      this.store.select(s => s.ledColorsProgramming.action).pipe(filter(l => !!l && !!l.commands)),
      (routeParams, action) => {
        let id = parseInt(routeParams.id) || undefined;
        return action.commands.find(c => c.command === routeParams.command && c.id === id);
      }
    );

    // all params
    this._params = this._command.pipe(map(c => c.params));

    // hide params marked hidden
    this._displayParams = this._command
      .pipe(
        map(c => c.params),
        map(p => p.filter(p => p.type !== 'HIDDEN')),
        tap(p => {
          p.forEach(p => {
            // set the default Button Id to first in the list of choices
            // and set display to show the button name not the Id
            if (p && p.values && (p.values.length > 0) &&
              (p.type == "LIST") && (p.name == "BUTTON_ID") && ((p._value == 0) || (p._value === undefined))) {
              p._value = p.values[0].id;
              p._display = p.values[0].name;
            }
          });
        })
      );
  }

  setCommand = () => {
    let command = Utils.snapshot<Command>(this._command);
    let params = Utils.snapshot<Parameter[]>(this._params);
    this.ledColorsService.setActionCommand(command, params);
    this.store.select(s => s.ledColorsProgramming.action.command)
      .pipe(
        filter(c => !!c && c.command === command.command),
        take(1)
      )
      .subscribe(data => {
        this.router.navigate(['conditionals'], { relativeTo: this.route });
      });
  }

  identify = (param: Parameter) => param.name + param.type;
}
