import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ContainerDirective } from './directives/container/container.directive';
import { C4Toolbar } from './directives/toolbar/toolbar';
import { ToolbarService, toolbarReducer } from './directives/toolbar/toolbar.service';
import { ScrollableDirective } from './directives/scrollable/scrollable.directive';
import { WaitUntilDirective } from './directives/wait/waitUntil.directive';
import { SpinnerDirective } from './directives/wait/spinner.directive';
import { GaugeComponent } from './directives/gauge/gauge.component';

import { C4Form } from './directives/input/c4-form';
import { C4OptionService } from './directives/input/c4-option-service';
import { C4InputCheckbox } from './directives/input/checkbox/c4-input-checkbox';
import { C4InputColor } from './directives/input/color/c4-input-color';
import { C4InputDate } from './directives/input/date/c4-input-date';
import { C4InputDatetime } from './directives/input/datetime/c4-input-datetime';
import { C4InputEmail } from './directives/input/email/c4-input-email';
import { C4InputFactory } from './directives/input/factories/c4-input-factory';
import { C4InputFloat } from './directives/input/float/c4-input-float';
import { C4InputInteger } from './directives/input/integer/c4-input-integer';
import { C4InputList } from './directives/input/list/c4-input-list';
import { C4InputMonth } from './directives/input/month/c4-input-month';
import { C4InputPassword } from './directives/input/password/c4-input-password';
import { C4InputRadio } from './directives/input/radio/c4-input-radio';
import { C4InputSelect } from './directives/input/select/c4-input-select';
import { C4InputSliderFloat } from './directives/input/slider-float/c4-input-slider-float';
import { C4InputSliderInteger } from './directives/input/slider-integer/c4-input-slider-integer';
import { C4InputTel } from './directives/input/tel/c4-input-tel';
import { C4InputText } from './directives/input/text/c4-input-text';
import { C4InputTime } from './directives/input/time/c4-input-time';
import { C4InputUrl } from './directives/input/url/c4-input-url';
import { C4InputWeek } from './directives/input/week/c4-input-week';
import { C4NoDoubleClickDirective } from './directives/no-double-click/no-double-click.directive';

let exportedDirectives = [
  ContainerDirective,
  C4Toolbar,
  ScrollableDirective,
  WaitUntilDirective,
  SpinnerDirective,
  GaugeComponent,
  C4Form,
  C4InputCheckbox,
  C4InputColor,
  C4InputDate,
  C4InputDatetime,
  C4InputEmail,
  C4InputFactory,
  C4InputFloat,
  C4InputInteger,
  C4InputList,
  C4InputMonth,
  C4InputPassword,
  C4InputRadio,
  C4InputSelect,
  C4InputTel,
  C4InputText,
  C4InputTime,
  C4InputUrl,
  C4InputWeek,
  C4InputSliderFloat,
  C4InputSliderInteger,
  C4NoDoubleClickDirective,
];

export const UI_REDUCERS = { toolbar: toolbarReducer };

@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  declarations: [
    ...exportedDirectives
  ],
  exports: [
    ...exportedDirectives
  ],
  providers: [
    ToolbarService,
    C4OptionService
  ]
})
export class UIModule { }
