/**
 * Copyright 2017 Control4 Corporation. All rights reserved.
 * Control4 Confidential and Proprietary Information.
 */
import { Component, enableProdMode } from '@angular/core';

import { Store } from '@ngrx/store';

import { BackupService, UrlFactoryService, BrokerService, PerformanceService, CommonConfigService, CONNECTION_STATUS, MonitoredHttpState } from '@when-then/core';
import { AuthenticationService, IdentityService } from '@when-then/auth';
import { Observable, Subscription, combineLatest, timer } from 'rxjs';
import { filter, debounce, map, take } from 'rxjs/operators';

import { AuthenticationState } from '@when-then/auth';
import { BackupContext, StorageService, AnalyticsService } from '@when-then/core';

import { environment } from '../environments/environment';
import { ToolbarService } from '@when-then/ui';
import { RouterEvent, Router, GuardsCheckEnd } from '@angular/router';

@Component({
  selector: 'c4-container',
  templateUrl: './dev.component.html',
  styleUrls: ['./dev.component.less']
})
export class DevComponent {

  checked: Observable<boolean>;
  guardsChecked: Observable<boolean>;
  ready: Observable<boolean>;
  authenticated: Observable<boolean>;

  subscriptions: Subscription[] = [];

  connection: Observable<CONNECTION_STATUS>;
  debouncedConnection: Observable<CONNECTION_STATUS>;

  CONNECTION_STATUS = CONNECTION_STATUS;
  rebooting: Observable<boolean>;

  constructor(
    private store: Store<{
      backup: BackupContext, authentication: AuthenticationState,
      router: {
        path: string
      },
      monitoredHttpState: MonitoredHttpState
    }>,
    private router: Router,
    private backup: BackupService,
    private storage: StorageService,
    private commonConfig: CommonConfigService,
    private perf: PerformanceService,
    private authenticationService: AuthenticationService,
    private analytics: AnalyticsService
  ) {
    // this.perf.mark('mark_root_component_ctor', 'indexhtml_to_app_constructor', 'mark_indexhtml_loaded');
    this.commonConfig.loadConfig(environment);

    // NOTE when the app is started from scratch, clear the previous
    // temporary "skip welcome" state so the user is redirected to the welcome
    // page again (unless they've opted out)
    this.storage.remove('proceedWithCaution');

    this.ready = this.store.select(state => state.authentication.initialized);
    this.authenticated = this.store.select(state => state.authentication.authenticated);

    this.connection = this.store.select(state => state.monitoredHttpState)
      .pipe(
        filter(s => !!s),
        map(s => s.connectionStatus)
      );

    this.rebooting = combineLatest(
      this.connection,
      this.store.select(s => s.monitoredHttpState.rebootRequested),
      (connection, rebootRequested) => connection === CONNECTION_STATUS.DISCONNECTED && rebootRequested
    );

    this.guardsChecked = this.router.events
      .pipe(
        filter(evt => evt instanceof GuardsCheckEnd),
        take(1),
        map(evt => true)
      );

    // set initial state and establish a watcher for our online status
    this.storage.set('isOnline', navigator.onLine);
    window.document.addEventListener('onLine', (event: any) => {
      this.storage.set('isOnline', navigator.onLine);
    });

    // Introducing a delay here to deal
    // with reflector flakiness.
    this.debouncedConnection = this.connection
      .pipe(
        debounce(status => {
          let interval = (status === CONNECTION_STATUS.DISCONNECTED) ? 5000 : 0;
          return timer(interval);
        })
      );
  }

  ngAfterViewInit() {
    // this.perf.mark('mark_root_component_init', 'root_component_ctor_to_init', 'mark_root_component_ctor')
  }

  // logState = () => {
  //   this.store.select(s => s).take(1).subscribe(state => console.log('STATE: ', state));
  // }

  // logout = () => {
  //   this.authenticationService.unauthenticate();
  // }
}
