import { Injectable } from '@angular/core';

import { BaseService } from './base.service';

import { BrokerService } from './broker.service';

@Injectable()
export class UpdateService extends BaseService {

  constructor(private broker: BrokerService) {
    super();
  }

  getUpdateVersions(updateURL: string): Promise<any> {
    var body = {
      url: updateURL
    };

    // TODO is a body on a GET legal?   does it get url encoded instead?
    return this.broker.call({
      path: '/api/v1/agents/update_manager/versions_available',
      data: body
    });
  }

  updateToVersion(url: string, version: string, email: string): Promise<any> {
    var body = {
      url: url,
      version: version,
      email: email
    };

    return this.broker.call({
      method: 'POST',
      path: '/api/v1/agents/update_manager/versions_available',
      data: body
    });
  }

}
