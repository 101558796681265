import { EventType } from './event-type.interface';

export const DOORSTATION_BUTTON: EventType = {
  name: 'Doorstation',
  description: 'When a doorstation button is pressed',
  route: 'buttons/doorstation',
  category: 'Buttons',
  icon: 'touch-press',
  order: 3,
  whitelist: [
    // NOTE order is significant here, most specific field set first
    {
      fields: { control: 'doorstation', protocolControl: 'control4_doorstation' },
      events: [0, 1, 19, 20, 21, 22]
    },
    {
      fields: { proxy: 'doorstation' },
      events: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]
    }
  ]
}
