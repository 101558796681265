import { EventType } from './event-type.interface';

export const PRESSURE: EventType = {
  name: 'Pressure',
  description: 'When a pressure sensor is triggered',
  route: 'pressure',
  category: 'Sensors',
  icon: 'pressure',
  whitelist: [
    {
      fields: { proxy: 'contactsingle_pressuresensor_c4' },
      events: [1, 2]
    }
  ]
}
