<prg-nav-header title="Select {{ (currentType|async)?.name }}" (cancel)="cancelClicked()">
  <div right>
    <div class="search">
      <span class="icon blue search small"></span>
      <input type="search" [(ngModel)]="_searchText" name="searchText" placeholder="Search">
    </div>
  </div>
</prg-nav-header>
<cxScrollable>
  <cxWait [cxUntil]="ready|async">
    <prg-block-button [buttonClass]="rowClass" *ngFor="let device of (devices|async) | matchingSearchText:_searchText" (click)="selected.next(device)"
      leadingIcon="blue {{device | icon}}" trailingIcon="right" title="{{device | deviceName}}" subtitle="{{device | deviceLocation}}"></prg-block-button>
  </cxWait>
</cxScrollable>
