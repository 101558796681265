import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment';

import { ProgrammingUtilsService as Utils } from './../services/utils.service';
import { ScheduleEvent } from '../../quickstarts/quickstart-schedule/+state/schedule.interfaces';

@Pipe({
  name: 'sortSchedules'
})
export class SortSchedulesPipe implements PipeTransform {

  constructor() { }

  transform(events: any, order?: any): any {
    // console.log('pipe: sortSchedule', events, order);
    order = order || 'asc';
    if (events) {
      return events.sort((a: ScheduleEvent, b: ScheduleEvent) => {
        let _a: moment.Moment = Utils.toMoment(a.next_occurence.year, a.next_occurence.month, a.next_occurence.day, ((a.next_occurence.hour * 60) + a.next_occurence.min));
        let _b: moment.Moment = Utils.toMoment(b.next_occurence.year, b.next_occurence.month, b.next_occurence.day, ((b.next_occurence.hour * 60) + b.next_occurence.min));
        if (_a < _b) {
          return (order === 'asc') ? -1 : 1;
        } else if (_a > _b) {
          return (order === 'asc') ? 1 : -1;
        } else {
          return 0;
        }
      });
    } else {
      return events;
    }
  }

}
