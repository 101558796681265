import {Pipe, PipeTransform} from '@angular/core';
import {Store} from '@ngrx/store';

@Pipe({
  name: 'locationEvents'
})
export class LocationEventFilterPipe implements PipeTransform {

  constructor(
    private store: Store<any>
  ) { }

  transform(events: any): Array<any> {
    // TODO filter the list of events to those found on devices in the selected location (site+building+floor)
    return events;
  }
}
