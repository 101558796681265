import { HttpHeaders, HttpParams } from "@angular/common/http";

export enum HttpMethods {
    GET = 'GET',
    POST = 'POST',
    PATCH = 'PATCH',
    DELETE = 'DELETE',
    PUT = 'PUT',
    HEAD = 'HEAD',
    JSONP = 'JSONP',
    OPTIONS = 'OPTIONS'
  }

export interface HttpOptions {
  body?: any;
  headers?: HttpHeaders | {
      [header: string]: string | string[];
  };
  observe?: 'body' | 'events';
  params?: HttpParams | {
      [param: string]: string | string[];
  };
  reportProgress?: boolean;
  responseType: 'json' | 'text' | 'blob' | 'arraybuffer';
  withCredentials?: boolean;
}
  