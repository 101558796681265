import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'itemDisplay'
})
export class ListItemDisplayPipe implements PipeTransform {
  transform(value: string): any {
    let display = value;
    if (arguments.length > 1) {

      let roomName = arguments[1];
      if (roomName) {
        display = (roomName + ' ' + String.fromCharCode(8250) + ' ' + display);
      }
    }

    return display;
  }
}
