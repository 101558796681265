<prg-nav-header title="{{prettyEvents[eventId] || '' + prettyCommands[command] || ''}}" (cancel)="cancelClicked()"></prg-nav-header>
<!-- <header class="bordered">
  <a onclick="window.history.back()" class="back"><span class="icon blue x-small left"></span> Back</a>
  <h6
    *ngIf="eventId != undefined"
    class="grow"
  >{{ prettyEvents[eventId] }}</h6>
  <h6
    *ngIf="command != undefined"
    class="grow"
  >{{ prettyCommands[command] }}</h6>
  <button class="default clickable" (click)="cancelClicked()">Cancel</button>
</header> -->
<cxScrollable>
  <prg-block-button [buttonClass]="{'dark': _isTrigger()}" *ngFor="let room of (_rooms|async)" (click)="select(room.id)"
    leadingIcon="blue room" title="{{room.name}}" subtitle="{{room.floorName}}" trailingIcon="right"></prg-block-button>
</cxScrollable>