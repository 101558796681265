import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'matchingNameOrLocation'
})
export class MatchingNameOrLocationPipe implements PipeTransform {

  transform(values: any[], args?: any): any {
    if (args) {
      let text = args.toLowerCase();

      return values.filter((value: any) => {
        let name = (value.name) ? value.name.toLowerCase() : '';
        let location = (value.roomName) ? (value.roomName.toLowerCase() + value.floorName.toLowerCase()) : '';
        // console.log('name, location, search', name, location, text);
        return (name && location && (name.indexOf(text) >= 0 || location.indexOf(text) >= 0));
      });
    }

    return values;
  }


  private realName(value: any): string {
    return value.name || '';
  }
}
