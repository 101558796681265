import {Pipe, PipeTransform} from '@angular/core';
import {Store} from '@ngrx/store';

@Pipe({
  name: 'selectDevices'
})
export class SelectDevicesFilterPipe implements PipeTransform {

  constructor(
    private store: Store<any>
  ) { }

  transform(items: Array<any>): Array<any> {
    if (items) {
      return items.filter(item => {
        return ((item.type >= 6 && item.type <= 7) || item.type > 8);
      });
    } else {
      return [];
    }
  }
}
