import { Subscription, timer } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as moment from 'moment';

import { BrokerService, Logger, LoggerFactory } from '@when-then/core';

import { ScheduleEvent, Schedules, ScheduleViewModel } from './+state/schedule.interfaces';
import {
  SetScheduleEvents, ClearScheduleEvent, SetSchedulesReady, SetSelectedSchedule, SetWaitMessage
} from './+state/schedule.actions';
import { DEFAULT_SCHEDULE_VIEW_MODEL } from './+state/schedule.init';
import { ProgrammingUtilsService as Utils, CW_CREATOR_ID, TAG_QSP } from '../../common/services/utils.service';
import { Event } from '../../common/interfaces/event.interface';
import { CodeItem } from '../../common/interfaces/code-item.interface';
import { AGENTS } from '../../common/services/agent-meta.service';
import { ScheduleEventLabelPipe } from '../../common/pipes/schedule-event-label.pipe';
import { CommonProgrammingContext } from '../../common/services/shared-programming.service';

const BLANK_EVENT: ScheduleEvent = {
  eventId: 0,
  deviceId: 100100,
  buttonbindingid: 0,
  creatorId: 0,
  creatorState: '',
  hidden: false,
  display: '',
  start: {
    offset: 0,
    offset_minutes: 0,
    start_date: {
      start_year: new Date().getFullYear(),
      start_month: new Date().getMonth(),
      start_day: new Date().getDate(),
      start_period: 1,
      start_weekday: 1
    },
    randomize: 0
  },
  repeat: {
    rate: 1,
    frequency: 1,
    daymask: 0,
    end_date: {
      end_year: new Date().getFullYear(),
      end_month: new Date().getMonth(),
      end_day: new Date().getDate()
    }
  }
};

// Events we don't want to expose to the user
const HIDDEN_EVENTS = [
  AGENTS.DRIVER_UPDATE.ID,
  AGENTS.UPDATE_MANAGER.ID
];

@Injectable()
export class SchedulesService implements OnDestroy {
  private _eventsSub: Subscription;
  private _scheduleSub: Subscription;
  private _logger: Logger;

  constructor(
    private broker: BrokerService,
    private store: Store<{
      sharedProgramming: CommonProgrammingContext,
      scheduleProgramming: Schedules
    }>,
    private scheduleLabel: ScheduleEventLabelPipe
  ) {
    this._logger = LoggerFactory.getLogger(SchedulesService.name);
    // this._logger.setLevel(LogLevel.DEBUG);

    this.broker.connected.pipe(filter(c => !!c)).subscribe(c => {
      this._eventsSub = this.broker.getObservable<Array<ScheduleEvent>>({
        path: '/api/v1/agents/scheduler'
      }).subscribe(events => this._handleUpdates(events));
    });
  }

  ngOnDestroy() {
    this.clearSchedule();
    if (!!this._eventsSub) {
      this._eventsSub.unsubscribe();
    }
  }

  async getSchedule(id: number) {
    this._logger.debug('getschedule', id);
    await this.clearSchedule();
    this._watchSchedule(id);
  }

  saveAction(action: any): Promise<any> {
    this._logger.debug('saveaction', action);
    return new Promise((resolve, reject) => {
      const event = Utils.snapshot<ScheduleEvent>(this.store.select(s => s.scheduleProgramming.selectedSchedule));
      this._logger.debug('schedule: saveaction: ', event, action);
      if (!!event && event.deviceId > 0 && event.eventId > 0 && !!action) {
        this._ready(false);
        action.creatorState = Utils.buildCreatorState(event, action);
        this.broker.call({
          method: 'POST',
          path: '/api/v1/agents/scheduler/' + event.eventId + '/commands',
          data: action
        }).then(result => {
          const updated = this._fixupSaveScheduleResponse(result);
          this._ready(true);
          resolve(updated);
        }, err => {
          this._logger.error('schedules.service: error saving action', err);
          this._ready(true);
          reject(err);
        });
      } else {
        this._logger.warn('invalid state when saving action for schedule', event, action);
      }
    });
  }

  /**
   * HACK when saving a new schedule event and associated action at the same time
   * the response represents the code item as an object, but our code item change
   * detection depends on it being an element in an array so fix that here
   * @param res ScheduleEvent
   */
  private _fixupSaveScheduleResponse(res: any): ScheduleEvent {
    if (!!res.codeItem) {
      res.codeItems = [res.codeItem];
      delete res.codeItem;
    }

    return res;
  }

  deleteAction(event: any, action: any): Promise<any> {
    this._logger.debug('deleteaction', event, action);
    return new Promise<any>((resolve, reject) => {
      const state = JSON.parse(action.creatorState || '{}');
      const canDelete = (action.creator === CW_CREATOR_ID) || (!!state.tags && state.tags.indexOf(TAG_QSP) >= 0);

      // this._logger.debug('sched: can delete action', canDelete);
      if (canDelete) {
        this.broker.call({
          method: 'DELETE',
          path: '/api/v1/agents/scheduler/' + event.eventId + '/' + action.codeItemId
        }).then(res => {
          resolve(res);
        }, err => {
          reject(err);
        });
      } else {
        this._logger.error('schedules.service: cannot delete action, protected');
        const err = {
          message: `Cannot delete this action because it is protected.
                    Please contact your Control4 dealer for assistance.` };
        reject(err);
      }
    });
  }

  updateSchedule(vm: ScheduleViewModel): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this._logger.debug('updateschedule', vm);
      this._ready(false);

      const event = this._buildScheduleEvent(vm);

      let path = '/api/v1/agents/scheduler';
      if (event.eventId) {
        path += ('/' + event.eventId);
      }

      this.broker.call({
        method: 'POST',
        path: path,
        data: event
      }).then(updated => {
        this._ready(true);
        resolve(updated);
      }, err => {
        this._ready(true);
        this._logger.error('error saving schedule', err);
        reject(err);
      });
    });
  }

  deleteSchedule(event: Event): Promise<any> {
    this._logger.debug('deleteschedule', event);
    this._ready(false);
    this.store.dispatch(new SetWaitMessage('Deleting schedule.'));
    return new Promise((resolve, reject) => {
      try {
        if (event && event.deviceId === 100100 && event.eventId > 0) {
          // NOTE canDelete returns true by default
          const canDelete: boolean = this._canDelete(event.codeItems);
          // this._logger.debug('sched: can delete schedule', canDelete);
          if (canDelete) {
            this.broker.call({
              path: '/api/v1/agents/scheduler/' + event.eventId,
              method: 'DELETE'
            }).then(res => {
              this.store.dispatch(new ClearScheduleEvent());
              this.clearSchedule();
              this._ready(true);
              resolve();
            }, err => {
              this._ready(true);
              reject({ message: 'error deleting schedule', err: err });
            });
          } else {
            this._logger.error('schedules.service: cannot delete action, protected');
            const err = { message: 'cannot delete a protected action' };
            reject(err);
          }
        } else {
          this._logger.error('schedules.service: invalid schedule given in delete context', event);
          const err = { message: 'invalid schedule given for delete' };
          reject(err);
        }
      } catch (e) {
        this._logger.error('schedules.service: error caught in delete schedule', e);
        const err = { message: 'An error occurred while deleting the schedule.' };
      } finally {
        this._ready(true);
        this.store.dispatch(new SetWaitMessage(''));
      }
    });
  }

  async clearSchedule(): Promise<any> {
    await this._unwatchSchedule();
    return new Promise((resolve, reject) => {
      this._logger.debug('clearing schedule from store');
      this.store.dispatch(new ClearScheduleEvent());
      // HACK wait until the selected schedule in the store has been cleared
      this.store.select(s => s.scheduleProgramming.selectedSchedule)
        .pipe(
          filter(s => !s),
          take(1)
        )
        .subscribe(s => {
          this._logger.debug('selected schedule cleared');
          resolve();
        }, err => {
          this._logger.error('error clearing selected schedule', err);
          reject(err);
        });
    })
  }

  private _buildScheduleEvent(vm: ScheduleViewModel): ScheduleEvent {
    this._logger.debug('buildscheduleevent', vm);
    const event: ScheduleEvent = Object.assign({}, BLANK_EVENT);

    event.display = vm.name;
    event.eventId = vm.eventId || 0;

    // repeat
    if (vm.repeatType === 'every') {
      event.repeat.rate = vm.repeatRate;
      event.repeat.frequency = vm.repeatFrequency;
      event.repeat.daymask = vm.repeatDays;

      if (vm.hasEndDate) {
        const m = moment(vm.endDate);
        event.repeat.end_date = {
          end_year: m.year(),
          end_month: m.month() + 1,
          end_day: m.date()
        };
      } else {
        delete event.repeat.end_date;
      }
    } else {
      delete event.repeat;
    }

    // start time
    if (vm.startTimeType === 'fixed') {
      // this._logger.debug('building event with fixed time');
      event.start.offset = 0;
      event.start.offset_minutes = this._startOffsetMinutesFromContext(vm.startTime);
    } else {
      // this._logger.debug('building event with sunrise/sunset time');
      if (vm.sunriseSunsetOffsetType === 'after') {
        event.start.offset_minutes = vm.sunriseSunsetInterval;
      } else if (vm.sunriseSunsetOffsetType === 'before') {
        event.start.offset_minutes = -(vm.sunriseSunsetInterval);
      } else {
        event.start.offset_minutes = 0;
      }

      if (vm.sunriseOrSunset === 'sunrise') {
        event.start.offset = 1;
      } else if (vm.sunriseOrSunset === 'sunset') {
        event.start.offset = 2;
      }
    }

    // start date
    if (vm.startDateType === 'fixed') {
      const m = moment(vm.startDate);
      event.start.start_date.start_year = m.year();
      event.start.start_date.start_month = m.month() + 1;
      event.start.start_date.start_day = m.date();
      delete event.start.start_date.start_period;
      delete event.start.start_date.start_weekday;
    } else {
      event.start.start_date.start_year = vm.startYear;
      event.start.start_date.start_month = vm.startMonth;
      event.start.start_date.start_weekday = vm.startDayOfWeek;
      event.start.start_date.start_period = vm.startWeekOffset;
      delete event.start.start_date.start_day;
    }

    // compose a subtitle representing the start time
    event.subtitle = this.scheduleLabel.transform(event);
    this._logger.debug('returning schedule event', event);
    return event;
  }

  parseSchedule(event: ScheduleEvent): ScheduleViewModel {
    const vm: ScheduleViewModel = { ...DEFAULT_SCHEDULE_VIEW_MODEL };

    if (!!event) {
      vm.eventId = event.eventId;
      vm.name = event.display;

      // start time
      if (!!event.start) {
        if (event.start.offset > 0) {
          vm.startTimeType = 'sol';
          const minutes = Math.abs(event.start.offset_minutes);
          vm.sunriseSunsetInterval = minutes;

          if (event.start.offset_minutes > 0) {
            vm.sunriseSunsetOffsetType = 'after';
          } else if (event.start.offset_minutes < 0) {
            vm.sunriseSunsetOffsetType = 'before';
          } else {
            vm.sunriseSunsetOffsetType = 'at';
          }

          if (event.start.offset === 1) {
            vm.sunriseOrSunset = 'sunrise';
          } else if (event.start.offset === 2) {
            vm.sunriseOrSunset = 'sunset';
          }
        } else {
          const hours = Math.floor(event.start.offset_minutes / 60);
          const mins = Math.floor(event.start.offset_minutes % 60);
          vm.startTimeType = 'fixed';
          vm.startTime = (moment().hours(hours).minutes(mins).format('HH:mm'));
        }

        // start date
        const sd = event.start.start_date;
        if (!!sd && !!sd.start_period && sd.start_period > 0) {
          vm.startWeekOffset = sd.start_period;
          vm.startMonth = sd.start_month;
          vm.startYear = sd.start_year;
          vm.startDayOfWeek = sd.start_weekday;
          vm.startDateType = 'relative';
        } else {
          vm.startDate = moment(new Date(sd.start_year, sd.start_month, sd.start_day)).format('YYYY-MM-DD');
          vm.startDateType = 'fixed';
        }
      }

      // repeating
      if (!!event.repeat) {
        vm.repeatType = 'every';
        vm.repeatFrequency = event.repeat.frequency;
        vm.repeatRate = event.repeat.rate;
        vm.repeatDays = event.repeat.daymask;

        if (!!event.repeat.end_date) {
          const ed = event.repeat.end_date;
          vm.endDate = moment(new Date(ed.end_year, ed.end_month - 1, ed.end_day)).format('YYYY-MM-DD');
          vm.hasEndDate = true;
        } else {
          vm.hasEndDate = false;
        }
      } else {
        vm.repeatType = 'once';
      }
    }

    return vm;
  }


  validate(vm: ScheduleViewModel): string[] {
    this._logger.debug('_validate', vm);
    const errors = [];
    if (!!vm.name && vm.name.length > 0) {
      const events = Utils.snapshot<ScheduleEvent[]>(this.store.select(s => s.scheduleProgramming.scheduleEvents));
      if (events.some(e => (e.display.toLowerCase() === vm.name.toLowerCase()) && e.eventId !== vm.eventId)) {
        errors.push('A schedule with this name already exists. Schedule names must be unique.');
      }
    } else {
      errors.push('A schedule name is required.');
    }

    const start = moment(vm.startDate);
    const end = moment(vm.endDate);

    if (vm.startDateType === 'fixed') {
      if (start.isValid()) {
      } else {
        errors.push('Start date is invalid.');
      }
    }

    if (vm.hasEndDate) {
      if (end.isValid()) {
        if (start.isAfter(end)) {
          errors.push('End date must be after start date.')
        }
      } else {
        errors.push('End date is invalid.');
      }
    }

    if (vm.startTimeType === 'fixed') {
      const tmp = (moment().format('YYYY-MM-DD') + 'T' + vm.startTime);
      this._logger.debug('validating start time', tmp);
      const startTime = moment(tmp);
      if (vm.startTime.length === 0 || !startTime.isValid()) {
        errors.push('Start time is invalid.');
      }
    }

    if (vm.repeatFrequency === 2 && vm.repeatDays === 0) { // weekly
      errors.push('A schedule that repeats weekly must also specify the days of the week it occurs.')
    }

    return errors;
  }

  private _canDelete(codeitems: CodeItem[]): boolean {
    let canDelete = true;

    if (codeitems) {
      codeitems.forEach(ci => {
        canDelete = canDelete && (!ci.protected) && this._canDelete(ci.codeItems);
      });
    }

    return canDelete;
  }

  private _startOffsetMinutesFromContext(startTime: string): number {
    const parts = startTime.split(':');
    return (parseInt(parts[0], 10) * 60) + parseInt(parts[1], 10);
  }

  private _handleUpdates(events: Array<ScheduleEvent>) {
    this._logger.debug('handle event list update', events);
    if (!!events && Array.isArray(events)) {
      events = <ScheduleEvent[]>Utils.resolveProtected(events);
      this.store.dispatch(new SetScheduleEvents(
        events.filter(evt => HIDDEN_EVENTS.indexOf(evt.creatorId) == -1)
      ));
    }
  }

  private async _unwatchSchedule(): Promise<any> {
    this._logger.debug('unwatch schedule');

    if (!!this._scheduleSub) {
      this._scheduleSub.unsubscribe();
      this._scheduleSub = null;
    }

    // HACK see _unwatchEvent in events.service.ts
    return timer(0).pipe(take(1)).toPromise();
  }

  private async _watchSchedule(id: number) {
    this._logger.debug('watch schedule', id);

    if (id > 0) {
      this._ready(false);
      this._scheduleSub = this.broker.getObservable<ScheduleEvent>({
        path: '/api/v1/agents/scheduler/' + id
      }).subscribe(sched => {
        this._logger.debug('schedule update received', sched);
        Utils.resolveProtected([sched]);
        sched.subtitle = this.scheduleLabel.transform(sched);
        this.store.dispatch(new SetSelectedSchedule(sched));
        this._logger.debug('setting selected schedule', sched);
        this._ready(true);
      }, err => {
        this._logger.error('error subscribing to given schedule', err);
        this._ready(true);
      });
    }
  }

  private _ready(state: boolean) {
    this.store.dispatch(new SetSchedulesReady(state));
  }
}
