<div [cxWait]="(ready | async)">
  <prg-nav-header title="Which Navigator custom buttons screen would you like to use to trigger this automation?"
    (cancel)="goHome()"></prg-nav-header>
  <div class="select-screen">
    <div *ngIf="(ready | async) && (allScreens | async)?.length === 0">
      <header>
        <span class="icon grey information small"></span>
        <h6 class="grow">No Screens Found</h6>
      </header>
      <p class="help-text">There are no Navigator custom button screens defined in your project.</p>
    </div>

    <section class="filter" *ngIf="(ready | async) && (allScreens | async)?.length > 0">
      <label for="floorSel" class="inline-label">Floor</label>
      <select name="floorSel" id="floorSel" class="transparent" (change)="setFloor($event)">
        <option value="any">Any Floor</option>
        <option [value]="floor.id" *ngFor="let floor of (allFloors | async | sortBy:'name')">{{floor.name}}</option>
      </select>
      <label for="roomSel" class="inline-label">Room</label>
      <select name="roomSel" id="roomSel" class="transparent" (change)="setRoom($event)">
        <option value="any">Any Room</option>
        <option [value]="room.id" *ngFor="let room of (allRooms | async | matchingFloor:_currentFloor | sortBy:'name')">{{room.name}}</option>
      </select>
      <span class="spacer"></span>
      <span>
        <i class="icon small search grey"></i>
        <input type="search" placeholder="Search" [(ngModel)]="_searchText">
      </span>
    </section>
    <cxScrollable>
      <prg-block-button *ngFor="let sc of (allScreens | async | customButtonsMatchingFloor:_currentFloor | customButtonsMatchingRoom:_currentRoom | matchingNameOrLocation:_searchText | sortBy:'name')"
        (click)="screenSelected(sc)" buttonClass="dark" leadingIcon="blue room" title="{{sc.name}}" [subtitle]="getSubtitle(sc)"></prg-block-button>
    </cxScrollable>
  </div>
</div>
