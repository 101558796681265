import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

// Formerly /core services
import { BackupService } from './services/backup.service';
import { CommonConfigService } from './services/common-config.service';
import { ConsoleService } from './services/console.service';
import { ControllerConnectionService } from './services/controller-connection.service';
import { CoreConfigService } from './services/core-config.service';
import { MonitoredHttpService } from './services/monitored-http.service';
import { ResourceService } from './services/resource.service';
import { StatusService } from './services/status.service';
import { ViewBrokerService } from './services/view-broker.service';
import { WindowService } from './services/window.service';
import { WrappedHttpService } from './services/http.service';
import { RouterHistoryService } from './services/router-history.service';
import { AnalyticsService } from './services/analytics.service';
import { PerformanceService } from './services/performance.service';

// Formerly /api services
import { AccountService } from './services/account.service';
import { BrokerService } from './services/broker.service';
import { CategoryService } from './services/category.service';
import { ConfigurationService } from './services/configuration.service';
import { GUIDService } from './services/guid.service';
import { PlatformService } from './services/platform.service';
import { RegistrationService } from './services/registration.service';
import { ServiceLocatorService } from './services/service-locator.service';
import { StorageService } from './services/storage.service';
import { UpdateService } from './services/update.service';
import { UrlFactoryService } from './services/url-factory.service';

import { DirectorConnectionService } from './director-connection/director-connection.service';
import { DirectorConnectedGuard } from './director-connection/director-connected.guard';
import { DirectorReadyGuard } from './director-connection/director-ready.guard';
import { LocationsService } from './services/location.service';
import { httpAuthInterceptors } from './interceptors/auth.interceptor';

const CORE = [CommonConfigService, ConsoleService, ControllerConnectionService,
  CoreConfigService, WrappedHttpService, MonitoredHttpService, BackupService,
  ResourceService, StatusService, ViewBrokerService, WindowService, AnalyticsService,
  PerformanceService, LocationsService
];

const API = [AccountService, BrokerService, CategoryService, ConfigurationService,
  GUIDService, PlatformService, RegistrationService, ServiceLocatorService, 
  StorageService, UpdateService, UrlFactoryService, BackupService, WrappedHttpService, 
  RouterHistoryService];

@NgModule({
  imports: [
    HttpClientModule
  ],
  providers: [
    ...CORE,
    ...API,
    DirectorConnectionService,
    DirectorConnectedGuard,
    DirectorReadyGuard,
    ...httpAuthInterceptors
  ]
})
export class CoreModule { }
