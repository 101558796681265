import {
  Schedules,
  ScheduleEvent,
} from './../quickstart-schedule/+state/schedule.interfaces';
import { VoiceScenesService } from './../quickstart-voice-scenes/voice-scenes.service';
import { Location, LocationsContext } from '@when-then/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { map, filter, switchMap, withLatestFrom } from 'rxjs/operators';
import {
  Component,
  OnInit,
  ViewChild,
  Directive,
  ElementRef,
  Renderer2,
} from '@angular/core';

import { TimeOfDayService } from './../../common/services/time-of-day.service';
import { ProgrammingUtilsService as Utils } from './../../common/services/utils.service';
import { SharedProgrammingService } from './../../common/services/shared-programming.service';
import { BaseRoutingComponent } from '../../common/base-routing/base-routing.component';
import { CommonProgrammingContext } from '../../common/services/shared-programming.service';
import {
  EventsUIContext,
  EVENTS_BY,
  EVENTS_FILTER,
  EVENTS_UI_ACTIONS,
} from './eventsUI.service';
import {
  EventsService,
  ProgrammingEventsState,
} from '../../common/services/events.service';
import { LoggerFactory } from '@when-then/core';
import { Event } from '../../common/interfaces/event.interface';

interface MenuItem {
  label: string;
  token: EVENTS_BY | EVENTS_FILTER;
}

@Component({
  selector: 'prg-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.less'],
})
export class EventsComponent extends BaseRoutingComponent implements OnInit {
  currentGrouping: Observable<any>;
  searchText: Observable<string>;
  ready: Observable<boolean>;
  currentTime: Observable<string>;
  filteredEvents: Observable<Event[]>;
  currentCount: Observable<number>;
  scheduleEvents: Observable<ScheduleEvent[]>;
  scheduleEventsToday: Observable<ScheduleEvent[]>;

  _rooms: Observable<Location[]>;
  _selectedRoom: Location;

  _by: Observable<MenuItem>;
  _filter: Observable<MenuItem>;

  showFilters = false;

  private _logger = LoggerFactory.getLogger(EventsComponent.name);

  _bys: MenuItem[] = [
    {
      label: 'Description',
      token: EVENTS_BY.DESCRIPTION,
    },
    {
      label: 'Today',
      token: EVENTS_BY.TODAY,
    },
    {
      label: 'Scheduled',
      token: EVENTS_BY.SCHEDULED,
    },
    {
      label: 'Room',
      token: EVENTS_BY.ROOM,
    },
    {
      label: 'Device',
      token: EVENTS_BY.DEVICE,
    },
  ];

  _filters: MenuItem[] = [
    {
      label: 'All Automation',
      token: EVENTS_FILTER.ALL,
    },
    {
      label: 'My Automation',
      token: EVENTS_FILTER.MINE,
    },
  ];

  @ViewChild('filterMenu') filterMenu;

  EVENTS_BY = EVENTS_BY;
  EVENTS_FILTER = EVENTS_FILTER;

  constructor(
    protected router: Router,
    private route: ActivatedRoute,
    protected store: Store<{
      sharedProgramming: CommonProgrammingContext;
      eventsUI: EventsUIContext;
      programmingEvents: ProgrammingEventsState;
      locations: LocationsContext;
      scheduleProgramming: Schedules;
    }>,
    protected eventsService: EventsService,
    protected shared: SharedProgrammingService,
    private timeOfDay: TimeOfDayService,
    private vss: VoiceScenesService
  ) {
    super();
  }

  ngOnInit() {
    this.currentCount = this.store.select((s) => s.eventsUI.currentCount);
    this.currentGrouping = this.shared.currentGrouping;
    this.searchText = this.shared.searchText;
    this.ready = this.eventsService.ready;

    this.scheduleEvents = <Observable<ScheduleEvent[]>>(
      this.store.select((s) => s.scheduleProgramming.scheduleEvents)
    );

    this._rooms = this.store
      .select((s) => s.locations.all)
      .pipe(
        filter((all) => !!all),
        map((all) => all.filter((loc) => loc.type === 8))
      );

    this._by = this.store
      .select((s) => s.eventsUI.by)
      .pipe(map((by) => this._bys.find((mi) => mi.token == by)));

    this._filter = this.store
      .select((s) => s.eventsUI.filter)
      .pipe(map((filter) => this._filters.find((f) => f.token == filter)));

    this.filteredEvents = this.store
      .select((s) => s.programmingEvents.programmedEvents)
      .pipe(
        filter((events) => !!events),
        switchMap((events) => this.vss.filterProhibitedEvents(events))
      );

    this.shared.clearError();
    // this.shared.clearSelectedEvent();
    // NOTE ensure the events view always starts collapsed
    this.shared.setEditing(false);

    this.currentTime = this.timeOfDay.timeOfDay.pipe(
      map((t) =>
        Utils.toTimeDisplay(
          t.getFullYear(),
          t.getMonth() + 1,
          t.getDate(),
          t.getHours() * 60 + t.getMinutes()
        )
      )
    );
  }

  searchTextChanged(event: any) {
    this.shared.setSearchText(event.target.value);
  }

  isActive(path: string): boolean {
    return window.location.href.indexOf(path) > 0;
  }

  // goAdd() {
  //   this.router.navigate(['quickstarts/triggers'], { relativeTo: this.moduleRoot });
  // }

  updateSearchText(event: any) {
    this.shared.setSearchText(event.target.value);
  }

  _updateBy = (by: string) => {
    this.store.dispatch({
      type: EVENTS_UI_ACTIONS.SET_BY,
      payload: by,
    });
  };

  _updateFilter = (filter: any) => {
    console.log('cmp: setting filter', filter);
    this.store.dispatch({
      type: EVENTS_UI_ACTIONS.SET_FILTER,
      payload: filter,
    });
  };

  selectRoom(room: Location) {
    this._selectedRoom = room;
  }
}

@Directive({
  selector: '[cxGrabFocus]',
})
export class FocusGrabbingInputAttribute {
  constructor(private renderer: Renderer2, public elementRef: ElementRef) {}

  ngOnInit() {
    this.elementRef.nativeElement.focus();
  }
}
