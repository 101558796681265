import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { UIModule } from '@when-then/ui';

import { ProgrammingCommonModule } from '../../common/common.module';
import { QuickstartCommonModule } from '../quickstart-common/quickstart-common.module';

import { QSRoomsService } from './quickstart-rooms.service';
import { QSRoomsComponent } from './quickstart-rooms.component';

import { SelectRoomComponent } from './select-room/select-room.component';
import { SelectParamsComponent } from './select-params/select-params.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    UIModule,
    ProgrammingCommonModule,
    QuickstartCommonModule
  ],
  providers: [
    QSRoomsService
  ],
  declarations: [
    QSRoomsComponent,
    SelectRoomComponent,
    SelectParamsComponent
  ]
})
export class QuickstartRoomsModule { }
