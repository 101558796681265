import { WhenThenComponent } from './when-then/when-then.component';
/**
 * Copyright 2017 Control4 Corporation. All rights reserved.
 * Control4 Confidential and Proprietary Information.
 */

import { Routes } from '@angular/router';

import { QUICKSTART_ACTIONS_NOTIFICATIONS_ROUTES } from './quickstart-notifications/notifications.routes';
import { QUICKSTART_ACTIONS_LED_COLORS_ROUTES } from './quickstart-led-colors/led-colors.routes';
import { QUICKSTART_ACTIONS_TIMERS_ROUTES } from './quickstart-timers/timers.routes';
import { QUICKSTART_ACTIONS_LIGHTS_ROUTES, QUICKSTART_TRIGGERS_LIGHTS_ROUTES } from './quickstart-lights/quickstart-lights.routes';
import { QUICKSTART_TRIGGERS_SCHEDULE_ROUTES } from './quickstart-schedule/schedule-quickstart.routes';
import { QUICKSTART_TRIGGERS_SIMPLE_ROUTES, QUICKSTART_ACTIONS_SIMPLE_ROUTES } from './quickstart-simple/quickstart-simple.routes';
import { QUICKSTART_TRIGGERS_ROOMS_ROUTES, QUICKSTART_ACTIONS_ROOMS_ROUTES } from './quickstart-rooms/quickstart-rooms.routes';
import { QUICKSTART_ACTIONS_MEDIA_ROUTES } from './quickstart-media/quickstart-media.routes';
import { QUICKSTART_ACTIONS_THERMOSTAT_ROUTES } from './quickstart-thermostats/quickstart-thermostats.routes';
import { VOICE_SCENE_AUTOMATION_ROUTES } from './quickstart-voice-scenes/voice-scenes.routes';

import { EditComponent } from './edit/edit.component';
import { SelectEventTypeComponent } from './select-event-type/select-event-type.component';
import { EventsComponent } from './events/events.component';
import { SelectActionComponent } from './select-action/select-action.component';
import { EventComponent } from './event/event.component';
import { AddComponent } from './add/add.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { IntroductionComponent } from './introduction/introduction.component';
import { AddScheduleComponent } from './quickstart-schedule/add-schedule/add-schedule.component';
import { QUICKSTART_TRIGGERS_BUTTONS_ROUTES } from './quickstart-buttons/buttons.routes';
import { VSEditComponent } from './quickstart-voice-scenes/edit/edit.component';
import {
  QUICKSTART_TRIGGER_DOORSTATION_MOTION_ROUTES, QUICKSTART_TRIGGER_DOORSTATION_SECURITY_ROUTES
} from './quickstart-doorstations/quickstart-doorstation.routes';
import { QUICKSTART_FLOW_CONTROL_ROUTES } from './quickstart-flow-control/flow-control.routes';
import { WhatsNewComponent } from './whats-new/whats-new.component';

const QUICKSTART_ACTION_ROUTES: Routes = [
  ...QUICKSTART_ACTIONS_NOTIFICATIONS_ROUTES,
  ...QUICKSTART_ACTIONS_LED_COLORS_ROUTES,
  ...QUICKSTART_ACTIONS_TIMERS_ROUTES,
  ...QUICKSTART_ACTIONS_LIGHTS_ROUTES,
  ...QUICKSTART_ACTIONS_MEDIA_ROUTES,
  ...QUICKSTART_ACTIONS_THERMOSTAT_ROUTES,
  ...QUICKSTART_ACTIONS_ROOMS_ROUTES,
  ...QUICKSTART_ACTIONS_SIMPLE_ROUTES,
  ...QUICKSTART_FLOW_CONTROL_ROUTES
];

export const QUICKSTART_PROGRAMMING_ROUTES: Routes = [{
  path: '', redirectTo: 'when-then', pathMatch: 'full'
}, {
  path: 'when-then', component: WhenThenComponent, children: [{
    path: '', redirectTo: 'events', pathMatch: 'full'
  }, {
    path: 'welcome', component: WelcomeComponent
  }, {
    path: 'whats-new', component: WhatsNewComponent
  }, {
    path: 'intro', component: IntroductionComponent
  }, {
    path: 'events', component: EventsComponent
  }, {
    path: 'add', component: AddComponent, children: [{
      path: '', redirectTo: 'type', pathMatch: 'full'
    }, {
      path: 'type', component: SelectEventTypeComponent
    },
    ...QUICKSTART_TRIGGERS_SCHEDULE_ROUTES,
    ...QUICKSTART_TRIGGERS_BUTTONS_ROUTES,
    ...QUICKSTART_TRIGGERS_ROOMS_ROUTES,
    ...QUICKSTART_TRIGGERS_LIGHTS_ROUTES,
    ...QUICKSTART_TRIGGER_DOORSTATION_MOTION_ROUTES,
    ...QUICKSTART_TRIGGER_DOORSTATION_SECURITY_ROUTES,
    ...QUICKSTART_TRIGGERS_SIMPLE_ROUTES,
    ...VOICE_SCENE_AUTOMATION_ROUTES
    ]
  }, {
    path: 'device/:deviceId/event/:eventId',
    component: EventComponent,
    children: [{
      path: '', redirectTo: 'edit', pathMatch: 'full'
      // }, {
      //   path: 'view', component: ViewComponent
    }, {
      path: 'edit', component: EditComponent
    }, {
      path: 'edit/schedule', component: AddScheduleComponent, data: { editing: true }
    }, {
      path: 'edit/voice-scene', component: VSEditComponent
    }, {
      path: 'add', children: [{
        path: '', redirectTo: 'select', pathMatch: 'full'
      }, {
        path: 'select', component: SelectActionComponent
      },
      ...QUICKSTART_ACTION_ROUTES
      ]
    }]
  }]
}];
