import { Store } from '@ngrx/store';
import { AgentMetaService } from './../services/agent-meta.service';
import { Pipe, PipeTransform } from '@angular/core';
import { DeviceCategoryService } from '../services/device-category.service';
import { ProgrammingUtilsService as Utils } from '../services/utils.service';
import { LocationsService, Location } from '@when-then/core';

@Pipe({
  name: 'triggerIconClass'
})
export class TriggerIconClassPipe implements PipeTransform {

  constructor(
    private store: Store<any>,
    private agents: AgentMetaService,
    private categorySvc: DeviceCategoryService,
    private locations: LocationsService
  ) { }

  transform(value: any, args?: any): any {
    // console.log('trig-icon: getting icon for ', value);
    let icon = 'fa fa-fw fa-question';

    let items = Utils.getValue(this.store, s => s.programmingItems.itemsMap);
    let item = items[value.deviceId];
    // is it a device?
    if (item) {
      if (item.typeName === 'device') {
        // console.log('trig-icon: getting icon for item', item);
        icon = this.categorySvc.getCategoryIconForItem(item);
      } else if (item.typeName === 'agent') {
        // console.log('trig-icon: getting icon for agent id', value.deviceId);
        // is it an agent?
        icon = this.agents.getIconForId(value.deviceId);
      }
    } else {
      // is it a room?
      let room = Utils.snapshot<Location>(this.locations.getLocationById(value.deviceId));
      if (room && room.type === 8) {
        icon = 'fa fa-fw fa-cube';
      }
    }

    // console.log('returning icon for item', value, icon);
    return icon;
  }
}
