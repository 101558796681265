import { Component } from '@angular/core';

@Component({
  selector: 'prg-working',
  templateUrl: './working.component.html',
  styleUrls: ['./working.component.less']
})
export class WorkingComponent {

}
