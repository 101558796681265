import { Routes } from "@angular/router";
import { DoorstationComponent } from "./doorstation/doorstation.component";
import { DOORSTATION_BUTTON } from "../quickstart-simple/event-types/doorstation-button.type";
import { DOORSTATION_MOTION } from "../quickstart-simple/event-types/doorstation-camera.type";
import { DOORSTATION_SECURITY } from "../quickstart-simple/event-types/doorstation-security.type";
import { DoorstationEventsComponent } from "./doorstation-events/doorstation-events.component";

export const QUICKSTART_TRIGGER_DOORSTATION_BUTTONS_ROUTES: Routes = [{
    path: 'doorstation', component: DoorstationComponent
}, {
    path: 'doorstation/:id/events', component: DoorstationEventsComponent, data: { eventType: DOORSTATION_BUTTON }
}];

export const QUICKSTART_TRIGGER_DOORSTATION_MOTION_ROUTES: Routes = [{
    path: 'doorstation-motion', component: DoorstationComponent
}, {
    path: 'doorstation-motion/:id/events', component: DoorstationEventsComponent, data: { eventType: DOORSTATION_MOTION }
}];

export const QUICKSTART_TRIGGER_DOORSTATION_SECURITY_ROUTES: Routes = [{
    path: 'doorstation-security', component: DoorstationComponent
}, {
    path: 'doorstation-security/:id/events', component: DoorstationEventsComponent, data: { eventType: DOORSTATION_SECURITY }
}];
