import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'distinctProxies'
})
export class DistinctProxiesPipe implements PipeTransform {

  constructor() { }

  transform(items: any): Array<any> {
    let foundProxies = new Set<string>();

    items.forEach(item => {
      if (!foundProxies.has(item.proxy)) {
        foundProxies.add(item.proxy);
      }
    });

    return new Array<any>(foundProxies);
  }
}
