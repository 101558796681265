import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';

import { AccessControlList, AuthorizationProvider } from './authorization-provider';
import { AuthenticationComponent } from './../authentication.component';
import { IdentityState, PROFILE_ROOT, PROFILE_CUSTOMER, PROFILE_UNKNOWN } from './../services/identity.service';

const MANIFEST: any = {
  'root': {
    login: true,
    composer: true,
    quickstartProgramming: false,
    systemManager: true
  },
  'pro-installer': {
    login: true,
    composer: true,
    quickstartProgramming: true,
    systemManager: true
  },
  'primary': {
    login: true,
    composer: true,
    quickstartProgramming: true,
    systemManager: false
  }
};

const NOT_AUTHORIZED: AccessControlList = {
  login: true,
  composer: false,
  quickstartProgramming: false,
  systemManager: false
};

@Injectable()
export class RoleBasedAuthorizationProvider implements AuthorizationProvider {

  constructor() { }

  provide(identity: IdentityState): AccessControlList {
    // console.log('rbac-prov: autz manifest', MANIFEST);
    // console.log('rbac-prov: given identity', identity);
    let authz: AccessControlList = MANIFEST[identity.role];
    // console.log('rbac-prov: corresponding autz is ', authz)
    return authz || NOT_AUTHORIZED;
  }
}
