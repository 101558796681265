import { LoggerFactory } from './../../../../../../../libs/core/src/lib/services/log.service';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { Device, Item } from './../../../common/interfaces/item.interface';
import { ItemsService } from './../../../common/services/items.service';
import { SharedProgrammingService, CommonProgrammingContext } from '../../../common/services/shared-programming.service';
import { ProgrammingUtilsService as Utils } from './../../../common/services/utils.service';

import { LIGHTS_QUERY } from '../quickstart-lights.common';

import { QuickstartProgrammingService } from '../../services/quickstart-programming.service';
import { Room } from '../services/interfaces/room.interface';
import { LightsService, LightsProgrammingContext } from '../services/lights.service';
import { Light } from '../services/interfaces/light.interface';

@Component({
  selector: 'prg-light-action',
  templateUrl: './light-action.component.html',
  styleUrls: ['./light-action.component.less']
})
export class LightActionComponent {

  private _logger = LoggerFactory.getLogger(LightActionComponent.name);
  private _rooms: Observable<Room[]>;

  private lights: Observable<Item[]>;

  constructor(
    private store: Store<{ lightsProgramming: LightsProgrammingContext, sharedProgramming: CommonProgrammingContext }>,
    private router: Router,
    private route: ActivatedRoute,
    private items: ItemsService,
    private lightsService: LightsService
  ) {
    this._rooms = this.lightsService.actionLightsByRoom;
    this.lights = this.lightsService.actionLights;
  }

  ngOnInit() {
    this.lights = this.items.itemsList
      .pipe(
        map(items => {
          return items.filter((item: any) => {
            return (['light_v2', 'light'].indexOf(item.proxy) >= 0 && item.type === 7);
          })
        })
      );
  }

  lightSelected(light: Light) {
    this._logger.debug('light-action.lightselected: ', light);
    this.lightsService.setActionLight(light);
    this.router.navigate(['../../light/' + light.id], { relativeTo: this.route });
  }
}
