import { Pipe, PipeTransform } from '@angular/core';
import { ScheduleEvent } from '../+state/schedule.interfaces';

@Pipe({
  name: 'scheduleIcon'
})
export class ScheduleIconPipe implements PipeTransform {

  transform(value: ScheduleEvent, args?: any): any {
    let icon = 'schedule';

    if (value.start) {
      if (value.start.offset === 1) {
        icon = 'dawn';
      } else if (value.start.offset === 2) {
        icon = 'dusk';
      } else if (value.repeat && value.repeat.frequency) {
        icon = 'event';
      } else if (value.start.start_date && value.start.start_date.start_day) {
        icon = 'calendar';
      }
    }

    return icon;
  }

}
