import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { ScheduleIconPipe } from './../../quickstarts/quickstart-schedule/pipes/schedule-icon.pipe';
import { ItemsService } from './items.service';
import { ProgrammingUtilsService as Utils } from '../../common/services/utils.service';
import { CommonProgrammingContext } from './shared-programming.service';

import { BrokerService } from '@when-then/core';
import { Subscription, Observable } from 'rxjs';
import { filter, distinctUntilChanged, map } from 'rxjs/operators';
import { Item, Device } from '../interfaces/item.interface';

export const AGENTS = {
  ACCESS: { CONTROL: 'control4_agent_access' },
  ADVANCED_LIGHTING: { CONTROL: 'control4_agent_adv_lighting' },
  ANNOUNCEMENTS: { CONTROL: 'control4_agent_announcements', ID: 100118 },
  BACKUP: { CONTROL: 'control4_agent_backup' },
  COMMUNICATION: { CONTROL: 'control4_agent_videointercom' },
  CUSTOM_BUTTONS: { CONTROL: 'control4_agent_custombuttons', ID: 100111 },
  DELAY: { ID: 100000 },
  DRIVER_UPDATE: { CONTROL: 'control4_agent_driverupdate', ID: 100124 },
  EMAIL_NOTIFICATION: { CONTROL: 'control4_agent_emailnotification', ID: 100113 },
  FOURSTORE: { CONTROL: 'control4_agent_4store' },
  GUEST_SERVICES: { CONTROL: 'control4_agent_services' },
  HISTORY: { CONTROL: 'control4_agent_history' },
  IDENTITY: { CONTROL: 'control4_agent_identity' },
  LIGHT_PROPERTIES: { CONTROL: 'control4_agent_light_properties' },
  MACROS: { CONTROL: 'control4_agent_macros' },
  MEDIA_SCENES: { CONTROL: 'control4_agent_audioscenes', ID: 100114 },
  NAVIGATION: { CONTROL: 'control4_agent_navigation' },
  PUSH_NOTIFICATION: { CONTROL: 'control4_agent_notification' },
  REMOTE_ACCESS: { CONTROL: 'control4_agent_remoteaccess', ID: 100117 },
  SCHEDULER: { CONTROL: 'control4_agent_scheduler', ID: 100100 },
  SCREEN_SAVER: { CONTROL: 'control4_agent_screensaver', ID: 100112 },
  SYSTEM_DIAGNOSTICS: { CONTROL: 'control4_agent_diagnostics', ID: 100115 },
  TIMER: { CONTROL: 'control4_agent_timer', ID: 100120 },
  UI_CONFIGURATION: { CONTROL: 'control4_agent_uiconfiguration' },
  UPDATE_MANAGER: { CONTROL: 'control4_agent_updatemanager', ID: 100116 },
  WAKEUP: { CONTROL: 'control4_agent_wakeup', ID: 100104 },
  WAKEUP_GOODNIGHT: { CONTROL: 'control4_agent_wakeup_goodnight' },
  VARIABLES: { ID: 100001 },
  ZWAVE: { CONTROL: 'control4_agent_zwave', ID: 100123 },
  ZWAVE_SCENES: { CONTROL: 'control4_agent_zwave_scenes' }
}

@Injectable()
export class AgentMetaService {

  _agentsSub: Subscription;
  schedulerIcon: ScheduleIconPipe;
  _agents: Observable<Device[]>;

  constructor(
    private items: ItemsService,
    private broker: BrokerService,
    private store: Store<{
      sharedProgramming: CommonProgrammingContext
    }>
  ) {
    this.schedulerIcon = new ScheduleIconPipe();

    this._agents = this.items.itemsList
      .pipe(
        filter(s => !!s),
        distinctUntilChanged(),
        map((items: Device[]) => items.filter(itm => itm.type === 9))
      );
  }

  getIconForId(id: number): string {

    if (!!id) {
      let item: any = this.items.getItem(id);

      if (!!item) {
        switch (id) {
          case AGENTS.SCHEDULER.ID: return this.schedulerIcon.transform(item);
          case AGENTS.VARIABLES.ID: return 'fa fa-fw fa-subscript'; // variables
          case AGENTS.EMAIL_NOTIFICATION.ID: return 'email'; // email notify
          case AGENTS.DELAY.ID: return this._getFlowControlIcon(item);
          case AGENTS.ANNOUNCEMENTS.ID: return 'announcement'; // announcement
          case AGENTS.CUSTOM_BUTTONS.ID: return 'custom-button';
          case AGENTS.MEDIA_SCENES.ID: return 'scenes';
          default:
            if (item.type === 9) {
              switch (item.control) {
                case AGENTS.ADVANCED_LIGHTING.CONTROL: return 'lightscene';
                case AGENTS.MACROS.CONTROL: return 'playlist';
                case AGENTS.COMMUNICATION.CONTROL: return 'camera';
                case AGENTS.BACKUP.CONTROL: return 'storage';
                case AGENTS.HISTORY.CONTROL: return 'calendar';
                case AGENTS.UI_CONFIGURATION.CONTROL: return 'tablet';
                case AGENTS.WAKEUP_GOODNIGHT.CONTROL: return 'dawn';
                case AGENTS.LIGHT_PROPERTIES.CONTROL: return 'lightbulb';
                case AGENTS.PUSH_NOTIFICATION.CONTROL: return 'notice';
                case AGENTS.IDENTITY.CONTROL: return 'profile';
              }
            }
        }
      }
    }

    return 'unknown';
  }

  getNameForId(id: number): string {
    switch (id) {
      case AGENTS.ANNOUNCEMENTS.ID: return 'Announce';
      case AGENTS.CUSTOM_BUTTONS.ID: return 'Custom Button';
      case AGENTS.DELAY.ID: return 'Delay';
      case AGENTS.DRIVER_UPDATE.ID: return 'Driver Update';
      case AGENTS.EMAIL_NOTIFICATION.ID: return 'Email';
      case AGENTS.MEDIA_SCENES.ID: return 'Media Scene';
      case AGENTS.REMOTE_ACCESS.ID: return 'Remote Access';
      case AGENTS.SCHEDULER.ID: return 'Schedule';
      case AGENTS.SCREEN_SAVER.ID: return 'Screen Saver';
      case AGENTS.SYSTEM_DIAGNOSTICS.ID: return 'System Diagnostics';
      case AGENTS.TIMER.ID: return 'Timer';
      case AGENTS.UPDATE_MANAGER.ID: return 'Update Manager';
      case AGENTS.WAKEUP.ID: return 'Wakeup';
      case AGENTS.VARIABLES.ID: return 'Variable';
      case AGENTS.ZWAVE.ID: return 'Z-Wave';

      default:

        let item = this.items.getItem(id);

        if (item && item.control) {
          return this._getNameForControl(item.control);
        }
    }

    return 'unknown'
  }

  getAgent(id: number): Item {
    return Utils.snapshot<Item[]>(this._agents).find(a => a.id === id);
  }

  getAgentId(control: string): number {
    return Utils.snapshot<Device[]>(this._agents).find(ag => ag.control === control).id;
  }

  private _getNameForControl(control: string): string {
    switch (control) {
      case AGENTS.ACCESS.CONTROL: return 'Access';
      case AGENTS.ADVANCED_LIGHTING.CONTROL: return 'Lighting';
      case AGENTS.BACKUP.CONTROL: return 'Backup';
      case AGENTS.COMMUNICATION.CONTROL: return 'Communication';
      case AGENTS.FOURSTORE.CONTROL: return '4Store';
      case AGENTS.GUEST_SERVICES.CONTROL: return 'Guest Services';
      case AGENTS.HISTORY.CONTROL: return 'History';
      case AGENTS.IDENTITY.CONTROL: return 'Identity';
      case AGENTS.LIGHT_PROPERTIES.CONTROL: return 'Light Properties';
      case AGENTS.MACROS.CONTROL: return 'Macro';
      case AGENTS.NAVIGATION.CONTROL: return 'Navigation';
      case AGENTS.PUSH_NOTIFICATION.CONTROL: return 'Notification';
      case AGENTS.UI_CONFIGURATION.CONTROL: return 'UI Configuration';
      case AGENTS.WAKEUP_GOODNIGHT.CONTROL: return 'Wakeup/Goodnight';
      case AGENTS.ZWAVE_SCENES.CONTROL: return 'Z-Wave Scenes';
    }

    return 'unknown';
  }

  private _getFlowControlIcon(item: any): string {
    console.log('getting flow control icon for ', item);
    if (item.command) {
      switch (item.command.command) {
        case 'DELAY': return 'intervals';
        case 'BREAK': return 'break';
        case 'RETURN': return 'stop';
      }
    }
    return 'unknown';
  }
}
