import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'aggregateDisplay'
})
export class AggregateDisplayPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value && value.length) {
      let displays = [];
      value.forEach(val => {
        displays.push(val.display);
      });

      return displays.join(', and ');
    }

    return null;
  }

}
