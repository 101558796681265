import { EventType } from './event-type.interface';

export const VOICE_SCENE: EventType = {
  name: 'Voice Scene',
  description: 'Make something happen when you speak',
  route: 'voice-scene/list',
  category: 'Voice Scenes',
  icon: 'voice-scene',
  order: 101,
  OSMinVersion: '2.10.3',
  whitelist: [
    {
      fields: { proxy: 'voice-scene' },
      events: [],
      minVersion: 67
    }
  ]
}
