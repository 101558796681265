import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'expiredRecurrence'
})
export class ExpiredRecurrencePipe implements PipeTransform {

  transform(events: Array<any>, args?: any): any {
    if (events) {
      return events.filter(evt => {
        return (evt.next_occurrence);
      });
    }

    return events;
  }

}
