import { map } from 'rxjs/operators';
import { Component, OnInit, Input } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';

import { ProgrammingUtilsService as Utils } from './../../../common/services/utils.service';
import { SharedProgrammingService } from './../../../common/services/shared-programming.service';
import { CodeItem } from './../../../common/interfaces/code-item.interface';
import { CommonProgrammingContext } from '../../../common/services/shared-programming.service';
import { Event } from '../../../common/interfaces/event.interface';
import { LoggerFactory } from '@when-then/core';

@Component({
  selector: 'prg-code-item,[prg-code-item]',
  templateUrl: './code-item.component.html',
  styleUrls: ['./code-item.component.less']
})
export class CodeItemComponent implements OnInit {

  private _logger = LoggerFactory.getLogger(CodeItemComponent.name);

  @Input() codeItem: any;
  @Input() event: Event;
  @Input() parent: CodeItem;

  listMode: Observable<boolean>;

  disabled: boolean;
  _showConfirmDelete: boolean = false;

  constructor(
    private shared: SharedProgrammingService,
    private utils: Utils,
    private store: Store<{ sharedProgramming: CommonProgrammingContext }>
  ) {
    this.listMode = combineLatest(
      this.store.select(s => s.sharedProgramming.editing),
      this.store.select(s => s.sharedProgramming.adding)
    ).pipe(
      map(([adding, editing]) => !adding && !editing)
    );
  }

  ngOnInit() {

  }

  ngOnChanges() {
    this.disabled = this.isDisabled();
  }

  private showConfirmDelete(state: boolean) {
    this._showConfirmDelete = state;
  }

  confirmDelete(event: MouseEvent) {
    this._logger.debug('confirm delete clicked', event);
    event.stopPropagation();
    console.log('confirming delete for', this.codeItem);
    if (!this.codeItem.protected) {
      this.showConfirmDelete(true);
    }
  }

  toggleClicked(event: MouseEvent) {
    event.stopPropagation();
  }

  deleteCodeItem() {
    this.showConfirmDelete(false);
    this.shared.deleteAction(this.event, this.codeItem);
  }

  toggleCodeitem() {
    this.shared.toggleCodeitemEnabled(this.codeItem, this.event);
  }

  deleteColor = '';

  addDeleteHighlight() {
    this.deleteColor = 'dark-grey';
  }

  removeDeleteHighlight() {
    this.deleteColor = '';
  }

  isDisabled(): boolean {
    return !this.codeItem.enabled || this.anyParentDisabled();
  }

  anyParentDisabled(): boolean {
    return this._isDisabled(this.parent);
  }

  private _isDisabled(item: any): boolean {
    if (!!item && item.hasOwnProperty('enabled')) {
      return !item.enabled || this._isDisabled(item.parent);
    }

    return false;
  }

  isProtected(): boolean {
    return (!!this.codeItem && this.codeItem.hasOwnProperty('protected') && !this.codeItem.protected);
  }
}
