import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { Device } from '../../../common/interfaces/item.interface';
import { SharedProgrammingService } from '../../../common/services/shared-programming.service';
import { ProgrammingUtilsService as Utils } from '../../../common/services/utils.service';
import { EventTypes } from '../event-types/event-types';
import { EventType } from '../event-types/event-type.interface';
import { SimpleProgrammingContext, SIMPLE_ACTIONS } from '../quickstart-simple.service';
import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';
import { Event } from '../../../common/interfaces/event.interface';

@Component({
  templateUrl: './select-event-device.component.html',
  styleUrls: ['./select-event-device.component.less']
})
export class QSSimpleSelectEventDeviceComponent extends BaseRoutingComponent {

  _searchText: string;
  ready: Observable<boolean>;
  _eventType: Observable<EventType>;
  _devices: Observable<Device[]>;

  constructor(
    private _route: ActivatedRoute,
    protected store: Store<{
      simpleProgramming: SimpleProgrammingContext
    }>,
    protected router: Router,
    private _shared: SharedProgrammingService
  ) {
    super();

    this._eventType = this._route.params
      .pipe(
        map((params: { type: string }) => {
          return EventTypes.find(et => et.route == params.type);
        })
      );

    this._devices = combineLatest(
      this._eventType
        .pipe(
          filter(et => !!et),
          map(et => et.whitelist.map(wl => wl.fields['proxy']))
        ),
      this.store.select(s => s.simpleProgramming.devices).pipe(filter(d => !!d)),
      (whitelist, devices) => {
        return devices.filter(device => {
          return whitelist.indexOf(device.proxy) > -1;
        });
      }
    );

    this.ready = this._devices.pipe(map(ds => !!ds));
  }

  cancelClicked() {
    if (this.isForTrigger(this._route)) {
      this.goHome();
    } else {
      this.goToEvent();
    }
  }

  selectDevice(device: Device) {
    this.router.navigate(['../device', device.id], {
      skipLocationChange: true,
      relativeTo: this._route
    })
  }
}
