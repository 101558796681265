import { Pipe, PipeTransform } from '@angular/core';
import { OSCompatibilityService } from '../services/os-compatibility.service';

@Pipe({
  name: 'OSCompatibility'
})
export class OSCompatibilityPipe implements PipeTransform {

  constructor(
    private osCompatibilityService: OSCompatibilityService
  ) { }

  transform(value: any, args?: any): any {
    if (value && Array.isArray(value)) {
      // check OS Compatibility for the voice scene event
      let voiceScene = value.find(item => (item.route && item.name === 'Voice Scene'));
      if (voiceScene) {
        if (!this.osCompatibilityService.isFeatureSupported("event", "voice-scene")) {
          return value.filter(item => {
            return (item.name != 'Voice Scene')
          });
        }
      }
      else { // check OS Compatibility for the custom buttons event
        let customButtons = value.find(item => (item.route && item.name === 'Custom Buttons'));
        if (customButtons) {
          if (!this.osCompatibilityService.isFeatureSupported("event", "custom-buttons")) {
            return value.filter(item => {
              return (item.name != 'Custom Buttons')
            });
          }
        }
      }
    }
    return value;
  }
}
