import { EventType } from './event-type.interface';

export const KEYPAD_BUTTON: EventType = {
  name: 'Button',
  description: 'When a keypad button is tapped',
  route: 'buttons/keypads',
  category: 'Buttons',
  icon: 'keypad',
  order: 2,
  whitelist: [
    {
      fields: { proxy: 'keypad_proxy' },
      events: [5000, 5001, 5002, 5003, 5004]
    },
    {
      fields: { proxy: 'control4_keypad_kpz-11-w' },
      events: [5001, 5002, 5003, 5201, 5202, 5203]
    },
    {
      fields: { proxy: 'control4_keypad_kpz-31-w' },
      events: [5001, 5002, 5003, 5101, 5102, 5103, 5201, 5202, 5203]
    },
    {
      fields: { proxy: 'control4_keypad_kpz-61-w' },
      events: [5001, 5002, 5003, 5101, 5102, 5103, 5201, 5202, 5203, 5301, 5302, 5303, 5401, 5402, 5403, 5501, 5502, 5503]
    },
    {
      fields: { proxy: 'knx_keypad' },
      events: [3, 6, 9, 12, 15, 18, 21, 24]
    }
  ]
}
