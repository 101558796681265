import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, ReplaySubject, BehaviorSubject, combineLatest, of } from 'rxjs';
import { filter, concatMap, map, take, tap, switchMap, distinctUntilKeyChanged, shareReplay } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { BrokerService as Broker, UrlFactoryService } from '@when-then/core';

import { ItemsService } from '../../common/services/items.service';
import { SharedProgrammingService } from '../../common/services/shared-programming.service';
import { Device } from '../../common/interfaces/item.interface';
import { Event } from '../../common/interfaces/event.interface';
import { QuickstartProgrammingState } from '../quickstarts.stores';
import { VOICE_SCENE } from '../quickstart-simple/event-types/voice-scene.type';

export interface VoiceScene {
  $t: string,
  off: number,
  off_prohibited: boolean,
  on: number,
  on_prohibited: boolean
}

export interface ListScenesResponse {
  name: string,
  seq: string,
  result: number,
  scenes: {
    scene: VoiceScene[]
  }
}

@Injectable()
export class VoiceScenesService {

  private _driver: Observable<Device> = this._store.select(s => s.programmingItems.itemsList)
    .pipe(
      map(items => <Device[]>items.filter(item => item.typeName !== undefined)),
      map(devices => devices.find(d => !!d.proxy && d.proxy == 'voice-scene')),
      shareReplay(1)
    );

  // Because we can't subscribe directly to
  // voice scenes, we need to keep track of
  // when a request has been performed so
  // we may provide a functional observable
  // to consumers
  private _refreshRequired: ReplaySubject<null> = new ReplaySubject();
  private _scenesObservable: Observable<VoiceScene[]>;

  public events: Observable<Event[]>;

  constructor(
    private _store: Store<QuickstartProgrammingState>,
    private _http: HttpClient,
    private _broker: Broker,
    private _url: UrlFactoryService,
    private _shared: SharedProgrammingService
  ) {
    this.events = this._driver
      .pipe(
        filter(d => !!d),
        switchMap(driver => this._broker.getObservable<Event[]>({
          path: `/api/v1/items/${driver.id}/events`,
          queryString: {
            allEvents: 'true'
          }
        }))
      );

    this._scenesObservable = this._refreshRequired
      .pipe(
        switchMap(n => {
          return this.getScenes();
        }),
        shareReplay(1)
      );

      // EL - looks like this keeps the filterProhibitedEvents from breaking...
    this._driver.subscribe(d => this._refreshRequired.next(null));

    // We need to refresh the list of scenes when
    // the driver flags things as prohibited (or
    // not), and we can't subscribe directly to the
    // command. Just watch for UPDATE_PROPERTY events
    // to come across the wire and emit refreshRequired.
    this._driver.pipe(
      filter(d => !!d),
      distinctUntilKeyChanged('id'),
      switchMap(driver => {
        return this._broker.getObservable({
          path: `/api/v1/items/${driver.id}/datatoui`,
          queryString: {
            messagefilterq: {
              "data.devicecommand.command": {
                $eq: "UPDATE_PROPERTY"
              }
            }
          }
        })
      })
    ).subscribe(dui => this._refreshRequired.next(null));
  }

  public async createVoiceScene(name: string): Promise<Event[]> {
    await this._sendCommand('Add Voice Scene', {
      'Scene Name': name
    })
      .toPromise();

    const createdEvents = await this._store.select(s => s.programmingEvents.allEvents).pipe(
      map(events => events.filter(evt => evt.name == `Turn On ${name}` || evt.name == `Turn Off ${name}`)),
      filter(events => !!events && events.length == 2),
      take(1)
    ).toPromise();

    this._refreshRequired.next(null);

    return createdEvents;
  }

  public async renameVoiceScene(oldName: string, newName: string): Promise<Event[]> {
    await this._sendCommand('Rename Voice Scene', {
      'Scene Name': oldName,
      'New Scene Name': newName
    }).toPromise();

    const renamedEvents = await this._store.select(s => s.programmingEvents.allEvents).pipe(
      map(events => events.filter(evt => evt.name == `Turn On ${newName}` || evt.name == `Turn Off ${newName}`)),
      filter(events => !!events && events.length == 2),
      take(1)
    ).toPromise();

    this._refreshRequired.next(null);

    return renamedEvents;
  }

  public async deleteVoiceScene(name: string): Promise<boolean> {
    await this._sendCommand('Delete Voice Scene', {
      'Scene Name': name
    }).toPromise();

    const deleted = await this._store.select(s => s.programmingEvents.allEvents).pipe(
      map(events => events.filter(evt => evt.name == `Turn On ${name}` || evt.name == `Turn Off ${name}`)),
      filter(events => !!events && events.length == 0),
      map(events => true),
      take(1)
    ).toPromise();

    this._refreshRequired.next(null);

    return deleted;
  }

  public async getScenes(): Promise<VoiceScene[]> {
    return this._driver
      .pipe(
        switchMap(driver => {

          return !!driver
            ? this._sendCommand<ListScenesResponse>('List Voice Scenes')
              .pipe(
                map(r => r.scenes ? r.scenes.scene : [])
              )
            : of([])
        }),
        take(1)
      )
      .toPromise();
  }

  public getScenesObservable(): Observable<VoiceScene[]> {
    //this._refreshRequired.next(null);
    return this._scenesObservable;
  }

  public async getEventId(sceneName: string, onOff: 'on' | 'off'): Promise<number> {
    return this._scenesObservable
      .pipe(
        filter(scenes => scenes.findIndex(s => s.$t == sceneName) > -1),
        map(scenes => scenes.find(scene => scene.$t == sceneName)[onOff]),
        take(1)
      )
      .toPromise();
  }

  public async getEvents(sceneName: string): Promise<Event[]> {
    return await this.events
      .pipe(
        map(events => events.filter(event => {
          return event.name == "Turn On " + sceneName ||
            event.name == "Turn Off " + sceneName
        })),
        take(1)
      )
      .toPromise();
  }

  public async getDriverId(): Promise<number> {
    return this._driver
      .pipe(
        map(d => d.id),
        take(1)
      )
      .toPromise();
  }

  public filterProhibitedEvents(events: Event[] = []): Observable<Event[]> {
    //this._refreshRequired.next(null);

    return combineLatest(
      this._driver,
      this._scenesObservable
    )
      .pipe(
        map(([driver, scenes]) => {

          // If the voice scene driver
          // isn't installed, or we
          // have no configured voice
          // scenes, return as quickly
          // as possible
          if (
            !driver
            || !scenes
            || scenes.length == 0
          ) {
            return events;
          }

          return events.filter(event => {

            // If we're not dealing with a
            // voice scene, we're done
            if (event.deviceId != driver.id) {
              return true;
            }

            // If the voice scene driver
            // isn't new enough, no
            // event for you
            if (driver.version < VOICE_SCENE.whitelist.find(w => w.fields['proxy'] == 'voice-scene').minVersion) {
              return false;
            }

            // The "any voice scene recieved" event
            // needs to be passed directly through
            if (event.eventId == 1) {
              return true;
            }

            const scene = scenes.find(s => s.on == event.eventId || s.off == event.eventId);

            const onOff = event.name.indexOf("Turn On ") == 0 ? 'on' : 'off';

            // Under certain inscrutible conditions, newly-created scenes
            // may not be immediately available.
            return !!scene ? !scene[`${onOff}_prohibited`] : true;
          });
        })
      )
  }

  private _sendCommand = <T>(command: string, tParams?: { [key: string]: string }): Observable<T> => {
    return this._driver
      .pipe(
        filter(d => !!d),
        switchMap(driver => <Promise<T>>this._broker.call({
          path: `/api/v1/items/${driver.id}/commands`,
          method: 'POST',
          data: {
            command: command,
            tParams: tParams,
            async: false
          }
        })
        ),
        take(1)
      );
  }
}
