import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'deviceLocation'
})
export class DeviceLocationPipe implements PipeTransform {
  transform(device: any): string {
    // console.log('getting device location for', device);
    if (device) {
      return device.floorName + ' ' + String.fromCharCode(8250) + ' ' + (device.roomName || device.name);
    } else {
      return '';
    }
  }
}
