<div class="whats-new">
  <cxScrollable>
    <section class="first">
      <h3 class="fg-white shadowed">Get to know the</h3>
      <h1 class="fg-white shadowed">NEWEST FEATURES OF WHEN &gt;&gt; THEN</h1>

      <div class="aside">
        <div class="fg-white shadowed title">What's New</div>
        <p class="fg-white shadowed">Add time, and time delays to make your automations more specific.</p>
      </div>
    </section>
    <section class="bg-blue second">
      <span>Ready to try it? </span>
      <button [routerLink]="['../events']" class="huge bg-white fg-blue">Go to When &gt;&gt; Then</button>
    </section>
    <section class="bg-darkgrey third">
      <h5 class="fg-blue">New Features</h5>
      <h2 class="fg-white">ADD TIME TO YOUR AUTOMATIONS TO MAKE THEM MORE POWERFUL</h2>
      <div class="flex-row row transparent nowrap justify-center">
        <img src="../../../assets/images/wn-new-1.png" alt="add time to your automations" width="350" height="206">
        <div>
          <p>The latest update to When >> Then gives you even more ways to personalize your Control4 Smart Home with
            the ability to add time to your automations. You can specify THEN events that happen only when certain time
            criteria is met, such as day time or between 11 p.m. and 6 a.m.</p>
          <ul>
            <li>If it is day time,</li>
            <li>If it is between 6 a.m. and 10 p.m.,</li>
            <li>If it is a time relative to sunrise or sunset</li>
          </ul>
        </div>
      </div>
      <h3 class="fg-blue">Examples</h3>
      <div class="wt-header flex-row  transparent nowrap">
        <span class="title grow"><strong>When</strong> this happens...</span>
        <span class="title grow"><strong>Then</strong> do this.</span>
      </div>
      <div class="example">
        <div class="flex-row padded transparent nowrap event grow">
          <span class="icon blue garage"></span>
          <span class="label grow">When the Garage Door is open</span>
        </div>
        <div class="delta trailing"></div>
        <div class="actions grow">
          <div class="flex-row padded nowrap action">
            <span class="icon blue conditional"></span>
            <span class="label">If time is night time</span>
            <div class="callout">
              <div class="delta orange leading"></div>
              <span class="icon white small time"></span>
              <span class="label">Specify a time range</span>
            </div>
          </div>
          <div class="flex-row padded nowrap action offset">
            <span class="icon blue lightbulb"></span>
            <span class="label">Turn on Garage Hallway Inside > Garage Light</span>
          </div>
          <div class="flex-row padded nowrap action">
            <span class="icon blue keypad"></span>
            <span class="label">Use the on color for Garage Light</span>
          </div>
        </div>
      </div>
    </section>
    <section class="bg-black fourth">
      <h5 class="fg-blue">New Features</h5>
      <h2 class="fg-white">INCLUDE A TIME DELAY</h2>
      <h4 class="fg-orange">Now you can get more specific with your automations</h4>
      <div class="flex-row  transparent nowrap justify-center">
        <div class="left">
          <p class="fg-lightgrey">Delays are another new feature in When >> Then, giving you the ability to specify
            THEN reactions that
            occur seconds, minutes, or even hours after the WHEN action.</p>
          <div class="fg-orange">Choose your action, but specify</div>
          <ul>
            <li>Wait 90 seconds</li>
            <li>Wait 4 minutes</li>
            <li>Wait 120 minutes</li>
          </ul>
        </div>
        <div class="right">
          <img src="../../../assets/images/wn-new-2.png" alt="include a time delay" width="303" height="212">
        </div>
      </div>
      <div>
        <h5 class="fg-blue">Examples</h5>
        <div class="wt-header flex-row  transparent nowrap">
          <span class="title grow"><strong>When</strong> this happens...</span>
          <span class="title grow"><strong>Then</strong> do this.</span>
        </div>
        <div class="example">
          <div class="flex-row  transparent nowrap event grow">
            <span class="icon blue keypad"></span>
            <span class="label grow">When the Office &gt; Keypad Button 4 is double tapped</span>
          </div>
          <div class="delta trailing"></div>
          <div class="actions grow">
            <div class="flex-row padded nowrap action">
              <span class="icon blue room"></span>
              <span class="label">Turn Off all Audio/Video in Basement &gt; Office</span>
            </div>
            <div class="flex-row padded nowrap action">
              <span class="icon blue intervals"></span>
              <span class="label">Delay 30 seconds</span>
              <div class="callout">
                <div class="delta orange leading"></div>
                <span class="icon white small time"></span>
                <span class="label">Specify a time delay</span>
              </div>
            </div>
            <div class="flex-row padded nowrap action">
              <span class="icon blue lightbulb"></span>
              <span class="label">Turn Off all Office &gt; Office Floor Lamp</span>
            </div>
            <div class="flex-row padded nowrap action">
              <span class="icon blue intervals"></span>
              <span class="label">Delay 30 seconds</span>
              <div class="callout">
                <div class="delta orange leading"></div>
                <span class="icon white small time"></span>
                <span class="label">Specify a time delay</span>
              </div>
            </div>
            <div class="flex-row padded nowrap action">
              <span class="icon blue lightbulb"></span>
              <span class="label">Turn off all Office &gt; Office Lights</span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="bg-blue fifth">
      <h4 class="fg-white">Ready to try it?</h4>
      <button [routerLink]="['../events']" class="huge fg-blue bg-white">View My When &gt;&gt; Then Automations</button>
    </section>
  </cxScrollable>

</div>
