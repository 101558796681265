<cxWait [cxUntil]="!!(_scene|async)" cxText="Loading scene actions...">
  <div>
    <prg-nav-header title="What do you want to do with the '{{(_scene | async)?.name}}' lighting scene?" (cancel)="goToEvent()"></prg-nav-header>

    <prg-block-button cxNoDoubleClick (click)="addCommand('ACTIVATE_SCENE')" leadingIcon="blue lightscene" title="Activate '{{(_scene | async)?.name}}'"
      trailingIcon="right"></prg-block-button>

    <prg-block-button cxNoDoubleClick (click)="addCommand('DEACTIVATE_SCENE')" leadingIcon="blue lightscene" title="Deactivate '{{(_scene | async)?.name}}'"
      trailingIcon="right"></prg-block-button>

  </div>
</cxWait>
