import { ActionReducerMap } from '@ngrx/store';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';

import { CORE_STORE, CoreState } from '@when-then/core';
import { AUTH_REDUCERS, AuthState } from '@when-then/auth';
import { PROGRAMMING_REDUCERS, ProgrammingState } from '../app/programming.stores';

export interface AppState extends
  CoreState,
  AuthState,
  ProgrammingState {
  router: RouterReducerState
}

export const APP_REDUCERS: ActionReducerMap<AppState> = {
  ...CORE_STORE,
  ...AUTH_REDUCERS,
  ...PROGRAMMING_REDUCERS,
  router: routerReducer
};
