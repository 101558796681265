import { ActionReducerMap } from '@ngrx/store';

import { customButtonsProgrammingReducer, CustomButtonsProgrammingContext } from './services/custom-buttons.service';

export interface QuickstartCustomButtonsState {
  customButtonsProgramming: CustomButtonsProgrammingContext
}

export const QUICKSTART_CUSTOM_BUTTONS_REDUCERS: ActionReducerMap<QuickstartCustomButtonsState> = {
  customButtonsProgramming: customButtonsProgrammingReducer
};
