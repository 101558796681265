import { LoggerFactory } from './../../../../../../../libs/core/src/lib/services/log.service';
import { CodeItemType } from './../../../common/interfaces/item.interface';
import { Command } from './../../../common/interfaces/command.interface';
// angular
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// libs
import { Observable, Subscription } from 'rxjs';
import { filter, map, distinctUntilChanged, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

// C4 libs
import { BrokerService } from '@when-then/core';

// common
import { ItemsService } from './../../../common/services/items.service';
import { Event } from './../../../common/interfaces/event.interface';
import { ProgrammingUtilsService as Utils, CW_CREATOR_ID } from '../../../common/services/utils.service';
import { SharedProgrammingService, CommandData, CommonProgrammingContext } from '../../../common/services/shared-programming.service';
import { Parameter, ValueType } from '../../../common/interfaces/parameter.interface';
import { DeviceEvent } from '../../../common/interfaces/event.interface';

// module
import { Light, LightSceneRef, LightSceneDetail } from './interfaces/light.interface';
import { Room } from './interfaces/room.interface';
import { Button } from './interfaces/button.interface';
import { Item, Device } from '../../../common/interfaces/item.interface';
import { ItemsState } from '../../../common/services/items.service';
import { AGENTS } from '../../../common/services/agent-meta.service';

/**
 * List of commands supported by
 * the lights quickstart
 *
 * @type {Array<string>}
 */
const COMMAND_WHITELIST = ['ON', 'OFF', 'TOGGLE', 'SET_LEVEL'];

/**
 * List of events supported by
 * the lights quickstart
 *
 * @type {Array<number>}
 */
export const EVENT_WHITELIST = [
  1,    // push top
  2,    // release top
  3,    // push bottom
  4,    // release bottom
  // 5,    // change level
  // 5000, // change level
  5001, // single tap top
  5002, // double tap top
  5003, // triple tap top
  5004, // double tap top
  5005, // triple tap top
  5006, // release bottom
  5007, // press bottom
  5008, // single tap bottom
  5009, // double tap bottom
  5010, // triple tap bottom
  // 5011, // release toggle
  // 5012, // press toggle
  // 5013, // single tap toggle
  // 5014, // double tap toggle
  // 5015, // triple tap toggle
  5101, // single tap bottom
  5102, // double tap bottom
  5103, // triple tap bottom
];

/**
 * Pulled from Broker
 * @type {Array<string>}
 */
const LIGHT_PROXIES = [
  'light_v2',
  'light',
  'fan',
  'outlet_wireless',
  'outlet_light',
  'outlet_switch_module',
  'outlet_dimmer_module',
  'din_rail_2_panel',
  'din_rail_5_panel',
  'din_rail_8_channel_dimmer_module',
  'din_rail_8_channel_0-10V_module',
  'din_rail_8_port_ethernet_switch ',
  'din_rail_8_channel_relay_module'
];

export interface LightsProgrammingContext {
  lights: Light[];
  trigger?: Trigger;
  action?: Action;

  roomScenes?: LightSceneRef[];
  initialized: boolean;
}

interface Trigger {
  light?: Light;
  events?: DeviceEvent[];
  button?: Button;
  event?: DeviceEvent;
}

interface Action {
  light?: Light;
  commands?: Command[];
  command?: CommandData;
  scene?: LightSceneDetail;
  sceneCommand?: CommandData;
}

const INITIAL_STATE: LightsProgrammingContext = {
  lights: [],
  trigger: {},
  action: {},
  initialized: false
}

const STORE_NAME = ['PROGRAMMING', 'QUICKSTARTS', 'LIGHTS'].join(':');

export const ACTIONS = {
  SET_LIGHTS: `${STORE_NAME}:SET_LIGHTS`,
  SET_TRIGGER: `${STORE_NAME}:SET_TRIGGER`,
  SET_EVENTS: `${STORE_NAME}:SET_EVENTS`,
  SET_BUTTON: `${STORE_NAME}:SET_BUTTON`,
  SET_EVENT: `${STORE_NAME}:SET_EVENT`,
  SET_ACTION: `${STORE_NAME}:SET_ACTION`,
  SET_COMMANDS: `${STORE_NAME}:SET_COMMANDS`,
  SET_COMMAND: `${STORE_NAME}:SET_COMMAND`,
  SET_COMMAND_VALUES: `${STORE_NAME}:SET_COMMAND_VALUES`,

  SET_ROOM_SCENES: `${STORE_NAME}:SET_ROOM_SCENES`,
  SET_SCENE: `${STORE_NAME}:SET_SCENE`,
  SET_SCENE_COMMAND: `${STORE_NAME}:SET_SCENE_COMMAND`,
  SET_INITIALIZED: `${STORE_NAME}:SET_INITIALIZED`,
}

const ACTION_STRINGS = Object.keys(ACTIONS).map(key => ACTIONS[key]);

export function lightsProgrammingReducer(state: LightsProgrammingContext = INITIAL_STATE, { type, payload }): LightsProgrammingContext {

  // if (ACTION_STRINGS.indexOf(type) > -1) {
  // console.info('%s called with %O', type, payload);
  // }

  switch (type) {
    case ACTIONS.SET_SCENE_COMMAND:
      return { ...state, ...{ action: { ...state.action, ...{ sceneCommand: payload } } } };
    case ACTIONS.SET_ROOM_SCENES:
      return Object.assign({}, state, { roomScenes: payload });
    case ACTIONS.SET_SCENE:
      const newState = {
        ...state,
        ...{
          action: { ...state.action, ...{ scene: payload } }
        }
      };
      console.log('after setting scene, state is ', newState);
      return newState;
    case ACTIONS.SET_INITIALIZED:
      return Object.assign({}, state, { initialized: payload });

    case ACTIONS.SET_LIGHTS:
      return Object.assign({}, state, { lights: payload });

    case ACTIONS.SET_TRIGGER:
      let t1 = Object.assign({}, state.trigger, { light: payload });
      return Object.assign({}, state, { trigger: t1 });

    case ACTIONS.SET_EVENTS:
      let t2 = Object.assign({}, state.trigger, { events: payload });
      return Object.assign({}, state, { trigger: t2 });

    case ACTIONS.SET_BUTTON:
      let t3 = Object.assign({}, state.trigger, { button: payload });
      return Object.assign({}, state, { trigger: t3 });

    case ACTIONS.SET_EVENT:
      let t4 = Object.assign({}, state.trigger, { event: payload });
      return Object.assign({}, state, { trigger: t4 });

    case ACTIONS.SET_ACTION:
      let a1 = Object.assign({}, state.action, { light: payload });
      return Object.assign({}, state, { action: a1 });

    case ACTIONS.SET_COMMANDS:
      // console.log('lights: setting commands', payload);
      let a2 = Object.assign({}, state.action, { commands: payload });
      return Object.assign({}, state, { action: a2 });

    case ACTIONS.SET_COMMAND:
      let a3 = Object.assign({}, state.action, { command: payload });
      return Object.assign({}, state, { action: a3 });

    case ACTIONS.SET_COMMAND_VALUES:
      let commands: Command[] = [...state.action.commands];
      let commandIx = commands.findIndex(c => {
        return c.command === payload.command.command &&
          c.id === payload.command.id
      });

      commands[commandIx].params = [...payload.params];

      return Object.assign({}, state, { action: Object.assign({}, state.action, { commands: commands }) });

    default:
      return state;
  }
}

@Injectable()
export class LightsService {

  private _logger = LoggerFactory.getLogger(LightsService.name);
  private _lights: Observable<Light[]>;

  /**
   * Lights with whitelisted events
   */
  public triggerLights: Observable<Light[]>;

  /**
   * Lights with whitelisted commands
   */
  public actionLights: Observable<Light[]>;

  // TOOD re-evalute this approach
  // NOTE when saving a lighting scene command it is not safe to assume that the trigger is
  // always a light, in fact in many cases it will not be.  The shared programming service
  // must own the selected trigger because some triggers require pre-processing before the
  // codeitem (command) can be saved (i.e., schedules), probably need to examine whether or
  // not the lights service really needs to own the selected trigger here.
  private _trigger: Observable<Trigger>;
  private _triggerDevice: Observable<any>;
  private _triggerEvent: Observable<DeviceEvent>;

  private _action: Observable<Action>;
  private _actionCommand: Observable<CommandData>;

  private _sharedTriggerEvent: Observable<Event>;

  /**
   * Convenience observables that group
   * lights by the room they belong to,
   * and appends the appropriate Room
   * parameters
   */
  public lightsByRoom: Observable<Room[]>;
  public triggerLightsByRoom: Observable<Room[]>;
  public actionLightsByRoom: Observable<Room[]>;

  public roomScenes: Observable<LightSceneRef[]>;
  public scene: Observable<LightSceneDetail>;
  public initialized: Observable<boolean>;

  constructor(
    private _broker: BrokerService,
    private _store: Store<{
      lightsProgramming: LightsProgrammingContext,
      sharedProgramming: CommonProgrammingContext,
      programmingItems: ItemsState
    }>,
    private _shared: SharedProgrammingService,
    private _items: ItemsService,
    private _router: Router
  ) {

    this.roomScenes = this._store.select(s => s.lightsProgramming.roomScenes);
    this.scene = this._store.select(s => s.lightsProgramming.action.scene);
    this.initialized = this._store.select(s => s.lightsProgramming.initialized);

    // this._lights = this._store.select(s => s.lightsProgramming.lights);
    this._lights = <Observable<Light[]>>this._items.itemsList
      .pipe(map(items => {
        return items.filter((item: Device) => {
          return (LIGHT_PROXIES.indexOf(item.proxy) > -1 && item.type === 7)
        });
      })
      );

    this.lightsByRoom = <Observable<Room[]>>this._byRoom(this._lights);

    this.triggerLights = this._lights
      .pipe(map(lights => {
        return lights.filter(light => {
          // HACK silly me, assuming that event ids would be represented consistently (id not eventId)
          return light.events && light.events.some((e: any) => EVENT_WHITELIST.indexOf(e.id) > -1);
        })
      })
      );
    this.triggerLightsByRoom = this._byRoom(this.triggerLights);

    this.actionLights = this._lights.pipe(map(lights => lights.filter((light: Light) => {
      // console.log('lights: filtering light for actions', light);
      return light.commands && light.commands.command.some(c => COMMAND_WHITELIST.indexOf(c.name) > -1);
    }))
    );
    this.actionLightsByRoom = this._byRoom(this.actionLights);

    this._trigger = this._store.select(s => s.lightsProgramming.trigger);
    this._triggerEvent = <Observable<DeviceEvent>>this._trigger
      .pipe(
        map(t => (!!t) ? t.event : undefined),
        distinctUntilChanged(Utils.distinct)
      );
    this._triggerDevice = this._store.select(s => s.lightsProgramming.trigger.light)
      .pipe(
        filter(l => !!l),
        distinctUntilChanged(Utils.distinct)
      );

    this._action = this._store.select(s => s.lightsProgramming.action);
    this._actionCommand = this._action
      .pipe(
        filter(a => !!a),
        map((a: any) => a.command)
      );

    this._sharedTriggerEvent = this._store.select(s => s.sharedProgramming.trigger.event);

    this._monitorAction();
    this._monitorTrigger();
  }

  saveTrigger = (): Promise<any> => {
    return new Promise((resolve, reject) => resolve());
  }

  setTrigger(event: Event) {
    this._store.dispatch({ type: ACTIONS.SET_EVENT, payload: event });
  }

  setTriggerLight(l: Light | number): void {
    // console.log('lights.settrigger: ', l);
    if (typeof l == 'number') {
      this._getLightById(l)
        .pipe(
          filter(l => !!l),
          take(1)
        )
        .subscribe(l => this._store.dispatch({
          type: ACTIONS.SET_TRIGGER,
          payload: l
        }))
    } else {
      this._store.dispatch({
        type: ACTIONS.SET_TRIGGER,
        payload: l
      });
    }
  }

  setTriggerButton(button: Button): void {
    this._store.dispatch({ type: ACTIONS.SET_BUTTON, payload: button });
  }

  setTriggerEvent(event: DeviceEvent): void {
    this._router.navigate(['../../device', event.deviceId, 'event', event.eventId, 'add'], { relativeTo: this._shared.moduleRoot });
  }

  setActionLight(l: Light | number): void {
    // console.log('lights.setaction', l);
    if (typeof l == 'number') {
      this._getLightById(l)
        .pipe(
          filter(l => !!l),
          take(1)
        )
        .subscribe(l => {
          // console.log('lights.setaction: found light for id', l);
          this._store.dispatch({
            type: ACTIONS.SET_ACTION,
            payload: l
          })
        })
    } else {
      this._store.dispatch({
        type: ACTIONS.SET_ACTION,
        payload: l
      });
    }
  }

  setCommand(command: Command): CommandData {

    if (!!command.params) {
      // Hidden params will have a value set, but
      // no _value. Set it.
      command.params.filter(p => !!p && p.type === 'HIDDEN').forEach(p => {
        p._value = p._value || p.value;
      });
    }

    // TODO this bypasses the central sharedProgramming.action.command store and
    // introduces special cases, there should be ONE source of the truth
    this._store.dispatch({ type: ACTIONS.SET_COMMAND, payload: command });

    // NOTE this is required to allow the shared service to save the inner action
    // after the conditoinal is saved, there should only be one implementation of
    // this anyway
    const data = Utils.buildCommandData(CodeItemType.Command, command);
    this._shared.setPendingAction(command, data);

    return data;
  }

  updateParam = (evt: ValueType, param: Parameter, command: Command) => {
    let newParam: Parameter = Object.assign({}, param, {
      _value: evt.value,
      _display: evt.display
    });

    let params = [...command.params];

    let pix = params.findIndex(p => p.name === newParam.name && p.type === newParam.type);

    params[pix] = newParam;

    this.updateParams(params, command);
  }

  updateParams = (params: Parameter[], command: Command) => {
    this._store.dispatch({
      type: ACTIONS.SET_COMMAND_VALUES,
      payload: {
        params: params,
        command: command
      }
    })
  }

  public getScene(id: number) {
    this._broker.call({
      path: '/api/v1/agents/advanced_lighting/' + id
    }).then(res => {
      this._store.dispatch({ type: ACTIONS.SET_SCENE, payload: res });
    }, err => {
      console.error('lights.service.ts: error: %0', err);
    });
  }

  public setSceneCommand(name: string) {
    let agent = this._findSceneAgent()
    console.log('advanced lighting agent is', agent);
    if (!!agent) {
      this._store.dispatch({ type: ACTIONS.SET_COMMAND, payload: name });

      const event = Utils.snapshot(this._store.select(s => s.sharedProgramming.trigger.event));
      const scene = Utils.snapshot(this._store.select(s => s.lightsProgramming.action.scene));

      if (!!event && !!scene) {

        // NOTE fake a command since we didn't pass a real command around
        const disp = name.startsWith('DEACTIVATE') ? `Deactivate lighting scene "${scene.name}"` : `Activate lighting scene "${scene.name}"`;
        const command: Command = {
          deviceId: agent.id,
          command: name,
          params: [{
            name: 'SCENE_ID',
            type: 'INT',
            value: scene.scene_id
          }],
          label: disp,
          display: disp
        };

        let codeitem = Utils.buildCommandData(CodeItemType.Command, command);
        codeitem.creatorState = Utils.buildCreatorState(event, codeitem);

        // NOTE have to set this in both places so the shared code can
        // save a conditinal action if necessary
        this._shared.setPendingAction(command, codeitem);
        this._store.dispatch({ type: ACTIONS.SET_SCENE_COMMAND, payload: codeitem });
      }
    }
  }

  private _findSceneAgent(): Item {
    const items = <Device[]>Utils.snapshot(this._store.select(s => s.programmingItems.itemsList));
    return items.find(itm => itm.control === AGENTS.ADVANCED_LIGHTING.CONTROL);
  }

  /**
   * Monitors the action in the
   * store, and sets commands as
   * it changes
   */
  private _monitorAction = () => {
    // console.log('lights: monitoring action ');
    this._action
      .pipe(
        filter(a => !!a),
        map((a: any) => {
          // console.log('lights: light changed', a);
          return (!!a.light && !!a.light.URIs && !!a.light.URIs.commands) ?
            a.light.URIs.commands :
            undefined;
        }),
        distinctUntilChanged<string>(Utils.distinct)
      )
      .subscribe(
        uri => {
          // console.log('lights: light uri is', uri);
          if (uri === undefined) {
            this._store.dispatch({ type: ACTIONS.SET_COMMANDS, payload: undefined });
          } else {
            // console.log('lights: getting commands for light', uri);
            this._broker.call({
              path: uri
            }).then(
              (commands: Command[]) => {
                // console.log('lights: whitelisting light commands', commands);
                let whiteListed = commands.filter(c => COMMAND_WHITELIST.indexOf(c.command) > -1);
                this._store.dispatch({ type: ACTIONS.SET_COMMANDS, payload: whiteListed });
              },
              error => console.error('lights.service.ts: error: %O', error)
            );
          }
        },
        error => console.error('lights.service.ts: error: %O', error)
      );
  }

  private _monitorTrigger = () => {
    // console.log('lights: monitoring trigger');
    this._triggerDevice
      .pipe(
        filter(t => !!t),
        distinctUntilChanged<Device>(Utils.distinct)
      )
      .subscribe(light => {
        // console.log('lights: getting events for trigger device', light);
        this._broker.call({
          path: ['/api/v1/items', light.id, 'events'].join('/'),
          queryString: {
            allevents: true
          }
        }).then(res => {
          this._store.dispatch({ type: ACTIONS.SET_EVENTS, payload: res });
        }, err => {
          console.error('lights: error getting events for trigger light', err);
        });
      });
  }

  private _getLightById = (id: number): Observable<Light> => {
    return this._lights.pipe(map(lights => lights.find(l => l.id === id)));
  }

  private _byRoom = (lightsObservable: Observable<Light[]>): Observable<Room[]> => {
    return lightsObservable.pipe(
      map(lights => lights.reduce((rooms: Room[], light) => {
        let room = rooms.find(r => r.roomId === light.roomId);

        if (room === undefined) {
          room = {
            roomName: light.roomName,
            roomId: light.roomId,
            devices: []
          }

          rooms.push(room);
        }

        room.devices.push(light);

        return rooms;
      }, []))
    );
  }
}
