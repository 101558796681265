import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'deviceName'
})
export class DeviceNamePipe implements PipeTransform {
  transform(device: any): string {
    if (!!device) {
      return (!!device.defaultProxy) ? device.defaultProxy.name : device.name;
    } else {
      return 'UNKNOWN';
    }
  }
}
