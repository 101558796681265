import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { QSRoomsComponent } from './quickstart-rooms.component';

import { SelectRoomComponent } from './select-room/select-room.component';
import { SelectParamsComponent } from './select-params/select-params.component';
import { CONDITIONAL_ROUTES } from '../quickstart-flow-control/flow-control.routes';

export const QUICKSTART_TRIGGERS_ROOMS_ROUTES: Routes = [
  {
    path: 'rooms',
    component: QSRoomsComponent,
    children: [
      {
        path: ':eventId',
        component: SelectRoomComponent
      },
      {
        path: ':eventId/:roomId',
        component: SelectParamsComponent
      }
    ]
  }
];

export const QUICKSTART_ACTIONS_ROOMS_ROUTES: Routes = [
  {
    path: 'rooms',
    component: QSRoomsComponent,
    children: [
      {
        path: ':command',
        component: SelectRoomComponent
      },
      {
        path: ':command/conditionals',
        children: [...CONDITIONAL_ROUTES]
      },
      {
        path: ':command/:roomId',
        component: SelectParamsComponent
      },
      {
        path: ':command/:roomId/conditionals',
        children: [...CONDITIONAL_ROUTES]
      }
    ]
  }
];
