import { StreamingService } from './interfaces/streaming.interface';

import { Deezer } from './deezer';
import { Pandora } from './pandora';
import { Tidal } from './tidal';
import { Spotify } from './spotify';
import { IHeartRadio } from './iheartradio';
import { Amazon } from './amazon';

import { SiriusXM } from './siriusxm';
import { SoundMachine } from './soundmachine';
import { Napster } from './napster';
import { RadioDotCom } from './radiodotcom';

export const StreamingServices: StreamingService[] = [
  Deezer,
  Pandora,
  Tidal,
  Spotify,
  IHeartRadio,
  SiriusXM,
  // Napster,
  // SoundMachine,
  // RadioDotCom
  // Amazon
];
