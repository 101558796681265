import { Store } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { StorageService } from '@when-then/core';
import { BaseRoutingComponent } from '../../common/base-routing/base-routing.component';

@Component({
  selector: 'prg-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.less']
})
export class WelcomeComponent extends BaseRoutingComponent implements OnInit {

  constructor(
    private storage: StorageService,
    protected router: Router,
    protected store: Store<any>,
    private route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit() {
  }
}
