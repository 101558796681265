<div class="modal" (click)="modalClicked($event)">
  <section>
    <header>
      <h4 class="grow">{{title}}</h4>
    </header>
    <div class="content">
      <ng-content select="[message]"></ng-content>
    </div>
    <footer>
      <button (click)="cancelClicked($event)" [disabled]="disabled">Cancel</button>
      <button [ngClass]="confirmClass" (click)="confirmClicked($event)" [disabled]="disabled">{{confirmLabel}}</button>
    </footer>
  </section>
</div>
