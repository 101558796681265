/**
 * Copyright 2017 Control4 Corporation. All rights reserved.
 * Control4 Confidential and Proprietary Information.
 */

import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AuthModule } from '@when-then/auth';
import { UIModule } from '@when-then/ui';
import { CoreModule } from '@when-then/core';

import { ProgrammingCommonModule } from '../common/common.module';
import { QuickstartSecuritySystemModule } from './quickstart-security-system/quickstart-security-system.module';
import { QuickstartNotificationsModule } from './quickstart-notifications/notifications.module';
import { QuickstartLEDColorsModule } from './quickstart-led-colors/led-colors.module';
import { QuickstartTimersModule } from './quickstart-timers/timers.module';
import { QuickstartLightsModule } from './quickstart-lights/quickstart-lights.module';
import { QuickstartScheduleModule } from './quickstart-schedule/schedule-quickstart.module';
import { QuickstartButtonsModule } from './quickstart-buttons/buttons.module';
import { QuickstartSimpleModule } from './quickstart-simple/quickstart-simple.module';
import { QuickstartRoomsModule } from './quickstart-rooms/quickstart-rooms.module';
import { QuickstartMediaModule } from './quickstart-media/quickstart-media.module';
import { QuickstartThermostatModule } from './quickstart-thermostats/quickstart-thermostats.module';
import { VoiceScenesModule } from './quickstart-voice-scenes/voice-scenes.module';

import { WhenThenComponent } from './when-then/when-then.component';
import { QuickstartsComponent } from './quickstarts.component';
import { EventComponent } from './event/event.component';
import { AddComponent } from './add/add.component';
import { SelectActionComponent } from './select-action/select-action.component';
import { QuickstartProgrammingService } from './services/quickstart-programming.service';
import { QuickstartSensorsModule } from './quickstart-sensors/quickstart-sensors.module';
import { SelectEventTypeComponent } from './select-event-type/select-event-type.component';
import { ListByRoomComponent } from './list-by-room/list-by-room.component';
import { ListByTypeComponent } from './list-by-type/list-by-type.component';
import { ListByNameComponent } from './list-by-name/list-by-name.component';
import { EditComponent } from './edit/edit.component';
import { TodayComponent } from './today/today.component';
import { EventsComponent, FocusGrabbingInputAttribute } from './events/events.component';

import { SelectEventService } from './select-event-type/select-event-type.service';
import { EventsUIService } from './events/eventsUI.service';
import { TriggersComponent } from './triggers/triggers.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { IntroductionComponent } from './introduction/introduction.component';
import { QuickstartCommonModule } from './quickstart-common/quickstart-common.module';
import { SkipWelcomeGuard } from './guards/skip-welcome.guard';
import { QuickstartFlowControlModule } from './quickstart-flow-control/flow-control.module';
import { WhatsNewComponent } from './whats-new/whats-new.component';

let pipes = [
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    // --- framework
    CoreModule,
    UIModule,
    AuthModule,
    // --- locals
    ProgrammingCommonModule,
    QuickstartCommonModule,
    // --- submodules
    QuickstartScheduleModule,
    QuickstartNotificationsModule,
    QuickstartButtonsModule,
    QuickstartLEDColorsModule,
    QuickstartTimersModule,
    QuickstartLightsModule,
    QuickstartSensorsModule,
    QuickstartSecuritySystemModule,
    QuickstartSimpleModule,
    QuickstartRoomsModule,
    QuickstartMediaModule,
    QuickstartThermostatModule,
    QuickstartFlowControlModule,
    VoiceScenesModule
  ],
  declarations: [
    ...pipes,
    AddComponent,
    QuickstartsComponent,
    SelectActionComponent,
    SelectEventTypeComponent,
    WhenThenComponent,
    ListByNameComponent,
    ListByTypeComponent,
    ListByRoomComponent,
    EditComponent,
    EventComponent,
    TodayComponent,
    FocusGrabbingInputAttribute,
    EventsComponent,
    TriggersComponent,
    WelcomeComponent,
    IntroductionComponent,
    WhatsNewComponent
  ],
  providers: [
    QuickstartProgrammingService,
    SelectEventService,
    SkipWelcomeGuard,
    EventsUIService
  ],
  exports: [
    ...pipes,
    SelectActionComponent,
  ]
})
export class QuickstartsProgrammingModule { }
