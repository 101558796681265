import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { AgentMetaService } from './../services/agent-meta.service';
import { ProgrammingUtilsService as Utils } from '../services/utils.service';
import { DeviceCategoryService } from '../services/device-category.service';

@Pipe({
  name: 'actionIconClass'
})
export class ActionIconClassPipe implements PipeTransform {

  constructor(
    private store: Store<any>,
    private agents: AgentMetaService,
    private categorySvc: DeviceCategoryService
  ) { }

  transform(event: any, args?: any): any {
    let items = Utils.getValue(this.store, s => s.programmingItems.itemsMap);
    if (!!event && !!event.codeItems && event.codeItems.length > 0) {
      let ci = event.codeItems[0];
      let item = items[ci.deviceId];
      // is it a device?
      if (item) {
        return this.categorySvc.getCategoryIconForItem(item);
      } else {
        // is it an agent?
        let icon = this.agents.getIconForId(ci.deviceId);
        if (icon) {
          return icon;
        }
      }
    }

    return 'fa fa-fw fa-question';
  }

}
