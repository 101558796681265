import { Pipe, PipeTransform } from '@angular/core';
import { ItemsService } from '../services/items.service';

@Pipe({
  name: 'lightSceneDeviceName'
})
export class LightSceneDeviceNamePipe implements PipeTransform {

  constructor(
    private _items: ItemsService
  ) { }

  transform(value: any, args?: any): any {
    if (value && value.device_id) {
      let id = parseInt(value.device_id);
      if (!isNaN(id)) {
        let item = this._items.getItem(id);
        if (item && item.protocolName) {
          return item.protocolName;
        }
      }
    }

    return value;
  }

}
