import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { SharedProgrammingService } from '../../../common/services/shared-programming.service';
import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';
import { ScheduleEvent, Schedules } from '../+state/schedule.interfaces';
import { SchedulesService } from '../schedule.service';


@Component({
  selector: 'prg-schedule-list',
  templateUrl: './schedule-list.component.html',
  styleUrls: ['./schedule-list.component.less']
})
export class ScheduleListComponent extends BaseRoutingComponent {

  _scheduleEvents: Observable<Array<ScheduleEvent>>;
  initialized: Observable<boolean>;
  _searchText: string;

  constructor(
    protected router: Router,
    private route: ActivatedRoute,
    private schedules: SchedulesService,
    protected store: Store<{
      scheduleProgramming: Schedules
    }>,
    private shared: SharedProgrammingService
  ) {
    super();
    this._scheduleEvents = this.store.select(s => s.scheduleProgramming.scheduleEvents);
    this.initialized = this.store.select(s => s.scheduleProgramming.ready);
    this._searchText = '';
  }

  openSchedule(schedule: ScheduleEvent) {
    // NOTE in this context we want to go directly to the add route, if the same schedule is opened from the
    // my programming list we go to edit
    this.router.navigate(['when-then/device/100100/event', schedule.eventId, 'add'], { relativeTo: this.moduleRoot });
  }
}
