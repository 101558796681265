import { ReactiveFormsModule } from '@angular/forms';
// angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

// libs
import { UIModule } from '@when-then/ui';

// external
import { QuickstartCommonModule } from '../quickstart-common/quickstart-common.module';
import { ProgrammingCommonModule } from '../../common/common.module';

// module
import { QSNotificationsActionComponent } from './action/action.component';
import { QSNotificationsCommandComponent } from './action/command/command.component';
import { QSNotificationsParametersComponent } from './action/parameters/parameters.component';
import { QSNotificationsService } from './services/notifications.service';
import { QSPushNotificationComponent } from './push-notification/push-notification.component';
import { QSSelectPushNotificationComponent } from './select-notification/select-notification.component';
import { QSEmailNotificationComponent } from './email-notification/email-notification.component';
import { QSSelectAnnouncementComponent } from './select-announcement/select-announcement.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    UIModule,
    ProgrammingCommonModule,
    QuickstartCommonModule
  ],
  providers: [
    QSNotificationsService
  ],
  declarations: [
    QSNotificationsActionComponent,
    QSNotificationsCommandComponent,
    QSNotificationsParametersComponent,
    QSPushNotificationComponent,
    QSSelectPushNotificationComponent,
    QSEmailNotificationComponent,
    QSSelectAnnouncementComponent
  ]
})
export class QuickstartNotificationsModule { }
