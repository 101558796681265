import { ProgrammingUtilsService } from './../services/utils.service';
import { ItemsService } from './../services/items.service';
import { Event } from './../interfaces/event.interface';
import { Pipe, PipeTransform } from '@angular/core';
import { DeviceCategoryService } from '../services/device-category.service';

@Pipe({
  name: 'eventIcon'
})
export class EventIconPipe implements PipeTransform {

  constructor(
    private items: ItemsService,
    private categories: DeviceCategoryService,
    private utils: ProgrammingUtilsService
  ) { }

  transform(event: Event, args?: any): any {
    let iconClass = 'fa fa-question';
    if (event && event.deviceId) {
      let device = this.items.getItem(event.deviceId);
      if (device) {
        iconClass = this.categories.getCategoryIconForItem(device);
      } else {
        console.warn('eventIcon: request for icon, item not found', event);
      }
    }

    return iconClass;
  }
}
