// import { LightsService } from './../services/lights.service';
import { Store } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';
import { ProgrammingUtilsService as Utils } from './../../../common/services/utils.service';
import { SharedProgrammingService } from '../../../common/services/shared-programming.service';

import { LightsService, LightsProgrammingContext, EVENT_WHITELIST } from '../services/lights.service';
import { Light } from '../services/interfaces/light.interface';
import { Button } from '../services/interfaces/button.interface';


@Component({
  selector: 'prg-select-button',
  templateUrl: './select-button.component.html',
  styleUrls: ['./select-button.component.less']
})
export class SelectButtonComponent extends BaseRoutingComponent implements OnInit {

  light: Observable<Light>;
  buttons: Observable<Button[]>;

  constructor(
    protected router: Router,
    protected store: Store<{ lightsProgramming: LightsProgrammingContext }>,
    private route: ActivatedRoute,
    private lightsService: LightsService,
    private shared: SharedProgrammingService
  ) {
    super();

    this.light = this.store.select(s => s.lightsProgramming.trigger.light);
    this.buttons = this.store.select(s => s.lightsProgramming.trigger.events)
      .pipe(
        filter(e => !!e),
        map(events => events
          .filter((event, i, a) => {
            return EVENT_WHITELIST.indexOf(event.eventId) > -1 && !!event.buttonName && (a.map(e => e.buttonName).indexOf(event.buttonName) == i);
          })
          .map(event => {
            return {
              label: event.buttonName + ' Button',
              buttonName: event.buttonName,
              deviceName: event.deviceName,
              deviceRoomName: event.deviceRoomName
            }
          })
        )
      );

    // this.buttons.subscribe(buttons => console.info('select-button.component.ts: buttons: %O', buttons));
  }

  ngOnInit() {
  }

  buttonSelected(button: Button) {
    this.lightsService.setTriggerButton(button);
    this.router.navigate([button.buttonName], { relativeTo: this.route });
  }
}
