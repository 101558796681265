import {Pipe, PipeTransform} from '@angular/core';

import {DeviceCategoryService} from '../services';

@Pipe({
  name: 'fontAwesomeClass'
})
export class FontAwesomeClassPipe implements PipeTransform {
  constructor(
    private deviceCategories: DeviceCategoryService
  ) { }

  transform(iconType: string): string {
    let iconcls = 'fa fa-fw ';
    switch (iconType) {
      case 'unknown':
        iconcls += 'fa-question-circle';
      default:
        iconcls += 'fa-cube';
    }

    return iconcls;
  }
}
