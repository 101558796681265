import { Pipe, PipeTransform } from '@angular/core';

declare var _: any;

@Pipe({
  name: 'categoryLabel'
})
export class CategoryLabelPipe implements PipeTransform {
  transform(cat: any): string {
    if (cat && cat.value) {
      switch (cat.value.toLowerCase().trim()) {
        case 'uidevice': return 'Touchscreen'
        default: return cat.label;
      }
    }

    return cat;
  }
}

