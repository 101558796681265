import { Component, Input, ViewChild } from "@angular/core";
import { LoggerFactory } from "@when-then/core";
import { Parameter } from "../../../common/interfaces/parameter.interface";

@Component({
  selector: "prg-input",
  templateUrl: './input.component.html',
  styleUrls: ['./dynamic.less']
})
export class InputComponent {
  @Input() type: string;
  @Input() param: Parameter;
  errors: string[];

  private _logger = LoggerFactory.getLogger(InputComponent.name);

  constructor() {
  }

  ngOnInit() {
    this.errors = [];
    this._logger.debug('oninit, param is', this.param);
  }

  ngAfterViewInit() {
    this._logger.debug('afterviewinit, param is', this.param);
  }

  inputChanged(event) {
    this.param.value = event.target.value;
  }

  valueChanged(event) {
    this.errors = [];
    switch (this.param.type) {
      case 'TIMEOFDAY':
        this._validateTime(); break;
      case 'INTEGER':
      case 'RANGED_INTEGER':
      case 'FLOAT':
      case 'RANGED_FLOAT':
        this._validateNumeric(); break;
    }
  }

  private _validateNumeric() {
    if (this.param.value > this.param.high) {
      this.param.value = this.param.high;
    }

    if (this.param.value < this.param.low) {
      this.param.value = this.param.low;
    }
  }

  private _validateTime() {
    if (this.param.type === 'TIMEOFDAY') {
      if (this.param.value) {
        const hhmm = this.param.value.split(':');

      } else {
        this.errors.push('please enter a valid time');
      }
    }
  }
}
