import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { flatMap, map } from 'rxjs/operators';

import { BrokerService } from '@when-then/core';

import { MediaProgrammingContext, MEDIA_ACTIONS } from './quickstart-media.service';
import { SetListenDevices, SetWatchDevices } from './quickstart-media.actions';

@Injectable()
export class QSMediaEffects {

  constructor(
    private actions$: Actions,
    private _broker: BrokerService,
    private _store: Store<{
      mediaProgramming: MediaProgrammingContext
    }>
  ) { }

  @Effect() listenDevices$ = this.actions$
    .pipe(
      ofType(MEDIA_ACTIONS.SET_ROOMS),
      flatMap(act => this._broker.call({
        path: '/api/v1/locations/rooms/listen_devices'
      })),
      map(devices => new SetListenDevices(devices))
    );

  @Effect() watchDevices$ = this.actions$
    .pipe(
      ofType(MEDIA_ACTIONS.SET_ROOMS),
      flatMap(act => this._broker.call({
        path: '/api/v1/locations/rooms/watch_devices'
      })),
      map(devices => new SetWatchDevices(devices))
    );

}
