import { CodeItemType } from './../../../common/interfaces/item.interface';
import { Component, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, ReplaySubject, BehaviorSubject, combineLatest, from } from 'rxjs';
import {
  map,
  flatMap,
  filter,
  take
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as pluralize from 'pluralize';

import { BrokerService, LoggerFactory } from '@when-then/core';

import { ProgrammingUtilsService as Utils } from './../../../common/services/utils.service';
import { ItemsService } from './../../../common/services/items.service';
import { Parameter } from '../../../common/interfaces/parameter.interface';
import { Device } from '../../../common/interfaces/item.interface';
import { SharedProgrammingService, CommandData, CommonProgrammingContext } from '../../../common/services/shared-programming.service';
import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';

import { Room, MediaProgrammingContext, QSMediaService } from '../quickstart-media.service';
import { Command } from '../../../common/interfaces/command.interface';

interface Config {
  [key: string]: {
    display: string;
    icon: string;
    waitText: string;
    command: string;
    endpoint: string;
  }
}

const CONFIG: Config = {
  broadcast_audio: {
    display: 'Select Radio Station to play',
    icon: 'music',
    waitText: 'Finding audio sources...',
    command: 'SELECT_AUDIO_MEDIA:BROADCAST_AUDIO',
    endpoint: 'broadcast_audio'
  },
  broadcast_video: {
    display: 'Select TV Station to play',
    icon: 'watch',
    waitText: 'Finding video sources...',
    command: 'SELECT_VIDEO_MEDIA:BROADCAST_VIDEO',
    endpoint: 'broadcast_video'
  },
  movie: {
    display: 'Select Movie to play',
    icon: 'watch',
    waitText: 'Finding movies...',
    command: 'SELECT_VIDEO_MEDIA:MOVIE',
    endpoint: 'movies'
  },
  playlist: {
    display: 'Select Playlist to play',
    icon: 'music',
    waitText: 'Finding playlists...',
    command: 'SELECT_AUDIO_MEDIA:PLAYLIST',
    endpoint: 'albums/playlists'
  },
  album: {
    display: 'Select Album to play',
    icon: 'music',
    waitText: 'Finding albums...',
    command: 'SELECT_AUDIO_MEDIA:ALBUM',
    endpoint: 'albums'
  }
}

interface Filter {
  id: string;
  name: string;
  default: string;
  options: any[];
  selected?: string;
}

interface Channel {
  id: number;
  device_id: number;
  genre: string;
  description: string;
  name?: string;
  title?: string;
  artist?: string;
  source?: Device;
  record_label?: string;
  release_date?: string;
}

const FILTERABLE_FIELDS = ['genre', 'artist', 'source'];

@Component({
  templateUrl: './select-media.component.html',
  styleUrls: ['./select-media.component.less'],
  host: {
    '(document:click)': 'onClick($event)',
  }
})
export class QSMediaSelectMediaComponent extends BaseRoutingComponent {
  private _logger = LoggerFactory.getLogger(QSMediaSelectMediaComponent.name);
  _sourceName: Observable<string>;
  _roomId: Observable<number>;
  _room: Observable<Room>;
  _params: Observable<Parameter[]>;
  _channels: ReplaySubject<Channel[]>;
  _channelCount: number;
  _filteredChannels: Observable<Channel[]>;
  _command: Observable<Command>;
  _waitText: Observable<string>;
  _config = CONFIG;
  _filters: ReplaySubject<Filter[]>;
  _sources: Device[];

  _showFilter: boolean = false;

  constructor(
    private _route: ActivatedRoute,
    private _broker: BrokerService,
    protected store: Store<{
      mediaProgramming: MediaProgrammingContext,
      sharedProgramming: CommonProgrammingContext
    }>,
    private _shared: SharedProgrammingService,
    public router: Router,
    private _service: QSMediaService,
    private _elementRef: ElementRef,
    private _items: ItemsService
  ) {
    super();

    this._sourceName = this._route.params.pipe(map((params: { source: string }) => params.source));

    this._roomId = this._route.params.pipe(map((params: { roomId: string }) => parseInt(params.roomId)));

    this._room = this._roomId
      .pipe(
        flatMap(roomId => {
          return this.store.select(s => s.mediaProgramming.rooms)
            .pipe(
              filter(r => !!r),
              map(rooms => rooms.find(room => room.id == roomId))
            )
        })
      );

    this._command = combineLatest(
      this._room,
      this._sourceName,
      (room, source) => room.commands.find(c => c.command == CONFIG[source].command)
    );

    this._command.subscribe(c => this._logger.debug('media command changed', c));

    this._filters = new ReplaySubject();

    this._channels = new ReplaySubject();

    combineLatest(
      this._sourceName,
      this._room
    )
      .pipe(
        flatMap(([sourceName, room]) => from(<Promise<Channel[]>>this._broker.call({
          path: `/api/v1/locations/rooms/${room.id}/media/${CONFIG[sourceName].endpoint}`
        }))),
        // We need to get the name of the device
        // providing the media, the simplest way
        // is to query for the devices associated
        // with each channel. NOTE: This may have
        // a prohibitive performance cost. If it
        // turns out to be too expensive, this will
        // need to be rethought.
        flatMap(channels => {
          const ids = channels
            .map(c => c.device_id)
            // Dedupe the array of device IDs as
            // will happen with digital media,
            // otherwise we could overflow the
            // request
            .filter((v, i, a) => a.indexOf(v) == i);

          return this._items.itemsList
            .pipe(
              map(items => items.filter(itm => ids.indexOf(itm.id) >= 0)),
              map((devices: Device[]) => channels.map(channel => {
                channel.source = devices.find(d => d.id == channel.device_id);
                return channel;
              }))
            );

          // TODO figure out how to integrate items service/subscription here
          // return from(<Promise<Device[]>>this._broker.call({
          //   path: '/api/v1/items',
          //   queryString: {
          //     query: {
          //       id: {
          //         '$in': channels
          //           .map(c => c.device_id)

          //           .filter((v, i, a) => a.indexOf(v) == i)
          //       }
          //     }
          //   }
          // }))
          //   .map(devices => channels.map(channel => {
          //     channel.source = devices.find(d => d.id == channel.device_id);
          //     return channel;
          //   }));
        })
      )
      .subscribe(channels => {
        this._channelCount = channels.length;
        this._channels.next(channels);
      });


    // EXPERIMENTAL
    this._filteredChannels = combineLatest(
      this._channels,
      this._filters,
      (channels, filters) => {
        return channels.filter(channel => {
          return filters
            .filter(f => !!f.selected && f.default != f.selected)
            .every(f => {
              return (f.id == 'source')
                ? channel[f.id].name == f.selected
                : channel[f.id] == f.selected;
            })
        });
      }
    );

    // EXPERIMENTAL
    // Dynamically build filters. This iterates
    // over our collection of channels, watching
    // for string fields that are frequently the
    // same value across channel. e.g. genre is
    // likely to have a relatively small number
    // of discrete values.
    this._channels
      .pipe(
        filter(c => !!c),

        // Pull the names of fields common
        // to ALL channels.
        map(channels => {
          return channels
            .map(channel => Object.keys(channel).sort())
            .reduce((prev, curr) => {
              prev = prev || [...curr];
              let accumulator = [];
              while (prev.length > 0 && curr.length > 0) {
                if (prev[0] < curr[0]) { prev.shift(); }
                else if (prev[0] > curr[0]) { curr.shift(); }
                else {
                  accumulator.push(prev.shift());
                  curr.shift();
                }
              }
              return accumulator;
            }, undefined); // This errs if undefined isn't provided
        }),

        // For each key, find ALL values for
        // that key across all channels
        flatMap(keys => {
          return this._channels
            .pipe(
              map(channels => {
                return channels.reduce((acc, curr) => {
                  keys.map(key => {
                    acc[key] = acc[key] || [];

                    const val = (key == 'source' && !!curr.source)
                      ? curr.source.name
                      : curr[key];

                    if (val != '' && acc[key].indexOf(val) == -1) {
                      acc[key].push(val);
                    }
                  });

                  return acc;
                }, {})
              })
            )
        }),

        // Create a filter for each
        // of the keys that meets our requirements
        map((values: {
          [key: string]: any[]
        }) => {
          let filters: Filter[] = [];

          Object.keys(values).map(key => {
            let vals = values[key];
            if (
              this._keyIsWhitelisted(key) &&
              this._hasReasonableOptionCount(vals) &&
              this._allAreStrings(vals) &&
              this._allValuesReasonablyShort(vals)
            ) {
              filters.push({
                id: key,
                name: this._pretty(key),
                default: `All ${pluralize(this._pretty(key))}`,
                options: vals.sort()
              })
            }
          })

          return filters;
        }),
        take(1)
      )
      .subscribe(filters => {
        this._filters.next(filters);
      });

    this._waitText = this._sourceName.pipe(map(cn => this._config[cn].waitText));
  }

  // We don't necessarily want to filter on
  // ANY possible field, as there are some
  // that would be of very limited utility
  // e.g. Record Label, or even worse, img
  private _keyIsWhitelisted = (key: string) => {
    return FILTERABLE_FIELDS.indexOf(key) > -1;
  }

  // Make sure the filter field has a useful
  // number of options. If everything is coming
  // from the same source, there's no point,
  // nor is there any point if every channel
  // has a unique value
  private _hasReasonableOptionCount = (vals: any[]) => {
    const max = (this._channelCount || 300) * (2 / 3);
    return !!vals && vals.length > 1 && vals.length < max;
  }

  private _allAreStrings = (vals: any[]) => {
    return vals.every(val => typeof val == 'string');
  }

  private _allValuesReasonablyShort = (vals: string[]) => {
    return vals.every(val => val.length < 300);
  }

  /**
   * Capitalize each word in a string,
   * used for filter names
   */
  private _pretty = (str: string): string => {
    let tokens = str.split(/[\s,_]+/);
    return tokens.map(t => {
      return t.charAt(0).toUpperCase() + t.slice(1);
    }).join(' ');
  }

  // NOTE unfortunately coupling to the UI here, but we need to stop propagation of the click event that
  // calls this, otherwise the parent click handler will also get invoked, which will keep the filter menu
  // open
  private _setFilter(id: string, selected: string) {
    this._filters
      .pipe(
        take(1)
      )
      .subscribe(filters => {
        let newState = [...filters];
        let ix = newState.findIndex(f => f.id == id);
        newState[ix].selected = selected;
        this._filters.next(newState);
      });

    this.hideFilter();
  }

  _setAction(selection: Channel) {
    this._logger.debug('selected channel or station', selection);
    const command = Utils.snapshot<Command>(this._command);
    if (!!command) {
      const data = {
        ...Utils.buildCommandData(CodeItemType.Command, command),
        ...{
          params: command.params.map(p => {
            let param = {
              name: p.name,
              value: p.value || selection.id,
              display: undefined
            }

            p.valueDisplay = (command.command == "SELECT_AUDIO_MEDIA:PLAYLIST" && p.name == "mediaid") ? "{title}" : p.valueDisplay;
            param.display = (!!p.valueDisplay) ? QSMediaService.composeField(p.valueDisplay, selection) : undefined;

            return param;
          })
        }
      };

      this._logger.debug('setting pendng action', command, data);
      this._shared.setPendingAction(command, data);
      this.router.navigate(['conditionals'], { relativeTo: this._route });
    }
  }

  toggleFilter() {
    this._showFilter = !this._showFilter;
  }

  hideFilter() {
    this._showFilter = false;
  }

  // close tbe drop down menu if the user clicks anywhere outside the header
  onClick = (event: MouseEvent) => {
    if (!this._elementRef.nativeElement.contains(event.target)) {
      this.hideFilter();
    }
  }

  getSubtitle(channel: Channel): string {
    let subtitle = '';

    if (!!channel) {
      if (channel.artist) {
        subtitle = channel.artist;
      } else if (!!channel.source && !!channel.source.name) {
        subtitle = channel.source.name;
      } else if (!!channel.description) {
        subtitle = channel.description;
      }

      if (subtitle.length > 0) {
        subtitle += ' : ';
      }

      if (!!channel.genre) {
        subtitle += channel.genre;
      }
    }

    return subtitle;
  }
}
