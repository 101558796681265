import { Pipe, PipeTransform } from '@angular/core';
import { CodeItem } from '../../common/interfaces/code-item.interface';

@Pipe({
  name: 'countCodeItems'
})
export class CountCodeItemsPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return this._count(value);
  }

  _count(codeitems: CodeItem[]): number {
    if (!!codeitems && Array.isArray(codeitems)) {
      return codeitems.reduce<number>((count: number, ci: CodeItem) => {
        return count + this._count(ci.codeItems);
      }, codeitems.length);
    } else {
      return 0;
    }
  }
}
