
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';

import { BrokerService } from './broker.service';
import { CommonConfigService } from './common-config.service';

export interface ControllerConnection {
  connected: boolean;
  commonName: string;
  registeredAccount: any;
}

const INITIAL_STATE: ControllerConnection = {
  connected: false,
  commonName: undefined,
  registeredAccount: undefined
};

const STORE_NAME: string = 'CONTROLLER_CONNECTION';
const UPDATE_CONNECTION: string = STORE_NAME + ':UPDATE_CONNECTION';
const SET_CONNECTION: string = STORE_NAME + ':SET_CONNECTION';
const SET_REGISTERED_ACCOUNT: string = STORE_NAME + ':SET_REGISTERED_ACCOUNT';
const CLEAR_REGISTERED_ACCOUNT: string = STORE_NAME + ':CLEAR_REGISTERED_ACCOUNT';

export function connectionReducer(state: ControllerConnection = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case SET_CONNECTION:
      return Object.assign({}, INITIAL_STATE, payload);
    case UPDATE_CONNECTION:
      let newState = Object.assign({}, state, { connected: payload.connected });
      return newState;
    case CLEAR_REGISTERED_ACCOUNT:
      return Object.assign({}, state, { registeredAccount: undefined });
    case SET_REGISTERED_ACCOUNT:
      return Object.assign({}, state, { registeredAccount: payload });
    default:
      return state;
  }
};

@Injectable()
export class ControllerConnectionService {
  controllerConnection: Observable<ControllerConnection>;

  constructor(
    private store: Store<any>,
    private broker: BrokerService,
    private config: CommonConfigService) {
    this.controllerConnection = store.select(state => state.controllerConnection);

    // this.broker.connected.subscribe((data: any) => this.onBrokerConnected(data));
    // this.broker.disconnected.subscribe((data: any) => this.onBrokerDisconnected(data));

    combineLatest(
      this.broker.connected,
      this.config.loaded,
      (connected, configured) => {
        // we must have loaded the complete set of configuration data first
        if (configured) {
          // and broker must be connected
          if (connected) {
            this.getCommonName().then((data: any) => {
              // console.log('controller-connection.service: common name is ', data.commonName);
              this.store.dispatch({ type: SET_CONNECTION, payload: { connected: true, commonName: data.commonName } });
            }, (err: any) => {
              console.error('controller-connection.service: error getting common name', err);
            });
          } else {
            this.store.dispatch({ type: SET_CONNECTION, payload: { connected: false, commonName: undefined } });
          }
        }
        // NOTE if we're not yet configured, don't do anything because who knows how the auth flow will react
      });
  }


  // disconnect(andReconnect: boolean = false) {
  //   this.broker.manualDisconnect();

  //   if (this._eventSubscriber) {
  //     this._eventSubscriber.unsubscribe();
  //   }
  // }

  // connect(): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     this.broker.init({
  //       connectStream: true
  //     }).then(
  //       (result: any) => {
  //         this.getCommonName().then(
  //           (data: any) => {
  //             // console.log('controller-connection.service: common name is ', data.commonName);
  //             this.store.dispatch({ type: SET_CONNECTION, payload: { connected: true, commonName: data.commonName } });

  //             // this.getRegisteredAccount(data.commonName);
  //             // prevent multiple subscriptions caused by below
  //             //this.watchConnection();
  //             resolve();
  //           },
  //           (err: any) => {
  //             console.error('controller-connection.service: error getting common name', err);
  //             reject(err);
  //           });
  //       },
  //       (err: any) => {
  //         console.error('controller-connection.service.ts: err: %O', err);
  //         this.store.dispatch({ type: SET_CONNECTION, payload: INITIAL_STATE });
  //         reject(err);
  //       });
  //   });
  // }

  // private watchConnection() {

  //   if (this._eventSubscriber) {
  //     this._eventSubscriber.unsubscribe();
  //     this._eventSubscriber = null;
  //   }

  //   this.broker.subscribe({
  //     path: '/api/v1/status'
  //   }).then(
  //     (result: any) => {
  //       this._eventSubscriber = result;
  //       this._eventSubscriber.subscribe(
  //         (msg: any) => {
  //           if (msg && msg.director) {
  //             if (['alive', 'Connected', 'connected'].indexOf(msg.director) >= 0) {
  //               this.store.dispatch({ type: UPDATE_CONNECTION, payload: { connected: true } });
  //             } else {
  //               this.store.dispatch({ type: UPDATE_CONNECTION, payload: { connected: false } });
  //             }
  //           } else {
  //             this.store.dispatch({ type: UPDATE_CONNECTION, payload: { connected: false } });
  //           }
  //         },
  //         (err: any) => {
  //           console.error('ctrlconn.service: error on status notification', err);
  //         });
  //     },
  //     (err: any) => {
  //       console.error('ctrlconn.service: error attempting to watch status', err);
  //     });
  // }

  // private onBrokerConnected(msg: any) {
  //   // if (this._connectRetryInterval) {
  //   //   console.log('ctrlconn.service: clearing connect timeout');
  //   //   clearInterval(this._connectRetryInterval);
  //   //   this._connectRetryInterval = null;
  //   // }

  //   // this.connect();
  // }

  // private onBrokerDisconnected(msg: any) {
  //   // this.store.dispatch({ type: SET_CONNECTION, payload: INITIAL_STATE });

  //   // console.log('ctrlconn.service: broker disconnected');
  //   // this._connectRetryInterval = setInterval(() => {
  //   //   console.log('ctrlconn.service: connect timeout expired, let\'s try connecting again');
  //   //   this.broker.init({
  //   //     connectStream: true
  //   //   });
  //   // }, 10000);
  // }

  private getCommonName(): Promise<any> {
    console.log('controller-connection: get common name');
    return this.broker.call({
      path: '/api/v1/common_name'
    });
  }
}
