import { DeviceEvent } from './../../../common/interfaces/event.interface';
import { LoggerFactory } from '@when-then/core';
import { Store } from '@ngrx/store';
import { BrokerService } from '@when-then/core';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { ItemsService } from './../../../common/services/items.service';
import { ProgrammingUtilsService as Utils } from './../../../common/services/utils.service';
import { SharedProgrammingService } from '../../../common/services/shared-programming.service';
import { Device } from '../../../common/interfaces/item.interface';
import { OSCompatibilityService } from './../../../common/services/os-compatibility.service';

export const LEGACY_KEYPAD_PROXIES: string[] = ['control4_keypad_kpz-11-w', 'control4_keypad_kpz-31-w', 'control4_keypad_kpz-61-w'];
export const ALL_KEYPAD_PROXIES: string[] = ['keypad_proxy', 'knx_keypad', ...LEGACY_KEYPAD_PROXIES];
const IGNORABLE_BUTTON_ACTIONS: string[] = ['pushed', 'pressed', 'released', 'press', 'release'];

export interface KeypadProgrammingContext {
  ready: boolean;
  keypads?: Array<Device>;
  trigger: {
    keypad?: any;
    buttons?: Array<any>;
    button?: any;
    events?: Array<DeviceEvent>;
    event?: DeviceEvent;
  }
}

const INITIAL_STATE: KeypadProgrammingContext = {
  trigger: {},
  ready: false
}

const STORE_NAME: string = 'PROGRAMMING:QUICKSTARTS:KEYPADS:';
const CLEAR_CONTEXT: string = STORE_NAME + 'CLEAR_CONTEXT';
const SET_DEVICES: string = STORE_NAME + 'SET_DEVICES';
const SET_DEVICE: string = STORE_NAME + 'SET_DEVICE';
const SET_BUTTONS: string = STORE_NAME + 'SET_BUTTONS';
const SET_BUTTON: string = STORE_NAME + 'SET_BUTTON';
const SET_EVENTS: string = STORE_NAME + 'SET_EVENTS';
const CLEAR_BUTTONS: string = STORE_NAME + 'CLEAR_BUTTONS';
const SET_READY: string = STORE_NAME + 'SET_READY';

export function keypadProgrammingReducer(state: KeypadProgrammingContext = INITIAL_STATE, { type, payload }) {

  switch (type) {
    case CLEAR_CONTEXT: return Object.assign({}, INITIAL_STATE);
    case SET_DEVICES: return Object.assign({}, state, { keypads: payload });
    case SET_DEVICE: return Object.assign({}, state, { trigger: Object.assign({}, state.trigger, { keypad: payload }) });
    case SET_BUTTONS: return Object.assign({}, state, { trigger: Object.assign({}, state.trigger, { buttons: payload }) });
    case SET_BUTTON: return Object.assign({}, state, { trigger: Object.assign({}, state.trigger, { button: payload }) });
    case SET_EVENTS: return Object.assign({}, state, { trigger: Object.assign({}, state.trigger, { events: payload }) });
    case CLEAR_BUTTONS: return Object.assign({}, state, { trigger: Object.assign({}, state.trigger, { buttons: [] }) });
    case SET_READY: return Object.assign({}, state, { ready: payload });

    default: return state;
  }
};

@Injectable()
export class QuickstartKeypadsService {
  private _logger = LoggerFactory.getLogger(QuickstartKeypadsService.name);
  selected: boolean;
  trigger: Observable<any>;
  allKeypads: Observable<Device[]>;
  ready: Observable<boolean>;
  supportsLegacyKeypads: boolean = false;

  constructor(
    protected store: Store<{ keypadProgramming: KeypadProgrammingContext }>,
    protected broker: BrokerService,
    protected shared: SharedProgrammingService,
    protected osCompatibilityService: OSCompatibilityService,
    private items: ItemsService
  ) {
    this.supportsLegacyKeypads = this.osCompatibilityService.isFeatureSupported('event', 'legacy-keypad-button-press');
    this.allKeypads = <Observable<Device[]>>this.items.itemsList
      .pipe(
        map(items => {
          return items.filter((item: Device) => {
            return ((item.type === 7 && this.isSupportedProxy(item.proxy)));
          });
        })
      );

    this.ready = combineLatest(
      this.shared.isReady,
      this.store.select(s => s.keypadProgramming.ready),
      (a, b) => (a && b)
    );
  }

  setKeypad(keypad: Device) {
    this.store.dispatch({ type: SET_READY, payload: false });
    this.store.dispatch({ type: SET_DEVICE, payload: keypad });
    this.getEvents(keypad);
  }

  setButton(button: any) {
    this.store.dispatch({ type: SET_BUTTON, payload: button });
    this.setInteractions(button);
  }

  // check for OS compatibility for legacy keypads
  private isSupportedProxy(proxy: string): boolean {
    return ((this.supportsLegacyKeypads && (ALL_KEYPAD_PROXIES.indexOf(proxy) >= 0)) ||
      (proxy === 'keypad_proxy'));
  }

  private setInteractions(button: any) {
    this._logger.debug('setting interactions for button', button);
    this._logger.debug('keypad events are', button.events);
    if (button.events) {
      let interactions = [];
      button.events.forEach(event => {
        if (event.buttonName === button.label) {
          interactions.push(event);
        }
      });
      this._logger.debug('interactions for button are ', button, interactions);
      this.store.dispatch({ type: SET_EVENTS, payload: interactions });
    }
  }

  private groupByRoom(items: Array<any>) {
    let roomsWithKeypadsMap = {};
    items.forEach(light => {
      let room = roomsWithKeypadsMap[light.roomId];
      if (!room) {
        room = {
          name: light.roomName,
          id: light.roomId,
          devices: []
        };
        roomsWithKeypadsMap[light.roomId] = room;
      }
      room.devices.push(light);
    });

    let roomsWithDevices = [];
    Object.keys(roomsWithKeypadsMap).forEach(key => {
      roomsWithDevices.push(roomsWithKeypadsMap[key]);
    });

    this.store.dispatch({ type: SET_DEVICES, payload: roomsWithDevices });
  }

  private getEvents(keypad: Device) {
    this.broker.call({
      path: '/api/v1/items/' + keypad.id + '/events',
      queryString: {
        allevents: true
      }
    }).then(res => {
      this._logger.debug('setting events', res);
      this.store.dispatch({ type: SET_EVENTS, payload: res });

      let buttons;
      if (keypad.proxy === 'knx_keypad') {
        buttons = this._knxButtons(res);
      } else {
        buttons = this.resolveButtons(res);
      }

      this._logger.debug('setting buttons', buttons);
      this.store.dispatch({ type: SET_BUTTONS, payload: buttons });
      this.store.dispatch({ type: SET_READY, payload: true });
    }, err => {
      this.shared.error(err);
      this.store.dispatch({ type: SET_READY, payload: true });
    });
  }

  private resolveButtons(events: Array<any>): any[] {
    this.store.dispatch({ type: CLEAR_BUTTONS });

    let buttonMap = {};
    events.forEach(event => {
      if (event.buttonName) {
        let btn = buttonMap[event.buttonName];
        if (!btn) {
          btn = {
            label: event.buttonName,
            events: []
          };
          buttonMap[event.buttonName] = btn;
        }

        if (IGNORABLE_BUTTON_ACTIONS.indexOf(event.buttonAction.toLowerCase()) === -1) {
          btn.events.push(event);
        }
      }
    });

    let buttons = [];
    Object.keys(buttonMap).forEach(key => {
      buttons.push(buttonMap[key]);
    });

    return buttons;
  }

  private _knxButtons(events: DeviceEvent[]): any[] {
    const buttons = [];

    for (let i = 0; i < 8; i++) {
      buttons.push({
        label: 'Button ' + (i + 1),
        events: events.filter(e => e.eventId === ((i + 1) * 3)).map(e => ({ ...e, ...{ buttonName: 'Button ' + (i + 1) } }))
      });
    }

    return buttons;
  }
}
