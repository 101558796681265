import { ActionReducerMap } from '@ngrx/store';

import { remoteProgrammingReducer, RemoteProgrammingContext } from './quickstart-remotes.service';

export interface QuickstartRemotesState {
  remoteProgramming: RemoteProgrammingContext
}

export const QUICKSTART_REMOTES_REDUCERS: ActionReducerMap<QuickstartRemotesState> = {
  remoteProgramming: remoteProgrammingReducer
};
