/**
 * Copyright 2017 Control4 Corporation. All rights reserved.
 * Control4 Confidential and Proprietary Information.
 */

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AUTH_ROUTES } from '@when-then/auth';

import { PROGRAMMING_ROUTES } from './../app/programming.routes';
import { RequiresBackupGuard } from '../app/common/guards/requires-backup.guard';
import { BackupProjectComponent } from './backup/backup-project.component';
import { SessionExpiredComponent } from './session-expired/session-expired.component';
import { DirectorConnectedGuard } from '@when-then/core';


const routes: Routes = [
  { path: '', redirectTo: 'programming', pathMatch: 'full' },
  ...PROGRAMMING_ROUTES,
  ...AUTH_ROUTES,
  {
    path: 'backup', component: BackupProjectComponent,
    canActivate: [DirectorConnectedGuard]
  },
  {
    path: 'session-expired', component: SessionExpiredComponent
  }
];

// The backup guard has a little bit of a snarled
// dependency graph. Disable it when running programming
// standalone for development.
// routes.forEach(route => {
//   if (!!route.canActivate) {
//     route.canActivate = route.canActivate.filter(c => c !== RequiresBackupGuard);
//   }

//   if (!!route.canActivateChild) {
//     route.canActivateChild = route.canActivateChild.filter(c => c !== RequiresBackupGuard);
//   }
// });

// console.warn('WARN >> dev-routing.module.ts:44: Backup Guard is disabled!');

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule],
  providers: []
})
export class DevRoutingModule { }
