import { LoggerFactory } from './log.service';
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders,
  HttpHandler,
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import {
  filter,
  distinctUntilChanged,
  map,
  tap,
  share,
  take,
  catchError,
  finalize
} from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { StorageService } from './storage.service';
import { UrlFactoryService } from './url-factory.service';
import { SubState } from '../interfaces/store';
import { HttpMethods, HttpOptions } from 'libs/core/src/lib/interfaces/http';

export interface MonitoredHttpState {
  log: RequestEntry[];
  jwtStatus: JWT_STATUS;
  connectionStatus: CONNECTION_STATUS;
  rebootRequested: boolean;
  latestJWTRequest?: RequestEntry;
  latestNonJWTRequest?: RequestEntry;
  latestApiRequest?: RequestEntry;
  openRequestCount: number;
}

export enum JWT_STATUS {
  UNKNOWN,
  NONE,
  VALID,
  INVALID
}

export enum CONNECTION_STATUS {
  UNKNOWN,
  CONNECTED,
  DISCONNECTED
}

export interface RequestEntry {
  url: string;
  method: string | HttpMethods;
  statusCode: number;
  statusText: string;
  time: number;
  error: boolean;
  jwtTransmitted: boolean;
  timestamp: number;
}

export const MONITORED_HTTP_INITIAL_STATE: MonitoredHttpState = {
  log: [],
  jwtStatus: JWT_STATUS.UNKNOWN,
  connectionStatus: CONNECTION_STATUS.UNKNOWN,
  rebootRequested: false,
  openRequestCount: 0
}

const STORE_NAME = 'MONITORED_HTTP';

const ACTIONS = {
  LOG: `${STORE_NAME}:LOG`,
  JWT_STATUS: `${STORE_NAME}:JWT_STATUS`,
  JWT_REQUEST: `${STORE_NAME}:JWT_REQUEST`,
  NON_JWT_REQUEST: `${STORE_NAME}:NON_JWT_REQUEST`,
  API_REQUEST: `${STORE_NAME}:API_REQUEST`,
  CONNECTION_STATUS: `${STORE_NAME}:CONNECTION_STATUS`,
  REBOOT_REQUESTED: `${STORE_NAME}:REBOOT_REQUESTED`,
  REQUEST_OPENED: `${STORE_NAME}:REQUEST_OPENED`,
  REQUEST_CLOSED: `${STORE_NAME}:REQUEST_CLOSED`
}

export { ACTIONS as MONITORED_HTTP_ACTIONS };
export const MONITORED_HTTP_STRINGS = Object.keys(ACTIONS).map(key => ACTIONS[key]);

export function monitoredHttpReducer(state: MonitoredHttpState = MONITORED_HTTP_INITIAL_STATE, { type, payload }) {

  // if (MONITORED_HTTP_STRINGS.indexOf(type) > -1) {
  //   console.debug('type: %s, payload: %O', type, payload);
  // }

  switch (type) {
    case ACTIONS.LOG:
      return Object.assign({}, state, { log: [...state.log, payload] });

    case ACTIONS.JWT_REQUEST:
      return Object.assign({}, state, { latestJWTRequest: payload });

    case ACTIONS.NON_JWT_REQUEST:
      return Object.assign({}, state, { latestNonJWTRequest: payload });

    case ACTIONS.API_REQUEST:
      return Object.assign({}, state, { latestApiRequest: payload });

    case ACTIONS.JWT_STATUS:
      return Object.assign({}, state, { jwtStatus: payload });

    case ACTIONS.CONNECTION_STATUS:
      let nu: MonitoredHttpState = Object.assign({}, state, { connectionStatus: payload });

      if (payload == CONNECTION_STATUS.CONNECTED) {
        nu.rebootRequested = false;
      }

      return nu;

    case ACTIONS.REBOOT_REQUESTED:
      return Object.assign({}, state, { rebootRequested: payload });

    case ACTIONS.REQUEST_OPENED:
      return Object.assign({}, state, { openRequestCount: state.openRequestCount + 1 });

    case ACTIONS.REQUEST_CLOSED:
      return Object.assign({}, state, { openRequestCount: state.openRequestCount - 1 });

    default:
      return state;
  }
}

@Injectable()
export class MonitoredHttpService extends HttpClient {
  private _logger = LoggerFactory.getLogger(MonitoredHttpService.name);
  private _latestRequest: Observable<RequestEntry>;
  private _latestJWTRequest: Observable<RequestEntry>;
  private _latestAPIRequest: Observable<RequestEntry>;

  constructor(
    handler: HttpHandler,
    private _store: Store<{
      monitoredHttpState: MonitoredHttpState,
      commonConfig: SubState
    }>,
    private _storage: StorageService,
    private _urlFactory: UrlFactoryService
  ) {
    super(handler);

    this._latestRequest = this._store.select(s => s.monitoredHttpState)
      .pipe(
        filter(s => !!s && s.log && s.log.length > 0),
        map(state => state.log[state.log.length - 1]),
        distinctUntilChanged(),
        share()
      );

    this._latestRequest
      .pipe(
        filter(l => !!l && l.jwtTransmitted)
      )
      .subscribe(latest => this._store.dispatch({
        type: ACTIONS.JWT_REQUEST,
        payload: latest
      }));

    this._latestRequest
      .pipe(
        filter(l => !!l && l.url && l.url.indexOf('/api/v1') > -1)
      )
      .subscribe(latest => this._store.dispatch({
        type: ACTIONS.API_REQUEST,
        payload: latest
      }));

    this._latestAPIRequest = this._store.select(s => s.monitoredHttpState)
      .pipe(
        filter(s => !!s && !!s.latestApiRequest),
        map(s => s.latestApiRequest)
      );

    this._latestAPIRequest
      .pipe(
        distinctUntilChanged()
      )
      .subscribe(latest => {
        let status: CONNECTION_STATUS;

        switch (latest.statusCode) {
          case undefined:
          case null:
          case 0:
          case 502:
            status = CONNECTION_STATUS.DISCONNECTED;
            break;

          default:
            status = CONNECTION_STATUS.CONNECTED;
        }

        this._store.dispatch({
          type: ACTIONS.CONNECTION_STATUS,
          payload: status
        });
      });

    this._latestRequest
      .pipe(
        filter(l => !!l && !l.jwtTransmitted)
      )
      .subscribe(latest => this._store.dispatch({
        type: ACTIONS.NON_JWT_REQUEST,
        payload: latest
      }));

    this._latestJWTRequest = this._store.select(s => s.monitoredHttpState)
      .pipe(
        filter(s => !!s && !!s.latestJWTRequest),
        map(s => s.latestJWTRequest)
      );

    this._latestJWTRequest
      .pipe(
        distinctUntilChanged()
      )
      .subscribe(latest => {
        let status: JWT_STATUS;

        switch (latest.statusCode) {
          case 200:
          case 400:
            status = JWT_STATUS.VALID;
            break;

          case 401:
            status = JWT_STATUS.INVALID;
            break;

          default:
            status = JWT_STATUS.UNKNOWN;
        }

        this._store.dispatch({
          type: ACTIONS.JWT_STATUS,
          payload: status
        })
      });

    const jwtStatus = this._store.select(s => s.monitoredHttpState)
      .pipe(
        map(s => !!s && s.jwtStatus),
        distinctUntilChanged()
      );

    // If jwt status is unknown, let's attempt to verify
    jwtStatus
      .pipe(
        filter(s => s == JWT_STATUS.UNKNOWN)
      )
      .subscribe(s => {
        this._logger.warn('WARN >> monitored-http.service.ts:157: JWT status is unknown, verifying...');
        this._verifyJWT();
      });

    jwtStatus
      .pipe(
        filter(s => s == JWT_STATUS.NONE)
      )
      .subscribe(s => {
        this._logger.warn('WARN >> monitored-http.service.ts:163: No JWT present.');
      });

    jwtStatus
      .pipe(
        filter(s => s == JWT_STATUS.INVALID)
      )
      .subscribe(s => {
        this._logger.warn('WARN >> monitored-http.service.ts:168: JWT is invalid.');
      });

    jwtStatus
      .pipe(
        filter(s => s == JWT_STATUS.VALID)
      )
      .subscribe(s => {
        this._logger.info('INFO >> monitored-http.service.ts:173: JWT is valid.');
      });

    const connectionStatus = this._store.select(s => !!s && s.monitoredHttpState)
      .pipe(
        filter(s => !!s),
        map(s => s.connectionStatus),
        distinctUntilChanged()
      );

    // TODO this causes a premature call to /api/v1/common_name, before the proper endpoint has even
    // been read from the runtime or query string config !!! (depends solely on the build time config,
    // which will be incorrect for a production deployment)
    connectionStatus
      .pipe(
        filter(s => s == CONNECTION_STATUS.UNKNOWN)
      )
      .subscribe(s => {
        this._logger.warn('WARN >> monitored-http.service.ts:219: API Connection status is unknown, verifying...');
        this._verifyConnection();
      });

    connectionStatus
      .pipe(
        filter(s => s == CONNECTION_STATUS.DISCONNECTED)
      )
      .subscribe(s => {
        this._logger.warn('WARN >> monitored-http.service.ts:225: API Connection is DOWN!');
      });

    connectionStatus
      .pipe(
        filter(s => s == CONNECTION_STATUS.CONNECTED)
      )
      .subscribe(s => {
        this._logger.info('INFO >> monitored-http.service.ts:230: API Connection is UP!');
      });
  }

  monitoredRequest<TBody = {}, TResponse = {}>(methodOrReq: string | HttpRequest<TBody>, url?: string, options?: HttpOptions): Observable<HttpResponse<TResponse> | TResponse> {
    let req = null;

    this._store.dispatch({
      type: ACTIONS.REQUEST_OPENED
    });

    let entry: RequestEntry = {
      url: undefined,
      method: undefined,
      statusCode: undefined,
      statusText: undefined,
      timestamp: Date.now(),
      time: undefined,
      error: undefined,
      jwtTransmitted: undefined,
    };

    if (typeof methodOrReq === 'string') {
      entry.url = url;
      entry.method = methodOrReq;
      entry.jwtTransmitted = /JWT=/.test(url);
    } else {
      req = methodOrReq as HttpRequest<TBody>;
      entry.url = req.url;
      entry.method = req.method;
      entry.jwtTransmitted = false;
      options = {
        ...options,
        body: req.body
      }

      // If we haven't already attached authorization,
      // do so here. This helps us sniff JWT status.
      const authHeader = req.headers.get('Authorization')
      const authToken = this._storage.get('authToken')

      if (!authHeader && !!authToken) {
        options = {
          ...options,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`
          }
        }
      }

      if (req.headers.get('Authorization') != null) {
        entry.jwtTransmitted = req.headers.get('Authorization').indexOf('Bearer ') == 0;
      }
    }

    const observable = super.request(entry.method, entry.url, options)

      .pipe(

        // For success states
        tap((response: any) => {
          entry.statusCode = response.status;
          entry.statusText = response.statusText;
          entry.error = false;
        }),

        // For error states
        catchError((err: HttpResponse<any>) => {
          entry.statusCode = err.status;
          entry.statusText = err.statusText;
          entry.error = true;

          return throwError(err);
        }),

        // After ALL requests, regardless of
        // success/error
        finalize(() => {
          entry.time = Date.now() - entry.timestamp;
          this._store.dispatch({
            type: ACTIONS.LOG,
            payload: entry
          });

          this._store.dispatch({
            type: ACTIONS.REQUEST_CLOSED
          });
        })
      );

    return observable;
  }

  public setJwtStatus = (status: JWT_STATUS): void => this._store.dispatch({
    type: ACTIONS.JWT_STATUS,
    payload: status
  });

  public setConnectionStatus = (status: CONNECTION_STATUS): void => this._store.dispatch({
    type: ACTIONS.CONNECTION_STATUS,
    payload: status
  });

  public setRebootRequested = (requested: boolean): void => {
    this._store.dispatch({ type: ACTIONS.CONNECTION_STATUS, payload: CONNECTION_STATUS.DISCONNECTED });
    this._store.dispatch({ type: ACTIONS.REBOOT_REQUESTED, payload: true });
  };

  /**
   * Attempts to verify the status
   * of our JWT
   */
  private _verifyJWT = <TBody = null>() => {
    this._store.select(s => s.commonConfig.loaded)
      .pipe(
        filter(c => !!c),
        take(1)
      )
      .subscribe(c => {
        this._logger.debug('configuration loaded, checking jwt');
        const JWT = this._storage.get('authToken');
        if (!JWT) {
          this._store.dispatch({
            type: ACTIONS.JWT_STATUS,
            payload: JWT_STATUS.NONE
          });

        } else {
          // this._logger.debug('mon-http: veryify-jwt: get common name');
          this.monitoredRequest(new HttpRequest<TBody>(
            HttpMethods.GET, 
            this._urlFactory.getBrokerURL('/api/v1/common_name'),
            {
              headers: new HttpHeaders({
                Authorization: `Bearer ${JWT}`,
                Accept: 'application/json'
              })
            })).subscribe();
          }
      });
  }

  /**
   * Attempts to verify the status
   * of our API connection
   */
  private _verifyConnection = <TBody = {}>() => {
    // this._logger.debug('mon-http: veryify-conn: get common name');
    this._store.select(s => s.commonConfig.loaded)
      .pipe(
        filter(c => !!c),
        take(1)
      )
      .subscribe(c => {
        this._logger.debug('configuration loaded, checking connection');
        this.monitoredRequest(new HttpRequest<TBody>(
          HttpMethods.GET, 
          this._urlFactory.getBrokerURL('/api/v1/common_name'), 
          {
            headers: new HttpHeaders({
            Accept: 'application/json'
          })
        })).subscribe();
      });
  }

}
