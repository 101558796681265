import { TIME_OF_DAY } from "./time-of-day.type";
import { YEARS } from "./year.type";
import { MONTHS } from "./month.type";
import { ConditionalType } from "./conditional-type.interfaces";
import { DATE } from "./date.type";
import { DAY_OF_WEEK } from "./day-of-week.type";

export const ConditionalTypes: ConditionalType[] = [
  TIME_OF_DAY,
  // DAY_OF_WEEK,
  // DATE,
  // MONTHS,
  // YEARS
];
