import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { Device, Item } from './../../../common/interfaces/item.interface';
import { ItemsService } from './../../../common/services/items.service';
import { TimersService } from './../services/timers.service';

import { OSCompatibilityService } from './../../../common/services/os-compatibility.service';
import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';
import { Room } from '../../quickstart-lights/services/interfaces/room.interface';

@Component({
  selector: 'atm-timers-action',
  templateUrl: './timers-action.component.html',
  styleUrls: ['./timers-action.component.less']
})
export class TimersActionComponent extends BaseRoutingComponent implements OnInit {

  private _rooms: Observable<Room[]>;

  timerDevices: Observable<Item[]>;

  @Input() rowClass: string;
  @Input() title: string;

  @Output() selected: EventEmitter<Device>;

  constructor(
    private route: ActivatedRoute,
    private timersService: TimersService,
    private osCompatibilityService: OSCompatibilityService,
    protected store: Store<any>,
    protected router: Router,
    private items: ItemsService
  ) {
    super();
    this._rooms = this.timersService.actionTimerDevicesByRoom;
    this.timerDevices = this.timersService.actionTimerDevices;
    this.rowClass = '';
    this.title = '';
    this.selected = new EventEmitter<Device>();
  }

  ngOnInit() {
    this.timerDevices = this.items.itemsList
      .pipe(
        map(items => {
          return items.filter((item: any) => {
            return ((item.type === 7 && this.isSupportedProxy(item)));
          })
        })
      );
  }

  deviceSelected(device: Device) {
    console.log('timers-action.deviceselected: ', device);
    this.timersService.setActionTimerDevice(device);
    this.router.navigate(['../../device/' + device.id], { relativeTo: this.route });
  }

  private isSupportedProxy(item: any): boolean {
    // outlet lights do not support timer commands
    if ((item.proxy === 'light_v2') && (item.protocolControl === 'outlet_light'))
      return false;
    else
      return (['light_v2', 'fan'].indexOf(item.proxy) >= 0);
  }
}
