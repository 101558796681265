import { LoggerFactory } from './log.service';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Store, Action } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { BrokerService } from './broker.service';

export interface Location {
  type: number;
  typeName: string;
  name: string;
  parentId: number;
  id: number;
  siteId: number;
  siteName: string;
  siteOrder: number;
  buildingId?: number;
  buildingName?: string;
  buildingOrder?: number;
  floorId?: number;
  floorName?: string;
  floorOrder?: number;
  roomId?: number;
  roomName?: string;
  roomOrder?: number;
  protocolId?: number;
  protocolName?: string;
  roomHidden?: boolean;
  tempHidden?: boolean;
  // largeImage: string;
  // smallImage: string;
}

export interface LocationsContext {
  all: Location[];
  ready: boolean;
}

const SLICE_PREFIX = 'CORE:LOCATIONS:';
const SET_LOCATIONS = `${SLICE_PREFIX}SET_LOCATIONS`;

class SetLocations implements Action {
  constructor(public payload: Location[]) { }
  readonly type = SET_LOCATIONS;
}

const INITIAL_STATE: LocationsContext = {
  ready: false,
  all: []
};

type LocationActions = SetLocations;

export function locationsReducer(state: LocationsContext = INITIAL_STATE, action: LocationActions) {
  switch (action.type) {
    case SET_LOCATIONS: return { ...state, ...{ all: action.payload, ready: true } };
    default: return state;
  }
}

@Injectable()
export class LocationsService extends BaseService {
  private _logger = LoggerFactory.getLogger(LocationsService.name);
  ready: Observable<boolean>;

  constructor(
    private broker: BrokerService,
    private store: Store<{ locations: LocationsContext }>
  ) {
    super();
    // this.store.select(s => s).subscribe(s => this._logger.debug('store update', s));
    this.ready = this.store.select(s => s.locations.ready);

    this.broker.connected
      .pipe(
        filter(c => !!c)
      )
      .subscribe(c => {
        this.broker.getObservable<Location[]>({
          path: '/api/v1/locations'
        }).subscribe(locations => {
          this.store.dispatch(new SetLocations(locations));
        });
      });
  }

  getAllLocations(): Observable<Location[]> {
    return this.store.select(s => s.locations.all);
  }

  getRooms(floorId?: number): Observable<Location[]> {
    const rooms = this.store.select(s => s.locations.all)
      .pipe(
        map(locs => locs.filter(loc => loc.type === 8))
      );
    if (!!floorId && floorId > 0) {
      return rooms
        .pipe(
          map(rooms => rooms.filter(rm => rm.floorId === floorId))
        );
    } else {
      return rooms;
    }
  }

  getFloors(buildingId?: number): Observable<Location[]> {
    const floors = this.store.select(s => s.locations.all)
      .pipe(
        map(locs => locs.filter(loc => loc.type === 4))
      );
    if (!!buildingId && buildingId > 0) {
      return floors
        .pipe(
          map(flrs => flrs.filter(f => f.buildingId === buildingId))
        );
    } else {
      return floors;
    }
  }

  getSites(): Observable<Location[]> {
    return this.store.select(s => s.locations.all)
      .pipe(
        map(locs => locs.filter(loc => loc.type === 2))
      );
  }

  getBuildings(siteId?: number): Observable<Location[]> {
    const buildings = this.store.select(s => s.locations.all)
      .pipe(
        map(locs => locs.filter(loc => loc.type === 3))
      );
    if (!!siteId && siteId > 0) {
      return buildings
        .pipe(
          map(bldgs => bldgs.filter(b => b.siteId === siteId))
        );
    } else {
      return buildings;
    }
  }

  getLocationById(id: number): Observable<Location> {
    return this.store.select(s => s.locations.all)
      .pipe(
        map(locs => locs.find(loc => loc.id === id))
      );
  }

  getChildLocations(parentId: number): Observable<Location[]> {
    return this.store.select(s => s.locations.all)
      .pipe(
        map(locs => locs.filter(loc => loc.parentId === parentId))
      );
  }
}
