import { Routes } from "@angular/router";
import { TimersActionComponent } from "./timers-action/timers-action.component";
import { TimersActionTypeComponent } from "./timers-action-type/timers-action-type.component";
import { TimersSelectParametersComponent } from './select-parameters/select-parameters.component';
import { TimersSelectActionComponent } from './select-action/select-action.component';
import { CONDITIONAL_ROUTES } from "../quickstart-flow-control/flow-control.routes";

export const QUICKSTART_ACTIONS_TIMERS_ROUTES: Routes = [{
  path: 'timers', children: [{
    path: '', redirectTo: 'select', pathMatch: 'full'
  },{
    path: 'select', component: TimersActionTypeComponent, children: [{
      path: '', redirectTo: 'device', pathMatch: 'full'
    },{
      path: 'device', component: TimersActionComponent
    }]
  }, {
    path: 'device/:id', component: TimersSelectActionComponent
  }, {
    path: 'device/:id/conditionals', children: [...CONDITIONAL_ROUTES]
  }, {
    path: 'device/:id/:command', component: TimersSelectParametersComponent
  }, {
    path: 'device/:id/:command/conditionals', children: [...CONDITIONAL_ROUTES]
  }]
}];
