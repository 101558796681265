import { EventType } from './event-type.interface';

export const LIGHT: EventType = {
  name: 'Light',
  description: 'When a Light is turned on or off',
  route: 'light',
  category: 'Lights',
  icon: 'lightbulb',
  order: 3,
  whitelist: [
    {
      fields: { proxy: 'light_v2' },
      events: [5100, 5101]
    }
  ]
}
