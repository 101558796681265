import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment';

@Pipe({
  name: 'unixTimeFormat'
})
export class UnixTimeFormatPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let time = parseInt(value);
    if (!isNaN(time)) {
      let m = moment();
      m.hour(Math.floor(time / 60));
      m.minute(Math.floor(time % 60));
      return m.format('h:mm A');
    }

    return 'UNKNOWN TIME';
  }

}
