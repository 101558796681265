import { Pipe, PipeTransform } from '@angular/core';
import { ItemsService } from '../services/items.service';

@Pipe({
  name: 'inARoom'
})
export class InARoomPipe implements PipeTransform {

  constructor(
    private items: ItemsService
  ) { }

  transform(values: Array<any>, args?: any): any {
    if (!!values) {
      return values.filter(event => {
        let item = this.items.getItem(event.deviceId);
        if (!!item) {
          return !!(item.roomId);
        }

        return false;
      });
    } else {
      return values;
    }
  }

}
