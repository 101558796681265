import { CodeItemType } from './../../../common/interfaces/item.interface';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { SharedProgrammingService } from '../../../common/services/shared-programming.service';
import { AGENTS, AgentMetaService } from '../../../common/services/agent-meta.service';
import { Device } from '../../../common/interfaces/item.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { Command } from '../../../common/interfaces/command.interface';
import { BrokerService, LoggerFactory } from '@when-then/core';
import { ProgrammingUtilsService as Utils, CW_CREATOR_ID } from '../../../common/services';
import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';
import { Store } from '@ngrx/store';
import { delay } from 'q';


interface DelayModel {
  interval: number;
  units: string;
}

@Component({
  templateUrl: './delay.component.html',
  styleUrls: ['./delay.component.less']
})
export class DelayComponent extends BaseRoutingComponent implements OnInit {
  private _logger = LoggerFactory.getLogger(DelayComponent.name);
  errors: string[];
  delay: DelayModel;

  ready: Observable<boolean>;

  constructor(
    protected router: Router,
    protected store: Store<any>,
    private route: ActivatedRoute,
    private broker: BrokerService,
    private agents: AgentMetaService,
    private shared: SharedProgrammingService

  ) {
    super();
    this.delay = {
      interval: 1,
      units: '1000'
    };

    this.errors = [];
    this.ready = this.shared.isReady;
  }

  ngOnInit() {
    this.shared.setActionDevice(<Device>this.agents.getAgent(AGENTS.DELAY.ID));
  }

  valueChanged(event: Event) {
    // if (this.delay.interval <= 0) {
    //   this.delay.interval = 1;
    // }
  }

  async addDelay() {
    try {
      this.errors = [];
      if (this.delay.interval <= 0) {
        this.errors.push('Please enter a delay interval greater than zero.')
      }

      if (this.errors.length === 0) {
        const commands = await this.broker.call({
          path: `/api/v1/items/${AGENTS.DELAY.ID}/commands`
        });

        const cmd: Command = commands.find(c => c.command === 'DELAY');
        if (!!cmd) {
          const units = (this.delay.units === '1') ? 'milliseconds' : 'seconds';
          // HACK we don't get a readable display text for this commmand,
          // so compose one on the fly
          cmd.display = `Wait for ${this.delay.interval} ${units}`;
          cmd.params.find(p => p.name === 'time').value = (this.delay.interval * parseInt(this.delay.units));

          const delay = Utils.buildCommandData(CodeItemType.Command, cmd);
          this.shared.setPendingAction(cmd, delay);

          this.router.navigate(['conditionals'], { relativeTo: this.route });
        } else {
          throw new Error('command not found: DELAY');
        }
      }

    } catch (e) {
      this._logger.error(e);
      this.errors.push('An error occurred while trying to add this action.');
    }
  }
}
