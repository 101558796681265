import { Observable, range } from 'rxjs';
import { filter, map, distinctUntilChanged, toArray } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';

import { SharedProgrammingService, CommonProgrammingContext } from './../../../common/services/shared-programming.service';
import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';
import { SchedulesService } from '../schedule.service';
import { Schedules, ScheduleEvent, ScheduleViewModel, IndexedValueLabelPair } from '../+state/schedule.interfaces';
import { DEFAULT_SCHEDULE_VIEW_MODEL, REPEAT_FREQUENCIES, WEEK_DAYS, SUNRISE_SUNSET_OFFSETS, MONTHS, WEEK_OFFSETS } from '../+state/schedule.init';
import { ProgrammingUtilsService as Utils } from '../../../common/services';

import * as moment from 'moment';
import { Logger, LoggerFactory } from '@when-then/core';

const REPEAT_DAYS_MASK = {
  WEEKDAYS: 62,
  WEEKENDS: 65,
  ALL: 127,
  NONE: 0
};
@Component({
  selector: 'prg-add-schedule',
  templateUrl: './add-schedule.component.html',
  styleUrls: ['./add-schedule.component.less']
})
export class AddScheduleComponent extends BaseRoutingComponent implements OnInit, OnChanges {
  private _logger: Logger;
  private _adding = false;

  saveCompleted = true;

  ready: Observable<boolean>;
  editing: Observable<boolean>;
  event: Observable<ScheduleEvent>;
  errors: string[];
  repeatFrequencies = REPEAT_FREQUENCIES;
  repeatDays = WEEK_DAYS;
  fixRepeating: boolean;

  readonly _MONTHS = MONTHS;
  readonly _WEEKS = WEEK_OFFSETS;
  readonly _DAYS = WEEK_DAYS;
  sunriseSunsetOffsets: IndexedValueLabelPair[];

  dateType: string;
  nextTenYears: Observable<Array<number>>;
  vm: ScheduleViewModel;

  constructor(
    public route: ActivatedRoute,
    protected router: Router,
    protected store: Store<{
      scheduleProgramming: Schedules,
      sharedProgramming: CommonProgrammingContext
    }>,
    private shared: SharedProgrammingService,
    private schedules: SchedulesService
  ) {
    super();

    this._logger = LoggerFactory.getLogger(AddScheduleComponent.name);
    this.vm = DEFAULT_SCHEDULE_VIEW_MODEL;
    this.event = this.store.select(s => s.scheduleProgramming.selectedSchedule);
    this.dateType = 'fixed';
    this.sunriseSunsetOffsets = SUNRISE_SUNSET_OFFSETS;
    this.fixRepeating = false;
    this.ready = this.store.select(s => s.scheduleProgramming.ready);

    let now = new Date();
    this.nextTenYears = range(now.getFullYear(), 10).pipe(toArray());

    this.route.params
      .pipe(
        filter(p => !!p),
        distinctUntilChanged(),
        map(params => parseInt(params.id, 10))
      )
      .subscribe(id => {
        if (!isNaN(id) && id === 0) {
          this.schedules.clearSchedule();
          this.vm = { ...DEFAULT_SCHEDULE_VIEW_MODEL };
          const m = moment();
          this.vm.startTime = m.format('HH:mm');
          this.vm.startDate = m.format('YYYY-MM-DD');
          this.vm.endDate = m.format('YYYY-MM-DD');
        }
        // NOTE editing a schedule can only be done from the event view, when the
        // device event corresponding to a schedule is selected an effect will
        // cause the actual schedule event to be retrieved and set in the store,
        // so all we need to do is to react when that happens (below).
      });


    this.event
      .pipe(
        filter(e => !!e),
        distinctUntilChanged()
      )
      .subscribe(schedule => {
        this.vm = this.schedules.parseSchedule(schedule);
      });

    this.editing = this.store.select(s => s.scheduleProgramming.selectedSchedule).pipe(map(s => !!s));
    // this.editing.subscribe(e => this._logger.debug('is editing', e));
  }

  ngOnInit() {
    this.saveCompleted = true;
    this._resetRepeating();
  }

  ngOnChanges(changes: SimpleChanges) {
    this._logger.debug('changes detected', changes);
  }

  createSchedule() {
    if (!this._adding) {
      this._adding = true;
      this.errors = this.schedules.validate(this.vm);
      if (this.errors.length === 0) {
        this.saveCompleted = false;
        this.schedules.updateSchedule(this.vm).then(schedule => {
          this.router.navigate(['when-then/device/100100/event/' + schedule.eventId + '/add'], { relativeTo: this.moduleRoot });
        }, err => {
          if (!!err.errors) {
            this.errors = err.errors;
          } else {
            this.errors = ['An error occured why trying to save this schedule.'];
          }
          this._adding = false;
        });
      } else {
        this._adding = false;
      }
    }
  }

  getLimit(repeatFreq: any): number {
    switch (parseInt(repeatFreq)) {
      case 1: return 1825; // days
      case 2: return 260; // weeks
      case 3: return 60; // months
      case 4: return 5; // years
    }
  }

  updateSchedule() {
    this.saveCompleted = false;
    this.schedules.updateSchedule(this.vm).then(res => {
      this.goToEvent();
    }, err => {
      if (!!err.errors) {
        this.errors = err.errors;
      } else {
        this.errors = ['An error occured why trying to update this schedule.'];
      }
    });
  }

  setSunriseSunsetOffsetType(event) {
    const parts = event.target.value.split(':');
    if (parts.length === 2) {
      this.vm.sunriseSunsetOffsetType = parts[0];
      this.vm.sunriseOrSunset = parts[1];
    }
  }

  repeatRateChanged(event) {
    const val = parseInt(event.target.value);
    const max = parseInt(event.target.max);
    if (val > max) {
      this.vm.repeatRate = max;
    }
  }

  supportsInterval() {
    return this.vm.sunriseSunsetOffsetType.startsWith('before') || this.vm.sunriseSunsetOffsetType.startsWith('after');
  }

  setFrequency(event) {
    this.vm.repeatFrequency = parseInt(event.target.value, 10);
    const max = this.getLimit(this.vm.repeatFrequency);
    if (this.vm.repeatRate > max) {
      this.vm.repeatRate = max;
    }
  }

  isChecked(dow: number): boolean {
    return Utils.bitTest(this.vm.repeatDays, dow);
  }

  selectWeekends() {
    this.vm.repeatDays = REPEAT_DAYS_MASK.WEEKENDS;
  }

  selectWeekdays() {
    this.vm.repeatDays = REPEAT_DAYS_MASK.WEEKDAYS;
  }

  selectAll() {
    this.vm.repeatDays = REPEAT_DAYS_MASK.ALL;
  }

  selectNone() {
    this.vm.repeatDays = REPEAT_DAYS_MASK.NONE;
  }

  toggleDay(dow: number) {
    this.vm.repeatDays = Utils.bitFlip(this.vm.repeatDays, dow);
  }

  repeatChanged() {
    // this._logger.log('VIEW: repeat changed', this.vm);
  }

  validateDateRange(): boolean {
    return !this.vm.hasEndDate || moment(this.vm.startDate).isBefore(moment(this.vm.endDate));
  }

  private _resetRepeating() {
    this.vm.repeatType = 'every';
  }
}
