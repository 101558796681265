import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { BrokerService } from '@when-then/core';

import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';
import { ItemsState } from '../../../common/services/items.service';
import { Device } from '../../../common/interfaces/item.interface';

import { MediaProgrammingContext } from '../quickstart-media.service';
import { StreamingServices } from './drivers/services';

@Component({
  templateUrl: './select-streaming-category.component.html'
})
export class QSMediaSelectStreamingCategoryComponent extends BaseRoutingComponent {

  public driver: Observable<Device>;
  public categories: Observable<string[]>;

  public readonly display = {
    Albums: 'An Album',
    Artists: 'An Artist',
    Stations: 'A Station',
    Playlists: 'A Playlist',
    Tracks: 'A Track'
  }

  constructor(
    public router: Router,
    private _route: ActivatedRoute,
    protected store: Store<{
      mediaProgramming: MediaProgrammingContext,
      programmingItems: ItemsState
    }>
  ) {
    super();

    this.driver = combineLatest(
      this._route.params,
      this.store.select(s => s.programmingItems.itemsList)
    )
      .pipe(
        filter(([params, items]) => !!params && !!items && items.length > 0),
        map(([params, items]) => <Device>items.find(item => item.id == params['driverId']))
      );

    this.categories = this.driver
      .pipe(
        map(driver => {
          const svc = StreamingServices.find(svc => svc.protocolFilename == driver.protocolFilename);
          const categories = [];
          ['Albums', 'Artists', 'Stations', 'Playlists', 'Tracks'].forEach(cat => {
            !!svc[cat] ? categories.push(cat) : null;
          });

          return categories;
        }
        )
      );
  }
}
