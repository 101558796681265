import { CommandType } from './command-type.interface';

export const SPRINKLER_SYSTEM: CommandType = {
  name: 'Sprinkler System',
  description: 'Turn on, off or toggle a sprinkler system',
  route: 'sprinkler-system',
  category: 'Landscaping',
  order: 0,
  whitelist: [
    {
      proxy: 'relaysingle_sprinklers_c4',
      commands: [
        {
          id: 0,
          command: 'CLOSE',
          icon: 'water'
        },
        {
          id: 1,
          command: 'OPEN',
          icon: 'water'
        },
        {
          id: 2,
          command: 'TOGGLE',
          icon: 'water'
        }
      ]
    }
  ]
}
