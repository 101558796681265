/**
 * Copyright 2017 Control4 Corporation. All rights reserved.
 * Control4 Confidential and Proprietary Information.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
// import { DragulaModule } from 'ng2-dragula/ng2-dragula';

import { UIModule } from '@when-then/ui';
import { CoreModule } from '@when-then/core';
import { AuthModule } from '@when-then/auth';

import { ProgrammingRoutingModule } from './programming.routes';
import { ProgrammingCommonModule } from './common/common.module';
import { QuickstartsProgrammingModule } from './quickstarts/quickstarts.module';
// import { AdvancedProgrammingModule } from './advanced/advanced.module';

import { ProgrammingComponent } from './programming.component';
import { PROGRAMMING_REDUCERS } from './programming.stores';

export const MODULE_ROOT: Function = ProgrammingComponent;

@NgModule({
  imports: [
    // --- angular
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    CommonModule,
    // --- router
    RouterModule,
    // --- @ngrx
    StoreModule,
    // --- third-parties
    // DragulaModule,
    // --- framework
    CoreModule,
    AuthModule,
    UIModule,
    // --- local
    ProgrammingCommonModule,
    // AdvancedProgrammingModule,
    QuickstartsProgrammingModule,
    ProgrammingRoutingModule,
  ],
  declarations: [
    ProgrammingComponent
  ],
  providers: [
  ],
  exports: [
    ProgrammingComponent
  ]
})
export class ProgrammingModule { }
