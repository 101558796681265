import { Observable, combineLatest } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ProgrammingUtilsService as Utils } from './../../common/services/utils.service';
import { SharedProgrammingService, CommonProgrammingContext } from '../../common/services/shared-programming.service';
import { BaseRoutingComponent } from '../../common/base-routing/base-routing.component';
import { Event } from '../../common/interfaces/event.interface';
import { Schedules, ScheduleEvent } from '../quickstart-schedule/+state/schedule.interfaces';
import { Logger, LoggerFactory } from '@when-then/core';

@Component({
  selector: 'prg-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.less']
})
export class EventComponent extends BaseRoutingComponent implements OnInit {

  private _logger: Logger;

  title: Observable<string>;
  editing: Observable<boolean>;
  ready: Observable<boolean>;
  event: Observable<Event>;
  adding: Observable<boolean>;
  waitMessage: Observable<string>;
  schedule: Observable<ScheduleEvent>;

  expanded = true;

  constructor(
    protected router: Router,
    protected shared: SharedProgrammingService,
    private utils: Utils,
    protected store: Store<{
      sharedProgramming: CommonProgrammingContext,
      scheduleProgramming: Schedules
    }>,
    private route: ActivatedRoute
  ) {
    super();
    this._logger = LoggerFactory.getLogger(EventComponent.name);

    this.event = this.store.select(s => s.sharedProgramming.trigger.event).pipe(distinctUntilChanged());
    this.title = this.store.select(s => s.sharedProgramming.viewTitle).pipe(distinctUntilChanged());
    this.editing = this.store.select(s => s.sharedProgramming.editing).pipe(distinctUntilChanged());
    this.adding = this.store.select(s => s.sharedProgramming.adding).pipe(distinctUntilChanged());
    this.waitMessage = this.store.select(s => s.sharedProgramming.waitMessage);
    this.schedule = this.store.select(s => s.scheduleProgramming.selectedSchedule);

    this.ready = combineLatest(
      this.store.select(s => s.sharedProgramming.ready),
      this.store.select(s => s.scheduleProgramming.ready),
      (a, b) => (a && b)
    ).pipe(distinctUntilChanged());


    // NOTE don't wait for distinct changes, route params will only change
    // when the page is being loaded again
    this.route.params.subscribe((params: any) => {
      // this.shared.clearSelectedEvent();
      this._logger.debug('event.cmp: route params changed', params);
      // NOTE route params are strings
      let eid = parseInt(params.eventId, 10);
      let did = parseInt(params.deviceId, 10);
      if (!isNaN(eid) && !isNaN(did) && did > 0) {
        this._logger.debug('event route params', did, eid);
        this.shared.fetchEvent(did, eid);
      }
    });
  }

  ngOnInit() {
    this.shared.clearError();
    this._logger.debug('event.cmp: event in context on init: ', Utils.snapshot(this.event));
  }

  ngOnDestroy() {
    this.shared.clearSelectedEvent();
  }

  isActive(path: string): boolean {
    return (window.location.href.indexOf(path) >= 0);
  }

  addClicked() {
    this.router.navigate(['when-then/add'], { relativeTo: this.moduleRoot });
  }
}
