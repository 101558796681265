import { EventType } from './event-type.interface';

export const GATE: EventType = {
  name: 'Gate',
  description: 'When a Gate opens or closes',
  route: 'gate',
  category: 'Security & Access',
  icon: 'gate',
  whitelist: [
    {
      fields: { proxy: 'contactsingle_gate_c4' },
      events: [1, 2]
    },
    {
      fields: { proxy: 'relaycontact_electronicgate_c4' },
      events: [1, 2]
    },
    {
      fields: { proxy: 'relaysingle_electronicgate_c4' },
      events: [1, 2]
    },
  ]
}
