import { Store } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { SharedProgrammingService } from '../../common/services/shared-programming.service';
import { QuickstartProgrammingService } from './../services/quickstart-programming.service';
import { SchedulesService } from './schedule.service';
import { BaseRoutingComponent } from '../../common/base-routing/base-routing.component';

@Component({
  selector: 'prg-schedule-quickstart',
  templateUrl: './schedule-quickstart.component.html',
  styleUrls: ['./schedule-quickstart.component.less']
})
export class ScheduleQuickstartComponent extends BaseRoutingComponent implements OnInit {

  constructor(
    protected router: Router,
    protected store: Store<any>,
    private schedules: SchedulesService,
    private shared: SharedProgrammingService,
    private quickstarts: QuickstartProgrammingService
  ) {
    super();

    // this.store.select(s => s).distinctUntilChanged().subscribe(s => console.log('STATE: ', s));
  }

  ngOnInit() {
    this.shared.clearError();
  }

}
