// angular
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// internal
import { QSEmailNotificationComponent } from './email-notification/email-notification.component';
import { QSPushNotificationComponent } from './push-notification/push-notification.component';
import { QSSelectPushNotificationComponent } from './select-notification/select-notification.component';
import { QSSelectAnnouncementComponent } from './select-announcement/select-announcement.component';
import { CONDITIONAL_ROUTES } from '../quickstart-flow-control/flow-control.routes';

export const QUICKSTART_ACTIONS_NOTIFICATIONS_ROUTES: Routes = [
  { path: 'notify/push/select', component: QSSelectPushNotificationComponent },
  { path: 'notify/push/select/conditionals', children: [...CONDITIONAL_ROUTES] },
  { path: 'notify/push/:command/:id', component: QSPushNotificationComponent },
  { path: 'notify/push/:command/:id/conditionals', children: [...CONDITIONAL_ROUTES] },
  { path: 'notify/email/:command/:id', component: QSEmailNotificationComponent },
  { path: 'notify/email/:command/:id/conditionals', children: [...CONDITIONAL_ROUTES] },
  { path: 'notify/announcement/select', component: QSSelectAnnouncementComponent },
  { path: 'notify/announcement/select/conditionals', children: [...CONDITIONAL_ROUTES] }
];
