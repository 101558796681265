import { EventType } from './event-type.interface';

export const HEAT: EventType = {
  name: 'Heat',
  description: 'When a heat sensor is triggered',
  route: 'heat',
  category: 'Sensors',
  icon: 'heat-2',
  whitelist: [
    {
      fields: { proxy: 'contactsingle_heatdetector_c4' },
      events: [1, 2]
    }
  ]
}
