import { BrokerService } from '@when-then/core';
import { Injectable } from '@angular/core';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { ProgrammingUtilsService as Utils } from './utils.service';
import { Event } from '../interfaces/event.interface';

export interface ProgrammingEventsState {
  ready: boolean;

  // These are optional so we can
  // determine whether we've asked
  // for the events yet or not
  allEvents?: Event[];
  programmedEvents?: Event[];
}

const INITIAL_STATE: ProgrammingEventsState = {
  ready: false
};

const STORE_NAME: string = 'PROGRAMMING:EVENTS:';
const SET_ALL_EVENTS: string = STORE_NAME + 'SET_ALL_EVENTS';
const SET_PROGRAMMED_EVENTS: string = STORE_NAME + 'SET_PROGRAMMED_EVENTS';

export function programmingEventsReducer(state: ProgrammingEventsState = INITIAL_STATE, { type, payload }) {
  if (type.indexOf(STORE_NAME) > -1) {
    // console.debug('DEBUG >> %s called with %O', type, payload);
  }

  switch (type) {
    case SET_ALL_EVENTS: return Object.assign({}, state, { allEvents: payload });
    case SET_PROGRAMMED_EVENTS: return Object.assign({}, state, { ready: true, programmedEvents: payload });
  }

  return state;
}

@Injectable()
export class EventsService {

  private _allEventsSub: Subscription;
  private _programmedEventsSub: Subscription;

  ready: Observable<boolean>;
  allEvents: Observable<Event[]>;
  programmedEvents: Observable<Event[]>;

  constructor(
    private broker: BrokerService,
    private utils: Utils,
    private store: Store<{ programmingEvents: ProgrammingEventsState }>
  ) {
    this.allEvents = this.store.select(s => s.programmingEvents.allEvents);
    this.programmedEvents = this.store.select(s => s.programmingEvents.programmedEvents);

    // This service is only ready once we've heard back
    // from the /events endpoint.
    this.ready = this.store.select(s => s.programmingEvents.ready);

    this.broker.connected
      .pipe(
        filter(c => !!c)
      )
      .subscribe(c => {
        this._watchEvents();
      })
  }

  // NOTE we get all events back whenever anything changes
  private _handleAllEventsUpdates(updates: Event[]) {
    if (!!updates) {
      if (!Array.isArray(updates)) {
        updates = [].concat(updates);
      }
    } else {
      updates = [];
    }

    this.store.dispatch({ type: SET_ALL_EVENTS, payload: updates });
  }

  private _handleProgrammedEventsUpdates(updates: Event[]) {
    if (!!updates) {
      if (!Array.isArray(updates)) {
        updates = [].concat(updates);
      }
    } else {
      updates = [];
    }

    this.store.dispatch({ type: SET_PROGRAMMED_EVENTS, payload: Utils.resolveProtected(updates) });
  }

  private _unwatchEvents() {
    if (!!this._allEventsSub) {
      this._allEventsSub.unsubscribe();
      this._allEventsSub = null;
    }

    if (!!this._programmedEventsSub) {
      this._programmedEventsSub.unsubscribe();
      this._programmedEventsSub = null;
    }
  }

  private _watchEvents() {
    this._unwatchEvents();

    // this subscription is updated any time a new device is added to the project and is used
    // to re-evaluate automation capabilities
    this._allEventsSub = this.broker.getObservable<Event[]>({
      path: '/api/v1/items/events',
      queryString: {
        allevents: true,
        fields: 'name,eventId,deviceId,display,deviceName,deviceRoomName,params'
      }
    })
      .pipe(
        filter(ev => !!ev && ev.length > 0)
      )
      .subscribe(events => {
        this._handleAllEventsUpdates(events);
      });

    // this subscription only watches for changes to codeitems on events and us used to populate
    // lists of existing programming
    this._programmedEventsSub = this.broker.getObservable<Event[]>({
      path: '/api/v1/items/events'
    })
      // We need to filter on whether the endpoint
      // returned an error or not. Arrays of length 0 are
      // legitimate.
      .pipe(
        filter(Array.isArray)
      )
      .subscribe(events => {
        this._handleProgrammedEventsUpdates(events);
      })

    // DEBUG
    // this.allEvents.subscribe(events => {
    //   console.log('roomdevice events', events.filter(evt => evt.display.indexOf('Hallway') > -1));
    // })
  }
}
