import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'prg-form-modal',
  templateUrl: './form-modal.component.html',
  styleUrls: ['./form-modal.component.less']
})
export class FormModalComponent implements OnInit {

  @Input() title: string;
  @Input() confirmLabel: string;
  @Input() confirmClass: string;
  @Input() disabled: boolean;

  @Output() confirm: EventEmitter<any>;
  @Output() cancel: EventEmitter<any>;

  constructor() {
    this.confirm = new EventEmitter<any>();
    this.cancel = new EventEmitter<any>();
  }

  ngOnInit() {
  }

}
