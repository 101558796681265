import { Logger, LoggerFactory } from './log.service';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { StorageService } from './storage.service';
import { SubState } from '../interfaces/store';
import { IReducerArgument } from '../interfaces/reducer';
import { C4Environment } from '../../environments/base';

const CONFIG_INITIAL_STATE: SubState = { loaded: false };

export const UPDATE_COMMON_CONFIG: string = 'UPDATE_COMMON_CONFIG';

export function commonConfigReducer(state: SubState = CONFIG_INITIAL_STATE, { type, payload }: IReducerArgument): SubState {
  switch (type) {
    case UPDATE_COMMON_CONFIG:
      return { ...payload, loaded: true }
  }

  return state;
};

@Injectable()
export class CommonConfigService {
  private _logger: Logger;
  commonConfig: Observable<any>;
  loaded: Observable<boolean>;

  constructor(
    private store: Store<any>,
    private http: HttpClient,
    private storage: StorageService
  ) {
    this._logger = LoggerFactory.getLogger(CommonConfigService.name);
    this.loaded = this.store.select(s => s.commonConfig.loaded);
  }

  loadConfig(buildEnv: C4Environment) {
    // NOTE was clearing everything, let's be a little selective about what we
    // remove so non-critical settings (skipWelcome, etc.), can be preserved
    this.storage.remove('brokerAddr');
    this.storage.remove('JWT');
    this.storage.remove('jwt');
    this.storage.remove('authToken');
    this.storage.remove('appKey');
    this.storage.remove('trackingId');
    this._logger.debug('previous configuration cleared');

    // first, read build-time environment provided by the angular CLI
    let config = { ...buildEnv };
    this._logger.debug('build configuration added');

    // second, read runtime config from deployed file
    this.http.get('assets/config/env.json', {responseType: 'json'}).subscribe(res => {
      this._logger.debug('deployment configuration read');
      if (!!res) {
        config = { ...config, ...res };
      }
      this._logger.debug('deployment configuration added');

      this._evalQueryString(config);
    }, err => {
      this._logger.error('error reading deployment config (assets/config/env.json), the deployment may not be configured correctly', err);
      this._evalQueryString(config);
    });
  }

  private _evalQueryString(config: any) {
    // third, read parameters given in the query string
    config.isRemote = false;

    // this.storage.set('queryString', window.location.search);
    let qs = window.location.search.substring(1).split('&');
    qs.forEach(nv => {
      let parts = nv.split('=');
      this.storage.set(parts[0], parts[1]);
      if (parts[0].toLowerCase() === 'jwt') {
        config.isRemote = true;
        config.authToken = parts[1];
      }
    });

    this._logger.debug('query string configuration added');
    this.storage.setAll(config);
    this.store.dispatch({ type: UPDATE_COMMON_CONFIG, payload: config });
  }
}
