import { Routes } from '@angular/router';

import { SelectKeypadButtonComponent } from './select-keypad-button/select-keypad-button.component';
import { SelectKeypadComponent } from './select-keypad/select-keypad.component';
import { SelectKeypadInteractionComponent } from './select-keypad-interaction/select-keypad-interaction.component';

const KEYPAD_QUERY: any = {
  $and: [{
    proxy: { $in: ['keypad_proxy'] }
  }, {
    type: 7
  }]
};

export const QUICKSTART_TRIGGERS_KEYPADS_ROUTES: Routes = [{
  path: 'keypads', children: [{
    path: '', redirectTo: 'select', pathMatch: 'full'
  }, {
    path: 'select', component: SelectKeypadComponent
  }, {
    path: 'buttons', component: SelectKeypadButtonComponent
  }, {
    path: 'interactions', component: SelectKeypadInteractionComponent
  }]
}];
