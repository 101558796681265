<div [cxWait]="(ready | async)" style="margin: 12px 0;">
  <div class="alert" *ngIf="!(searchText | async) && (ready | async) && (source?.length === 0)" class="alert">
    <header>
      <span class="icon information grey small"></span>
      <h5 class="grow">No Automation Found</h5>
    </header>
    <p>There is no automation to be displayed. The currently selected filter might be hiding some automation. You can
      try selecting a different filter, or <a [routerLink]="['../add']">create some new automation </a>.
    </p>
  </div>

  <div class="alert" *ngIf="(searchText | async) && (ready | async) && (source?.length === 0)">
    <header>
      <span class="icon information grey small"></span>
      <h5 class="grow">No Matching Automation </h5>
    </header>
    <p class="grow">No automation matches the search text you have entered. Try searching for room names, device names
      or specific actions, such as "opened" or "tapped".</p>
  </div>

  <prg-when-then-header *ngIf="(source?.length > 0)"></prg-when-then-header>
  <cxScrollable>
    <div *ngFor="let group of source">
      <header>
        <h6>{{group.name}}</h6>
      </header>
      <div class="grow" prg-event-codeitems [expanded]="expanded" *ngFor="let item of group.events" [event]="item" [routerLink]="['../device/' + item.deviceId + '/event/' + item.eventId + '/edit']"></div>
    </div>
  </cxScrollable>
</div>
