import { AgentMetaService } from './../services/agent-meta.service';
import { Event } from './../interfaces/event.interface';
import { ItemsService } from './../services/items.service';
import { Pipe, PipeTransform } from '@angular/core';
import { SharedProgrammingService } from '../services/shared-programming.service';
import { DeviceCategoryService } from '../services/device-category.service';

import * as _ from 'lodash';

@Pipe({
  name: 'groupByCategory'
})
export class GroupByCategoryPipe implements PipeTransform {

  constructor(
    private items: ItemsService,
    private agents: AgentMetaService,
    private shared: SharedProgrammingService,
    private categories: DeviceCategoryService
  ) { }

  transform(events: Event[], args?: any): any {
    // console.log('pipe: >>> groupbycat', events);
    this.shared.ready(false);

    let catMap = {};

    let eventsWithoutCat = {
      name: 'Programming Not of a Specific Type',
      events: []
    };

    if (events) {
      events.forEach(evt => {
        let device = this.items.getItem(evt.deviceId);
        // console.log('pipe: groupbycat: device is', device);
        if (device) {
          let devcat = undefined;

          if (device.type === 9) {
            devcat = this.agents.getNameForId(device.id);
          } else {
            devcat = this.categories.getItemCategory(device);
          }

          if (devcat) {
            devcat = _.startCase(_.toLower(devcat));
          }

          // console.log('pipe: groupbycat: devcat is', devcat);
          let cat = catMap[devcat];
          // console.log('pipe: groupbycat: cat is', cat);
          if (cat) {
            cat.events.push(evt);
          } else {
            catMap[devcat] = {
              name: devcat,
              events: [evt]
            };
          }
        } else {
          eventsWithoutCat.events.push(evt);
        }
      });
    }

    let catList = [];
    Object.keys(catMap).forEach(key => catList.push(catMap[key]));

    if (eventsWithoutCat.events.length > 0) {
      catList.push(eventsWithoutCat);
    }

    this.shared.ready(true);
    // console.log('pipe: <<< groupbycat', catList);
    return catList;
  }

}
