import { Pipe, PipeTransform } from '@angular/core';
import { SharedProgrammingService } from './../services/shared-programming.service';
import { ItemsService } from '../services/items.service';

@Pipe({
  name: 'notInARoom'
})
export class NotInARoomPipe implements PipeTransform {

  constructor(
    private shared: SharedProgrammingService,
    private items: ItemsService
  ) { }

  transform(values: Array<any>, args?: any): any {
    if (!!values) {
      return values.filter(event => {
        let item = this.items.getItem(event.deviceId);
        if (!!item) {
          return !(item.roomId);
        } else {
          return false;
        }
      });
    } else {
      return values;
    }
  }

}
