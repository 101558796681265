import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { UIModule } from '@when-then/ui';

import { ProgrammingCommonModule } from '../../common/common.module';
import { QuickstartCommonModule } from '../quickstart-common/quickstart-common.module';

import { QSSimpleService } from './quickstart-simple.service';

import { QSSimpleComponent } from './quickstart-simple.component';
import { QSSimpleSelectDeviceComponent } from './select-device/select-device.component';
import { QSSimpleSelectEventComponent } from './select-event/select-event.component';
import { QSSimpleSelectActionComponent } from './select-action/select-action.component';
import { QSSimpleSelectParamsComponent } from './select-params/select-params.component';
import { QSSimpleSelectEventDeviceComponent } from './select-event-device/select-event-device.component';
import { QSSimpleSelectActionDeviceComponent } from './select-action-device/select-action-device.component';
import { QuickstartFlowControlModule } from '../quickstart-flow-control/flow-control.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    UIModule,
    QuickstartFlowControlModule,
    ProgrammingCommonModule,
    QuickstartCommonModule
  ],
  declarations: [
    QSSimpleComponent,
    QSSimpleSelectDeviceComponent,
    QSSimpleSelectEventDeviceComponent,
    QSSimpleSelectActionDeviceComponent,
    QSSimpleSelectEventComponent,
    QSSimpleSelectActionComponent,
    QSSimpleSelectParamsComponent
  ]
})
export class QuickstartSimpleModule { }
