import { Item, Device } from './../../common/interfaces/item.interface';
import { REMOTES_ACTIONS } from './quickstart-remotes.actions';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { BrokerService, LoggerFactory, Location, LocationsService } from '@when-then/core';

import { Event } from '../../common/interfaces/event.interface';
import { SharedProgrammingService, CommonProgrammingContext } from '../../common/services/shared-programming.service';
import { ProgrammingUtilsService as Utils } from '../../common/services/utils.service';

export interface RemoteProgrammingContext {
  room: Location;
  events: Event[];
  remotes?: Device[];
}

const INITIAL_STATE: RemoteProgrammingContext = {
  room: undefined,
  events: []
};

export function remoteProgrammingReducer(state: RemoteProgrammingContext = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case REMOTES_ACTIONS.SET_SELECTED_ROOM: return Object.assign({}, state, { room: payload });
    case REMOTES_ACTIONS.SET_ROOM_EVENTS: return Object.assign({}, state, { events: payload });
    case REMOTES_ACTIONS.SET_REMOTES: return Object.assign({}, state, { remotes: payload });

    default: return state;
  }
}

export const SUPPORTED_SYSTEM_REMOTE_PROXIES = [
  'control4_sr260'
];

const EVENT_WHITELIST = [
  // 2072, // red button
  // 2073, // green button
  // 2074, // yellow button
  // 2075, // blue button
  2084, // custom 1
  2085, // custom 2
  2086  // custom 3
];

@Injectable()
export class QuickstartRemotesService {
  private _logger = LoggerFactory.getLogger(QuickstartRemotesService.name);

  roomEvents: Observable<Event[]>;
  allRooms: Observable<Location[]>;
  selectedRoom: Observable<Location>;
  selectedEvent: Observable<Event>;

  constructor(
    private store: Store<{ remoteProgramming: RemoteProgrammingContext, sharedProgramming: CommonProgrammingContext }>,
    // HACK can't know if this has to be injected to ensure service initialization or whether it's really unused
    private shared: SharedProgrammingService,
    private locations: LocationsService,
    private broker: BrokerService
  ) {
    this.allRooms = this.locations.getRooms();

    this.roomEvents = this.store.select(s => s.remoteProgramming.events);
    this.selectedRoom = this.store.select(s => s.remoteProgramming.room);
    this.selectedEvent = this.store.select(s => s.sharedProgramming.trigger.event);
  }

  setSelectedRoom(room: Location) {
    // NOTE clear the events regardless of our current state
    this.store.dispatch({ type: REMOTES_ACTIONS.SET_ROOM_EVENTS, payload: [] });
    this.store.dispatch({ type: REMOTES_ACTIONS.SET_SELECTED_ROOM, payload: room });
    if (room) {
      this._getRoomCustomButtonEvents(room);
    }
  }

  getSelectedEvent(event: Event): Promise<Event> {
    return new Promise<Event>((resolve, reject) => {
      this._logger.debug('rem-svc: setting selected event', event);
      if (event && event.eventId) {
        let room: Location = Utils.snapshot<Location>(this.selectedRoom);
        this._logger.debug('rem-svc: selected room is ', room);
        if (room) {
          this.broker.call({
            path: '/api/v1/items/' + room.id + '/events/' + event.eventId
          }).then(
            res => {
              this._logger.debug('find system remote button event', res);
              resolve(res[0]);
            },
            err => {
              this._logger.error('remotes-svc: error getting canonical event for remote button', err);
              reject(err);
            });
        } else {
          this._logger.error('remotes-svc: invalid state, no room selected when setting button event');
          reject({ message: 'invalid state, no room selected when setting button event' });
        }
      } else {
        this._logger.error('remotes-svc: invalid state, missing or invalid event given when selecting button', event);
        reject({ message: 'invalid state, missing or invalid event given when selecting button' });
      }
    });
  }

  private _getRoomCustomButtonEvents(room: Location) {
    this.broker.call({
      path: '/api/v1/items/' + room.id + '/events',
      queryString: {
        allevents: true
      }
    }).then((res: Event[]) => {
      for (var i = 0; i < res.length; i++) {
        var evt: any = res[i];
        if (evt.name === 'Commands') {
          let filtered = evt.params[0].values.filter((val: any) => (EVENT_WHITELIST.indexOf(val.eventId) >= 0));
          this.store.dispatch({ type: REMOTES_ACTIONS.SET_ROOM_EVENTS, payload: filtered });
          break;
        }
      }
    });
  }
}
