import { EventType } from './event-type.interface';

export const ROOM_MEDIA_PAUSED: EventType = {
  name: 'Room Media Paused',
  description: 'When the Pause Audio/Video command is received by a room',
  route: 'rooms/2051',
  category: 'Room Media',
  icon: 'pause',
  whitelist: [
    {
      fields: { proxy: 'roomdevice' },
      events: [2051]
    }
  ]
}
