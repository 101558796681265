import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import {
  ROUTER_NAVIGATION, ROUTER_CANCEL, ROUTER_ERROR
} from '@ngrx/router-store';

export interface RouterHistoryState {
  connected: boolean;
  history: {
    path: string
  }[];
}

export const ROUTER_HISTORY_INITIAL_STATE: RouterHistoryState = {
  connected: false,
  history: []
}

const ACTION_STRINGS = [
  ROUTER_NAVIGATION,
  ROUTER_CANCEL,
  ROUTER_ERROR
];

export function routerHistoryReducer(state: RouterHistoryState = ROUTER_HISTORY_INITIAL_STATE, { type, payload }): RouterHistoryState {

  switch (type) {
    case ROUTER_NAVIGATION:
      let p = {
        path: payload.routerState.url.split('?')[0]
      }
      return Object.assign({}, state, { history: [p, ...state.history], connected: true });
  }

  return state;

}

@Injectable()
export class RouterHistoryService {

  public pathHistory: Observable<string[]>;

  constructor(
    private _store: Store<{
      routerHistory: RouterHistoryState
    }>
  ) {

    this.pathHistory = this._store.select(s => s.routerHistory).pipe(
      map(s => s.history.map(s => s.path))
    );

  }

}
