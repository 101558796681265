import { EventType } from './event-type.interface';

export const CARBON_MONOXIDE: EventType = {
  name: 'Carbon Monoxide',
  description: 'When a Carbon Monoxide detector indicates the presence of CO',
  route: 'carbon-monoxide',
  category: 'Safety',
  icon: 'detect-co',
  whitelist: [
    {
      fields: { proxy: 'contactsingle_carbonmonoxidedetector_c4' },
      events: [1, 2]
    }
  ]
}
