import { NgModule } from '@angular/core';
import { Routes, Router, RouterModule } from '@angular/router';

import { SelectScreenComponent } from './select-screen/select-screen.component';
import { SelectCustomButtonComponent } from './select-custom-button/select-custom-button.component';
import { SelectCustomButtonInteractionComponent } from './select-custom-button-interaction/select-custom-button-interaction.component';

export const QUICKSTART_TRIGGERS_CUSTOM_BUTTONS_ROUTES: Routes = [{
  path: 'custom', children: [{
    path: '', redirectTo: 'screens', pathMatch: 'full'
  }, {
    path: 'screens', component: SelectScreenComponent
  }, {
    path: 'buttons', component: SelectCustomButtonComponent
  }, {
    path: 'interactions', component: SelectCustomButtonInteractionComponent
  }]
}];
