import { CodeItemType } from './../../../common/interfaces/item.interface';
// angular
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// libs
import { Observable, Subscription } from 'rxjs';
import { take, map, filter, flatMap, debounce } from 'rxjs/operators';
import { Store } from '@ngrx/store';

// C4 libs
import { BrokerService, LoggerFactory } from '@when-then/core';
import { ProgrammingUtilsService as Utils } from './../../../common/services/utils.service';

// common
import { Device } from './../../../common/interfaces/item.interface';
import { ItemsService } from './../../../common/services/items.service';
import { ProgrammingUtilsService as Util } from '../../../common/services/utils.service';
import { SharedProgrammingService, CommandData, SET_ACTION_COMMAND } from '../../../common/services/shared-programming.service';
import { Parameter, ValueType } from '../../../common/interfaces/parameter.interface';
import { Agent } from './interfaces/agent.interface';
import { Command } from '../../../common/interfaces/command.interface';
import { Announcement } from './interfaces/announcement.interface';

// module

const COMMAND_WHITELIST = ['SEND_NOTIFICATION', 'SEND_NOTIFICATION:JSON', 'SEND_EMAIL', 'execute_announcement'];

const NOTIFICATION_CONTROLS = [
  'control4_agent_notification',
  'control4_agent_emailnotification',
  'control4_agent_announcement'
];

export type NotificationAgentType = 'push' | 'email' | 'announcement';

const AGENT_MAP = {
  push: 'control4_agent_notification',
  email: 'control4_agent_emailnotification',
  announcement: 'control4_agent_announcements'
}

export interface PushNotificationTemplate {
  name: string;
  category: number;
  severity: number;
  subject: string;
  message: string;
  timestamp: number;
  attachments: string;
  deviceId: number;
  templateId: number;
}

export interface NotificationProgrammingContext {
  agentType?: 'push' | 'email' | 'announcement';
  agent?: Agent;
  commands?: Command[];
  command?: CommandData;
  templates?: PushNotificationTemplate[];
  announcements?: Announcement[];
}

const INITIAL_STATE: NotificationProgrammingContext = {};

const STORE_NAME = ['PROGRAMMING', 'QUICKSTARTS', 'NOTIFICATIONS'].join(':');

const ACTIONS = {
  SET_AGENT_TYPE: `${STORE_NAME}:SET_AGENT_TYPE`,
  SET_AGENT: `${STORE_NAME}:SET_AGENT`,
  SET_COMMANDS: `${STORE_NAME}:SET_COMMANDS`,
  SET_COMMAND: `${STORE_NAME}:SET_COMMAND`,
  SET_COMMAND_VALUES: `${STORE_NAME}:SET_COMMAND_VALUES`,
  CLEAR: `${STORE_NAME}:CLEAR`,
  SET_PUSH_TEMPLATES: `${STORE_NAME}:SET_PUSH_TEMPLATES`,
  SET_ANNOUNCEMENTS: `${STORE_NAME}:SET_ANNOUNCEMENTS`
}

const ACTION_STRINGS = Object.keys(ACTIONS).map(key => ACTIONS[key]);

export function notificationsProgrammingReducer(state: NotificationProgrammingContext = INITIAL_STATE, { type, payload }): NotificationProgrammingContext {

  // if (ACTION_STRINGS.indexOf(type) > -1) {
  //   console.info('%s called with %O', type, payload);
  // }

  switch (type) {
    case ACTIONS.SET_AGENT_TYPE:
      return Object.assign({}, state, { agentType: payload });

    case ACTIONS.SET_AGENT:
      return Object.assign({}, state, { agent: payload });

    case ACTIONS.SET_COMMANDS:
      return Object.assign({}, state, { commands: payload });

    case ACTIONS.SET_COMMAND:
      return Object.assign({}, state, { command: payload });

    case ACTIONS.SET_COMMAND_VALUES:
      let commands = [...state.commands];
      let commandIx = commands.findIndex(c => {
        return c.command === payload.command.command &&
          c.id === payload.command.id
      });

      commands[commandIx].params = [...payload.params];

      return Object.assign({}, state, { commands: commands });

    case ACTIONS.CLEAR:
      return Object.assign({}, INITIAL_STATE);

    case ACTIONS.SET_PUSH_TEMPLATES:
      return Object.assign({}, state, { templates: payload });

    case ACTIONS.SET_ANNOUNCEMENTS:
      return Object.assign({}, state, { announcements: payload });

    default:
      return state;
  }
}



@Injectable()
export class QSNotificationsService {

  private _logger = LoggerFactory.getLogger(QSNotificationsService.name);
  private _subscriptions: Subscription[] = [];
  private _sentNotificationCommand: Command;

  pushTemplates: Observable<PushNotificationTemplate[]>;
  announcements: Observable<Announcement[]>;

  constructor(
    private _router: Router,
    private _broker: BrokerService,
    private _route: ActivatedRoute,
    private _store: Store<{ notificationsProgramming: NotificationProgrammingContext }>,
    private _shared: SharedProgrammingService,
    private _items: ItemsService
  ) {
    this._monitor();

    this.pushTemplates = this._store.select(s => s.notificationsProgramming.templates);
    this.announcements = this._store.select(s => s.notificationsProgramming.announcements);
  }

  setAgentType = (agent: NotificationAgentType) => this._store.dispatch({
    type: ACTIONS.SET_AGENT_TYPE,
    payload: agent
  });

  setActionCommand(command: Command, params: Parameter[] = []) {
    this._logger.debug('setting action', command, params);
    // Hidden params will have a value set, but
    // no _value. Set it.
    params.filter(p => !!p && p.type === 'HIDDEN').forEach(p => {
      p._value = p._value || p.value;
    });

    // HACK because the email and push forms do not use C4Input the params retain some embedded state
    // from the last use, so make sure that both value fields are in sync,
    params.forEach(p => {
      p._value = p.value;
    });

    this._logger.debug('normalized params list', params);

    let agent = Util.snapshot<Agent>(this._store.select(s => s.notificationsProgramming.agent));
    this._logger.debug('current agent is', agent);

    command.params = params;

    this._store.dispatch({ type: ACTIONS.SET_COMMAND, payload: command });
    this._store.dispatch({ type: SET_ACTION_COMMAND, payload: command });

    const data = Utils.buildCommandData(CodeItemType.Command, command);
    this._logger.debug('setting pending action', command, data);
    this._shared.setPendingAction(command, data);
  }

  updateParam = (evt: ValueType, param: Parameter, command: Command) => {

    const params = [...command.params];
    const pix = params.findIndex(p => p.name === param.name && p.type === param.type);
    if (pix >= 0) {
      const updated = { ...params[pix] };
      updated._display = evt.display;
      delete updated._value;
      updated._value = evt.value;

      params[pix] = updated;
      this.updateParams(params, command);
    } else {
      this._logger.warn('attempt to update a param that does not exist', param);
    }
  }

  updateParams = (params: Parameter[], command: Command) => {
    this._store.dispatch({
      type: ACTIONS.SET_COMMAND_VALUES,
      payload: {
        params: params,
        command: command
      }
    })
  }

  public getPushTemplates = async () => {
    const templates = await this._broker.call({
      path: '/api/v1/agents/push_notification'
    });

    this._store.dispatch({ type: ACTIONS.SET_PUSH_TEMPLATES, payload: templates });
  }

  public getAnnouncements = async () => {
    const announcements = await this._broker.call({
      path: `/api/v1/agents/announcements`
    });

    this._store.dispatch({ type: ACTIONS.SET_ANNOUNCEMENTS, payload: announcements });
  }

  public setSendNotificationCommand = async (template: PushNotificationTemplate) => {
    const commands = await this._store.select(s => s.notificationsProgramming.commands).pipe(take(1)).toPromise();
    const command = commands.find(c => c.command === 'SEND_NOTIFICATION');
    if (!!command) {
      this.setActionCommand(command, [{ name: 'TEMPLATE_ID', type: 'INTEGER', value: template.templateId, _display: template.name }]);
    } else {
      console.error('ERROR >> notifications.service.ts:221: Could not identify send notification command');
    }
  }

  public setSendAnnouncementCommand = async (announcement: Announcement) => {
    const commands = await this._store.select(s => s.notificationsProgramming.commands).pipe(take(1)).toPromise();
    const command = commands.find(c => c.command === 'execute_announcement');
    if (!!command) {
      this.setActionCommand(command, [{ name: 'id', type: 'INTEGER', value: announcement.id, _display: announcement.name }]);
    } else {
      console.error('ERROR >> notifications.service.ts:234: Could not identify announcement command');
    }
  }

  private _monitor = (): void => {
    this._monitorAgentType();
    this._monitorAgent();
  }

  /**
   * Watches the agent type, and
   * populates the agent accordingly
   */
  private _monitorAgentType = (): void => {
    this._subscriptions.push(
      this._store.select(s => s.notificationsProgramming.agentType)
        .pipe(
          map(agentType => AGENT_MAP[agentType]),
          flatMap(control => {
            return this._items.itemsList.pipe(map(items => items.filter((item: Device) => item.control === control)))
          }),
          filter(agents => agents.length < 2),
          map(agents => agents[0] || undefined)
        )
        .subscribe(agent => this._store.dispatch({
          type: ACTIONS.SET_AGENT,
          payload: agent
        }))
    );
  }

  /**
   * Watches the agent and populates the
   * commands accordingly
   */
  private _monitorAgent = (): void => {
    this._subscriptions.push(
      this._store.select(s => s.notificationsProgramming.agent)
        .pipe(
          map(agent => (!!agent && !!agent.URIs && !!agent.URIs.commands) ?
            agent.URIs.commands : undefined)
        )
        .subscribe(uri => {
          if (!!uri) {
            this._broker.call({
              path: uri
            }).then(
              (commands: Command[]) => {
                commands = commands
                  .filter(c => COMMAND_WHITELIST.indexOf(c.command) > -1);
                this._store.dispatch({
                  type: ACTIONS.SET_COMMANDS,
                  payload: commands
                })
              },
              error => console.error('notifications.service.ts: error: %O', error)
            )
          } else {
            this._store.dispatch({
              type: ACTIONS.SET_COMMANDS,
              payload: []
            })
          }
        })
    );
  }

  ngOnDestroy() {
    this._subscriptions.forEach(sub => {
      sub.unsubscribe();
    });

    this.clear();
  }

  clear() {
    this._store.dispatch({
      type: ACTIONS.CLEAR
    });
  }
}
