import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { Event } from './../../../common/interfaces/event.interface';
import { SharedProgrammingService } from './../../../common/services/shared-programming.service';
import { CommonProgrammingContext } from '../../../common/services/shared-programming.service';
import { ProgrammingUtilsService as Utils } from '../../../common/services/utils.service';
import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';
import { EventsService } from './../../../common/services/events.service';
import { ScheduleEvent } from '../../quickstart-schedule/+state/schedule.interfaces';

@Component({
  selector: 'prg-grouped-list',
  templateUrl: './grouped-list.component.html',
  styleUrls: ['./grouped-list.component.less']
})
export class GroupedListComponent extends BaseRoutingComponent implements OnInit {

  ready: Observable<boolean>;
  searchText: Observable<string>;
  schedule: Observable<ScheduleEvent>;
  expanded = false;

  @Input() source: Array<any>;

  constructor(
    protected router: Router,
    protected store: Store<any>,
    protected shared: SharedProgrammingService,
    protected events: EventsService
  ) {
    super();

    this.searchText = this.shared.searchText;
    this.schedule = this.store.select(s => s.scheduleProgramming.selectedSchedule);
    this.ready = this.events.ready;
  }

  ngOnInit() {
  }

  tileClicked(event: Event) {
    this.router.navigate(['when-then', 'device', event.deviceId, 'event', event.eventId, 'edit'], { relativeTo: this.moduleRoot });
  }
}
