import { LoggerFactory } from './../../../../../../libs/core/src/lib/services/log.service';
import { CreatorPipe } from './../../common/pipes/creator.pipe';
import { MatchingSearchTextPipe } from './../../common/pipes/matching-search-text.pipe';
import { EVENTS_UI_ACTIONS, EventsUIService } from './../events/eventsUI.service';
import { Store } from '@ngrx/store';
import { Component, OnInit, Input } from '@angular/core';
import { Observable, combineLatest, of } from 'rxjs';
import { switchMap, filter, map, withLatestFrom } from 'rxjs/operators';

import { SharedProgrammingService } from '../../common/services/shared-programming.service';
import { ProgrammingEventsState } from './../../common/services/events.service';
import { VoiceScenesService } from '../quickstart-voice-scenes/voice-scenes.service';
import { EVENTS_FILTER } from '../events/eventsUI.service';
import { Event } from '../../common/interfaces/event.interface';

@Component({
  selector: 'prg-list-by-name',
  templateUrl: './list-by-name.component.html'
})
export class ListByNameComponent implements OnInit {
  searchText: Observable<string>;
  ready: Observable<boolean>;

  private _logger = LoggerFactory.getLogger(ListByNameComponent.name);

  @Input() source: Event[];

  constructor(
    private shared: SharedProgrammingService,
    private eventsUI: EventsUIService
  ) {
    this.shared.setGrouping('description');
    this.searchText = this.shared.searchText;
  }

  ngOnInit() {
    this.shared.clearError();

    this.ready = combineLatest(
      this.shared.isReady,
      this.shared.initialized,
      of(this.source).pipe(filter(el => !!el)),
      function () {
        return [].slice.apply(arguments).every(p => !!p);
      });
  }

  ngOnChanges() {
    if (!!this.source) {
      this.eventsUI.setEventCount(this.source.length);
    }
  }
}
