import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { UIModule } from '@when-then/ui';

import { ProgrammingCommonModule } from '../../common/common.module';
import { GroupedListComponent } from './grouped-list/grouped-list.component';
import { FlatEventListComponent } from './flat-list/flat-list.component';
import { SelectDeviceComponent } from './select-device/select-device.component';
import { SelectInteractionComponent } from './select-interaction/select-interaction.component';
import { SelectDeviceButtonComponent } from './select-device-button/select-device-button.component';
import { SelectButtonInteractionComponent } from './select-button-interaction/select-button-interaction.component';
import { SelectCommandComponent } from './select-command/select-command.component';
import { CodeitemSummaryComponent } from './codeitem-summary/codeitem-summary.component';
import { CodeitemListComponent } from './codeitem-list/codeitem-list.component';
import { CodeItemIconsComponent } from './code-item-icons/code-item-icons.component';
import { CodeItemComponent } from './code-item/code-item.component';
import { EventCodeitemsComponent } from './event-codeitems/event-codeitems.component';
import { ProxyEventIconPipe } from './pipes/proxy-event-icon.pipe';
import { NavHeaderComponent } from './nav-header/nav-header.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    UIModule,
    ProgrammingCommonModule
  ],
  declarations: [
    SelectInteractionComponent,
    SelectDeviceButtonComponent,
    SelectButtonInteractionComponent,
    SelectCommandComponent,
    SelectDeviceComponent,
    FlatEventListComponent,
    GroupedListComponent,
    CodeitemSummaryComponent,
    CodeitemListComponent,
    CodeItemComponent,
    CodeItemIconsComponent,
    EventCodeitemsComponent,
    ProxyEventIconPipe,
    NavHeaderComponent,
  ],
  exports: [
    SelectDeviceButtonComponent,
    SelectButtonInteractionComponent,
    SelectInteractionComponent,
    SelectDeviceComponent,
    FlatEventListComponent,
    GroupedListComponent,
    CodeitemSummaryComponent,
    CodeitemListComponent,
    CodeItemComponent,
    CodeItemIconsComponent,
    EventCodeitemsComponent,
    ProxyEventIconPipe,
    NavHeaderComponent,
  ]
})
export class QuickstartCommonModule { }
