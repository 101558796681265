import { ProgrammingUtilsService } from './../services/utils.service';
import { Pipe, PipeTransform } from '@angular/core';
import { ItemsService } from '../services/items.service';
import { SharedProgrammingService } from '../services/shared-programming.service';

@Pipe({
  name: 'supportedTriggers'
})
export class SupportedTriggersPipe implements PipeTransform {

  constructor(
    private items: ItemsService,
    private utils: ProgrammingUtilsService,
    private shared: SharedProgrammingService
  ) { }

  transform(triggers: Array<any>, args?: any): any {
    return triggers;
  }

}
