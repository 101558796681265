import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService } from './base.service';

import { BrokerService } from './broker.service';

@Injectable()
export class PlatformService extends BaseService {

  constructor(private broker: BrokerService) {
    super();
  }

  getPlatformStatus(): Promise<any> {
    console.log('platform:getPlatformStatus()');
    return this.broker.call({
      path: '/api/v1/platform_status'
    });
  }

  getCommonName(): Promise<any> {
    console.log('platform:getCommonName()');
    return this.broker.call({
      path: '/api/v1/comon_name'
    });
  }

  watchAPIStatus(): Promise<Observable<any>> {
    console.log('platform:watchAPIStatus()');
    return this.broker.subscribe({
      path: '/api/v1/status'
    });
  }

}
