import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'itemsByNameOrRoom'
})
export class ItemsByNameOrRoomPipe implements PipeTransform {

  transform(items: Array<any>, args?: any): any {
    if (args) {
      if (items && Array.isArray(items)) {
        return items.filter(item => {
          let _name = item.name.toLowerCase();
          let _room = item.roomName.toLowerCase();
          let _text = args.toString().toLowerCase();
          return (_name.indexOf(_text) >= 0 || _room.indexOf(_text) >= 0);
        })
      }
    } else {
      return items;
    }
  }

}
