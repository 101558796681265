import { LoggerFactory } from '@when-then/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { filter, find, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';

const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

import { ProgrammingUtilsService as Utils } from './../../../common/services/utils.service';
import { SharedProgrammingService, CommonProgrammingContext } from './../../../common/services/shared-programming.service';
import { QSNotificationsService } from './../services/notifications.service';
import { Parameter } from '../../../common/interfaces/parameter.interface';
import { NotificationProgrammingContext } from '../services/notifications.service';
import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';
import { Command } from '../../../common/interfaces/command.interface';
import { CodeItemType } from '../../../common/interfaces/item.interface';

interface EmailNotification {
  to: string;
  subject: string;
  message: string;
  timestamp: boolean;
}

@Component({
  selector: 'prg-email-notification',
  templateUrl: './email-notification.component.html',
  styleUrls: ['./email-notification.component.less']
})
export class QSEmailNotificationComponent extends BaseRoutingComponent implements OnInit {
  errors = [];
  private _logger = LoggerFactory.getLogger(QSEmailNotificationComponent.name);
  email: EmailNotification;

  @ViewChild('emailForm') emailForm;

  command: Command;
  params: Parameter[];
  private _routeParams: Observable<any>;
  _ready: Observable<boolean>;

  constructor(
    protected store: Store<{
      notificationsProgramming: NotificationProgrammingContext,
      sharedProgramming: CommonProgrammingContext
    }>,
    private _route: ActivatedRoute,
    protected router: Router,
    private _notifications: QSNotificationsService,
    private _shared: SharedProgrammingService
  ) {
    super();

    this._notifications.setAgentType('email');
    this._ready = this._shared.isReady;
  }

  ngOnInit() {
    this.errors = [];
    this.email = {
      to: '',
      subject: '',
      message: '',
      timestamp: false
    };

    this._routeParams = <Observable<{ command: string, id: string }>>this._route.params;

    combineLatest(
      this._routeParams,
      this.store.select(s => s.notificationsProgramming.commands).pipe(filter(c => !!c)),
      (routeParams, commands) => {
        let id = parseInt(routeParams.id);
        return commands.find(c => c.command === routeParams.command && c.id === id);
      }
    ).pipe(
      filter(c => !!c)
    ).subscribe(c => {
      this.command = c;
      this.params = c.params || [];
    });


  }

  validateAndSave() {
    this._logger.debug('validate and save email notification', this.email);
    this.errors = [];

    if (!this.email.to || this.email.to.length === 0) {
      this.errors.push('An email address is required.');
    } else {
      if (!EMAIL_REGEX.test(this.email.to)) {
        this.errors.push('A valid email address is required.');
      }
    }

    if (this.errors.length === 0) {

      this.params.find(p => p.name === 'to').value = this.email.to;
      this.params.find(p => p.name === 'subject').value = this.email.subject;
      this.params.find(p => p.name === 'message').value = this.email.message;
      this.params.find(p => p.name === 'timestamp').value = this.email.timestamp ? "1" : "0";

      const data = Utils.buildCommandData(CodeItemType.Command, this.command);

      this._logger.debug('setting email commmand', this.command);
      this._notifications.setActionCommand(this.command, this.command.params);

      this.router.navigate(['conditionals'], { relativeTo: this._route });
    }
  }
}
