import { Component } from '@angular/core';

import { QSMediaService } from './quickstart-media.service';

@Component({
  template: '<router-outlet></router-outlet>'
})
export class QSMediaComponent {

  constructor(
    private _svc: QSMediaService
  ) { }
}
