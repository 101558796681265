import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageService } from './storage.service';
import { HttpOptions, HttpMethods } from '../interfaces/http';

export interface ICustomHttpOptions extends HttpOptions {
  observe?: 'body';
  responseType: 'json'
}

export interface IC4Response {
  C4ErrorResponse: any
}

/**
 *
 * Wraps the angular HttpClient service to provide facilties for logging, expired auth detection, etc.
 *
 * @export
 * @class WrappedHttpService
 */
@Injectable()
export class WrappedHttpService {
  constructor(
    private http: HttpClient,
    private storage: StorageService
  ) {
    console.debug('DEBUG >> http.service.ts:20: constructor()');
  }

  request<TRequest = {}, TResponse = {}>(method: HttpMethods, urlOrRequest: string | HttpRequest<TRequest>, options?: HttpOptions): Observable<HttpResponse<TResponse>> {
    console.debug('DEBUG >> http.service.ts:24: request()');
    console.debug('DEBUG >> http.service.ts:25: url: %s', urlOrRequest);
    console.debug('DEBUG >> http.service.ts:26: options: %O', options);
    const obs = urlOrRequest instanceof HttpRequest ? 
        this.http.request(urlOrRequest as HttpRequest<TRequest>) : 
        this.http.request(method, urlOrRequest, options);

    obs.subscribe(res => {
      let data = res.json();
      if (data.C4ErrorResponse) {
        this._handleError(data.C4ErrorResponse);
      }
    }, err => {
      this._handleError(err);
    });

    return obs;
  }

  get<TResponse = {}>(url: string, options?: ICustomHttpOptions): Observable<HttpResponse<TResponse> | Object> {
    console.debug('DEBUG >> http.service.ts:40: get()');
    console.debug('DEBUG >> http.service.ts:41: url: %s', url);
    console.debug('DEBUG >> http.service.ts:42: options: %O', options);

    let obs = this.http.get(url, options);
    console.log('http-wrapper: get');

    obs.subscribe((data: IC4Response) => {
      if (data.C4ErrorResponse) {
        this._handleError(data.C4ErrorResponse);
      }
    }, err => {
      this._handleError(err);
    });

    return obs;
  }

  post<TResponse = {}>(url: string, body: any, options?: ICustomHttpOptions): Observable<HttpResponse<TResponse> | Object> {
    console.debug('DEBUG >> http.service.ts:57: post()');
    console.debug('DEBUG >> http.service.ts:58: url: %s', url);
    console.debug('DEBUG >> http.service.ts:59: body: %O', body);
    console.debug('DEBUG >> http.service.ts:60: options: %O', options);

    let obs = this.http.post(url, body, options);
    console.log('http-wrapper: post');

    obs.subscribe((data: IC4Response) => {
      if (data.C4ErrorResponse) {
        this._handleError(data.C4ErrorResponse);
      }
    }, err => {
      this._handleError(err);
    });

    return obs;
  }

  put<TResponse = {}>(url: string, body: any, options?: ICustomHttpOptions): Observable<HttpResponse<TResponse> | Object> {
    console.debug('DEBUG >> http.service.ts:74: put()');
    console.debug('DEBUG >> http.service.ts:75: url: %s', url);
    console.debug('DEBUG >> http.service.ts:76: body: %O', body);
    console.debug('DEBUG >> http.service.ts:77: options: %O', options);

    let obs = this.http.put(url, body, options);

    obs.subscribe((data: IC4Response) => {
      if (data.C4ErrorResponse) {
        this._handleError(data.C4ErrorResponse);
      }
    }, err => {
      this._handleError(err);
    });

    return obs;
  }

  delete<TResponse = {}>(url: string, options?: ICustomHttpOptions): Observable<HttpResponse<TResponse> | Object> {
    console.debug('DEBUG >> http.service.ts:90: delete()');
    console.debug('DEBUG >> http.service.ts:91: url: %s', url);
    console.debug('DEBUG >> http.service.ts:92: options: %O', options);

    let obs = this.http.delete(url, options);

    obs.subscribe((data: IC4Response) => {
      if (data.C4ErrorResponse) {
        this._handleError(data.C4ErrorResponse);
      }
    }, err => {
      this._handleError(err);
    });

    return obs;
  }

  patch<TResponse = {}>(url: string, body: any, options?: ICustomHttpOptions): Observable<HttpResponse<TResponse> | Object> {
    console.debug('DEBUG >> http.service.ts:106: patch()');
    console.debug('DEBUG >> http.service.ts:107: url: %s', url);
    console.debug('DEBUG >> http.service.ts:108: body: %O', body);
    console.debug('DEBUG >> http.service.ts:109: options: %O', options);

    let obs = this.http.patch(url, body, options);

    obs.subscribe((data: IC4Response) => {
      if (data.C4ErrorResponse) {
        this._handleError(data.C4ErrorResponse);
      }
    }, err => {
      this._handleError(err);
    });

    return obs;
  }

  head<TResponse = {}>(url: string, options?: ICustomHttpOptions): Observable<HttpResponse<TResponse> | Object> {
    console.debug('DEBUG >> http.service.ts:122: head()');
    console.debug('DEBUG >> http.service.ts:123: url: %s', url);
    console.debug('DEBUG >> http.service.ts:124: options: %O', options);

    let obs = this.http.head(url, options);

    obs.subscribe((data: IC4Response) => {
      if (data.C4ErrorResponse) {
        this._handleError(data.C4ErrorResponse);
      }
    }, err => {
      this._handleError(err);
    });

    return obs;
  }

  options<TResponse = {}>(url: string, options?: ICustomHttpOptions): Observable<HttpResponse<TResponse> | Object> {
    console.debug('DEBUG >> http.service.ts:138: options()');
    console.debug('DEBUG >> http.service.ts:139: url: %s', url);
    console.debug('DEBUG >> http.service.ts:140: options: %O', options);

    let obs = this.http.options(url, options);

    obs.subscribe((data: IC4Response) => {
      if (data.C4ErrorResponse) {
        this._handleError(data.C4ErrorResponse);
      }
    }, err => {
      this._handleError(err);
    });

    return obs;
  }

  private _handleError(err) {
    console.debug('DEBUG >> http.service.ts:154: _handleError()');
    console.debug('DEBUG >> http.service.ts:155: err: %O', err);

    if (!!err) {
      switch (err.code || err.status) {
        // client errors
        case 400: break;
        case 401: console.error('http: 401 Unauthorized'); break;
        case 402: break;
        case 403: break;
        case 404: break;
        case 405: break;
        // TODO: others?

        // server errors
        case 500: break;
        case 501: break;
        case 502: break;
        case 503: break;
        case 504: break;
        // TODO: others?

        default: console.error('http: unrecognized/unsupported HTTP status', err);
      }
    }
  }
}
