<svg 
	class="cxSpinner"
	[style.width]="cxDiameter"
	[style.height]="cxDiameter"
	viewBox="0 0 100 100">
		<svg:circle 
			class="cxSpinner-outline"
			cx="50"
			cy="50"
			[attr.stroke-width]="outerLineWeight"
			[attr.r]="50 - offset">
		</svg:circle>
		<svg:path 
			class="cxSpinner-arc"
			[attr.stroke-width]="innerLineWeight"
			[attr.d]="d">
		</svg:path>
</svg>