<!--
 * Copyright 2017 Control4 Corporation. All rights reserved.
 * Control4 Confidential and Proprietary Information.
 -->
<div class="programming">
  <div *ngIf="(_jwtStatus | async) == JWT_STATUS.VALID">
    <router-outlet></router-outlet>
  </div>
  <div class="version flex-row transparent nowrap">
    <span class="grow"></span>
    <span>OS: {{osVersion || 'loading...'}}</span>
    <span (click)="versionClicked($event)">WT: {{wtVersion || 'loading...'}}</span>
  </div>
  <div id="dev-toolbar" *ngIf="!env.production" [@toggleDevBar]="devBar" (mouseenter)="showDev()" (mouseleave)="hideDev()">
    <button (click)="logState()">Log State</button>
  </div>
</div>

<prg-form-modal class="popup" *ngIf="_showLoggingDialog" title="Elevate Logging" confirmLabel="Elevate Logging" confirmClass="blue"
  (confirm)="elevateLogging()" (cancel)="_showLoggingDialog = false">

  <form action="">
    <div class="field-group">
      <label class="fg-white" for="level" class="leading">Set Level to</label>
      <select name="level" id="level" [(ngModel)]="logging.level">
        <option [value]="LOGLEVEL.ERROR">ERROR</option>
        <option [value]="LOGLEVEL.WARN">WARN</option>
        <option [value]="LOGLEVEL.DEBUG">DEBUG</option>
        <option [value]="LOGLEVEL.INFO">INFO</option>
        <option [value]="LOGLEVEL.TRACE">TRACE</option>
      </select>
    </div>
    <div class="field-group">
      <label class="fg-white" for="interval" class="leading">For</label>
      <div class="inline-fields">
        <input type="number" min="1" max="10" name="interval" id="interval" [(ngModel)]="logging.interval">
        <select name="units" id="units" [(ngModel)]="logging.units">
          <option value="1000">seconds</option>
          <option value="60000">minutes</option>
          <option value="3600000">hours</option>
          <option value="86400000">days</option>
        </select>
      </div>
    </div>
    <div class="field-group offset">
      <p class="fg-white">Logging will revert to default levels once this interval has expired.</p>
    </div>

  </form>

</prg-form-modal>
