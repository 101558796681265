import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UIModule } from '@when-then/ui';

import { ProgrammingCommonModule } from '../../common/common.module';
import { QuickstartCommonModule } from './../quickstart-common/quickstart-common.module';

import { SelectKeypadComponent } from './select-keypad/select-keypad.component';
import { SelectKeypadButtonComponent } from './select-keypad-button/select-keypad-button.component';
import { QuickstartKeypadsService } from './services/keypads.service';
import { SelectKeypadInteractionComponent } from './select-keypad-interaction/select-keypad-interaction.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    UIModule,
    ProgrammingCommonModule,
    QuickstartCommonModule,
  ],
  providers: [
    QuickstartKeypadsService
  ],
  declarations: [SelectKeypadComponent, SelectKeypadButtonComponent, SelectKeypadInteractionComponent]
})
export class QuickstartKeypadsModule { }
