import { Pipe, PipeTransform } from '@angular/core';

import { Event } from '../interfaces/event.interface';
import { CW_CREATOR_ID } from '../services/utils.service';
import { EVENTS_FILTER } from '../../quickstarts/events/eventsUI.service';
import { ScheduleEvent } from '../../quickstarts/quickstart-schedule/+state/schedule.interfaces';

@Pipe({
  name: 'creator'
})
export class CreatorPipe implements PipeTransform {

  transform(value: Event[], filter: EVENTS_FILTER): ScheduleEvent[] | Event[] {
    if (!!value && filter === EVENTS_FILTER.MINE) {
      return value.filter(evt => !!evt.codeItems && evt.codeItems.some(ci => ci.creator == CW_CREATOR_ID));
    }
    return value;
  }
}
