<div *ngIf="!(searchText | async) && (source?.length === 0)" class="alert">
  <header>
    <span class="icon information small grey"></span>
    <h5 class="grow"> No Automation Found</h5>
  </header>
  <p>There is no automation to be displayed. The currently selected filter might be hiding some automation. You can
    try
    selecting a different filter, or <a [routerLink]="['../add']">create some new automation</a>.
  </p>
</div>

<div class="alert flex" *ngIf="(searchText | async) && (source?.length === 0)">
  <header>
    <span class="icon information small grey"></span>
    <h5 class="grow"> No Matching Automation</h5>
  </header>
  <p class="grow">No automation matches the search text you have entered. Try searching for room names, device names
    or
    specific actions, such as "opened" or "tapped".</p>
</div>

<prg-when-then-header *ngIf="(source?.length > 0)"></prg-when-then-header>
<cxScrollable>
  <div *ngFor="let item of source" prg-event-codeitems [expanded]="expanded" [event]="item" (click)="itemClicked($event, item)"></div>
</cxScrollable>
