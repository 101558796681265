import { Component } from '@angular/core';

import { QSRoomsService } from './quickstart-rooms.service';

@Component({
  templateUrl: './quickstart-rooms.component.html',
  styleUrls: ['./quickstart-rooms.component.less']
})
export class QSRoomsComponent {

  constructor(
    private _service: QSRoomsService
  ) { }
}
