import { EventType } from './event-type.interface';

export const SMOKE: EventType = {
  name: 'Smoke',
  description: 'When a Smoke Detector indicates the presence of smoke',
  route: 'smoke',
  category: 'Safety',
  icon: 'detect-smoke',
  whitelist: [
    {
      fields: { proxy: 'contactsingle_smokedetector_c4' },
      events: [1, 2]
    }
  ]
}
