import { Component } from '@angular/core';
import { QSSimpleService } from './quickstart-simple.service';

@Component({
  template: "<router-outlet></router-outlet>",
  providers: [QSSimpleService]
})
export class QSSimpleComponent {

  constructor(private _service: QSSimpleService) { }

}
