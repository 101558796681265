import { Pipe, PipeTransform } from '@angular/core';
import { Device } from '../interfaces/item.interface';

interface RoomRef {
  id: number;
  name: string;
}

@Pipe({
  name: 'roomsFromDevices'
})
export class RoomsFromDevicesPipe implements PipeTransform {

  transform(devices: Device[], args?: any): any {
    // console.log('rooms-from-devices: ', devices);
    let roomMap: Map<number, RoomRef> = new Map<number, RoomRef>();

    if (devices) {
      devices.forEach(dev => {
        let room = roomMap.get(dev.roomId);
        if (!room) {
          roomMap.set(dev.roomId, { id: dev.roomId, name: dev.roomName });
        }
      });
    }

    // console.log('rooms-from-devices: returning', roomMap.values());
    return Array.from(roomMap.values());
  }

}
