import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, Subscription, timer, combineLatest } from 'rxjs';
import { filter, debounce, map, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http'

import {
  StorageService,
  environment as ENV,
  UrlFactoryService,
  DirectorConnectionState,
  DirectorConnectionService,
  MonitoredHttpService as Http
} from '@when-then/core';

import { AuthenticationService } from './services/authentication.service';
import { AuthenticationState } from './services/authentication-state';

const CONTROLLERS: {
  [key: string]: {
    model: string;
    desc: string;
  }
} = {
  'control4_ca1': {
    model: 'CA-1',
    desc: 'Automation Controller'
  },
  'control4_ea1': {
    model: 'EA-1',
    desc: 'Entertainment & Automation Controller'
  },
  'control4_ea3': {
    model: 'EA-3',
    desc: 'Entertainment & Automation Controller'
  },
  'control4_ea5': {
    model: 'EA-5',
    desc: 'Entertainment & Automation Controller'
  },
  'control4_hc800': {
    model: 'HC-800',
    desc: 'Home Controller 800'
  },
  'control4_hc250': {
    model: 'HC-250',
    desc: 'Home Controller 250'
  }
};

const UNKNOWN_CONTROLLER = {
  model: 'Unknown',
  desc: 'Unknown Controller'
};

@Component({
  selector: 'ca-authenticate',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.less'],
})
export class AuthenticationComponent {

  director: Observable<string>;
  busy: Observable<boolean>;

  creds: any = {
    username: '',
    password: '',
    accountPassword: ''
  };

  controller = undefined;

  inComposer: boolean;
  error: Observable<boolean>;
  errors: Observable<any>;
  ready: Observable<boolean>;
  nameSub: Subscription;

  _interval = 5000;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private store: Store<{
      authentication: AuthenticationState,
      directorConnection: DirectorConnectionState
    }>,
    private storageService: StorageService,
    private directorService: DirectorConnectionService,
    url: UrlFactoryService,
    http: HttpClient
  ) {

    this.director = this.store.select(d => d.directorConnection)
      .pipe(
        filter(d => !!d),
        map(d => d.director)
      );

    this.errors = this.store.select(s => s.authentication.errors);
    this.busy = this.store.select(s => s.authentication.busy)
      .pipe(
        debounce(() => timer(50))
      );

    this.ready = this.director
      .pipe(
        map(d => d == 'connected' || d == 'disabled')
      );

    // Clear out any old auth stuff
    this.authenticationService.unauthenticate();

    storageService.setAll(ENV);

    // Subscription to the common name of the controller.
    // We only need this once, but because the Controller
    // goes down completely during a reboot, we can't count
    // on getting a valid HTTP response. This keeps trying
    // until we get one.
    // this.nameSub = Observable.timer(0, this._interval).flatMap(t => {

    //   // .onErrorResumeNext permits the subscription to recover
    //   // from HTTP errors and will try again upon the next tick
    //   return <Observable<Response>>http.get(url.getBrokerURL('/api/v1/common_name'))
    //     .timeout(this._interval)
    //     .onErrorResumeNext((err, caught) => {
    //       console.warn("WARN >> authentication.component.ts:98: Could not fetch the controller's common name");
    //       return undefined;
    //     })
    // })
    //   .filter(r => !!r)
    //   .map(r => r.json().commonName)
    //   .map(commonName => commonName.substr(0, commonName.lastIndexOf("_")))
    //   .take(1)
    //   .subscribe(controllerType => {
    //     this.controller = CONTROLLERS[controllerType] || UNKNOWN_CONTROLLER;
    //   });

    http.get(url.getBrokerURL('/api/v1/common_name'), {responseType: 'json'})
      .pipe(
        filter(r => !!r),
        map((r: {commonName: string}) => r.commonName),
        map(commonName => commonName.substr(0, commonName.lastIndexOf("_"))),
        take(1)
      )
      .subscribe(type => {
        this.controller = CONTROLLERS[type] || UNKNOWN_CONTROLLER;
      }, err => {
        this.controller = UNKNOWN_CONTROLLER;
      });

    // have I checked for a 4sight license?
    combineLatest(
      this.store.select(s => s.authentication.licenseChecked),
      this.store.select(s => s.authentication.licensed)
    )
      .pipe(
        filter(combined => combined.every(l => !!l))
      )
      .subscribe(combined => this.router.navigate(['/']));
  }

  ngOnInit() {
    this.inComposer = !!this.router.config.find(route => !!route.component && route.component.name == 'ComposerComponent');
  }

  doLogin() {
    // NOTE 'profile' is not the same as 'username' for login
    this.storageService.set('profile', this.creds.username);
    let username = (this.creds.username !== 'primary') ? this.creds.username : this.creds.accountEmail;
    this.storageService.set('username', username);
    this.authenticationService.authenticate(username, this.creds.password);
  }

  ngOnDestroy() {
    this.nameSub.unsubscribe();
  }
}
