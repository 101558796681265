<div *ngIf="(ready | async)">
  <prg-nav-header title="Which action on the {{device | async | deviceInRoom }} do you want to take place?" (cancel)="goToEvent()"></prg-nav-header>
  <cxScrollable>
    <prg-block-button *ngFor="let command of _simpleCommands|async" (click)="setCommand(command)" leadingIcon="blue keypad"
      title="{{ command.display }}" trailingIcon="rignt"></prg-block-button>

    <prg-block-button *ngFor="let command of _parameterCommands|async" [routerLink]="['../parameters', command.command, {id: command.id}]"
      leadingIcon="blue keypad" title="{{ command.label }}" trailingIcon="right"></prg-block-button>

  </cxScrollable>
</div>