import { Pipe, PipeTransform } from '@angular/core';
import { Device, Location } from './../interfaces/item.interface';

@Pipe({
  name: 'matchingRoom'
})
export class MatchingRoomPipe implements PipeTransform {

  transform(devices: Device[], args?: any): any {
    if (args !== 'any') {
      let room: Location = args;
      if (devices && room) {
        return devices.filter(dev => { return dev.roomId === room.id });
      }
    }

    return devices;
  }

}
