import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hasButtonEvents'
})
export class HasButtonEventsPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (Array.isArray(value)) {
      return value.filter(item => {
        return (item.capabilities && item.capabilities.has_button_events);
      });
    }

    return value;
  }

}
