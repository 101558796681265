import { QSRemotesEffects } from './quickstart-remotes.effects';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIModule } from '@when-then/ui';
import { ProgrammingCommonModule } from './../../common/common.module';
import { QuickstartCommonModule } from './../quickstart-common/quickstart-common.module';

import { SelectRoomComponent } from './select-room/select-room.component';
import { QuickstartRemotesService } from './quickstart-remotes.service';
import { SelectRemoteButtonComponent } from './select-remote-button/select-remote-button.component';

@NgModule({
  imports: [
    CommonModule,
    ProgrammingCommonModule,
    QuickstartCommonModule,
    UIModule,
    EffectsModule.forFeature([QSRemotesEffects])
  ],
  declarations: [SelectRoomComponent, SelectRemoteButtonComponent],
  providers: [QuickstartRemotesService]
})
export class QuickstartSystemRemotesModule { }
