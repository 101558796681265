// tslint:disable:no-bitwise

import { Pipe, PipeTransform } from '@angular/core';
import { ProgrammingUtilsService as Utils, DAYS_OF_THE_WEEK, SHORT_DAYS_OF_THE_WEEK } from '../services/utils.service';
import { WEEKS_OF_THE_MONTH, MONTHS_OF_THE_YEAR } from './../services/utils.service';
import { ScheduleEvent } from '../../quickstarts/quickstart-schedule/+state/schedule.interfaces';

@Pipe({
  name: 'scheduleEventLabel'
})
export class ScheduleEventLabelPipe implements PipeTransform {
  constructor() { }

  transform(schedule: ScheduleEvent, args?: any): any {
    // console.log('schedule-label: ', schedule);
    let display = '';

    if (!!schedule && !!schedule.start) {
      // start date
      if (!!schedule.start.start_date && !!schedule.start.start_date.start_period) {
        // relative start
        display += 'on the ';
        display += WEEKS_OF_THE_MONTH[schedule.start.start_date.start_period - 1];
        display += ' ';
        display += DAYS_OF_THE_WEEK[schedule.start.start_date.start_weekday - 1];
        display += ' of ';
        display += MONTHS_OF_THE_YEAR[schedule.start.start_date.start_month - 1];
        display += ', ';
        display += schedule.start.start_date.start_year;
        display += ', ';
      } else {
        // fixed start
        display += ' on '
        display += Utils.toDateDisplay(
          schedule.start.start_date.start_year,
          schedule.start.start_date.start_month,
          schedule.start.start_date.start_day);
        display += ', ';
      }

      // start time
      display += this.getStartTime(schedule);

      // repeat
      if (!!schedule.repeat && schedule.repeat.frequency > 0) {
        display = 'Starting ' + display;

        display += (' and repeating every ' + this.getFrequency(schedule.repeat));

        // end date
        if (!!schedule.repeat.end_date) {
          display += ' until ';
          display += Utils.toDateDisplay(
            schedule.repeat.end_date.end_year,
            schedule.repeat.end_date.end_month,
            schedule.repeat.end_date.end_day);
        }
      } else {
        display = 'Happening once ' + display;
      }
    }

    // console.log('composed schedule label is ', display);
    return display;
  }

  private getFrequency(repeat: any): string {
    let freq = '';
    let rate = parseInt(repeat.rate, 10);

    if (rate > 1) {
      freq += (repeat.rate + ' ');
    }

    switch (repeat.frequency) {
      case 0: break; // NOTE shouldn't get here
      case 1: freq += 'day'; break;
      case 2: freq += 'week'; break;
      case 3: freq = 'month'; break;
      case 4: freq = 'year'; break;
    }

    if (rate > 1) {
      freq += 's';
    }

    // repeat days
    if (repeat.frequency === 2) {
      freq += ' on ';
      freq += this.composeDaysOfTheWeekDisplay(repeat.daymask);
    }

    return freq;
  }

  private composeDaysOfTheWeekDisplay(days: number): string {
    let display = '';

    const list = [];

    for (let i = 0; i < 7; i++) {
      let val = Math.pow(2, i);
      if ((days & val) === val) {
        list.push(SHORT_DAYS_OF_THE_WEEK[i]);
      }
    }

    return list.join(', ');
  }

  private getStartTime(schedule: ScheduleEvent): string {
    let display = '';

    if (schedule && schedule.start) {
      if (schedule.start.offset) {
        // sunrise or sunset
        if (schedule.start.offset_minutes) {
          if (schedule.start.offset_minutes < 0) {
            display += (Math.abs(schedule.start.offset_minutes) + ' before ');
          } else if (schedule.start.offset_minutes > 0) {
            display += (schedule.start.offset_minutes + ' after ');
          }
        } else {
          display += 'At ';
        }

        display += ((schedule.start.offset === 1) ? 'Sunrise ' : 'Sunset ');
      } else {
        display += 'At ';
        let now = new Date();
        let h = Math.floor((schedule.start.offset_minutes / 60));
        let m = (schedule.start.offset_minutes % 60);
        display += Utils.toTimeDisplay(now.getFullYear(), now.getMonth() + 1, now.getDate(), schedule.start.offset_minutes);
        display += ' ';
      }
    }

    return display;
  }
}
