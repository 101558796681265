// angular
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// libs
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

// common
import { SharedProgrammingService } from '../../../common/services/shared-programming.service';
import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';
import { ProgrammingUtilsService as Utils } from './../../../common/services/utils.service';

// module
import { LightsService, LightsProgrammingContext } from '../services/lights.service';
import { Light } from '../services/interfaces/light.interface';
import { Room } from '../services/interfaces/room.interface';

@Component({
  selector: 'prg-light-trigger',
  templateUrl: './light-trigger.component.html',
  styleUrls: ['./light-trigger.component.less']
})
export class LightTriggerComponent extends BaseRoutingComponent implements OnInit {

  // private _rooms: Observable<Room[]>;
  _lights: Observable<Light[]>;

  constructor(
    protected router: Router,
    protected store: Store<{ lightsProgramming: LightsProgrammingContext }>,
    private shared: SharedProgrammingService,
    private lightsService: LightsService,
    private route: ActivatedRoute
  ) {
    super();
    this._lights = lightsService.triggerLights;
  }

  ngOnInit() {
  }

  lightSelected(light: Light) {
    console.log('light-trigger: routing to light', light);
    this.lightsService.setTriggerLight(light);
    this.router.navigate([light.id, 'buttons'], { relativeTo: this.route });
  }
}
