import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { Observable, combineLatest, from } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BrokerService } from '@when-then/core';

import { SharedProgrammingService } from '../../../common/services/shared-programming.service';
import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';
import { ProgrammingUtilsService as Utils } from '../../../common/services/utils.service';
import { DeviceEvent } from '../../../common/interfaces/event.interface';
import { Command } from '../../../common/interfaces/command.interface';

import { RoomsProgrammingContext } from '../quickstart-rooms.service';
import { CodeItemType } from '../../../common/interfaces/item.interface';

const EVENTS = [
  {
    id: 1,
    name: 'Room Off'
  },
  {
    id: 2,
    name: 'Audio Selection'
  },
  {
    id: 3,
    name: 'Video Selection'
  },
  {
    id: 2051,
    name: 'Pause Media'
  },
  {
    id: 2054,
    name: 'Play Media'
  }
]

@Component({
  templateUrl: './select-room.component.html',
  styleUrls: ['./select-room.component.less']
})
export class SelectRoomComponent extends BaseRoutingComponent {

  _rooms: Observable<any>;
  eventId: number;
  command: string;

  readonly prettyEvents = {
    1: 'When audio and video is turned off in which room?',
    2: 'When the audio selection changes in which room?',
    3: 'When the video selection changes in which room?',
    2051: 'When the pause command is received by which room?',
    2054: 'When the play command is recieved by which room?'
  }

  readonly prettyCommands = {
    ROOM_OFF: 'Turn off all audio and video in which room?'
  }

  constructor(
    protected store: Store<{
      roomsProgramming: RoomsProgrammingContext
    }>,
    private _shared: SharedProgrammingService,
    private _route: ActivatedRoute,
    private _broker: BrokerService,
    public router: Router
  ) {
    super();

    this._rooms = combineLatest(
      // NOTE there is a hidden dependency on the rooms service here, but it is not injected above, guess we get lucky
      this.store.select(s => s.roomsProgramming.rooms).pipe(filter(r => !!r)),
      this._route.params.pipe(map((params: { eventId: number }) => params.eventId)),
      (rooms, eventId) => {
        return rooms.filter(room => {
          return room.events.some(evt => evt.eventId == eventId || evt.name == 'Commands') &&
            !!room.bindings &&
            room.bindings.length > 0;
        })
      }
    );

    this._route.params.subscribe((params: { eventId?: number, command?: string }) => {
      this.command = params.command;
      this.eventId = params.eventId;
    });
  }

  select = (roomId: number) => {
    if (this.command !== undefined) this.selectAction(roomId);
    if (this.eventId !== undefined) this.selectEvent(roomId);
  }

  selectAction = (roomId: number) => {
    from(<Promise<Command[]>>this._broker.call({
      path: `/api/v1/locations/rooms/${roomId}/commands`
    }))
      .pipe(
        map(commands => commands.find(c => c.command == this.command))
      )
      .subscribe(command => {
        if (!!command.params && command.params.length > 0) {
          this.router.navigate(['../' + command.deviceId, command.command], {
            relativeTo: this._route
          });
        } else {
          this._shared.setPendingAction(command, Utils.buildCommandData(CodeItemType.Command, command));
          this.router.navigate(['conditionals'], { relativeTo: this._route });
        }
      })
  }


  selectEvent = (roomId: number) => {
    this._broker.call({
      path: `/api/v1/items/${roomId}/events`,
      queryString: {
        allEvents: true
      }
    }).then(events => {
      let event = this._buildEvent(this.eventId, events)
      // this._shared.setSelectedEvent(event);
      this.router.navigate(
        [['when-then', 'device', event.deviceId, 'event', event.eventId, 'add'].join('/')],
        {
          relativeTo: this.moduleRoot
        }
      )
    });
  }

  _buildEvent = (eventId: number, events: DeviceEvent[]): DeviceEvent => {
    let event = events.find(e => e.eventId == eventId || e.name == 'Commands');
    event.eventId = event.eventId || eventId;
    return event;
  }

  _isTrigger() {
    return window.location.href.indexOf('trigger') > -1;
  }

  cancelClicked() {
    if (this._isTrigger()) {
      this.goHome();
    } else {
      this.goToEvent();
    }
  }
}
