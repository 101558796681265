import { EventType } from './event-type.interface';

export const FAN: EventType = {
  name: 'Fan',
  description: 'When a Fan starts, stops or changes speed',
  route: 'fan',
  category: 'Fan',
  icon: 'fan',
  order: 3,
  whitelist: [
    {
      fields: { proxy: 'relaysingle_fan_c4' },
      events: [1, 2]
    },
    {
      fields: { proxy: 'fan' },
      events: [101, 102, 103]
    }
  ]
}
