import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { EffectsModule } from '@ngrx/effects';

import { UIModule } from '@when-then/ui';

import { ProgrammingCommonModule } from '../../common/common.module';
import { QuickstartCommonModule } from '../quickstart-common/quickstart-common.module';

import { QSMediaService } from './quickstart-media.service';
import { QSMediaEffects } from './quickstart-media.effects';

import { QSMediaComponent } from './quickstart-media.component';
import { QSMediaSelectRoomComponent } from './select-room/select-room.component';
import { QSMediaSelectMediaComponent } from './select-media/select-media.component';
import { QSMediaSelectDeviceComponent } from './select-device/select-device.component';
import { QSMediaSelectRemoteComponent } from './select-remote/select-remote.component';
import { QSMediaSelectStreamingServiceComponent } from './streaming/select-service.component';
import { QSMediaSelectStreamingCategoryComponent } from './streaming/select-streaming-category.component';
import { QSMediaSelectStreamingMediaComponent } from './streaming/select-media.component';
import { QSMediaSelectStreamingRoomsComponent } from './streaming/select-streaming-rooms.component';
import { DataToUIShimService } from './streaming/datatoui-shim.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    UIModule,
    ProgrammingCommonModule,
    QuickstartCommonModule,
    EffectsModule.forFeature([QSMediaEffects])
  ],
  providers: [
    QSMediaService,
    DataToUIShimService
  ],
  declarations: [
    QSMediaComponent,
    QSMediaSelectRoomComponent,
    QSMediaSelectMediaComponent,
    QSMediaSelectDeviceComponent,
    QSMediaSelectRemoteComponent,
    QSMediaSelectStreamingServiceComponent,
    QSMediaSelectStreamingCategoryComponent,
    QSMediaSelectStreamingMediaComponent,
    QSMediaSelectStreamingRoomsComponent
  ]
})
export class QuickstartMediaModule { }
