<div class="parameter-input" [ngSwitch]="_inputType">
   <!--<label *ngIf="_inputType != 'hidden'">{{param.name | readable}}</label>-->

  <!-- number -->
  <ng-template [ngSwitchCase]="'number'">
    <input
      [type]="_inputType"
      [name]="param.name"
      id="{{param.name}}-input"
      [ngClass]="inputClass"
      [ngModel]="param._value"
      (ngModelChange)="handleChange($event)"
      min="{{ param.minimum || param.low || 0 }}"
      max="{{ param.maximum || param.high || 100 }}"
      step="{{ param.resolution || 1 }}">
  </ng-template>

  <!-- range -->
  <ng-template [ngSwitchCase]="'range'">
    <input
      [type]="_inputType"
      [name]="param.name"
      id="{{param.name}}-input"
      [ngClass]="inputClass"
      [ngModel]="param._value"
      (ngModelChange)="handleChange($event)"
      min="{{ param.minimum || param.low || 0 }}"
      max="{{ param.maximum || param.high || 100 }}"
      step="{{ param.resolution || 1 }}">
  </ng-template>

  <!-- radio -->
  <ng-template [ngSwitchCase]="'radio'">
    <ng-template ngFor let-option [ngForOf]="_values|async" let-i="index">
      <input
        type="radio"
        [name]="param.name"
        [value]="option._value"> {{ param._display }}<br>
    </ng-template>
  </ng-template>

  <!-- select -->
  <ng-template [ngSwitchCase]="'select'">
    <select
      [name]="param.name"
      id="{{param.name}}-input"
      class="{{ inputClass }}"
      [ngClass]="{
        placeholder: param._value == undefined
      }"
      [ngModel]="param._value"
      (ngModelChange)="handleChange($event)">
      <option
        *ngIf="param._value == undefined"
        disabled
        [value]="undefined">Please select an option...</option>
      <option
        *ngFor="let option of _values|async"
        [ngValue]="option._value">{{ option._display }}</option>
    </select>
  </ng-template>

  <!-- list -->
  <ng-template [ngSwitchCase]="'list'">
    <select
      [name]="param.name"
      id="{{param.name}}-input"
      class="{{ inputClass }}"
      [ngClass]="{
        placeholder: param._value == undefined
      }"
      [ngModel]="param._value"
      (ngModelChange)="handleChange($event)"
      [multiple]="param.multiselect">
      <option
        *ngFor="let option of _values|async"
        [ngValue]="option._value">{{ option._display }} ({{ option._value }})</option>
    </select>
  </ng-template>

  <!-- textarea -->
  <ng-template [ngSwitchCase]="'textarea'">
    <textarea
      [name]="param.name"
      id="{{param.name}}-input"
      class="{{ inputClass }}"
      [ngClass]="{
        placeholder: param._value == undefined
      }"
      [ngModel]="param._value"
      (ngModelChange)="handleChange($event)"
      placeholder="Please enter a {{ param.name | readable }}..."
      rows="1"></textarea>
  </ng-template>

  <!-- color -->
  <ng-template [ngSwitchCase]="'color'">  
    <div style="display:flex; flex-flow:row nowrap;">
      <input id="{{param.name}}-input"
            [style.width]="'100px'"
            [style.margin-right]="'10px'"
            [name]="param.name" 
            [value]="((param._display.indexOf('#') === -1) ? '#' + param._display : param._display)" 
            [colorPicker]="((param._value.indexOf('#') === -1) ? '#' + param._value : param._value)"
            (colorPickerChange)="handleChange($event)"
            [cpPresetColors]="['#000000', '#ff8080', '#ffff80', '#80ff80', '#80ffff', '#0080ff', '#ff80c0', '#ff1600', '#8080c0', '#804040', '#ff8040', '#008040', '#2889e9', '#0000a0', '#ffffff']"
            [cpToggle]="true"
            [cpDialogDisplay]="'inline'"
            [cpDisableInput]="true"
            [cpWidth]="'500px'" 
            [cpHeight]="'auto'"/>
    </div>
  </ng-template>
    
  <!-- default, most input types -->
  <ng-template ngSwitchDefault>
    <input
      [type]="_inputType"
      [name]="param.name"
      id="{{param.name}}-input"
      [ngClass]="inputClass"
      [ngModel]="param._value"
      (ngModelChange)="handleChange($event)"
      [placeholder]="placeholder || 'Please enter a '+ (param.name | readable) +' ...'">
  </ng-template>
</div>
