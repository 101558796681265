/**
 * Copyright 2017 Control4 Corporation. All rights reserved.
 * Control4 Confidential and Proprietary Information.
 */

import { ActionReducerMap } from '@ngrx/store';

import { CORE_STORE, CoreState } from '@when-then/core';
import { AUTH_REDUCERS, AuthState } from '@when-then/auth';

import { COMMON_PROGRAMMING_REDUCERS, CommonProgrammingState } from './common/common.stores';
import { QUICKSTART_PROGRAMMING_REDUCERS, QuickstartProgrammingState } from './quickstarts/quickstarts.stores';
// import { ADVANCED_PROGRAMMING_REDUCERS } from './advanced/advanced.stores';

export interface ProgrammingState extends
  CoreState,
  AuthState,
  QuickstartProgrammingState { }

export const PROGRAMMING_REDUCERS: ActionReducerMap<ProgrammingState> = {
  ...CORE_STORE,
  ...AUTH_REDUCERS,
  ...QUICKSTART_PROGRAMMING_REDUCERS
}
