import { LoggerFactory } from './../../../../../../libs/core/src/lib/services/log.service';
import { CodeItemType } from './../interfaces/item.interface';
import { take } from 'rxjs/operators';
import { CommandData } from './shared-programming.service';
import { Conditional } from './../interfaces/conditional.interface';
import { Command } from './../interfaces/command.interface';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import * as moment from 'moment';

import { BrokerService } from '@when-then/core';

import { Event } from './../interfaces/event.interface';
import { CodeItem } from '../interfaces/code-item.interface';
import { Parameter } from '../interfaces/parameter.interface';

export const TAG_QSP: string = 'c4-qsp';
export const CW_CREATOR_ID: number = 3;

const MOMENT_DATE_VALUE_FORMAT: string = 'YYYY-MM-DD';
const MOMENT_DATE_DISPLAY_FORMAT: string = 'dddd, MMMM Do, YYYY';
const MOMENT_TIME_DISPLAY_FORMAT: string = 'h:mm a';

const ICON_CLASS_UNKNOWN: string = 'fa fa-fw fa-question';

export const WEEKS_OF_THE_MONTH: Array<string> = ['First', 'Second', 'Third', 'Fourth', 'Fifth', 'Last'];
export const MONTHS_OF_THE_YEAR: Array<string> = [
  'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
export const DAYS_OF_THE_WEEK: Array<any> = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
export const SHORT_DAYS_OF_THE_WEEK: Array<any> = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export enum DaysOfWeekBits {
  Sunday = 1,
  Monday = 2,
  Tuesday = 4,
  Wednesday = 8,
  Thursday = 16,
  Friday = 32,
  Saturday = 64
};

@Injectable()
export class ProgrammingUtilsService {

  private static _logger = LoggerFactory.getLogger(ProgrammingUtilsService.name);

  constructor(
    private broker: BrokerService,
    private store: Store<any>
  ) { }

  static buildCreatorState(
    event: {
      eventId: number;
      deviceId: number;
    },
    codeitem: CommandData
  ): string {
    let state: any = {
      eventId: event.eventId,
      deviceId: event.deviceId,
      type: codeitem.type,
      tags: TAG_QSP,
      creator: CW_CREATOR_ID,
      lastUpdate: new Date().toUTCString()
    };

    if (!codeitem.codeItemId) {
      state.createdOn = new Date().toUTCString()
    }

    switch (codeitem.type) {
      case 1: state.codeitem = ProgrammingUtilsService.buildCommandState(codeitem); break;
      case 2: state.codeitem = ProgrammingUtilsService.buildConditionState(codeitem); break;
      case 3: state.codeitem = ProgrammingUtilsService.buildLoopState(codeitem); break;
    }

    // console.log('creator state for event and codeitem', event, codeitem, state);
    return JSON.stringify(state);
  }

  private static buildCommandState(codeitem: CommandData): any {
    // console.log('buildCommandState: building command from ', codeitem);
    return {
      command: codeitem.command,
      deviceId: codeitem.deviceId,
      codeitemId: 0
    };
  }

  private static buildConditionState(condition: CommandData): any {
    return {
      condition: condition.conditional,
      deviceId: condition.deviceId,
      codeitemId: condition.codeItemId
    };
  }

  private static buildLoopState(loop: CommandData): any {
    return {
      loop: loop.loop,
      deviceId: loop.deviceId,
      codeitemId: loop.codeItemId
    };
  }

  static resolveProtected(events: Event[]): Event[] {
    if (!!events) {
      events.forEach(evt => {
        evt.hasProtected = ProgrammingUtilsService._hasProtected(evt);
      });
    }
    // console.log('utils: protected state resolved for', events);
    return events;
  }

  private static _hasProtected(parent: any): boolean {
    let hasProtected = false;
    if (!!parent.codeItems) {
      parent.codeItems.forEach(ci => {
        ci.parent = parent;
        ci.protected = (!ProgrammingUtilsService._isAuthoredByCW(ci));
        // NOTE _hasProtected has to be first because otherwise it will be skipped once a protected codeitem is found
        hasProtected = this._hasProtected(ci) || hasProtected || ci.protected;
      });
    }

    return hasProtected;
  }

  private static _isAuthoredByCW(codeitem: CodeItem): boolean {
    let state = ProgrammingUtilsService._parseCreatorState(codeitem) || {};
    // stopgap -- remove sometime before 2.10 release
    return (
      codeitem.creator === CW_CREATOR_ID ||
      (!!state && !!state.tags && state.tags.indexOf(TAG_QSP) >= 0)
    );
  }

  private static _parseCreatorState(ci: CodeItem) {
    let state = undefined;
    if (!!ci && ci.creatorState && ci.creatorState.length > 0) {
      try {
        state = JSON.parse(ci.creatorState);
      } catch (e) {
        console.error('attempt to parse invalid creator state', ci.creatorState, e);
      }
    }
    return state;
  }

  private static _canEdit(codeitem: CodeItem): boolean {
    return this._isAuthoredByCW(codeitem);
  }

  static deriveDevicesFromItems(items: Array<any>): Array<any> {
    let devMap = {};
    let devices = [];

    items.forEach((item: any) => {
      switch (item.type) {
        case 7:
          let parent = devMap[item.parentId];
          if (parent && parent.typeName === 'device') {
            parent.proxies = parent.proxies || [];
            parent.proxies[item.id] = item;
            if (parent.proxy === item.proxy) {
              parent.defaultProxy = item;
              parent.protocolName = parent.name;
              parent.name = item.name;
            }
          } else {
            devMap[item.id] = item;
            devices.push(item);
          }
          break;
        case 6:
        default:
          devMap[item.id] = item;
          devices.push(item);
          break;
      }
    });

    return devices;
  }

  static toMoment(year: number, month: number, day: number, minutes?: number) {
    return moment([
      year,
      month - 1,
      day,
      Math.floor((minutes || 0) / 60),
      Math.floor((minutes || 0) % 60)
    ]);
  }

  static toStart(m: any) {
    return {
      start_date: {
        start_year: m.year(),
        start_month: m.month() + 1,
        start_day: m.date(),
        start_period: Math.floor(m.date() / 7),
        start_weekday: m.day()
      },
      offset_minutes: m.hour() * 60 + m.minute()
    };
  }

  // TODO refactor this to take year, month, day and not be coupled to the start date (because we need this for end date too)
  // once refactored, remove the export on the const above
  static toDateValue(year: number, month: number, day: number): string {
    return this.toMoment(year, month, day).format(MOMENT_DATE_VALUE_FORMAT);
  }

  static toDateDisplay(year: number, month: number, day: number): string {
    return this.toMoment(year, month, day).format(MOMENT_DATE_DISPLAY_FORMAT);
  }

  static toTimeDisplay(year: number, month: number, day: number, minutes: number): string {
    return this.toMoment(year, month, day, minutes).format(MOMENT_TIME_DISPLAY_FORMAT);
  }

  static getValue = <T, U>(store: Store<T>, callback: (state: T) => U): U => {
    var currentValue: any;
    store.select(callback).pipe(take(1)).subscribe(r => currentValue = r);
    return currentValue;
  };

  // SEE http://stackoverflow.com/questions/1436438/how-do-you-set-clear-and-toggle-a-single-bit-in-javascript
  static bitTest(num, bit): boolean {
    return ((num >> bit) % 2 != 0);
  }

  static bitSet(num, bit): number {
    return num | 1 << bit;
  }

  static bitClear(num, bit): number {
    return num & ~(1 << bit);
  }

  static bitFlip(num, bit): number {
    return this.bitTest(num, bit) ? this.bitClear(num, bit) : this.bitSet(num, bit);
  }

  static distinct(x: Object, y: Object): boolean {
    return JSON.stringify(x) === JSON.stringify(y);
  }

  static snapshot<T>(obs: Observable<T>): T {
    let val: T;
    obs.pipe(take(1)).subscribe(t => val = t);
    return val;
  }

  static getPathFromRoot(route: ActivatedRoute): string {
    return route.snapshot.pathFromRoot.map(path => path.url.join('/')).join('/');
  }

  static someTrue(...args: boolean[]): boolean {
    return [].slice.apply(arguments).some(p => p);
  }

  static allTrue(...arg: boolean[]): boolean {
    return [].slice.apply(arguments).some(p => p);
  }

  static buildCommandData(type: CodeItemType, action: Command | Conditional, parentCodeItemId?: number): CommandData {
    this._logger.debug('building command data for ', type, action, parentCodeItemId);
    const codeItem: CommandData = {
      type: type,
      codeItemId: 0,
      parentCodeItemId: parentCodeItemId || 0,
      beforeCodeItemId: 0,
      deviceId: action.deviceId,
      creator: CW_CREATOR_ID,
      command: ''
    };

    switch (type) {
      case CodeItemType.Conditional:
        codeItem.conditional = codeItem.command = (<Conditional>action).name;
        break;
      case CodeItemType.Command:
        codeItem.command = (<Command>action).command;
        break;
    }

    if (action.params && action.params.length > 0) {
      codeItem.params = [];
      action.params.forEach(p => {
        codeItem.params.push({
          name: p.name,
          value: this._transformParamValue(p),
          display: p._display
        })
      });
    }

    return codeItem;
  }


  private static _transformParamValue(p: Parameter): string | number {
    // HACK conditionals uses value, not _value to store the actual value of the
    // parameter, so we need to normalize it here, eventually unify the implementations
    p._value = p._value || p.value || 0;  // is this safe? what about strings?

    switch (p.type) {
      case 'TIMEOFDAY':
        // e.g. "HH:MM"
        const parts = (<string>p._value).split(':');
        if (parts.length === 2) {
          return (parseInt(parts[0]) * 60) + parseInt(parts[1]);
        }
    }

    return p._value;
  }

  // static toTimeDisplay(year: number, month: number, day: number, minutes: number): string {
  //   return ProgrammingUtilsService.toMoment(year, month, day, minutes).format(MOMENT_TIME_DISPLAY_FORMAT);
  // }

  // static toMoment(year: number, month: number, day: number, minutes?: number) {
  //   return moment([
  //     year,
  //     month - 1,
  //     day,
  //     Math.floor((minutes || 0) / 60),
  //     Math.floor((minutes || 0) % 60)
  //   ]);
  // }
}
