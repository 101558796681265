import {
  SET_SCHEDULE_EVENTS,
  SET_SCHEDULES_READY,
  SET_SELECTED_SCHEDULE,
  CLEAR_SCHEDULE_EVENT,
  CLEAR_STATE,
  SchedulesAction
} from './schedule.actions';
import { Schedules, ScheduleEvent } from './schedule.interfaces';
import { schedulesInitialState } from './schedule.init';

export function schedulesReducer(state: Schedules = schedulesInitialState, action: SchedulesAction): Schedules {
  // console.log('REDUCER: ', action);

  switch (action.type) {
    case SET_SCHEDULE_EVENTS: {
      return { ...state, ...{ scheduleEvents: <Array<ScheduleEvent>>action.payload, ready: true } };
    }
    case SET_SCHEDULES_READY: {
      return { ...state, ...{ ready: <boolean>action.payload } };
    }
    case SET_SELECTED_SCHEDULE: {
      return { ...state, ...{ selectedSchedule: <ScheduleEvent>action.payload } };
    }
    case CLEAR_STATE: {
      return { ...schedulesInitialState };
    }
    case CLEAR_SCHEDULE_EVENT: {
      return { ...state, ...{ selectedSchedule: undefined } };
    }
    default: {
      return state;
    }
  }
}
