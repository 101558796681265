import { EventType } from './event-type.interface';

export const SCHEDULE: EventType = {
  name: 'Button',
  description: 'Schedule something to happen at a fixed time or repeating interval',
  route: 'schedule/list',
  category: 'Scheduled Actions',
  icon: 'schedule',
  order: 1,
  whitelist: [
    {
      fields: { control: 'control4_agent_scheduler' }
    }
  ]
}
