import { CreatorPipe } from './../../common/pipes/creator.pipe';
import { MatchingSearchTextPipe } from './../../common/pipes/matching-search-text.pipe';
import { EVENTS_UI_ACTIONS, EventsUIService } from './../events/eventsUI.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, filter, map } from 'rxjs/operators';
import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import { SharedProgrammingService } from '../../common/services/shared-programming.service';
import { Event } from '../../common/interfaces/event.interface';
import { EventsService } from '../../common/services/events.service';
import { VoiceScenesService } from '../quickstart-voice-scenes/voice-scenes.service';
import { EVENTS_FILTER } from '../events/eventsUI.service';

@Component({
  selector: 'prg-list-by-type',
  templateUrl: './list-by-type.component.html'
})
export class ListByTypeComponent implements OnInit {

  ready: Observable<boolean>;
  deviceEvents: Observable<Event[]>;

  @Input() source: Event[];

  constructor(
    private store: Store<any>,
    private route: ActivatedRoute,
    protected router: Router,
    private events: EventsService,
    private shared: SharedProgrammingService,
    private vss: VoiceScenesService,
    private eventsUI: EventsUIService
  ) {
    this.ready = this.shared.isReady;

    // this.deviceEvents = this.events.programmedEvents
    //   .pipe(
    //     // Doing this here because filtering the events
    //     // in the events service would introduce a
    //     // circular dependency
    //     switchMap(events => this.vss.filterProhibitedEvents(events))
    //   );

  }

  ngOnInit() {
    this.shared.clearError();
    this.shared.setGrouping('device');
  }

  ngOnChanges() {
    if (!!this.source) {
      this.eventsUI.setEventCount(this.source.length);
    }
  }

  tileClicked(item: any) {
    console.log('tile clicked: ', item);
    if (item) {
      // this.shared.setSelectedEvent(item).then(res => {
      this.router.navigate(['../device', item.deviceId, 'event', item.eventId, 'edit'], { relativeTo: this.route });
      // });
    }
  }

}
