<prg-nav-header title="Select a Room" (cancel)="goHome()"></prg-nav-header>
<div>
  <p class="help-text">System remotes are capable of controlling one room at a time. When a custom button is pressed on
    a system remote it affects only the room that the system remote is currently controlling. </p>
  <p class="help-text">Select the room that should be affected when a custom button on a system remote is pressed.</p>
  <cxScrollable class="room-list">

    <prg-block-button buttonClass="dark" *ngFor="let room of (allRooms | async)" (click)="roomSelected(room)" buttonClass="dark"
      leadingIcon="blue room" trailingIcon="grey small right" [title]="room.name" [subtitle]="room.floorName"></prg-block-button>

  </cxScrollable>
</div>