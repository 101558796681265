import { CommonProgrammingContext } from './../../../common/services/shared-programming.service';
import { CodeItemType } from './../../../common/interfaces/item.interface';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, from } from 'rxjs';
import { filter, map, share, flatMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { BrokerService, LoggerFactory } from '@when-then/core';

import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';
import { Device } from '../../../common/interfaces/item.interface';
import { Parameter } from '../../../common/interfaces/parameter.interface';
import { C4ValueType } from '../../../common/params/param-input.component';
import { SharedProgrammingService, CommandData } from '../../../common/services/shared-programming.service';
import { ProgrammingUtilsService as Util } from '../../../common/services/utils.service';

import { SimpleProgrammingContext } from '../quickstart-simple.service';
import { CommandTypes } from '../command-types/command-types';
import { Command } from '../../../common/interfaces/command.interface';

@Component({
  templateUrl: './select-params.component.html',
  styleUrls: ['./select-params.component.less']
})
export class QSSimpleSelectParamsComponent extends BaseRoutingComponent {

  private _logger = LoggerFactory.getLogger(QSSimpleSelectParamsComponent.name);
  _device: Observable<Device>;
  _command: Observable<Command>;
  _parameters: Parameter[];
  _icon: Observable<string>;

  constructor(
    public router: Router,
    private _route: ActivatedRoute,
    protected store: Store<{
      simpleProgramming: SimpleProgrammingContext,
      sharedProgramming: CommonProgrammingContext,
    }>,
    private _broker: BrokerService,
    private _shared: SharedProgrammingService
  ) {
    super();

    const routeParams = <{ id: string, command: string }>Util.snapshot(this._route.params);

    // this._command = from(this._broker.call({
    //   path: `/api/v1/items/commands`,
    //   queryString: {
    //     query: {
    //       command: routeParams.command,
    //       deviceId: parseInt(routeParams.id)
    //     }
    //   }
    // }))
    //   .pipe(
    //     map((commands: Command[]) => commands[0]),
    //     share()
    //   );

    this._command = this.store.select(s => s.sharedProgramming.action.command);

    this._device = this.store.select(s => s.simpleProgramming.devices)
      .pipe(
        filter(d => !!d),
        map(devices => devices.find(device => device.id == parseInt(routeParams.id)))
      );

    this._icon = this.store.select(s => s.simpleProgramming.devices)
      .pipe(
        filter(d => !!d),
        map(devices => devices.find(device => device.id == parseInt(routeParams.id))),
        map(device => CommandTypes.find(ct => ct.whitelist.some(wl => wl.proxy == device.proxy)).whitelist.find(wl => wl.proxy == device.proxy)),
        flatMap(whitelist => this._command.pipe(map(c => whitelist.commands.find(wc => wc.command == c.command)))),
        map(command => command.icon)
      );

    this._command.subscribe(command => {
      this._parameters = command.params
    });
  }

  _configured = (): boolean => {
    return (this._parameters || []).every(param => {
      return param.value != undefined || param._value != undefined;
    })
  }

  _saveCommand = () => {
    if (!this._configured()) { return; }

    // TODO if this is important, it's broken, this._parameters is used below,
    // maybe the return value can just be ignored...?
    let params = this._parameters
      .filter(p => !!p)
      .map(p => {
        p._value = p._value || p.value;
        return p;
      });

    let command = Util.snapshot<Command>(this._command);
    this._logger.debug('setting pending action', command);

    let data = {
      ...Util.buildCommandData(CodeItemType.Command, command),
      ...{
        params: this._parameters.map(p => {
          return {
            name: p.name,
            value: p._value,
            display: p._display
          }
        })
      }
    };

    this._shared.setPendingAction(command, data);
    this.router.navigate(['conditionals'], { relativeTo: this._route });
  }

  _update = (param: Parameter, value: C4ValueType) => {
    let ix = this._parameters.findIndex(p => param.name == p.name && param.type == p.type);
    this._parameters[ix] = Object.assign({}, this._parameters[ix], {
      _display: value.display,
      _value: value.value
    });

    this._parameters = [...this._parameters];
  }

  _identify = (param: Parameter) => param.name + param.type;
}
