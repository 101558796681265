import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'prg-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.less']
})
export class ToggleSwitchComponent implements OnInit {

  @Input() color: string;
  @Input() value: boolean;

  @Output() changed: EventEmitter<boolean>;

  constructor() {
    this.color = '#32b4e5';
    this.changed = new EventEmitter<boolean>();
  }

  ngOnInit() {
  }

  clicked(event: MouseEvent) {
    event.stopPropagation();
    console.log('toggle: clicked');
    this.changed.next(!this.value);
  }

}
