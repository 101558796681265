import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SensorsService } from './sensors.service';
import { QuickstartCommonModule } from './../quickstart-common/quickstart-common.module';
import { SelectSensorTypeComponent } from './select-sensor-type/select-sensor-type.component';
import { ProgrammingCommonModule } from '../../common/common.module';
import { SelectSensorComponent } from './select-sensor/select-sensor.component';
import { SelectSensorEventComponent } from './select-sensor-event/select-sensor-event.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ProgrammingCommonModule,
    QuickstartCommonModule,
  ],
  declarations: [SelectSensorTypeComponent, SelectSensorComponent, SelectSensorEventComponent],
  providers: [SensorsService]
})
export class QuickstartSensorsModule { }
