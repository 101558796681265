export enum LogLevel {
  NONE = 0,
  ERROR = 1,
  WARN = 2,
  INFO = 4,
  DEBUG = 8,
  TRACE = 16
};

export interface Logger {
  error(...args);
  warn(...args);
  info(...args);
  debug(...args);
  trace(...args);
  log(...args);
  setLevel(level: LogLevel);
}

class BaseLogger implements Logger {
  private _name = 'DEFAULT';
  private _level = LogLevel.WARN;

  constructor(name?: string, level?: LogLevel) {
    this._name = name || this._name;
    this._level = level || this._level;
  }

  error(...args) {
    if (this._level >= LogLevel.ERROR) {
      console.error(`[${this._name}]:ERROR:`, args);
    }
  }

  warn(...args) {
    if (this._level >= LogLevel.WARN) {
      console.warn(`[${this._name}]:WARN:`, args);
    }
  }

  info(...args) {
    if (this._level >= LogLevel.INFO) {
      console.info(`[${this._name}]:INFO:`, args);
    }
  }

  debug(...args) {
    if (this._level >= LogLevel.DEBUG) {
      console.debug(`[${this._name}]:DEBUG:`, args);
    }
  }

  trace(...args) {
    if (this._level >= LogLevel.TRACE) {
      console.trace(`[${this._name}]:TRACE:`, args);
    }
  }

  log(level: LogLevel, ...args) {
    if (this._level >= level) {
      console.log(`[${this._name}]:${this._levelName(level)}:`, args);
    }
  }

  setLevel(level: LogLevel) {
    this._level = level;
  }

  private _levelName(level: LogLevel): string {
    switch (level) {
      case LogLevel.ERROR: return 'ERROR';
      case LogLevel.WARN: return 'WARN';
      case LogLevel.INFO: return 'INFO';
      case LogLevel.DEBUG: return 'DEBUG';
      case LogLevel.TRACE: return 'TRACE';
    }
  }
}

export class LoggerFactory {
  protected static _defaultLogLevel = LogLevel.WARN;
  protected static _originalDefaultLevel = LogLevel.WARN;
  protected static _originalLevels = {};
  protected static _loggingTimeout: any;

  static set defaultLogLevel(level: LogLevel) { LoggerFactory._defaultLogLevel = level; }
  static get defaultLogLevel(): LogLevel { return LoggerFactory._defaultLogLevel; }

  protected static _loggers = {
    'DEFAULT': new BaseLogger('DEFAULT', LoggerFactory._defaultLogLevel)
  };

  static getLogger(name?: string, level?: LogLevel): Logger {
    // console.log('getting logger for ', name, level);
    name = name || 'DEFAULT';
    level = level || LoggerFactory._defaultLogLevel;
    let logger = LoggerFactory._loggers[name];
    if (!logger) {
      logger = new BaseLogger(name);
      LoggerFactory._loggers[name] = logger;
    }

    logger.setLevel(level);
    return logger;
  }

  static elevateLogging(level: LogLevel, duration?: number) {
    // NOTE if we've already elevated logging, reset and start over
    if (!!LoggerFactory._originalLevels) {
      clearTimeout(LoggerFactory._loggingTimeout);
      LoggerFactory._restoreLogging();
    }

    duration = duration || 30000; // 30 seconds
    console.log(`setting log level to ${level} for ${Math.floor(duration/1000)} seconds`)

    // so that new loggers created after logging is elevated are also elevated
    // NOTE unfortunately, this means they'll stay elevated until the app is reloaded
    this._originalDefaultLevel = LoggerFactory._defaultLogLevel;
    LoggerFactory.defaultLogLevel = level;

    LoggerFactory._originalLevels = {};
    Object.keys(LoggerFactory._loggers).forEach(k => {
      if (LoggerFactory._loggers.hasOwnProperty(k)) {
        LoggerFactory._originalLevels[k] = LoggerFactory._loggers[k].level;
        LoggerFactory._loggers[k].setLevel(level);
      }
    });
    // console.log('elevated loggers', LoggerFactory._loggers);

    LoggerFactory._loggingTimeout = setTimeout(() => {
      console.debug('elevated logging timeout expired');
      LoggerFactory._restoreLogging();
    }, duration);
  }

  protected static _restoreLogging() {
    console.debug('restoring original log levels');
    LoggerFactory.defaultLogLevel = LoggerFactory._originalDefaultLevel || LogLevel.WARN;

    if (!!LoggerFactory._originalLevels) {
      Object.keys(LoggerFactory._originalLevels).forEach(k => {
        if (LoggerFactory._loggers.hasOwnProperty(k) && LoggerFactory._originalLevels.hasOwnProperty(k)) {
          LoggerFactory._loggers[k].setLevel(LoggerFactory._originalLevels[k]);
        }
      });
      LoggerFactory._originalLevels = null;
    } else {
      // not sure why _originalLevels would ever be undefined (above), but doesn't hurt to be safe 
      if (!!LoggerFactory._loggers) {
        Object.keys(LoggerFactory._loggers).forEach(k => {
          if (LoggerFactory._loggers.hasOwnProperty(k)) {
            LoggerFactory._loggers[k].setLevel(LoggerFactory.defaultLogLevel);
          }
        })
      }
    }
  }
}
