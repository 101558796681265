import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'valueDisplay'
})
export class ValueDisplayPipe implements PipeTransform {

  transform(value: any, param: any): any {
    let returned = value.toString();

    let open = returned.indexOf('{');
    if (open < 0) {
      return returned;
    }

    while (open >= 0) {
      let close = returned.substring(open).indexOf('}');

      if (close >= 0) {
        let token = returned.substring(open, close);
        let field = token.substring(1, token.length - 2);
        returned.replace(token, param[field]);
      } else {
        console.log('error: invalid display value format, found open brace without close brace', value, param);
        break;
      }

      open = returned.substring('{');
    }

    return returned;
  }
}
