<main>
  <div prg-titled-container (click)="headerClicked($event)" title="Backup Required" [cxWait]="(required | async) !== 0" cxShowText="false">
    <div body class="backup-required">
      <div *ngIf="(required | async) && !backups.progress.isStarted()">
        <header>
          <h4>Backup required</h4>
        </header>
        <p>A recent backup of your system is required before adding automation. Having a backup available can help with system recovery if something goes wrong, such as an equipment failure or unexpected side-effects of automation.</p>
        <p>Backups are stored in the cloud so they are always accessible, if needed. If it ever becomes necessary to restore a backup, please contact your Control4 Dealer.</p>
        <button class="primary large" (click)="startBackup()" [disabled]="backupRequested">Back Up Now</button>
      </div>
      <div *ngIf="(backups.progress.isStarted() && !backups.progress.isComplete()) || (!backups.progress.isStarted() && backups.progress.isSuccess())">
        <header>
          <h4>{{backups.progress.message()}}</h4>
        </header>
        <p class="wrap" *ngIf="!backups.progress.isComplete()">The time required to complete a successful backup will vary depending on the number of connected devices in your Control4 system, level of customization, and Internet speed.</p>
      </div>
      <div *ngIf="(backups.progress.isComplete() && !backups.progress.isSuccess())">
        <header>
          <h4>{{backups.progress.message()}}</h4>
          <p>{{backups.progress.error().message}}</p>
        </header>
      </div>
    </div>
  </div>
</main>