import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'matchingDisplay'
})
export class MatchingDisplayPipe implements PipeTransform {

  transform(value: any, args?: any): any {

    if (args && value && value.length) {
      let text = args.toString().toLowerCase();
      return value.filter(val => {
        let found = false;

        if (val.display) {
          let disp = val.display.toLowerCase();
          found = (disp.indexOf(text) >= 0);
        }

        if (!found && val.codeItems && val.codeItems.length) {
          found = this.findTextInCodeItems(val.codeItems, text);
        }

        return found;
      });
    }

    return value;
  }

  private findTextInCodeItems(codeitems: Array<any>, text: string): boolean {
    let found = false;

    if (codeitems) {
      for (let i = 0; i < codeitems.length; i++) {
        let ci = codeitems[i];
        let disp = ci.display.toLowerCase();
        found = (ci.display && disp.indexOf(text) >= 0);
        if (found) {
          break;
        }
      }
    }

    return found;
  }
}
