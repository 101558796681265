import { Component, OnInit, Input, EventEmitter, Output, enableProdMode } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { CodeItem } from '../../../common/interfaces/code-item.interface';
import { Event } from '../../../common/interfaces/event.interface';
import { SharedProgrammingService, CommonProgrammingContext } from '../../../common/services/shared-programming.service';

@Component({
  selector: 'prg-codeitem-list,[prg-codeitem-list]',
  templateUrl: './codeitem-list.component.html',
  styleUrls: ['./codeitem-list.component.less']
})
export class CodeitemListComponent implements OnInit {

  @Input() codeItems: CodeItem[];
  @Input() event: Event;
  @Input() parent: CodeItem;

  @Output() delete: EventEmitter<CodeItem>;

  editing: Observable<boolean>;

  constructor(
    private shared: SharedProgrammingService,
    private store: Store<{ sharedProgramming: CommonProgrammingContext }>
  ) {
    this.editing = this.store.select(s => s.sharedProgramming.editing);
    this.delete = new EventEmitter<CodeItem>();
  }

  ngOnInit() {
  }

  private toggleEnabled(codeitem: CodeItem) {
    // console.log('codeitem: toggling enabled for', codeitem);
    this.shared.toggleCodeitemEnabled(codeitem);
  }

  isProtected(ci: CodeItem): boolean {
    return ci.protected || !ci.enabled || (!!this.parent && !this.parent.enabled);
  }
}
