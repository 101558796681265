import { Store } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';

import { SelectEventService } from './select-event-type.service';
import { SharedProgrammingService } from '../../common/services/shared-programming.service';
import { EventType } from '../quickstart-simple/event-types/event-type.interface';
import { BaseRoutingComponent } from '../../common/base-routing/base-routing.component';
import { Router } from '@angular/router';

@Component({
  selector: 'prg-select-event-type',
  templateUrl: './select-event-type.component.html',
  styleUrls: ['./select-event-type.component.less']
})
export class SelectEventTypeComponent extends BaseRoutingComponent implements OnInit {

  ready: Observable<boolean>;
  searchText: Observable<string>;

  constructor(
    protected store: Store<any>,
    protected router: Router,
    private shared: SharedProgrammingService,
    public selectEventService: SelectEventService
  ) {
    super();
    this.ready = this.selectEventService.ready;
    this.searchText = this.selectEventService.searchText;
  }

  ngOnInit() {
    // this.shared.clearSelectedEvent();
  }

  searchTextChanged(event: any) {
    this.shared.setSearchText(event.target.value);
  }

  typeClicked(type: EventType) {
    this.shared.setEventType(type);
  }
}
