import { EventType } from './event-type.interface';

export const SPRINKLER_SYSTEM: EventType = {
  name: 'Sprinkler System',
  description: 'When a sprinkler system is turned on or off',
  route: 'sprinklers',
  category: 'Landscaping',
  icon: 'water',
  whitelist: [
    {
      fields: { proxy: 'relaysingle_sprinklers_c4' },
      events: [1, 2]
    }
  ]
}
