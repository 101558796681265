import { EventType } from './event-type.interface';

export const SECURITY_SYSTEM: EventType = {
  name: 'Security System',
  description: 'When a security system state changes',
  route: 'security',
  category: 'Security & Access',
  icon: 'security',
  whitelist: [
    {
      fields: { proxy: 'security' },
      events: Array.apply(null, { length: 100 }).map(Number.call, Number)
    }
  ]
}
