import { EventType } from './event-type.interface';

export const GARAGE: EventType = {
  name: 'Garage',
  description: 'When a Garage Door opens or closes',
  route: 'garage',
  category: 'Security & Access',
  icon: 'garage',
  whitelist: [
    {
      fields: { proxy: 'contactsingle_garagedoorsensor_c4' },
      events: [1, 2]
    },
    {
      fields: { proxy: 'relaycontact_garagedoor_c4' },
      events: [1, 2]
    },
    {
      fields: { proxy: 'relaysingle_garagedoor_c4' },
      events: [1, 2]
    }
  ]
}
