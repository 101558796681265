import { Component, Input, ElementRef, HostBinding, HostListener } from '@angular/core';

@Component({
  selector: '[tooltip]',
  templateUrl: './tooltip.directive.html',
  styleUrls: ['./tooltip.directive.less'],
  host: {
    '[style.position]': "'relative'",
    '(mouseenter)': 'onMouseEnter()',
    '(mouseleave)': 'onMouseLeave()'
  }
})
export class TooltipDirective {

  @Input('tooltip-text') text: string;
  @Input('tooltip-icon') icon: string;
  @Input('tooltip-position') position: string = 'left';

  _hover: boolean = false;

  constructor() { }

  onMouseEnter = () => {
    this._hover = true;
  }

  onMouseLeave = () => {
    this._hover = false;
  }

}
