import { EventType } from './event-type.interface';

export const DOOR_SENSOR: EventType = {
  name: 'Door',
  description: 'When a door is opened or closed',
  route: 'door-sensor',
  category: 'Security & Access',
  icon: 'door',
  whitelist: [
    {
      fields: { proxy: 'contactsingle_doorcontactsensor_c4' },
      events: [1, 2]
    }
  ]
}
