<div *ngIf="(ready | async)">
  <prg-nav-header title="Which action on the {{light | async | deviceInRoom }} do you want to take place?" (cancel)="goToEvent()"></prg-nav-header>
  <cxScrollable>

    <prg-block-button cxNoDoubleClick *ngFor="let command of (_simpleCommands|async)" (click)="setCommand(command)" leadingIcon="blue lightbulb"
      title="{{ command.display }}" trailingIcon="light"></prg-block-button>

    <prg-block-button cxNoDoubleClick *ngFor="let command of (_parameterCommands|async)" [routerLink]="['../parameters', command.command, {id: command.id}]"
      leadingIcon="blue lightbulb" title="{{ command.label }}" trailingIcon="right"></prg-block-button>

  </cxScrollable>
</div>
