
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';


export interface ToolbarContext {
  sidebarVisible: boolean;
  title: string;
  subtitle: string;
}

const INITIAL_STATE: ToolbarContext = {
  sidebarVisible: true,
  title: '',
  subtitle: ''
};

const STORE_NAME: string = 'TOOLBAR:';
const TOGGLE_SIDEBAR: string = STORE_NAME + 'TOGGLE_SIDEBAR';

export function toolbarReducer(state: ToolbarContext = INITIAL_STATE, {type, payload}) {
  switch (type) {
    case TOGGLE_SIDEBAR: return Object.assign({}, state, { sidebarVisible: !state.sidebarVisible });

    default: return state;
  }
};

@Injectable()
export class ToolbarService {
  context: Observable<ToolbarContext>;

  constructor(private store: Store<any>) {
    this.context = <Observable<ToolbarContext>>this.store.select('toolbar');
  }

  toggleSidebar() {
    this.store.dispatch({ type: TOGGLE_SIDEBAR });
  }
}
