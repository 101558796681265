import { Pipe, PipeTransform } from '@angular/core';

import { LabelizePipe } from './labelize.pipe';
import { CapitalizePipe } from './capitalize.pipe';

const paramRegex = /PARAM:([0-9]+)/g;

@Pipe({
  name: 'commandLabel'
})
export class CommandLabelPipe implements PipeTransform {

  constructor(
    private labelize: LabelizePipe,
    private capitalize: CapitalizePipe
  ) {
    // code...
  }

  transform(display: string, params?: {
    name: string
  }[]): string {

    if (!params || params.length == 0) {
      return display;
    }

    let tokens = display.match(paramRegex);

    if (!tokens || tokens.length == 0) {
      return display;
    }

    tokens.forEach(token => {
      let [p, i] = token.split(":");
      let index = parseInt(i);

      if (!!params[index]) {
        let name = this.capitalize.transform(this.labelize.transform(params[index].name));
        display = display.replace(token, name);
      }
    });

    return display;
  }
}
