import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Store } from '@ngrx/store';

import { CommonProgrammingContext, SharedProgrammingService } from './../../../common/services/shared-programming.service';
import { Device } from '../../../common/interfaces/item.interface';
import { ProgrammingUtilsService as Utils } from '../../../common/services/utils.service';
import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';
import { LocationsService, Location } from '@when-then/core';

@Component({
  selector: 'prg-select-device',
  templateUrl: './select-device.component.html',
  styleUrls: ['./select-device.component.less']
})
export class SelectDeviceComponent extends BaseRoutingComponent implements OnInit {

  @Input() source: Array<Device>;
  @Input() titleText: string;
  @Input() iconClass: string;
  @Input() rowClass: string;

  @Output() selected: EventEmitter<Device>;

  allFloors: Observable<Location[]>;
  allRooms: Observable<Location[]>;

  _searchText: string;
  _currentRoom: Observable<Location>;
  _currentFloor: Observable<Location>;

  ready: Observable<boolean>;

  constructor(
    protected store: Store<{
      sharedProgramming: CommonProgrammingContext
    }>,
    private route: ActivatedRoute,
    private shared: SharedProgrammingService,
    private locations: LocationsService,
    protected router: Router
  ) {
    super();

    this.ready = this.shared.isReady;

    this.selected = new EventEmitter<Device>();
    this.rowClass = '';

    this._searchText = '';
  }

  ngOnInit() {
    this.allRooms = this.locations.getRooms();
    this.allFloors = this.locations.getFloors();
  }

  deviceSearchChanged(event: any) {
    this._searchText = event.target.value;
  }

  setFloor(event: any) {
    // console.log('select-device: setting floor: ', event.target.value);
    this._currentFloor = this.locations.getLocationById(parseInt(event.target.value));
  }

  setRoom(event: any) {
    // console.log('select-device: setting room: ', event.target.value);
    this._currentRoom = this.locations.getLocationById(parseInt(event.target.value));
  }
}
