import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment';

@Pipe({
  name: 'happeningToday'
})
export class HappeningTodayPipe implements PipeTransform {

  transform(events: Array<any>, args?: any): any {
    if (events) {
      // console.log('filtering events for current day', events);
      return events.filter(evt => {
        // console.log('checking event', evt);
        if (evt && evt.next_occurence) {
          let today = moment();

          let next = moment(new Date(
            evt.next_occurence.year,
            evt.next_occurence.month-1,
            evt.next_occurence.day,
            evt.next_occurence.hour,
            evt.next_occurence.min, 0));

            // console.log('comparing scheduled programming with today', today, next);

          return (next.isSame(today, 'day')); // && next.isAfter(today));
        }
      });
    }

    return events;
  }

}
