import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { CookieService } from 'ngx-cookie';

import { StorageService, UrlFactoryService, LoggerFactory } from '@when-then/core';

import { AuthenticationStrategy } from './authentication-strategy';
import { AuthenticationState } from '../services/authentication-state';

const JWT_COOKIE_NAME: string = 'JWT';

@Injectable()
export class RemoteAuthStrategy implements AuthenticationStrategy {
  private _logger = LoggerFactory.getLogger(RemoteAuthStrategy.name);
  authentication: Observable<AuthenticationState>;

  constructor(
    private http: HttpClient,
    private cookies: CookieService,
    private url: UrlFactoryService,
    private storage: StorageService
  ) {
  }

  authenticate(profile: string, password: string): Promise<any> {
    this._logger.debug('remote-auth: authenticate()');

    // get cookie
    let jwt = this.cookies.get(JWT_COOKIE_NAME);
    this._logger.debug('remote-auth: check jwt cookie resposne', jwt);

    // NOTE if we have a JWT cookie we assume we are auhenticated
    if (jwt) {
      return Promise.resolve();
    } else {
      return Promise.reject({ message: 'missing or invalid auth token' });
    }

  }

  unauthenticate(): Promise<any> {
    this._logger.debug('remote-auth:: unauthenticate()');
    this.cookies.remove(JWT_COOKIE_NAME);
    return Promise.resolve();
  }

  validateAuthentication(): Promise<boolean> {
    this._logger.debug('remote-auth:: validateAuthentication()');
    return new Promise((resolve, reject) => {
      let options = this._getOptions();

      this.http.get(this.url.getBrokerURL('/api/v1/logger/level'), options).subscribe(
        response => resolve(true),
        error => {
          // DEBUG let's not remove the cookie just yet
          // this.unauthenticate().then(() => {
          //   resolve(false);
          // });

          resolve(false);
        });
    });
  }

  getAuthToken(): string {
    return this.cookies.get(JWT_COOKIE_NAME);
  }

  isAuthenticated(): boolean {
    this._logger.warn('remote-auth:: isAuthenticated not supported with Local auth');
    return true;
  }

  isAuthorized(): boolean {
    let authToken: string = this.cookies.get(JWT_COOKIE_NAME);
    return (authToken !== null && authToken.length > 0);
  }

  getCurrentAccount(accessToken: string): Promise<string> {
    this._logger.warn('remote-auth:: getCurrentAccount not supported with Local auth');
    return Promise.reject({ message: 'unsupported: getCurrentAccoutn unsupported with Local auth' });
  }

  getHeaders(token?: string): HttpHeaders {
    let headers = new HttpHeaders();

    token = token || this.storage.get('authToken');
    if (token) {
      headers.append('Authorization', 'Bearer ' + token);
    }
    headers.append('Accept', 'application/json');
    // headers.append('Content-Type', 'application/json');
    return headers;
  }

  checkLicense(profile: string): Promise<any> {
    // NOTE used for portal access, assumption is that the portal controls access to the
    // app based on it's evaluation of the accounts 4Sight license.
    // Might need to change this if this auth strategy is used elsewhere
    return Promise.resolve(true);
  }

  private _getOptions(): any {
    return {
      withCredentials: true,
      headers: this.getHeaders()
    }
  }
}

