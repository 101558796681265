import { BaseRoutingComponent } from './../../../common/base-routing/base-routing.component';
import { QuickstartCustomButtonsService } from './../services/custom-buttons.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { QuickstartProgrammingService } from '../../services/quickstart-programming.service';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { SharedProgrammingService } from '../../../common/services/shared-programming.service';

@Component({
  selector: 'prg-select-custom-button',
  templateUrl: './select-custom-button.component.html',
  styleUrls: ['./select-custom-button.component.less']
})
export class SelectCustomButtonComponent extends BaseRoutingComponent implements OnInit {
  screen: Observable<any>;
  ready: Observable<boolean>;

  constructor(
    protected router: Router,
    protected store: Store<any>,
    private shared: SharedProgrammingService,
    private route: ActivatedRoute,
    private customButtonsService: QuickstartCustomButtonsService
  ) {
    super();
    this.screen = this.store.select(s => s.customButtonsProgramming.trigger.screen);
    this.ready = this.customButtonsService.ready;
  }

  ngOnInit() {
  }

  buttonSelected(button: any) {
    this.customButtonsService.setButton(button);
    this.router.navigate(['../interactions'], { relativeTo: this.route });
  }

  goHome() {
    this.router.navigate(['../programming'], { relativeTo: this.moduleRoot });
  }
}
