import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColorPickerModule } from 'ngx-color-picker';

import { UIModule } from '@when-then/ui';

import { RequiresBackupGuard } from './guards/requires-backup.guard';
import { WhenThenTileComponent } from './when-then-tile/when-then-tile.component';
import { BlockButtonComponent } from './block-button/block-button.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { WorkingComponent } from './working/working.component';

import { EventsService } from './services/events.service';
import { TimeOfDayService } from './services/time-of-day.service';
import { ItemsService } from './services/items.service';
import { ProgrammingUtilsService } from './services/utils.service';
import { DeviceIconsService } from './services/device-icons.service';
import { EventStartTimeLabelPipe } from './pipes/event-start-time-label.pipe';

import { SortByNamePipe } from './pipes/sort-by-name.pipe';
import { CountCodeItemsPipe } from './pipes/count-code-items.pipe';
import { DeviceCategoryService } from './services/device-category.service';
import { SharedProgrammingService } from './services/shared-programming.service';
import { OSCompatibilityService } from './services/os-compatibility.service';
import { AgentMetaService } from './services/agent-meta.service';

import { ParamInput } from './params/param-input.component';
import { ParamInputs } from './params/param-inputs.component';
import { ParamLabel } from './params/param-label.component';

import {
  CapitalizePipe, CategoryEventFilterPipe, CategoryImagePipe, CategoryLabelPipe, CategoryItemsPipe,
  DeviceLocationPipe, DeviceEventsFilterPipe, DeviceNamePipe, DistinctProxiesPipe, EventDisplayPipe,
  FontAwesomeClassPipe, FriendlyProxyNamePipe, HasProxyPipe, ListFilterPipe, LocationEventFilterPipe,
  LocationItemsFilterPipe, NameFilterPipe, NormalizeCategoryPipe, RoomEventFilterPipe, OSCompatibilityPipe,
  RoomItemsFilterPipe, SelectDevicesFilterPipe, SelectLocationsFilterPipe, SelectRoomsFilterPipe,
  SingularizePipe, HasParamsPipe, LabelizePipe, ValueDisplayPipe, ListItemDisplayPipe,
  ItemsByNameOrRoomPipe, AggregateDisplayPipe, MatchingDisplayPipe, ComposedTitlePipe,
  ScheduleEventsPipe, HappeningTodayPipe, ExpiredRecurrencePipe, ReadablePipe,
} from './pipes/index';

import { ScheduleTileComponent } from './schedule-tile/schedule-tile.component';
import { ScheduleTitlePipe } from './pipes/schedule-title.pipe';
import { DeviceInRoomPipe } from './pipes/device-in-room.pipe';
import { MatchingSearchTextPipe } from './pipes/matching-search-text.pipe';
import { CreatorPipe } from './pipes/creator.pipe';
import { TriggerLabelPipe } from './pipes/trigger-label.pipe';
import { HappeningBeforePipe } from './pipes/happening-before.pipe';
import { HappeningAfterPipe } from './pipes/happening-after.pipe';
import { CodeitemsExpanderIconPipe } from './pipes/codeitems-expander-icon.pipe';
import { ProgrammingHeaderComponent } from './programming-header/programming-header.component';
import { HasButtonEventsPipe } from './pipes/has-button-events.pipe';
import { NavContainerComponent } from './nav-container/nav-container';
import { GlanceComponent } from './glance/glance.component';
import { TriggerIconClassPipe } from './pipes/trigger-icon-class.pipe';
import { UnixTimeFormatPipe } from './pipes/unix-time-format.pipe';
import { ActionIconClassPipe } from './pipes/action-icon-class.pipe';
import { SummaryComponent } from './summary/summary.component';
import { TitledContainerComponent } from './titled-container/titled-container.component';
import { CodeitemActionIconPipe } from './pipes/codeitem-action-icon.pipe';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ProtectedProgrammingComponent } from './protected-programming/protected-programming.component';
import { RecentComponent } from './recent/recent.component';
import { TimestampToLocaleStringPipe } from './pipes/timestamp-to-locale-string.pipe';
import { SortSchedulesPipe } from './pipes/sort-schedules';
import { EventIconPipe } from './pipes/event-icon.pipe';
import { DeviceIconPipe } from './pipes/device-icon.pipe';
import { MatchingFloorPipe } from './pipes/matching-floor.pipe';
import { MatchingRoomPipe } from './pipes/matching-room.pipe';
import { CustomButtonsMatchingFloorPipe } from './pipes/custom-buttons-matching-floor.pipe';
import { CustomButtonsMatchingRoomPipe } from './pipes/custom-buttons-matching-room.pipe';
import { InteractionIconPipe } from './pipes/interaction-icon.pipe';
import { GroupByRoomPipe } from './pipes/group-by-room.pipe';
import { GroupByCategoryPipe } from './pipes/group-by-category.pipe';
import { GroupByTimePipe } from './pipes/group-by-time.pipe';
import { SortByPipe } from './pipes/sort-by.pipe';
import { RoomsFromDevicesPipe } from './pipes/rooms-from-devices.pipe';
import { FloorsFromDevicesPipe } from './pipes/floors-from-devices.pipe';
import { SupportedTriggersPipe } from './pipes/supported-triggers.pipe';
import { IconPipe } from './pipes/icon.pipe';
import { MatchingNameOrLocationPipe } from './pipes/matching-name-or-location.pipe';
import { AlertModalComponent } from './alert-modal/alert-modal.component';
import { LightSceneDeviceNamePipe } from './pipes/light-scene-device-name.pipe';
import { LightSceneDeviceEndStatePipe } from './pipes/light-scene-device-end-state.pipe';
import { ToggleSwitchComponent } from './toggle-switch/toggle-switch.component';
import { NotInARoomPipe } from './pipes/not-in-a-room.pipe';
import { InARoomPipe } from './pipes/in-a-room.pipe';
import { LabelIconComponent } from './label-icon/label-icon.component';
import { TooltipDirective } from './tooltip/tooltip.directive';
import { CommandLabelPipe } from './pipes/command-label.pipe';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { LimitPipe } from './pipes/limit.pipe';
import { RecentPipe } from './pipes/recent.pipe';
import { SortByDatePipe } from './pipes/sort-by-date.pipe';
import { SortByRoomPipe } from './pipes/sort-by-room.pipe';
import { InTriggerCategoryPipe } from './pipes/in-trigger-category.pipe';
import { EffectsModule } from '@ngrx/effects';
import { SharedProgrammingEffects } from './services/shared-programming.effects';
import { ScheduleEventLabelPipe } from './pipes/schedule-event-label.pipe';
import { NonScheduleEventGuard } from './guards/non-schedule-event.guard';
import { WhenThenHeaderComponent } from './when-then-header/when-then-header.component';
import { FormModalComponent } from './form-modal/form-modal.component';

let pipes = [
  CapitalizePipe, CategoryEventFilterPipe, CategoryImagePipe, CategoryLabelPipe, CategoryItemsPipe,
  CustomButtonsMatchingFloorPipe, CustomButtonsMatchingRoomPipe,
  DeviceLocationPipe, DeviceEventsFilterPipe, DeviceNamePipe, DistinctProxiesPipe, EventDisplayPipe,
  FontAwesomeClassPipe, FriendlyProxyNamePipe, HasProxyPipe, ListFilterPipe, LocationEventFilterPipe,
  LocationItemsFilterPipe, NameFilterPipe, NormalizeCategoryPipe, RoomEventFilterPipe, RoomItemsFilterPipe,
  SelectDevicesFilterPipe, SelectLocationsFilterPipe, SelectRoomsFilterPipe, SingularizePipe,
  HasParamsPipe, LabelizePipe, ValueDisplayPipe, ListItemDisplayPipe, ItemsByNameOrRoomPipe, DeviceIconPipe,
  AggregateDisplayPipe, MatchingDisplayPipe, ComposedTitlePipe, HappeningTodayPipe,
  ScheduleEventsPipe, ExpiredRecurrencePipe, ScheduleTitlePipe, DeviceInRoomPipe, MatchingSearchTextPipe,
  SortByNamePipe, TriggerLabelPipe, HappeningBeforePipe, HappeningAfterPipe, CodeitemsExpanderIconPipe,
  MatchingRoomPipe, MatchingFloorPipe, InteractionIconPipe, EventIconPipe, MatchingFloorPipe, MatchingRoomPipe,
  GroupByRoomPipe, GroupByCategoryPipe, GroupByTimePipe, SortByPipe, FloorsFromDevicesPipe, RoomsFromDevicesPipe,
  EventStartTimeLabelPipe, LightSceneDeviceNamePipe, LightSceneDeviceEndStatePipe, IconPipe,
  HasButtonEventsPipe, ReadablePipe, SortSchedulesPipe, TimestampToLocaleStringPipe, UnixTimeFormatPipe, EventIconPipe,
  GroupByRoomPipe, GroupByCategoryPipe, GroupByTimePipe, SortByPipe, RoomsFromDevicesPipe,
  FloorsFromDevicesPipe, SupportedTriggersPipe, IconPipe, MatchingNameOrLocationPipe,
  InteractionIconPipe, NotInARoomPipe, InARoomPipe, TriggerIconClassPipe, ActionIconClassPipe, CodeitemActionIconPipe, CountCodeItemsPipe,
  CreatorPipe, CommandLabelPipe, LimitPipe, RecentPipe, SortByDatePipe, SortByRoomPipe, InTriggerCategoryPipe,
  ScheduleEventLabelPipe, OSCompatibilityPipe
];

let providers = [
  DeviceCategoryService, DeviceIconsService, ItemsService, ProgrammingUtilsService, AgentMetaService,
  SharedProgrammingService, TimeOfDayService, RequiresBackupGuard, EventsService,
  OSCompatibilityService, ItemsService, NonScheduleEventGuard
];

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ColorPickerModule,
    CommonModule,
    RouterModule,
    UIModule,
    EffectsModule.forFeature([SharedProgrammingEffects])
  ],
  declarations: [
    ...pipes,
    WhenThenTileComponent,
    ScheduleTileComponent,
    BlockButtonComponent,
    ProgrammingHeaderComponent,
    NavContainerComponent,
    GlanceComponent,
    SummaryComponent,
    TitledContainerComponent,
    ParamInput,
    ParamInputs,
    ParamLabel,
    BreadcrumbsComponent,
    BreadcrumbComponent,
    ProtectedProgrammingComponent,
    ConfirmModalComponent,
    RecentComponent,
    AlertModalComponent,
    ToggleSwitchComponent,
    LabelIconComponent,
    AlertModalComponent,
    TooltipDirective,
    WorkingComponent,
    WhenThenHeaderComponent,
    FormModalComponent,
  ],
  providers: [
    ...pipes,
    ...providers
  ],
  exports: [
    ...pipes,
    WhenThenTileComponent,
    ScheduleTileComponent,
    BlockButtonComponent,
    GlanceComponent,
    ProgrammingHeaderComponent,
    NavContainerComponent,
    TitledContainerComponent,
    ParamInput,
    ParamInputs,
    ParamLabel,
    BreadcrumbComponent,
    ProtectedProgrammingComponent,
    ToggleSwitchComponent,
    AlertModalComponent,
    ConfirmModalComponent,
    TooltipDirective,
    WorkingComponent,
    WhenThenHeaderComponent,
    FormModalComponent,
  ]
})
export class ProgrammingCommonModule { }
