<cxScrollable>
  <cxWait [cxUntil]="_command | async">
    <prg-nav-header title="Action Parameters" (cancel)="goToEvent()"></prg-nav-header>
    <header>
      <p>Please provide the information required to perform the <strong>{{ (_command | async)?.label || (_command |
          async)?.command }}</strong> action on the <strong>{{_device | async | deviceInRoom}}</strong>.</p>
    </header>
    <div class="params">
      <form>
        <div class="field-group" *ngFor="let param of _parameters; trackBy: _identify">
          <label class="leading">{{ param.name | readable }}
            <span *ngIf="param.type == 'RANGE' && param._value != undefined">({{ param._value }})</span>
          </label>
          <c4ParamInput [param]="param" (valueChange)="_update(param, $event)"></c4ParamInput>
        </div>
      </form>
    </div>
    <footer>
      <div class="confirm clickable rounded-rect" (click)="_saveCommand()">
        <span class="icon blue small" [ngClass]="_icon | async"></span>
        <h6 class="word-wrap grow"><c4ParamLabel [command]="_command|async" [params]="_parameters"></c4ParamLabel></h6>
        <span class="icon small grey right"></span>
      </div>
    </footer>
  </cxWait>
</cxScrollable>