import { QuickstartCommonModule } from './../quickstart-common/quickstart-common.module';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { UIModule } from '@when-then/ui';

import { ProgrammingCommonModule } from '../../common/common.module';

import { VSCreateComponent } from './create/create.component';
import { VSListComponent } from './list/list.component';
import { VSEditComponent } from './edit/edit.component';

import { VoiceScenesService } from './voice-scenes.service';

const PIPES = [

];

const SERVICES = [
  VoiceScenesService
];

const COMPONENTS = [
  VSCreateComponent,
  VSListComponent,
  VSEditComponent
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UIModule,
    ProgrammingCommonModule,
    QuickstartCommonModule,
    HttpClientModule,
    RouterModule.forChild([])
  ],
  declarations: [
    ...PIPES,
    ...COMPONENTS
  ],
  entryComponents: [
  ],
  providers: [
    ...PIPES,
    ...SERVICES
  ],
  exports: [
    ...PIPES
  ]
})
export class VoiceScenesModule { }
