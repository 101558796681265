<div class="modal">
  <section>
    <header *ngIf="!!title">
      <h4 class="grow">{{title}}</h4>
    </header>
    <div class="content">
      <ng-content select="[message]"></ng-content>
    </div>
    <footer>
      <button (click)="confirm.next()">{{confirmLabel}}</button>
    </footer>
  </section>
</div>
