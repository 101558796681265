import { EventType } from './event-type.interface';

export const BLINDS: EventType = {
  name: 'Blinds and Shades',
  description: 'When blinds or shades are open or closed',
  route: 'blinds',
  category: 'Security & Access',
  icon: 'shades',
  whitelist: [
    {
      fields: { proxy: 'blind' },
      events: [2, 4, 8, 9]
    },
    // {
    //   fields: { proxy: 'blindV2' },
    //   events: [1, 2]
    // },
    {
      fields: { proxy: 'shade' },
      events: [1, 2, 3]
    }
  ]
}
