import { Pipe, PipeTransform } from '@angular/core';
import { ItemsService } from '../../../common/services/items.service';
import { IconPipe } from '../../../common/pipes/icon.pipe';
import { DeviceEvent } from '../../../common/interfaces/event.interface';
import { Device } from '../../../common/interfaces/item.interface';

@Pipe({
  name: 'proxyEventIcon'
})
export class ProxyEventIconPipe implements PipeTransform {

  constructor(
    private iconPipe: IconPipe,
    private items: ItemsService
  ) { }

  transform(value: any, args?: any): any {
    let icon = undefined;

    let event: DeviceEvent = <DeviceEvent>value;
    // console.log('getting icon for event', event);

    let device = this.items.getItem(event.deviceId);
    // console.log('device for event ', device);

    if (device) {
      switch (device.proxy) { // legacy keypads
        case 'control4_keypad_kpz-11-w':
        case 'control4_keypad_kpz-31-w':
        case 'control4_keypad_kpz-61-w':
          icon = 'keypad';
          break;
        case 'contactsingle_windowcontactsensor_c4':
          icon = this._windowIcon(event);
          break;
        case 'relaysingle_doorlock_c4':
        case 'Lock_Zigbee_Baldwin_SmartLock':
        case 'DriverWorks_BlackDecker_Lock':
        case 'DriverWorks_BlackDecker_Lock_SmartLock RF Zigbee_Black and Decker':
        case 'Lock_Zigbee_Kwikset_SmartLock':
        case 'Lock_ZigBee_Yale':
        case 'lock':
          icon = this._doorLockIcon(event);
          break;
        case 'contactsingle_doorcontactsensor_c4':
          icon = this._doorSensorIcon(event);
          break;
        case 'contactsingle_garagedoorsensor_c4':
        case 'relaycontact_garagedoor_c4':
        case 'relaysingle_garagedoor_c4':
          icon = this._garageDoorIcon(event);
          break;
        case 'relaysingle_gasfireplace_c4':
          icon = this._fireplaceIcon(event);
          break;
        case 'contactsingle_drivewaysensor_c4':
          icon = (event.eventId === 1) ? 'driveway' : 'driveway-off';
          break;
        case 'contactsingle_gate_c4':
        case 'relaycontact_electronicgate_c4':
        case 'relaysingle_electronicgate_c4':
          icon = (event.eventId === 1) ? 'gate-open' : 'gate';
          break;
        case 'contactsingle_humiditysensor_c4':
          icon = (event.eventId === 1) ? 'humidity' : 'humidity-off';
          break;
        case 'contactsingle_motionsensor_c4':
          icon = (event.eventId === 1) ? 'motion-trigger' : 'motion';
          break;
        case 'contactsingle_heatdetector_c4':
          icon = (event.eventId === 1) ? 'heat' : 'heat-2';
          break;
        case 'blind':
          icon = (event.eventId === 2) ? 'shades-close' : 'shades-open';
          break;
        case 'blindV2':
        case 'shade':
          icon = (event.eventId === 1) ? 'shades-open' : 'shades-close';
          break;
        case 'contactsingle_doorbell_c4':
          icon = 'touch-press';
          break;
      }
    }
    // console.log('derived proxy event icon is', icon);

    return icon || this.iconPipe.transform(value, args);
  }

  private _windowIcon(event: DeviceEvent): string {
    return (event.eventId === 1) ? 'window-open' : 'window';
  }

  private _doorLockIcon(event: DeviceEvent): string {
    return (event.eventId === 1) ? 'door-lock' : 'door-unlock';
  }

  private _doorSensorIcon(event: DeviceEvent): string {
    return (event.eventId === 1) ? 'door-open' : 'door';
  }

  private _garageDoorIcon(event: DeviceEvent): string {
    return (event.eventId === 1) ? 'garage-open' : 'garage';
  }

  private _fireplaceIcon(event: DeviceEvent): string {
    return (event.eventId === 1) ? 'fireplace-off' : 'fireplace';
  }
}
