<div [cxWait]="(ready | async)">
  <div class="alert" *ngIf="(screen | async)?.buttons?.button?.length === 0">
    <h4>No Buttons Defined</h4>
    <p>The selected screen does not have any buttons configured. Please contact your Control4 dealer for assistance. You can
      also <a onclick="window.history.back()">try another screen</a>.</p>
  </div>
  <prg-select-screen-button *ngIf="(screen | async)?.buttons?.button?.length > 0" 
    [screen]="(screen | async)"
    (selected)="buttonSelected($event)" iconClass="keypad"></prg-select-screen-button>
</div>
