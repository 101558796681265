import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'triggerLabel'
})
export class TriggerLabelPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let label = '';

    if (value) {
      label = value.description || value.display || 'UNKNOWN';
    }

    return label;
  }

}
