// angular
import { Component, ElementRef, ViewChild, ChangeDetectorRef, NgZone } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Component({
  selector: 'cxScrollable',
  templateUrl: './scrollable.directive.html',
  styleUrls: [
    './scrollable.directive.less'
  ]
})
export class ScrollableDirective {

  @ViewChild('cxScrollable', {static: true}) element!: ElementRef;

  top: number;
  boxCompensation: number;

  height: SafeStyle;

  uiPollHandle: number;
  uiPollInterval = 100;

  i = 0;

  public get container(): HTMLElement {
    return (!!this.element) ? this.element.nativeElement : null;
  }

  constructor(
    private sanitizer: DomSanitizer,
    private detector: ChangeDetectorRef,
    private zone: NgZone
  ) {
    this.height = sanitizer.bypassSecurityTrustStyle('50vh');

    // Since we have no inputs or bound
    // DOM events, we can ignore the
    // normal change detection loop
    detector.detach();

    // By default, Zone.js patches setInterval
    // and Angular triggers the change detection
    // loop every time the interval ticks, this
    // isolates local change detection so we're
    // not forcing the rest of the app to thrash
    // every time we poll the DOM
    zone.runOutsideAngular(() => {
      this.uiPollHandle = window.setInterval(() => {
        this.checkHeight();
      }, this.uiPollInterval);
    });
  }

  ngOnDestroy() {
    // Kill the loop when we're done
    this.zone.runOutsideAngular(() => {
      window.clearInterval(this.uiPollHandle);
    });
  }

  /**
   * Takes a given element, returns a list
   * of all ancestors
   */
  private getAncestors(element: HTMLElement): HTMLElement[] {

    let tracking = element;
    let ancestors: HTMLElement[] = [tracking];

    while (!!tracking.parentElement) {
      tracking = tracking.parentElement;
      ancestors.push(tracking);
    }

    return ancestors;
  }

  /**
   * Checks the DOM for our element, and
   * it's height.
   */
  private checkHeight() {
    if (!!this.container && this.container.offsetTop !== this.top) {

      this.top = this.container.offsetTop;
      this.boxCompensation = this.calculateCompensation();
      this.height = this.sanitizer.bypassSecurityTrustStyle('calc(100vh - ' + (this.top + this.boxCompensation) + 'px)');

      this.detector.detectChanges();

    }
  }

  /**
   * Figure out how much space needs to remain below
   * the scrolling container. This respects the
   * padding, border and margin of all ancestral elements.
   */
  private calculateCompensation(): number {

    let ancestors = this.getAncestors(this.container);

    let compensation = ancestors.reduce((accumulator, element) => {
      let style = window.getComputedStyle(element);
      return accumulator + [
        parseFloat(style.marginBottom),
        parseFloat(style.paddingBottom),
        parseFloat(style.borderBottomWidth)
      ].reduce((acc, curr) => {
        return acc + curr;
      }, 0);
    }, 0);

    console.log('scrollable compensation ', compensation);
    return compensation;
  }
}
