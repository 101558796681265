import {Pipe, PipeTransform} from '@angular/core';

declare var _: any;

@Pipe({
  name: 'singularize'
})
export class SingularizePipe implements PipeTransform {
  transform(value: string): string {
    if (value && value.endsWith('s')) {
      if (value.endsWith('ies')) {
        return ((value.substring(0, value.length - 3) + 'y'));
      }

      return value.substring(0, value.length - 1);
    }

    return value;
  }
}
