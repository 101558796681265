import { EventType } from './event-type.interface';

export const MOTION: EventType = {
  name: 'Motion',
  description: 'When a motion sensor detects motion or lack thereof',
  route: 'motion',
  category: 'Sensors',
  icon: 'motion-trigger',
  whitelist: [
    {
      fields: { proxy: 'contactsingle_motionsensor_c4' },
      events: [1, 2]
    },
    {
      fields: { proxy: 'contactsingle_motionsensor' },
      events: [1, 2]
    }
  ]
}
