import { Injectable, EventEmitter } from '@angular/core';
import { HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable, Observer, BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { TeardownLogic } from 'rxjs';
import { filter, distinctUntilChanged, map, take, flatMap } from 'rxjs/operators';

import { BaseService } from './base.service';
import { MonitoredHttpService as Http, JWT_STATUS, CONNECTION_STATUS } from './monitored-http.service';

import { StorageService } from './storage.service';
import { UrlFactoryService } from './url-factory.service';
import { GUIDService } from './guid.service';
import { LoggerFactory, Logger } from './log.service';
import { PerformanceService, REQUEST_ID_HEADER } from './performance.service';
import { HttpOptions, HttpMethods } from '../interfaces/http';

declare const window: any;
declare const global: any;

export interface QueryString {
  [key: string]: any;
}

export interface BrokerRequestHeader {
  [key: string]: string;
}

export interface BrokerRequest {
  path: string;
  method?: string;
  queryString?: QueryString;
  headers?: BrokerRequestHeader;
  data?: any;
}

export class BrokerSubscriber<T> extends EventEmitter<T> {
  private _logger = LoggerFactory.getLogger(BrokerSubscriber.name);
  constructor(private socket: any, private subscriberId: string) {
    super();
  }

  unsubscribe() {
    this._logger.debug('brokersub: removing event listener for subscription ' + this.subscriberId);
    this.socket.removeListener(this.subscriberId);
    this._logger.debug('brokersub: emitting endSubscription command for subscription ' + this.subscriberId);
    this.socket.emit('endSubscription', this.subscriberId);
  }
}

@Injectable()
export class BrokerService extends BaseService {
  private _logger: Logger;

  public connecting: BehaviorSubject<boolean>;
  public connected: BehaviorSubject<boolean>;
  public disconnected: BehaviorSubject<boolean>;
  public reconnecting: BehaviorSubject<boolean>;
  public initialized: BehaviorSubject<boolean>;
  public reconnected: BehaviorSubject<boolean>;
  public reconnectFailed: BehaviorSubject<boolean>;
  public afterConnect: BehaviorSubject<boolean>;

  private _connecting: boolean;
  private _subscribers: Map<string, BehaviorSubject<any>>;
  private _subscriptionClientId: string;
  private _socket: any;
  private _loadSocketIOTimeout: any;

  constructor(
    private http: Http,
    private urlFactory: UrlFactoryService,
    private storage: StorageService,
    private store: Store<any>,
    private perf: PerformanceService,
    private guid: GUIDService
  ) {
    super();
    this._logger = LoggerFactory.getLogger(BrokerService.name);
    this._connecting = false;
    this._subscribers = new Map<string, BehaviorSubject<any>>();

    this.connecting = new BehaviorSubject<boolean>(false);
    this.connected = new BehaviorSubject<boolean>(false);
    this.disconnected = new BehaviorSubject<boolean>(false);
    this.reconnecting = new BehaviorSubject<boolean>(false);
    this.reconnectFailed = new BehaviorSubject<boolean>(false);
    this.initialized = new BehaviorSubject<boolean>(false);
    this.reconnected = new BehaviorSubject<boolean>(false);
    this.afterConnect = new BehaviorSubject<boolean>(false);

    // NOTE have to reference the store blind here because auth depends on core
    this.store.select(s => s.authentication)
      .pipe(
        filter(a => !!a),
        map(a => a.initialized),
        filter(i => !!i)
      )
      .subscribe(initialized => {
        this._logger.debug('broker: authentication initialized', initialized);
        this.store.select(s => s.authentication.authenticated).pipe(distinctUntilChanged()).subscribe(authenticated => {
          this._logger.debug('broker: auth changed', authenticated);
          if (authenticated) {
            this._logger.debug('broker: now authenticated, initalizing broker');
            this._init({ connectStream: true }).then(res => {
              this._logger.debug('broker: initialization complete');
            }, err => {
              this._logger.error('broker: error intializing broker after auth change', err);
            });
          } else {
            this._logger.debug('broker: no longer authenticated, disconnecting broker');
            this._disconnect();
          }
        });
      });
  }

  call(brokerReq: BrokerRequest): Promise<any> {
    this._logger.debug('broker:call()', brokerReq);

    var request = this.buildRequest(brokerReq);
    this._logger.debug('composed request', request);
    const p = new Promise((resolve, reject) => {
      this.http.monitoredRequest(request)
        .subscribe(
          (data) => resolve(data),
          err => {
            this._logger.error('error making broker request', brokerReq, err);
            reject(err);
          });
    });

    this.perf.monitor(request, p);

    return p;
  }

  // use this when non-JSON is returned from broker
  callNonJSON(brokerReq: BrokerRequest): Promise<any> {
    this._logger.debug('broker:callNonJSON()', brokerReq);
    var request = this.buildRequest(brokerReq);
    const p = new Promise((resolve, reject) => {

      this.http.monitoredRequest(request)
        .subscribe(
          res => {
            // this._logger.debug('broker: http success response - non-JSON returned', res);
            resolve(res);
          },
          err => {
            this._logger.error('error making broker request', brokerReq, err);
            reject(err);
          });
    });

    this.perf.monitor(request, p);

    return p;
  }

  // TODO Please clean me up :'(
  subscribe<TBody = null>(brokerReq: BrokerRequest): Promise<BrokerSubscriber<any>> {
    this._logger.debug('broker:subscribe()', brokerReq);

    return new Promise<BrokerSubscriber<any>>((resolve, reject) => {
      brokerReq.queryString = brokerReq.queryString || [];
      brokerReq.queryString = Object.assign({}, brokerReq.queryString || {}, { 'SubscriptionClient': this._subscriptionClientId, 'JWT': this.storage.get('authToken') });

      var httpRequest: HttpRequest<TBody> = this.buildRequest(brokerReq);
      this._logger.debug('broker: http request prepared', httpRequest);
      this.http.monitoredRequest(httpRequest).subscribe((data: {subscriptionId: string}) => {
        this._logger.debug('request response received', data);
        if (data.subscriptionId) {
          // create a new subcriber based on an BehaviorSubject that also provides the consumer the
          // ability to unsubscribe
          var subscriber = new BrokerSubscriber<any>(this._socket, data.subscriptionId);

          this._logger.debug('starting subscription timing for ', data);
          this.perf.start(brokerReq.path, data.subscriptionId, httpRequest.headers.get('X-REQEUST-ID'), this._subscriptionClientId);

          // when a message is recieved on the socket for the given subscriptionId, invoke the
          // next() method on the obervable which causes a subscribed consumer to receive the
          // message
          this._socket.on(data.subscriptionId, (msg: any) => {
            this.perf.tick(data.subscriptionId);
            this._logger.debug('broker: subscription message received for ' + data.subscriptionId, msg);
            subscriber.next(msg);
          });

          // tell the socket server to start the subscription
          this._socket.emit('startSubscription', data.subscriptionId);

          // return the observable to the consumer
          resolve(subscriber);
        } else {
          this._logger.error('broker: no subscriptionId received from server');
          reject({ message: 'subscription not created' });
        }
      }, (err: any) => {
        this._logger.error('error making request', brokerReq, httpRequest, err);
        reject(err);
      });
    });
  }

  /**
   * Accepts BrokerRequest, and returns an observable
   * that subscribes to the endpoint ONLY upon calling
   * subscribe(). Also waits for Broker to be connected,
   * so the service doesn't have to bother.
   */
  getObservable<T>(incomingRequest: BrokerRequest): Observable<T> {
    return new Observable<T>((observer: Observer<T>) => {

      let _subscriptionId: string;
      let _requestId: string;

      const next = (data: T) => {
        this.perf.tick(_subscriptionId);
        observer.next(data);
      };

      const error = (error: any) => {
        observer.next(error);
      };

      this.connected
        .pipe(
          filter(c => !!c),
          distinctUntilChanged(),
          flatMap(connected => {

            const queryString: QueryString = Object.assign(
              {},
              incomingRequest.queryString,
              {
                SubscriptionClient: this._subscriptionClientId,
                JWT: this.storage.get('authToken')
              }
            );

            const brokerRequest: BrokerRequest = Object.assign(
              {},
              incomingRequest,
              {
                queryString: queryString
              }
            );

            const httpRequest = this.buildRequest(brokerRequest);
            _requestId = httpRequest.headers.get(REQUEST_ID_HEADER);

            return this.http.monitoredRequest<{}, { subscriptionId?: string }>(httpRequest)
              .pipe(
                map(r => r),
                filter((r: { subscriptionId?: string }) => !!r.subscriptionId),
                map((r: any) => r.subscriptionId)
              );

          })
        )
        .subscribe(
          id => {
            this._logger.debug(`sid for path ${incomingRequest.path} is ${id}`)
            _subscriptionId = id;
            this.perf.start(incomingRequest.path, id, _requestId, this._subscriptionClientId);

            this._socket.emit('startSubscription', _subscriptionId);

            this._socket.on(_subscriptionId, next);
          },
          error
        );

      return (): TeardownLogic => {
        this._logger.debug('tearing down subscripotion for', _subscriptionId);
        // NOTE the socket will be closed when the current authentication
        // is cleared, but the subscription might only be ended when
        // the component owning it is unloaded, so check to see that we
        // still have a socket to work with.
        if (this._socket) {
          // It's theoretically possible
          // to unsubscribe from an observable
          // before we've had a chance to actually
          // listen, so make this conditional
          if (!!_subscriptionId) {
            this.perf.stop(_subscriptionId);
            this._socket.off(_subscriptionId, next);
          }

          // If there are no other listeners,
          // we can safely stop the subscription
          if (!this._socket.hasListeners(_subscriptionId)) {
            this._logger.debug('emitting end sub for ', _subscriptionId);
            this._socket.emit('endSubscription', _subscriptionId);
          }
        }
      }

    });
  }

  // TODO this should be wrapped up in the observable returned from subscribe
  unsubscribe(subscriberId: string): void {
    var emitter: BehaviorSubject<any> = this._subscribers.get(subscriberId);
    if (emitter) { // just checking to see that we know what this is
      this._socket.removeListener(subscriberId);
      this.perf.stop(subscriberId);
      this._socket.emit('endSubscription', subscriberId);
      this._subscribers.delete(subscriberId);
    }
  }

  private _init(options: any): Promise<any> {
    var that = this;
    return new Promise((resolve, reject) => {

      this._disconnect().then(res => {
        // NOTE avoid parallel requests to initialize broker
        if (!that._connecting) {
          that._connecting = true;

          that._loadSocketIOTimeout = setTimeout(() => {
            this._logger.error('error loading socket.io, is broker running?');
            that.manualDisconnect();
          }, 60000);

          let jwt = this.storage.get('authToken');
          // this._logger.debug('broker: (REMOVE THIS) JWT prior to socket.io lib request', jwt);
          if (!jwt || jwt.length === 0) {
            reject({ message: 'missing or invalid JWT in context for broker init', severity: 'FATAL' });
          }

          var url = that.urlFactory.getBrokerURL('/socket.io/socket.io.js', {
            'JWT': jwt
          });
          // this._logger.debug('get socket.io url is ' + url);

          // TODO reevaluate this
          // NOTE ng2 http does not yet support examining response headers, so we'll have to get socket.io every time
          // var socketIOMD5 = this.storage.get('socketIOMD5');
          var headers = new HttpHeaders();

          var options: HttpOptions = {
            headers,
            responseType: 'text'
          };

          // !!! TODO add preflight OPTIONS request with Access-Control-RequestMethodw and Origin headers to enable CORS
          that.http.monitoredRequest(HttpMethods.GET, url, options).subscribe(data => {
            clearTimeout(that._loadSocketIOTimeout);
            that._loadSocketIOTimeout = null;

            globalEval(data);
            that.connect().then(
              (result) => {
                this._connecting = false;
                resolve(result);
              },
              (err) => {
                this._logger.error('broker: unable to initialize broker socket connection, subscriptions are unavaialble', err);
                this._connecting = false;
                reject(err);
              });
          }, (err: any) => {
            clearTimeout(that._loadSocketIOTimeout);
            that._loadSocketIOTimeout = null;

            this._connecting = false;
            this._logger.error('broker: error getting socket.io', err);
            reject(err);
          });
        }
      }, err => {
        this._logger.debug('broker: error disconnecting prior to re-init', err);
        reject(err);
      });
    });
  }

  endAllSubscriptions() {
    this._logger.debug('broker: endAllSubscriptions');

    for (var k in this._subscribers) {
      this.unsubscribe(k);
    }
    this._subscribers.clear();

    this._socket.removeAllListeners();
    this._socket.emit('endAllSubscriptions');
  }

  /**
   * @deprecated
   * @returns {boolean}
   * @memberOf BrokerService
   */
  isConnected(): boolean {
    this._logger.warn('broker.svc: isConnected() is deprecated.  Subscribe to connected instead');
    return (typeof this._socket !== 'undefined' && typeof this._subscriptionClientId !== 'undefined');
  }

  /**
   * @see manualDisconnect
   * This function is used to allow a consumer to forcce the service to reconnect to Broker.  This
   * can be important in situations where, for example, an app on a mobile device is suspended and then
   * resumed.  In this case it is important to force this service to rebuild its internal state. Since
   * it's not known how long the app was suspended.
   */
  manualReconnect(): Promise<any> {
    return this.connect();
  }

  /**
   * @see manualReconnect
   * This method is called to force this service to disconnect from Broker. This can be important for cases
   * where, for example, an app on a mobile device is being suspended.  In this case it can take several minutes
   * for the connection to broker to disconnect due to inactivity.  It is useful therefore to be able to force
   * the service to disconnect as the app is being suspended.
   */
  manualDisconnect(): Promise<any> {
    return this._disconnect();
  }

  private connect(): Promise<any> {
    var that = this;
    return new Promise(function(resolve, reject) {

      that.connected.pipe(take(1)).subscribe(connected => {

        if (connected) {
          that._disconnect();
        }

        var options: any = {};
        // NOTE assume https protocol here
        options['connect timeout'] = 100;
        options['forceNew'] = true;
        options['query'] = 'JWT=' + that.storage.get('authToken');

        const transport = that.storage.get('apiTransport');
        if (!!transport) {
          options['transports'] = transport.split(',');
        } else {
          options['transports'] = ['polling', 'websocket'];
        }
        // options['transports'] = ['polling', 'websocket'];
        // options['reconnectionAttempts'] = 200;

        var url = that.urlFactory.getBrokerURL();

        that.connecting.next(true);

        that._socket = window.io.connect(url, options);
        that._socket.heartbeatTimeout = 10000;
        that._socket.closeTimeout = 10000;

        that.afterConnect.next(true);

        that._socket.on('connect', function() {
          that._logger.debug('broker: connected');

          if (that._socket.io && that._socket.io.engine) {
            that._subscriptionClientId = that._socket.io.engine.id;
          } else {
            that._subscriptionClientId = that._socket.socket.sessionId;
          }
          that.storage.set('subscriptionClientId', that._subscriptionClientId);

          if (!that._subscriptionClientId) {
            that._logger.error('broker: error initalizing broker api, no subscription client id received');
            reject({ message: 'no subscription client id received' });
            that.connected.next(false);
          } else {
            that._logger.debug('broker: subscriptionClientId is ' + that._subscriptionClientId);
            that.connected.next(true);
            resolve();
          }
        });

        that._socket.on('disconnect', reason => {
          that._logger.debug('DEBUG >> broker.service.ts:452: socket disconnected: %s', reason);
          that.disconnected.next(true);

          // We can't know _why_ we lost the connection,
          // just that it's lost. It's the responsibility
          // of the MonitoredHttpService to work out whether
          // the JWT is good
          that.http.setJwtStatus(JWT_STATUS.UNKNOWN);

          // However, the connection itself is almost
          // certainly down...
          that.http.setConnectionStatus(CONNECTION_STATUS.DISCONNECTED);
        });

        that._socket.on('reconnect', attempt => {
          that._logger.debug('DEBUG >> broker.service.ts:457: socket reconnected after %s attempts', attempt);
          that.reconnected.next(true);
          that.http.setJwtStatus(JWT_STATUS.VALID);
          that.http.setConnectionStatus(CONNECTION_STATUS.CONNECTED);
        });

        that._socket.on('reconnecting', attempt => {
          that._logger.warn('WARN >> broker.service.ts:466: socket reconnection attempt %s', attempt);
          that.reconnecting.next(true);
        });

        that._socket.on('reconnect_failed', () => {
          that._logger.error('ERROR >> broker.service.ts:475: socket reconnect failed');
          that.reconnectFailed.next(true);
        });

        that._socket.once('connect', () => {
          that.initialized.next(true);
        });

        that._socket.on('connect', () => {
          that._logger.debug('LOG >> broker.service.ts:440: socket connected');
          that.http.setJwtStatus(JWT_STATUS.VALID);
          that.http.setConnectionStatus(CONNECTION_STATUS.CONNECTED);
        });

      });

    });
  }

  private _disconnect(): Promise<any> {
    var handler = (resolve: Function, reject: Function) => {
      try {
        if (this._socket) {
          if (this._socket.disconnectSync) {
            this._socket.disconnectSync();
          } else {
            this._socket.disconnect();
          }
        }

        if (this._socket) {
          this._socket.removeAllListeners();
          delete this._socket;
          if (typeof (window.io) !== 'undefined' && window.io.sockets) {
            window.io.sockets = {};
          }
        }

        this.connected.next(false);

        resolve();
      } catch (e) {
        reject(e);
      }
    }
    return new Promise(handler);
  }

  // TODO This method needs a clean up -Samuel
  buildRequest<TBody = {}>(request: BrokerRequest, subscriptionClientId?: string): HttpRequest<TBody> {
    const method = this.getRequestMethod(request.method);
    const url = this.buildURL(request);
    let req: HttpRequest<TBody> = new HttpRequest<TBody>(method as any, url);

    var headers = this.buildHeaders(request.headers);
    if (req.method === HttpMethods.POST || req.method === HttpMethods.PUT || req.method === HttpMethods.DELETE) {
      if (request.data) {
        headers.append('Content-Type', 'application/json');
        req = {
          ...req,
          responseType: 'json',
          body: JSON.stringify(request.data)
        } as HttpRequest<TBody | any>;
      }
    }

    req = {
      ...req,
      headers: headers,
      // NOTE the following is required to allow the JWT cookie to be sent for ng2 http requests
      // for the case where the app is accepting a JWT in the query string at startup
      withCredentials: true
    } as HttpRequest<TBody>;

    return req;
  }

  private buildURL(request: BrokerRequest): string {
    return this.urlFactory.getBrokerURL(request.path, request.queryString);
  }

  private buildHeaders(additionalHeaders?: BrokerRequestHeader): HttpHeaders {
    var headers = new HttpHeaders();
    headers.append('Authorization', 'Bearer ' + this.storage.get('authToken'));
    headers.append('Accept', 'application/json');
    // TODO decide how to correlate this with responses
    headers.append(REQUEST_ID_HEADER, this.guid.generate());

    if (additionalHeaders) {
      Object.keys(additionalHeaders).forEach(key => {
        headers.append(key, additionalHeaders[key]);
      });
    }

    return headers;
  }

  private getRequestMethod(given: string): HttpMethods {
    switch (given) {
      case 'GET':
        return HttpMethods.GET;
      case 'POST':
        return HttpMethods.POST;
      case 'PUT':
        return HttpMethods.PUT;
      case 'DELETE':
        return HttpMethods.DELETE;
      default:
        return HttpMethods.GET;
    }
  }
}

// Non-jQuery global eval function
var globalEval = (function(global: any, realArray, indirectEval, indirectEvalWorks?) {
  try {
    eval('var Array={};');
    indirectEvalWorks = indirectEval('Array') == realArray;
  } catch (err) { }

  return indirectEvalWorks ? indirectEval :
    (global.execScript
      ? function(expression) {
        global.execScript(expression);
      }
      : function(expression) {
        setTimeout(expression, 0);
      });
})(this, Array, window.eval);
