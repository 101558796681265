import { ItemsService } from './../services/items.service';
import { DeviceCategoryService } from './../services/device-category.service';
import { Device } from './../interfaces/item.interface';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'deviceIcon'
})
export class DeviceIconPipe implements PipeTransform {

  constructor(
    private items: ItemsService,
    private categories: DeviceCategoryService
  ) { }

  transform(id: number, args?: any): any {
    let icon = 'unknown';
    if (!!id) {
      let item = this.items.getItem(id);
      if (!!item) {
        icon = this.categories.getCategoryIconForItem(item);
        // let cat = this.categories.getItemCategory(item);
        // if (!!cat) {
        //   icon = this._getIconForCategory(cat);
        // }
      } else {
        console.warn('deviceIcon: request for icon, item not found', id);
      }
    }

    return icon;
  }

  // private _getIconForCategory(cat: string): string {
  //   return this.categories.getCategoryIconForItem()
  // }
}
