import { Routes } from '@angular/router';
import { DelayComponent } from './delay/delay.component';
import { FlowControlComponent } from './flow-control.component';
import { SelectConditionalComponent } from './select-conditional/select-conditional.component';
import { ConditionalComponent } from './conditional/conditional.component';
import { ConditionalParamsComponent } from './conditional-params/conditional-params.component';
import { NonScheduleEventGuard } from '../../common/guards/non-schedule-event.guard';

export const CONDITIONAL_ROUTES: Routes = [{
  path: '', component: ConditionalComponent,
  canActivate: [NonScheduleEventGuard], canActivateChild: [NonScheduleEventGuard],
  children: [{
    path: '', redirectTo: 'select', pathMatch: 'full'
  }, {
    path: 'select', component: SelectConditionalComponent
  }, {
    path: ':name/params', component: ConditionalParamsComponent
  }]
}];

export const QUICKSTART_FLOW_CONTROL_ROUTES: Routes = [{
  path: 'flow', component: FlowControlComponent, children: [{
    path: 'delay', component: DelayComponent
  }, {
    path: 'delay/conditionals', children: [...CONDITIONAL_ROUTES]
  }]
}];

