/**
 * Copyright 2017 Control4 Corporation. All rights reserved.
 * Control4 Confidential and Proprietary Information.
 */

import { NgModule, InjectionToken, enableProdMode } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { EffectsModule } from '@ngrx/effects';

import { StoreModule, ActionReducerMap } from '@ngrx/store';

import { CoreModule, LoggerFactory, LogLevel } from '@when-then/core';
import { AuthModule } from '@when-then/auth';
import { UIModule } from '@when-then/ui';
import { AssetsModule } from '@when-then/assets';


import { environment } from '../environments/environment';

import { ProgrammingModule } from './../app/programming.module';
import { ProgrammingCommonModule } from './../app/common/common.module';

import { DevComponent } from './dev.component';
import { DevRoutingModule } from './dev.routes';
import { StoreRouterConnectingModule, DefaultRouterStateSerializer } from '@ngrx/router-store';
import { APP_REDUCERS, AppState } from './dev.store';
import { BackupProjectComponent } from './backup/backup-project.component';
import { SessionExpiredComponent } from './session-expired/session-expired.component';

if (environment.production) {
  enableProdMode();
  LoggerFactory.defaultLogLevel = LogLevel.WARN;
} else {
  LoggerFactory.defaultLogLevel = LogLevel.DEBUG;
}

export const REDUCER_TOKEN = new InjectionToken<
  ActionReducerMap<AppState>
  >('Registered Reducers');

export function getReducers(): ActionReducerMap<AppState> {
  return APP_REDUCERS;
}

@NgModule({
  declarations: [
    DevComponent,
    BackupProjectComponent,
    SessionExpiredComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    AssetsModule,
    CoreModule,
    UIModule,
    AuthModule,
    ProgrammingCommonModule,
    ProgrammingModule,
    DevRoutingModule,
    EffectsModule.forRoot([]),
    StoreModule.forRoot(REDUCER_TOKEN, {
      runtimeChecks: {
          strictStateImmutability: false,
          strictActionImmutability: false,
      }
  }),
    StoreRouterConnectingModule.forRoot({ serializer: DefaultRouterStateSerializer })
  ],
  providers: [
    {
      provide: REDUCER_TOKEN,
      useFactory: getReducers
    }
  ],
  bootstrap: [DevComponent]
})
export class DevModule { }
