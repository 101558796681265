import { Store } from '@ngrx/store';
import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';

@Component({
  selector: 'prg-select-button-interaction',
  templateUrl: './select-button-interaction.component.html',
  styleUrls: ['./select-button-interaction.component.less']
})
export class SelectButtonInteractionComponent extends BaseRoutingComponent implements OnInit {
  @Input() button: any;
  @Input() iconClass: string;
  @Input() device: any;
  @Input() events: Array<any>;

  @Output() selected: EventEmitter<any>;

  constructor(
    protected router: Router,
    protected store: Store<any>,
  ) {
    super();

    this.selected = new EventEmitter<any>();
  }

  ngOnInit() {
    if (!this.device || !this.button) {
      this.router.navigate(['../programming'], { relativeTo: this.moduleRoot });
    }
  }

}
