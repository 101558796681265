<div class="when-then" *ngIf="!!event">
  <div class="event dark">
    <div class="icon blue" [ngClass]="event | proxyEventIcon"></div>
    <div class="text">
      <div class="title">{{event?.display}}</div>
      <cxWait *ngIf="event?.deviceId === 100100 && (showSubtitle | async)" [cxUntil]="!!subtitle" cxText="evaluating..."
        cxShowSpinner="false">
        <div class="subtitle">{{subtitle}}</div>
      </cxWait>

    </div>
    <div class="delta"></div>
  </div>
  <div class="actions" [ngClass]="{'empty': isEmpty()}">
    <div prg-codeitem-list class="action fill" *ngIf="!!expanded && !isEmpty()" [event]="event" [codeItems]="event?.codeItems"
      (delete)="delete.next($event)">
    </div>
    <div prg-codeitem-summary class="fill" *ngIf="!expanded && !isEmpty()" [event]="event"></div>
    <div (click)="toggleExpanded($event)" class="expander" *ngIf="event?.codeItems?.length > 1">
      <span class="icon dark-grey" [ngClass]="{'caret-up': !!expanded, 'caret-down': !expanded}"></span>
    </div>
    <div *ngIf="isEmpty()" class="flex row nowrap">
      <span class="text grow">No actions currently assigned.</span>
    </div>
  </div>
</div>
