import { Action } from '@ngrx/store';

import { MEDIA_ACTIONS, MediaDevice } from './quickstart-media.service';

export class SetListenDevices implements Action {
  readonly type = MEDIA_ACTIONS.SET_LISTEN_DEVICES;

  constructor(public payload: {
    id: number,
    name: string,
    listen_devices: MediaDevice[]
  }[]) { }
}

export class SetWatchDevices implements Action {
  readonly type = MEDIA_ACTIONS.SET_WATCH_DEVICES;

  constructor(public payload: {
    id: number,
    name: string,
    watch_devices: MediaDevice[]
  }[]) { }
}
