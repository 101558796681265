import { Routes, RouterModule } from '@angular/router';

import { QUICKSTART_TRIGGERS_KEYPADS_ROUTES } from '../quickstart-keypads/quickstart-keypads.routes';
import { QUICKSTART_TRIGGERS_REMOTES_ROUTES } from '../quickstart-system-remotes/quickstart-system-remotes.routes';
import { ButtonsComponent } from './buttons.component';
import { QUICKSTART_TRIGGERS_LIGHTS_BUTTONS_ROUTES } from '../quickstart-lights/quickstart-lights.routes';
import { QUICKSTART_TRIGGER_DOORSTATION_BUTTONS_ROUTES } from '../quickstart-doorstations/quickstart-doorstation.routes';
import { QUICKSTART_TRIGGERS_CUSTOM_BUTTONS_ROUTES } from '../quickstart-custom-buttons/quickstart-custom-buttons.routes';

export const QUICKSTART_TRIGGERS_BUTTONS_ROUTES: Routes = [{
  path: 'buttons', component: ButtonsComponent, children: [
    ...QUICKSTART_TRIGGERS_LIGHTS_BUTTONS_ROUTES,
    ...QUICKSTART_TRIGGERS_KEYPADS_ROUTES,
    ...QUICKSTART_TRIGGERS_REMOTES_ROUTES,
    ...QUICKSTART_TRIGGER_DOORSTATION_BUTTONS_ROUTES,
    ...QUICKSTART_TRIGGERS_CUSTOM_BUTTONS_ROUTES
  ]
}];
