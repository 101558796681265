import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortByName'
})
export class SortByNamePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let field = undefined;
    if (args) {
      field = args[0];
    }

    if (value && Array.isArray(value)) {
      return value.sort((a: any, b: any) => {
        if (field) {
          return a[field].localCompare(b[field]);
        } else {
          return a.toString().localeCompare(b.toString());
        }
      });
    } else {
      return value;
    }
  }

}
