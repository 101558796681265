<div *ngIf="(_ready | async)">
  <prg-nav-header title="Select a Remote to find" (cancel)="goToEvent()"></prg-nav-header>
  <cxScrollable>
    <cxWait [cxUntil]="(_remotes | async) != undefined" cxDisplayBlock="true" cxText="Finding your remotes...">

      <prg-block-button *ngFor="let remote of (_remotes | async)" (click)="_setAction(remote)" leadingIcon="blue remote"
        title="{{remote | deviceName}}" subtitle="{{remote | deviceLocation}}"></prg-block-button>

    </cxWait>
  </cxScrollable>
</div>
