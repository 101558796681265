import { ActionReducerMap } from '@ngrx/store';

import { thermostatProgrammingReducers, ThermostatProgrammingContext } from './quickstart-thermostats.service';

export interface QuickstartThermostatState {
  thermostatProgramming: ThermostatProgrammingContext
}

export const QUICKSTART_THERMOSTAT_REDUCERS: ActionReducerMap<QuickstartThermostatState> = {
  thermostatProgramming: thermostatProgrammingReducers
}
