import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { ProgrammingUtilsService as Utils } from './utils.service';

export interface OSCompatibilityMapType {
    type: string;
    name: string;
    OSMinVersion: string;
}

// Map for OS feature compatibility
// Broker did not support related routes until the version of the OS indicated
// details here: https://confluence.control4.com/pages/viewpage.action?pageId=54846182
// Min OS Versions for dynamic actions (LED Colors and Blinds) are indicated in quickstart-actions.json
export const OSCompatibilityMap: Array<OSCompatibilityMapType> = [
    {'type':'menu-item', 'name':'edit-lighting-scenes', 'OSMinVersion':'2.10.4'},
    {'type':'menu-item', 'name':'voice-scene', 'OSMinVersion':'2.10.3'},
    {'type':'event', 'name':'voice-scene', 'OSMinVersion':'2.10.3'},
    {'type':'event', 'name':'legacy-keypad-button-press', 'OSMinVersion':'2.10.4'},
    {'type':'event', 'name':'custom-buttons', 'OSMinVersion':'3.0.0'},
    {'type':'action', 'name':'legacy-keypad-led-colors', 'OSMinVersion':'2.10.4'},
    {'type':'action', 'name':'remote-beep', 'OSMinVersion':'2.10.2'},
    {'type':'action', 'name':'room-volume', 'OSMinVersion':'2.10.3'},
    {'type':'action', 'name':'security-driver-button-press', 'OSMinVersion':'2.10.3'}
];

@Injectable()
export class OSCompatibilityService {

  _osVersion: Observable<string>;

  constructor(
    private store: Store<any>
  ) {
    this._osVersion = this.store.select(s => s.sharedProgramming.osVersion);
  }

  isFeatureSupported(featureType:string, featureName: string): boolean {

    let osVersion = Utils.getValue(this.store, s => s.sharedProgramming.osVersion);

    let result: boolean = true;
    const featureItem = OSCompatibilityMap.find(fi => (fi.type === featureType) && (fi.name === featureName));
    if (!featureItem)
      result = false;
    else
    if (featureItem.OSMinVersion && osVersion) {
      if (osVersion === 'unknown')
        result = false;
      else {
        if (!osVersion || !featureItem.OSMinVersion) // if either is undefined return false
          return false;
        else
          result = (this.compareVersions(osVersion, featureItem.OSMinVersion) >= 0);
      }
    }
    // console.log("--isFeatureSupported--", featureType, featureName, osVersion, result);
    return result;
  }

  // returns true if version passed in is greater or equal to current controller
  isVersionOrGreater(checkVersion: string) {
    let osVersion = Utils.getValue(this.store, s => s.sharedProgramming.osVersion);
    if (!!osVersion && (osVersion !== "unknown") && !!checkVersion && (checkVersion !== "unknown"))
        return (this.compareVersions(osVersion, checkVersion) >= 0);
    else
        return false;
  }

  /* compareVersions(a,b)
    Returns:
    - a number < 0 if a < b
    - a number > 0 if a > b
    - 0 if a = b 
    Note: do not use for testing equal versions (has to be exact match)
    */
   private compareVersions(a:string, b:string): number {

    // chops off -res
    if (a.indexOf("-res") > -1)
      a = a.substring(0,a.indexOf("-res"));

    if (b.indexOf("-res") > -1)
      b = b.substring(0,b.indexOf("-res"));

    var i, diff;
    var regExStrip0 = /(\.0+)+$/;
    var segmentsA = a.replace(regExStrip0, '').split('.');
    var segmentsB = b.replace(regExStrip0, '').split('.');
    var l = Math.min(segmentsA.length, segmentsB.length);

    for (i = 0; i < l; i++) {
        diff = parseInt(segmentsA[i], 10) - parseInt(segmentsB[i], 10);
        if (diff) {
            return diff;
        }
    }
    return segmentsA.length - segmentsB.length;
  }

}
