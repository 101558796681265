import {Pipe, PipeTransform} from '@angular/core';
import {Store} from '@ngrx/store';

@Pipe({
  name: 'selectRooms'
})
export class SelectRoomsFilterPipe implements PipeTransform {

  constructor(
    private store: Store<any>
  ) { }

  transform(allItems: Array<any>): Array<any> {
    if (allItems) {
      return allItems.filter(item => {
        return (item.type === 8);
      });
    } else {
      return [];
    }
  }
}
