import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';

import { BaseService } from './base.service';

import { StorageService } from './storage.service';
import { UrlFactoryService } from './url-factory.service';
import { ServiceLocatorService } from './service-locator.service';

@Injectable()
export class AccountService extends BaseService {

  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private url: UrlFactoryService,
    private serviceLocator: ServiceLocatorService
  ) {
    super();
  }

  getAccountNameForController(controllerCommonName: string): Promise<any> {
    console.log('acct: getAccountName for ' + controllerCommonName);
    return new Promise((resolve, reject) => {

      // var url = this.url.getAccountURL('/v2/rest/customers?_s=certificatecommonname==' + controllerCommonName);

      // var options = new BaseRequestOptions();
      // options = options.merge({
      //   url: url,
      //   headers: this.getHeaders()
      // });

      // var request: Request = new Request(options);
      // this.http.request(request)
      //   .subscribe(
      //   res => resolve(res.json()),
      //   err => reject(err));

      reject({ message: 'deprecated: this method is deprecated due to adoption of local auth' });
    });
  }

  findAccounts(terms: any, count: number, start: number): Promise<any> {
    console.log('acct: findAccounts for ', terms, count, start);
    var that = this;
    return new Promise((resolve, reject) => {
      // var searchQuery = that.composeSearchQuery(terms);
      // if (searchQuery.length === 0) {
      //   searchQuery = 'name==*';
      // }
      // console.debug('acct: encoded search query', searchQuery);

      // var query: any = {
      //   count: count,
      //   startIndex: start,
      //   _s: searchQuery
      // };

      // var path = '/account/v2/rest/customers';
      // var url = that.url.getAccountURL(path, query);

      // var options: any = {
      //   url: url,
      //   method: 'GET',
      //   headers: that.getHeaders()
      // };
      // console.debug('acct: find accounts options', options);

      // var request = new Request(options);
      // console.debug('acct: request prepared', request);

      // that.http.request(request).subscribe(
      //   res => {
      //     var data = res.json();
      //     console.debug('acct: findAccounts response', data);
      //     resolve(data.item);
      //   },
      //   err => {
      //     console.error('acct: error on findAccounts for ', options, err);
      //     reject(err);
      //   },
      //   () => {
      //     console.debug('acct: findAccounts call complete for ', options);
      //   });

      reject({ message: 'deprecated: this method is deprecated due to adoption of local auth' });
    });
  }

  getAccountFromHref(accountHref: string): Promise<any> {
    return new Promise((resolve, reject) => {
      // var options = new BaseRequestOptions();
      // options = options.merge({
      //   headers: this.getHeaders(),
      //   method: RequestMethod.Get
      // });

      // this.http.request(accountHref, options).subscribe(
      //   res => {
      //     var data = res.json();
      //     console.debug('accounts: get account from href response', data);
      //     resolve(data);
      //   },
      //   err => {
      //     console.error('accounts: error getting account from href', err);
      //     reject(err);
      //   },
      //   () => {
      //     console.debug('accounts get account from href request complete');
      //   });
      reject({ message: 'deprecated: this method is deprecated due to adoption of local auth' });
    });
  }

  getRegistrationCode(accountHref: string): Promise<any> {
    return new Promise((resolve, reject) => {

      // var options = new BaseRequestOptions();
      // options = options.merge({
      //   headers: this.getHeaders(),
      //   method: RequestMethod.Get
      // });

      // this.http.request(accountHref + '/controller', options).subscribe(
      //   res => {
      //     resolve(res.json());
      //   },
      //   err => {
      //     console.error('accounts: error getting registration code', err);
      //     reject(err);
      //   },
      //   () => {
      //     console.debug('accounts: registerControllerToAccount complete');
      //   });

      reject({ message: 'deprecated: this method is deprecated due to adoption of local auth' });
    });
  }

  createAccount(account: any): Promise<any> {
    console.log('acct: createAccount...');
    return new Promise((resolve, reject) => {
      // var accountData: any = {
      //   "user": {
      //     "firstName": account.firstName,
      //     "lastName": account.lastName,
      //     "eMail": account.email,
      //     "accountName": account.name
      //   }
      // };

      // var options = new BaseRequestOptions();
      // options.merge({
      //   method: RequestMethod.Post,
      //   body: JSON.stringify(accountData)
      // });

      // var headers = this.getHeaders();
      // headers.append('Content-Type', 'application/json');
      // options.merge({
      //   headers: headers
      // });

      // var url = this.url.getAccountURL('/v2/rest/customers');

      // this.http.request(url, options).subscribe(
      //     res => {
      //       var data = res.json();
      //       console.debug('accounts: create account successful', data);
      //       resolve(data);
      //     },
      //     err => {
      //       console.error('accounts: error creating account', err);
      //       reject(err);
      //     },
      //     () => {
      //       console.debug('accounts: create account request complete', account);
      //     });

      reject({ message: 'deprecated: this method is deprecated due to adoption of local auth' });
    });
  }

  private composeSearchQuery(terms: any): string {
    let qs = '';

    Object.keys(terms).forEach((key: string) => {
      if (terms.hasOwnProperty(key)) {
        var val = terms[key];
        if (val && (val.length > 0)) {
          qs += (key + '==*' + val + '*;');
        }
      }
    });
    return qs;
  }

  private getHeaders(): HttpHeaders {
    let headers = new HttpHeaders();
    headers.append('Authorization', 'Bearer ' + this.storage.get('accessToken'));
    headers.append('Accept', 'application/json');
    return headers;
  }

}
