import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, distinctUntilChanged, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { SharedProgrammingService, CommonProgrammingContext } from './../../../common/services/shared-programming.service';
import { ProgrammingUtilsService as Util } from '../../../common/services/utils.service';
import { QuickstartProgrammingService } from '../../services/quickstart-programming.service';
import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';

import { TimersService, TimersProgrammingContext } from '../services/timers.service';

import { Command } from '../../../common/interfaces/command.interface';
import { Parameter } from "../../../common/interfaces/parameter.interface";

import { Device } from '../../../common/interfaces/item.interface';

@Component({
  selector: 'prg-timers-select-action',
  templateUrl: './select-action.component.html',
  styleUrls: ['./select-action.component.less']
})
export class TimersSelectActionComponent extends BaseRoutingComponent implements OnInit {
  device: Observable<Device>;
  ready: Observable<boolean>;

  private _commands: Observable<Command[]>;
  private _simpleCommands: Observable<Command[]>;
  private _parameterCommands: Observable<Command[]>;

  constructor(
    protected router: Router,
    private route: ActivatedRoute,
    private shared: SharedProgrammingService,
    protected store: Store<{
      timersProgramming: TimersProgrammingContext,
      sharedProgramming: CommonProgrammingContext
    }>,
    private timersService: TimersService,
    private utils: Util,
    private quickstarts: QuickstartProgrammingService
  ) {
    super();

    this.route.params.subscribe((params: { id: string }) => {
      this.timersService.setActionTimerDevice(parseInt(params.id));
    });

    this.device = this.store.select(s => s.timersProgramming.action.timerDevice);
    this.ready = this.shared.isReady;

    this._commands = this.store.select(s => s.timersProgramming.action.commands)
      .pipe(
        filter(c => !!c),
        distinctUntilChanged(Util.distinct),
        map((c: any) => c.map(this._setParameterDefaults))
      );

    // fix for fan_speed_controller having hidden params for commands that should be hidden
    this._simpleCommands = this._commands.pipe(map(c => c.filter(c => !c.params)));
    //this._simpleCommands = this._commands.map(c => c.filter(c => !c.params || c.params.every(p => p.type === 'HIDDEN')));

    this._parameterCommands = this._commands.pipe(map(c => c.filter(c => !!c.params && c.params.some(p => p.type !== 'HIDDEN'))));
  }

  ngOnInit() {
  }

  private _setParameterDefaults = (command: Command): Command => {
    if (!!command.params) {
      command.params = command.params.map(p => {
        p._value = p._value || p.value || p.default || p.low || p.minimum || 0;
        p._display = (typeof p._value == 'string') ? p._value : p._value.toString();

        return p;
      });
    }

    return command;
  }

  setCommand(command: Command, params?: Parameter[]) {
    let allParams: Parameter[] = [];
    allParams.concat(command.params, params);
    this.timersService.setActionCommand(command, allParams);
    /**
     * TODO parallel implementation #1
     */
    this.store.select(s => s.timersProgramming.action.command)
      .pipe(
        filter(c => !!c && c.command === command.command),
        take(1)
      )
      .subscribe(data => {
        this.router.navigate(['conditionals'], { relativeTo: this.route });
      });
  }
}
