import { Injectable } from '@angular/core';

import { Observable, from } from 'rxjs';
import { flatMap, map, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { BrokerService, BrokerRequest, Location, LocationsService } from '@when-then/core';

import { Room as BaseRoom, Device } from '../../common/interfaces/item.interface';
import { ItemsService } from './../../common/services/items.service';
import { Command } from '../../common/interfaces/command.interface';

export interface MediaDevices { }

export interface MediaDevice {
  id: number;
  type: string;
  roomId: number;
  name: string;
  category: string[];
  visible: boolean;
}

interface Media {
  roomId?: number;
  watch_devices: {
    visible: MediaDevice[],
  },
  listen_devices: {
    visible: MediaDevice[]
  }
}

interface Source {
  id: number;
  device_id: number;
  name: string;
  location: string;
  genre: string;
  description: string;
  img: string;
}

export interface Room extends BaseRoom {
  broadcast_audio?: Source[];
  broadcast_video?: Source[];
  albums?: Source[];
  playlists?: Source[];
  movies?: Source[];
  commands?: Command[];
}

export interface MediaProgrammingContext {
  rooms?: Room[];
  listen_devices?: {
    id: number,
    name: string,
    listen_devices: MediaDevice[]
  }[];
  watch_devices?: {
    id: number,
    name: string,
    watch_devices: MediaDevice[]
  }[];
}

const COMMAND_WHITELIST = [
  'SELECT_AUDIO_MEDIA:PLAYLIST',
  'SELECT_AUDIO_MEDIA:ALBUM',
  'SELECT_VIDEO_MEDIA:MOVIE',
  'SELECT_AUDIO_MEDIA:BROADCAST_AUDIO',
  'SELECT_AUDIO_DEVICE',
  'SELECT_VIDEO_MEDIA:BROADCAST_VIDEO',
  'SELECT_VIDEO_DEVICE'
];

const INITIAL_STATE: MediaProgrammingContext = {}

const STORE_NAME = ['PROGRAMMING', 'QUICKSTARTS', 'MEDIA'].join(':');

export const MEDIA_ACTIONS = {
  SET_ROOMS: `${STORE_NAME}:SET_ROOMS`,
  SET_REMOTES: `${STORE_NAME}:SET_REMOTES`,
  SET_LISTEN_DEVICES: `${STORE_NAME}:SET_LISTEN_DEVICES`,
  SET_WATCH_DEVICES: `${STORE_NAME}:SET_WATCH_DEVICES`
}

const MEDIA_ACTION_STRINGS = Object.keys(MEDIA_ACTIONS).map(key => MEDIA_ACTIONS[key]);

export function mediaProgrammingReducers(state: MediaProgrammingContext = INITIAL_STATE, { type, payload }): MediaProgrammingContext {

  // if (MEDIA_ACTION_STRINGS.indexOf(type) > -1) {
  //   console.debug('DEBUG >> %s called with %O', type, payload);
  // }

  switch (type) {

    case MEDIA_ACTIONS.SET_ROOMS:
      return Object.assign({}, state, { rooms: payload });

    case MEDIA_ACTIONS.SET_REMOTES:
      return Object.assign({}, state, { remotes: payload });

    case MEDIA_ACTIONS.SET_LISTEN_DEVICES:

      return Object.assign({}, state, { listen_devices: payload });

    case MEDIA_ACTIONS.SET_WATCH_DEVICES:

      return Object.assign({}, state, { watch_devices: payload });

    default:
      return state;
  }
}

@Injectable()
export class QSMediaService {

  constructor(
    private _store: Store<{
      mediaProgramming: MediaProgrammingContext
    }>,
    private _broker: BrokerService,
    private locations: LocationsService,
    private _items: ItemsService
  ) {
    this.locations.getRooms()
      .pipe(
        flatMap(rooms => this._attachEach(rooms, {
          path: '/api/v1/items/commands',
          queryString: {
            query: {
              deviceId: {
                '$in': rooms.map(room => room.id)
              },
              command: {
                '$in': COMMAND_WHITELIST
              }
            }
          }
        }))
      )
      .subscribe(rooms => this._store.dispatch({
        type: MEDIA_ACTIONS.SET_ROOMS,
        payload: rooms
      }));
  }

  private _attachEach = <T extends Location>(rooms: T[], request: BrokerRequest): Observable<T[]> => {
    let key = request.path.split('/').pop();
    return from(this._broker.call(request))
      .pipe(
        map((responses: {
          deviceId: number
        }[]) => {
          return rooms.map(room => {
            room[key] = responses.filter(r => r.deviceId == room.id);
            return room;
          })
        })
      );
  }

  private _attach = (room: Room, request: BrokerRequest): Observable<Room> => {
    let key = request.path.split('/').pop();
    return from(this._broker.call(request))
      .pipe(
        take(1),
        map(response => {
          room[key] = response
          return room;
        })
      );
  }

  static composeField = (field: string, option: {}): string => {
    var tokens: string[] = field.split(/[{}]/);

    if (tokens.length === 1) return option[field];

    return tokens.slice(1, -1).map((token, i) => {
      if (i % 2 === 0) {
        return option[token];
      }

      return token;
    }).join("");
  }

  static interpolate = (inputString: string, obj: Object): string => {
    const keys = inputString.match(/\[(.*?)\]/g);

    if (keys == null) {
      return inputString;
    }

    var outputString = inputString;

    keys.forEach(key => {
      outputString = outputString.replace(key, obj[key.replace('[', '').replace(']', '')])
    })
    return outputString;
  }
}
