import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie';
import { Store } from '@ngrx/store';
import {
  StorageService,
  MonitoredHttpService as Http,
  UrlFactoryService,
  LoggerFactory
} from '@when-then/core';

import { AuthenticationState } from './authentication-state';
import { AuthenticationStrategy } from '../strategies/authentication-strategy';
import { Local } from '../strategies/local';
import { RemoteAuthStrategy } from './../strategies/remote';

export const AUTHENTICATED_INITIAL_STATE: AuthenticationState = {
  initialized: false,
  authenticated: false,
  licenseChecked: false,
  licensed: false,
  redirectUrl: '',
  errors: undefined,
  busy: false
};

const STORE_NAME: string = 'AUTHENTICATION:';

export const AUTHENTICATION_ACTIONS = {
  SET_AUTHENTICATED: STORE_NAME + 'SET_AUTHENTICATED',
  SET_INITIALIZED: STORE_NAME + 'SET_INITIALIZED',
  SET_REDIRECT_URL: STORE_NAME + 'SET_REDIRECT_URL',
  SET_ERRORS: STORE_NAME + 'SET_ERRORS',
  SET_STRATEGY: STORE_NAME + 'SET_STRATEGY',
  SET_LICENSE_CHECKED: STORE_NAME + 'SET_LICENSE_CHECKED',
  SET_LICENSED: STORE_NAME + 'SET_LICENSED',
  SET_BUSY: STORE_NAME + 'SET_BUSY',
}

const ACTION_STRINGS: string[] = Object.keys(AUTHENTICATION_ACTIONS).map(key => AUTHENTICATION_ACTIONS[key]);

export function authenticationReducer(state: AuthenticationState = AUTHENTICATED_INITIAL_STATE, { type, payload }) {

  // if (ACTION_STRINGS.indexOf(type) > -1) {
  //   this._logger.info('>> type: %s, payload: %O', type, payload);
  // }

  switch (type) {
    case AUTHENTICATION_ACTIONS.SET_AUTHENTICATED:
      return Object.assign({}, state, { authenticated: payload, initialized: true });
    case AUTHENTICATION_ACTIONS.SET_INITIALIZED:
      return Object.assign({}, state, { initialized: payload });
    case AUTHENTICATION_ACTIONS.SET_REDIRECT_URL:
      return Object.assign({}, state, { redirectUrl: payload });
    case AUTHENTICATION_ACTIONS.SET_ERRORS:
      return Object.assign({}, state, { errors: payload });
    case AUTHENTICATION_ACTIONS.SET_STRATEGY:
      return Object.assign({}, state, { strategy: payload });
    case AUTHENTICATION_ACTIONS.SET_LICENSE_CHECKED:
      return Object.assign({}, state, { licenseChecked: payload });
    case AUTHENTICATION_ACTIONS.SET_LICENSED:
      return Object.assign({}, state, { licensed: payload });
    case AUTHENTICATION_ACTIONS.SET_BUSY:
      return Object.assign({}, state, { busy: payload });

    default:
      return state;
  }
};

/**
 * The AuthenticationService is directly responsible
 * for managing the store, but delegates the actual
 * work of authentication on to the configured _strategy
 * service. High-level methods remain here to provide
 * a strategy-agnostic API.
 */
@Injectable()
export class AuthenticationService {
  private _logger = LoggerFactory.getLogger(AuthenticationService.name);
  authentication: Observable<AuthenticationState>;
  busy: Observable<boolean>;

  private _strategy: AuthenticationStrategy;

  private _statusObs: Observable<string>;
  private _statusSubscription: Subscription;

  constructor(
    private store: Store<{ authentication: AuthenticationState, commonConfig: any }>,
    private storage: StorageService,
    private url: UrlFactoryService,
    private router: Router,
    private cookies: CookieService,
    // NOTE this is only injected so it can be passed to the Strategy constructor
    private http: Http
  ) {
    // NOTE for the purpose of enabling auto-detection of a JWT on the query string, this bypasses the setter
    // this should be changed to use the setter once a better auth resolution stratey is available.
    this.authentication = <Observable<AuthenticationState>>this.store.select('authentication');
    this.busy = this.store.select(s => s.authentication.busy);
  }

  unauthenticate(): Promise<any> {
    this._busy(true);
    this.store.dispatch({ type: AUTHENTICATION_ACTIONS.SET_ERRORS, payload: undefined });
    return this.strategy.unauthenticate().then(() => {
      this.store.dispatch({ type: AUTHENTICATION_ACTIONS.SET_AUTHENTICATED, payload: false });
      this.store.dispatch({ type: AUTHENTICATION_ACTIONS.SET_LICENSE_CHECKED, payload: false });
      this.store.dispatch({ type: AUTHENTICATION_ACTIONS.SET_LICENSED, payload: false });

      this._busy(false);
      // NOTE --- per requirement, user should be returned to the
      // dashboard page after re-authenticating this implementation
      // is preserved in case we change our minds

      // If for some reason unauthenticate is called, remember where
      // the user is, so they can return once re-authenticated
      // this.setRedirectUrl(this.router.routerState.snapshot.url);
      // this.router.navigate(['/authenticate'], { skipLocationChange: true });

      // NOTE --- it should not be the responsbitility of the service to navigate after unautenticating,
      // because the service has no idea what context this methbod was called in
      // this.router.navigate(['/authenticate']);
    });
  }

  authenticate(username: string, password: string) {
    this._busy(true);
    this.store.dispatch({ type: AUTHENTICATION_ACTIONS.SET_ERRORS, payload: undefined });

    this.strategy.authenticate(username, password).then(
      success => {
        this._logger.debug('authN: authenticated, setting state');
        this.store.dispatch({ type: AUTHENTICATION_ACTIONS.SET_AUTHENTICATED, payload: true });
        this._checkLicense();

        // NOTE see unauthenticate() above
        // this.store.select(s => s.authentication.redirectUrl)
        //   .take(1)
        //   .subscribe(url => {
        //     this.router.navigate([url]);
        //   });

        this._busy(false);
      },
      error => {
        this._logger.error('authentication.service.ts: Problem authenticating with %s strategy.', this._strategy.constructor.name, error);
        this.store.dispatch({ type: AUTHENTICATION_ACTIONS.SET_ERRORS, payload: error });
        this._busy(false);
      }
    );
  }

  validateAuthentication() {
    this._logger.debug('authN: validating authenticaiton');
    this.store.select(s => s.commonConfig.loaded)
      .pipe(
        filter(c => !!c),
        take(1)
      )
      .subscribe(c => {
        this.strategy.validateAuthentication().then(
          authenticated => {
            this.store.dispatch({ type: AUTHENTICATION_ACTIONS.SET_AUTHENTICATED, payload: authenticated });
            this.strategy.checkLicense(this.storage.get('profile')).then(res => {
              this._logger.debug('auth: valid 4sight license detected');
            }, err => {
              this._logger.error('auth: no valid 4sight license detected, clearing current auth context');
              this.unauthenticate();
            });
          }
        );
      });
  }

  /**
   * When we're in an unauthenticated state,
   * we need to remember where the user
   * is attempting to go so we can send
   * them on their way after authentication
   *
   * @param {string} url
   */
  public setRedirectUrl(url: string): void {
    this.store.dispatch({
      type: AUTHENTICATION_ACTIONS.SET_REDIRECT_URL,
      payload: url
    })
  }

  public get strategy(): AuthenticationStrategy {
    // this._logger.debug('~~~ getting auth strategy');
    if (!this._strategy) {
      // TODO figure out how to use injection at runtime here
      if (this.storage.get('isRemote') === true) {
        this._strategy = new RemoteAuthStrategy(this.http, this.cookies, this.url, this.storage);
      } else {
        this._strategy = new Local(this.http, this.url, this.storage);
      }
    }

    return this._strategy;
  }

  private _busy(state: boolean) {
    this.store.dispatch({ type: AUTHENTICATION_ACTIONS.SET_BUSY, payload: state });
  }

  private _checkLicense() {
    this._logger.info('INFO >> authentication.service.ts:212: _checkLicense()');
    this._busy(true);
    this._logger.debug('auth: checking license');
    let profile = this.storage.get('profile');
    if (profile === 'primary') {
      this.strategy.checkLicense(profile).then(licensed => {
        this._logger.debug('auth: 4sight license status', licensed);
        this.store.dispatch({ type: AUTHENTICATION_ACTIONS.SET_LICENSE_CHECKED, payload: true });
        this.store.dispatch({ type: AUTHENTICATION_ACTIONS.SET_LICENSED, payload: licensed });

        if (!licensed) {
          this.store.dispatch({
            type: AUTHENTICATION_ACTIONS.SET_ERRORS, payload: {
              message: 'A 4Sight license is required to access this feature of your system.  Please visit customer.control4.com to purchase or renew your 4Sight license.'
            }
          });
        }

        this._busy(false);
      }, err => {
        this._logger.error('auth: error checking 4sight license', err);
        this.store.dispatch({ type: AUTHENTICATION_ACTIONS.SET_LICENSE_CHECKED, payload: true });
        this.store.dispatch({ type: AUTHENTICATION_ACTIONS.SET_LICENSED, payload: false });

        this.store.dispatch({
          type: AUTHENTICATION_ACTIONS.SET_ERRORS, payload: {
            message: 'An error occured while trying to verify your 4Sight license status.  Please check your login credentials, or contact your Control4 dealer for assistance.'
          }
        });

        this._busy(false);
      });
    } else {
      // NOTE: a little white like, but harmless?
      this._logger.debug('auth: no 4sight license required');
      this.store.dispatch({ type: AUTHENTICATION_ACTIONS.SET_LICENSE_CHECKED, payload: true });
      this.store.dispatch({ type: AUTHENTICATION_ACTIONS.SET_LICENSED, payload: true });

      this._busy(false);
    }
  }
}
