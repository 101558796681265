import { Component, OnInit, ViewChild } from '@angular/core';
import { QSNotificationsService, NotificationProgrammingContext } from './../services/notifications.service';
import { LoggerFactory } from '@when-then/core';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { Parameter, CombinedType } from '../../../common/interfaces/parameter.interface';
import { SharedProgrammingService, CommonProgrammingContext } from '../../../common/services/shared-programming.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ProgrammingUtilsService as Utils } from '../../../common/services';
import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';
import { Command } from '../../../common/interfaces/command.interface';

interface ValueLabel {
  value: number;
  label: string;
}

interface PushNotification {
  severity: number;
  category: number;
  addTimestamp: boolean;
  subject: string;
  message: string;
}

@Component({
  selector: 'prg-push-notification',
  templateUrl: './push-notification.component.html',
  styleUrls: ['./push-notification.component.less']
})
export class QSPushNotificationComponent extends BaseRoutingComponent implements OnInit {
  errors = [];
  private _logger = LoggerFactory.getLogger(QSPushNotificationComponent.name);
  pushNotif: PushNotification;

  @ViewChild('pushForm') pushForm;

  _command: Observable<Command>;
  _params: Observable<Parameter[]>;
  _severities: CombinedType[];
  _categories: CombinedType[];
  _ready: Observable<boolean>;
  private _routeParams: Observable<any>;


  constructor(
    protected store: Store<{
      notificationsProgramming: NotificationProgrammingContext,
      sharedProgramming: CommonProgrammingContext
    }>,
    private _route: ActivatedRoute,
    protected router: Router,
    private _notifications: QSNotificationsService,
    private _shared: SharedProgrammingService
  ) {
    super();

    this._ready = this._shared.isReady;
    this._clearPushNotif();
  }

  private _clearPushNotif() {
    this.pushNotif = {
      category: 0,
      severity: 0,
      subject: '',
      message: '',
      addTimestamp: false
    };
  }

  ngOnInit() {
    this._notifications.clear();
    this._notifications.setAgentType('push');
    this.errors = [];
    this._clearPushNotif();

    this._routeParams = <Observable<{ command: string, id: string }>>this._route.params;

    this._command = combineLatest(
      this._routeParams,
      this.store.select(s => s.notificationsProgramming.commands).pipe(filter(c => !!c)),
      (routeParams, commands) => {
        let id = parseInt(routeParams.id);
        return commands.find(c => c.command === routeParams.command && c.id === id);
      }
    );

    this._params = this._command.pipe(map(c => (!!c) ? c.params : []));

    this._command
      .pipe(
        filter(c => !!c)
      )
      .subscribe(c => {
        this._severities = c.params.find(p => p.name === 'SEVERITY').values;
        this._categories = c.params.find(p => p.name === 'CATEGORY').values;
      });
  }

  validateAndSave() {
    this._logger.debug('validate and save push notification', this.pushNotif);
    this.errors = [];

    if (this.pushNotif.category === 0 || this.pushNotif.severity === 0) {
      this.errors.push('Cateogry and Severity are required fields.  Please select a value for each field to continue.');
    }

    if (this.pushNotif.message.length == 0 && this.pushNotif.subject.length == 0) {
      this.errors.push('Either a subject or message is required.  You may also provide both.');
    }

    if (this.errors.length === 0) {
      const command = Utils.snapshot<Command>(this._command);
      const params = Utils.snapshot<Parameter[]>(this._params);
      this._logger.debug('params before update', params);

      params.find(p => p.name === 'SEVERITY').value = this.pushNotif.severity;
      params.find(p => p.name === 'CATEGORY').value = this.pushNotif.category;
      params.find(p => p.name === 'MESSAGE').value = this.pushNotif.message;
      params.find(p => p.name === 'SUBJECT').value = this.pushNotif.subject;
      params.find(p => p.name === 'ADD_TIMESTAMP').value = this.pushNotif.addTimestamp ? "1" : "0";

      this._logger.debug('setting push commmand params', command, params);
      this._notifications.setActionCommand(command, params);
      /**
       * TODO parallel implementation #4
       * @see ../../quickstart-led-colors/select-action/select-action.component.ts
       */
      this.store.select(s => s.sharedProgramming.action.command)
        .pipe(
          filter(c => !!c && c.command === command.command),
          take(1)
        )
        .subscribe(data => {
          this.router.navigate(['conditionals'], { relativeTo: this._route });
        });
    }
  }
}
