import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, flatMap } from 'rxjs/operators';
import { ProgrammingUtilsService } from '../services/utils.service';
import { Logger, LoggerFactory } from '@when-then/core';

@Injectable()
export class RequiresBackupGuard implements CanActivate, CanActivateChild {

  private _logger: Logger;

  constructor(
    private utils: ProgrammingUtilsService,
    private store: Store<any>,
    private router: Router
  ) {
    this._logger = LoggerFactory.getLogger(RequiresBackupGuard.name);
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this._logger.debug('guard: checking backup status');

    let obs = this.store.select(s => !!s.backup && s.backup.checked)
      .pipe(
        filter(c => !!c),
        flatMap(c => this.store.select(s => !s.backup.required))
      );

    obs.subscribe(hasRecentBackup => {
      if (!hasRecentBackup) {
        this._logger.debug('guard: routing to backup');
        let path = state.url.substring(0, state.url.indexOf('programming')) + 'backup';
        this._logger.debug('guard: backup url', path);

        let qsindex = state.url.indexOf('?');
        let redirect = (qsindex >= 0) ? state.url.substring(0, qsindex) : state.url;

        this._logger.debug('guard: redirect url: ', redirect);

        this.router.navigate([path, { origin: redirect }]);
      }
    });

    return obs;
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.canActivate(next, state);
  }
}
