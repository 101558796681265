<!-- Waiting -->
<span
	*ngIf="wait && !_value"
	[class]="'cxWait ' + class"
	[ngClass]="{'cxWait-block':_displayBlock, 'cxWait-full':_displayFull}"
	[style.height]="height">
		<cxSpinner *ngIf="_showSpinner" cxDiameter="1em"></cxSpinner>
		<span *ngIf="_showText" class="cxWait-text">{{cxText}}</span>
		<div class="cxWait-progress" *ngIf="_displayBlock || _displayFull">
			<p *ngFor="let text of cxProgressText">{{text}}</p>
		</div>
</span>

<ng-template [ngIf]="!wait || !!_value"><ng-content></ng-content></ng-template>
