<section>
  <h1 *ngIf="controller">{{controller.model}}</h1>
  <p *ngIf="controller">{{controller.desc}}</p>
  <label *ngIf="!(ready | async)">
    <cxSpinner cxDiameter="20"></cxSpinner>
    <div>Checking the controller state. Please wait a moment for the process to complete.</div>
  </label>
  <form *ngIf="ready | async" (submit)="doLogin()">
    <label *ngIf="errors | async" class="error">
      <span *ngIf="(errors | async)?.message">{{(errors | async)?.message}}</span>
      <span *ngIf="!((errors | async)?.message)">There was a problem with your username or password. Please try again.</span>
      </label>
    <label for="profile" class="help-text">Please select an authentication profile.</label>
    <select required name="profile" id="profile" [(ngModel)]="creds.username">
      <option value=""></option>
      <option value="root">System Admin</option>
      <option
        *ngIf="( director | async ) == 'connected'"
        value="primary">Primary End User</option>
      <option
         *ngIf="( director | async ) == 'connected'"
         value="pro-installer">Pro-Installer</option>
    </select>
    <label for="email" class="help-text" *ngIf="creds.username === 'primary'">Please log in with your Control4 account credentials.</label>
    <input name="email" id="email" type="email" placeholder="Email Address" *ngIf="creds.username === 'primary'" [(ngModel)]="creds.accountEmail">
    <label for="password" class="help-text" *ngIf="creds.username === 'root' || creds.username === 'pro-installer'">Please log in by entering the system password for this profile below.</label>
    <span *ngIf="creds.username">
      <input required type="password" name="password" id="password" placeholder="Password" [ngClass]="{error: (error | async)}" [(ngModel)]="creds.password">
      <span class="icon pw grey medium clickable" (click)="doLogin()"></span>
    </span>
    <button type="submit"></button>
  </form>
  <div [cxWait]="!(busy | async)" cxText="Authenticating..."></div>
  <span class="logo grey"></span>
</section>
<nav *ngIf="inComposer">
  <ul>
    <li><a href="https://www.control4.com/legal/privacy-policy" target="_blank">Privacy Policy</a></li>
    <li><a href="https://www.control4.com/legal/end-user-license-agreement" target="_blank">License</a></li>
    <li><a href="https://www.control4.com/legal/terms-of-use" target="_blank">Terms of Use</a></li>
    <li><a [routerLink]="['/oss']">Open Source Software</a></li>
  </ul>
</nav>
