import { Pipe, PipeTransform } from '@angular/core';
import { DeviceCategoryService } from '../services';

@Pipe({
  name: 'categoryItems'
})
export class CategoryItemsPipe implements PipeTransform {

  constructor(
    private deviceCategories: DeviceCategoryService
  ) { }

  transform(items: any, category: string): Array<any> {
    if (category) {
      return items.filter(item => {
        let devcat = this.deviceCategories.getItemCategory(item);
        return (devcat === category);
      });
    } else {
      return items;
    }
  }
}
