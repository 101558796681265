<prg-nav-header title="Enter values for the parameters of this condition" (cancel)="goToEvent()"></prg-nav-header>
<form>
  <!--
                Need to make this more dyanamic for it to be scalable to other conditionals
                @see https://medium.com/@DenysVuika/dynamic-content-in-angular-2-3c85023d9c36
            -->
  <div class="field-group">
    <label class="leading">{{(conditional|async)?.label}}</label>
    <div [ngSwitch]="(conditional|async)?.name" class="inline-fields">
      <!-- <div *ngSwitchCase="'IF_TIME:COMPARE'" class="inline-fields">
                <prg-select [param]="findParam('COMPARISON')"></prg-select>
                <prg-input type="time" [param]="findParam('TIME')"></prg-input>
            </div> -->
      <div *ngSwitchCase="'IF_TIME:BETWEEN'" class="inline-fields">
        <prg-input type="time" [param]="findParam('OFFSET_FROM')"></prg-input>
        <label for="" class="inline">and</label>
        <prg-input type="time" [param]="findParam('OFFSET_TO')"></prg-input>
      </div>
      <div *ngSwitchCase="'IF_TIME:SUNRISE'" class="inline-fields">
        <label for="" class="inline">is within</label>
        <prg-input type="number" [param]="findParam('OFFSET_MINUTES')"></prg-input>
        <label for="" class="inline">minutes</label>
        <prg-select [param]="findParam('OFFSET_TO')"></prg-select>
      </div>
    </div>
  </div>
  <div class="errors" *ngIf="errors.length > 0">
    <div class="error" *ngFor="let err of errors">{{err}}</div>
  </div>
  <div class="field-group offset">
    <cxWait [cxUntil]="ready|async" cxText="Working...">
      <div class="buttons">
        <button cxNoDoubleClick class="blue" (click)="addAction()" [disabled]="saving">Add Conditional Action</button>
      </div>
    </cxWait>
  </div>
</form>
