<prg-nav-header title="Which {{ CONFIG[commandName | async]?.display }} would you like to select in {{ (room | async)?.name }}?"
  (cancel)="goToEvent()"></prg-nav-header>
<cxScrollable>
  <cxWait [cxUntil]="(devices | async) != undefined" [cxText]="CONFIG[commandName | async]?.waitText">

    <prg-block-button *ngFor="let device of (devices | async)" (click)="setSource(device)" title="{{ device.name }}"
      leadingIcon="watch blue"></prg-block-button>

    <div class="row alert" *ngIf="(devices | async)?.length == 0">
      <header>No Devices Found</header>
    </div>
  </cxWait>
</cxScrollable>
