import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'scheduleEvents'
})
export class ScheduleEventsPipe implements PipeTransform {

  transform(events: Array<any>, args?: any): any {
    if (events) {
      // console.log('searching for schedule events', events);

      let  schedevts = events.filter(evt => {
        return (evt.deviceId === 100100);
      });
      console.log('scheduleEvents: events, schedevts', events, schedevts);

      return schedevts;
    }

    return events;
  }

}
