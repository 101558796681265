import { EventType } from './event-type.interface';

export const ROOM_AUDIO: EventType = {
  name: 'Room Audio Changed',
  description: 'When the audio selection playing in a room has changed',
  route: 'rooms/2',
  category: 'Room Media',
  icon: 'song',
  whitelist: [
    {
      fields: { proxy: 'roomdevice' },
      events: [2]
    }
  ]
}
