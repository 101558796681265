import { Injectable } from '@angular/core';
import { switchMap, catchError } from 'rxjs/internal/operators';

import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageService } from '../services/storage.service';

const STORAGE_KEY: string = 'authToken';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private storage: StorageService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    
    /**
     * Grabs the token and injects it in the header
     * @param token 
     */
    const setHeader = (token: string) => next.handle(req.clone({
      headers: req.headers.set('Authorization', `Bearer ${token}`)
    }))

    return this.storage.getAsync(STORAGE_KEY)
    .pipe(
      switchMap(setHeader),
      catchError(() => next.handle(req))
    );
  }
}

export const httpAuthInterceptors = [{
  provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true
}];
