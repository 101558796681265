import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { UIModule } from '@when-then/ui';
import { QuickstartCommonModule } from './../quickstart-common/quickstart-common.module';
import { ProgrammingCommonModule } from '../../common/common.module';

import { SelectLightComponent } from './select-light/select-light.component';
import { SelectActionComponent } from './select-action/select-action.component';
import { SelectParametersComponent } from './select-parameters/select-parameters.component';
import { SelectButtonComponent } from './select-button/select-button.component';
import { LightTriggerComponent } from './light-trigger/light-trigger.component';
import { LightActionComponent } from './light-action/light-action.component';
import { LightsService } from './services/lights.service';
import { SelectLightInteractionComponent } from './select-light-interaction/select-light-interaction.component';
import { LightSceneListComponent } from './light-scene-list/light-scene-list.component';
import { LightActionTypeComponent } from './light-action-type/light-action-type.component';
import { ScenesInRoomPipe } from './pipes/scenes-in-room.pipe';
import { LightSceneEventsComponent } from './light-scene-events/light-scene-events.component';
import { LightSceneEditComponent } from './light-scene-edit/light-scene-edit.component';
import { LightSceneCommandComponent } from './light-scene-command/light-scene-command.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    UIModule,
    ProgrammingCommonModule,
    QuickstartCommonModule,
  ],
  providers: [
    LightsService,
    LightsService
  ],
  declarations: [
    SelectLightComponent,
    SelectActionComponent,
    SelectButtonComponent,
    SelectLightInteractionComponent,
    SelectParametersComponent,
    LightTriggerComponent,
    LightActionComponent,
    LightSceneListComponent,
    LightActionTypeComponent,
    ScenesInRoomPipe,
    LightSceneEditComponent,
    LightSceneEventsComponent,
    LightSceneCommandComponent,
  ]
})
export class QuickstartLightsModule { }
