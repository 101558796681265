import { SetSystemRemotes } from './quickstart-remotes.actions';
import { SUPPORTED_SYSTEM_REMOTE_PROXIES } from './../quickstart-system-remotes/quickstart-remotes.service';
import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';

import { SET_ITEMS } from '../../common/services';

@Injectable()
export class QSRemotesEffects {

  constructor(
    private actions$: Actions
  ) { }

  @Effect() systemRemotes$ = this.actions$
    .pipe(
      ofType(SET_ITEMS),
      map((action: { type, payload }) => action.payload.itemsList),
      map(items => items.filter(itm => SUPPORTED_SYSTEM_REMOTE_PROXIES.indexOf(itm.proxy) >= 0)),
      map(remotes => new SetSystemRemotes(remotes))
    );

}
