import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { LoggerFactory, LogLevel } from '@when-then/core';

import { SharedProgrammingService } from './../../common/services/shared-programming.service';
import { Device } from '../../common/interfaces/item.interface';
import { ItemsService } from './../../common/services/items.service';
import { QuickstartProgrammingService } from '../../quickstarts/services/quickstart-programming.service';
import { ProgrammingState } from '../../programming.stores';

import { EventTypes } from '../quickstart-simple/event-types/event-types';
import { EventType } from '../quickstart-simple/event-types/event-type.interface';
import { QSRoomsService } from '../quickstart-rooms/quickstart-rooms.service';
import { QSMediaService } from '../quickstart-media/quickstart-media.service';


export interface Category {
  name: string;
  order: number;
  availableTypes: EventType[];
  installedTypes: EventType[];
}

@Injectable()
export class SelectEventService {
  private _logger = LoggerFactory.getLogger(SelectEventService.name);
  installedCategories: Observable<Category[]>;

  ready: Observable<boolean>;
  searchText: Observable<string>;

  private supportedCategories: Category[] = EventTypes
    .map(et => et.category)
    .reduce((acc, curr) => {
      return (acc.indexOf(curr) == -1) ? acc.concat(curr) : acc;
    }, [])
    .map(cat => {
      return {
        name: cat,
        order: [...EventTypes.filter(et => et.category == cat)][0].order || 999, /// ewww!
        availableTypes: [...EventTypes.filter(et => et.category == cat)],
        installedTypes: []
      }
    });

  constructor(
    private store: Store<ProgrammingState>,
    private items: ItemsService,
    private shared: SharedProgrammingService,
    private _qsService: QuickstartProgrammingService,
    // NOTE these satisfy hidden dependencies, do not remove them!
    private _media: QSMediaService,
    private _roomsService: QSRoomsService,
  ) {
    this._logger.setLevel(LogLevel.DEBUG);

    this.installedCategories = combineLatest(
      // this._installedDevices,

      this.store.select(s => s.programmingItems.itemsList)
        .pipe(
          filter(il => !!il),
          map((items: Device[]) => items.filter(dev => {
            return EventTypes.some(et => this._deviceSupportsType(dev, et) && this._isSupportedProxyVersion(dev.version, et));
          }))
        ),

      // We need to wait until the store has something
      // for each of these to assure we can correctly
      // display the list of events
      this.store.select(s => s.mediaProgramming.listen_devices)
        .pipe(filter(d => !!d)),
      this.store.select(s => s.sharedProgramming.osVersion)
        .pipe(filter(v => !!v))
    )
      .pipe(
        map(([devices, listen, osVersion]) => {
          // this._logger.debug('mapping event types to devices', devices, listen);

          return this.supportedCategories
            .filter(cat => {

              // Preemtively filter Room Media category
              // if we don't have any listen devices
              if (cat.name == 'Room Media') {
                listen = listen || [];
                const listenDevs = listen.reduce((acc: { id: number }[], curr) => {
                  if (Array.isArray(curr.listen_devices)) {

                    curr.listen_devices.forEach(device => {
                      if (acc.findIndex(d => d.id == curr.id) == -1) {
                        acc.push(device);
                      }
                    });
                  }

                  return acc;
                }, []);

                if (!listenDevs || listenDevs.length == 0) {
                  return false;
                }
              }

              return cat.availableTypes.some(type => this._isSupportedType(devices, type));
            })
            .map(cat => {
              cat.installedTypes = cat.availableTypes.filter(type => {
                return type.whitelist.some(wl => this._isSupportedType(devices, type))
                  && (!type.OSMinVersion || this._qsService.matchesRequiredMinOSVersion(type));
                // HACK ^^^ this is why osversion is in the combined observable above, ugh
              });

              return cat;
            });
        })
        );

    this.ready = combineLatest(
      this.shared.isReady,
      this.items.ready,
      this.installedCategories,
      (a, b, c) => (a && b && !!c)
    );

    this.searchText = this.shared.searchText;
  }

  ngOnInit() {

  }

  searchTextChanged(event: any) {
    this.shared.setSearchText(event.target.value);
  }

  private _isSupportedProxyVersion(installedVersion: number, type: EventType): boolean {
    return type.whitelist.every(criteria => {
      return !criteria.fields['proxy']
        || !criteria.fields['minVersion']
        || installedVersion >= criteria.fields['minVersion']
    })
  }

  private _isSupportedType(devices: Device[], type: EventType): boolean {
    return devices.some(dev => this._deviceSupportsType(dev, type));
  }

  private _deviceSupportsType(device: Device, type: EventType): boolean {
    return type.whitelist.some(wl => Object.keys(wl.fields).every(key => device.hasOwnProperty(key) && device[key] === wl.fields[key]));
  }
}
