import { Store } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';

@Component({
  selector: 'prg-timers-action-type',
  templateUrl: './timers-action-type.component.html',
  styleUrls: ['./timers-action-type.component.less']
})
export class TimersActionTypeComponent extends BaseRoutingComponent implements OnInit {

  constructor(
    protected router: Router,
    protected store: Store<any>,
  ) {
    super();
  }

  ngOnInit() {
  }
}

