import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

const INITIAL_STATE: Array<any> = [];

const STORE_NAME: string = 'STATUS';
const ADD_MESSAGE: string = STORE_NAME + ':ADD_MESSAGE';

export function statusReducer(state: Array<any> = INITIAL_STATE, {type, payload}) {
  switch (type) {
    case ADD_MESSAGE:
      let rtn = new Array<string>().concat(state);
      rtn.unshift(payload);
      if (rtn.length > 10) {
        rtn = rtn.slice(0, 9);
      }
      return rtn;
    default:
      return state;
  }
};


@Injectable()
export class StatusService {

  messages: Observable<Array<string>>;

  constructor(private store: Store<any>) {
    this.messages = <Observable<Array<string>>>this.store.select('statusMessages');
  }


  addMessage(message: string) {
    this.store.dispatch({ type: ADD_MESSAGE, payload: message });
  }

}
