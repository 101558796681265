/**
 * Copyright 2017 Control4 Corporation. All rights reserved.
 * Control4 Confidential and Proprietary Information.
 */
import { ActionReducerMap } from '@ngrx/store';

import { QUICKSTART_SCHEDULE_REDUCERS, QuickstartScheduleState } from './quickstart-schedule/schedule-quickstart.store';
import { QUICKSTART_NOTIFICATIONS_REDUCERS, QuickstartNotificationsState } from './quickstart-notifications/notifications.store';
import { COMMON_PROGRAMMING_REDUCERS, CommonProgrammingState } from '../common/common.stores';
import { quickstartsReducer, QuickstartsContext } from './services/quickstart-programming.service';
import { QUICKSTART_LED_COLORS_REDUCERS } from './quickstart-led-colors/led-colors.stores';
import { QUICKSTART_TIMERS_REDUCERS } from './quickstart-timers/timers.stores';
import { QUICKSTART_LIGHTS_REDUCERS, QuickstartLightsState } from './quickstart-lights/quickstart-lights.stores';
import { QUICKSTART_KEYPADS_REDUCERS, QuickstartKeypadsState } from './quickstart-keypads/quickstart-keypads.stores';
import { QUICKSTART_CUSTOM_BUTTONS_REDUCERS, QuickstartCustomButtonsState } from './quickstart-custom-buttons/quickstart-custom-buttons.stores';
import { QUICKSTART_REMOTES_REDUCERS, QuickstartRemotesState } from './quickstart-system-remotes/quickstart-remotes.stores';
import { QUICKSTART_SIMPLE_REDUCERS, QuickstartSimpleState } from './quickstart-simple/quickstart-simple.store';
import { QUICKSTART_ROOMS_REDUCERS, QuickstartRoomsState } from './quickstart-rooms/quickstart-rooms.store';
import { QUICKSTART_MEDIA_REDUCERS, QuickstartMediaState } from './quickstart-media/quickstart-media.store';
import { QUICKSTART_THERMOSTAT_REDUCERS, QuickstartThermostatState } from './quickstart-thermostats/quickstart-thermostats.store';
import { eventsUIReducer, EventsUIContext } from './events/eventsUI.service';

export interface QuickstartProgrammingState extends
  QuickstartScheduleState,
  QuickstartLightsState,
  QuickstartKeypadsState,
  QuickstartCustomButtonsState,
  QuickstartNotificationsState,
  QuickstartRemotesState,
  QuickstartSimpleState,
  QuickstartRoomsState,
  QuickstartMediaState,
  QuickstartThermostatState,
  CommonProgrammingState {
  quickstartProgramming: QuickstartsContext,
  eventsUI: EventsUIContext
}

export const QUICKSTART_PROGRAMMING_REDUCERS: ActionReducerMap<QuickstartProgrammingState> = {
  ...QUICKSTART_SCHEDULE_REDUCERS,
  ...QUICKSTART_LIGHTS_REDUCERS,
  ...QUICKSTART_LED_COLORS_REDUCERS,
  ...QUICKSTART_TIMERS_REDUCERS,  
  ...QUICKSTART_KEYPADS_REDUCERS,
  ...QUICKSTART_CUSTOM_BUTTONS_REDUCERS,
  ...QUICKSTART_NOTIFICATIONS_REDUCERS,
  ...QUICKSTART_REMOTES_REDUCERS,
  ...QUICKSTART_SIMPLE_REDUCERS,
  ...QUICKSTART_ROOMS_REDUCERS,
  ...QUICKSTART_MEDIA_REDUCERS,
  ...QUICKSTART_THERMOSTAT_REDUCERS,
  ...COMMON_PROGRAMMING_REDUCERS,
  quickstartProgramming: quickstartsReducer,
  eventsUI: eventsUIReducer
};
