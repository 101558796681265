<div>
  <prg-nav-header title="Create or Select Voice Scene" (cancel)="goHome()">
    <div right>
      <span class="icon search grey small"></span>
      <input type="search" name="searchText" [value]="searchText | async" (input)="searchText.next($event.target.value)"
        placeholder="Search">
    </div>
  </prg-nav-header>
  <cxWait [cxUntil]="initialized" cxText="Loading Voice Scene information...">
    <prg-block-button buttonClass="blue" [routerLink]="['../new']" leadingIcon="white plus" title="Create a new Voice Scene"
      trailingIcon="right"></prg-block-button>
    <cxScrollable>
      <prg-block-button buttonClass="dark" *ngFor="let scene of filteredScenes | async" (click)="addActions(scene)"
        leadingIcon="blue voice-scene" title="When I say '{{ scene }}'" trailingIcon="right"></prg-block-button>
    </cxScrollable>
    <div class="alert" *ngIf="(searchText | async) && (filteredScenes | async)?.length == 0">
      <header>
        <span class="icon small grey information"></span>
        <h5 class="grow">No Matching Voice Scenes</h5>
      </header>
      <p>No voice scene automation was found that matches your search terms. Please try a different search.</p>
    </div>
    <div class="alert" *ngIf="!(searchText | async) && (filteredScenes | async)?.length == 0">
      <header>
        <span class="icon small grey information"></span>
        <h5 class="grow grey">No Voice Scenes Defined</h5>
      </header>
      <p>No voice scenes have been defined for your system yet. Try creating some by clicking the button above.</p>
    </div>
  </cxWait>
</div>
