import { Observable } from 'rxjs';

import { StreamingService } from './interfaces/streaming.interface';
import { Playlist } from './interfaces/playlist.interface';

export const Pandora = {

  protocolFilename: 'pandora.c4z',

  Stations: {
    collectionEndpoint: '/api/v1/items/[protocolId]/custom_select_data?command=GetStationsForProgramming',
    selectionEndpoint: '/api/v1/items/[protocolId]/commands',
    selectionCommand: 'PlayStation'
  }
}
