import { AgentMetaService } from './../services/agent-meta.service';
import { Pipe, PipeTransform } from '@angular/core';
import { DeviceCategoryService } from '../services/device-category.service';
import { Store } from '@ngrx/store';
import { ProgrammingUtilsService as Utils } from '../services/utils.service';

@Pipe({
  name: 'composedTitle'
})
export class ComposedTitlePipe implements PipeTransform {

  constructor(
    private store: Store<any>,
    private agents: AgentMetaService,
    private categorySvc: DeviceCategoryService
  ) { }

  transform(value: any, args?: any): any {
    // console.log('composing bricklet title for ', value);
    return this.getEventLabel(value) + ' ' + this.getActionLabel(value);
  }

  private getEventLabel(event: any): string {
    // console.log('getting event label for ', event);
    let items = Utils.getValue(this.store, s => s.programmingItems.itemsMap);

    if (event.buttonAction) {
      return event.buttonAction;
    } else {
      let item = items[event.deviceId];
      // console.log('composed-display: trigger device is ', item);
      if (item) {
        return item.name;
      } else {
        if (event.deviceId === 100100) {
          return event.display;
        } else {
          let name = this.agents.getNameForId(event.deviceId);
          if (name) {
            return name;
          }
        }
      }
    }

    return '';
  }

  private getItemName(item: any): string {
    let category = this.categorySvc.getItemCategory(item);
    return item.name || category;
  }

  private getAgentName(event: any): string {
    return this.agents.getNameForId(event.deviceId);
  }

  private getActionLabel(event: any): string {
    let items = Utils.getValue(this.store, s => s.programmingItems.itemsMap);

    // console.log('composed-display: getting action label for event', event);
    let title = '';
    if (!!event && !!event.codeItems) {
      let ci = event.codeItems[0];
      // console.log('composed-display: first codeitem is', ci);
      let item2 = items[ci.deviceId];
      // console.log('composed-display: device for first codeitem is ', item2);
      if (item2) {
        let cat2 = this.categorySvc.getItemCategory(item2);
        // console.log('composed-display: category for device for first codeitem is ', cat2);
        title += (' ' + (item2.name || cat2));
      }
    }
    // console.log('composed-display: action label is ', title);
    return title;
  }
}
