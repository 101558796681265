import { Injectable } from '@angular/core';

import { BaseService } from './base.service';

import { BrokerService } from './broker.service';

@Injectable()
export class RegistrationService extends BaseService {

  constructor(private broker: BrokerService) {
    super();
  }

  register(regCode: string): Promise<any> {
    var body = {
      regCode: regCode
    };

    return this.broker.call({
      method: 'POST',
      path: '/api/v1/registration',
      data: body
    });
  }

  unregister(): Promise<any> {
    return this.broker.call({
      method: 'DELETE',
      path: '/api/v1/registration'
    });
  }

  getRegistration(): Promise<any> {
    return this.broker.call({
      path: '/api/v1/registration'
    });
  }

}
