<prg-nav-header title="Create or Select Schedule" (cancel)="goHome()">
  <div right>
    <div class="search">
      <span class="icon blue search small"></span>
      <input type="search" name="searchText" [(ngModel)]="_searchText" placeholder="Search">
    </div>
  </div>
</prg-nav-header>
<cxWait [cxUntil]="initialized | async" cxText="Loading schedule information...">
  <prg-block-button buttonClass="blue" leadingIcon="white plus" trailingIcon="small white right" title="Create New Schedule"
    [routerLink]="['../0']"></prg-block-button>

  <cxScrollable>
    <prg-block-button buttonClass="dark" *ngFor="let schedule of (_scheduleEvents | async | matchingSearchText:_searchText)"
      (click)="openSchedule(schedule)" [leadingIcon]="schedule | scheduleIcon" trailingIcon="grey small right" title="{{schedule.display}}"
      subtitle="{{schedule | scheduleEventLabel}}"></prg-block-button>
  </cxScrollable>
  <div class="alert" *ngIf="_searchText && (_scheduleEvents | async | matchingSearchText:_searchText)?.length===0">
    <header style="border-bottom: none">
      <span class="icon small grey information"></span>
      <h5 class="grow">No Matching Scheduled Automation</h5>
    </header>
    <p>No scheduled automation was found that matches your search terms. Please try a different search.</p>
  </div>
  <div class="alert" *ngIf="!_searchText && (_scheduleEvents | async)?.length===0">
    <header style="border-bottom: none">
      <span class="icon small grey information"></span>
      <h5 class="grow grey">No Scheduled Automation</h5>
    </header>
    <p>No scheduled automation has been defined for your system yet. Try creating some by clicking the button above.</p>
  </div>
</cxWait>
