import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, combineLatest } from 'rxjs';
import { filter, map, switchMap, flatMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { BrokerService } from '@when-then/core';
import { CodeItemType } from './../../../common/interfaces/item.interface';
import { ProgrammingUtilsService as Utils } from './../../../common/services/utils.service';

import { Device } from '../../../common/interfaces/item.interface';
import { Parameter } from '../../../common/interfaces/parameter.interface';
import { C4ValueType } from '../../../common/params/param-input.component';
import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';
import { SharedProgrammingService, CommonProgrammingContext, CommandData } from '../../../common/services/shared-programming.service';

import { ThermostatProgrammingContext } from '../quickstart-thermostats.service';
import { Command } from '../../../common/interfaces/command.interface';

@Component({
  templateUrl: './select-params.component.html',
  styleUrls: ['./select-params.component.less']
})
export class QSThermostatSelectParamsComponent extends BaseRoutingComponent {

  _thermostat: Observable<Device>;
  _command: Command;
  _parameters: Parameter[];

  constructor(
    public router: Router,
    private _route: ActivatedRoute,
    protected store: Store<{
      thermostatProgramming: ThermostatProgrammingContext,
      sharedProgramming: CommonProgrammingContext
    }>,
    private _broker: BrokerService,
    private _shared: SharedProgrammingService
  ) {
    super();

    this._route.params
      .pipe(
        flatMap((params: { id: string, command: string }) => this._broker.call({
          path: `/api/v1/items/commands`,
          queryString: {
            query: {
              command: params.command,
              deviceId: parseInt(params.id)
            }
          }
        })),
        map((commands: Command[]) => commands[0])
      )
      .subscribe(command => {
        this._command = command;
        this._parameters = command.params;
      });

  }

  _configured = (): boolean => {
    return (this._parameters || []).every(param => {
      return param.value != undefined || param._value != undefined;
    });
  }

  saveCommand = () => {

    if (!this._configured()) {
      return;
    }

    let params = this._parameters
      .filter(p => !!p)
      .map(p => {
        p._value = p._value || p.value
        return p;
      });

    const data = {
      ...Utils.buildCommandData(CodeItemType.Command, this._command),
      ...{
        params: params.map(p => {
          return {
            name: p.name,
            value: p._value
          }
        })
      }
    };

    this._shared.setPendingAction(this._command, data);
    this.router.navigate(['conditionals'], { relativeTo: this._route });
  }

  update = (param: Parameter, value: C4ValueType) => {
    let ix = this._parameters.findIndex(p => param.name == p.name && param.type == p.type);
    this._parameters[ix] = Object.assign({}, this._parameters[ix], {
      _display: value.display,
      _value: value.value
    });

    this._parameters = [...this._parameters];
  }

  identify = (param: Parameter) => param.name + param.type;
}
