import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { QuickstartRemotesService } from '../quickstart-remotes.service';
import { Event } from './../../../common/interfaces/event.interface';
import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';
import { LoggerFactory, Location } from '@when-then/core';

@Component({
  selector: 'prg-select-remote-button',
  templateUrl: './select-remote-button.component.html',
  styleUrls: ['./select-remote-button.component.less']
})
export class SelectRemoteButtonComponent extends BaseRoutingComponent implements OnInit {

  private _logger = LoggerFactory.getLogger(SelectRemoteButtonComponent.name);
  events: Observable<Event[]>;
  room: Observable<Location>;

  constructor(
    protected router: Router,
    protected store: Store<any>,
    private remotes: QuickstartRemotesService
  ) {
    super();

    this.events = this.remotes.roomEvents;
    this.room = this.remotes.selectedRoom;
  }

  ngOnInit() {
  }

  buttonSelected(event: Event) {
    this._logger.debug('selected button event', event);
    this.remotes.getSelectedEvent(event).then(evt => {
      // HACK! component should own navigation because of moduleRoot, but the "event" we've
      // been given is nothing but a name and id, we have to lookup the event details and
      // set the real event on shared context then subscribe to that before we can safely navigate.
      console.log('sel-rem-btn: event is now ', evt);
      if (evt && evt.eventId === event.eventId) {
        this.router.navigate(['when-then','device', evt.deviceId, 'event', evt.eventId, 'add'], { relativeTo: this.moduleRoot });
      }
    });
  }
}
