import { CommandType } from './command-type.interface';

export const BLINDS: CommandType = {
  name: 'Blinds',
  description: 'Open or Close Blinds or Shades',
  route: 'blinds',
  category: 'Security & Access',
  order: 1,
  whitelist: [
    {
      proxy: 'blind',
      commands: [
        { id: 0, command: 'SET_LEVEL_TARGET:LEVEL_TARGET_OPEN', icon: 'blinds-open' },
        { id: 0, command: 'SET_LEVEL_TARGET:LEVEL_TARGET_CLOSED', icon: 'blinds' },
        { id: 0, command: 'SET_LEVEL_TARGET', icon: 'blinds' },
        { id: 0, command: 'TOGGLE', icon: 'blinds' },
      ]
    }
  ]
}
