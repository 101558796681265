import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';

import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { take, map, shareReplay } from 'rxjs/operators';

import { QuickstartProgrammingState } from '../../quickstarts.stores';
import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';

import { VoiceScenesService, VoiceScene } from '../voice-scenes.service';

@Component({
  selector: 'atm-vs-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.less']
})
export class VSCreateComponent extends BaseRoutingComponent {

  public sceneName = new FormControl();
  public onOff = new FormControl("on");
  private _scenes: Observable<VoiceScene[]>;
  public sceneExists: Observable<boolean>;
  public working: boolean = false;

  constructor(
    protected router: Router,
    protected store: Store<QuickstartProgrammingState>,
    private _sceneService: VoiceScenesService
  ) {
    super();

    this._scenes = this._sceneService.getScenesObservable();

    this.sceneExists = combineLatest(
      this.sceneName.valueChanges,
      this._scenes
    )
      .pipe(
        map(([sceneName, scenes]) => scenes
          .map(s => s.$t)
          .indexOf(sceneName.replace(',', '')) > -1),
        shareReplay(1)
      );
  }

  public async addActions() {

    const onOff = this.onOff.value;
    const sceneName = this.sceneName.value.replace(',', '');

    this.working = true;

    const exists = await this.sceneExists.pipe(take(1)).toPromise();

    if (!exists) {
      await this._sceneService.createVoiceScene(sceneName);
    }

    const eventId = await this._sceneService.getEventId(sceneName, onOff);
    const deviceId = await this._sceneService.getDriverId();

    this.working = false;

    this.router.navigate(['when-then', 'device', deviceId, 'event', eventId, 'add'], {
      relativeTo: this.moduleRoot
    });
  }
}
