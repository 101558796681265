import { EVENTS_UI_ACTIONS, EventsUIContext, EventsUIService } from './../../events/eventsUI.service';
import { CreatorPipe } from './../../../common/pipes/creator.pipe';
import { MatchingSearchTextPipe } from './../../../common/pipes/matching-search-text.pipe';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Component, OnInit, Input } from '@angular/core';
import { Observable, of } from 'rxjs';

import { SharedProgrammingService, CommonProgrammingContext } from './../../../common/services/shared-programming.service';
import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';
import { EVENTS_FILTER } from '../../events/eventsUI.service';
import { ScheduleEvent, Schedules } from '../+state/schedule.interfaces';
import { switchMap, filter, map } from 'rxjs/operators';

@Component({
  selector: 'prg-list-by-date',
  templateUrl: './list-by-date.component.html',
  styleUrls: ['./list-by-date.component.less']
})
export class ListByDateComponent extends BaseRoutingComponent implements OnInit {

  ready: Observable<boolean>;
  initialized: Observable<boolean>;
  searchText: Observable<string>;
  expanded = false;

  @Input() source: ScheduleEvent[];

  constructor(
    protected router: Router,
    protected store: Store<{ sharedProgramming: CommonProgrammingContext, scheduleProgramming: Schedules }>,
    private shared: SharedProgrammingService,
    private eventsUI: EventsUIService
  ) {
    super();
    this.initialized = this.store.select(s => s.scheduleProgramming.ready);

    this.ready = this.shared.isReady;
    this.searchText = this.shared.searchText;
  }

  ngOnInit() {
    this.shared.clearError();
    this.shared.setGrouping('scheduled');
  }

  ngOnChanges() {
    if (!!this.source) {
      this.eventsUI.setEventCount(this.source.length);
    }
  }

  tileClicked(item: any) {
    console.log('tile clicked: ', item);
    if (item) {
      this.router.navigate(['when-then/device', item.deviceId, 'event', item.eventId, 'edit'], { relativeTo: this.moduleRoot });
    }
  }
}
