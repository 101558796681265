import { StorageService } from './storage.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Subscription, combineLatest } from 'rxjs';
import { filter, take, map } from 'rxjs/operators';

import { BrokerService } from './broker.service';
import { MonitoredHttpState, JWT_STATUS } from './monitored-http.service';
import * as moment from 'moment';
import { Logger, LoggerFactory, LogLevel } from './log.service';

const BACKUP_SOURCE: string = 'ConfigWeb';
const BACKUP_PERSISTENCE: string = 'rolling';

const PROFILE_NAME_PRIMARY_END_USER: string = 'primary';
const STORAGE_KEY_PROFILE: string = 'profile';
const STORAGE_KEY_IS_REMOTE: string = 'isRemote';

export interface BackupEntry {
  id: string;
  name: string;
  time: string;
  size: number;
  url: string;
  build_number: string;
  backup_options: {
    [key: string]: boolean
  }
}

export interface BackupStatus {
  current_operation?: {
    name: string;
    progress: number;
    start_time: number;
  };
  license?: string;
  next_scheduled_backup?: number;
  previous_backup?: {
    end_time: number;
    message: string;
    success: boolean;
  };
  previous_operation?: {
    end_time: number;
    message: string;
    name: string;
    success: boolean;
  };
  error?: any;
}

export interface BackupContext {
  ready: boolean;
  required: boolean;
  checked: boolean;
  complete: boolean;
  running: boolean;
  error: any;
  status?: BackupStatus;
  redirectURL: string;
  list?: Array<BackupEntry>;
}

const INITIAL_STATE: BackupContext = {
  ready: true,
  required: false,
  checked: false,
  complete: false,
  running: false,
  redirectURL: '',
  error: undefined,
  status: {}
};

const STORE_NAME: string = 'C4:BACKUPS:';
const SET_BACKUP_REQUIRED: string = STORE_NAME + 'SET_BACKUP_REQUIRED';
const SET_BACKUP_STATUS: string = STORE_NAME + 'SET_BACKUP_STATUS';
const SET_BACKUP_COMPLETE: string = STORE_NAME + 'SET_BACKUP_COMPLETE';
const SET_REDIRECT_URL: string = STORE_NAME + 'SET_REDIRECT_URL';
const SET_READY: string = STORE_NAME + 'SET_READY';
const SET_RUNNING: string = STORE_NAME + 'SET_RUNNING';
const SET_CHECKED: string = STORE_NAME + 'SET_CHECKED';
const SET_ERROR: string = STORE_NAME + 'SET_ERROR';
const SET_BACKUP_CHECK_RESULT: string = STORE_NAME + 'SET_BACKUP_CHECK_RESULT';

export function backupReducer(state: BackupContext = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case SET_BACKUP_REQUIRED: return Object.assign({}, state, { required: payload });
    case SET_BACKUP_STATUS: return Object.assign({}, state, { status: payload });
    case SET_BACKUP_COMPLETE: return Object.assign({}, state, { complete: payload });
    case SET_REDIRECT_URL: return Object.assign({}, state, { redirectURL: payload });
    case SET_READY: return Object.assign({}, state, { ready: payload });
    case SET_RUNNING: return Object.assign({}, state, { running: payload });
    case SET_CHECKED: return Object.assign({}, state, { checked: payload });
    case SET_ERROR: return Object.assign({}, state, { error: payload });
    case SET_BACKUP_CHECK_RESULT: return Object.assign({}, state, { checked: payload.checked, required: payload.required });

    default: return state;
  }
}

@Injectable()
export class BackupService {
  private _logger: Logger;

  required: Observable<boolean>;
  checked: Observable<boolean>;
  running: Observable<boolean>;
  previousOperation: Observable<any>;
  currentOperation: Observable<any>;
  error: Observable<any>;

  progress: BackupProgressHandler;
  private _backupSubscriber: Subscription;

  constructor(
    private store: Store<{
      backup: BackupContext,
      authentication: {
        licenseChecked: boolean
      },
      monitoredHttpState: MonitoredHttpState
    }>,
    private broker: BrokerService,
    private storage: StorageService,
    private router: Router
  ) {
    this._logger = LoggerFactory.getLogger(BackupService.name);
    // this._logger.setLevel(LogLevel.DEBUG);

    this.required = this.store.select(s => s.backup.required);
    this.checked = this.store.select(s => s.backup.checked);
    this.running = this.store.select(s => s.backup.running);
    this.previousOperation = this.store.select(s => s.backup.status.previous_operation);
    this.currentOperation = this.store.select(s => s.backup.status.current_operation);
    this.error = this.store.select(s => s.backup.error);

    this.progress = new BackupIdleProgressHandler();

    combineLatest(
      this.store.select(s => s.monitoredHttpState)
        .pipe(
          filter(s => !!s),
          map(s => s.jwtStatus),
          filter(s => s == JWT_STATUS.VALID)
        ),
      this.broker.connected,
      (valid, connected) => valid && connected
    )
      .pipe(
        filter(s => s),
        take(1)
      )
      .subscribe(s => this.checkForRecentBackup());
  }

  checkForRecentBackup() {
    let profile = this.storage.get(STORAGE_KEY_PROFILE);
    let remote = this.storage.get(STORAGE_KEY_IS_REMOTE);
    this._logger.debug('checking for recent backup, profile and remote', profile, remote);
    // NOTE backups are NOT required for system admin or pro-staller
    // NOTE remote access is always by the consumer, which requires backup
    if (profile !== PROFILE_NAME_PRIMARY_END_USER && !remote) {
      this.store.dispatch({ type: SET_BACKUP_CHECK_RESULT, payload: { required: false, checked: true } });
      this._ready(true);
    } else {
      this.broker.call({ path: '/api/v1/agents/backup' })
        .then(res => {
          this._logger.info('backup: backup status response: ', res);
          let backupRequired = true;

          if (!!res.previous_backup && res.previous_backup.success) {
            let dt = new Date((parseInt(res.previous_backup.end_time) * 1000));
            this._logger.debug('date of last backup: ', moment(dt).format('YYYY-MM-DD'));
            backupRequired = !(this._isLessThanOneWeekAgo(dt));
          }

          this._logger.info('setting backup required: ', backupRequired);
          this.store.dispatch({ type: SET_BACKUP_CHECK_RESULT, payload: { required: backupRequired, checked: true } });
          this._ready(true);
        }, err => {
          this._logger.error('backup: error checking backup agent status', err);
          this._ready(true);
          this._error(err);
        });
    }
  }

  hasRecentBackup(): boolean {
    let hasRecent = false;
    this.store.select(s => s.backup.required).pipe(take(1)).subscribe(required => {
      hasRecent = !required;
    });
    return hasRecent;
  }

  private _error(err: any) {
    this.store.dispatch({ type: SET_ERROR, payload: err });
  }

  private _isRecentBackup(backup: any) {
    let date = new Date(Date.parse(backup.time));
    return this._isLessThanOneWeekAgo(date);
  }

  setBackup(dayOfWeek: string) {
    this._ready(false);
    this._error(undefined);
    // NOTE reset progress in case this is a second attempt
    this.progress = new BackupIdleProgressHandler();
    let data = {
      // backups_enabled: true,
      backup_schedule: {
        days: dayOfWeek || '',
        hour: 0,
        minute: 0,
        second: 0
      },
      backup_options: {
        INCLUDE_PROJECT_DATA: true,
        INCLUDE_PERSONAL_INFO: true,
        // INCLUDE_DRIVERS: true,
        // INCLUDE_MEDIA: true,
        // INCLUDE_COVER_ART: true,
        // INCLUDE_SCREEN_SAVER: true,
        // INCLUDE_ANNOUNCEMENTS: true,
        // INCLUDE_WALLPAPER: true,
        // INCLUDE_ZWAVE: true,
        source: BACKUP_SOURCE,
        persistence: BACKUP_PERSISTENCE
      },
      backup_now: true
    };

    this.broker.call({
      path: 'api/v1/agents/backup/setup',
      method: 'POST',
      data: data
    }).then(res => {
      this._backupSubscriber = this.broker.getObservable<BackupStatus>({
        path: '/api/v1/agents/backup'
      }).subscribe(bs => {
        this.progress = this.progress.handleStatus(bs);
        this._logger.debug('current message is', this.progress.message());
        if (this.progress.isComplete()) {
          this._logger.debug('backup is complete');
          if (this.progress.isSuccess()) {
            this._logger.debug('backup is successful');
            this.store.dispatch({ type: SET_BACKUP_REQUIRED, payload: false });
          } else {
            this._logger.debug('backup is unsuccessful');
            const error = this.progress.error();
            if (!!error) {
              this._error(error);
            }
          }

          this.store.dispatch({ type: SET_BACKUP_COMPLETE, payload: true });
          this.store.dispatch({ type: SET_READY, payload: true });
        }
      });
    }, err => {
      this._error(err);
      this._ready(true);
    });
  }

  setRedirectURL(url: string) {
    this.store.dispatch({ type: SET_REDIRECT_URL, payload: url });
  }

  private _isLessThanOneWeekAgo(then: Date): boolean {
    let m = moment(then);
    this._logger.info('backup: checking backup on: ', m.toISOString());
    let now = moment();
    this._logger.info('backup: current date is: ', now.toISOString());
    let oneWeekAgo = now.subtract(1, 'week');
    this._logger.info('backup: oldest allowed backup date is: ', oneWeekAgo.toISOString());

    let recent = (m.isAfter(oneWeekAgo));
    this._logger.info('is recent: ', recent);
    return recent;
  }

  // private handleBackupStatus(status: BackupStatus) {
  //   this._logger.debug(`~~~ handle backup status: ${status.previous_operation.name} (${status.previous_operation.success}) > ${status.current_operation.name}`);
  //   this.progress = this.progress.handleStatus(status);
  //   this._logger.debug('~~~ new state is ', this.progress);
  //   if (this.progress.isComplete()) {
  //     this._logger.debug('~~~ backup is complete');
  //     if (this.progress.isSuccess()) {
  //       this._logger.debug('~~~ backup is successful');
  //       let date = new Date(status.previous_operation.end_time * 1000); // this is a unix timestamp
  //       this._logger.info('backup: parsed date of previous backup end time', date);
  //       if (this._isLessThanOneWeekAgo(date)) {
  //         this._logger.debug('~~~ backup is recent');
  //         // remove our subscription to the backup status
  //         this._backupSubscriber.unsubscribe();
  //         if (this._backupSubscriber) {
  //           this._backupSubscriber = null;
  //         }

  //         this.store.dispatch({ type: SET_BACKUP_REQUIRED, payload: false });
  //         this.store.dispatch({ type: SET_BACKUP_COMPLETE, payload: true });
  //         this._ready(true);
  //       } else {
  //         // NOTE shouldn't really get here because errors are handled above
  //         this._ready(true);
  //         this.router.navigate(['../backup']);
  //       }
  //     } else {
  //       this._logger.debug('~~~ backup was not successful');
  //       this._error({ message: 'An error occurred while preparing or saving this backup. Please contact your Control4 dealer.', context: status });
  //       this.router.navigate(['../backup']);
  //       this._ready(true);
  //     }
  //   } else {
  //     this._logger.debug('~~~ backup is not complete');
  //   }
  // }

  private _ready(state: boolean) {
    this.store.dispatch({ type: SET_READY, payload: state });
  }
}

//-------------------------------------------------------------------------------------------------

const BACKUP_OPERATIONS = {
  IDLE: 'Idle',
  CREATE_BACKUP: 'Create Backup',
  UPLOAD_BACKUP: 'Upload Backup'
};

/**
 * use state pattern to simplify tracking backup progress
 */

export interface BackupProgressHandler {
  handleStatus(status: BackupStatus): BackupProgressHandler;
  isStarted(): boolean;
  isComplete(): boolean;
  isSuccess(): boolean;
  error(): any;
  message(): string;
}

export class BackupIdleProgressHandler implements BackupProgressHandler {
  private _logger: Logger = LoggerFactory.getLogger(BackupIdleProgressHandler.name);
  message(): string { return 'Backup not started.' };

  constructor() {
    this._logger.setLevel(LogLevel.DEBUG);
  }

  private _lastStatus: BackupStatus;

  handleStatus(status: BackupStatus): BackupProgressHandler {
    this._logger.debug('~~~ backup current state: not started, handling update', status);
    this._lastStatus = status;

    // NOTE previously this was checking previous_operation.name but if we interrupt a running backup
    // or come on the heels of a restore that message can vary, so relax the gating condition a bit to
    // just look for Create Backup, the BackupStarteProgressdHandler will have to handle duplicate
    // Create Backup messages
    if (!!status.current_operation && status.current_operation.name === BACKUP_OPERATIONS.CREATE_BACKUP) {
      return new BackupStartedProgressHandler(status);
    }
    return this;
  }

  isComplete(): boolean {
    return false;
  }

  isSuccess(): boolean {
    return false;
  }

  isStarted(): boolean {
    return false;
  }

  error(): any {
    return null;
  }
}

export class BackupStartedProgressHandler implements BackupProgressHandler {
  private _logger: Logger = LoggerFactory.getLogger(BackupStartedProgressHandler.name);

  message(): string { return 'Preparing backup.  Please wait...' };

  private _lastStatus: BackupStatus;

  constructor(prevStatus: BackupStatus) {
    this._lastStatus = prevStatus;
    this._logger.setLevel(LogLevel.DEBUG);
  }

  handleStatus(status: BackupStatus): BackupProgressHandler {
    this._logger.debug('~~~ backup current state: started, handling update', status);

    this._lastStatus = status;

    // NOTE we can only gate out of this step if the create backup operation completes and was successful,
    // and the current operation is UPLOAD, anything else would be an error or unknown state
    if (!!status.previous_operation && status.previous_operation.name === BACKUP_OPERATIONS.CREATE_BACKUP) {
      if (status.previous_operation.success) {
        // NOTE the next state can only be UPLOAD_BACKUP (or an error)
        if (!!status.current_operation && status.current_operation.name === BACKUP_OPERATIONS.UPLOAD_BACKUP) {
          return new BackupUploadProgressHandler(status);
        } else {
          this._logger.error('invalid state update for create backup state: ', status);
          return new BackupErrorProgressHandler({ ...status, ...{ error: { message: 'Invalid backup state.' } } });
        }
      } else {
        // NOTE this assumes previous success will always be false for any failure
        if (!!status.error) {
          this._logger.error('backup create failed: ', status.error);
          return new BackupErrorProgressHandler(status);
        } else {
          this._logger.error('backup create failed: ', status);
          return new BackupErrorProgressHandler({ ...status, ...{ error: { message: 'An error occurred while creating the backup.' } } });
        }
      }
    }

    // NOTE we can get multiple updates with current_operation === CREATE_BACKUP, so just stay in this
    // state until the gating conditions above are met
    return this;
  }

  isComplete(): boolean {
    return false;
  }

  isSuccess(): boolean {
    return false;
  }

  isStarted(): boolean {
    return true;
  }

  error(): any {
    return null;
  }
}

/**
 * This state is used to represent when the backup is being uploaded to the
 * cloud.
 */
export class BackupUploadProgressHandler implements BackupProgressHandler {
  private _logger: Logger = LoggerFactory.getLogger(BackupUploadProgressHandler.name);

  message(): string { return 'Uploading the backup.  Please wait...'; }

  private _lastStatus: BackupStatus;

  constructor(status: BackupStatus) {
    this._lastStatus = status;
    this._logger.setLevel(LogLevel.DEBUG);
  }

  handleStatus(status: BackupStatus): BackupProgressHandler {
    this._logger.debug('~~~ backup current state: uploading, handling update', status);

    // NOTE we only pass the gate out of this state when the upload has become the previous operation
    if (!!status.previous_operation && status.previous_operation.name === BACKUP_OPERATIONS.UPLOAD_BACKUP) {
      if (status.previous_operation.success) {
        this._logger.debug('~~~ upload complete, setting backup complete state');

        return new BackupCompleteProgressHandler(status);
      } else {
        if (!!status.error) {
          this._logger.error('backup upload failed: ', status);
          return new BackupErrorProgressHandler(status);
        } else {
          this._logger.error('backup upload failed: ', status);
          return new BackupErrorProgressHandler({ ...status, ...{ error: { message: 'Backup upload failed.' } } });
        }
      }
    }

    this._logger.debug('~~~ no state change detected');
    return this;
  }

  isComplete(): boolean {
    return false;
  }

  isSuccess(): boolean {
    return false;
  }

  isStarted(): boolean {
    return true;
  }

  error(): any {
    return null;
  }
}

/**
 * This state is used to represent when the backup procedure has completed.  Note
 * that it may complete successfully or not.  An unsuccessful completion is not
 * necessarily the same as an error condition below.
 */
export class BackupCompleteProgressHandler implements BackupProgressHandler {
  private _logger: Logger = LoggerFactory.getLogger(BackupCompleteProgressHandler.name);

  message(): string { return 'Backup complete.  Navigating to When >> Then...'; }

  private _lastStatus: BackupStatus;

  constructor(lastStatus: BackupStatus) {
    this._lastStatus = lastStatus;
    this._logger.setLevel(LogLevel.DEBUG);
  }

  handleStatus(status: BackupStatus): BackupProgressHandler {
    this._logger.debug('backup state: complete, handling update', status);
    // TODO this means that we have reached the end, the consumer of this class
    // examines the outputs below to see what happened
    return this;
  }

  isComplete(): boolean {
    return true;
  }

  isSuccess(): boolean {
    // return this._lastStatus.previous_operation.success;
    return true;
  }

  isStarted(): boolean {
    return false;
  }

  error(): any {
    return this._lastStatus.error;
  }
}

/**
 * This state is used to represent when an error has happened during the
 * backup procedure.  This can happen at almost any time, so most intermediate
 * states will need to check for an error response and return this state instead.
 */
export class BackupErrorProgressHandler implements BackupProgressHandler {
  private _logger: Logger = LoggerFactory.getLogger(BackupErrorProgressHandler.name);

  message(): string { return 'Backup error. Please contact your Control4 dealer for assistance.' };

  private _lastStatus: BackupStatus;

  constructor(prevStatus: BackupStatus) {
    this._lastStatus = prevStatus;
    this._logger.setLevel(LogLevel.DEBUG);
  }

  handleStatus(status: BackupStatus): BackupProgressHandler {
    this._logger.debug('backup state: error, handling update', status);
    this._lastStatus = status;
    // TODO this means that we have reached the end, via an error somewhere in the process,
    // the consumer of this class examines the outputs below to see what happened
    this._logger.debug('~~~ no state change detected');
    return this;
  }

  isComplete(): boolean {
    return true;
  }

  isSuccess(): boolean {
    return false;
  }

  isStarted(): boolean {
    return false;
  }

  error(): any {
    return this._lastStatus.error;
  }
}
