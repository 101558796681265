import { Injectable } from '@angular/core';
import { Observable, interval } from 'rxjs';
import { map, share } from 'rxjs/operators';

@Injectable()
export class TimeOfDayService {

  timeOfDay: Observable<Date>;

  constructor() {
    this.timeOfDay = interval(1000)
      .pipe(
        map(tick => new Date()),
        share()
      );
  }

}
