import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'deviceInRoom'
})
export class DeviceInRoomPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value) {
      return (value.roomName + ' ' + String.fromCharCode(8250) + ' ' + value.name);
    } else {
      return '';
    }
  }

}
