import {Pipe, PipeTransform} from '@angular/core';
import {Store} from '@ngrx/store';

@Pipe({
  name: 'selectLocations'
})
export class SelectLocationsFilterPipe implements PipeTransform {

  constructor(
    private store: Store<any>
  ) { }

  transform(allItems: Array<any>): Array<any> {
    if (allItems) {
      return allItems.filter(item => {
        return (item.type >= 2 && item.type <= 4);
      });
    } else {
      return [];
    }
  }
}
