import { CodeItemType } from './../../../common/interfaces/item.interface';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, combineLatest } from 'rxjs';
import { filter, map, flatMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { BrokerService } from '@when-then/core';

import { ProgrammingUtilsService as Utils } from './../../../common/services/utils.service';
import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';
import { CommandData, SharedProgrammingService } from '../../../common/services/shared-programming.service';
import { Device } from '../../../common/interfaces/item.interface';
import { ItemsState } from '../../../common/services/items.service';

import { Room, MediaDevice, MediaProgrammingContext } from '../quickstart-media.service';
import { Command } from '../../../common/interfaces/command.interface';

interface Config {
  [key: string]: {
    display: string;
    icon: string;
    waitText: string;
    command: string;
    endpoint: string;
  }
}

const SUPPORTED_FILENAMES = [
  'cd.c4i',
  'discchanger.c4i',
  'dvd.c4i',
  'media_player.c4i',
  'cable.c4i',
  'projector.c4i',
  'satellite.c4i',
  'tuner.c4i',
  'tunerXM.c4i',
  'tv.c4i',
  'vcr.c4i',
];

@Component({
  templateUrl: './select-device.component.html'
})
export class QSMediaSelectDeviceComponent extends BaseRoutingComponent {

  public commandName: Observable<string>;
  public roomId: Observable<number>;
  public room: Observable<Room>;
  public command: Observable<Command>;
  public devices: Observable<Device[]>;

  public readonly CONFIG: Config = {
    SELECT_AUDIO_DEVICE: {
      display: 'Audio Source',
      icon: 'music',
      waitText: 'Finding audio devices...',
      command: 'SELECT_AUDIO_DEVICE',
      endpoint: 'listen_devices'
    },
    SELECT_VIDEO_DEVICE: {
      display: 'Video Source',
      icon: 'watch',
      waitText: 'Finding video devices...',
      command: 'SELECT_VIDEO_DEVICE',
      endpoint: 'watch_devices'
    }
  };

  constructor(
    public router: Router,
    protected store: Store<{
      mediaProgramming: MediaProgrammingContext,
      programmingItems: ItemsState
    }>,
    private _route: ActivatedRoute,
    private _broker: BrokerService,
    private _shared: SharedProgrammingService
  ) {
    super();

    this.commandName = this._route.params.pipe(map((params: { source: string }) => params.source));

    this.roomId = this._route.params.pipe(map((params: { roomId: string }) => parseInt(params.roomId)));

    this.room = this.roomId
      .pipe(
        flatMap(roomId => {
          return this.store.select(s => s.mediaProgramming.rooms)
            .pipe(
              filter(r => !!r),
              map(rooms => rooms.find(room => room.id == roomId))
            )
        })
      );

    this.command = combineLatest(this.commandName, this.roomId)
      .pipe(
        flatMap(([commandName, roomId]) => {
          return this.store.select(s => s.mediaProgramming.rooms)
            .pipe(
              filter(r => !!r),
              map(rooms => rooms.find(room => room.id == roomId).commands),
              map(commands => commands.find(command => command.command == commandName))
            )
        })
      );

    this.devices = this.command
      .pipe(
        flatMap(cmd => {
          const param = cmd.params.find(param => param.name == 'deviceid');
          return <Promise<{ visible: MediaDevice[] }>>this._broker.call({
            path: `/api/v1/locations/rooms/${cmd.deviceId}/media/${this.CONFIG[cmd.command].endpoint}`
          });
        }),
        map(r => r.visible.map(d => d.id)),
        flatMap(deviceIds => this.store.select(s => s.programmingItems.itemsList)
          .pipe(
            map(items => <Device[]>items.filter((item: Device) => {
              return !!item.filename
                && deviceIds.indexOf(item.id) > -1
                && SUPPORTED_FILENAMES.indexOf(item.filename) > -1;
            }))
          )
        )
      );
  }

  public setSource = async (device: MediaDevice) => {
    const command = await this.command.pipe(take(1)).toPromise();

    // NOTE special handling if command params
    const data: CommandData = {
      ...Utils.buildCommandData(CodeItemType.Command, command),
      ...{
        params: [{
          name: 'deviceid',
          value: device.id,
          display: device.name
        },
        {
          name: 'deselect',
          value: '1'
        }]
      }
    };

    this._shared.setPendingAction(command, data);
    this.router.navigate(['conditionals'], { relativeTo: this._route });
  }

}
