import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'prg-buttons',
  templateUrl: './flow-control.component.html'
})
export class FlowControlComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
