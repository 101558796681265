import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';

// import { environment as ENV } from '../../environments/environment';

@Injectable()
export class StorageService extends BaseService {

  constructor() {
    super();
    // this.setAll(ENV);
    // if (!ENV.brokerAddr || ENV.brokerAddr.length === 0) {
    //   this.set('currentHost', window.location.hostname);
    // } else {
    //   this.remove('currentHost');
    // }
  }

  set(key: string, value: any): void {
    window.localStorage.setItem(key, JSON.stringify({ value: value }));
  }

  get(key: string): any {
    var val: any = null;
    var raw = window.localStorage.getItem(key);
    if (raw) {
      var parsed = JSON.parse(raw);
      if (parsed && parsed.value) {
        val = parsed.value;
      }
    }
    return val;
  }

  getAsync<T = any>(key: string): Observable<T> {
    return Observable.create((observer) => {
      observer.next(this.get(key))
      observer.complete();
    });
  }

  remove(key: string): void {
    window.localStorage.removeItem(key);
  }

  setAll(obj: any): void {
    var that = this;
    Object.keys(obj).forEach(function (key) {
      if (obj.hasOwnProperty(key)) {
        that.set(key, obj[key]);
      }
    });
  }

  clear(): void {
    window.localStorage.clear();
  }


  dump(): string {
    let text = '';

    for (let i = 0; i < window.localStorage.length; i++) {
      const key = window.localStorage.key(i);
      if (key !== 'appKey' && key !== 'authToken') {
        text += (key + ': ' + window.localStorage[key] + '\n');
      }
    }

    return text;
  }
}
