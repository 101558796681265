import { ProgrammingUtilsService as Utils } from './../services/utils.service';
import { Pipe, PipeTransform } from '@angular/core';

import { Event } from '../interfaces/event.interface';
import { ItemsService } from '../services/items.service';
import { SharedProgrammingService } from './../services/shared-programming.service';
import { Item } from '../interfaces/item.interface';
import { AgentMetaService } from '../services/agent-meta.service';
import { LocationsService, Location } from '@when-then/core';


@Pipe({
  name: 'groupByRoom'
})
export class GroupByRoomPipe implements PipeTransform {

  constructor(
    private items: ItemsService,
    private shared: SharedProgrammingService,
    private agents: AgentMetaService,
    private locations: LocationsService
  ) { }

  transform(events: Event[], args?: any): any {
    // console.log('pipe: >>> groupByRoom', events);
    this.shared.ready(false);

    let roomMap: {
      [key: number]: {
        name: string;
        events: Event[]
      }
    } = {};

    let eventsWithoutRoom = {
      name: 'Programming Not in a Specific Room',
      events: []
    };

    if (events) {
      events.forEach(evt => {
        // console.log('pipe: groupbyroom: event is', evt);
        let device = this.items.getItem(evt.deviceId);
        // console.log('pipe: groupbyroom: device is', device);
        if (device) {
          let room = undefined;
          if (device.type === 8) { // it's a room
            // console.log('found a room event', device, evt);
            room = device;
            room.events = [];
            roomMap[device.roomId] = room;
          } else {
            room = roomMap[device.roomId];
          }

          // console.log('pipe: groupbyroom: room is', room);
          if (room) {
            room.events.push(evt);
          } else {
            roomMap[device.roomId] = {
              name: (device.roomName) ? device.roomName : this.getRoomName(device),
              events: [evt]
            };
          }
        } else {
          // TODO is this even valid anymore?  rooms are caught above\
          // might be a room (for system remotes)
          let rm = Utils.snapshot<Location>(this.locations.getLocationById(evt.deviceId));
          // console.log('pipe: got location for device', rm);
          if (rm) {
            let room = roomMap[rm.id];
            if (room) {
              room.events.push(evt);
            } else {
              roomMap[rm.id] = {
                name: rm.name,
                events: [evt]
              };
            }
          } else {
            // not a device or room, stick it in the generic bucket
            eventsWithoutRoom.events.push(evt);
          }
        }
      });
    }

    let roomList: {
      name: string;
      events: Event[]
    }[] = [];

    Object.keys(roomMap)
      .filter(key => key != 'undefined') // <- Filter events not associated with rooms
      .forEach(key => roomList.push(roomMap[key]));

    if (eventsWithoutRoom.events.length > 0) {
      roomList.push(eventsWithoutRoom);
    }

    this.shared.ready(true);
    // console.log('pipe: <<< groupByRoom', roomList);
    return roomList;
  }

  private getRoomName(device: Item): string {
    if (device.typeName === 'agent') {
      return this.agents.getNameForId(device.id);
    } else {
      return 'Unknown Room or Location';
    }
  }

}
