<div class="modal">
  <section>
    <header>
      <h4 class="grow">{{title}}</h4>
    </header>
    <div class="content">
      <ng-content></ng-content>
    </div>
    <footer>
      <button (click)="cancel.next()" class="default" [disabled]="disabled">Cancel</button>
      <button [ngClass]="confirmClass" (click)="confirm.next()" [disabled]="disabled">{{confirmLabel}}</button>
    </footer>
  </section>
</div>