import { Routes } from '@angular/router';

import { SelectRoomComponent } from './select-room/select-room.component';
import { SelectRemoteButtonComponent } from './select-remote-button/select-remote-button.component';

export const QUICKSTART_TRIGGERS_REMOTES_ROUTES: Routes = [{
  path: 'remotes', children: [{
    path: '', redirectTo: 'room', pathMatch: 'full'
  },{
    path: 'room', component: SelectRoomComponent
  }, {
    path: 'button', component: SelectRemoteButtonComponent
  }]
}];
