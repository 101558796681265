import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AgentMetaService } from './agent-meta.service';
import { Device } from './../interfaces/item.interface';
import { Item } from '../interfaces/item.interface';

interface DeviceCategory {
  category: string;
  valueField: string;
  values: string[];
  strategy?: 'startsWith' | string;
}

const DEVICE_CATEGORIES: DeviceCategory[] = [
  {
    "category": "email",
    "valueField": "deviceId",
    "values": ['100113']
  },
  {
    "category": "push",
    "valueField": "deviceName",
    "values": ["Push Notification"]
  },
  {
    "category": "keypads",
    "valueField": "proxy",
    "values": ["keypad_proxy",
      "control4_keypad_kpz-11-w",
      "control4_keypad_kpz-31-w",
      "control4_keypad_kpz-61-w"]
  },
  {
    "category": "controller",
    "valueField": "proxy",
    values: [
      "controller"
    ]
  },
  {
    "category": "lights",
    "valueField": "proxy",
    "values": [
      "light_v2",
      "light",
      "outlet_wireless",
      "outlet_light",
      "outlet_dimmer_module",
      "outlet_switch_module",
      "din_rail_8_channel_dimmer_module",
      "din_rail_8_channel_0-10V_module",
    ]
  },
  {
    "category": "relays",
    "valueField": "proxy",
    "values": [
      "din_rail_8_channel_relay_module",
    ]
  },
  {
    "category": "security",
    "valueField": "proxy",
    "values": [
      "security",
      "standalonesecurity",
      "occupancy_simulation"
    ]
  },
  {
    "category": "security",
    "valueField": "control",
    "values": [
      "control4_agent_access",
      "control4_agent_remoteaccess",
    ]
  },
  {
    "category": "agents",
    "valueField": "control",
    "values": [
      "control4_agent_identity",
      "control4_agent_services",
      "control4_agent_history",
      "control4_agent_macros",
      "control4_agent_navigation",
      "control4_agent_uiconfiguration",
      "control4_agent_wakeup_goodnight",
      "control4_agent_wakeup",
      "control4_agent_diagnostics",
      "control4_agent_updatemanager",
      "control4_agent_timer",
      "control4_agent_driverupdate",
    ]
  },
  {
    "category": "agents",
    "valueField": "typeName",
    "values": [
      "agent"
    ]
  },
  {
    "category": "zwave",
    "valueField": "control",
    "values": [
      "control4_agent_zwave"
    ]
  },
  {
    "category": "scenes",
    "valueField": "proxy",
    "values": [
      "voice-scene"
    ]
  },
  {
    "category": "scenes",
    "valueField": "control",
    "values": [
      "control4_agent_audioscenes"
    ]
  },
  {
    "category": "communication",
    "valueField": "control",
    "values": [
      "control4_agent_videointercom",
      "control4_agent_announcements",
    ]
  },
  {
    "category": "pools",
    "valueField": "proxy",
    "values": [
      "pool",
      "pool_232_jandy_aqualink"
    ]
  },
  {
    "category": "sensors",
    "valueField": "proxy",
    "values": [
      "contactsingle_carbonmonoxidedetector_c4",
      "contactsingle_contactswitch_c4",
      "contactsingle_doorbell_c4",
      "contactsingle_doorcontactsensor_c4",
      "contactsingle_drivewaysensor_c4",
      "relaycontact_garagedoor_c4",
      "contactsingle_gate_c4",
      "contactsingle_glassbreakdetector_c4",
      "contactsingle_heatdetector_c4",
      "contactsingle_humiditysensor_c4",
      "contactsingle_irbeam_c4",
      "contactsingle_motionsensor_c4",
      "contactsingle_pressuresensor_c4",
      "contactsingle_smokedetector_c4",
      "contactsingle_watersensor_c4",
      "contactsingle_windowcontactsensor_c4",
      "contactsingle_motionsensor",
      "cardaccess_wirelesscontact",
      "cardaccess_wirelesspir"
    ]
  },
  {
    "category": "system_remotes",
    "valueField": "proxy",
    "values": [
      "control4_sr250",
      "control4_sr150",
      "control4_sr260"
    ]
  },
  {
    "category": "touchscreens",
    "valueField": "proxy",
    "values": [
      "uidevice",
    ]
  },
  {
    "category": "touchscreens",
    "valueField": "protocolFilename",
    "values": [
      "uidevice_glassedge7.c4i",
      "uidevice_glassedge7p.c4i",
      "uidevice_glassedge10.c4i",
      "uidevice_glassedge10p.c4i",
      "uidevice_bcm7.c4i",
      "uidevice_bcm7p.c4i",
      "uidevice_c7.c4i",
      "uidevice_c5.c4i",
      "experience-button-key-status.c4z",
    ]
  },
  {
    "category": "touchscreens",
    "valueField": "control",
    "values": [
      "control4_agent_custombuttons",
      "control4_agent_screensaver"
    ]
  },
  {
    "category": "doorstations",
    "valueField": "proxy",
    "values": [
      "doorstation"
    ]
  },
  {
    "category": "media_distribution",
    "valueField": "proxy",
    "values": [
      "avswitch",
      "aswitch",
      "avswitch_ip_control4_v3",
      "avswitch_ip_control4_v2",
      "avswitch_ip_control4"
    ]
  },
  {
    "category": "motors",
    "valueField": "proxy",
    "values": [
      "cardaccess_wirelessrelay",
      "relaysingle_door_c4",
      "relaysingle_drapes_c4",
      "relaysingle_drivewayheater_c4",
      "relaysingle_electronicgate_c4",
      "relaycontact_electronicgate_c4",
      "relaysingle_fountain_c4",
      "relaysingle_gasfireplace_c4",
      "relaysingle_motorizedlift_c4",
      "relaysingle_motorizedscreen_c4",
      "relaysingle_pump_c4",
      "relaysingle_radiantfloor_c4",
      "relaysingle_relay_c4",
      "relaysingle_sprinklers_c4",
      "cardaccess_whpc",
    ]
  },
  {
    "category": "fan",
    "valueField": "proxy",
    "values": [
      "fan",
      "relaysingle_fan_c4",
    ]
  },
  {
    "category": "blinds",
    "valueField": "proxy",
    "values": [
      "blind",
      "blindV2",
      "blind_ir",
      "blind_single_relay",
      "blind_double_relay"
    ]
  },
  {
    "category": "locks",
    "valueField": "proxy",
    "values": [
      "relaysingle_doorlock_c4",
      "Lock_Zigbee_Baldwin_SmartLock",
      "DriverWorks_BlackDecker_Lock",
      "DriverWorks_BlackDecker_Lock_SmartLock RF Zigbee_Black and Decker",
      "Lock_Zigbee_Kwikset_SmartLock",
      "Lock_ZigBee_Yale",
      "lock"
    ]
  },
  {
    "category": "media",
    "valueField": "proxy",
    "values": [
      "media_service",
      "rf_cable",
      "rf_uhf_vhf",
      "rf_dish",
      "rf_usdtv",
      "tv",
      "vcr",
      "cable",
      "projector",
      "satellite",
      "dvd",
      "rf_am",
      "rf_fm",
      "rf_internet",
      "rf_sirius",
      "rf_sky",
      "rf_star",
      "rf_voom",
      "control4_digitalaudio",
      "tuner",
      "receiver",
      "media_player",
      "ipod",
      "amplifier",
      "cd",
      "discchanger",
      "C4-MP2-E",
      "tunerXM",
      "tv_232_fujitsu_XTA",
      "amp3_4Ch_control4",
      "amp16_ip_control4",
      "control4_dock_ipod",
      "plasma",
    ]
  },
  {
    "category": "rooms",
    "valueField": "proxy",
    "values": [
      "roomdevice"
    ]
  }, {
    "category": "network",
    "valueField": "proxy",
    "values": [
      "din_rail_8_port_ethernet_switch",
      "control4_485_gateway",
      "control4_network_mediastorage"
    ]
  }, {
    "category": "power",
    "valueField": "proxy",
    "values": [
      "bus_power_supply"
    ]
  }, {
    "category": "thermostats",
    "valueField": "proxy",
    "values": [
      "thermostatV2"
    ]
  },
  {
    "category": "infrastructure",
    "valueField": "proxy",
    "values": [
      "din_rail_2_panel",
      "din_rail_5_panel",
      "control4_modulator",
      "control4_iox",
      "control4_contactrelayextender",
      "control4_usb_mediastorage"
    ]
  },
];

@Injectable()
export class DeviceCategoryService {

  constructor(
    private http: HttpClient,
    private agents: AgentMetaService
  ) {
    // console.log('devcatsvc: ctor()');
  }

  getItemCategory = (item: Item): string => {
    let dc = DEVICE_CATEGORIES.find(devcat => {
      return devcat.values.some(v => {
        return (devcat.strategy == 'startsWith') ?
          item[devcat.valueField].startsWith(v) :
          item[devcat.valueField] == v;
      })
    });

    return (dc) ? dc.category : 'unknown';
  }

  getCategoryIconForItem(item: any): string {
    let cat = this.getItemCategory(item);
    // console.log('devcat: category for item is ', item, cat);

    switch (cat) {
      // -- NEW
      case 'lights': return 'lightbulb';
      case 'locks': return 'door-lock';
      case 'security': return 'fa fa-fw fa-shield';
      case 'blinds': return 'blinds-open';
      case 'media': return 'fa fa-fw fa-music';
      case 'thermostats': return 'thermostat';
      case 'controllers': return 'controller';
      case 'sensors': return this._getSensorsIcon(item);
      case 'motors': return this._getMotorsIcon(item);
      case 'cameras': return 'fa fa-fw fa-video-camera';
      case 'keypads': return 'keypad';
      case 'pools': return 'fa fa-fw fa-tint';
      case 'fan': return 'fan';
      case 'agents': return this._getAgentIcon(item);
      case 'schedules': return 'calendar';
      case 'system_remotes': return 'remote';
      case 'email': return 'email';
      case 'push': return 'push';
      case 'scenes': return this._getScenesIcon(item);
      case 'touchscreens': return 'touch';

      case 'conditional': return 'conditional';
      case 'power': return 'fa-bolt';
      case 'relays': return 'relay';
      case 'communication': return 'communication';
      case 'doorstations': return 'keypad';  /// was 'doorstation'
      case 'media_distribution': return 'avdevice';
      case 'rooms': return 'room';
      case 'network': return 'network';
      case 'infrastructure': return 'infrastructure';

      default:
        // console.warn('dev-cat.svc: unable to determine category icon for item', item);
        return 'unknown';
    }
  }

  private _getAgentIcon(id: number): string {
    return this.agents.getIconForId(id);
  }

  private _getMotorsIcon(item: any): string {
    switch (item.protocolFilename) {
      case 'relaysingle_gasfireplace_c4.c4i': return 'fireplace';
      // case "cardaccess_wirelessrelay.c4i": return 'motors';
      case "relaysingle_door_c4.c4i": return 'door-open';
      case "relaysingle_drapes_c4.c4i": return 'blinds'; // TODO fix this
      case "relaysingle_drivewayheater_c4.c4i": return 'driveway';
      case "relaysingle_electronicgate_c4.c4i": return 'gate';
      case "relaycontact_electronicgate_c4.c4i": return 'gate';
      // case "relaysingle_fountain_c4.c4i": return 'fountain';
      case "relaysingle_gasfireplace_c4.c4i": return 'fireplace';
      // case "relaysingle_motorizedlift_c4.c4i": return 'lift';
      // case "relaysingle_motorizedscreen_c4.c4i": return 'screen';
      // case "relaysingle_pump_c4.c4i": return 'pump';
      // case "relaysingle_radiantfloor_c4.c4i": return 'radiant-floor';
      case "relaysingle_relay_c4.c4i": return 'relay';
      // case "relaysingle_sprinklers_c4.c4i": return 'sprinklers';
      // case "cardaccess_whpc.c4i": return 'whpc';
    }

    return 'unknown';
  }

  private _getSensorsIcon(item: any): string {
    if (!!item) {
      switch (item.protocolFilename) {
        case 'contactsingle_doorcontactsensor_c4.c4i': return 'door-open';
        case "contactsingle_carbonmonoxidedetector_c4.c4i": return 'detect-co';
        case "contactsingle_contactswitch_c4.c4i": return 'relay';
        case "contactsingle_doorbell_c4.c4i": return 'touch-press';
        case "contactsingle_drivewaysensor_c4.c4i": return 'driveway';
        case "relaycontact_garagedoor_c4.c4i": return 'garage';
        case "contactsingle_gate_c4.c4i": return 'gate';
        case "contactsingle_glassbreakdetector_c4.c4i": return 'window-break';
        case "contactsingle_heatdetector_c4.c4i": return 'heat';
        case "contactsingle_humiditysensor_c4.c4i": return 'humidity';
        case "contactsingle_irbeam_c4.c4i": return 'infrared-trigger';
        case "contactsingle_motionsensor_c4.c4i": return 'motion-trigger';
        case "control4_wirelesspir.c4i": return 'motion-trigger';
        case "contactsingle_pressuresensor_c4.c4i": return 'pressure';
        case "contactsingle_smokedetector_c4.c4i": return 'detect-smoke';
        case "contactsingle_watersensor_c4.c4i": return 'detect-water';
        case "contactsingle_windowcontactsensor_c4.c4i": return 'window-open';
        case "contactsingle_motionsensor.c4i": return 'motion-trigger';
        case "cardaccess_wirelesscontact.c4i": return 'relay';
        // case "cardaccess_wirelesspir.c4i": return 'wirelesspir';
      }
    }

    return 'unknown';
  }

  private _getScenesIcon(item: Device): string {
    if (item.proxy === 'voice-scene') {
      return 'voice-scene';
    } else {
      return 'scenes';
    }
  }
}
