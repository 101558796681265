import { ActionReducerMap } from '@ngrx/store';

import { notificationsProgrammingReducer, NotificationProgrammingContext } from './services/notifications.service';

export interface QuickstartNotificationsState {
  notificationsProgramming: NotificationProgrammingContext
}

export const QUICKSTART_NOTIFICATIONS_REDUCERS: ActionReducerMap<QuickstartNotificationsState> = {
  notificationsProgramming: notificationsProgrammingReducer
}
