import { ActionReducerMap } from '@ngrx/store';

import { mediaProgrammingReducers, MediaProgrammingContext } from './quickstart-media.service';

export interface QuickstartMediaState {
  mediaProgramming: MediaProgrammingContext
}

export const QUICKSTART_MEDIA_REDUCERS: ActionReducerMap<QuickstartMediaState> = {
  mediaProgramming: mediaProgrammingReducers
}
