import { FAN } from './fan.type';
import { ROOM_MEDIA_PLAY } from './room-media-play.type';
import { ROOM_MEDIA_OFF } from './room-media-off.type';
import { SCHEDULE } from './schedule.type';
import { LIGHT_BUTTON } from './light-button.type';
import { BLINDS } from './blinds.type';
import { CARBON_MONOXIDE } from './co.type';
import { CONTACT_SENSOR } from './contact-sensor.type';
// import { CUSTOM_BUTTON } from './custom-button.type';
import { DOOR_LOCK } from './door-lock.type';
import { DOOR_SENSOR } from './door-sensor.type';
import { DRIVEWAY } from './driveway.type';
import { FIREPLACE } from './fireplace.type';
import { GARAGE } from './garage.type';
import { GATE } from './gate.type';
import { GLASS } from './glass.type';
import { HEAT } from './heat.type';
import { HUMIDITY } from './humidity.type';
import { IR } from './ir.type';
import { LIGHT } from './light.type';
import { MOTION } from './motion.type';
import { PRESSURE } from './pressure.type';
import { SECURITY_SYSTEM } from './security-system.type';
import { SMOKE } from './smoke.type';
import { WATER } from './water.type';
import { WINDOW } from './window.type';
import { KEYPAD_BUTTON } from './keypad-button.type';
import { REMOTE_BUTTON } from './remote-button.type';
import { ROOM_AUDIO } from './room-audio.type';
import { ROOM_VIDEO } from './room-video.type';
import { ROOM_MEDIA_PAUSED } from './room-media-pause.type';
import { DOORBELL_CONTACT } from './doorbell.type';
import { SPRINKLER_SYSTEM } from './sprinkler-system.type';
import { DOORSTATION_BUTTON } from './doorstation-button.type';
import { DOORSTATION_MOTION } from './doorstation-camera.type';
import { DOORSTATION_SECURITY } from './doorstation-security.type';
import { LIGHTING_SCENES } from './light-scenes.type';
import { VOICE_SCENE } from './voice-scene.type';

export const EventTypes = [
  BLINDS,
  CARBON_MONOXIDE,
  CONTACT_SENSOR,
  // CUSTOM_BUTTON,
  DOOR_LOCK,
  DOOR_SENSOR,
  DRIVEWAY,
  FIREPLACE,
  GARAGE,
  GATE,
  GLASS,
  HEAT,
  HUMIDITY,
  IR,
  KEYPAD_BUTTON,
  LIGHT,
  LIGHT_BUTTON,
  MOTION,
  PRESSURE,
  REMOTE_BUTTON,
  ROOM_AUDIO,
  ROOM_MEDIA_OFF,
  ROOM_MEDIA_PAUSED,
  ROOM_MEDIA_PLAY,
  ROOM_VIDEO,
  SCHEDULE,
  SECURITY_SYSTEM,
  SMOKE,
  WATER,
  WINDOW,
  DOORBELL_CONTACT,
  FAN,
  SPRINKLER_SYSTEM,
  DOORSTATION_BUTTON,
  DOORSTATION_MOTION,
  DOORSTATION_SECURITY,
  LIGHTING_SCENES,
  VOICE_SCENE
]
