import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { StorageService } from '@when-then/core';

@Injectable()
export class SkipWelcomeGuard implements CanActivate, CanActivateChild {

  constructor(
    private storage: StorageService,
    private router: Router
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    // let skip = this.storage.get('skipWelcome');
    // let proceed = this.storage.get('proceedWithCaution');
    // // console.log('guard: skip welcome is', skip);
    // if (!skip && !proceed) {
    //   let path = state.url.substring(0, state.url.indexOf('programming')) + 'programming/when-then/welcome';
    //   // console.log('guard: skip welcome start url', path);

    //   this.router.navigate([path]);
    // }

    // return true;

    // NOTE skip welcome is not allowed now
    return false;
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(next, state);
  }
}
