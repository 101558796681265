<cxScrollable>
  <div class="intro">
    <section class="light one flex-col nowrap align-center">
      <h3 class="fg-blue">Tutorial</h3>
      <h1 class="fg-blue wd-medium">WHEN &gt;&gt; THEN LETS YOU PERSONALIZE YOUR CONTROL4 AUTOMATION </h1>
      <p class="fg-white centered wd-medium">After your dealer installs Control4, When >> Then lets you personalize
        your
        experience. You can add a single button press to start your favorite tv station, automate lights that turn on
        at dusk, or specify music that starts automatically when you get home.</p>
      <div class="flex-row nowrap split space-around justify-center">
        <div class="grow"></div>
        <div class="flex-col nowrap align-center button-container">
          <h5 class="fg-white">Let us show you how</h5>
          <button (click)="showVideo()" class="fg-blue border-blue bg-white"><span class="icon blue small play"></span>
            <span>Watch the Video</span></button>
        </div>
        <div class="flex-col nowrap align-center button-container">
          <h5 class="fg-white">Ready to try it?</h5>
          <button [routerLink]="['../events']" class="fg-blue border-blue bg-white">Go to When &gt;&gt; Then</button>
        </div>
        <div class="grow"></div>
      </div>
    </section>

    <section class="dark flex-col nowrap align-center">
      <h3 class="fg-blue">How does it work?</h3>
      <h1 class="fg-white">ACTION. REACTION.</h1>
      <div class="flex-row nowrap justify-left">
        <div class="left">
          <p class="fg-medium-grey fs-24">"Whens" are actions.</p>
          <p class="fg-medium-grey fs-24">"Thens" are reactions.</p>
        </div>
        <div class="right">
          <p class="fg-white">“Whens" are events: like a button press, or your door unlocks, or it's sunset. "Thens"
            are the reactions that you want to automatically happen after a "When" event, like turning on a light.</p>
          <p class="fg-white">Select a "When” from the list of those available in your home. Join it to one of the
            available “Thens” and you’re done.</p>
        </div>
      </div>
      <div class="wt-header flex-row  transparent nowrap">
        <span class="title grow"><strong>When</strong> this happens...</span>
        <span class="title grow"><strong>Then</strong> do this.</span>
      </div>
      <div class="example">
        <div class="flex-row padded transparent nowrap event grow">
          <span class="icon blue keypad"></span>
          <span class="label grow">
            <div class="title">When the Office &gt; Keypad Top Button is double tapped</div>
          </span>

        </div>
        <div class="delta trailing"></div>
        <div class="actions grow">
          <div class="flex-row padded nowrap action">
            <span class="icon blue intervals"></span>
            <span class="label">Turn on the Office &gt; Overhead Lights
            </span>
          </div>
        </div>
      </div>
    </section>

    <section class="light flex-col nowrap align-center">
      <h1 class="fg-white">GET PERSONAL</h1>
      <div class="flex-row nowrap justify-left">
        <div class="left">
          <p class="fg-medium-grey fs-24">Personalize your home settings until they’re just right.</p>
        </div>
        <div class="right">
          <p class="fg-white">Your home may already support automation created by your Control4 Dealer when the system
            was installed. You’ll be able to see this automation, but you’ll need to contact your Control4 Dealer to
            change or remove it.</p>
          <p class="fg-white">Using When &gt;&gt; Then, you can add your own automation alongside your dealer’s, or
            create an entirely new automation.</p>
        </div>
      </div>

      <div class="wt-header flex-row  transparent nowrap">
        <span class="title grow"><strong>When</strong> this happens...</span>
        <span class="title grow"><strong>Then</strong> do this.</span>
      </div>
      <div class="example">
        <div class="flex-row padded transparent nowrap event grow">
          <span class="icon blue motion-trigger"></span>
          <span class="label grow">
            <div class="title">When the Front Porch &gt; Motion Sensor detects motion</div>
          </span>

        </div>
        <div class="delta trailing"></div>
        <div class="actions grow">
          <div class="flex-row padded nowrap action">
            <span class="icon blue intervals"></span>
            <span class="grow label">Start a motion timer</span>
            <span class="icon medium-grey dealer-lock"></span>
          </div>
          <div class="flex-row padded nowrap action">
            <span class="icon blue notice"></span>
            <span class="label">Send a push notification with subject "Motion Detected" and severity "Alert"</span>
          </div>
        </div>
      </div>
    </section>


    <section class="dark flex-col nowrap align-center">
      <h1 class="fg-white">REPEAT HISTORY</h1>
      <div class="flex-row nowrap justify-left">
        <div class="left">
          <p class="fg-medium-grey fs-24">Automate repetitive tasks with scheduled events.</p>
        </div>
        <div class="right">
          <p class="fg-white">Create a repeating schedule to automate mundane, repetitive tasks, such as turning the
            exterior lights on at sunset. Add as many actions as you want to a given event. Chain together events and
            actions to create sophisticated automations.</p>
        </div>
      </div>

      <div class="example">
        <div class="flex-row  transparent nowrap event grow">
          <span class="icon blue schedule"></span>
          <div>
            <div class="label grow">When it's dusk</div>
            <div class="subtitle">Every day at sunset</div>
          </div>
        </div>
        <div class="delta trailing"></div>
        <div class="actions grow">
          <div class="flex-row padded nowrap action">
            <span class="icon blue lightbulb"></span>
            <span class="label">Turn on the Outside &gt; Porch Lights</span>
          </div>
          <div class="flex-row padded nowrap action">
            <span class="icon blue lightbulb"></span>
            <span class="label">Turn on the Outside &gt; Garden Accents</span>
          </div>
          <div class="flex-row padded nowrap action">
            <span class="icon blue blinds"></span>
            <span class="label">Open the Great Room &gt; Blinds</span>
          </div>
        </div>
      </div>
    </section>

    <section class="blue flex-col nowrap align-center">
      <h3 class="fg-white">Ready to Try It?</h3>
      <button class="huge bg-white fg-blue" [routerLink]="['../events']">View My When &gt;&gt; Then Automations</button>
    </section>

    <section class="black">
      <p class=" fg-medium-grey">When &gt;&gt; Then is a product of Control4 for use with registered systems.
        Automation created by a Control4 Dealer must be unlocked by the Dealer in order to be modified with this
        application. Contact your Control4 Dealer to request access to automation that they created. This applicaiton
        requires a recent backup of your system. If a recent backup is not detected, you will be prompted to make one.
        All backups created by this application are stored in the cloud. Your Control4 Dealer can help you restore your
        system from a backup, if necessary.
      </p>
    </section>

  </div>
</cxScrollable>
