import { Injectable } from '@angular/core';

import { ProgrammingUtilsService } from './../../common/services/utils.service';
import { SharedProgrammingService } from '../../common/services/shared-programming.service';

const SENSOR_EVENT_WHITE_LIST: any = {
  'contactsingle_motionsensor_c4': [ 1, 2 ],
  'relaycontact_garagedoor_c4': [ 1, 2 ],
  'contactsingle_watersensor_c4': [ 1, 2 ],
  'contactsingle_doorcontactsensor_c4': [ 1, 2 ],
  'contactsingle_windowcontactsensor_c4': [ 1, 2 ],
  'contactsingle_gate_c4': [ 1, 2 ],
  'contactsingle_smokedetector_c4': [ 1, 2 ],
  'contactsingle_heatdetector_c4': [ 1, 2 ],
  'contactsingle_carbonmonoxidedetector_c4': [ 1, 2 ],
  'contactsingle_glassbreakdetector_c4': [ 1, 2 ]
};

@Injectable()
export class SensorsService {

  constructor(
    private shared: SharedProgrammingService,
    private utils: ProgrammingUtilsService
  ) { }


  // getSensors(query: any) {
  //   this.shared.getTriggerDevices(query);
  // }
}
