import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'limit'
})
export class LimitPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (Array.isArray(value)) {
      if (args) {
        let limit = parseInt(args[0]);
        if (!isNaN(limit)) {
          return value.slice(0, limit - 1);
        }
      }
    }
    return value;
  }

}
