<prg-nav-header title="Where would you like the {{ prettySource[(source | async)] }} to play?" (cancel)="goToEvent()"></prg-nav-header>
<cxScrollable>
  <cxWait [cxUntil]="(_rooms | async) != undefined" cxText="Finding supported rooms...">
    <div *ngIf="(_rooms | async)?.length === 0">
      <header>
        <P class="grow"><span class="icon information grey x-small darkgrey"></span> There are no rooms that have
          visible devices which can play {{ prettySource[(source | async)] }}s, or no {{ prettySource[(source | async)] }}s were found.</P>
      </header>
    </div>
    <prg-block-button *ngFor="let room of (_rooms | async)" [routerLink]="[room.id, nextStep(source | async)]"
      leadingIcon="blue room" title="{{room.name}}" subtitle="{{room.floorName}}" trailingIcon="right"></prg-block-button>
  </cxWait>
</cxScrollable>