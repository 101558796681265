import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseService } from './base.service';

import { StorageService } from './storage.service';
import { GUIDService } from './guid.service';

@Injectable()
export class ConfigurationService extends BaseService {

	constructor(
		private http: HttpClient,
		private storage: StorageService
	) {
		super();
	}

	loadConfig(path: string): Promise<any> {
		console.log('configuration:load()');

		return new Promise((resolve, reject) => {
			this.http.get(path, {responseType: 'json'}).subscribe(
				data => {
					if (data) {
						this.storage.setAll(data);
						resolve();
					} else {
						reject({ message: 'no configuration data received from path: ' + path });
					}
				},
				(err: any) => reject(err));
		});
	}

}
