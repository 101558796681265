import { EventType } from './event-type.interface';

export const FIREPLACE: EventType = {
  name: 'Fireplace',
  description: 'When a Fireplace is lit or extinguished',
  route: 'fireplace',
  category: 'Fireplace',
  icon: 'fireplace',
  whitelist: [
    {
      fields: { proxy: 'relaysingle_gasfireplace_c4' },
      events: [1, 2]
    },
    {
      fields: { proxy: 'fireplace' },
      events: [1, 2]
    }
  ]
}
