import { Routes } from '@angular/router';

import { SelectActionComponent } from './select-action/select-action.component';
import { SelectParametersComponent } from './select-parameters/select-parameters.component';
import { SelectButtonComponent } from './select-button/select-button.component';
import { LightTriggerComponent } from './light-trigger/light-trigger.component';
import { SelectLightInteractionComponent } from './select-light-interaction/select-light-interaction.component';
import { LightActionComponent } from './light-action/light-action.component';
import { LightSceneListComponent } from './light-scene-list/light-scene-list.component';
import { LightSceneCommandComponent } from './light-scene-command/light-scene-command.component';
import { LightSceneEventsComponent } from './light-scene-events/light-scene-events.component';
import { CONDITIONAL_ROUTES } from '../quickstart-flow-control/flow-control.routes';

export const QUICKSTART_TRIGGERS_LIGHTS_BUTTONS_ROUTES: Routes = [{
  path: 'lights', children: [{
    path: '', component: LightTriggerComponent, data: { next: 'button' }
  }, {
    path: ':id/buttons', component: SelectButtonComponent
  }, {
    path: ':id/buttons/:button', component: SelectLightInteractionComponent
  }]
}];

export const QUICKSTART_TRIGGERS_LIGHTS_ROUTES: Routes = [{
  path: 'lights', children: [{
    path: 'light', children: [{
      path: '', redirectTo: 'select', pathMatch: 'full'
    }, {
      path: 'select', component: LightTriggerComponent
    }]
  }, {
    path: 'scenes', children: [{
      path: '', redirectTo: 'select', pathMatch: 'full'
    }, {
      path: 'select', component: LightSceneListComponent, data: { next: 'events' }
    }, {
      path: ':id/events', component: LightSceneEventsComponent
    }]
  }]
}];

export const QUICKSTART_ACTIONS_LIGHTS_ROUTES: Routes = [{
  path: 'lights', children: [{
    path: 'light', children: [{
      path: '', redirectTo: 'select', pathMatch: 'full'
    }, {
      path: 'select', component: LightActionComponent
    }, {
      path: ':id', component: SelectActionComponent
    }, {
      path: ':id/conditionals', children: [...CONDITIONAL_ROUTES]
    }, {
      path: ':id/:command', component: SelectParametersComponent
    }, {
      path: ':id/:command/conditionals', children: [...CONDITIONAL_ROUTES]
    }]
  }, {
    path: 'scenes', children: [{
      path: '', redirectTo: 'select', pathMatch: 'full'
    }, {
      path: 'select', component: LightSceneListComponent, data: { next: 'actions' }
    }, {
      path: ':id/actions', component: LightSceneCommandComponent
    }, {
      path: ':id/actions/conditionals', children: [...CONDITIONAL_ROUTES]
    }]
  }]
}];
