import { Router } from '@angular/router';
import { Component } from '@angular/core';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { Device } from '../../../common/interfaces/item.interface';

import { ThermostatProgrammingContext } from '../quickstart-thermostats.service';
import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';
import { SharedProgrammingService } from '../../../common/services/shared-programming.service';


@Component({
  templateUrl: './select-thermostat.component.html',
  styleUrls: []
})
export class QSThermostatSelectThermostatComponent extends BaseRoutingComponent {

  _thermostats: Observable<Device[]>;
  ready: Observable<boolean>;

  constructor(
    protected router: Router,
    protected shared: SharedProgrammingService,
    protected store: Store<{
      thermostatProgramming: ThermostatProgrammingContext
    }>
  ) {
    super();
    this.ready =  this.store.select(s => s.thermostatProgramming.listChecked);
    this._thermostats = this.store.select(s => s.thermostatProgramming.thermostats);
  }

}
