import { LoggerFactory } from '@when-then/core';
import { QuickstartKeypadsService } from './../services/keypads.service';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ProgrammingUtilsService } from './../../../common/services/utils.service';
import { Component, OnInit } from '@angular/core';
import { QuickstartProgrammingService } from '../../services/quickstart-programming.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedProgrammingService } from '../../../common/services/shared-programming.service';
import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';

@Component({
  selector: 'prg-select-keypad-interaction',
  templateUrl: './select-keypad-interaction.component.html',
  styleUrls: ['./select-keypad-interaction.component.less']
})
export class SelectKeypadInteractionComponent extends BaseRoutingComponent implements OnInit {
  private _logger = LoggerFactory.getLogger(SelectKeypadInteractionComponent.name);
  events: Observable<Array<any>>;
  device: Observable<any>;
  button: Observable<any>;
  ready: Observable<boolean>;

  constructor(
    protected router: Router,
    protected store: Store<any>,
    private utils: ProgrammingUtilsService,
    private route: ActivatedRoute,
    private shared: SharedProgrammingService,
    private keypads: QuickstartKeypadsService
  ) {
    super();
    this.events = this.store.select(s => s.keypadProgramming.trigger.events);
    this.device = this.store.select(s => s.keypadProgramming.trigger.keypad);
    this.button = this.store.select(s => s.keypadProgramming.trigger.button);
    this.ready = this.shared.isReady;
  }

  ngOnInit() {
    // this.shared.setTitle('Select a Button Interaction')

    this.events
      .pipe(
        filter(e => !!e),
        take(1)
      )
      .subscribe(events => {
        this._logger.debug('keypad interaction events', events);
        if (events.length === 1) {
          this.interactionSelected(events[0]);
        }
      })
  }

  interactionSelected(event: any) {
    this.router.navigate(['when-then', 'device', event.deviceId, 'event', event.eventId, 'add'], { relativeTo: this.moduleRoot });
  }

  goHome() {
    this.router.navigate(['../programming'], { relativeTo: this.moduleRoot });
  }
}
