import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, withLatestFrom, filter } from 'rxjs/operators';

import {
  REMOVE_CODEITEM_VERIFIER,
  CODEITEM_VERIFIERS_CHECKED,
  SET_WAIT_MESSAGE,
  SET_TRIGGER_EVENT,
  CLEAR_TRIGGER_EVENT
} from './shared-programming.service';
import { CommonProgrammingContext } from './shared-programming.service';
import {
  SET_WAIT_MESSAGE as SET_SCHEDULE_WAIT_MESSAGE,
  ClearScheduleEvent
} from '../../quickstarts/quickstart-schedule/+state/schedule.actions';
import { AnalyticsService, LoggerFactory } from '@when-then/core';
import { ItemsService } from './items.service';
import { DeviceEvent } from '../interfaces/event.interface';

@Injectable()
export class SharedProgrammingEffects {

  private _logger = LoggerFactory.getLogger(SharedProgrammingEffects.name);

  constructor(
    private _actions$: Actions,
    private analytics: AnalyticsService,
    private items: ItemsService,
    private store: Store<{
      sharedProgramming: CommonProgrammingContext
    }>
  ) { }

  @Effect() verifyOutstandingCodeItemChanges$ = this._actions$
    .pipe(
      ofType(SET_TRIGGER_EVENT),
      map((action: { type: string, payload: any }) => action.payload),
      withLatestFrom(this.store.select(s => s.sharedProgramming.codeItemVerifiers)),
      map(([event, verifiers]) => {
        return verifiers
          .filter(verify => {
            if (verify(event)) {
              this.store.dispatch({ type: REMOVE_CODEITEM_VERIFIER, payload: verify });
              return false;
            }

            return true;
          })
          .length
      }),
      map(outstanding => ({
        type: CODEITEM_VERIFIERS_CHECKED
      }))
    );

  /**
   * When a trigger event is selected, emit an analytics event.
   */
  @Effect({ dispatch: false }) eventAnalytics$ = this._actions$.pipe(
    ofType(SET_TRIGGER_EVENT),
    map((action: { type: string, payload: any }) => action.payload),
    filter(p => !!p),
    map((event: DeviceEvent) => {
      const dev = this.items.getItem(event.deviceId);
      if (!!dev) {
        this._logger.debug('analytics event: ', event);
        const label = `${dev.proxy || dev.protocolName}:${event.eventId}`;
        this.analytics.emitEvent('Event', 'selected', label);
      }
    })
  );

  /**
   * whenever a device event is selected that is a schedule event,
   * go get the corresponding schedule event as well.
  @Effect({dispatch: false}) getSchedule$ = this._actions$
    .ofType(SET_TRIGGER_EVENT)
    .map((action:{type:string, payload:any}) => action.payload)
    .distinctUntilChanged()
    .filter(e => !!e && e.deviceId === AGENTS.SCHEDULER.ID && e.eventId > 0)
    .map(e => this.schedules.getSchedule(e.eventId));
   */

  /**
   * When the selected device event is cleared, also clear the schedule event
   */
  @Effect() clearSchedule$ = this._actions$
    .pipe(
      ofType(CLEAR_TRIGGER_EVENT),
      map(e => new ClearScheduleEvent())
    );

  /**
   * When a schedule interaction requires a wait message, propagate that
   * message to the schedule view. Required because the component that
   * owns the schedule interactions (AddSchedule) cannot call the shared
   * service due to a circular dependency.
   */
  @Effect() waitMessageSet$ = this._actions$
    .pipe(
      ofType(SET_SCHEDULE_WAIT_MESSAGE),
      map((action: { type: string, payload: any }) => action.payload),
      map(e => ({ type: SET_WAIT_MESSAGE, payload: e }))
    );
}
