import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';

import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import {
  DirectorConnectionService,
  DirectorConnectionState
} from './director-connection.service';

@Injectable()
/**
 * Guard that makes sure Director
 * is up and connected. Appropriate
 * for routes than require Director
 * support.
 */
export class DirectorConnectedGuard implements CanActivate, CanActivateChild {

  private _status: Observable<string>;

  constructor(
    private _store: Store<{ directorConnection: DirectorConnectionState }>,
    private _service: DirectorConnectionService
  ) {
    this._status = this._store.select(s => s.directorConnection.director)
      // Don't resolve until we get _something_ back from
      // the service so we can route immediately
      .pipe(filter(d => d !== undefined));
  }

  canActivate = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
    // console.log('~~~ checking director connection guard');
    return this._status.pipe(map(s => s == 'connected' || s == 'alive'));
  }

  canActivateChild = (childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
    return this.canActivate(childRoute, state);
  }

}
