import { QuickstartCustomButtonsService } from './../services/custom-buttons.service';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { ProgrammingUtilsService } from './../../../common/services/utils.service';
import { Component, OnInit } from '@angular/core';
import { QuickstartProgrammingService } from '../../services/quickstart-programming.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedProgrammingService } from '../../../common/services/shared-programming.service';
import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';

@Component({
  selector: 'prg-select-custom-button-interaction',
  templateUrl: './select-custom-button-interaction.component.html',
  styleUrls: ['./select-custom-button-interaction.component.less']
})
export class SelectCustomButtonInteractionComponent extends BaseRoutingComponent implements OnInit {

  events: Array<any> = [
    {buttonAction:'Press', display:'PRESS' }, 
    {buttonAction:'Release', display:'RELEASE' }
  ];

  screen: Observable<any>;
  button: Observable<any>;
  ready: Observable<boolean>;

  constructor(
    protected router: Router,
    protected store: Store<any>,
    private utils: ProgrammingUtilsService,
    private route: ActivatedRoute,
    private shared: SharedProgrammingService
  ) {
    super();
    this.screen = this.store.select(s => s.customButtonsProgramming.trigger.screen);
    this.button = this.store.select(s => s.customButtonsProgramming.trigger.button);
    this.ready = this.shared.isReady;
  }

  ngOnInit() {
  }

  interactionSelected(event: any) {
    console.log("TBD - adding custom button code items not wired in broker yet");
    //this.router.navigate(['when-then', 'device', event.deviceId, 'event', event.eventId, 'add'], { relativeTo: this.moduleRoot });
  }

  goHome() {
    this.router.navigate(['../programming'], { relativeTo: this.moduleRoot });
  }
}
