import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { CoreModule } from '@when-then/core';
import { UIModule } from '@when-then/ui';

import { ProgrammingCommonModule } from '../../common/common.module';
import { QuickstartCommonModule } from '../quickstart-common/quickstart-common.module';

import { ScheduleQuickstartComponent } from './schedule-quickstart.component';
import { SelectRepeatComponent } from './select-repeat/select-repeat.component';
import { RepeatLabelPipe } from './pipes/repeat-label.pipe';
import { TimeLabelPipe } from './pipes/time-label.pipe';
import { EnddateLabelPipe } from './pipes/enddate-label.pipe';
import { StartdateLabelPipe } from './pipes/startdate-label.pipe';
import { SchedulesService } from './schedule.service';
import { ScheduleListComponent } from './schedule-list/schedule-list.component';
import { AddScheduleComponent } from './add-schedule/add-schedule.component';
import { StartDateComponent } from './start-date/start-date.component';
import { StartTimeComponent } from './start-time/start-time.component';
import { ScheduleIconPipe } from './pipes/schedule-icon.pipe';
import { ListByDateComponent } from './list-by-date/list-by-date.component';
import { ScheduleVMLabelPipe } from './pipes/schedule-vm-label.pipe';
import { SunriseSunsetOffsetPipe } from './pipes/sunrise-sunset-offset.pipe';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    CoreModule,
    UIModule,
    ProgrammingCommonModule,
    QuickstartCommonModule,
  ],
  declarations: [
    ScheduleQuickstartComponent,
    SelectRepeatComponent,
    RepeatLabelPipe,
    TimeLabelPipe,
    StartdateLabelPipe,
    EnddateLabelPipe,
    ScheduleListComponent,
    AddScheduleComponent,
    StartDateComponent,
    StartTimeComponent,
    ScheduleIconPipe,
    ListByDateComponent,
    ScheduleVMLabelPipe,
    SunriseSunsetOffsetPipe
  ],
  exports: [
    ListByDateComponent
  ],
  providers: [
    SchedulesService,
    ScheduleVMLabelPipe,
    SunriseSunsetOffsetPipe
  ]
})
export class QuickstartScheduleModule { }
