import { EventType } from './event-type.interface';

export const WATER: EventType = {
  name: 'Water',
  description: 'When a water sensor indicates the presence of water',
  route: 'water',
  category: 'Sensors',
  icon: 'water',
  whitelist: [
    {
      fields: { proxy: 'contactsingle_watersensor_c4' },
      events: [1, 2]
    }
  ]
}
