import { ActionReducerMap } from '@ngrx/store';

import { lightsProgrammingReducer, LightsProgrammingContext } from './services/lights.service';

export interface QuickstartLightsState {
  lightsProgramming: LightsProgrammingContext
}

export const QUICKSTART_LIGHTS_REDUCERS: ActionReducerMap<QuickstartLightsState> = {
  lightsProgramming: lightsProgrammingReducer
};
