import { EventType } from './event-type.interface';

export const GLASS: EventType = {
  name: 'Glass',
  description: 'When a Glass break is detected',
  route: 'glass',
  category: 'Security & Access',
  icon: 'window-break',
  whitelist: [
    {
      fields: { proxy: 'contactsingle_glassbreakdetector_c4' },
      events: [1, 2]
    }
  ]
}
