import { EventType } from './event-type.interface';

export const DOORBELL_CONTACT: EventType = {
  name: 'Doorbell',
  description: 'When a doorbell button is pressed',
  route: 'doorbell-contact',
  category: 'Buttons',
  icon: 'touch-press',
  whitelist: [
    {
      fields: { proxy: 'contactsingle_doorbell_c4' },
      events: [2]
    }
  ]
}
