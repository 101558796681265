
import { Pipe, PipeTransform } from '@angular/core';
import { ScheduleEvent, ScheduleViewModel } from '../+state/schedule.interfaces';

@Pipe({
  name: 'sunriseSunsetOffset'
})
export class SunriseSunsetOffsetPipe implements PipeTransform {

  transform(vm: ScheduleViewModel, args?: any): any {
    return `${vm.sunriseSunsetOffsetType}:${vm.sunriseOrSunset}`;
  }

}
