<prg-nav-header
  title="Which service would you like to stream from?"
  (cancel)="goToEvent()">
</prg-nav-header>
<cxScrollable>
  <cxWait
    [cxUntil]="(services | async) != undefined"
    cxText="Finding streaming services...">

    <prg-block-button
      *ngFor="let service of (services | async)"
      [routerLink]="['./', service.id]"
      title="{{ service.name }}"
      leadingIcon="song blue"
      trailingIcon="right">
    </prg-block-button>

    <div
      class="row alert"
      *ngIf="(services | async)?.length == 0">
      <header>No Streaming Services Found</header>
    </div>

  </cxWait>
</cxScrollable>
