
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { BrokerService, LoggerFactory } from '@when-then/core';
import { Observable, BehaviorSubject, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { C4Param, C4ValueType } from '../../../common/params/param-input.component';

interface C4RegularizedType {
  _display: string;
  _value: string | number;
}

@Component({
  selector: 'c4TimersInput',
  templateUrl: './timers-param-input.component.html',
  styleUrls: ['./timers-param-input.component.less']
})
export class TimersParamInputComponent {

  private _logger = LoggerFactory.getLogger(TimersParamInputComponent.name);

  @Input() param: C4Param;
  @Input() inputClass: string;

  @Output() valueChange = new EventEmitter<C4ValueType>();

  // Used if we're fetching a collection of values
  // from elsewhere...
  _values: Observable<C4RegularizedType[]>;
  _hoursOptions: Array<number> = [];
  _minOptions: Array<number> = [];
  _secOptions: Array<number> = [];

  _valHours: number = 0;
  _valMinutes: number = 0;
  _valSeconds: number = 0;

  _maxMinutes: number = 59;

  constructor(private broker: BrokerService) {
  }

  ngOnInit() {

    // Assemble an array of values from 'values', 'items',
    // or 'valueSrc', depending upon which we find. Wraps
    // all three types in an observable for template
    // consistency
    if (!!this.param.values) {
      let bh: BehaviorSubject<C4RegularizedType[]>;
      this._values = bh = new BehaviorSubject<C4RegularizedType[]>([]);
      bh.next(this.param.values.map(this._regularize));

    } else if (!!this.param.items) {
      let bh: BehaviorSubject<C4RegularizedType[]>;
      this._values = bh = new BehaviorSubject<C4RegularizedType[]>([]);
      bh.next(this.param.items.map(i => {

        // items are lists of numbers or strings, so just
        // map the value to both fields
        return {
          _display: (typeof i === 'string') ? i : i.toString(),
          _value: (typeof i === 'number') ? i : i.toString()
        }
      }))

    } else if (!!this.param.valueSrc && !!this.param.valueSrc.path) {
      this._values = from(
        this.broker.call({
          path: this.param.valueSrc.path,
          method: this.param.valueSrc.method || 'GET'
        })
      )
        .pipe(
          map(v => v.map(this._regularize))
        );
    }

    this._hoursOptions = [];
    this._minOptions = [];
    this._secOptions = [];

    this._valHours = 0;
    this._valMinutes = 0;
    this._valSeconds = this.param.low;
    let maxHours: number = (this.param.high / 3600) - 1;
    for (let i = 0; i <= maxHours; i++)
      this._hoursOptions.push(i);

    if (maxHours == 0)
      this._maxMinutes = this.param.high / 60;
    for (let i = 0; i <= this._maxMinutes; i++)
      this._minOptions.push(i);

    this.resetMinimumSeconds();
  }

  /**
   * Parses a returned list of values, extracting the
   * relevant fields to _value and _display for template
   * consistency.
   */
  private _regularize = (v: any): C4RegularizedType => {

    let val =
      (v[this.param.valueField] != undefined) ? v[this.param.valueField] :
        (v.value != undefined) ? v.value :
          v.id;

    let pd = this._parseDisplay(this.param.valueDisplay, v);

    let dis =
      (pd != undefined) ? pd :
        (v.display != undefined) ? v.display :
          v.name;

    return Object.assign({}, v, {
      _value: val,
      _display: dis
    });
  }

  /**
   * Parses the tokens in a valueDisplay field, replacing them with
   * their corresponding values from the item
   */
  private _parseDisplay = (valueDisplay: string, value: Object): string => {
    let parsed = valueDisplay;
    let tokens = valueDisplay
      .match(/{(.*?)}/g)
      .map(t => t.substr(1, t.length - 2));

    tokens.forEach(t => parsed = parsed.replace('{' + t + '}', value[t]));

    return (!!tokens) ? parsed : undefined;
  }

  public hoursChange = (value: number) => {
    this._valHours = value;
    this.checkSeconds();
    this.handleChange(this.getTotalSeconds());
  }

  public minutesChange = (value: number) => {
    this._valMinutes = value;
    this.checkSeconds();
    this.handleChange(this.getTotalSeconds());
  }

  public secondsChange = (value: number) => {
    this._valSeconds = value;
    this.handleChange(this.getTotalSeconds());
  }

  private getTotalSeconds() {
    return ((this._valHours * 3600) + (this._valMinutes * 60) + this._valSeconds);
  }

  private checkSeconds() {
    // show all options for seconds (0-59) if minutes or hours > 0
    if ((this._valMinutes > 0) || (this._valHours > 0)) {
      if (this._secOptions.length < 60) {
        this._secOptions = [];
        for (let i=0; i<=59; i++)
          this._secOptions.push(i);
      }
    }
    else {
      this.resetMinimumSeconds();
    }
  }

  private resetMinimumSeconds() {
    let maxSeconds: number = 59;
    let minSeconds: number = 0;
    if (this._maxMinutes == 0)
      maxSeconds = this.param.high;

    // in case there's a low value specified in the param
    if (this.param.low && (this.param.low > 0)) {
      minSeconds = this.param.low;
      this._valSeconds = minSeconds;
    }

    this._secOptions = [];
    for (let i=minSeconds; i<=maxSeconds; i++)
      this._secOptions.push(i);
  }

  /**
   * Emits changes. Mostly to handle the cases where
   * we don't have a _values array...
   */
  private handleChange(value: number) {
    let newValue: C4ValueType = {
      value: value,
      display: value.toString()
    };
    this._logger.debug('setting new timer property value', value);
    this.valueChange.next(newValue);
  }
}
