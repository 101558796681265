import { Store } from '@ngrx/store';
import { BrokerService, LocationsService, Location } from '@when-then/core';
import { Observable, combineLatest } from 'rxjs';
import { Injectable } from '@angular/core';

//import { Command } from '../../../common/params/interfaces/command.interface';
import { SharedProgrammingService } from '../../../common/services/shared-programming.service';

export interface CustomButton {
  id: number;
  name: string;
  title: string;
}

export interface CustomButtons {
  button: Array<CustomButton>;
}

export interface CustomButtonScreen {
  roomid: number;
  roomId?: number;
  floorId?: number;
  roomName?: string;
  floorName?: string;
  id: number;
  name: string;
  buttons: CustomButtons;
}

export interface CustomButtonsProgrammingContext {
  screens?: Array<CustomButtonScreen>;
  ready: boolean;
  trigger: {
    screen?: CustomButtonScreen;
    buttons?: CustomButtons;
    button?: CustomButton;
    //commands?: Command[];
    //command?: CommandData;
    //events?: Array<DeviceEvent>; // press/release only
    //event?: DeviceEvent;
  }
}

const INITIAL_STATE: CustomButtonsProgrammingContext = {
  trigger: {},
  ready: false
}

const STORE_NAME: string = 'PROGRAMMING:QUICKSTARTS:CUSTOMBUTTONS:';
const CLEAR_CONTEXT: string = STORE_NAME + 'CLEAR_CONTEXT';
const SET_SCREENS: string = STORE_NAME + 'SET_SCREENS';
const SET_SCREEN: string = STORE_NAME + 'SET_SCREEN';
const SET_BUTTONS: string = STORE_NAME + 'SET_BUTTONS';
const SET_BUTTON: string = STORE_NAME + 'SET_BUTTON';
const SET_EVENTS: string = STORE_NAME + 'SET_EVENTS'; // ??
//const SET_COMMANDS: string = STORE_NAME + 'SET_COMMANDS'; // ??
const CLEAR_BUTTONS: string = STORE_NAME + 'CLEAR_BUTTONS';
const SET_READY: string = STORE_NAME + 'SET_READY';

export function customButtonsProgrammingReducer(state: CustomButtonsProgrammingContext = INITIAL_STATE, { type, payload }) {

  switch (type) {
    case CLEAR_CONTEXT: return Object.assign({}, INITIAL_STATE);
    case SET_SCREENS: return Object.assign({}, state, { screens: payload });
    case SET_SCREEN: return Object.assign({}, state, { trigger: Object.assign({}, state.trigger, { screen: payload }) });
    case SET_BUTTONS: return Object.assign({}, state, { trigger: Object.assign({}, state.trigger, { buttons: payload }) });
    case SET_BUTTON: return Object.assign({}, state, { trigger: Object.assign({}, state.trigger, { button: payload }) });
    case SET_EVENTS: return Object.assign({}, state, { trigger: Object.assign({}, state.trigger, { events: payload }) });
    case CLEAR_BUTTONS: return Object.assign({}, state, { trigger: Object.assign({}, state.trigger, { buttons: [] }) });
    case SET_READY: return Object.assign({}, state, { ready: payload });

    default: return state;
  }
};

@Injectable()
export class QuickstartCustomButtonsService {

  trigger: Observable<any>;
  allScreens: Observable<CustomButtonScreen[]>;
  ready: Observable<boolean>;
  allRooms: Observable<Location[]>;

  constructor(
    protected store: Store<{ customButtonsProgramming: CustomButtonsProgrammingContext }>,
    protected broker: BrokerService,
    protected shared: SharedProgrammingService,
    private locations: LocationsService,
  ) {
    this.ready = combineLatest(
      this.shared.isReady,
      this.store.select(s => s.customButtonsProgramming.ready),
      (a, b) => (a && b)
    );

    this.allRooms = this.locations.getRooms();
    this.allScreens = this.store.select(s => s.customButtonsProgramming.screens);

    // combine room and floor info into screens so we can using existing device pipes
    let screens = combineLatest(
      this.allRooms,
      this.allScreens,
      (rooms, screens) => ({ rooms, screens })
    );
    screens.subscribe(o => {
      if (!!o && !!o.rooms && !!o.screens) {
        o.screens.map(sc => {
          let room = o.rooms.find(rm => (sc.roomid === rm.id));
          if (room) {
            sc.floorId = room.floorId;
            sc.floorName = room.floorName;
            sc.roomId = room.id;
            sc.roomName = room.name;
          }
          else // screen that applies to all rooms
            if (sc.roomid === 0) {
              sc.floorId = 0;
              sc.floorName = "";
              sc.roomId = 0;
              sc.roomName = "";
              // add all room and floor names so device search filters work
              o.rooms.map(r => {
                if (sc.roomName.indexOf(r.name) === -1)
                  sc.roomName += r.name;
                if (sc.floorName.indexOf(r.floorName) === -1)
                  sc.floorName += r.floorName;
              });
            }
        });
      }
    });

    // broker TODO - support subscriptions?
    // broker does not propogate changes made to custom buttons agent in CP
    this.broker.call({
      path: '/api/v1/agents/custom_buttons'
    }).then(
      res => {
        this.handleUpdates(res);
      },
      err => {
        console.error('error getting custom_buttons', err);
      });
  }

  setScreen(screen: CustomButtonScreen) {
    //    this.store.dispatch({ type: SET_READY, payload: false });
    this.store.dispatch({ type: SET_SCREEN, payload: screen });
    //    this.getEvents(screen);
  }

  setButton(button: CustomButton) {
    this.store.dispatch({ type: SET_BUTTON, payload: button });
  }

  private handleUpdates(screens: Array<CustomButtonScreen>) {
    if (!!screens && Array.isArray(screens)) {
      this.store.dispatch({ type: SET_SCREENS, payload: screens });
    }
    this.store.dispatch({ type: SET_READY, payload: true });
  }
}
