import { ActionReducerMap } from '@ngrx/store';

import { connectionReducer, ControllerConnection } from './services/controller-connection.service';
import { commonConfigReducer } from './services/common-config.service';
import { ISubStore, SubState } from './interfaces/store';
import { backupReducer, BackupContext } from './services/backup.service';
import { directorConnectionReducer, DirectorConnectionState } from './director-connection/director-connection.service';
import { monitoredHttpReducer, MonitoredHttpState } from './services/monitored-http.service';
import { routerHistoryReducer, RouterHistoryState } from './services/router-history.service';
import { locationsReducer, LocationsContext } from './services/location.service';

export interface CoreState {
  controllerConnection: ControllerConnection,
  commonConfig: SubState,
  backup: BackupContext,
  directorConnection: DirectorConnectionState,
  monitoredHttpState: MonitoredHttpState,
  routerHistory: RouterHistoryState
}

export const CORE_STORE: ActionReducerMap<CoreState> = {
  controllerConnection: connectionReducer,
  commonConfig: commonConfigReducer,
  backup: backupReducer,
  directorConnection: directorConnectionReducer,
  monitoredHttpState: monitoredHttpReducer,
  routerHistory: routerHistoryReducer
}
