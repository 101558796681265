import { ActionReducerMap } from '@ngrx/store';

import { simpleProgrammingReducers, SimpleProgrammingContext } from './quickstart-simple.service';

export interface QuickstartSimpleState {
  simpleProgramming: SimpleProgrammingContext
}

export const QUICKSTART_SIMPLE_REDUCERS: ActionReducerMap<QuickstartSimpleState> = {
  simpleProgramming: simpleProgrammingReducers
}
