import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { Observable, combineLatest, of, Subscription, empty, from } from 'rxjs';
import { filter, map, shareReplay, timeout, catchError, take, concatMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { BrokerService } from '@when-then/core';

import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';
import { ItemsState } from '../../../common/services/items.service';
import { Device } from '../../../common/interfaces/item.interface';

import { MediaProgrammingContext } from '../quickstart-media.service';
import { StreamingServices } from './drivers/services';
import { Collection, MediaItem } from './drivers/interfaces/streaming.interface';
import { QSMediaService } from '../quickstart-media.service';
import { DataToUIShimService } from './datatoui-shim.service';

@Component({
  templateUrl: './select-media.component.html'
})
export class QSMediaSelectStreamingMediaComponent extends BaseRoutingComponent {

  public driver: Observable<Device>;
  public category: Observable<string>;
  public collection: Observable<MediaItem[]>;

  constructor(
    public router: Router,
    private _route: ActivatedRoute,
    protected store: Store<{
      mediaProgramming: MediaProgrammingContext,
      programmingItems: ItemsState
    }>,
    private _broker: BrokerService,
    private _http: HttpClient,
    private _dataToUI: DataToUIShimService
  ) {
    super();

    this.driver = combineLatest(
      this._route.params,
      this.store.select(s => s.programmingItems.itemsList)
    )
      .pipe(
        filter(([params, items]) => !!params && !!items && items.length > 0),
        map(([params, items]) => <Device>items.find(item => item.id == params['driverId']))
      );

    this.category = this._route.params
      .pipe(
        map(params => params['category'])
      );

    this.collection = this._dataToUI.customCommandData(this.driver, this.category)
      .pipe(
        timeout(20000),
        catchError(err => of([]))
      );

  }
}
