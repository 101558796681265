import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { filter, take, distinctUntilChanged, map, flatMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { BrokerService } from '@when-then/core';

import { ProgrammingEventsState } from './../../common/services/events.service';
import { Room as BaseRoom, Device, Location } from '../../common/interfaces/item.interface';
import { DeviceEvent, Event } from '../../common/interfaces/event.interface';
import { Binding } from '../../common/interfaces/binding.interface';
import { ItemsService } from '../../common/services/items.service';

const EVENTS_WHITELIST = [1, 2, 3];

export interface Room extends BaseRoom {
  bindings: Binding[];
  events: DeviceEvent[];
}

export interface RoomsProgrammingContext {
  rooms?: Room[]
}

const INITIAL_STATE: RoomsProgrammingContext = {}

const STORE_NAME = ['PROGRAMMING', 'QUICKSTARTS', 'ROOMS'].join(':');

export const ROOMS_ACTIONS = {
  SET_ROOMS: `${STORE_NAME}:SET_ROOMS`
}

const ROOMS_ACTION_STRINGS = Object.keys(ROOMS_ACTIONS).map(key => ROOMS_ACTIONS[key]);

export function roomsProgrammingReducers(state: RoomsProgrammingContext = INITIAL_STATE, { type, payload }): RoomsProgrammingContext {

  switch (type) {
    case ROOMS_ACTIONS.SET_ROOMS:
      return Object.assign({}, state, { rooms: payload });

    default:
      return state;
  }
}

@Injectable()
export class QSRoomsService {

  constructor(
    private _store: Store<{
      roomsProgramming: RoomsProgrammingContext,
      programmingEvents: ProgrammingEventsState
    }>,
    private _broker: BrokerService,
    private _items: ItemsService
  ) {

    this._items.itemsList
      .pipe(
        filter(items => !!items && items.length > 0),
        distinctUntilChanged(),
        map(items => {
          return items.filter(item => {
            return item.type === 8;
          })
        }),
        flatMap(rooms => {
          return this._broker.getObservable({
            path: '/api/v1/items/bindings',
            queryString: {
              query: {
                id: {
                  '$in': [...rooms.map(r => r.id)]
                },
                bindingClass: {
                  '$in': ['AUDIO_SELECTION', 'VIDEO_SELECTION']
                }
              }
            }
          })
            .pipe(
              take(1),
              map((bindings: Binding[]) => {
                return rooms
                  .map((room: Device) => {
                    room.bindings = bindings.filter(binding => binding.id == room.id && binding.connections.length > 0);
                    return room;
                  });
              })
            )
        }),
        flatMap(rooms => {
          return this._store.select(s => s.programmingEvents.allEvents)
            .pipe(
              filter(e => !!e),
              map((events: Event[]) => {
                return rooms.map((room: Device) => {
                  room.events = <DeviceEvent[]>events.filter((ev: Event) => {
                    return ev.deviceId == room.id &&
                      (EVENTS_WHITELIST.indexOf(ev.eventId) > -1 || ev.name == "Commands")
                  });
                  return room;
                })
              })
            )
        })
      )
      .subscribe(rooms => this._store.dispatch({
        type: ROOMS_ACTIONS.SET_ROOMS,
        payload: rooms
      }))
  }
}
