import { ProgrammingEventsState } from './../../../common/services/events.service';
import { LoggerFactory } from './../../../../../../../libs/core/src/lib/services/log.service';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { bufferTime, debounceTime } from 'rxjs/operators';
import { Component, OnInit, Input } from '@angular/core';

import { SharedProgrammingService, CommonProgrammingContext } from './../../../common/services/shared-programming.service';
import { EventsService } from '../../../common/services/events.service';
import { Event } from './../../../common/interfaces/event.interface';
import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';

@Component({
  selector: 'prg-flat-list',
  templateUrl: './flat-list.component.html',
  styleUrls: ['./flat-list.component.less']
})
export class FlatEventListComponent extends BaseRoutingComponent implements OnInit {
  @Input() source: Event[];

  private _logger = LoggerFactory.getLogger(FlatEventListComponent.name);

  searchText: Observable<string>;
  expanded = false;

  constructor(
    protected router: Router,
    protected store: Store<{
      sharedProgramming: CommonProgrammingContext,
      programmingEvents: ProgrammingEventsState
    }>,
    protected shared: SharedProgrammingService,
    protected events: EventsService
  ) {
    super();

    this.searchText = this.shared.searchText;
  }

  ngOnInit() { }

  itemClicked(event: MouseEvent, item: Event) {
    event.stopPropagation();
    this._logger.debug('list item clicked', event);
    // TODO INVESTIGAGTE -Samuel
    // Property 'classList' does not exist on type 'EventTarget'.
    // if (event.srcElement.classList.contains('trash') || event.srcElement.classList.contains('toggle')) {
    //   return;
    // }

    this.router.navigate(['when-then', 'device', item.deviceId, 'event', item.eventId, 'edit'], { relativeTo: this.moduleRoot });
  }
}
