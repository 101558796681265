import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, from } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { BrokerService } from '@when-then/core';

import { LightSceneDetail } from '../services/interfaces/light.interface';
import { Event } from '../../../common/interfaces/event.interface';
import { AgentMetaService, AGENTS } from '../../../common/services/agent-meta.service';
import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';
import { Store } from '@ngrx/store';

const EVENTS_PER_SCENE = 5;

@Component({
  selector: 'prg-light-scene-events',
  templateUrl: './light-scene-events.component.html',
  styleUrls: ['./light-scene-events.component.less']
})
export class LightSceneEventsComponent extends BaseRoutingComponent {

  scene: Observable<LightSceneDetail>;
  _events: Observable<Event[]>;

  constructor(
    private broker: BrokerService,
    private agents: AgentMetaService,
    private route: ActivatedRoute,
    protected store: Store<any>,
    protected router: Router
  ) {
    super();

    this.route.params
      .pipe(
        distinctUntilChanged()
      )
      .subscribe(params => {
        const sceneId = parseInt(params.id, 10);

        this.scene = from(this.broker.call({
          path: `/api/v1/agents/advanced_lighting/${sceneId}`
        }));

        const agentId = this.agents.getAgentId(AGENTS.ADVANCED_LIGHTING.CONTROL);
        // NOTE this little piece of witchcraft is due to how events are dynamically
        // allocated an id based on the scene id and they are all returned in one list
        // instead of being avaiable from a route based on the scene id
        // TODO double check: was from<Event[]> -Samuel
        this._events = from<Promise<any>>(this.broker.call({
          path: `/api/v1/items/${agentId}/events`,
          queryString: {
            allevents: true
          }
        }))
          .pipe(
            map(events => events.filter(evt =>
              evt.eventId === (EVENTS_PER_SCENE * sceneId) ||
              evt.eventId === ((sceneId * EVENTS_PER_SCENE) + 1)
            ))
          );
      });
  }

  ngOnInit() {
  }


  selectEvent(event: Event) {
    // NOTE the event component will handle subscribing to the event for us
    const agentId = this.agents.getAgentId(AGENTS.ADVANCED_LIGHTING.CONTROL);
    this.router.navigate(['when-then', 'device', agentId, 'event', event.eventId, 'add'], { relativeTo: this.moduleRoot });
  }
}
