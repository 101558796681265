import { Pipe, PipeTransform } from '@angular/core';
import { Device, Location } from '../interfaces/item.interface';

interface FloorRef {
  id: number,
  name: string;
}

@Pipe({
  name: 'floorsFromDevices'
})
export class FloorsFromDevicesPipe implements PipeTransform {

  transform(devices: Device[], args?: any): any {
    let floorMap: Map<number, FloorRef> = new Map<number, FloorRef>();

    if (devices) {
      devices.forEach(dev => {
        let floor = floorMap.get(dev.floorId);
        if (!floor) {
          floorMap.set(dev.floorId, { id: dev.floorId, name: dev.floorName });
        }
      });
    }

    return Array.from(floorMap.values());
  }

}
