<cxScrollable>
  <div class="flex column nowrap">
    <prg-nav-header *ngIf="!((route.data | async)?.editing)" title="Create Schedule" (cancel)="goHome()"></prg-nav-header>
    <prg-nav-header *ngIf="!!((route.data | async)?.editing)" title="Edit Schedule" (cancel)="goToEvent()"></prg-nav-header>
    <div class="alert alert-warning" *ngIf="errors?.length > 0">
      <div class="error">
        Oops! Looks like something isn't quite right. Please address the issues below and try again.
      </div>
      <ul class="errors">
        <li class="error" *ngFor="let err of errors">{{err}}</li>
      </ul>
    </div>
    <div class="grow">
      <form class="schedule" #scheduleForm="ngForm" action="">
        <div class="field-group">
          <label class="leading" for="name">Schedule Name</label>
          <input required type="text" maxlength="50" name="name" id="name" #name="ngModel" [(ngModel)]="vm.name"
            placeholder="enter a name for this schedule">
          <!-- <div [hidden]="name.valid || name.pristine" class="alert alert-danger">A schedule name is required.</div> -->
        </div>

        <!-- <prg-start-date [vm]="vm"></prg-start-date> -->
        <div class="field-group">
          <label for="" class=" leading">Start Date</label>
          <span class="inline-fields">
            <input type="radio" id="startFixed" name="startDateType" value="fixed" class="" [(ngModel)]="vm.startDateType">
            <label for="startFixed" class="control-label inline"> On </label>
            <input type="date" name="startDate" class="" #startDate="ngModel" [(ngModel)]="vm.startDate" [disabled]="!(vm.startDateType === 'fixed')">
            <!-- <div [hidden]="startDate.valid || startDate.pristine" class="alert alert-danger">Please enter a valid start date.</div> -->
          </span>
        </div>
        <div class="field-group offset">
          <span class="inline-fields">
            <input type="radio" id="startRelative" name="startDateType" [(ngModel)]="vm.startDateType" value="relative">
            <label for="startRelative" class="control-label inline">The</label>
            <select name="startWeekOffset" id="startWeekOffset" [(ngModel)]="vm.startWeekOffset" [disabled]="!(vm.startDateType === 'relative')">
              <option value="1">1st</option>
              <option value="2">2nd</option>
              <option value="3">3rd</option>
              <option value="4">4th</option>
              <option value="5">Last</option>
            </select>
            <select name="startDayOfWeek" id="startDayOfWeek" [(ngModel)]="vm.startDayOfWeek" [disabled]="!(vm.startDateType === 'relative')">
              <option value="1">Sunday</option>
              <option value="2">Monday</option>
              <option value="3">Tuesday</option>
              <option value="4">Wednesday</option>
              <option value="5">Thursday</option>
              <option value="6">Friday</option>
              <option value="7">Saturday</option>
            </select>
            <label for="" class="control-label inline"> of </label>
            <select name="month" id="" [(ngModel)]="vm.startMonth" [disabled]="!(vm.startDateType === 'relative')">
              <option value="1">January</option>
              <option value="2">February</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">August</option>
              <option value="9">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </select>
            <select name="startYear" id="startYear" [(ngModel)]="vm.startYear" [disabled]="!(vm.startDateType === 'relative')">
              <option *ngFor="let yr of (nextTenYears | async)" [value]="yr">{{yr}}</option>
            </select>
          </span>
        </div>

        <!-- <prg-start-time [vm]="vm"></prg-start-time> -->
        <div class="field-group">
          <label for="" class="leading">Start Time</label>
          <div class="inline-fields">
            <input id="fixedTime" type="radio" name="timeType" value="fixed" [(ngModel)]="vm.startTimeType">
            <label for="fixedTime" class=" inline"> at </label>
            <input type="time" name="time" [(ngModel)]="vm.startTime" [disabled]="!(vm.startTimeType === 'fixed')">
          </div>
        </div>
        <div class="field-group offset">
          <div class="inline-fields">
            <input id="relativeTime" type="radio" name="timeType" value="sol" [(ngModel)]="vm.startTimeType">
            <select name="sunriseSunsetOffset" [value]="vm | sunriseSunsetOffset" (change)="setSunriseSunsetOffsetType($event)"
              [disabled]="!(vm.startTimeType === 'sol')">
              <option value="before:sunrise">Before Sunrise</option>
              <option value="at:sunrise">At Sunrise</option>
              <option value="after:sunrise">After Sunrise</option>
              <option value="before:sunset">Before Sunset</option>
              <option value="at:sunset">At Sunset</option>
              <option value="after:sunset">After Sunset</option>
            </select>
            <span class="inline-fields" *ngIf="supportsInterval()">
              <label class="inline"> by </label>
              <input type="number" name="sunriseSunsetInterval" [(ngModel)]="vm.sunriseSunsetInterval">
              <label class="inline">minutes</label>
            </span>
          </div>
        </div>

        <!-- <prg-select-repeat [vm]="vm"></prg-select-repeat> -->
        <div class="field-group">
          <label for="occurs" class="leading">Occurs</label>
          <span class="inline-fields">
            <select name="occurs" id="occurs" [(ngModel)]="vm.repeatType" *ngIf="!fixRepeating">
              <option value="once">Once</option>
              <option value="every">Every</option>
            </select>
            <span class="inline-fields" *ngIf="vm.repeatType === 'every'">
              <input name="repeatRate" type="number" [max]="getLimit(vm.repeatFrequency)" [(ngModel)]="vm.repeatRate"
                min="1" (change)="repeatRateChanged($event)">
              <select name="repeatInterval" id="repeatInterval" [value]="vm.repeatFrequency" (change)="setFrequency($event)">
                <option value="1">Day(s)</option>
                <option value="2">Week(s)</option>
                <option value="3">Month(s)</option>
                <option value="4">Year(s)</option>
              </select>
            </span>
          </span>
        </div>
        <div class="field-group" *ngIf="vm.repeatFrequency === 2 && vm.repeatType === 'every'">
          <label for="" class=" leading"> On</label>
          <span class="inline-fields">
            <button onfocus="this.blur()" (click)="toggleDay(0)" class="round btn-default" [ngClass]="{'checked': isChecked(0)}">S</button>
            <button onfocus="this.blur()" (click)="toggleDay(1)" class="round btn-default" [ngClass]="{'checked': isChecked(1)}">M</button>
            <button onfocus="this.blur()" (click)="toggleDay(2)" class="round btn-default" [ngClass]="{'checked': isChecked(2)}">T</button>
            <button onfocus="this.blur()" (click)="toggleDay(3)" class="round btn-default" [ngClass]="{'checked': isChecked(3)}">W</button>
            <button onfocus="this.blur()" (click)="toggleDay(4)" class="round btn-default" [ngClass]="{'checked': isChecked(4)}">T</button>
            <button onfocus="this.blur()" (click)="toggleDay(5)" class="round btn-default" [ngClass]="{'checked': isChecked(5)}">F</button>
            <button onfocus="this.blur()" (click)="toggleDay(6)" class="round btn-default" [ngClass]="{'checked': isChecked(6)}">S</button>
          </span>
        </div>
        <div class="field-group offset helpers" *ngIf="vm.repeatFrequency === 2 && vm.repeatType === 'every'">
          <a (click)="selectAll()">All</a>
          <a (click)="selectNone()">None</a>
          <a (click)="selectWeekends()">Weekends</a>
          <a (click)="selectWeekdays()">Weekdays</a>
        </div>
        <div class="field-group offset" *ngIf="vm.repeatType === 'every'">
          <span class="inline-fields">
            <input type="checkbox" name="hasEndDate" id="hasEndDate" #hasEndDate="ngModel" [(ngModel)]="vm.hasEndDate">
            <label for="hasEndDate" class=" checkbox-label"> Until</label>
            <input type="date" class="" name="endDate" #endDate="ngModel" [(ngModel)]="vm.endDate" [disabled]="!vm.hasEndDate">
            <!-- <div [hidden]="endDate.valid || endDate.pristine || !hasEndDate.checked" class="alert alert-danger">Please enter a valid end date.</div> -->
            <!-- <div [hidden]="validateDateRange()" class="alert alert-danger">End date must be after start date.</div> -->
          </span>
        </div>

      </form>

    </div>
    <footer class="flex row transparent">
      <span class="preview">{{vm | scheduleVMLabel}}</span>
      <cxWait [cxUntil]="(ready|async) && saveCompleted" cxText="Saving schedule..."><button cxNoDoubleClick class="blue clickable"
          (click)="createSchedule()">{{!(editing|async) ? 'Next: Add Actions' : 'Save Schedule'}}</button></cxWait>
    </footer>
  </div>
</cxScrollable>
