import { EventType } from './event-type.interface';

export const IR: EventType = {
  name: 'Infrared',
  description: 'When an Infrared sensor is triggered',
  route: 'infrared',
  category: 'Sensors',
  icon: 'infrared-trigger',
  whitelist: [
    {
      fields: { proxy: 'contactsingle_irbeam_c4' },
      events: [1, 2]
    }
  ]
}
