import { Store } from '@ngrx/store';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from "@angular/core";

import { StorageService } from './storage.service';
import { AnalyticsService } from './analytics.service';
import { LoggerFactory, LogLevel } from './log.service';
import { take } from 'rxjs/operators';

import * as moment from 'moment';
import { HttpMethods } from '../interfaces/http';

export const REQUEST_ID_HEADER = 'x-request-id';

const MIN_LOG_DUMP_SIZE = 10;

interface BaseLogEntry {
  osVersion?: string;
  wtVersion?: string;
  tt?: number;
  err?: string;
  user_agent?: string;
  client_end_timestamp?: string;
  client_start_timestamp?: string;
}

interface NetworkLogEntry extends BaseLogEntry {
  path: string;
  method: string;
  st?: 'succ' | 'fail';
  subscriptionclient?: string;
  reqid?: string;
}

interface MeasurementLogEntry extends BaseLogEntry {
  starttag: string;
  endtag: string;
}

interface RemoteLogMessage {
  source: string;
  level: string;
  name: string;
  message: string;
}

@Injectable()
export class PerformanceService {

  private _pendingMarks = [];
  private _logger = LoggerFactory.getLogger(PerformanceService.name);
  private _subscriptions = [];
  private _queue = [];
  private _dumpingLogs = false;

  constructor(
    private analytics: AnalyticsService,
    private storage: StorageService,
    private http: HttpClient,
    private store: Store<{ sharedProgramming: { osVersion: string; } }>
  ) { }

  // mark(name: string, measureName?: string, measureStart?: string) {
  //   performance.mark(name);

  //   if (!!measureName && !!measureStart) {
  //     this.measure(measureName, measureStart, name);
  //   }
  // }

  // measure(name: string, start: string, end: string) {
  //   performance.measure(name, start, end);
  // }

  monitor<TBody = {}>(request: HttpRequest<TBody>, p: Promise<any>) {
    const start = performance.now();
    const entry = this._buildNetworklEntry(request);
    // this._logger.debug('monitoring request with', request, entry);
    p.then(res => {
      entry.tt = Math.round(performance.now() - start);
      entry.st = 'succ';
      entry.client_end_timestamp = moment().toISOString();
      this.analytics.emitTiming('Broker', 'PromiseSuccess', `${entry.method} ${entry.path}`, entry.tt);
      this._enqueue(entry);
    }, err => {
      entry.tt = Math.round(performance.now() - start);
      entry.st = 'fail';
      entry.err = JSON.stringify(err);
      entry.client_end_timestamp = moment().toISOString();
      this.analytics.emitTiming('Broker', 'PromiseError', `${entry.method} ${entry.path}`, entry.tt);
      this._enqueue(entry);
    });
  }

  private _buildNetworklEntry<TBody = {}>(request: HttpRequest<TBody>): NetworkLogEntry {
    const method = this._getMethodName(request.method as HttpMethods);
    const path = this._getRequestPath(request.url);
    const entry: NetworkLogEntry = { method: method, path: path };
    entry.client_start_timestamp = moment().toISOString();

    const reqid = request.headers.get(REQUEST_ID_HEADER);
    // this._logger.debug('request id header value is ', reqid);
    if (!!reqid) {
      entry.reqid = reqid;
    }

    const sid = this.storage.get('subscriptionClientId');
    if (!!sid) {
      entry.subscriptionclient = sid;
    }

    return entry;
  }

  private _buildSubscriptionEntry(entry: any, status: 'succ' | 'fail', delta: number): NetworkLogEntry {
    return {
      method: 'subscription',
      path: entry.path,
      st: status,
      tt: delta,
      user_agent: navigator.userAgent,
      subscriptionclient: entry.subscriptionclient,
      reqid: entry.reqid,
      client_start_timestamp: entry.client_start_timestamp,
      client_end_timestamp: moment().toISOString()
    };
  }

  private _getMethodName(method: HttpMethods): string {
    switch (method) {
      case HttpMethods.POST: return 'post';
      case HttpMethods.DELETE: return 'delete';
      case HttpMethods.GET: return 'get';
      case HttpMethods.PUT: return 'put';
    }

    return 'unknown';
  }

  private _getRequestPath(fullPath: string): string {
    return fullPath.substring(fullPath.indexOf('/api/'));
  }

  start(path: string, subscriptionId: string, reqid: string, clientId: string) {
    // this._logger.debug('start', subscriptionId, path, reqid, clientId);
    this._subscriptions.push({
      path: path,
      id: subscriptionId,
      start: performance.now(),
      lastTick: 0,
      reqid: reqid,
      subscriptionclient: clientId,
      client_start_timestamp: moment().toISOString()
    });
  }

  tick(id: string) {
    // this._logger.debug('tick', id);
    const i = this._subscriptions.findIndex(s => s.id === id);
    if (i >= 0) {
      // NOTE if we're not tracking delta in subscription updates we don't need these to hang around
      const entry = this._subscriptions.splice(i, 1)[0];
      this._logger.debug('subs waiting for first update', this._subscriptions.length);
      if (!!entry) {
        const delta = Math.round(performance.now() - entry.start);
        this._logger.debug(`perf: ${entry.path} time to first update ${delta}ms`);
        this.analytics.emitTiming('Broker', 'SubscriptionFirstUpdate', `${entry.path}`, delta);
        this._enqueue(this._buildSubscriptionEntry(entry, 'succ', delta));
      }
    // } else {
    //   this._logger.debug(`expected to find subscription with id ${id} but did not`);
    }
  }

  // NOTE with the impl of tick above this will always be a no-op,
  // but leave it here in case we go back to monitoring subscription
  // update deltas
  stop(id: string) {
    const idx = this._subscriptions.findIndex(s => s.id === id);
    if (idx >= 0) {
      this._subscriptions.splice(idx, 1);
    }
  }

  private _enqueue(stmt: BaseLogEntry) {
    // augment the log entry with OS versions
    let osVersion = 'unknown';
    this.store.select(s => s.sharedProgramming.osVersion)
      .pipe(
        take(1)
      )
      .subscribe(osv => { osVersion = osv; });
    stmt.osVersion = osVersion;
    stmt.wtVersion = this.storage.get('wtVersion') || 'dev-server';

    // this._logger.debug('storing perf log', stmt);
    this._queue.push({
      source: 'whenthen',
      level: 'debug',
      name: 'perf',
      message: this._composeMessage(stmt)
    });

    this._dump();
  }

  private _dump() {
    if (this._queue.length > MIN_LOG_DUMP_SIZE && !this._dumpingLogs) {
      this._dumpingLogs = true;
      this._logger.debug('dumping perf logs', this._queue);
      const url = this.storage.get('remoteLoggingURL');
      const token = this.storage.get('authToken');
      if (!!url && !!token) {
        const payload = [...this._queue];
        this._queue = [];
        const headers = new HttpHeaders();
        headers.append('Authorization', `Bearer ${token}`);
        headers.append('Content-Type', 'application/json; charset=UTF-8');
        // headers.append('Accept', 'application/json');
        this.http.post(url, payload, { headers: headers }).subscribe(res => {
          this._logger.debug('remote perf log chunk written');
          this._dumpingLogs = false;
        }, err => {
          this._logger.error('error dumping perf logs', err);
          // recover the failed logging statements and try again later
          this._queue = payload.concat(this._queue);
          this._dumpingLogs = false;
        });
      } else {
        console.warn('remote logger called without token or url in conrtext');
        this._dumpingLogs = false;
      }
    }
  }

  protected _levelName(level: LogLevel): string {
    switch (level) {
      case LogLevel.ERROR: return 'ERROR';
      case LogLevel.WARN: return 'WARN';
      case LogLevel.INFO: return 'INFO';
      case LogLevel.DEBUG: return 'DEBUG';
      case LogLevel.TRACE: return 'TRACE';
    }
  }

  protected _composeMessage(stmt: BaseLogEntry): string {
    let msg = '';

    if (!!stmt) {
      Object.keys(stmt).forEach(key => {
        if (stmt.hasOwnProperty(key)) {
          msg += (`${key}=${stmt[key]}` + ' ');
        }
      })
    }

    return msg.trim();
  }
}
