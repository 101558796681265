import { EventType } from './event-type.interface';

export const WINDOW: EventType = {
  name: 'Window',
  description: 'When a window is opened or closed',
  route: 'window',
  category: 'Security & Access',
  icon: 'window',
  whitelist: [
    {
      fields: { proxy: 'contactsingle_windowcontactsensor_c4' },
      events: [1, 2]
    }
  ]
}
