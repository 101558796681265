import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { take, filter } from 'rxjs/operators';

import { BackupService, BackupContext, LoggerFactory, LogLevel } from '@when-then/core';

@Component({
  selector: 'prg-backup-project',
  templateUrl: './backup-project.component.html',
  styleUrls: ['./backup-project.component.less']
})
export class BackupProjectComponent implements OnInit {

  required: Observable<boolean>;
  checked: Observable<boolean>;
  running: Observable<boolean>;
  current: Observable<any>;
  previous: Observable<any>;
  error: Observable<any>;

  dayOfWeek: string;
  scheduleBackups: boolean;

  backupRequested: boolean = false;

  constructor(
    protected router: Router,
    private route: ActivatedRoute,
    private store: Store<{ backup: BackupContext }>,
    public backups: BackupService
  ) {
    this.dayOfWeek = 'sun';
    this.scheduleBackups = false;

    this.required = this.backups.required;
    this.checked = this.backups.checked;
    this.running = this.backups.running;
    this.previous = this.backups.previousOperation;
    this.current = this.backups.currentOperation;
    this.error = this.backups.error;
  }

  ngOnInit() {
    this.route.params.pipe(take(1)).subscribe((params: any) => {
      console.log('backup-project: route params are', params);
      if (!!params.origin) {
        this.backups.setRedirectURL(decodeURI(params.origin));
      }
    })
  }

  startBackup() {
    console.log('start backup with', this.scheduleBackups, this.dayOfWeek);

    this.backupRequested = true;

    // NOTE the service controls the UI flow from here
    this.backups.setBackup('');

    combineLatest(
      this.store.select(s => s.backup.complete),
      this.store.select(s => s.backup.required),
      this.store.select(s => s.backup.ready),
      (complete, required, ready) => (complete && !required && ready))
      .pipe(
        filter(done => !!done)
      )
      .subscribe(done => {
        console.log('~~~ backup complete and successful');
        this.backupRequested = false;
        this.store.select(s => s.backup.redirectURL).pipe(take(1)).subscribe(redirectURL => {
          console.log('~~~ got redirect url', redirectURL);
          if (!!redirectURL) {
            // this.store.select(s => s).take(1).subscribe(s => console.log('state: ', s));
            console.log('~~~ routing to redirect url', redirectURL);
            this.router.navigate([redirectURL]);
          } else {
            console.warn('~~~ post backup redirect URL is not defined');
          }
        });
      });
  }

  headerClicked(event: MouseEvent) {
    if (event.altKey && event.shiftKey) {
      LoggerFactory.elevateLogging(LogLevel.DEBUG);
    }
  }
}
