<cxScrollable>
  <cxWait [cxUntil]="_command != undefined">
    <prg-nav-header title="{{ _command?.label || _command?.command }}" (cancel)="goToEvent()"></prg-nav-header>
    <div class="params">
      <form>
        <div class="field-group" *ngFor="let param of _parameters; trackBy: identify">
          <label class="leading">{{ param.name | readable }}
            <span *ngIf="param.type == 'RANGE' && param._value != undefined">({{ param._value }})</span>
          </label>
          <c4ParamInput [param]="param" (valueChange)="update(param, $event)"></c4ParamInput>
        </div>
      </form>
    </div>
    <footer class="row flex">
      <span class="grow">
        <c4ParamLabel [command]="_command" [params]="_parameters">
        </c4ParamLabel>
      </span>
      <button class="blue clickable" (click)="saveCommand()" [disabled]="!_configured()">Add Preset</button>
    </footer>

  </cxWait>
</cxScrollable>