import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, combineLatest, from, of } from 'rxjs';
import { filter, flatMap, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { BrokerService, LoggerFactory } from '@when-then/core';

import { Room, MediaProgrammingContext, MediaDevice } from '../quickstart-media.service';
import { BaseRoutingComponent } from '../../../common/base-routing/base-routing.component';
import { ItemsState } from '../../../common/services/items.service';
import { Item } from '../../../common/interfaces/item.interface';

const DEVICE_CLASSES: {
  [key: string]: {
    class: 'watch_devices' | 'listen_devices',
    types: string[]
  }
} = {
  broadcast_video: {
    class: 'watch_devices',
    types: [
      'RF_CABLE',
      'RF_UHF_VHF',
      'DIGITAL_VIDEO_SERVER'
    ]
  },
  movie: {
    class: 'watch_devices',
    types: ['VIDEO_SELECTION']
  },
  broadcast_audio: {
    class: 'listen_devices',
    types: [
      'RF_FM',
      'RF_AM',
      'DIGITAL_AUDIO_SERVER',
    ]
  },
  album: {
    class: 'listen_devices',
    types: ['AUDIO_MEDIA_STORAGE']
  },
  playlist: {
    class: 'listen_devices',
    types: ['AUDIO_MEDIA_STORAGE']
  }
};

@Component({
  templateUrl: './select-room.component.html',
  styleUrls: []
})
export class QSMediaSelectRoomComponent extends BaseRoutingComponent {

  private _logger = LoggerFactory.getLogger(QSMediaSelectRoomComponent.name);

  source: Observable<string>;
  _rooms: Observable<Room[]>;

  readonly prettySource = {
    broadcast_audio: 'Radio Station',
    broadcast_video: 'TV Station',
    movie: 'Movie',
    playlist: 'Playlist',
    album: 'Album',
    SELECT_AUDIO_DEVICE: 'Audio Source',
    SELECT_VIDEO_DEVICE: 'Video Source'
  }

  constructor(
    private _route: ActivatedRoute,
    protected store: Store<{
      mediaProgramming: MediaProgrammingContext,
      programmingItems: ItemsState
    }>,
    private _broker: BrokerService,
    protected router: Router
  ) {
    super();

    this.source = this._route.params.pipe(map((params: { source: string }) => params.source));

    this._rooms = combineLatest(
      this.source,
      this.store.select(s => s.mediaProgramming.rooms).pipe(filter(r => !!r)),
      this.store.select(s => s.mediaProgramming.listen_devices).pipe(filter(r => !!r)),
      this.store.select(s => s.mediaProgramming.watch_devices).pipe(filter(r => !!r))
    )
      .pipe(
        flatMap(([source, rooms, listen, watch]) => {
          this._logger.debug('filtering rooms for media devices', source, rooms, listen, watch);

          if (source == 'SELECT_AUDIO_DEVICE' || source == 'SELECT_VIDEO_DEVICE') {

            // Filter the list of rooms to only those
            // that have visible devices of the correct type
            return of(rooms.filter(room => {
              const command = room.commands.find(c => c.command == source);

              if (!command) { return false; }

              let devices: MediaDevice[];
              if (source == 'SELECT_AUDIO_DEVICE') {
                const d = listen.find(r => r.id == room.id);
                devices = (!!d) ? d.listen_devices : [];
              } else {
                const d = watch.find(r => r.id == room.id);
                devices = (!!d) ? d.watch_devices : [];
              }

              return !!devices && !!devices.some(d => d.visible);
            }))
          }

          return from(this._broker.call({
            path: '/api/v1/locations/rooms/' + DEVICE_CLASSES[source].class
          }))
            .pipe(
              map(response => {
                return rooms.filter(room => {
                  let devices: MediaDevice[] = response
                    .find(r => r.id == room.id)[DEVICE_CLASSES[source].class];

                  return !!devices
                    && devices.filter(d => d.visible && d.id > -1).length
                    && devices.some(d => {
                      return DEVICE_CLASSES[source].types.indexOf(d.type) > -1;
                    });
                })
              })
            )
        })
      );
  }

  public nextStep = (source: string): 'media' | 'device' => {
    return (source == 'SELECT_AUDIO_DEVICE' || source == 'SELECT_VIDEO_DEVICE')
      ? 'device'
      : 'media';
  }
}
