import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'listFilter'
})
export class ListFilterPipe implements PipeTransform {

  transform(values: Array<any>, filterText: string, displayField?: any): Array<any> {
    let filterLower = (filterText || '').toLowerCase().trim();
    if (filterLower.length === 0) {
      return values;
    }

    if (typeof displayField === 'object') {
      displayField = this.findDisplayField(values[0] || {}, displayField);
    }

    if (values) {
      return values.filter(val => {
        let itemLower = '';
        if (displayField) {
          let tmp = val[displayField];
          if (tmp) {
            itemLower = tmp.toString().toLowerCase().trim();
          }
        } else {
          itemLower = val.toString().toLowerCase().trim();
        }

        return (itemLower.indexOf(filterLower) >= 0);
      });
    }

    return values;
  }

  private findDisplayField(sampleValue: any, displayFields: Array<string>): string {
    let displayField = '';

    if (displayFields && sampleValue) {
      for (let i = 0; i < displayFields.length; i++) {
        let df = displayFields[i];
        if (df) {
          if (sampleValue.hasOwnProperty(df)) {
            displayField = df;
            break;
          }
        }
      }
    }

    return displayField;
  }
}

