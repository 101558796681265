import {Pipe, PipeTransform} from '@angular/core';

import * as _ from 'lodash';

@Pipe({
  name: 'normalizeCategory'
})
export class NormalizeCategoryPipe implements PipeTransform {
  transform(value: string): string {
    if (value && value.replace) {
      return _.startCase(value.replace('/', ' & ').replace('_', ' & ').trim());
    }

    return value;
  }
}
