<!-- <div [cxWait]="!!(_scenes | async)"> -->
<prg-nav-header title="Select a Lighting Scene" (cancel)="cancelClicked()">
  <div right>
    <select name="room" id="room" [(ngModel)]="_selectedRoom" (change)="setScenes()" style="display:inline-block">
      <option value="0">Any Room</option>
      <option [value]="r.roomId" *ngFor="let r of (_rooms | async | sortBy:'name')">{{r.name}}</option>
    </select>
    <div class="search" style="display:inline-block">
      <span class="icon blue search small"></span>
      <input type="search" [(ngModel)]="_searchText" placeholder="Search" name="searchText">
    </div>
  </div>
</prg-nav-header>
<div class="alert flex grow padded" *ngIf="!_selectedRoom && (_scenes | async)?.length === 0">
  <header>
    <span class="icon grey small information"></span>
    <h5 class="grow">No Lighting Scenes Found</h5>
  </header>
  <p>There are no lighting scenes defined. for your system. You can create lighting scenes using the Control4 app on
    your mobile device or touchscreen. Otherwise, please contact your Control4 dealer for assistance.
  </p>
</div>
<div class="alert flex grow padded" *ngIf="!!_searchText && (_scenes | async | matchingSearchText:_searchText)?.length === 0">
  <header>
    <span class="icon grey small information"></span>
    <h5 class="grow">No Matching Lighting Scenes</h5>
  </header>
  <p>
    There are no lighting scenes that match your search terms. Please try a different search.
  </p>
</div>
<div class="alert flex grow padded" *ngIf="!!_selectedRoom && !_searchText && (_scenes | async)?.length === 0">
  <header>
    <span class="icon grey small information"></span>
    <h5 class="grow">No Lighting Scenes Found</h5>
  </header>
  <p>
    There are no lighting scenes available in this room. Please try a different room.
  </p>
</div>
<cxScrollable>
  <prg-block-button *ngFor="let scene of (_scenes | async | sortBy:'order' | matchingSearchText:_searchText)" (click)="routeToScene(scene)"
    [buttonClass]="{'dark': isForTrigger(route)}" leadingIcon="blue lightscene" title="{{ scene.name }}" trailingIcon="right"></prg-block-button>
</cxScrollable>
<!-- </div> -->
